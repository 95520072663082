import React from 'react';

function TikTokIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 20}
      height={props.size ?? 20}
      fill="none"
      viewBox="0 0 21 20"
    >
      <path
        fill={props.color ?? '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19.5 6v8a5 5 0 01-5 5h-8a5 5 0 01-5-5V6a5 5 0 015-5h8a5 5 0 015 5z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.5 10a3 3 0 103 3V4c.333 1 1.6 3 4 3"
      ></path>
    </svg>
  );
}

export default TikTokIcon;
