import React, { useMemo, useState } from 'react';
import ReactPlayer from 'react-player';

import AudioPauseIcon from 'assets/svgs/AudioPauseIcon';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import './index.styles.scss';

export const MobileMedia = ({ data }) => {
  const [playVideo, setPlayVideo] = useState(false);

  return (
    <div className="mobile_media_body">
      {data.type === 'video' && (
        <div className="card_media_view">
          <ReactPlayer
            url={data.url}
            width="100%"
            height="100%"
            controls={false}
            playing={playVideo}
            onEnded={() => setPlayVideo(false)}
          />
          <div
            className="play_btn"
            onClick={() => setPlayVideo((prev) => !prev)}
          >
            {playVideo ? <AudioPauseIcon /> : <AudioPlayIcon color="#FF3E9A" />}
          </div>
        </div>
      )}
      {data.type === 'image' && (
        <img src={data.url} className="card_media_view" />
      )}
    </div>
  );
};
