import HttpClient from './apiClient';

export async function apiEventCreate(params: {
  organisation: any;
  longitude: any;
  latitude: any;
  location: any;
  title: any;
  description: any;
  date: any;
  image: any;
}) {
  try {
    const response = await HttpClient.postWithToken(`event/create`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEventEdit(params: {
  event: any;
  organisation: any;
  longitude: any;
  latitude: any;
  location: any;
  title: any;
  description: any;
  date: any;
  image: any;
}) {
  try {
    const response = await HttpClient.postWithToken(`event/edit`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEventHosted(params: {
  query: string;
  orgs: any;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`event/created`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEventDelete(params: { event: any }) {
  try {
    const response = await HttpClient.postWithToken(`event/delete`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEventDetail(params: { code: any }) {
  try {
    const response = await HttpClient.getWithToken(`event/detail`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEventRsvp(params: { code: any }) {
  try {
    const response = await HttpClient.getWithToken(`event/rsvps`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEventAll(params: {
  query: string;
  orgs: any;
  period: any;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`event/get`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEventConfirmRSVP(params: { event: number }) {
  try {
    const response = await HttpClient.postWithToken(
      `event/confirmRsvp`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEventRemoveRSVP(params: { event: number }) {
  try {
    const response = await HttpClient.postWithToken(`event/removeRsvp`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEventAttending(params: {
  query: string;
  orgs: any;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`event/attending`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
