import CalendarIcon from 'assets/svgs/CalendarIcon';
import CameraIcon from 'assets/svgs/CameraIcon';
import ClockIcon from 'assets/svgs/ClockIcon';
import EditPencilColoredIcon from 'assets/svgs/EditPencilColoredIcon';
import RoundBackIcon from 'assets/svgs/RoundBackIcon';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import moment from 'moment';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import { getBase64String } from 'shared/utils-IPFS/functions';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import './index.styles.scss';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { EventSelectOrgModal } from '../EventSelectOrgModal';
import { apiEventEdit } from 'shared/api/event';
import { apiGetOrgDetail } from 'shared/api/org';
import { DeviceSize } from 'shared/utils/constants';
import { useMediaQuery } from 'react-responsive';
import MapPinIcon from 'assets/svgs/MapPinIcon';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-google-places-autocomplete';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface EditEventModalProps {
  show: boolean;
  eventData: any;
  onClose: () => void;
  onSuccess: () => any;
}

export const EditEventModal = ({
  show,
  eventData,
  onClose,
  onSuccess
}: EditEventModalProps) => {
  const locationRef = useRef<any>(null);
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  const dateRef = useRef<DatePicker>();

  const [title, setTitle] = useState(eventData.title);
  const [descTxt, setDescTxt] = useState(eventData.description);
  const [isRsvpLimit, setIsRsvpLimit] = useState(false);
  const [selectedImg, setSelectedImg] = useState<any>(eventData.image);
  const [selectedOrg, setSelectedOrg] = useState<any>(null);
  const [showSelectOrgModal, setShowSelectOrgModal] = useState(false);

  const [date, setDate] = useState<any>(new Date());
  const [location, setLocation] = useState<any>(null);
  const [time, setTime] = useState('10:00');

  const { uploadBase64 } = useIPFS();
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const myApiKey =
    process.env.GOOGLE_MAPS_API_KEY ||
    'AIzaSyBJPLIWETfI_wOxTn-CUHWrk4B5diY2Fbw';

  useEffect(() => {
    if (show && eventData) {
      init();
      loadOrgInfo();

      isMobile &&
        document
          .getElementsByTagName('body')[0]
          .setAttribute('style', 'zoom: 80%;');
    } else {
      isMobile &&
        document
          .getElementsByTagName('body')[0]
          .setAttribute('style', 'zoom: 100%;');
    }
  }, [show]);

  const init = () => {
    setTitle(eventData.title);
    setDescTxt(eventData.description);
    setSelectedImg(eventData.image);
    setDate(new Date(eventData.date));
    setTime(moment(eventData.date).format('hh:mm'));
    setLocation({
      name: eventData.location,
      lat: eventData.latitude,
      lng: eventData.longitude
    });
  };

  const loadOrgInfo = async () => {
    const res = await apiGetOrgDetail({ organisation: eventData.organisation });

    if (res.success) {
      setSelectedOrg({
        ...res.data,
        member_number: res.data.organisation_members
      });
    }
  };

  const onSelectImage = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.multiple = true;
    inputElement.addEventListener('change', onAddImage);
    inputElement.dispatchEvent(new MouseEvent('click'));
  };

  const onAddImage = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    if (files.length) {
      const type = files[0].type;
      setIsUploadingImage(true);

      const base64String = await getBase64String(files[0]);
      const url = await uploadBase64(base64String, type, '');

      setSelectedImg(url);
      setIsUploadingImage(false);
    }
  };

  const onPublish = async () => {
    if (!isCreating && !!title && !!descTxt && selectedOrg && location) {
      setIsCreating(true);

      const res = await apiEventEdit({
        event: eventData.id,
        organisation: selectedOrg.id,
        longitude: location.lng,
        latitude: location.lat,
        location: location.name,
        title: title,
        description: descTxt,
        date: moment(date).valueOf(),
        image: selectedImg
      });

      if (res.success) {
        showToast('success', 'Successfully Updated');
        onSuccess();
      }

      setIsCreating(false);
    } else {
      showToast('error', 'Please check info');
    }
  };

  const onChangedLocation = async (newLocation) => {
    const results = await geocodeByAddress(newLocation.label);
    const latLng = await getLatLng(results[0]);

    setLocation({
      name: newLocation.label,
      lat: latLng.lat,
      lng: latLng.lng
    });
  };

  const renderHeader = () => {
    return (
      <div className="modal_header row_space_between row_align_items">
        <div
          className="clickable"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <RoundBackIcon />
        </div>
        <div className="font-bold font16">Edit Event</div>
        <div className="camera_btn clickable" onClick={onSelectImage}>
          <CameraIcon size={24} color="#FF3E9A" />
        </div>
      </div>
    );
  };

  const renderImageView = () => {
    return (
      <div className="image_view">
        {selectedImg && (
          <img
            src={selectedImg}
            className="image_view"
            style={{ borderBottom: 'none' }}
          />
        )}
        {isUploadingImage && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <LoadingCircular color="main" size={24} />
          </div>
        )}
      </div>
    );
  };

  const renderBody = () => {
    return <div className="modal_body">{renderTitle()}</div>;
  };

  const renderTitle = () => {
    return (
      <>
        <div
          className="font-regular font10"
          style={{ color: 'rgba(0, 0, 0, 0.6)' }}
        >
          Event Title
        </div>
        <input
          value={title}
          placeholder="Enter event name here..."
          className={`${
            selectedImg ? 'title_input' : 'default_title_input'
          } font-bold font24`}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </>
    );
  };

  const renderInfoView = () => {
    return (
      <div className="event_main_body">
        {renderDescription()}
        {renderDate()}
        {renderOrg()}
        {renderLocation()}
        {renderBottom()}
      </div>
    );
  };

  const renderDescription = () => {
    return (
      <div>
        <div className="divider" />
        <div>
          <div className="sub_title font-regular font10">Description</div>
          <textarea
            value={descTxt}
            placeholder="Enter event description here..."
            className="desc_input font-regular font16"
            onChange={(e) => {
              setDescTxt(e.target.value);
            }}
          />
        </div>
      </div>
    );
  };

  const renderDate = () => {
    return (
      <div>
        <div className="divider" />
        <div className="date_view">
          <div
            className="date_sub_view row_align_items row_space_between clickable"
            onClick={() => {
              dateRef.current.setOpen(true);
            }}
          >
            <div>
              <div className="sub_title font10">Date</div>
              <div className="font-medium font16" style={{ marginTop: 8 }}>
                <DatePicker
                  ref={dateRef}
                  selected={date}
                  onChange={(e) => {
                    setDate(e);
                  }}
                />
              </div>
            </div>
            <div>
              <CalendarIcon />
            </div>
          </div>
          <div style={{ width: 32 }} />
          <div className="date_sub_view row_align_items row_space_between clickable">
            <div>
              <div className="sub_title font10">Time</div>
              <div className="font-medium font16" style={{ marginTop: 8 }}>
                <TimePicker
                  onChange={(e) => {
                    console.log(e);
                  }}
                  value={time}
                />
              </div>
            </div>
            <div>
              <ClockIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderOrg = () => {
    return (
      <div>
        <div className="divider" />
        <div className="org_view">
          <div className="sub_title font10">School</div>
          <div className="row_space_between" style={{ marginTop: 8 }}>
            <div className="row_align_items">
              <img src={selectedOrg?.image} className="org_image" />
              <div style={{ marginLeft: 8 }}>
                <div className="font-bold font14">{selectedOrg?.name}</div>
                <div className="font-medium font12" style={{ opacity: 0.4 }}>
                  {selectedOrg?.member_number} members
                </div>
              </div>
            </div>
            <div
              className="clickable"
              onClick={() => setShowSelectOrgModal(true)}
            >
              <EditPencilColoredIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLocation = () => {
    return (
      <div>
        <div className="divider" />
        <div className="org_view">
          <div className="sub_title font10">Location</div>
          <div className="row_space_between" style={{ marginTop: 8 }}>
            <div
              className="row_align_items font-bold font14"
              style={{ width: '100%', marginRight: 16 }}
            >
              <GooglePlacesAutocomplete
                ref={locationRef}
                apiKey={myApiKey}
                selectProps={{
                  styles: {
                    container: (provided) => ({
                      ...provided,
                      border: 'none',
                      width: '100%'
                    }),
                    control: (provided) => ({
                      ...provided,
                      border: 'none',
                      width: '100%'
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: 'none'
                    }),
                    indicatorContainer: (provided) => ({
                      ...provided,
                      display: 'none !important'
                    })
                  },
                  menuPlacement: 'auto',
                  value: {
                    label: location?.name,
                    value: {
                      place_id: null
                    }
                  },
                  onChange: onChangedLocation
                }}
              />
            </div>
            <div
              onClick={() => {
                // locationRef?.current?.focus();
              }}
            >
              <MapPinIcon size={24} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div>
        <div className="divider" />
        <div className="action_view">
          <div className="cancel_btn clickable" onClick={onClose}>
            <div className="font-bold font16">Cancel</div>
          </div>
          <div style={{ width: 16 }} />
          <div className="publish_btn clickable" onClick={onPublish}>
            {isCreating ? (
              <LoadingCircular color="white" size={24} />
            ) : (
              <div className="font-bold font16" style={{ color: 'white' }}>
                Update Event
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSelectOrgModal = () => {
    return (
      <EventSelectOrgModal
        show={showSelectOrgModal}
        enableDefault={false}
        onClose={() => {
          setShowSelectOrgModal(false);
        }}
        onSelectOrg={(org) => {
          setSelectedOrg(org);
          setShowSelectOrgModal(false);
        }}
      />
    );
  };

  return (
    <PrimaryModal
      show={show}
      width={isMobile ? '100%' : 500}
      onClose={onClose}
      style={{ backgroundColor: 'red', padding: 0 }}
    >
      <div
        className="modal_edit_event"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {renderImageView()}
        {renderHeader()}
        {renderBody()}
        {renderInfoView()}
        {renderSelectOrgModal()}
      </div>
    </PrimaryModal>
  );
};
