import React, { useEffect, useMemo, useState } from 'react';
import { apiGetSuggestAIs } from 'shared/api/suggest';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { MobileCardChooseVoice } from './CardBot';

import './index.styles.scss';

interface Props {
  isCreating: boolean;
  isSkipingAndCompleting: boolean;
  onSkipAndCreateAI: () => void;
  onCreateAIWithVoice: (voiceId) => void;
}

export const MobileChooseVoice = ({
  isCreating,
  isSkipingAndCompleting,
  onSkipAndCreateAI,
  onCreateAIWithVoice
}: Props) => {
  const [query, setQuery] = useState('');

  const [arrBot, setArrBot] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState<any>(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [arrSelected, setArrSelected] = useState<Array<any>>([]);

  useEffect(() => {
    loadAgent();
  }, [query]);

  const loadAgent = async (init = true) => {
    if (init) {
      setIsLoading(true);

      const res = await apiGetSuggestAIs({
        type: 'voice',
        query: query,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrBot(res.data);
        setHasMore(res.hasMore);
        setLastId(res.lastId);
      }

      setIsLoading(false);
    } else {
      if (!isLoading && hasMore) {
        setIsLoading(true);

        const res = await apiGetSuggestAIs({
          type: 'voice',
          query: query,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrBot((prev) => [...prev, ...res.data]);
          setHasMore(res.hasMore);
          setLastId(res.lastId);
        }

        setIsLoading(false);
      }
    }
  };

  const onChooseAndCreate = () => {
    if (arrSelected.length > 0) {
      onCreateAIWithVoice(arrSelected[0].voice);
    } else {
    }
  };

  const onSelected = (botItem) => {
    setArrSelected([botItem]);
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 0.5;

    if (bottom) {
      loadAgent(false);
    }
  };

  const renderTitle = () => {
    return (
      <div className="choose_vocie_title font-bold font20">
        Choose a bot to copy
        <br />
        the voice from
      </div>
    );
  };

  const renderSearchBox = () => {
    return (
      <div className="search_view">
        <SearchBox
          onSearch={setQuery}
          height="50px"
          enableShadow={true}
          fontSize={16}
        />
      </div>
    );
  };

  const renderBotList = () => {
    return (
      <div className="bot_list" onScroll={handleScroll}>
        <div className="bot_list_body">
          {arrBot.map((item, index) => {
            var isSelected =
              arrSelected.filter((sItem, index) => sItem.id === item.id)
                .length > 0;

            return (
              <MobileCardChooseVoice
                key={`mobile_card_choose_bot_${index}`}
                isSelected={isSelected}
                data={item}
                onPressed={onSelected}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="bottom_container">
        {renderCreateBtn()}
        <div className="skip_btn">
          {isSkipingAndCompleting ? (
            <LoadingCircular color="main" size={16} />
          ) : (
            <div
              className="skip_btn_txt font-bold font14"
              onClick={onSkipAndCreateAI}
            >
              Skip Voice and Create AI
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderCreateBtn = () => {
    return (
      <PrimaryButton
        label="Choose voice and create AI"
        isLoading={isCreating}
        onPressed={() => {
          onChooseAndCreate();
        }}
      />
    );
  };

  return (
    <div className="mobile_choose_voice">
      {renderTitle()}
      {renderSearchBox()}
      {renderBotList()}
      {renderBottom()}
    </div>
  );
};
