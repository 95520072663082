import React from 'react';

import './index.styles.scss';

export const CardJoinedGroup = ({ index, data, isSelected, onSelected }) => {
  return (
    <div
      className="mobile_card_joined_group row_align_items"
      style={
        isSelected
          ? {
              backgroundColor: 'rgba(255, 230, 242, 0.5)',
              border: '1px solid #FF3E9A'
            }
          : {}
      }
      onClick={() => {
        onSelected(data);
      }}
    >
      {data.url && data.url.startsWith('https') ? (
        <img src={data.url} className="group_image" />
      ) : (
        <div className="group_image"></div>
      )}
      <div style={{ marginLeft: 8, width: 'calc(100% - 60px)' }}>
        <div className="group_name font-bold font14">{data.name}</div>
        <div className="font-medium font12" style={{ opacity: 0.4 }}>
          {data.members} members
        </div>
      </div>
    </div>
  );
};
