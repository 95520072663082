import AudioPauseIcon from 'assets/svgs/AudioPauseIcon';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useState } from 'react';
import ReactHowler from 'react-howler';
import { useAudioContext } from 'shared/contexts/AudioContext';

import './index.styles.scss';

export const MobileCardExplorePeople = ({ data, onPlayed }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const { playingAudio, setPlayingAudio } = useAudioContext();

  useEffect(() => {
    if ((playingAudio ?? '') === data.audio) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [playingAudio]);

  const togglePlayback = () => {
    if (isPlaying) {
      setPlayingAudio(null);
    } else {
      if (playingAudio) {
        setPlayingAudio(null);
      }
      setPlayingAudio(data.audio);
      onPlayed(data.id);
    }
  };

  const renderPlayBtn = () => {
    if (data.audio) {
      return (
        <div className="audio_play_btn" onClick={togglePlayback}>
          {isPlaying ? (
            <AudioPauseIcon color="white" size={12} />
          ) : (
            <AudioPlayIcon color="white" size={12} />
          )}
          {isPlaying && (
            <ReactHowler
              src={data.audio}
              playing={isPlaying}
              loop={false}
              html5={true} // Use HTML5 Audio API
              onEnd={() => setPlayingAudio(null)}
            />
          )}
        </div>
      );
    } else {
      return <div />;
    }
  };

  return (
    <div className="mobile_card_explore_people">
      <div className="user_image_container">
        {data.creator_image && data.creator_image.startsWith('https') ? (
          <img src={data.creator_image} className="user_image" />
        ) : (
          <DefaultAvatarIcon size={70} />
        )}
        {renderPlayBtn()}
      </div>

      <div className="user_name font-bold font14">{data.creator_name}</div>
    </div>
  );
};
