import React from 'react';

function ReadCheckIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 10}
      height={props.size ?? 8}
      fill="none"
      viewBox="0 0 10 8"
    >
      <path
        fill={"black"}
        stroke={props.color ?? "#99D870"}
        d="M8.8801 1.39817L8.88131 1.39696C8.8968 1.38133 8.91524 1.36893 8.93554 1.36047C8.95585 1.35201 8.97764 1.34766 8.99964 1.34766C9.02164 1.34766 9.04343 1.35201 9.06374 1.36047C9.08405 1.36893 9.10248 1.38133 9.11797 1.39696L9.47297 1.04486L9.13394 1.41236C9.15086 1.42796 9.16436 1.4469 9.17359 1.46799C9.18283 1.48907 9.1876 1.51184 9.1876 1.53486C9.1876 1.55787 9.18283 1.58064 9.17359 1.60172C9.16436 1.62281 9.15086 1.64175 9.13394 1.65736L9.12654 1.66418L9.11942 1.6713L3.67942 7.1113L3.67942 7.1113L3.67797 7.11275C3.66248 7.12838 3.64405 7.14077 3.62374 7.14924C3.60343 7.1577 3.58165 7.16205 3.55964 7.16205C3.53764 7.16205 3.51585 7.1577 3.49555 7.14924C3.47523 7.14077 3.4568 7.12838 3.44131 7.11275L3.44131 7.11275L3.43986 7.1113L0.879861 4.5513L0.879914 4.55125L0.873692 4.54524C0.85657 4.5287 0.842873 4.50895 0.833384 4.48712C0.823894 4.46528 0.818798 4.44179 0.818386 4.41799C0.817975 4.39419 0.822255 4.37054 0.830984 4.34839C0.839712 4.32624 0.852718 4.30603 0.869257 4.28891C0.885798 4.27178 0.905548 4.25809 0.927381 4.2486C0.949214 4.23911 0.972702 4.23401 0.996505 4.2336C1.02031 4.23319 1.04396 4.23747 1.06611 4.2462C1.08726 4.25453 1.10665 4.26677 1.12326 4.28226L3.20552 6.37118L3.55931 6.7261L3.91343 6.3715L8.8801 1.39817Z"
      />
    </svg>
  );
}

export default ReadCheckIcon;
