import HttpClient from './apiClient';

export async function apiSendInviteByEmail(params: { emails: Array<string> }) {
  try {
    const response = await HttpClient.postWithToken(
      `utils/inviteByEmail`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserInvitation(params: {
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`utils/invites`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetInvitationStats() {
  try {
    const response = await HttpClient.getWithToken(`utils/stats`, {});
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiRegisterWaitList(params: { email: string }) {
  try {
    const response = await HttpClient.postWithToken(`utils/waitlist`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
