import { MobilePromptAddBot } from 'components/Mobile/Chat/PromptAddBot';
import React from 'react';
import Sheet from 'react-modal-sheet';

import './index.styles.scss';

interface Props {
  roomId: any;
  open: boolean;
  currentBots: Array<any>;
  onClose: () => void;
  onSuccess: (newData) => void;
}

export const MobileAddBotModal = ({
  roomId,
  open,
  currentBots,
  onClose,
  onSuccess
}: Props) => {
  return (
    <Sheet isOpen={open} className="custom_add_bot_modal" onClose={onClose}>
      <Sheet.Container className="bot_modal_body">
        <Sheet.Content>
          <div className="mobile_chat_add_bot_modal">
            <MobilePromptAddBot
              open={open}
              roomId={roomId}
              currentBots={currentBots}
              onClose={onClose}
              onSuccess={onSuccess}
            />
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
