import React from 'react';

function CloseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 24}
      height={props.size ?? 24}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g opacity={props.opacity ?? 1}>
        <path
          stroke={props.color ? props.color : '#000'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M18 6L6 18M6 6l12 12"
        />
      </g>
    </svg>
  );
}

export default CloseIcon;
