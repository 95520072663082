import AngleRightIcon from 'assets/svgs/AngleRightIcon';
import BuildingIcon from 'assets/svgs/BuildingIcon';
import CloseIcon from 'assets/svgs/CloseIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import React, { useState } from 'react';
import Sheet from 'react-modal-sheet';
import { useNavigate } from 'react-router-dom';
import { apiDeleteChatAgent } from 'shared/api/chat';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { showToast } from 'shared/ui-kit/PrimaryToast';

import './index.styles.scss';

interface Props {
  open: boolean;
  roomId: any;
  aiId: any;
  promptTxt: string;
  isAgentCreator: boolean;
  enableDelete: boolean;
  onClose: () => void;
  onDeleteFromUI: (aiId) => void;
}

export const MobilePromptActionModal = ({
  open,
  roomId,
  aiId,
  promptTxt,
  isAgentCreator,
  enableDelete = true,
  onDeleteFromUI,
  onClose
}: Props) => {
  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = async () => {
    if (!isDeleting) {
      setIsDeleting(true);

      const res = await apiDeleteChatAgent({
        room: roomId,
        agent: aiId
      });

      setIsDeleting(false);

      if (res.success) {
        showToast('success', 'Agent removed from chat!');
        onDeleteFromUI(aiId);
        onClose();
      }
    }
  };

  const onChangePrompt = () => {
    onClose();
    navigate(`/prompt/${aiId}`);
  };

  const renderTop = () => {
    return (
      <div className="header_view">
        <div className="row_align_items">
          <div onClick={onClose} style={{ margin: '8px 0px' }}>
            <CloseIcon color={'rgba(0, 0, 0, 0.3)'} size={20} />
          </div>

          <div className="prompt_title font-bold font18">Current Prompt</div>
        </div>

        <div className="prompt_content">
          <div className="font-regular font14">{promptTxt}</div>
        </div>
        <div className="divider"></div>
      </div>
    );
  };

  const renderAction = () => {
    return (
      <div className="action_view">
        {enableDelete && (
          <div
            className="action_btn row_align_items row_space_between"
            onClick={onDelete}
          >
            <div className="left_view row_align_items">
              {isDeleting ? (
                <LoadingCircular color="main" size={16} />
              ) : (
                <BuildingIcon size={16} />
              )}
              <div className="vertical_divider"></div>
              <div className="font-regular font16">Delete AI</div>
            </div>
            <div>
              <AngleRightIcon color={'#FF3E9A'} opacity={1} />
            </div>
          </div>
        )}
        {isAgentCreator && (
          <div
            className="action_btn row_align_items row_space_between"
            onClick={onChangePrompt}
          >
            <div className="left_view row_align_items">
              <RobotIcon size={16} />
              <div className="vertical_divider"></div>
              <div className="font-regular font16">Change Prompt</div>
            </div>
            <div>
              <AngleRightIcon color={'#FF3E9A'} opacity={1} />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Sheet isOpen={open} onClose={onClose} detent={'content-height'}>
      <Sheet.Container
        style={{ borderTopLeftRadius: 32, borderTopRightRadius: 32 }}
      >
        <Sheet.Content>
          <div className="mobile_prompt_action_modal">
            <div
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                height: '4px',
                width: '56px',
                borderRadius: 4
              }}
            />
            {renderTop()}
            {renderAction()}
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
