import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import { QuestionAskBar } from 'components/Main/Organisation/components/QuestionAskBar';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sendAnalytics } from 'shared/utils/analytics';
import { AllEvent } from './components/AllEvent';
import { EventAttending } from './components/EventAttending';
import { EventHosted } from './components/EventHosted';

import './index.styles.scss';

export const MobileEventHome = () => {
  const navigate = useNavigate();
  const params = useParams();

  const orgId = params.id;
  const [searchTxt, setSearchTxt] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  useEffect(() => {
    sendAnalytics('Events Mobile');
  }, []);

  const onSearch = (question) => {
    setSearchTxt(question);
  };

  const renderAskBar = () => {
    return (
      <div className="row_align_items search_view">
        <QuestionAskBar
          onSearch={onSearch}
          onRealTimeSearch={onSearch}
          onFocus={() => {
            setIsSearchFocused(true);
          }}
          onClose={() => {
            setIsSearchFocused(false);
            setSearchTxt('');
          }}
          width="100%"
          height="56px"
          backgroundColor="#F1F6F9"
          placeholder="Discover new events here..."
          fontSize={20}
          searchIconClickable={true}
          showButton={false}
          showCloseButton={isSearchFocused}
        />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="events_body">
        <EventHosted orgId={orgId} />
        <EventAttending orgId={orgId} />
        <AllEvent orgId={orgId} searchTxt={''} isFocusedSearch={false} />
      </div>
    );
  };

  const renderSearchBody = () => {
    if (searchTxt === '') {
      return <div className="events_body"></div>;
    } else {
      return (
        <div className="events_body">
          <AllEvent
            orgId={orgId}
            searchTxt={searchTxt}
            isFocusedSearch={true}
          />
        </div>
      );
    }
  };

  return (
    <div className="mobile_event_home">
      {orgId && (
        <div
          className="back_btn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <BlackBackIcon />
        </div>
      )}
      {renderAskBar()}
      {isSearchFocused ? renderSearchBody() : renderBody()}
    </div>
  );
};
