import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { LinkVerifying } from 'components/Auth/LinkVerifying';
import { LoginScreen } from 'components/Auth/Login';
import { MagicLinkScreen } from 'components/Auth/MagicLink';

import { LinkNames } from './constants';
import { DeviceSize } from 'shared/utils/constants';
import { MobileLoginHome } from 'components/Auth/Mobile/LoginHome';
import { MobileLoginWithQR } from 'components/Auth/Mobile/LoginWithQR';
import { RegisterMagicLink } from 'components/Auth/RegisterMagicLink';
import { WaitingList } from 'components/Auth/WaitingList';
import { SuccessWaitingList } from 'components/Auth/WaitingList/SuccessWaitingList';
import { MobileSuccessWaitingList } from 'components/Auth/WaitingList/MobileSuccessWaitingList';
import { MobileLoginMagicLink } from 'components/Auth/Mobile/LoginMagicLink';

export const AuthRoutes = () => {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  return (
    <Routes>
      {/* <Route
        path={LinkNames.magic_link}
        element={isMobile ? <MobileLoginHome /> : <MagicLinkScreen />}
      /> */}
      <Route
        path={LinkNames.magic_link}
        element={isMobile ? <MobileLoginMagicLink /> : <MagicLinkScreen />}
      />
      <Route path={LinkNames.qr_verify} element={<MobileLoginWithQR />} />
      <Route path={LinkNames.login} element={<LoginScreen />} />
      <Route path={LinkNames.link_verify} element={<LinkVerifying />} />
      <Route path={LinkNames.register_magic} element={<RegisterMagicLink />} />
      <Route path={LinkNames.waiting_list} element={<WaitingList />} />
      <Route
        path={LinkNames.waiting_success}
        element={
          isMobile ? <MobileSuccessWaitingList /> : <SuccessWaitingList />
        }
      />
    </Routes>
  );
};
