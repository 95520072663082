import HttpClient from './apiClient';

export async function apiGetSuggestFeed(params: {
  types?: any;
  topics?: any;
  item_ids?: any;
  item_types?: any;
  dumps?: any;
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`suggestion/feed`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetSuggestAIs(params: {
  query?: any;
  type?: any;
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `suggestion/personalAIs`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetSuggestionSubscriptions(params: {
  query?: any;
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `suggestion/subscriptions`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetSuggestPersonas(params: {
  query?: any;
  type?: any;
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `suggestion/personas`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetSuggestTopic(params: {
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `suggestion/feedTopics`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetFeedCollective(params: {
  query?: any;
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `suggestion/feedCollective`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetFeedCreator(params: {
  query?: any;
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `suggestion/feedCreators`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetFeedDocs(params: {
  query?: any;
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `suggestion/feedDocs`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiAddSuggestionSearch(params: {
  item_id?: any;
  item_type?: any;
}) {
  try {
    const response = await HttpClient.postWithToken(
      `suggestion/search`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetSuggestionSearches(params: {
  item_type?: any;
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `suggestion/searches`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
