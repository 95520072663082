import React from 'react';

function QrCodeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ? props.size : '16'}
      height={props.size ? props.size : '16'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_1_180)">
        <path
          fill={props.color ?? '#FF3E9A'}
          d="M5.333 14H2.667A.666.666 0 012 13.333v-2.666a.667.667 0 10-1.333 0v2.666a2 2 0 002 2h2.666a.667.667 0 100-1.333zm9.334-4a.666.666 0 00-.667.667v2.666a.667.667 0 01-.667.667h-2.666a.666.666 0 100 1.333h2.666a2 2 0 002-2v-2.666a.666.666 0 00-.666-.667zM13.333.667h-2.666a.667.667 0 100 1.333h2.666a.667.667 0 01.667.667v2.666a.667.667 0 101.333 0V2.667a2 2 0 00-2-2zM1.333 6A.667.667 0 002 5.333V2.667A.667.667 0 012.667 2h2.666a.667.667 0 000-1.333H2.667a2 2 0 00-2 2v2.666A.667.667 0 001.333 6zm5.334-2.667H4A.667.667 0 003.333 4v2.667A.667.667 0 004 7.333h2.667a.667.667 0 00.666-.666V4a.667.667 0 00-.666-.667zM6 6H4.667V4.667H6V6zm3.333 1.333H12a.667.667 0 00.667-.666V4A.667.667 0 0012 3.333H9.333A.667.667 0 008.667 4v2.667a.667.667 0 00.666.666zM10 4.667h1.333V6H10V4.667zm-3.333 4H4a.667.667 0 00-.667.666V12a.667.667 0 00.667.667h2.667A.667.667 0 007.333 12V9.333a.667.667 0 00-.666-.666zM6 11.333H4.667V10H6v1.333zm3.333-.666A.667.667 0 0010 10a.667.667 0 000-1.333h-.667a.667.667 0 00-.666.666V10a.667.667 0 00.666.667zm2.667-2a.666.666 0 00-.667.666v2a.667.667 0 000 1.334H12a.667.667 0 00.667-.667V9.333A.667.667 0 0012 8.667zm-2.667 2.666a.666.666 0 100 1.333.666.666 0 000-1.333z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1_180">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default QrCodeIcon;
