import React from 'react';

function CommentMedicalIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '16'}
      height={props.size ?? '16'}
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#FF3E9A"
        d="M8.002 1.836a6.667 6.667 0 00-6.667 6.667 6.593 6.593 0 001.507 4.22l-1.333 1.333a.667.667 0 00-.14.727.666.666 0 00.633.386h6a6.667 6.667 0 000-13.333zm0 12H3.609l.62-.62a.667.667 0 000-.94 5.333 5.333 0 113.773 1.56zm1.333-6H8.67v-.667a.667.667 0 00-1.334 0v.667H6.67a.667.667 0 000 1.333h.666v.667a.667.667 0 001.334 0v-.667h.666a.667.667 0 000-1.333z"
      ></path>
    </svg>
  );
}

export default CommentMedicalIcon;
