import React from 'react';

function ExitFullScreenIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '800'}
      height={props.size ?? '800'}
      version="1.1"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
    >
      <g fill={props.color ?? '#030104'}>
        <path d="M24.586 27.414L29.172 32 32 29.172 27.414 24.586 32 20 20 20 20 32z"></path>
        <path d="M0 12L12 12 12 0 7.414 4.586 2.875 0.043 0.047 2.871 4.586 7.414z"></path>
        <path d="M0 29.172L2.828 32 7.414 27.414 12 32 12 20 0 20 4.586 24.586z"></path>
        <path d="M20 12L32 12 27.414 7.414 31.961 2.871 29.133 0.043 24.586 4.586 20 0z"></path>
      </g>
    </svg>
  );
}

export default ExitFullScreenIcon;
