import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React from 'react';

import './index.styles.scss';

export interface AiData {
  ai_id: number;
  ai_name: string;
  ai_image?: string;
  ai_type: 'user' | 'group' | 'organisation';
}

interface CardPersonAiProps {
  data: AiData;
  onSelected: (aiInfo) => void;
}

export const CardPersonAi = ({ data, onSelected }: CardPersonAiProps) => {
  const renderSelectBtn = () => {
    return (
      <div
        className="select_btn clickable"
        onClick={() => {
          onSelected(data);
        }}
      >
        <div className="font-bold font12" style={{ color: 'white' }}>
          Select
        </div>
      </div>
    );
  };

  return (
    <div className="card_person_ai">
      {data.ai_image && data.ai_image.startsWith('https') ? (
        <img src={data.ai_image} className="card_person_ai_image" />
      ) : (
        <div className="card_person_ai_image">
          <DefaultAvatarIcon size={48} />
        </div>
      )}
      <div className="card_person_ai_info">
        <div className="font-bold font14">{data.ai_name}</div>
      </div>
      {renderSelectBtn()}
    </div>
  );
};
