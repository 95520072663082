import * as htmlToImage from 'html-to-image';
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas';

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validURL = (str) => {
  try {
    const newUrl = new URL(str);
    return newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
};

export const getArrFromString = (str, keyWord = ';') => {
  if (str) {
    if (typeof str === 'string') return str.split(keyWord);

    return ['getArrFromString Error'];
  } else {
    return [];
  }
};

export const removeFirstBlank = (str) => {
  return str?.trimStart();
};

export const getNormalString = (str) => {
  return str.replace(/([A-Z])/g, ' $1').trim();
};

export const convertFirstToUpper = (str) => {
  if (str) {
    return str?.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return str;
  }
};

export const replaceToBr = (str) => {
  if (str) {
    return str.replaceAll('\n', '<br>');
  }
};

export const getImageFromHtml = async (elementId) => {
  var node = document.getElementById(elementId)!;
  console.log({ node });
  try {
    const base64Image = await htmlToImage.toPng(node, { cacheBust: true });
    return base64Image;
  } catch (error) {
    console.error('oops, something went wrong!', error);
    return null;
  }

  // domtoimage.toBlob(node).then(function (blob) {
  //   console.log({ blob });
  // });
};

export const testHtmlCanvas = async (elementId) => {
  var node = document.getElementById(elementId)!;

  const res = await html2canvas(node, { useCORS: true });

  var data = res.toDataURL();
  return data;
};

export const getFileExtenstion = (filename) => {
  if (typeof filename === 'string') return filename.split('.').pop();

  return 'getFileExtenstion error';
};

export default function objectToGetParams(object: {
  [key: string]: string | number | undefined | null;
}) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    );

  return params.length > 0 ? `?${params.join('&')}` : '';
}

export const getWindowWidth = () => {
  return window.innerWidth;
};

export const onTranscribe = async (blob: Blob) => {
  const base64 = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
  const body = JSON.stringify({ file: base64, model: 'whisper-1' });
  // console.log('Base64 recording: ', base64);
  const headers = { 'Content-Type': 'application/json' };
  const { default: axios } = await import('axios');
  const response = await axios.post(
    'https://webhook.site/90bbf81d-b51b-424e-83bc-d957e4c9797c',
    body,
    {
      headers
    }
  );
  const { text } = await response.data;
  // you must return result from your server in Transcript format
  return {
    blob,
    text
  };
};
