import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import RoundBackIcon from 'assets/svgs/RoundBackIcon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import TextareaAutosize from 'react-textarea-autosize';

import './index.styles.scss';
import PlusIcon from 'assets/svgs/PlusIcon';
import CloseIcon from 'assets/svgs/CloseIcon';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { apiCreateContentPoll } from 'shared/api/content';
import { showToast } from 'shared/ui-kit/PrimaryToast';

const MAX_POLL_COUNT = 5;
const MIN_POLL_COUNT = 2;

export const MobileCreatePoll = () => {
  const navigate = useNavigate();

  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [selectedCreator, setSelectedCreator] = useState<'owner' | 'anyo'>(
    'owner'
  );
  const [question, setQuestion] = useState('');
  const [arrOption, setArrOption] = useState<Array<any>>(['', '']);
  const [isCreating, setIsCreating] = useState(false);

  const onCreate = async () => {
    if (!isCreating && question !== '') {
      if (arrOption.filter((item, index) => item === '').length > 0) {
        showToast('error', 'Please complete your options');
        return;
      }

      setIsCreating(true);

      const choices = arrOption.map((item, index) => {
        return {
          name: item
        };
      });

      const res = await apiCreateContentPoll({
        item_id: userInfo?.id,
        item_type: 'user',
        question: question,
        choices: choices,
        is_anonymous: selectedCreator === 'anyo' ? true : false
      });

      if (res.success) {
        showToast(
          'success',
          'Success! Your poll has been created successfully'
        );
        navigate(-1);
      }

      setIsCreating(false);
    }
  };

  const renderHeader = () => {
    return (
      <div className="poll_header row_align_items">
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <RoundBackIcon size={36} color="#FF3E9A" />
        </div>
        <div className="poll_header_title font-bold font16">Create Polling</div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="poll_body">
        {renderInputView()}
        {renderActionView()}
      </div>
    );
  };

  const renderInputView = () => {
    return (
      <div className="poll_input">
        <TextareaAutosize
          value={question}
          placeholder="Enter question here..."
          className={`title_input font-bold font24`}
          onChange={(e) => {
            setQuestion(e.target.value);
          }}
        />
        <div className="option_list">
          {arrOption.map((item, index) => {
            return (
              <div
                key={`option_card_${index}`}
                className="card_option row_align_items"
              >
                <div className="mark_view">
                  <div className="font-medium font10" style={{ opacity: 0.6 }}>
                    {(index + 10).toString(36).toUpperCase()}
                  </div>
                </div>
                <div className="card_option_text">
                  <TextareaAutosize
                    value={item}
                    placeholder="Enter option here..."
                    className={`option_title_input font-medium font14`}
                    onChange={(e) => {
                      setArrOption((prev) =>
                        prev.map((pItem, pIndex) => {
                          if (pIndex == index) {
                            return e.target.value;
                          } else {
                            return pItem;
                          }
                        })
                      );
                    }}
                  />
                </div>
                {!(index < MIN_POLL_COUNT) && (
                  <div
                    onClick={() => {
                      setArrOption((prev) =>
                        prev.filter((pItem, pIndex) => pIndex !== index)
                      );
                    }}
                  >
                    <CloseIcon size={16} opacity={0.6} />
                  </div>
                )}
              </div>
            );
          })}

          {arrOption.length < 5 && (
            <div
              className="add_option row_align_items"
              onClick={() => {
                if (arrOption.length < 5) {
                  setArrOption((prev) => [...prev, '']);
                }
              }}
            >
              <div className="plus_btn">
                <PlusIcon size={10} color="white" />
              </div>
              <div className="font-regular font14" style={{ opacity: 0.4 }}>
                Add more options
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderActionView = () => {
    return (
      <div className="poll_action row_align_items">
        <div
          className={
            selectedCreator === 'owner'
              ? `selected_creator_image`
              : `creator_image`
          }
          onClick={() => setSelectedCreator('owner')}
        >
          {userInfo && userInfo.image && userInfo.image.startsWith('https') ? (
            <img
              src={userInfo.image}
              style={{
                width: 28,
                height: 28,
                borderRadius: 28,
                objectFit: 'cover'
              }}
            />
          ) : (
            <DefaultAvatarIcon size={32} />
          )}
        </div>
        <div
          className={
            selectedCreator === 'anyo'
              ? `selected_creator_image`
              : `creator_image`
          }
          onClick={() => setSelectedCreator('anyo')}
        >
          <DefaultAvatarIcon size={36} />
        </div>
        <div className="create_btn" onClick={onCreate}>
          {isCreating ? (
            <LoadingCircular color="white" size={16} />
          ) : (
            <div className="create_btn_txt font-bold font12">Publish Poll</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_create_poll">
      {renderHeader()}
      {renderBody()}
    </div>
  );
};
