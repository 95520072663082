import React from "react";

function LightBulbIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size? props.size: "12"}
      height={props.size? props.size: "16"}
      fill="none"
      viewBox="0 0 12 16"
    >
      <path
        fill={props.color? props.color:"#FF3E9A"}
        d="M9.394 1.88A5.333 5.333 0 004.94.773 5.333 5.333 0 00.76 4.987 5.38 5.38 0 001.907 9.42c.47.53.738 1.206.76 1.913v2a2 2 0 002 2h2.667a2 2 0 002-2V11.46c.022-.78.309-1.53.813-2.127a5.333 5.333 0 00-.753-7.466v.013zM8 13.333a.667.667 0 01-.666.667H4.667A.667.667 0 014 13.333v-.666h4v.666zm1.114-4.826A4.753 4.753 0 008 11.333H6.667v-2a.667.667 0 10-1.333 0v2H4A4.333 4.333 0 002.934 8.56a4 4 0 014.02-6.447A4 4 0 0110 6c.005.913-.308 1.8-.886 2.507z"
      ></path>
    </svg>
  );
}

export default LightBulbIcon;
