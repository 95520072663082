import CoinIcon from 'assets/svgs/CoinIcont';
import HorizontalDetailIcon from 'assets/svgs/HorizontalDetailIcon';
import React, { useState } from 'react';
import moment from 'moment';

import './index.styles.scss';
import { MobileQuestionActionModal } from 'components/Modals/Mobile/QuestionActionModal';
import { MobileShareMemeModal } from 'components/Modals/MobileShare/ShareMeme';
import ShareIcon from 'assets/svgs/ShareIcon';
import { useNavigate } from 'react-router-dom';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import MicIcon from 'assets/svgs/MicIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import FileIcon from 'assets/svgs/FileIcon';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import HeartIcon from 'assets/svgs/HeartIcon';
import HeartBreakIcon from 'assets/svgs/HeartBreakIcon';
import { apiContentVoteAI } from 'shared/api/content';

interface Props {
  data: any;
}

export const MobileCardMeme = ({ data }: Props) => {
  const navigate = useNavigate();

  const [showActionModal, setShowActionModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const [userVote, setUserVote] = useState(data.user_like);
  const [agree, setAgree] = useState(data.likes);
  const [doubt, setDoubt] = useState(data.dislikes);

  const onVote = async (vote: number) => {
    const res = await apiContentVoteAI({
      item_id: data.item_id,
      item_type: data.item_type,
      like: vote,
      remove: userVote === vote ? true : false
    });

    if (res.success) {
      const voteData = res.voting;
      setUserVote(voteData.user_like);
      setAgree(voteData.likes);
      setDoubt(voteData.dislikes);
    }
  };

  const onViewProfile = () => {
    if (data.creator_id) {
      navigate(`/profile/${data.creator_id}`);
    }
  };

  const onViewRef = () => {
    if (data.dump_type === 'url' || data.dump_type === 'html') {
      window.open(data.link_url);
    }
  };

  const renderTopView = () => {
    return (
      <div className="meme_top_view row_space_between">
        <div className="row_align_items">
          {data.creator_image && data.creator_image.startsWith('https') ? (
            <img
              src={data.creator_image}
              className="user_avatar"
              onClick={onViewProfile}
            />
          ) : (
            <div className="user_avatar" onClick={onViewProfile}></div>
          )}
          <div style={{ marginLeft: 8 }}>
            <div className="font-bold font10" onClick={onViewProfile}>
              {data.creator_name}
            </div>
            <div className="font-medium font10" style={{ opacity: 0.6 }}>
              {moment(data.timestamp).format('DD MMM')}
            </div>
          </div>
        </div>
        <div className="row_align_items">
          <div className="detail_btn" onClick={() => setShowShareModal(true)}>
            <ShareIcon size={16} color="rgba(0, 0, 0, 0.3)" />
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="meme_body">
        {data.images && data.images.startsWith('https') ? (
          <img src={data.images} className="meme_image" />
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const renderRightAction = () => {
    return (
      <div className="right_action_view">
        <div className="action_item">
          <AudioPlayIcon color="#FF3E9A" />
        </div>
        <div className="vote_divider"></div>
        <div
          className="action_item"
          onClick={() => onVote(1)}
          style={{ marginTop: 16 }}
        >
          <HeartIcon
            color={userVote === 1 ? '#FF3E9A' : 'rgba(0, 0, 0, 0.4)'}
          />
          <div
            className="font-medium font16"
            style={{ color: userVote === 1 ? '#FF3E9A' : 'rgba(0, 0, 0, 0.4)' }}
          >
            {agree}
          </div>
        </div>
        <div
          className="action_item"
          onClick={() => onVote(0)}
          style={{ marginTop: 16 }}
        >
          <HeartBreakIcon
            color={userVote === 0 ? '#000000' : 'rgba(0, 0, 0, 0.4)'}
          />
          <div
            className="font-medium font16"
            style={{ color: userVote === 0 ? '#000000' : 'rgba(0, 0, 0, 0.4)' }}
          >
            {doubt}
          </div>
        </div>
      </div>
    );
  };

  const renderRef = () => {
    return (
      <div className="ref_container row_align_items" onClick={onViewRef}>
        {renderFileIcon(data.dump_type)}
        <div className="ref_text font-regular font10">{data.dump_title}</div>
      </div>
    );
  };

  const renderFileIcon = (type) => {
    return (
      <div style={{ width: 20, height: 20 }} className="align-center">
        {type === 'pdf' ? (
          <PdfFileIcon size={20} color={'rgba(0, 0, 0, 0.3)'} />
        ) : type === 'audio' ? (
          <MicIcon size={20} color={'rgba(0, 0, 0, 0.3)'} opacity={1} />
        ) : type === 'url' || type === 'html' ? (
          <LinkIcon size={20} color={'rgba(0, 0, 0, 0.3)'} opacity={1} />
        ) : (
          <FileIcon size={20} color={'rgba(0, 0, 0, 0.3)'} />
        )}
      </div>
    );
  };

  const renderActionModal = () => {
    return (
      <MobileQuestionActionModal
        open={showActionModal}
        onClose={() => {
          setShowActionModal(false);
        }}
        onSelected={(type) => {
          setShowActionModal(false);

          if (type === 'share') {
            setShowShareModal(true);
          }
        }}
      />
    );
  };

  const renderShareModal = () => {
    return (
      <MobileShareMemeModal
        question={data}
        show={showShareModal}
        onClose={() => {
          setShowShareModal(false);
        }}
      />
    );
  };

  return (
    <div className="mobile_card_meme">
      {renderTopView()}
      {renderRef()}
      {renderBody()}
      {/* {renderRightAction()} */}
      {renderActionModal()}
      {renderShareModal()}
    </div>
  );
};
