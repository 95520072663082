import React from 'react';

function EnvelopAddIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '24'}
      height={props.size ?? '25'}
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        fill="#FF3E9A"
        d="M17 7.5h1v1a1 1 0 002 0v-1h1a1 1 0 100-2h-1v-1a1 1 0 00-2 0v1h-1a1 1 0 100 2zm4 4a1 1 0 00-1 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1V8.91l5.88 5.89a3 3 0 004.24 0l2.47-2.47a1.004 1.004 0 10-1.42-1.42l-2.47 2.47a1 1 0 01-1.4 0L5.41 7.5H13a1 1 0 100-2H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3v-6a1 1 0 00-1-1z"
      ></path>
    </svg>
  );
}

export default EnvelopAddIcon;
