import React from 'react';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';

import './index.styles.scss';

export const MobileInviteUsingContact = () => {
  return (
    <div className="mobile_invite_contact">
      <ContactIcon />
      <div className="font-bold font18" style={{ marginTop: 24 }}>
        Need Access to Your Contact
      </div>
      <div
        className="font-regular font12"
        style={{ marginTop: 8, textAlign: 'center' }}
      >
        Mappy need to be able to access you contacts
        <br />
        information to get your friend lists
      </div>
      <div style={{ marginTop: 24 }}>
        <PrimaryButton label="Open Setting" containerStyle={{ width: 130 }} />
      </div>
    </div>
  );
};

const ContactIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="54"
      fill="none"
      viewBox="0 0 57 54"
    >
      <path
        fill="#000"
        d="M42.843 4.118a26.2 26.2 0 00-4.34-.38 25.88 25.88 0 00-14 4.1 25.88 25.88 0 00-14-4 26.2 26.2 0 00-4.34.38 2 2 0 00-1.66 2v24a2 2 0 002.34 2 21.801 21.801 0 0116.5 3.82l.24.14h.22a1.82 1.82 0 001.4 0h.22l.24-.14a21.8 21.8 0 0116.5-4.04 2.001 2.001 0 002.34-2v-24a2 2 0 00-1.66-1.88zm-20.34 26.58a25.74 25.74 0 00-12-2.96h-2v-20a17.38 17.38 0 012 0 21.72 21.72 0 0112 3.6v19.36zm18-2.88h-2a25.74 25.74 0 00-12 2.96v-19.44a21.72 21.72 0 0112-3.6 17.38 17.38 0 012 0v20.08zm2.34 8.3a26.208 26.208 0 00-4.34-.38 25.88 25.88 0 00-14 4.1 25.88 25.88 0 00-14-4.1 26.208 26.208 0 00-4.34.38 2 2 0 00-1.66 2.3 2 2 0 002.34 1.58 21.801 21.801 0 0116.5 3.82 2 2 0 002.32 0 21.8 21.8 0 0116.5-3.82 2 2 0 002.34-1.58 2 2 0 00-1.66-2.3z"
      ></path>
      <rect width="32" height="32" x="23" y="20" fill="#FF3E9A" rx="16"></rect>
      <path
        fill="#fff"
        d="M43.96 36.666c-.148 0-.3-.046-.448-.08a6.306 6.306 0 01-.873-.26 1.333 1.333 0 00-1.653.667l-.147.3a8.119 8.119 0 01-1.773-1.333 8.12 8.12 0 01-1.334-1.774l.28-.186a1.333 1.333 0 00.667-1.654 6.88 6.88 0 01-.26-.873c-.033-.147-.06-.3-.08-.453a2 2 0 00-2-1.66h-2a2 2 0 00-2 2.273 12.667 12.667 0 0011.013 10.973h.254a2 2 0 001.827-1.185 2 2 0 00.173-.821v-2a2 2 0 00-1.647-1.934zm.332 4a.666.666 0 01-.482.642.7.7 0 01-.29.025 11.333 11.333 0 01-9.808-9.853.726.726 0 01.167-.547.668.668 0 01.5-.227h2a.667.667 0 01.667.527 7.424 7.424 0 00.407 1.573l-.934.434a.665.665 0 00-.327.886 9.66 9.66 0 004.667 4.667.667.667 0 00.507 0 .666.666 0 00.38-.347l.413-.933c.345.123.697.226 1.053.307.178.04.358.073.54.1a.666.666 0 01.527.666l.013 2.08z"
      ></path>
      <rect
        width="32"
        height="32"
        x="23"
        y="20"
        stroke="#fff"
        strokeWidth="4"
        rx="16"
      ></rect>
    </svg>
  );
};
