import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  apiChatAttachPrompt,
  apiGetChatIntroduce,
  apiGetChatPrompt
} from 'shared/api/chat';
import { MobileCardPrompt } from './CardPrompt';

import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import SearchIcon from 'assets/svgs/SearchIcon';

import './index.styles.scss';

export const MobilePrompts = () => {
  const navigate = useNavigate();
  const params = useParams();
  const aiId = params.aiId;

  const [arrPrompt, setArrPrompt] = useState<Array<any>>([]);
  const [lastIdPrompt, setLastIdPrompt] = useState(0);
  const [hasMorePrompt, setHasMorePrompt] = useState(true);
  const [isLoadingPrompt, setIsLoadingPrompt] = useState(false);

  const [roomInfo, setRoomInfo] = useState<any>(null);
  const [selectedPromptId, setSelectedPromptId] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;

    if (bottom) {
      loadPrompts(false);
    }
  };

  useEffect(() => {
    loadRoomInfo();
    loadPrompts();
  }, []);

  const loadPrompts = async (init = true) => {
    if (init) {
      setIsLoadingPrompt(true);

      const res = await apiGetChatPrompt({ query: '', limit: 10, offset: 0 });

      if (res.success) {
        setArrPrompt(res.data);
        setLastIdPrompt(res.lastId);
        setHasMorePrompt(res.hasMore);
        setTotalCount(res.total_count);
      }

      setIsLoadingPrompt(false);
    } else {
      if (!isLoadingPrompt && hasMorePrompt) {
        setIsLoadingPrompt(true);

        const res = await apiGetChatPrompt({
          query: '',
          limit: 10,
          offset: lastIdPrompt
        });

        if (res.success) {
          setArrPrompt((prev) => [...prev, ...res.data]);
          setLastIdPrompt(res.lastId);
          setHasMorePrompt(res.hasMore);
          setTotalCount(res.total_count);
        }

        setIsLoadingPrompt(false);
      }
    }
  };

  const loadRoomInfo = async () => {
    const res = await apiGetChatIntroduce({
      ai_id: aiId,
      ai_type: 'personal'
    });

    if (res.success) {
      setSelectedPromptId(res.data.prompt_id);
      setRoomInfo(res.data);
    }
  };

  const onUpdatePrompt = async (newPromptId) => {
    const res = await apiChatAttachPrompt({
      prompt: newPromptId,
      ai_id: aiId,
      ai_type: 'personal'
    });

    if (res.success) {
      setSelectedPromptId(newPromptId);
    }
  };

  const onRemoveFromUI = (promptId) => {
    setArrPrompt((prev) => prev.filter((item, index) => item.id != promptId));
  };

  const onBack = () => {
    navigate(-1);
  };

  const onAddMore = () => {
    navigate(`/prompt/create/${aiId}`);
  };

  const onSearch = () => {
    navigate(`/prompt/search/${aiId}`);
  };

  const onSelected = (botItem) => {
    onUpdatePrompt(botItem.id);
  };

  const renderHeader = () => {
    return (
      <div className="row_align_items row_space_between">
        <div className="row_align_items" style={{ gap: 16 }}>
          <div onClick={onBack}>
            <BlackBackIcon />
          </div>
          {roomInfo?.ai_image && roomInfo?.ai_image.startsWith('https') ? (
            <img src={roomInfo?.ai_image} className="bot_image" />
          ) : (
            <DefaultAvatarIcon size={40} />
          )}
          {roomInfo && (
            <div>
              <div className="personal_txt font-bold font14">Personal AI</div>
              <div className="row_align_items">
                <div className="font-bold font16">{roomInfo.ai_name}</div>
              </div>
            </div>
          )}
        </div>
        <div className="done_btn font-regular font14" onClick={onBack}>
          Done
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="content_container">
        <div className="font-bold font16">Prompts ({totalCount})</div>
        <div className="content_list" onScroll={handleScroll}>
          {arrPrompt.map((item, index) => {
            var isSelected = item.id === selectedPromptId;

            return (
              <MobileCardPrompt
                key={`mobile_card_prompt_${index}`}
                data={item}
                isSelected={isSelected}
                onPressed={onSelected}
                onRemoveFromUI={onRemoveFromUI}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="bottom_view">
        <div className="action_btn" onClick={onSearch}>
          <SearchIcon size={16} color={'black'} />
          <div className="font-bold font14">Search</div>
        </div>
        <div className="action_btn" onClick={onAddMore}>
          <PlusIcon size={16} color={'black'} />
          <div className="font-bold font14">Add Prompts</div>
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_prompts">
      {renderHeader()}
      {renderBody()}
      {renderBottom()}
    </div>
  );
};
