import BuildingIcon from 'assets/svgs/BuildingIcon';
import React from 'react';
import FilterCheckIcon from '../../TypeFilter/svgs/FilterCheckIcon';

import JoinedGroupPng from 'assets/pngs/JoinedGroupPng.png';
import JoinOrgPng from 'assets/pngs/OrgPng.png';

import './index.styles.scss';

interface Props {
  data: any;
  isSelected: boolean;
  onSelected: (item) => void;
}

export const MobileCardFeedCollectFilter = ({
  data,
  isSelected,
  onSelected
}: Props) => {
  return (
    <div className="mobile_card_feed_collect_filter">
      <div
        className={`${
          isSelected ? 'selected' : 'default'
        }_filter_item row_align_items row_space_between`}
        onClick={() => {
          onSelected({
            type: 'collective',
            id: data.ai_id,
            item_type: data.ai_type
          });
        }}
      >
        <div className="row_align_items">
          <img
            src={data.ai_id === -1 ? JoinedGroupPng : data.ai_image}
            className={'filter_image'}
          />
          <div className="font-regular font14" style={{ marginLeft: 6 }}>
            {data.ai_name}
          </div>
        </div>
        {isSelected ? (
          <FilterCheckIcon />
        ) : (
          <div className="default_mark"></div>
        )}
      </div>
    </div>
  );
};
