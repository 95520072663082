import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiGetUserVoiceCloned } from 'shared/api/user';
import CustomCircleProgressBar from 'shared/ui-kit/CircleProgressBar';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';

import './index.styles.scss';

interface Props {
  isCompleting: boolean;
  onComplete: () => void;
}

export const MobileCompleteBotCreation = ({
  isCompleting,
  onComplete
}: Props) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [profileAudio, setProfileAudio] = useState<any>(null);
  const [voiceClonePro, setVoiceClonePro] = useState(0);

  useEffect(() => {
    if (userInfo) {
      loadVoiceClone();
    }
  }, [userInfo]);

  const loadVoiceClone = async () => {
    const res = await apiGetUserVoiceCloned({
      userId: userInfo!.id
    });

    if (res.success) {
      setVoiceClonePro(res.percentage);
      setProfileAudio(res.audio?.audio);
    }
  };

  const progressBarColor = useMemo(() => {
    if (!!profileAudio) {
      return '#D83EFF';
    } else {
      if (voiceClonePro < 0.25) {
        return '#F24F4F';
      } else if (voiceClonePro >= 0.25 && voiceClonePro < 0.5) {
        return '#EC8362';
      } else if (voiceClonePro >= 0.5 && voiceClonePro < 1) {
        return '#ECAF39';
      } else {
        return '#22D654';
      }
    }
  }, [voiceClonePro, profileAudio]);

  const renderTop = () => {
    return (
      <div className="top_view_container">
        <div className="round_out">
          <div className="round_out">
            <div className="round_center">
              <CustomCircleProgressBar
                progress={!!profileAudio ? 100 : voiceClonePro * 100}
                range={{ from: 0, to: 100 }}
                sign={{ value: '%', position: 'end' }}
                text={'Match'}
                showMiniCircle={true}
                showValue={true}
                size={120}
                sx={{
                  strokeColor: progressBarColor,
                  barWidth: 5,
                  shape: 'full',
                  strokeLinecap: 'round',
                  loadingTime: 1000,
                  miniCircleColor: progressBarColor,
                  miniCircleSize: 7,
                  valueAnimation: true,
                  intersectionEnabled: true
                }}
              />
              <img src={userInfo?.image} className="user_image" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMainBody = () => {
    return (
      <div className="creation_body">
        {renderTop()}
        <div className="font-regular font12" style={{ textAlign: 'center' }}>
          You can get more of your voice cloned with creating voice notes from
          your profile talking to any AI in the chat asking questions on Q&A in
          your voice
        </div>
        {renderCompleteBtn()}
      </div>
    );
  };

  const renderCompleteBtn = () => {
    return (
      <PrimaryButton
        label="Attach Voice"
        isLoading={isCompleting}
        onPressed={onComplete}
      />
    );
  };

  return <div className="mobile_complete_bot_creation">{renderMainBody()}</div>;
};
