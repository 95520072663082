import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { apiUserRequestMagicLink } from 'shared/api/user';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import { sendAnalytics } from 'shared/utils/analytics';

import './index.styles.scss';

const RESEND_TIME = 60;

export const MagicLinkScreen = () => {
  const navigate = useNavigate();
  const [isSent, setIsSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [email, setEmail] = useState('');

  let timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [resendTime, setResendTime] = useState(RESEND_TIME);

  useEffect(() => {
    sendAnalytics('Loign');
  }, []);

  useEffect(() => {
    onCheck();
  }, [resendTime]);

  const onCheck = () => {
    if (resendTime == 0) {
      if (timerRef) {
        clearInterval(timerRef?.current!);
        setResendTime(RESEND_TIME);
      }
    }
  };

  const onStartCounter = () => {
    if (resendTime > 0) {
      timerRef.current = setInterval(() => {
        setResendTime((prev) => prev - 1);
      }, 1000);
    }
  };

  const onEnter = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      onSend();
    }
  };

  const onSend = async () => {
    if (resendTime === RESEND_TIME && email !== '') {
      setIsSending(true);

      const res = await apiUserRequestMagicLink({
        email: email.replaceAll(' ', '')
      });
      if (res.success) {
        onStartCounter();

        if (res.is_authorized) {
          setIsSent(true);
        } else {
          onWaitListSuccess();
        }
      } else {
        showToast('error', 'Magic link expired please request again');
      }

      setIsSending(false);
    }
  };

  const onWaitListSuccess = () => {
    navigate('/waitingsuccess');
  };

  const onWaitList = () => {
    navigate('/waitingList');
  };

  const onAcceptTerm = () => {
    window.open(process.env.REACT_APP_TERMS_URL);
  };

  const renderRegister = () => {
    return (
      <div>
        <div className="register_input">
          <div className="your_email font10 font-bold">Your Email</div>
          <div>
            <input
              value={email}
              placeholder="Enter your email here..."
              className="email_input font-bold font16"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onKeyUp={(e) => {
                onEnter(e);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSendBtn = () => {
    return (
      <div className="send_email_btn">
        <PrimaryButton
          label="Send Me a Magic Link"
          isLoading={isSending}
          onPressed={onSend}
        />
      </div>
    );
  };

  const renderReSendBtn = () => {
    return (
      <div className="send_email_btn">
        <PrimaryButton
          label={resendTime == 60 ? `Resend` : `Resend (${resendTime}s)`}
          isLoading={isSending}
          onPressed={onSend}
        />
      </div>
    );
  };

  const renderSentSuccess = () => {
    return (
      <div style={{ marginTop: -44 }}>
        <div style={{ fontSize: 88, margin: `0 auto 0`, textAlign: 'center' }}>
          📬
        </div>
        <div className="title font-bold font24" style={{ marginTop: 22 }}>
          Woohoo, we’ve sent you a magic link to log in
        </div>
        <div
          className="font-regular font16"
          style={{
            color: 'rgba(0, 0, 0, 0.6)',
            marginTop: 12,
            textAlign: 'center'
          }}
        >
          Check your email app for the link
          <br />
          (and spam if not there)
        </div>
        {renderReSendBtn()}
        {renderHavingProblem()}
      </div>
    );
  };

  const renderHavingProblem = () => {
    return (
      <div className="already_registered">
        <div className="font14 font-regular" style={{ textAlign: 'center' }}>
          Want to let us know what you love or any issue you
          have?&nbsp;&nbsp;Write us anytime&nbsp;
          <span className="check_referral font14 font-bold">
            {`${process.env.REACT_APP_SUPPORT_EMAIL}`}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="content_body">
      {!isSent ? (
        <div>
          <div className="align-center">
            <img
              src="/logo512.png"
              width={50}
              height={50}
              style={{ objectFit: 'contain' }}
            />
          </div>
          <div className="title font-bold">Login with Magic Link</div>
          <div className="register">
            {renderRegister()}
            {renderSendBtn()}

            <div className="accept_term font-regular font14">
              Don’t have an account yet?
              <span
                className="font-bold font14 clickable"
                style={{ marginLeft: 4, color: '#FF3E9A' }}
                onClick={onWaitList}
              >
                Register for Waitlist
              </span>
            </div>
          </div>
          <div className="have_account font-regular font14">
            By using the platform I accept
            <span
              className="font-bold font14 clickable"
              style={{ marginLeft: 4, color: '#FF3E9A' }}
              onClick={onAcceptTerm}
            >
              all terms and polices
            </span>
          </div>
        </div>
      ) : (
        renderSentSuccess()
      )}
    </div>
  );
};
