import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { RootState } from 'redux/interfaces';
import { LoadingDialog } from 'shared/ui-kit/LoadingDialog';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import { DeviceSize } from 'shared/utils/constants';
import profileAction from 'redux/profile/actions';

import './index.styles.scss';
import { SocialItem } from './SocialItem';

interface ConnectSocialModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const ConnectSocialModal = ({
  show,
  onClose,
  onSuccess
}: ConnectSocialModalProps) => {
  const dispatch = useDispatch();
  const { socials, isConnectingSocial, isDeletingSocial } = useSelector(
    (state: RootState) => state.profile
  );
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  useEffect(() => {
    if (show) {
      dispatch(profileAction.connectingSocialFailed());
    }
  }, [show]);

  const isExistedTwitter = useMemo(() => {
    if (socials.filter((item, index) => item.type === 'twitter').length > 0) {
      return true;
    } else {
      return false;
    }
  }, [socials]);

  const isExistedTikTok = useMemo(() => {
    if (socials.filter((item, index) => item.type === 'tiktok').length > 0) {
      return true;
    } else {
      return false;
    }
  }, [socials]);

  const isExistedInstagram = useMemo(() => {
    if (socials.filter((item, index) => item.type === 'instagram').length > 0) {
      return true;
    } else {
      return false;
    }
  }, [socials]);

  const isExistedReddit = useMemo(() => {
    if (socials.filter((item, index) => item.type === 'reddit').length > 0) {
      return true;
    } else {
      return false;
    }
  }, [socials]);

  const isExistedSnapChat = useMemo(() => {
    if (socials.filter((item, index) => item.type === 'snapchat').length > 0) {
      return true;
    } else {
      return false;
    }
  }, [socials]);

  const isExistedYoutube = useMemo(() => {
    if (socials.filter((item, index) => item.type === 'youtube').length > 0) {
      return true;
    } else {
      return false;
    }
  }, [socials]);

  const isExistedSpotify = useMemo(() => {
    if (socials.filter((item, index) => item.type === 'spotify').length > 0) {
      return true;
    } else {
      return false;
    }
  }, [socials]);

  const twitterHandleInfo = useMemo(() => {
    if (socials.filter((item, index) => item.type === 'twitter').length > 0) {
      return socials.filter((item, index) => item.type === 'twitter')[0];
    } else {
      return 'Twitter';
    }
  }, [socials]);

  const tiktokHandleInfo = useMemo(() => {
    if (socials.filter((item, index) => item.type === 'tiktok').length > 0) {
      return socials.filter((item, index) => item.type === 'tiktok')[0];
    } else {
      return 'Tiktok';
    }
  }, [socials]);

  const instagramHandleInfo = useMemo(() => {
    if (socials.filter((item, index) => item.type === 'instagram').length > 0) {
      return socials.filter((item, index) => item.type === 'instagram')[0];
    } else {
      return 'Instagram';
    }
  }, [socials]);

  const redditHandleInfo = useMemo(() => {
    if (socials.filter((item, index) => item.type === 'reddit').length > 0) {
      return socials.filter((item, index) => item.type === 'reddit')[0];
    } else {
      return 'reddit';
    }
  }, [socials]);

  const snapchatHandleInfo = useMemo(() => {
    if (socials.filter((item, index) => item.type === 'snapchat').length > 0) {
      return socials.filter((item, index) => item.type === 'snapchat')[0];
    } else {
      return 'snapchat';
    }
  }, [socials]);

  const youtubeHandleInfo = useMemo(() => {
    if (socials.filter((item, index) => item.type === 'youtube').length > 0) {
      return socials.filter((item, index) => item.type === 'youtube')[0];
    } else {
      return 'youtube';
    }
  }, [socials]);

  const spotifyHandleInfo = useMemo(() => {
    if (socials.filter((item, index) => item.type === 'spotify').length > 0) {
      return socials.filter((item, index) => item.type === 'spotify')[0];
    } else {
      return 'spotify';
    }
  }, [socials]);

  const renderHeader = () => {
    return (
      <div className="modal_connect_social_header">
        <div className="modal_back_btn" onClick={onClose}>
          <BlackBackIcon />
        </div>
        <div className={`title font-medium ${isMobile ? 'font24' : 'font24'}`}>
          Connect Social Media
        </div>
      </div>
    );
  };

  const renderConnected = () => {
    return (
      <div className="modal_connect_social_sub">
        <div className="modal_connect_social_sub_header">
          <div
            className="sub_header_mark"
            style={{ backgroundColor: '#08B883' }}
          ></div>
          <div className="font-bold font14">Connected</div>
        </div>
        {isExistedTikTok && (
          <SocialItem
            type="tiktok"
            socialInfo={tiktokHandleInfo}
            isConnected={isExistedTikTok}
          />
        )}
        {isExistedInstagram && (
          <SocialItem
            type="instagram"
            socialInfo={instagramHandleInfo}
            isConnected={isExistedInstagram}
          />
        )}
        {isExistedTwitter && (
          <SocialItem
            type="twitter"
            socialInfo={twitterHandleInfo}
            isConnected={isExistedTwitter}
          />
        )}
        {isExistedReddit && (
          <SocialItem
            type="reddit"
            socialInfo={redditHandleInfo}
            isConnected={isExistedReddit}
          />
        )}
        {isExistedSnapChat && (
          <SocialItem
            type="snapchat"
            socialInfo={snapchatHandleInfo}
            isConnected={isExistedSnapChat}
          />
        )}
        {isExistedYoutube && (
          <SocialItem
            type="youtube"
            socialInfo={youtubeHandleInfo}
            isConnected={isExistedYoutube}
          />
        )}
        {isExistedSpotify && (
          <SocialItem
            type={'spotify'}
            socialInfo={spotifyHandleInfo}
            isConnected={isExistedSpotify}
          />
        )}
      </div>
    );
  };

  const renderNotConnected = () => {
    return (
      <div className="modal_connect_social_sub" style={{ marginTop: 32 }}>
        <div className="modal_connect_social_sub_header">
          <div
            className="sub_header_mark"
            style={{ backgroundColor: '#CCCCCC' }}
          ></div>
          <div className="font-bold font14">Not Connected</div>
        </div>
        {!isExistedTikTok && (
          <SocialItem
            type="tiktok"
            socialInfo={tiktokHandleInfo}
            isConnected={isExistedTikTok}
          />
        )}
        {!isExistedInstagram && (
          <SocialItem
            type="instagram"
            socialInfo={instagramHandleInfo}
            isConnected={isExistedInstagram}
          />
        )}
        {!isExistedTwitter && (
          <SocialItem
            type="twitter"
            socialInfo={twitterHandleInfo}
            isConnected={isExistedTwitter}
          />
        )}
        {!isExistedReddit && (
          <SocialItem
            type="reddit"
            socialInfo={redditHandleInfo}
            isConnected={isExistedReddit}
          />
        )}
        {!isExistedSnapChat && (
          <SocialItem
            type="snapchat"
            socialInfo={snapchatHandleInfo}
            isConnected={isExistedSnapChat}
          />
        )}
        {!isExistedYoutube && (
          <SocialItem
            type={'youtube'}
            socialInfo={youtubeHandleInfo}
            isConnected={isExistedYoutube}
          />
        )}
        {!isExistedSpotify && (
          <SocialItem
            type={'spotify'}
            socialInfo={spotifyHandleInfo}
            isConnected={isExistedSpotify}
          />
        )}
      </div>
    );
  };

  const renderLoadingModal = () => {
    return (
      <LoadingDialog
        show={isConnectingSocial || isDeletingSocial}
        title={isConnectingSocial ? 'Connecting...' : 'Disconnecting...'}
        description="This may take a moment..."
        onClose={() => {}}
      />
    );
  };

  return (
    <PrimaryModal
      show={show}
      width={isMobile ? 'calc(100% - 32px)' : 500}
      style={{
        paddingLeft: '16px',
        paddingRight: '16px'
      }}
      onClose={onClose}
    >
      <div className="modal_connect_social">
        {renderHeader()}
        <div className="modal_connect_social_body">
          {!(
            !isExistedInstagram &&
            !isExistedTikTok &&
            !isExistedTwitter &&
            !isExistedReddit &&
            !isExistedSnapChat &&
            !isExistedYoutube &&
            !isExistedSpotify
          ) && renderConnected()}
          {!(
            isExistedTwitter &&
            isExistedTikTok &&
            isExistedInstagram &&
            isExistedReddit &&
            isExistedSnapChat &&
            isExistedYoutube &&
            isExistedSpotify
          ) && renderNotConnected()}
        </div>
        {renderLoadingModal()}
      </div>
    </PrimaryModal>
  );
};
