import React, { useState } from 'react';

import './index.styles.scss';

import BG_HOME from 'assets/pngs/bg_waiting_list.png';
import BG_DONE from 'assets/pngs/bg_on_waiting_list.png';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import { apiRegisterWaitList } from 'shared/api/utils';
import { validateEmail } from 'shared/utils/utils';
import { DeviceSize } from 'shared/utils/constants';
import { useMediaQuery } from 'react-responsive';

export const WaitingList = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  const [isSaved, setIsSaved] = useState(false);
  const [email, setEmail] = useState('');
  const [isSending, setIsSending] = useState(false);

  const onSend = async () => {
    if (!isSending && validateEmail(email)) {
      setIsSending(true);

      const res = await apiRegisterWaitList({
        email: email.replaceAll(' ', '')
      });

      if (res.success) {
        setIsSaved(true);
      }
      setIsSending(false);
    }
  };

  const onBack = async () => {
    navigate('/');
  };

  const renderHome = () => {
    return (
      <div className="wait_home">
        <img src={BG_HOME} style={{ width: 220, height: 190 }} />
        <div>
          <div className="wait_txt font-bold font24">Join the Waitlist</div>
          <div
            className="wait_txt font-regular font16"
            style={{ color: 'rgba(0, 0, 0, 0.4)', marginTop: 12 }}
          >
            Due to popular demand, early access is limited — signing up on the
            waitlist will reserve your spot!
            <br />
            Keep an eye out for our email, we’ll let you know when you can get
            in.
          </div>
        </div>
        <div className="register_input">
          <div className="your_email font10 font-bold">Your Email</div>
          <div>
            <input
              value={email}
              placeholder="Enter your email here..."
              className="email_input font-bold font16"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </div>
        {renderBackBtn()}
        {renderSendBtn()}
      </div>
    );
  };

  const renderBackBtn = () => {
    return (
      <div className="home_back_btn row_align_items clickable" onClick={onBack}>
        <BlackBackIcon color="#FF3E9A" />
        <div
          className="font-bold font14"
          style={{ marginLeft: 6, color: '#FF3E9A' }}
        >
          Back to Login
        </div>
      </div>
    );
  };
  const renderSendBtn = () => {
    return (
      <div className="send_email_btn">
        <PrimaryButton
          label={`Sign Me Up!`}
          isLoading={isSending}
          onPressed={onSend}
        />
      </div>
    );
  };

  const renderSuccess = () => {
    return (
      <div className="wait_success">
        <div>
          <div className="align-center">
            <img
              src={'/logo512.png'}
              width={50}
              height={60}
              style={{ objectFit: 'contain' }}
            />
          </div>
        </div>
        <img src={BG_DONE} style={{ width: 220, height: 190 }} />
        <div>
          <div className="wait_txt font-bold font24">
            You are on the waitlist!
          </div>
          <div
            className="wait_txt font-regular font16"
            style={{ color: 'rgba(0, 0, 0, 0.4)', marginTop: 12 }}
          >
            We’ll let you know when your turn comes.
            <br />
            Please wait for our invitation email.
          </div>
        </div>
        <div className="wait_tip">
          <div className="font-regular font14" style={{ textAlign: 'center' }}>
            💡<span className="font-bold font14">Pro tip:</span> You can skip
            the line if you receive an invitation from
            <br />
            users that already joined.{' '}
          </div>
        </div>
        <div
          className="font-bold font14 clickable"
          style={{ color: '#FF3E9A' }}
          onClick={onBack}
        >
          Back to Login
        </div>
      </div>
    );
  };

  return (
    <div
      className="waiting_list"
      style={isMobile ? { width: 'calc(100% - 32px)' } : {}}
    >
      {isSaved ? renderSuccess() : renderHome()}
    </div>
  );
};
