import React, { useEffect, useState } from 'react';
import Sheet from 'react-modal-sheet';

import IMG_BG_BOT from 'assets/pngs/bg_bot.png';

import './index.styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import { MobileBotAudioOption } from './BotAudioOption';
import { apiChatAttachVoice, apiChatCreatePersonalAIV2 } from 'shared/api/chat';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import { MobileCompleteBotCreation } from './CompleteBotCreation';
import { MobileBotUploadAudio } from './UploadAudio';
import { MobileBotUploadAudioStatus } from './UploadedStatus';
import { MobileBotCreateReview } from './BotCreateReview';
import { MobileChooseVoice } from './ChooseVoice';

interface Props {
  open: boolean;
  aiInfo: any;
  onClose: () => void;
}

export const MobileAddVoiceModal = ({ open, aiInfo, onClose }: Props) => {
  const navigate = useNavigate();
  const params = useParams();

  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [currentStep, setCurrentStep] = useState<
    | 'option'
    | 'complete'
    | 'new_voice'
    | 'upload_status'
    | 'review_bot'
    | 'existed_voice'
  >('option');

  const [isCreatingAI, setIsCreatingAI] = useState(false);
  const [isCompletingAI, setIsCompletingAI] = useState(false);

  const [promptId, setPromptId] = useState<any>(null);
  const [voiceUrl, setVoiceUrl] = useState<Array<any>>([]);

  useEffect(() => {
    if (open) {
      setCurrentStep('option');
    }
  }, [open]);

  const onBack = () => {
    if (currentStep === 'existed_voice') {
      setCurrentStep('option');
    } else if (currentStep === 'new_voice') {
      setCurrentStep('option');
    } else if (currentStep === 'complete') {
      setCurrentStep('option');
    } else {
      onClose();
    }
  };

  const onCompleteAI = async (voiceId = null) => {
    if (!isCreatingAI && !isCompletingAI) {
      setIsCompletingAI(true);

      const res = await apiChatAttachVoice({
        ai_id: aiInfo.id,
        ai_type: 'personal',
        voice: voiceId ?? 0
      });

      if (res.success) {
        showToast('success', 'Successfully attached voice!');

        if (voiceId) {
          setCurrentStep('review_bot');
        } else {
          onClose();
        }
      }

      setIsCompletingAI(false);
    }
  };

  const renderBg = () => {
    return (
      <div className="bot_bg_container">
        <img src={IMG_BG_BOT} className="bot_bg_image" />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="bot_create_body">
        {renderHeader()}
        {currentStep === 'option' ? (
          <MobileBotAudioOption
            isCreatingAI={isCreatingAI}
            onSelectedOption={setCurrentStep}
          />
        ) : currentStep === 'complete' ? (
          <MobileCompleteBotCreation
            isCompleting={isCompletingAI}
            onComplete={onCompleteAI}
          />
        ) : currentStep === 'new_voice' ? (
          <MobileBotUploadAudio
            aiId={aiInfo.ai_id}
            onSuccess={(newLinks) => {
              setVoiceUrl(newLinks);
              setCurrentStep('upload_status');
            }}
          />
        ) : currentStep === 'upload_status' ? (
          <MobileBotUploadAudioStatus
            isCreating={isCompletingAI}
            urls={voiceUrl}
            onCreateAIWithVoice={(voiceId) => {
              onCompleteAI(voiceId);
            }}
          />
        ) : currentStep === 'review_bot' ? (
          <MobileBotCreateReview
            aiName={aiInfo.name}
            aiImage={aiInfo.image}
            onFinish={() => {
              onClose();
            }}
          />
        ) : currentStep === 'existed_voice' ? (
          <MobileChooseVoice
            isCreating={isCompletingAI}
            onCreateAIWithVoice={(voiceId) => {
              onCompleteAI(voiceId);
            }}
          />
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div
        className="bot_header row_align_items"
        style={currentStep === 'review_bot' ? { marginRight: 0 } : {}}
      >
        {currentStep !== 'review_bot' && (
          <div onClick={onBack}>
            <BlackBackIcon />
          </div>
        )}
        <div className="bot_name font-bold font16">{`${aiInfo.name} AI`}</div>
      </div>
    );
  };

  return (
    <Sheet
      isOpen={open}
      className="custom_mobile_add_voice_modal"
      onClose={onClose}
    >
      <Sheet.Container className="mobile_add_voice_modal_body">
        <Sheet.Content>
          <div className="mobile_add_voice">
            {renderBg()}
            {renderBody()}
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
