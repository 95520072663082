import React, { useEffect, useState } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { apiGetGroups } from 'shared/api/group';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { CardJoinedGroup } from './CardJoinedGroup';

import BG_EMPTY_CREATOR from 'assets/pngs/bg_empty_chat_creator.png';

import './index.styles.scss';
import { useNavigate } from 'react-router-dom';

export const JoinedGroup = ({
  isLoadedAll,
  selectedGroup,
  onSelected,
  setIsLoaded
}) => {
  const navigate = useNavigate();
  const [arrGroup, setArrGroup] = useState<Array<any>>([]);
  const [lastIdGroup, setLastIdGroup] = useState(0);
  const [hasMoreGroup, setHasMoreGroup] = useState(true);
  const [isLoadingGroup, setIsLoadingGroup] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (init = true, keyword = '') => {
    if (init) {
      setIsLoadingGroup(true);
      const res = await apiGetGroups({
        query: keyword,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrGroup(res.data);
        setLastIdGroup(res.lastId);
        setHasMoreGroup(res.hasMore);
        if (res.data.length > 0) {
          onSelected(res.data[0]);
        }
      }
      setIsLoaded();
      setIsLoadingGroup(false);
    } else {
      if (hasMoreGroup && !isLoadingGroup) {
        setIsLoadingGroup(true);
        const res = await apiGetGroups({
          query: keyword,
          limit: 10,
          offset: lastIdGroup
        });

        if (res.success) {
          setArrGroup((prev) => [...prev, ...res.data]);
          setLastIdGroup(res.lastId);
          setHasMoreGroup(res.hasMore);
        }

        setIsLoadingGroup(false);
      }
    }
  };

  const onLoadMoreData = (e) => {
    loadData(false);
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const renderBody = () => {
    return (
      <div className="mobile_joined_group_body" id="mobile_joined_group_body">
        <ScrollMenu onWheel={onWheel} onUpdate={onLoadMoreData}>
          {arrGroup.map((item, index) => {
            return (
              <CardJoinedGroup
                index={index}
                isSelected={item.id === selectedGroup?.id}
                onSelected={onSelected}
                data={item}
                key={`card_joined_group_${index}`}
              />
            );
          })}
        </ScrollMenu>
      </div>
    );
  };

  const renderEmptyBody = () => {
    return (
      <div className="mobile_joined_empty_body">
        <img
          src={BG_EMPTY_CREATOR}
          style={{ width: 300, height: 230, marginBottom: 30 }}
        />
        <div
          className="font-bold font16"
          style={{ margin: '0px 16px', textAlign: 'center' }}
        >{`It looks like you haven’t joined any groups yet.`}</div>
        <div
          className="font-regular font12"
          style={{
            color: 'rgba(0, 0, 0, 0.4)',
            marginTop: 8,
            textAlign: 'center'
          }}
        >
          <span
            className="clickable font-bold font12"
            style={{ marginTop: 16, color: '#FF3E9A' }}
            onClick={() => {
              navigate('/group/join');
            }}
          >
            {'Start exploring '}
          </span>
          {`now to find the right groups for you.`}
        </div>
      </div>
    );
  };

  if (isLoadedAll) {
    if (arrGroup.length > 0) {
      return (
        <div className="mobile_group_joined">
          <div
            className="row_space_between row_align_items"
            style={{ marginLeft: 16 }}
          >
            <div className="font-bold font18">Joined Groups</div>
          </div>
          {renderBody()}
        </div>
      );
    } else {
      return <div className="mobile_group_joined">{renderEmptyBody()}</div>;
    }
  } else {
    return (
      <div className="mobile_group_joined">
        <LoadingBarAnimation />
      </div>
    );
  }
};
