import BuildingIcon from 'assets/svgs/BuildingIcon';
import CommentAltCheckIcon from 'assets/svgs/CommentAltCheckIcon';
import EditPencilIcon from 'assets/svgs/EditPencilIcon';
import RegenerateIcon from 'assets/svgs/RegenerateIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import React, { useEffect, useRef, useState } from 'react';
import { apiQABookMark, apiQAEditAnswer } from 'shared/api/qa';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import './index.styles.scss';
import { useNavigate } from 'react-router-dom';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface Props {
  qaAskInfo: any;
  isAIGeneral: boolean;
  aiInfo: any;
  onSwitchAI: () => void;
  onRefresh: () => void;
}

export const MobileQuestionAsked = ({
  qaAskInfo,
  isAIGeneral,
  aiInfo,
  onSwitchAI,
  onRefresh
}: Props) => {
  const navigate = useNavigate();

  const answerEditRef = useRef<HTMLTextAreaElement>(null);
  const [answer, setAnswer] = useState(qaAskInfo?.answer);

  const [editAnswer, setEditAnswer] = useState('');
  const [isEditAnswer, setIsEditAnswer] = useState(false);
  const [isSavingAnswer, setIsSavingAnswer] = useState(false);
  const [isBookMarkingAnswer, setIsBookMarkingAnswer] = useState(false);

  useEffect(() => {
    if (qaAskInfo) {
      if (isAIGeneral) {
        setAnswer(qaAskInfo?.answer);
      } else {
        setAnswer(qaAskInfo?.ai_answer);
      }
    }
  }, [qaAskInfo]);

  const onEditAnswer = () => {
    setEditAnswer(answer);
    setIsEditAnswer(true);

    setTimeout(() => {
      answerEditRef.current?.focus();
    }, 200);
  };

  const onSaveAnswer = async () => {
    if (!isSavingAnswer) {
      setIsSavingAnswer(true);

      const res = await apiQAEditAnswer({
        question: qaAskInfo ? qaAskInfo.id : 0,
        answer: editAnswer
      });

      if (res.success) {
        setAnswer(editAnswer);
        setIsEditAnswer(false);
      }

      setIsSavingAnswer(false);
    }
  };

  const onAnswerBookMark = async () => {
    if (!isBookMarkingAnswer) {
      setIsBookMarkingAnswer(true);

      if (isAIGeneral) {
        const res = await apiQABookMark({
          question: qaAskInfo ? qaAskInfo.id : 0
        });

        if (res.success) {
          showToast(
            'success',
            'Success! Your question and answer have been posted.'
          );

          navigate(-1);
        }
      } else {
        const res = await apiQABookMark({
          question: qaAskInfo ? qaAskInfo.id : 0,
          item_id: aiInfo ? aiInfo.ai_id : 30,
          item_type: 'organisation'
        });

        if (res.success) {
          showToast(
            'success',
            'Success! Your question and answer have been posted.'
          );

          navigate(-1);
        }
      }

      setIsBookMarkingAnswer(false);
    }
  };

  const onInput = (e) => {
    e.target.style.height = '1px';
    e.target.style.height = e.target.scrollHeight + 'px';
  };

  const renderTopView = () => {
    return (
      <div className="row_align_items row_space_between">
        {isAIGeneral ? renderGeneralInfo() : renderSchoolInfo()}
        <div className="row_align_items" style={{ gap: 8 }}>
          <div className="action_icon" onClick={onSwitchAI}>
            <BuildingIcon size={16} />
          </div>
          <div className="action_icon" onClick={onRefresh}>
            <RegenerateIcon size={16} />
          </div>
        </div>
      </div>
    );
  };

  const renderGeneralInfo = () => {
    return (
      <div className="row_align_items" style={{ gap: 8 }}>
        <div className="action_icon">
          <RobotIcon size={16} />
        </div>
        <div className="font-bold font16">General AI</div>
      </div>
    );
  };

  const renderSchoolInfo = () => {
    return (
      <div className="ai_info_view row_align_items" style={{ gap: 8 }}>
        <div className="action_icon">
          <img src={aiInfo?.ai_image} className="ai_image" />
        </div>
        <div>
          <div className="font-bold font10" style={{ opacity: 0.3 }}>
            School AI
          </div>
          <div className="font-bold font16">{aiInfo?.ai_name}</div>
        </div>
      </div>
    );
  };

  const renderAnswer = () => {
    return (
      <div className="answer_txt">
        <pre className="font-regular font14" style={{ lineHeight: '25.2px' }}>
          {answer}
        </pre>
      </div>
    );
  };

  const renderEditAnswer = () => {
    return (
      <div className="answer_txt">
        <textarea
          ref={answerEditRef}
          className="answer_edit_view font-regular font14"
          value={editAnswer}
          onChange={(e) => {
            onInput(e);
            setEditAnswer(e.target.value);
          }}
          onInput={onInput}
          onFocus={onInput}
          style={{
            backgroundColor: 'white',
            width: '100%',
            wordBreak: 'break-word'
          }}
        />
      </div>
    );
  };

  const renderEditAnswerActions = () => {
    return (
      <div className="row_align_items row_space_between" style={{ gap: 8 }}>
        <div
          className="edit_answer_btn"
          onClick={() => {
            setIsEditAnswer(false);
          }}
        >
          <div className="font-bold font12">Cancel</div>
        </div>
        <div className="post_answer_btn" onClick={onSaveAnswer}>
          {isSavingAnswer ? (
            <LoadingCircular size={16} color={'white'} />
          ) : (
            <div className="font-bold font12" style={{ color: 'white' }}>
              Save Answer
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderActions = () => {
    return (
      <div className="row_align_items row_space_between" style={{ gap: 8 }}>
        <div className="edit_answer_btn" onClick={onEditAnswer}>
          <EditPencilIcon size={16} color="#FF3E9A" />
          <div className="font-bold font12">Edit Answer</div>
        </div>
        <div className="post_answer_btn" onClick={onAnswerBookMark}>
          {isBookMarkingAnswer ? (
            <LoadingCircular size={16} color={'white'} />
          ) : (
            <>
              <CommentAltCheckIcon size={16} color={'white'} />
              <div className="font-bold font12" style={{ color: 'white' }}>
                Post as Answer
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_question_asked">
      {renderTopView()}
      {isEditAnswer ? renderEditAnswer() : renderAnswer()}
      {isEditAnswer ? renderEditAnswerActions() : renderActions()}
    </div>
  );
};
