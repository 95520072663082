import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import SearchIcon from 'assets/svgs/SearchIcon';

import './index.styles.scss';

interface SearchBoxProps {
  onSearch: (searchText: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClickedSearchIcon?: () => void;
  width?: string;
  height?: string;
  backgroundColor?: string;
  placeholder?: string;
  fontSize?: number;
  searchIconClickable?: boolean;
  type?: string;
  padding?: string;
  enableShadow?: boolean;
}

export const SearchBox = ({
  onSearch,
  onFocus,
  onBlur,
  onClickedSearchIcon,
  width,
  height,
  backgroundColor,
  placeholder,
  fontSize,
  searchIconClickable,
  type,
  enableShadow = true
}: SearchBoxProps) => {
  const [keyword, setKeyword] = useState('');
  const [debouncedKeyword] = useDebounce(keyword, 500);

  useEffect(() => {
    if (!searchIconClickable) {
      onSearch(debouncedKeyword);
    }
  }, [debouncedKeyword]);

  const onChange = (e) => {
    if (type === 'joinNewOrg') onSearch(e);
  };

  const onEnter = (e) => {
    if (keyword !== '') {
      if (e.keyCode === 13) {
        e.preventDefault();
        onSearch(debouncedKeyword);
      }
    }
  };

  return (
    <div
      className="search_box"
      style={
        enableShadow
          ? {
              width: width ?? '',
              height: height ?? 46,
              backgroundColor: backgroundColor ?? 'white',
              border: 'none'
            }
          : {
              width: width ?? '',
              height: height ?? 46,
              backgroundColor: backgroundColor ?? 'white',
              boxShadow: 'none',
              border: '1px solid rgba(0, 0, 0, 0.1)'
            }
      }
    >
      <div
        className={
          searchIconClickable ? 'clickable align-center' : 'align-center'
        }
        onClick={() => {
          if (searchIconClickable) {
            onSearch(debouncedKeyword);
          }
          if (onFocus) {
            onFocus();
          }

          onClickedSearchIcon && onClickedSearchIcon();
        }}
      >
        <SearchIcon />
      </div>
      <input
        value={keyword}
        placeholder={placeholder ?? 'Search here....'}
        className="search_box_input font-regular"
        onFocus={onFocus}
        onBlur={onBlur}
        style={{
          backgroundColor: backgroundColor ?? 'white',
          fontSize: fontSize ? fontSize : '26px',
          width: '100%'
        }}
        onChange={(e) => {
          if (e.nativeEvent['inputType'] === 'insertLineBreak') {
            e.preventDefault();
            return;
          } else {
            setKeyword(e.target.value);
            onChange(e.target.value);
          }
        }}
        onKeyUp={(e) => {
          e.preventDefault();
          onEnter(e);
        }}
      />
    </div>
  );
};
