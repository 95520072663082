import React from 'react';

function RoundBackIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '48'}
      height={props.size ?? '48'}
      fill="none"
      viewBox="0 0 48 48"
    >
      <rect
        width="48"
        height="48"
        fill={props.bgColor ?? '#fff'}
        rx="24"
        transform="matrix(-1 0 0 1 48 0)"
      ></rect>
      <path
        fill={props.color ?? '#000'}
        d="M21.17 23.29l4.24-4.24a1 1 0 111.42 1.41L23.29 24l3.54 3.54a1 1 0 010 1.41 1.001 1.001 0 01-.71.29 1 1 0 01-.71-.29l-4.24-4.24a.999.999 0 010-1.42z"
      ></path>
      <rect
        width="47"
        height="47"
        x="-0.5"
        y="0.5"
        stroke={props.bgColor ?? '#000'}
        strokeOpacity="0.1"
        rx="23.5"
        transform="matrix(-1 0 0 1 47 0)"
      ></rect>
    </svg>
  );
}

export default RoundBackIcon;
