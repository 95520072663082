import React from "react";

function QuestionMarkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FF3E9A"
        d="M9.29 13.29a1.578 1.578 0 00-.12.15.76.76 0 00-.09.18.64.64 0 00-.06.18 1.36 1.36 0 000 .2.84.84 0 00.08.38.9.9 0 00.54.54.94.94 0 00.76 0 .9.9 0 00.54-.54A1 1 0 0011 14a1 1 0 00-1.71-.71zM10 0a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16zm0-13a3 3 0 00-2.6 1.5 1 1 0 101.73 1A1 1 0 0110 7a1 1 0 110 2 1 1 0 00-1 1v1a1 1 0 102 0v-.18A3 3 0 0010 5z"
      ></path>
    </svg>
  );
}

export default QuestionMarkIcon;
