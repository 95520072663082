import { MobileUserBotModal } from 'components/Modals/Mobile/UserBotModal';
import React, { useEffect, useState } from 'react';
import Columns from 'react-columns';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiGetSuggestedUser } from 'shared/api/user';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { CardCommunityMember } from '../../components/CardCommunityMember';

import './index.styles.scss';

export const DiscoverFriend = ({ searchText }) => {
  const { communityScroll } = useSelector((state: RootState) => state.app);

  const [arrUser, setArrUser] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadedFirst, setIsLoadedFirst] = useState(false);

  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [showBotModal, setShowBotModal] = useState(false);

  useEffect(() => {
    loadData();
  }, [searchText]);

  useEffect(() => {
    if (isLoadedFirst) {
      loadData(false);
    }
  }, [communityScroll]);

  const loadData = async (init = true) => {
    if (init) {
      setIsLoading(true);

      const res = await apiGetSuggestedUser({
        query: searchText === '' ? null : searchText,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrUser(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setIsLoadedFirst(true);
      }

      setIsLoading(false);
    } else {
      if (hasMore && !isLoading) {
        const res = await apiGetSuggestedUser({
          query: searchText === '' ? null : searchText,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrUser((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }
      }
    }
  };

  const renderBotModal = () => {
    return (
      <MobileUserBotModal
        open={showBotModal}
        userId={selectedUser}
        onClose={() => {
          setShowBotModal(false);
        }}
        onViewOther={(otherId) => {
          setSelectedUser(otherId);
        }}
      />
    );
  };

  return (
    <div className="mobile_discover_friend">
      <div className="font-bold font18">Discover New Friends</div>
      <div className="mobile_discover_friend_body">
        {isLoading ? (
          <div style={{ marginTop: 16 }}>
            <LoadingBarAnimation />
          </div>
        ) : (
          <Columns columns={2} gap={'8px'} rootStyles={{ paddingBottom: 10 }}>
            {arrUser.map((item, index) => {
              return (
                <CardCommunityMember
                  data={item}
                  type="all"
                  key={`card_community_${index}`}
                  onViewProfile={(id) => {
                    setSelectedUser(id);
                    setShowBotModal(true);
                  }}
                />
              );
            })}
          </Columns>
        )}
      </div>
      {renderBotModal()}
    </div>
  );
};
