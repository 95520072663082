import BookMarkedIcon from 'assets/svgs/BookMarkedIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import moment from 'moment';
import RobotIcon from 'assets/svgs/RobotIcon';
import UCoinIcon from 'assets/svgs/UCoinsIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactHowler from 'react-howler';

import './index.styles.scss';
import { apiQABookMarkAnswer, apiQAUnBookMarkAnswer } from 'shared/api/qa';
import { removeFirstBlank } from 'shared/utils/utils';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import FoloderDownloadIcon from 'assets/svgs/FolderDownloadIcon';
import { apiContentVoteAI } from 'shared/api/content';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import ThumbDownIcon from 'assets/svgs/ThumbDownIcon';
import FavBorderIcon from 'assets/svgs/FavBorderIcon';
import { useAudioContext } from 'shared/contexts/AudioContext';
import AudioPauseIcon from 'assets/svgs/AudioPauseIcon';
import { MobileFeedAnswersModal } from 'components/Modals/Mobile/QuestionAnswersModal';

export interface QuestionData {
  ai_id?: number;
  ai_type?: 'user' | 'general' | 'organisation' | 'group';
  ai_name?: string;
  ai_image?: string;
  creator_id?: number;
  creator_image?: string;
  creator_name?: string;
  question: string;
  ai_answer: string;
  id: number;
  bookmark_id: number;
  views: number;
  points: number;
  answer_count: number;
  timestamp: string;
  is_anonymous: number;

  top_creator_id: number;
  top_creator_image: string;
  top_creator_name: string;
  top_points: number;
  top_user_vote: number;
  top_answer_text: string;
  top_answer_id: number;
  top_is_anonymous: number;
  top_is_bookmarked: number;
  top_1: number;
  top_2: number;
  top_3: number;
  top_answer_filename: string;
  top_answer_size: string;
  top_answer_media_type: string;

  likes: number;
  dislikes: number;
  user_like: any;

  audio?: any;
  top_audio?: any;
}

interface CardTrendingQuestionProps {
  data: QuestionData;
  type: 'top' | 'creator';
  hideBookMark?: boolean;
  onShareMedia: (data: QuestionData, type: string) => void;
}

export const MobileCardSimilarQuestion = ({
  data,
  type,
  hideBookMark,
  onShareMedia
}: CardTrendingQuestionProps) => {
  const navigate = useNavigate();

  const [isBookmarking, setIsBookmarking] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(
    data.top_is_bookmarked === 1
  );

  const [likeCount, setLikeCount] = useState(data.likes);
  const [dislikeCount, setDislikeCount] = useState(data.dislikes);
  const [userVote, setUserVote] = useState(data.user_like);

  const [answerPoints, setAnswerPoints] = useState(data.top_points);
  const [questionPoints, setQuestionPoints] = useState(data.points);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const { playingAudio, setPlayingAudio } = useAudioContext();
  const [showAnswers, setShowAnswers] = useState(false);

  useEffect(() => {
    if ((playingAudio ?? '') === data.top_audio) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [playingAudio, data]);

  const onShare = () => {
    onShareMedia(data, type);
  };

  const onViewProfile = (userId) => {
    navigate(`/profile/${userId}`);
  };

  const onBookmarkAnswer = async () => {
    if (!isBookmarking) {
      setIsBookmarking(true);

      if (isBookmarked) {
        const res = await apiQAUnBookMarkAnswer({
          answer: data.top_answer_id
        });

        if (res.success) {
          setIsBookmarked(false);
        }
      } else {
        const res = await apiQABookMarkAnswer({
          answer: data.top_answer_id,
          item_id: undefined,
          item_type: 'user'
        });

        if (res.success) {
          setIsBookmarked(true);
        }
      }

      setIsBookmarking(false);
    }
  };

  const onClickShare = (modalType) => {
    onShareMedia(data, modalType);
  };

  const onVote = async (vote: number) => {
    const res = await apiContentVoteAI({
      item_id: data.top_answer_id,
      item_type: 'answer',
      like: vote,
      remove: userVote === vote ? true : false
    });

    if (res.success) {
      const voteData = res.voting;
      setUserVote(voteData.user_like);
      setLikeCount(voteData.likes);
      setDislikeCount(voteData.dislikes);
    }
  };

  const togglePlayback = () => {
    if (isPlaying) {
      setPlayingAudio(null);
    } else {
      if (playingAudio) {
        setPlayingAudio(null);
      }
      setPlayingAudio(data.top_audio);
    }
  };

  const onShowAnswers = () => {
    // setShowAnswers(true);
  };

  const onDetail = (bookmark_id) => {
    navigate(`/question/${bookmark_id}`);
  };

  const renderTopView = () => {
    return (
      <div className="question_box_top">
        <div className="question_box_top_user">
          {renderCreatorCreatorInfo()}
          {renderPlaceInfo()}
        </div>
        <div className="right_panel">
          <div className="round_btn" onClick={onShare}>
            <ShareIcon size={16} color={'#FF3E9A'} />
          </div>
        </div>
      </div>
    );
  };

  const renderCreatorCreatorInfo = () => {
    if (data.is_anonymous === 1) {
      <div className="align-vertical-center">
        <DefaultAvatarIcon size={24} />
        <div className="font-medium font16 user_name" style={{ marginLeft: 8 }}>
          {'Anonymous'}
        </div>
      </div>;
    } else {
      if (data.creator_id) {
        return (
          <div
            className="align-vertical-center"
            onClick={() => {
              onViewProfile(data.creator_id);
            }}
          >
            {data.creator_image?.startsWith('https') ? (
              <img
                className="creator_image"
                src={data.creator_image}
                width="24"
                height="24"
                style={{ borderRadius: 24 }}
              ></img>
            ) : (
              <DefaultAvatarIcon size={24} />
            )}

            <div
              className="font-medium font12 user_name"
              style={{ marginLeft: 8 }}
            >
              {data.creator_name ? data.creator_name : 'Anonymous'}
            </div>
          </div>
        );
      } else {
        return (
          <div className="align-vertical-center">
            <RobotIcon />
            <div
              className="font-medium font12 user_name"
              style={{ marginLeft: 8 }}
            >
              {'General AI'}
            </div>
          </div>
        );
      }
    }
  };

  const renderPlaceInfo = () => {
    if (
      !data.ai_type ||
      data.ai_type === 'user' ||
      data.ai_type === 'general' ||
      !data.ai_id
    ) {
      return <div />;
    } else {
      return (
        <div className="align-vertical-center">
          <div
            className="font-regular font12 in_label"
            style={{ marginRight: 4, marginLeft: 4 }}
          >
            in
          </div>
          <div className="font-medium font12">{data.ai_name}</div>
        </div>
      );
    }
  };

  const renderQuestion = () => {
    return (
      <div
        className="question_text font-bold font16"
        onClick={() => onDetail(data.bookmark_id)}
      >
        {data.question}
      </div>
    );
  };

  const renderAddAnswerbar = () => {
    return (
      <div className="add_answer_bar" onClick={() => {}}>
        <div className="left_side">
          <div className="question_info">
            <div className="snack">
              <div className="font12 question_info_icon">👀</div>
              <div className="font12 question_info_text">
                {`${data.views} view${data.views > 1 ? 's' : ''}`}
              </div>
            </div>
            <div className="snack">
              <div className="font12 question_info_icon">💬</div>
              <div className="font12 question_info_text">
                {`${data.answer_count} answer${
                  data.answer_count > 1 ? 's' : ''
                }`}
              </div>
            </div>
            <div
              style={{
                width: 2,
                height: 16,
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                margin: `0px 0px`
              }}
            />
            <div className="question_points font12 font-regular">
              <UCoinIcon size={12} />
              &nbsp;
              {`${questionPoints} pt${questionPoints > 1 ? 's' : ''}`}
            </div>
          </div>
        </div>
        <div className="right_side">
          <div className="question_date font12 font-regular">
            {moment(data.timestamp).format('DD MMM yyyy').toString()}
          </div>
        </div>
      </div>
    );
  };

  const renderTopUserInfoBar = () => {
    return (
      <div className="answer_box_top" style={{ marginTop: 8 }}>
        <div className="answer_box_top_left">
          <div
            className="answer_box_top_user"
            onClick={() => {
              data.top_creator_id && onViewProfile(data.top_creator_id);
            }}
          >
            {data.top_creator_id ? (
              <>
                {data.top_creator_image === '' ? (
                  <DefaultAvatarIcon size={20} />
                ) : (
                  <img
                    src={data.top_creator_image}
                    style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%'
                    }}
                  />
                )}
                <div className="user_name font-regular font12">
                  {data.top_creator_name}
                </div>
              </>
            ) : (
              <div className="row_align_items">
                <img
                  className="creator_image"
                  src={data.ai_image}
                  width="20"
                  height="20"
                  style={{ borderRadius: 24, marginRight: 8 }}
                ></img>
                <div className="font-regular font12 org_name">
                  {`${data.ai_name} AI`}
                </div>
              </div>
            )}
          </div>
        </div>
        {data.top_creator_id && (
          <div className="answer_box_top_right">
            <div className="answer_point font-medium font10">
              <UCoinIcon size={12} />
              &nbsp;
              {`${answerPoints} pt${answerPoints > 1 ? 's' : ''}`}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderAnswerBody = () => {
    if (data.ai_answer != null || data.top_answer_text !== null)
      return (
        <div className="answer_box">
          {data.top_creator_id && (
            <div
              className="top_voted_mark font-bold font8"
              style={{ fontSize: 8 }}
            >
              Top Answer
            </div>
          )}
          {renderTopUserInfoBar()}
          {data.top_answer_media_type === null
            ? renderAnswer()
            : data.top_answer_media_type === 'text'
            ? renderAnswer()
            : renderFileAnswer()}
          {renderReivews()}
        </div>
      );
  };

  const renderFileAnswer = () => {
    if (data.top_answer_filename !== null && data.top_answer_size !== null)
      return (
        <div className="answer_media">
          <div className="answer_media_content">
            <PdfFileIcon size={72} />
            <div className="answer_media_content_txt">
              <div className="answer_media_content_txt_title font16 font-bold">
                {data.top_answer_filename}
              </div>
              <div className="answer_media_content_txt_size font12">
                Size {data.top_answer_size} MB
              </div>
            </div>
          </div>
          <a
            href={data.top_answer_text}
            style={{ textDecoration: 'none' }}
            target="blank"
          >
            <div className="answer_media_download_btn">
              <FoloderDownloadIcon />
              <div className="answer_media_download_btn_txt font14 font-bold">
                Download
              </div>
            </div>
          </a>
        </div>
      );
  };

  const renderAnswer = () => {
    if (data.ai_answer != null || data.top_answer_text !== null)
      return (
        <div
          className="trending_answer_text font-regular font14"
          style={{ margin: '16px 8px 0px' }}
          onClick={() => onShowAnswers()}
        >
          <pre className="font14" style={{ lineHeight: '25.2px' }}>
            {removeFirstBlank(
              data.top_answer_id ? data.top_answer_text : data.ai_answer
            )}
          </pre>
        </div>
      );
  };

  const renderAudioIcon = () => {
    if (data.top_audio) {
      return (
        <div className="audio_play_btn align-center" onClick={togglePlayback}>
          {isPlaying ? (
            <AudioPauseIcon color="#EB5099" size={16} />
          ) : (
            <AudioPlayIcon color="#EB5099" size={16} />
          )}
          {isPlaying && (
            <ReactHowler
              src={data.top_audio}
              playing={isPlaying}
              loop={false}
              html5={true} // Use HTML5 Audio API
              onLoad={() => setIsLoaded(true)}
              onEnd={() => setPlayingAudio(null)}
            />
          )}
        </div>
      );
    } else {
      return <div />;
    }
  };

  const renderReivews = () => {
    return (
      <div className="answer_box_bottom">
        {data.top_answer_id ? (
          <div className="answer_box_bottom_left">
            {data.top_audio && (
              <div className={'agree_btn clickable'}>{renderAudioIcon()}</div>
            )}
            <div
              className={
                userVote === 1
                  ? 'agree_btn_highlighted clickable'
                  : 'agree_btn clickable'
              }
              onClick={() => onVote(1)}
            >
              <FavBorderIcon size={16} />
            </div>
            <div
              className={
                userVote === 0
                  ? 'agree_btn_highlighted clickable'
                  : 'agree_btn clickable'
              }
              onClick={() => onVote(0)}
            >
              <ThumbDownIcon size={16} />
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {!hideBookMark &&
          (isBookmarking
            ? renderIsBookmarking()
            : isBookmarked
            ? renderBookmarkedBtn()
            : renderBookmarkBtn())}
      </div>
    );
  };

  const renderIsBookmarking = () => {
    return (
      <div className="bookmark_btn">
        <LoadingCircular color="main" size={20} />
      </div>
    );
  };

  const renderBookmarkBtn = () => {
    return (
      <div className="bookmark_btn clickable" onClick={onBookmarkAnswer}>
        <BookMarkedIcon color={'rgba(0, 0, 0, 0.4)'} />
        <div
          className="btn_label font-reqular font14"
          style={{ color: 'rgba(0, 0, 0)' }}
        >
          Bookmark this Answer
        </div>
      </div>
    );
  };

  const renderBookmarkedBtn = () => {
    return (
      <div className="bookmark_btn clickable" onClick={onBookmarkAnswer}>
        <BookMarkedIcon opacity="0.4" />
        <div className="btn_label font-reqular font14">
          Bookmarked this Answer
        </div>
      </div>
    );
  };

  const renderAnswersModal = () => {
    return (
      <MobileFeedAnswersModal
        open={showAnswers}
        data={{ ...data, item_id: data.bookmark_id }}
        onClose={() => {
          setShowAnswers(false);
        }}
        onSuccess={() => {}}
      />
    );
  };

  return (
    <div>
      <div className="mobile_profile_question_box clickable">
        {renderTopView()}
        {renderQuestion()}
        {/* {renderAddAnswerbar()} */}
        {renderAnswerBody()}
        {renderAnswersModal()}
      </div>
    </div>
  );
};
