import InstagramIcon from 'assets/svgs/InstagramIcon';
import TikTokIcon from 'assets/svgs/TikTokIcon';
import TwitterIcon from 'assets/svgs/TwitterIcon';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import SocialMediasPng from 'assets/pngs/SocialMediasPng.png';

import './index.styles.scss';
import EditPencilColoredIcon from 'assets/svgs/EditPencilColoredIcon';
import { apiGetUserInfo, apiGetUserSocials } from 'shared/api/user';
import RedditIcon from 'assets/svgs/RedditIcon';
import SnapChatIcon from 'assets/svgs/SnapChatIcon';
import YoutubIcon from 'assets/svgs/YoutubeIcon';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import SpotifyIcon from 'assets/svgs/SpotifyIcon';

interface MobileProfileSocialMediaProps {
  userId: any;
  isLoadedAll: boolean;
  onConnect: () => void;
}

export const MobileProfileSocialMedia = ({
  userId,
  isLoadedAll,
  onConnect
}: MobileProfileSocialMediaProps) => {
  const { hasNewSocialMedia } = useSelector((state: RootState) => state.app);
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [isLoading, setIsLoading] = useState(false);

  const [arrSocial, setArrSocial] = useState<Array<any>>([]);
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (userId) {
      setIsOwner(userId === userInfo?.id);
      loadUserSocial();
    }
  }, [userId, hasNewSocialMedia]);

  const loadUserSocial = async () => {
    setIsLoading(true);

    const res = await apiGetUserSocials(userId);
    if (res.success) {
      setArrSocial(res.socials);
    }

    setIsLoading(false);
  };

  const twitterHandleName = useMemo(() => {
    if (arrSocial.filter((item, index) => item.type === 'twitter').length > 0) {
      return arrSocial.filter((item, index) => item.type === 'twitter')[0]
        .handle;
    } else {
      return '';
    }
  }, [arrSocial]);

  const openSocialUrl = (
    type:
      | 'twitter'
      | 'instagram'
      | 'tiktok'
      | 'reddit'
      | 'snapchat'
      | 'youtube'
      | 'spotify'
  ) => {
    if (type === 'twitter') {
      window.open(`https://twitter.com/${twitterHandleName}`);
    } else if (type === 'instagram') {
      window.open(`https://www.instagram.com/${instagramHandleName}`);
    } else if (type === 'tiktok') {
      window.open(`https://www.tiktok.com/@${tiktokHandleName}`);
    } else if (type === 'reddit') {
      window.open(`https://www.reddit.com/user/${redditHandleName}`);
    } else if (type === 'snapchat') {
      window.open(`https://www.snapchat.com/add/${snapChatHandleName}`);
    } else if (type === 'youtube') {
      window.open(`https://www.youtube.com/user/${youtubeHandleName}`);
    } else if (type === 'spotify') {
      window.open(`https://www.spotify.com/us/account/overview/`);
    }
  };

  const youtubeHandleName = useMemo(() => {
    if (arrSocial.filter((item, index) => item.type === 'youtube').length > 0) {
      return arrSocial.filter((item, index) => item.type === 'youtube')[0]
        .handle;
    } else {
      return '';
    }
  }, [arrSocial]);

  const spotifyHandleName = useMemo(() => {
    if (arrSocial.filter((item, index) => item.type === 'spotify').length > 0) {
      return arrSocial.filter((item, index) => item.type === 'spotify')[0]
        .handle;
    } else {
      return '';
    }
  }, [arrSocial]);

  const tiktokHandleName = useMemo(() => {
    if (arrSocial.filter((item, index) => item.type === 'tiktok').length > 0) {
      return arrSocial.filter((item, index) => item.type === 'tiktok')[0]
        .handle;
    } else {
      return '';
    }
  }, [arrSocial]);

  const isExistedTikTok = useMemo(() => {
    if (arrSocial.filter((item, index) => item.type === 'tiktok').length > 0) {
      return true;
    } else {
      return false;
    }
  }, [arrSocial]);

  const isExistedTwitter = useMemo(() => {
    if (arrSocial.filter((item, index) => item.type === 'twitter').length > 0) {
      return true;
    } else {
      return false;
    }
  }, [arrSocial]);

  const isExistedSpotify = useMemo(() => {
    if (arrSocial.filter((item, index) => item.type === 'spotify').length > 0) {
      return true;
    } else {
      return false;
    }
  }, [arrSocial]);

  const isExistedInstagram = useMemo(() => {
    if (
      arrSocial.filter((item, index) => item.type === 'instagram').length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }, [arrSocial]);

  const isExistedReddit = useMemo(() => {
    if (arrSocial.filter((item, index) => item.type === 'reddit').length > 0) {
      return true;
    } else {
      return false;
    }
  }, [arrSocial]);

  const instagramHandleName = useMemo(() => {
    if (
      arrSocial.filter((item, index) => item.type === 'instagram').length > 0
    ) {
      return arrSocial.filter((item, index) => item.type === 'instagram')[0]
        .handle;
    } else {
      return '';
    }
  }, [arrSocial]);

  const redditHandleName = useMemo(() => {
    if (arrSocial.filter((item, index) => item.type === 'reddit').length > 0) {
      return arrSocial.filter((item, index) => item.type === 'reddit')[0]
        .handle;
    } else {
      return '';
    }
  }, [arrSocial]);

  const isExistedSnapChat = useMemo(() => {
    if (
      arrSocial.filter((item, index) => item.type === 'snapchat').length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }, [arrSocial]);

  const snapChatHandleName = useMemo(() => {
    if (
      arrSocial.filter((item, index) => item.type === 'snapchat').length > 0
    ) {
      return arrSocial.filter((item, index) => item.type === 'snapchat')[0]
        .handle;
    } else {
      return '';
    }
  }, [arrSocial]);

  const isExistedYoutube = useMemo(() => {
    if (arrSocial.filter((item, index) => item.type === 'youtube').length > 0) {
      return true;
    } else {
      return false;
    }
  }, [arrSocial]);

  const renderBody = () => {
    return (
      <div className="mobile_profile_social_container_body">
        {isExistedInstagram && (
          <div
            className="social_item"
            onClick={() => openSocialUrl('instagram')}
          >
            <InstagramIcon color={'#FF3E9A'} size={32} />
            <div className="social_item_divider" />
            <div className="social_item_name font-regular font16">
              {instagramHandleName}
            </div>
          </div>
        )}
        {isExistedTikTok && (
          <div className="social_item" onClick={() => openSocialUrl('tiktok')}>
            <TikTokIcon color={'#333333'} size={36} />
            <div className="social_item_divider" />
            <div className="social_item_name font-regular font16">
              {tiktokHandleName}
            </div>
          </div>
        )}
        {isExistedTwitter && (
          <div className="social_item" onClick={() => openSocialUrl('twitter')}>
            <TwitterIcon color={'#2D9CDB'} size={32} />
            <div className="social_item_divider" />
            <div className="social_item_name font-regular font16">
              {twitterHandleName}
            </div>
          </div>
        )}
        {isExistedReddit && (
          <div className="social_item" onClick={() => openSocialUrl('reddit')}>
            <RedditIcon color={'#2D9CDB'} size={32} />
            <div className="social_item_divider" />
            <div className="social_item_name font-regular font16">
              {redditHandleName}
            </div>
          </div>
        )}
        {isExistedSnapChat && (
          <div
            className="social_item"
            onClick={() => openSocialUrl('snapchat')}
          >
            <SnapChatIcon color={'#2D9CDB'} size={32} />
            <div className="social_item_divider" />
            <div className="social_item_name font-regular font16">
              {snapChatHandleName}
            </div>
          </div>
        )}
        {isExistedYoutube && (
          <div className="social_item" onClick={() => openSocialUrl('youtube')}>
            <YoutubIcon size={32} />
            <div className="social_item_divider" />
            <div className="social_item_name font-regular font16">
              {youtubeHandleName}
            </div>
          </div>
        )}
        {isExistedSpotify && (
          <div className="social_item" onClick={() => openSocialUrl('spotify')}>
            <SpotifyIcon size={32} />
            <div className="social_item_divider" />
            <div className="social_item_name font-regular font16">
              {spotifyHandleName}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderEmptyBody = () => {
    return (
      <div className="mobile_profile_social_container_body">
        <div className="social_item_icon">
          <TikTokIcon color={'#333333'} size={42} />
        </div>
        <div className="social_item_icon">
          <InstagramIcon color="#FF3E9A" size={38} />
        </div>
        <div className="social_item_icon">
          <YoutubIcon size={38} />
        </div>
        <div className="social_item_divider"></div>
        <div
          className="font-bold font14"
          style={{ marginLeft: 16, whiteSpace: 'nowrap' }}
        >
          No social connected
        </div>
      </div>
    );
  };

  const renderTop = () => {
    return (
      <div className="profile_social_body row_space_between">
        <div className="row_align_items">
          <img src={SocialMediasPng} width={24} height={24} />
          <div className="font-bold font16" style={{ marginLeft: 8 }}>
            Social Media
          </div>
        </div>
        {isOwner && (
          <div className="row_align_items" onClick={onConnect}>
            <div className="align-center">
              <EditPencilColoredIcon size={14} />
            </div>
            <div className="edit_text font-bold font12">Edit</div>
          </div>
        )}
      </div>
    );
  };

  const renderDivider = () => {
    return <div className="profile_section_divider"></div>;
  };

  const renderContent = () => {
    return !isExistedInstagram &&
      !isExistedTikTok &&
      !isExistedTwitter &&
      !isExistedReddit &&
      !isExistedSnapChat &&
      !isExistedSpotify
      ? renderEmptyBody()
      : renderBody();
  };

  const renderLoadingBar = () => {
    return (
      <div className="mobile_profile_social_container_body">
        <div className="loading_container">
          <LoadingCircular size={20} color="main" />
        </div>
      </div>
    );
  };

  if (isLoadedAll) {
    return (
      <div className="mobile_profile_social">
        {renderDivider()}
        <div className="mobile_profile_social_container">
          {renderTop()}
          {isLoading ? renderLoadingBar() : renderContent()}
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};
