import ShareIcon from 'assets/svgs/ShareIcon';
import UCheckIcon from 'assets/svgs/UCheckIcon';
import React, { useEffect, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { apiChatShareArticle, apiChatShareQA } from 'shared/api/chat';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { showToast } from 'shared/ui-kit/PrimaryToast';

import './index.styles.scss';

interface Props {
  open: boolean;
  roomId: any;
  messageId: any;
  showFullOptions: boolean;
  onClose: () => void;
  onSelectedOption: (option: 'selection') => void;
}

export const MobileShareChatModal = ({
  open,
  roomId,
  messageId,
  showFullOptions,
  onClose,
  onSelectedOption
}: Props) => {
  const [selectedType, setSelectedType] = useState<
    'question' | 'question_answer' | 'selection' | 'article' | ''
  >('');

  const [isSharingAsArticle, setIsShareingAsArticle] = useState(false);
  const [isSharedAsArticle, setIsSharedAsArticle] = useState(false);

  const [isSharingQuestion, setIsSharingQuestion] = useState(false);
  const [isSharingQuestionAnswer, setIsSharingQuestionAnswer] = useState(false);

  useEffect(() => {
    setIsSharedAsArticle(false);
  }, [open]);

  const onShareAsArticle = async () => {
    if (!isSharingAsArticle) {
      setIsShareingAsArticle(true);

      const res = await apiChatShareArticle({
        room: roomId
      });

      if (res.success) {
        setIsSharedAsArticle(true);
      }

      setIsShareingAsArticle(false);
    }
  };

  const onShareQuestion = async () => {
    if (!isSharingQuestion) {
      setIsSharingQuestion(true);

      const res = await apiChatShareQA({
        room: roomId,
        messageId: messageId,
        answer: false
      });

      if (res.success) {
        onClose();

        showToast('success', 'Shared successfully!');
      }

      setIsSharingQuestion(false);
    }
  };

  const onShareQuestionAnswer = async () => {
    if (!isSharingQuestionAnswer) {
      setIsSharingQuestionAnswer(true);

      const res = await apiChatShareQA({
        room: roomId,
        messageId: messageId,
        answer: true
      });

      if (res.success) {
        onClose();
        showToast('success', 'Shared successfully!');
      }

      setIsSharingQuestionAnswer(false);
    }
  };

  const onSelected = (type) => {
    setSelectedType(type);
  };

  const renderBody = () => {
    return (
      <div className="list_body">
        {showFullOptions && (
          <div
            className="item_container row_align_items"
            onClick={() => {
              onSelected('question');
              onShareQuestion();
            }}
          >
            {isSharingQuestion ? (
              <LoadingCircular size={20} color="main" />
            ) : (
              <ShareIcon />
            )}
            <div className="font-regular font16">Share question</div>
          </div>
        )}
        {showFullOptions && (
          <div
            className="item_container row_align_items"
            onClick={() => {
              onSelected('question_answer');
              onShareQuestionAnswer();
            }}
          >
            {isSharingQuestionAnswer ? (
              <LoadingCircular size={20} color="main" />
            ) : (
              <ShareIcon />
            )}
            <div className="font-regular font16">Share question and answer</div>
          </div>
        )}
        <div
          className="item_container row_align_items"
          onClick={() => {
            onSelectedOption('selection');
          }}
        >
          <ShareIcon />
          <div className="font-regular font16">Share selection</div>
        </div>
        <div
          className="item_container row_align_items"
          onClick={() => {
            onShareAsArticle();
          }}
        >
          {isSharingAsArticle ? (
            <LoadingCircular size={20} color="main" />
          ) : (
            <ShareIcon />
          )}
          <div className="font-regular font16">Share as article</div>
        </div>
        {renderCloseBtn()}
      </div>
    );
  };

  const renderSharedAsBody = () => {
    return (
      <div className="shared_as_article">
        <div className="success_mark">
          <UCheckIcon size={40} color={'white'} />
        </div>
        <div className="text_view">
          <div className="font-bold font18">
            Chat Article successfully shared
          </div>
          <div
            className="font-regular font16"
            style={{ opacity: 0.6, textAlign: 'center' }}
          >
            The article is currently being generated and will be available
            shortly.
          </div>
        </div>
        <PrimaryButton
          label="Continue"
          onPressed={() => {
            onClose();
          }}
        />
      </div>
    );
  };

  const renderCloseBtn = () => {
    return (
      <PrimaryButton
        label="Close"
        fontSize={14}
        onPressed={() => {
          onClose();
        }}
      />
    );
  };

  return (
    <Sheet isOpen={open} onClose={onClose} detent={'content-height'}>
      <Sheet.Container
        style={{ borderTopLeftRadius: 32, borderTopRightRadius: 32 }}
      >
        <Sheet.Content>
          <div className="mobile_share_chat_modal">
            <div
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                height: '4px',
                width: '56px',
                borderRadius: 4
              }}
            />
            {isSharedAsArticle ? renderSharedAsBody() : renderBody()}
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
