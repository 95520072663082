import React, { useState } from 'react';

import './index.styles.scss';
import { MobileInviteUsingContact } from './InviteUsingContact';
import { MobileInviteUsingEmail } from './InviteUsingEmail';
import { MobileInviteUsingQR } from './InviteUsingQR';

export const MobileInviteBody = () => {
  const [tab, setTab] = useState<'email' | 'qr' | 'contact'>('email');

  const renderTab = () => {
    return (
      <div className="invite_body_tab">
        <div
          className={
            (tab === 'email'
              ? 'font-bold selected_tab_item'
              : 'font-regular default_tab_item') + ' font12'
          }
          onClick={() => setTab('email')}
        >
          Email
        </div>
        <div
          className={
            (tab === 'qr'
              ? 'font-bold selected_tab_item'
              : 'font-regular default_tab_item') + ' font12'
          }
          onClick={() => setTab('qr')}
        >
          QR Code
        </div>
        {/* <div
          className={
            (tab === 'contact'
              ? 'font-bold selected_tab_item'
              : 'font-regular default_tab_item') + ' font12'
          }
          onClick={() => setTab('contact')}
        >
          Contact
        </div> */}
      </div>
    );
  };

  const renderUsingEmail = () => {
    return <MobileInviteUsingEmail />;
  };

  const renderUsingQr = () => {
    return <MobileInviteUsingQR />;
  };

  const renderUsingContact = () => {
    return <MobileInviteUsingContact />;
  };

  return (
    <div className="mobile_invite_body">
      {renderTab()}
      {tab === 'email' && renderUsingEmail()}
      {tab === 'qr' && renderUsingQr()}
      {tab === 'contact' && renderUsingContact()}
    </div>
  );
};
