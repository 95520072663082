import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiQAGetShareLink } from 'shared/api/qa';
import { apiGetTopicPercentage, apiGetUserSharingQR } from 'shared/api/user';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { getImageFromHtml } from 'shared/utils/utils';

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';

import Download1Icon from 'assets/svgs/Download1Icon';
import WhatsappIcon from 'assets/svgs/WhatsappIcon';
import TelegramIcon from 'assets/svgs/TelegramIcon';
import InstagramIcon from 'assets/svgs/InstagramIcon';
import TwitterIcon from 'assets/svgs/TwitterIcon';
import TikTokIcon from 'assets/svgs/TikTokIcon';

import './index.styles.scss';

export const MobileInviteUsingQR = () => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const { uploadBase64 } = useIPFS();
  const [shareUrl, setShareUrl] = useState('');
  const [qrCodeUrl, setQRCodeUrl] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [percentInfo, setPercentInfo] = useState<any>(null);

  useEffect(() => {
    getSharingQRcode();
    loadPercentInfo();
  }, []);

  const loadPercentInfo = async () => {
    const res = await apiGetTopicPercentage({
      userId: userInfo?.id
    });
    setPercentInfo(res.data);
  };

  const getSharingQRcode = async () => {
    const res = await apiGetUserSharingQR();
    if (res.success) {
      setQRCodeUrl(res.qr_url);
      initShareUrl();
    }
  };

  const initShareUrl = async () => {
    const base64Image = await getImageFromHtml('mobile_share_body_image');
    const url = await uploadBase64(base64Image, 'image/png', '');

    const res = await apiQAGetShareLink({
      url: url,
      item_id: userInfo?.id,
      type: 'user'
    });

    if (res.success) {
      setShareUrl(res.sharing_url);
    }
  };

  const onShare = async (type: 'download') => {
    if (!isDownloading)
      if (type === 'download') {
        setIsDownloading(true);
        const base64Image = await getImageFromHtml('mobile_share_body_image');
        const url = await uploadBase64(base64Image, 'image/png', '');

        await onDownloadFile(url);
        setIsDownloading(true);
      }
  };

  const onDownloadFile = async (fileUrl) => {
    if (!isDownloading) {
      setIsDownloading(true);

      fetch(fileUrl)
        .then((response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'share_image';
            alink.click();
          });
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  };

  const renderBody = () => {
    return (
      <div className="main_body_invite">
        <div className="qr_image_body" id="mobile_share_body_image">
          <div className="row_align_items">
            <div className="align-vertical-center">
              {userInfo?.image && userInfo?.image.startsWith('https') ? (
                <img
                  src={userInfo?.image}
                  style={{ width: 32, height: 32, borderRadius: 32 }}
                />
              ) : (
                <DefaultAvatarIcon size={32} />
              )}
            </div>
            <div style={{ marginLeft: 6 }}>
              <div
                className="font-bold font12"
                style={{ whiteSpace: 'nowrap' }}
              >
                {userInfo?.name}
              </div>
              {percentInfo && renderPercentInfo()}
            </div>
          </div>
          <div className="image_view1">
            <img
              src={qrCodeUrl}
              style={{ width: '100%', height: '100%' }}
              crossOrigin="anonymous"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPercentInfo = () => {
    return (
      <div
        className="font-regular font10"
        style={{ marginTop: 4, whiteSpace: 'nowrap' }}
      >
        {`Top ${percentInfo!.percentile}% in ${percentInfo!.topic_emoji} ${
          percentInfo!.topic_name
        }`}
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="bottom">
        <div className="divider"></div>
        <div className="desc_container font-bold font12">
          Will earn &nbsp;<div style={{ color: '#FF3E9A' }}>+100 pts</div>
          &nbsp;after they accept the invitation
        </div>
        <div style={{ margin: '16px' }}>{renderSocials()}</div>
      </div>
    );
  };

  const renderSocials = () => {
    return (
      <div className="share_bottom">
        <div className="statement_link_bar_items">
          <div
            className="statement_link_bar_items_item download_itm clickable"
            onClick={() => onShare('download')}
          >
            {isDownloading ? (
              <LoadingCircular size={20} color={'white'} />
            ) : (
              <Download1Icon />
            )}
          </div>
          <div className="font10">Download</div>
        </div>
        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <WhatsappShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Whatsapp_itm">
                <WhatsappIcon />
              </div>
              <div className="font10">Whatsapp</div>
            </div>
          </WhatsappShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TelegramShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Telegram_itm ">
                <TelegramIcon />
              </div>
              <div className="font10">Telegram</div>
            </div>
          </TelegramShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <FacebookShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div
                className="statement_link_bar_items_item Telegram_itm "
                style={{ padding: 0 }}
              >
                <FacebookIcon borderRadius={16} size={48} />
              </div>
              <div className="font10">Facebook</div>
            </div>
          </FacebookShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TwitterShareButton title={''} url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Twitter_itm">
                <TwitterIcon />
              </div>
              <div className="font10">Twitter</div>
            </div>
          </TwitterShareButton>
        )}
      </div>
    );
  };

  return (
    <div className="invite_using_qr">
      {renderBody()}
      {renderBottom()}
    </div>
  );
};
