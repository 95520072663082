import React, { useEffect, useState } from 'react';
import Typewriter from 'typewriter-effect';

import './index.styles.scss';

interface TypeLoadingTextProps {
  text: string;
  speed: number;
  fontSize?: string;
  onFinished: () => void;
  onStarted?: () => void;
}

export const TypeLoadingText = ({
  text = '',
  fontSize,
  speed = 20,
  onFinished,
  onStarted
}: TypeLoadingTextProps) => {
  return (
    <pre>
      <Typewriter
        options={{ delay: speed, wrapperClassName: fontSize ?? 'font20' }}
        onInit={(typewriter) => {
          onStarted && onStarted();

          typewriter
            .typeString(text)
            .callFunction(() => {
              onFinished();
            })
            .start();
        }}
      />
    </pre>
  );
};
