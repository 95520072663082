import CloseIcon from 'assets/svgs/CloseIcon';
import DocumentLayoutIcon from 'assets/svgs/DocumentLayoutIcon';
import React, { useState } from 'react';
import Sheet from 'react-modal-sheet';
import { useNavigate } from 'react-router-dom';
import { apiChatSummarise } from 'shared/api/chat';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';

import './index.styles.scss';

interface Props {
  open: boolean;
  roomId: any;
  onClose: () => void;
  onEnded: () => void;
  onAddMessage: (newMessage) => void;
}

export const MobileMultichatActionModal = ({
  open,
  roomId,
  onClose,
  onEnded,
  onAddMessage
}: Props) => {
  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isSummarizng, setIsSummarizing] = useState(false);

  const onSummarize = async () => {
    if (!isSummarizng) {
      setIsSummarizing(true);

      const res = await apiChatSummarise({
        room: roomId
      });
      if (res.success) {
        onAddMessage(res.data);
        onClose();
      }

      setIsSummarizing(false);
    }
  };

  const onEndChat = async () => {
    onClose();
    onEnded();
  };

  const renderBody = () => {
    return (
      <div className="action_container">
        <div className="action_item row_align_items" onClick={onSummarize}>
          {isSummarizng ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            <DocumentLayoutIcon size={20} />
          )}
          <div className="font-regular font16">Summarize Chat</div>
        </div>
        <div className="action_item row_align_items" onClick={onEndChat}>
          {isDeleting ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            <CloseIcon size={20} color={'#FF3E9A'} />
          )}
          <div className="font-regular font16">End Chat</div>
        </div>
        {renderCloseBtn()}
      </div>
    );
  };

  const renderCloseBtn = () => {
    return (
      <PrimaryButton
        label="Close"
        fontSize={14}
        onPressed={() => {
          onClose();
        }}
      />
    );
  };

  return (
    <Sheet isOpen={open} onClose={onClose} detent={'content-height'}>
      <Sheet.Container
        style={{ borderTopLeftRadius: 32, borderTopRightRadius: 32 }}
      >
        <Sheet.Content>
          <div className="mobile_multichat_action_modal">
            <div
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                height: '4px',
                width: '56px',
                borderRadius: 4
              }}
            />
            {renderBody()}
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
