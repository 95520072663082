import React, { useState } from 'react';
import moment from 'moment';

import './index.styles.scss';
import { EditEventModal } from 'components/Modals/EditEventModal';
import EditPencilColoredIcon from 'assets/svgs/EditPencilColoredIcon';
import { useNavigate } from 'react-router-dom';

interface Props {
  data: any;
  onReloadData?: () => void;
}

export const CardEventHosted = ({ data, onReloadData }: Props) => {
  const navigate = useNavigate();
  const [showEditModal, setShowEditModal] = useState(false);

  const onDetail = (e) => {
    e.stopPropagation();
    navigate(`/school/events/${data.qr_code}`);
  };

  const renderEditModal = () => {
    return (
      <EditEventModal
        eventData={data}
        show={showEditModal}
        onClose={() => setShowEditModal(false)}
        onSuccess={() => {
          setShowEditModal(false);
          onReloadData && onReloadData();
        }}
      />
    );
  };

  return (
    <div
      className="mobile_card_event_hosted clickable row_align_items"
      onClick={onDetail}
    >
      <div style={{ flex: 1 }}>
        <div className="row_align_items">
          <div className="card_mark"></div>
          <div className="card_title font-medium font16">{data.title}</div>
        </div>
        <div
          className="row_align_items"
          style={{ marginLeft: 16, marginTop: 6 }}
        >
          <div style={{ fontSize: 14 }}>🗓</div>
          <div
            className="font-regular font14"
            style={{ color: 'rgba(0, 0, 0, 0.6)', marginLeft: 6 }}
          >
            {moment(data.date).format('DD MMM YYYY')} •{' '}
            {moment(data.date).format('hh:mm A')}
          </div>
        </div>
      </div>
      <div
        style={{ width: 24, marginRight: 8 }}
        className="clickable"
        id={`event_edit_btn_${data.id}`}
        onClick={(e) => {
          e.stopPropagation();
          setShowEditModal(true);
        }}
      >
        <EditPencilColoredIcon />
      </div>
      {renderEditModal()}
    </div>
  );
};
