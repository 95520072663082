import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiGetQAAskOrgGeneral } from 'shared/api/qa';
import Sheet from 'react-modal-sheet';

import './mobile.styles.scss';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface ShareQuestionToOrgModalProps {
  show: boolean;
  question: any;
  selectedOrgId: number;
  onClose: () => void;
  onSuccess: () => void;
}

export const MobilePostQuestionToOrgModal = ({
  show,
  question,
  selectedOrgId,
  onClose,
  onSuccess
}: ShareQuestionToOrgModalProps) => {
  const [isPostingQuestion, setIsPostingQuestion] = useState(false);
  const [questionToPost, setQuestionToPost] = useState(question);
  const [isAnonymous, setIsAnonymous] = useState(false);

  const { userInfo } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    setQuestionToPost(question);
  }, [question]);

  const onPostQuestion = async () => {
    setIsPostingQuestion(true);
    const res = await apiGetQAAskOrgGeneral({
      question: questionToPost,
      org: selectedOrgId
    });

    if (res.success) {
      showToast('success', 'Question successfully posted');
      onSuccess();
    }
    setIsPostingQuestion(false);
  };

  const renderBody = () => {
    return (
      <textarea
        value={questionToPost}
        className="textarea_question font24 font-bold"
        onChange={(e) => {
          setQuestionToPost(e.target.value);
        }}
        rows={5}
      />
    );
  };

  const renderTopAction = () => {
    return (
      <div className="top_action_view row_align_items">
        <div
          className={
            !isAnonymous
              ? 'selected_btn clickable row_align_items'
              : 'default_btn clickable row_align_items'
          }
          key={`card_ref_user_1`}
          onClick={() => setIsAnonymous(false)}
        >
          {userInfo?.image === '' ? (
            <DefaultAvatarIcon size={24} />
          ) : (
            <img src={userInfo?.image} className="user_img" />
          )}
          <div
            className="font-medium font14"
            style={{
              maxWidth: 100,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {userInfo?.name}
          </div>
        </div>
        <div
          className={
            isAnonymous
              ? 'selected_btn clickable row_align_items'
              : 'default_btn clickable row_align_items'
          }
          key={`card_ref_user_2`}
          onClick={() => setIsAnonymous(true)}
        >
          <DefaultAvatarIcon size={24} />
          <div className="font-medium font14">Anonymous</div>
        </div>
      </div>
    );
  };

  const renderBottomBar = () => {
    return (
      <div className="add_answer_bottom_bar">
        <PrimaryButton
          label="Post Question"
          fontSize={14}
          onPressed={onPostQuestion}
          isLoading={isPostingQuestion}
        />
      </div>
    );
  };
  return (
    <Sheet isOpen={show} onClose={onClose} detent={'content-height'}>
      <Sheet.Container
        style={{ borderTopLeftRadius: 32, borderTopRightRadius: 32 }}
      >
        <Sheet.Content>
          <div className="mobile_modal_post_question">
            <div className="header_view row_align_items">
              <div
                className="align-vertical-center clickable"
                onClick={() => {
                  onClose();
                }}
              >
                <AngleLeftIcon color={'black'} size={24} />
              </div>
              <div className="question_title font-bold font14">
                Post My Question
              </div>
            </div>

            <div className="mobile_modal_post_question_body">
              {renderTopAction()}
              {renderBody()}
              {renderBottomBar()}
            </div>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
