import React, { useEffect, useState } from 'react';
import { apiDeletionSearches } from 'shared/api/delete';
import { apiGetSuggestionSearches } from 'shared/api/suggest';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import { MobileCardRecentHistory } from './CardRecentHistory';
import BG_EMPTY_CREATOR from 'assets/pngs/bg_empty_chat_creator.png';

import './index.styles.scss';

export const MobileCommunityRecentSearch = () => {
  const [arrUser, setArrUser] = useState<Array<any>>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDeletingAll, setIsDeletingAll] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const res = await apiGetSuggestionSearches({});

    if (res.success) {
      setIsLoaded(true);
      setArrUser(res.data);
    }
  };

  const onRemoveAll = async () => {
    if (!isDeletingAll) {
      setIsDeletingAll(true);
      const res = await apiDeletionSearches({ item_type: 'user' });
      if (res.success) {
        showToast('success', 'Searches successfully deleted!');
        setArrUser([]);
      }

      setIsDeletingAll(false);
    }
  };

  const onRemoveFromUI = (userItem) => {
    setArrUser((prev) =>
      prev.filter((item, index) => item.creator_id !== userItem.creator_id)
    );
  };

  const renderTop = () => {
    return (
      <div className="row_space_between">
        <div className="recent_search_txt font-regular font14">
          Recent Search
        </div>
        {arrUser.length > 0 && (
          <div
            className="delete_all_txt font-regular font14"
            onClick={onRemoveAll}
          >
            {isDeletingAll ? (
              <LoadingCircular size={20} color="main" />
            ) : (
              'Delete all'
            )}
          </div>
        )}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="history_list">
        {arrUser.map((item, index) => {
          return (
            <MobileCardRecentHistory
              key={`mobile_card_recent_history_${index}`}
              data={item}
              onRemoveFromUI={onRemoveFromUI}
            />
          );
        })}
      </div>
    );
  };

  const renderEmptyCreators = () => {
    return (
      <div className="empty_creator">
        <img
          src={BG_EMPTY_CREATOR}
          style={{ width: 300, height: 230, marginBottom: 30 }}
        />
        <div className="font-bold font16">{'No Recent Searches'}</div>
        <div
          className="font-regular font12"
          style={{
            color: 'rgba(0, 0, 0, 0.4)',
            textAlign: 'center',
            paddingBottom: 16
          }}
        >
          {'Start exploring and connecting with other users!'}
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_community_search_recent">
      {renderTop()}
      {isLoaded && arrUser.length === 0 ? renderEmptyCreators() : renderBody()}
    </div>
  );
};
