import React, { useEffect, useState } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { apiGetSuggestedOrg } from 'shared/api/user';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { CardJoinOrg } from './CardJoinOrg';

import './index.styles.scss';

export const MobileJoinOrg = () => {
  const [arrOrg, setArrOrg] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (init = true) => {
    if (init) {
      setIsLoading(true);

      const res = await apiGetSuggestedOrg({
        query: null,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrOrg(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setIsLoaded(true);
      }

      setIsLoading(false);
    } else {
      if (hasMore && !isLoading) {
        const res = await apiGetSuggestedOrg({
          query: null,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrOrg((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }
      }
    }
  };

  const onLoadMoreData = () => {
    loadData(false);
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const renderBody = () => {
    return (
      <div className="mobile_join_org_body" id="mobile_join_org_body">
        <ScrollMenu onWheel={onWheel} onUpdate={onLoadMoreData}>
          {arrOrg.map((item, index) => {
            return <CardJoinOrg data={item} key={`card_join_org_${index}`} />;
          })}
        </ScrollMenu>
      </div>
    );
  };

  return (
    <div className="mobile_join_org">
      <div className="font-bold font18" style={{ marginLeft: 16 }}>
        Join New Schools
      </div>
      {isLoading ? <LoadingBarAnimation /> : renderBody()}
    </div>
  );
};
