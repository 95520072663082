import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import EditPencilIcon from 'assets/svgs/EditPencilIcon';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import './index.styles.scss';
import { apiQAAnswer } from 'shared/api/qa';
import PointIcon from 'assets/svgs/PointIcon';
import { showToast } from 'shared/ui-kit/PrimaryToast';

import { useWhisper } from '@kkaczynski/use-whisper';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import MicIcon from 'assets/svgs/MicIcon';
import { getArrFromString } from 'shared/utils/utils';
import { getToken } from 'shared/api/storageHelper';

interface Props {
  open: boolean;
  data: any;
  onClose: () => void;
  onSuccess: (answerTxt, answerId) => void;
}

export const MobileQuestionAddAnswerModal = ({
  open,
  data,
  onClose,
  onSuccess
}: Props) => {
  const { uploadBase64 } = useIPFS();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [answer, setAnswer] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [audioUrl, setAudioUrl] = useState<any>(null);

  const onTranscribe = async (blob: Blob) => {
    const base64 = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    const body = JSON.stringify({ file: base64, model: 'whisper-1' });

    const url = await uploadBase64(base64, 'audio/webm', ''); //upload to wasabi and returns url of audio recording.

    // console.log('Base64 recording: ', base64);
    const headers = { 'Content-Type': 'application/json' };
    const { default: axios } = await import('axios');
    const response = await axios.post(
      'https://webhook.site/90bbf81d-b51b-424e-83bc-d957e4c9797c',
      body,
      {
        headers
      }
    );
    const { text } = await response.data;
    // you must return result from your server in Transcript format
    return {
      blob,
      text,
      url
    };
  };

  const {
    recording,
    speaking,
    transcript,
    transcribing,
    pauseRecording,
    startRecording,
    stopRecording
  } = useWhisper({
    apiKey: getToken(),
    streaming: true,
    removeSilence: false,
    timeSlice: 1_000, // 1 second
    whisperConfig: {
      language: 'en',
      endpoint: process.env.REACT_APP_WHISPER_URL
    },
    onTranscribe
  });

  useEffect(() => {
    if (open) {
      setAnswer('');
    }
  }, [open]);

  useEffect(() => {
    if (recording && transcript.text) {
      setAnswer(transcript.text);
    }
  }, [recording, transcript]);

  useEffect(() => {
    onInput(null);
  }, [answer]);

  useEffect(() => {
    const dumpData: any = transcript;
    setAudioUrl(dumpData?.url);
  }, [transcript]);

  const onSave = async () => {
    if (!isCreating && !recording && !transcribing) {
      setIsCreating(true);

      const res = await apiQAAnswer({
        bookmark: String(data.item_id),
        answer: answer,
        is_anonymous: isAnonymous,
        media_type: 'text',
        audio: audioUrl
      });

      if (res.sucess) {
        showToast('success', 'Cool! Your answer is now up!');
        setAnswer('');
        setAudioUrl(null);

        onSuccess(answer, res.answerId);
      }
      setIsCreating(false);
    }
  };

  const onInput = (e) => {
    if (inputRef.current) {
      inputRef.current.style.height = '1px';
      inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
    }
  };

  const onStartRecord = () => {
    startRecording();
  };

  const onStopRecord = () => {
    stopRecording();
  };

  const feedTopics = useMemo(() => {
    let feedTopicsArray: Array<any> = [];
    const feedItem = data;
    const arrTopicId = getArrFromString(feedItem.topic_ids, ';');
    const arrTopicName = getArrFromString(feedItem.topics, ';');
    const arrTopicEmoji = getArrFromString(feedItem.topic_emojis, ';');

    if (arrTopicId.length > 0) {
      feedTopicsArray = arrTopicId.map((item, index) => {
        return {
          id: item,
          name: arrTopicName[index],
          emjoi: arrTopicEmoji[index]
        };
      });
    }

    return feedTopicsArray;
  }, [data]);

  const renderHeader = () => {
    return (
      <div className="modal_header row_align_items">
        <div className="item_btn" onClick={onClose}>
          <AngleLeftIcon size={24} color="rgba(0, 0, 0, 0.4)" />
        </div>
        {renderTopics()}
      </div>
    );
  };

  const renderTopics = () => {
    return (
      <div className="topic_list">
        {feedTopics.map((item, index) => {
          return (
            <div
              className={`default_topic_item font-medium font12`}
              key={`card_mobile_feed_topic_${index}`}
            >
              {`${item.emjoi} ${item.name} `}
            </div>
          );
        })}
      </div>
    );
  };

  const renderQuestion = () => {
    return (
      <div className="question_view font-bold font28">{data.question}</div>
    );
  };

  const renderActionView = () => {
    return (
      <div className="modal_action_view">
        <div className="item_view row_align_items">
          <div className="font12">👀</div>
          <div className="font-medium font10">{data.seen_total ?? 0}</div>
          <div className="font-regular font10" style={{ opacity: 0.6 }}>
            views
          </div>
        </div>
        <div className="item_view row_align_items">
          <PointIcon size={12} />
          <div className="font-bold font10">{data.points ?? 0}</div>
          <div className="font-regular font10">{`pts`}</div>
        </div>
      </div>
    );
  };

  const renderAddAnswer = () => {
    return (
      <div className="add_answer_container">
        <div className="row_align_items">
          <EditPencilIcon color={'rgba(0, 0, 0, 0.3)'} sie={20} />
          <div className="font-bold font16" style={{ marginLeft: 8 }}>
            Write Answer
          </div>
        </div>
        <textarea
          ref={inputRef}
          value={answer}
          className="font-regular font14 answer_text_area"
          placeholder="Write your answer..."
          onChange={(e) => setAnswer(e.target.value)}
        />
        {renderEditAnswerAction()}
      </div>
    );
  };

  const renderEditAnswerAction = () => {
    return (
      <div className="add_answer_bottom_bar_btns">
        <div className="add_answer_bottom_bar_referneces">
          <div
            className={
              !isAnonymous ? 'user_star_btn_selected' : 'user_star_btn'
            }
            key={`card_ref_user_1`}
            onClick={() => setIsAnonymous(false)}
          >
            {userInfo?.image ? (
              <img className="user_avatar" src={userInfo?.image} />
            ) : (
              <DefaultAvatarIcon size={36} />
            )}
          </div>
          <div
            className={isAnonymous ? 'user_star_btn_selected' : 'user_star_btn'}
            key={`card_ref_user_2`}
            onClick={() => setIsAnonymous(true)}
          >
            <DefaultAvatarIcon size={36} />
          </div>
        </div>
        <div
          className="trascript_btn"
          style={
            recording || transcribing
              ? { backgroundColor: '#FF3E9A' }
              : { backgroundColor: 'white' }
          }
          onClick={() => {
            recording ? onStopRecord() : onStartRecord();
          }}
        >
          {transcribing ? (
            <LoadingCircular size={16} color={'white'} />
          ) : (
            <MicIcon color={recording ? 'white' : '#FF3E9A'} />
          )}
        </div>
        <div className="add_answer_btn clickable" onClick={() => onSave()}>
          {isCreating ? (
            <LoadingCircular color="white" size={16} />
          ) : (
            <div className="font-bold font12" style={{ whiteSpace: 'nowrap' }}>
              Submit Answer
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Sheet isOpen={open} onClose={onClose}>
      <Sheet.Container className="mobile_feed_question_add_answer_modal_body">
        <Sheet.Content>
          <div className="mobile_feed_question_add_answer_modal">
            {renderHeader()}
            {renderQuestion()}
            {renderActionView()}
            {renderAddAnswer()}
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
