import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import FilterIcon from 'assets/svgs/FilterIcon';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchBox } from 'shared/ui-kit/SearchBox';

import './index.styles.scss';
import { MobileCommunityRecentSearch } from './RecentSearch';
import { MobileCommunitySearchedUser } from './SearchedUser';

export const MobileCommunitySearch = () => {
  const navigate = useNavigate();

  const [query, setQuery] = useState('');

  const renderHeader = () => {
    return (
      <div className="top_view row_align_items">
        <div className="back_btn" onClick={() => navigate(-1)}>
          <AngleLeftIcon color="#323232" size={24} />
        </div>
        <div className="header_title font-bold font18">Search</div>
        <div className="filter_btn"></div>
      </div>
    );
  };

  const renderSearchBox = () => {
    return (
      <div className="search_view">
        <SearchBox
          onSearch={setQuery}
          height="50px"
          enableShadow={true}
          fontSize={16}
        />
      </div>
    );
  };

  const renderSearchedUser = () => {
    return <MobileCommunitySearchedUser query={query} />;
  };

  const renderHistory = () => {
    return <MobileCommunityRecentSearch />;
  };

  return (
    <div className="mobile_community_search">
      {renderHeader()}
      {renderSearchBox()}
      {!!query ? renderSearchedUser() : renderHistory()}
    </div>
  );
};
