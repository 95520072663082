import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import CoinIcon from 'assets/svgs/CoinIcont';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useState } from 'react';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import moment from 'moment';

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';

import Download1Icon from 'assets/svgs/Download1Icon';
import WhatsappIcon from 'assets/svgs/WhatsappIcon';
import TelegramIcon from 'assets/svgs/TelegramIcon';
import InstagramIcon from 'assets/svgs/InstagramIcon';
import TwitterIcon from 'assets/svgs/TwitterIcon';
import TikTokIcon from 'assets/svgs/TikTokIcon';

import './index.styles.scss';
import { getImageFromHtml } from 'shared/utils/utils';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { apiQAGetShareLink } from 'shared/api/qa';
import { apiGetUserSharingQR } from 'shared/api/user';

interface ModalProps {
  show: boolean;
  eventData: any;
  onClose: () => void;
}

export const MobileShareEventModal = ({
  show,
  eventData,
  onClose
}: ModalProps) => {
  const { uploadBase64 } = useIPFS();
  const [shareUrl, setShareUrl] = useState('');
  const [qrCodeUrl, setQRCodeUrl] = useState('');

  useEffect(() => {
    if (show && eventData) {
      getSharingQRcode();
    }
  }, [show, eventData]);

  useEffect(() => {
    initShareUrl();
  }, [qrCodeUrl]);

  const getSharingQRcode = async () => {
    const res = await apiGetUserSharingQR();
    if (res.success) {
      setQRCodeUrl(res.qr_url);
    }
  };

  const initShareUrl = async () => {
    const base64Image = await getImageFromHtml('mobile_share_body_image');
    const url = await uploadBase64(base64Image, 'image/png', '');

    const res = await apiQAGetShareLink({
      url: url,
      item_id: eventData.id,
      type: 'event'
    });

    if (res.success) {
      setShareUrl(res.sharing_url);
    }
  };

  const renderHeader = () => {
    return (
      <div className="share_header row_align_items">
        <div onClick={onClose}>
          <BlackBackIcon />
        </div>
        <div className="header_title font-bold font18">Share to Social</div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="share_body">
        <div className="share_main_body" id={'mobile_share_body_image'}>
          {renderSubHeader()}
          <div className="row_space_between">
            {renderInfo()}
            {renderImage()}
          </div>
        </div>
      </div>
    );
  };

  const renderSubHeader = () => {
    return (
      <div className="card_header row_space_between">
        <div className="row_align_items">
          <div className="row_align_items">
            {eventData.creator_image?.startsWith('https') ? (
              <img src={eventData.creator_image} className="item_image" />
            ) : (
              <DefaultAvatarIcon size={28} />
            )}
            {eventData.org_image?.startsWith('https') ? (
              <img
                src={eventData.org_image}
                className="item_image"
                style={{ marginLeft: -6 }}
              />
            ) : (
              <div style={{ marginLeft: -6 }}>
                <DefaultAvatarIcon size={28} />
              </div>
            )}
          </div>
          <div className="font-bold font12" style={{ marginLeft: 4 }}>
            {eventData.creator_name}
            <span
              className="font-bold font12"
              style={{ opacity: 0.4, margin: '0px 4px' }}
            >
              in
            </span>
            {eventData.org_name}
          </div>
        </div>
        <div className="statement_card_bottom_btns clickable">
          {!!qrCodeUrl && (
            <div className="statement_card_bottom_btns_QR">
              <img
                src={qrCodeUrl}
                style={{ width: '60px', height: '60px' }}
                crossOrigin="anonymous"
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <div className="info_view">
        <div className="row_align_items">
          <div className="card_mark"></div>
          <div className="card_title font-medium font24">{eventData.title}</div>
        </div>
        <div className="row_align_items" style={{}}>
          <div style={{ fontSize: 14 }}>📍</div>
          <div className="card_desc font-regular font14">
            {eventData.location}
          </div>
        </div>
        <div className="row_align_items">
          <div className="row_align_items" style={{}}>
            <div style={{ fontSize: 14 }}>🗓</div>
            <div
              className="font-regular font14"
              style={{ color: 'rgba(0, 0, 0, 0.6)', marginLeft: 6 }}
            >
              {moment(eventData.date).format('DD MMM YYYY')} •{' '}
              {moment(eventData.date).format('hh:mm A')}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderImage = () => {
    if (eventData.image?.startsWith('https')) {
      return <img src={eventData.image} className="event_image" />;
    } else {
      return <div></div>;
    }
  };

  const renderBottom = () => {
    return (
      <div className="share_bottom">
        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <WhatsappShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Whatsapp_itm">
                <WhatsappIcon />
              </div>
              <div className="font10">Whatsapp</div>
            </div>
          </WhatsappShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TelegramShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Telegram_itm ">
                <TelegramIcon />
              </div>
              <div className="font10">Telegram</div>
            </div>
          </TelegramShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <FacebookShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div
                className="statement_link_bar_items_item Telegram_itm "
                style={{ padding: 0 }}
              >
                <FacebookIcon borderRadius={16} size={48} />
              </div>
              <div className="font10">Facebook</div>
            </div>
          </FacebookShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TwitterShareButton title={''} url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Twitter_itm">
                <TwitterIcon />
              </div>
              <div className="font10">Twitter</div>
            </div>
          </TwitterShareButton>
        )}
      </div>
    );
  };

  return (
    <PrimaryModal
      show={show}
      width={'100%'}
      style={{ width: '100%', height: '100%', borderRadius: 0, padding: 0 }}
      onClose={onClose}
    >
      <div
        className="mobile_share_question_modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {renderHeader()}
        {renderBody()}
        {renderBottom()}
      </div>
    </PrimaryModal>
  );
};
