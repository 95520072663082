import React, { useState } from 'react';
import { apiOrgRequestMagicLink } from 'shared/api/org';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import { validateEmail } from 'shared/utils/utils';

import './index.styles.scss';
interface CreateGroupModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess: (email: string) => void;
}

export const JoinOrgByEmailModal = ({
  show,
  onClose,
  onSuccess
}: CreateGroupModalProps) => {
  const [isSending, setIsSending] = useState(false);
  const [additionalEmail, setAdditionalEmail] = useState('');

  const onSend = async () => {
    if (validateEmail(additionalEmail) && !isSending) {
      setIsSending(true);

      const res = await apiOrgRequestMagicLink({ email: additionalEmail });
      if (res.success) {
        onSuccess(additionalEmail);
      }

      setIsSending(false);
    }
  };

  return (
    <PrimaryModal show={show} width={500} onClose={onClose}>
      <div className="modal_create_group">
        <div className="modal_create_group_header">
          <div className="title font-bold font24">Add Additional Org Email</div>
        </div>
        <div className="modal_create_group_body">
          <div className="group_name">
            <div className="sub_title font-bold">Email</div>
            <div>
              <input
                value={additionalEmail}
                placeholder="Enter your email here..."
                className="sub_input font-bold font16"
                onChange={(e) => setAdditionalEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="btn_create">
            <div
              className="cancel_btn font14 font-bold clickable"
              onClick={() => onClose()}
            >
              Cancel
            </div>
            <PrimaryButton
              label="Verify"
              isLoading={isSending}
              onPressed={onSend}
            />
          </div>
        </div>
      </div>
    </PrimaryModal>
  );
};
