import React from 'react';

import './index.styles.scss';

const topCardHeight = 30;

interface Props {
  number: any;
  fillColor?: string;
  content: React.ReactNode;
}

export const FolderCard = ({ number, fillColor, content }: Props) => {
  return (
    <div className="folder_card">
      <FolderCardTop number={number} fillColor={fillColor} />
      <FolderCardBody content={content} fillColor={fillColor} />
    </div>
  );
};

const FolderCardTop = ({ number, fillColor }) => {
  return (
    <div
      className="folder_card_top row_align_items"
      style={{ height: topCardHeight }}
    >
      <div
        className="left_view"
        style={{ backgroundColor: fillColor ?? 'white' }}
      >
        <div className="number_txt font-medium font12">{number}</div>
      </div>
      <div className="right_view">
        <TopRightIcon
          width={(42 / 24) * topCardHeight}
          height={topCardHeight}
          color={fillColor ?? 'white'}
        />
      </div>
    </div>
  );
};

const FolderCardBody = ({ content, fillColor }) => {
  return (
    <div
      className="folder_card_body"
      style={{ backgroundColor: fillColor ?? 'white' }}
    >
      {content}
    </div>
  );
};

function TopRightIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? 41}
      height={props.height ?? 24}
      fill="none"
      viewBox="0 0 41 24"
    >
      <path
        fill={props.color ?? '#fff'}
        stroke="#FF3E9A"
        strokeWidth={0.8}
        strokeLinejoin="round"
        d="M.5.503h.006c7.53.1 13.854 4.765 20.218 10.142.817.69 1.634 1.392 2.455 2.096 4.795 4.117 9.692 8.323 15.154 11.759H.5V"
      ></path>
    </svg>
  );
}
