import React, { useEffect, useMemo, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { MobileFeedCollectFilter } from './CollectFilter';
import { MobileFeedCreatorFilter } from './CreatorFilter';
import { MobileFeedDocFilter } from './DocFilter';

import './index.styles.scss';
import { MobileFeedTopicFilter } from './TopicFilter';
import { MobileFeedTypeFilter } from './TypeFilter';

interface Props {
  filters: any;
  open: boolean;
  onClose: () => void;
  onDone: (items: Array<any>) => void;
}

export const MobileFeedFilterModal = ({
  filters,
  open,
  onDone,
  onClose
}: Props) => {
  const [tab, setTab] = useState<
    'type' | 'interest' | 'collect' | 'creator' | 'doc'
  >('type');
  const [selectedFilter, setSelectedFilter] = useState<Array<any>>([]);

  useEffect(() => {
    setSelectedFilter(filters);
  }, [filters]);

  useEffect(() => {
    setSelectedFilter([
      { type: 'type', id: 0, item_type: 'type' },
      { type: 'collective', id: -1, item_type: 'organisation' },
      { type: 'creator', id: -1, item_type: 'creator' },
      { type: 'doc', id: -1, item_type: 'doc' }
    ]);
  }, []);

  const onSelectedFilter = (item) => {
    if (item.item_type === 'type') {
      if (item.id === 0) {
        setSelectedFilter([
          { type: 'type', id: 0, item_type: 'type' },
          { type: 'collective', id: -1, item_type: 'organisation' },
          { type: 'creator', id: -1, item_type: 'creator' },
          { type: 'doc', id: -1, item_type: 'doc' }
        ]);
      } else {
        if (
          selectedFilter.filter(
            (sItem, sIndex) =>
              sItem.item_type === item.item_type && sItem.id === item.id
          ).length > 0
        ) {
          setSelectedFilter((prev) => [
            ...prev.filter(
              (sItem, sIndex) =>
                !(sItem.item_type === item.item_type && sItem.id === item.id)
            )
          ]);
        } else {
          setSelectedFilter((prev) => [
            ...prev.filter(
              (sItem, index) =>
                !(sItem.item_type === item.item_type && sItem.id == 0)
            ),
            item
          ]);
        }
      }
    } else if (item.item_type === 'topic') {
      if (
        selectedFilter.filter(
          (sItem, sIndex) =>
            sItem.item_type === item.item_type && sItem.id === item.id
        ).length > 0
      ) {
        setSelectedFilter((prev) => [
          ...prev.filter(
            (sItem, sIndex) =>
              !(sItem.item_type === item.item_type && sItem.id === item.id)
          )
        ]);
      } else {
        setSelectedFilter((prev) => [
          ...prev.filter(
            (sItem, index) =>
              !(sItem.item_type === item.item_type && sItem.id == 0)
          ),
          item
        ]);
      }
    } else if (item.type === 'collective') {
      if (item.id === -1) {
        setSelectedFilter((prev) => [
          ...prev.filter(
            (sItem, sIndex) => !(sItem.item_type === item.item_type)
          ),
          item
        ]);
      } else if (item.id === -2) {
        setSelectedFilter((prev) => [
          ...prev.filter(
            (sItem, sIndex) => !(sItem.item_type === item.item_type)
          ),
          item
        ]);
      } else {
        if (
          selectedFilter.filter(
            (sItem, sIndex) =>
              sItem.item_type === item.item_type && sItem.id === item.id
          ).length > 0
        ) {
          setSelectedFilter((prev) => [
            ...prev.filter(
              (sItem, sIndex) =>
                !(sItem.item_type === item.item_type && sItem.id === item.id)
            )
          ]);
        } else {
          setSelectedFilter((prev) => [
            ...prev.filter(
              (sItem, index) =>
                !(
                  sItem.item_type === item.item_type &&
                  (sItem.id == -1 || sItem.id == -2)
                )
            ),
            item
          ]);
        }
      }
    } else if (item.type === 'creator') {
      if (item.id === -1) {
        setSelectedFilter((prev) => [
          ...prev.filter(
            (sItem, sIndex) => !(sItem.item_type === item.item_type)
          ),
          item
        ]);
      } else {
        if (
          selectedFilter.filter(
            (sItem, sIndex) =>
              sItem.item_type === item.item_type && sItem.id === item.id
          ).length > 0
        ) {
          setSelectedFilter((prev) => [
            ...prev.filter(
              (sItem, sIndex) =>
                !(sItem.item_type === item.item_type && sItem.id === item.id)
            )
          ]);
        } else {
          setSelectedFilter((prev) => [
            ...prev.filter(
              (sItem, index) =>
                !(sItem.item_type === 'creator' && sItem.id == -1)
            ),
            item
          ]);
        }
      }
    } else if (item.type === 'doc') {
      if (item.id === -1) {
        setSelectedFilter((prev) => [
          ...prev.filter(
            (sItem, sIndex) => !(sItem.item_type === item.item_type)
          ),
          item
        ]);
      } else {
        if (
          selectedFilter.filter(
            (sItem, sIndex) =>
              sItem.item_type === item.item_type && sItem.id === item.id
          ).length > 0
        ) {
          setSelectedFilter((prev) => [
            ...prev.filter(
              (sItem, sIndex) =>
                !(sItem.item_type === item.item_type && sItem.id === item.id)
            )
          ]);
        } else {
          setSelectedFilter((prev) => [
            ...prev.filter(
              (sItem, index) => !(sItem.item_type === 'doc' && sItem.id == -1)
            ),
            item
          ]);
        }
      }
    }
  };

  const filterCount = useMemo(() => {
    return selectedFilter.filter(
      (sItem, sIndex) => !(sItem.id == -1 || sItem.id === 0)
    ).length;
  }, [selectedFilter]);

  const onApply = () => {
    onDone(selectedFilter);
  };

  const onReset = () => {
    setSelectedFilter([
      {
        type: 'type',
        id: 0
      }
    ]);
  };

  const renderHeader = () => {
    return (
      <div className="row_space_between row_align_items">
        <div className="font-bold font18">Filter</div>
        <div
          className="font-bold font12"
          style={{ color: '#FF3E9A' }}
          onClick={onReset}
        >
          Reset
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="mobile_feed_filter_modal">
        {renderHeader()}
        {renderTab()}
        {renderFilterBody()}
        {renderBottom()}
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="filter_bottom row_align_items">
        <div className="cancel_btn font-bold font14" onClick={onClose}>
          Cancel
        </div>
        <div className="apply_btn  font-bold font14" onClick={onApply}>
          Apply ({filterCount})
        </div>
      </div>
    );
  };

  const renderTab = () => {
    return (
      <div className="filter_tab">
        <div
          className={`${
            tab === 'type' ? 'selected_tab' : 'default_tab'
          } font-bold font12`}
          onClick={() => setTab('type')}
        >
          Type
        </div>
        <div
          className={`${
            tab === 'interest' ? 'selected_tab' : 'default_tab'
          } font-bold font12`}
          onClick={() => setTab('interest')}
        >
          Interests
        </div>
        <div
          className={`${
            tab === 'collect' ? 'selected_tab' : 'default_tab'
          } font-bold font12`}
          onClick={() => setTab('collect')}
        >
          Collective
        </div>
        <div
          className={`${
            tab === 'creator' ? 'selected_tab' : 'default_tab'
          } font-bold font12`}
          onClick={() => setTab('creator')}
        >
          Creators
        </div>
        {/* <div
          className={`${
            tab === 'doc' ? 'selected_tab' : 'default_tab'
          } font-bold font12`}
          onClick={() => setTab('doc')}
        >
          Docs
        </div> */}
      </div>
    );
  };

  const renderFilterBody = () => {
    return (
      <div className="filter_body">
        {tab === 'type' && (
          <MobileFeedTypeFilter
            selectedFilter={selectedFilter}
            onSelected={(item) => {
              onSelectedFilter(item);
            }}
          />
        )}
        {tab === 'interest' && (
          <MobileFeedTopicFilter
            selectedFilter={selectedFilter}
            onSelected={(item) => {
              onSelectedFilter(item);
            }}
          />
        )}
        {tab === 'collect' && (
          <MobileFeedCollectFilter
            selectedFilter={selectedFilter}
            onSelected={(item) => {
              onSelectedFilter(item);
            }}
          />
        )}
        {tab === 'creator' && (
          <MobileFeedCreatorFilter
            selectedFilter={selectedFilter}
            onSelected={(item) => {
              onSelectedFilter(item);
            }}
          />
        )}
        {/* {tab === 'doc' && (
          <MobileFeedDocFilter
            selectedFilter={selectedFilter}
            onSelected={(item) => {
              onSelectedFilter(item);
            }}
          />
        )} */}
      </div>
    );
  };

  return (
    <Sheet isOpen={open} onClose={onClose} detent={'content-height'}>
      <Sheet.Container
        style={{ borderTopLeftRadius: 32, borderTopRightRadius: 32 }}
      >
        <Sheet.Content>{renderBody()}</Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
