import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { apiOrgRequestMagicLink } from 'shared/api/org';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import './index.styles.scss';

interface OrgJoinMagicModalProps {
  show: boolean;
  onClose: () => void;
  email: string;
}
const RESEND_TIME = 60;
export const OrgJoinMagicModal = ({
  show,
  onClose,
  email
}: OrgJoinMagicModalProps) => {
  const [resendTime, setResendTime] = useState(RESEND_TIME);
  const [isSent, setIsSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  let timerRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    onCheck();
  }, [resendTime]);

  const onCheck = () => {
    if (resendTime == 0) {
      if (timerRef) {
        clearInterval(timerRef?.current!);
        setResendTime(RESEND_TIME);
      }
    }
  };

  const onStartCounter = () => {
    if (resendTime > 0) {
      timerRef.current = setInterval(() => {
        setResendTime((prev) => prev - 1);
      }, 1000);
    }
  };

  const onSend = async () => {
    if (resendTime === RESEND_TIME && email !== '') {
      setIsSending(true);

      const res = await apiOrgRequestMagicLink({ email: email });
      if (res.success) {
        onStartCounter();
        setIsSent(true);
      }

      setIsSending(false);
    }
  };
  const renderReSendBtn = () => {
    return (
      <div className="send_email_btn">
        <PrimaryButton
          label={resendTime == 60 ? `Resend` : `Resend (${resendTime}s)`}
          isLoading={isSending}
          onPressed={onSend}
        />
      </div>
    );
  };
  const renderHavingProblem = () => {
    return (
      <div className="already_registered">
        <div className="font14 font-regular">
          Want to let us know what you love or any issue you have?
        </div>
        <div className="check_referral font14 font-bold">{`${process.env.REACT_APP_SUPPORT_EMAIL}`}</div>
      </div>
    );
  };
  return (
    <PrimaryModal show={show} width={400} onClose={onClose}>
      <div className="modal_org_magic">
        <div className="modal_org_magic_body">
          <div className="content_body">
            <div style={{ marginTop: -44 }}>
              <div
                style={{
                  fontSize: 88,
                  margin: `0 auto 0`,
                  textAlign: 'center'
                }}
              >
                📬
              </div>
              <div className="title font-bold font24" style={{ marginTop: 22 }}>
                Woohoo, we’ve sent you a magic link to log in
              </div>
              <div
                className="font-regular font16"
                style={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginTop: 12,
                  textAlign: 'center'
                }}
              >
                Check your email app for the link,
                <br />
                sometimes we first land in your spam.
              </div>
              {renderReSendBtn()}
              {renderHavingProblem()}
            </div>
          </div>
        </div>
      </div>
    </PrimaryModal>
  );
};
