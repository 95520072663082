import HttpClient from './apiClient';

export async function apiDeletionAnswer(params: { answer: any }) {
  try {
    const response = await HttpClient.postWithToken(`deletion/answer`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletionGeneratedQuestion(params: { bookmark: any }) {
  try {
    const response = await HttpClient.postWithToken(
      `deletion/generatedQuestion`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletionBookmark(params: { bookmark: any }) {
  try {
    const response = await HttpClient.postWithToken(
      `deletion/bookmark`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletionStatement(params: { statement: any }) {
  try {
    const response = await HttpClient.postWithToken(
      `deletion/statement`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletionDump(params: { dump: any }) {
  try {
    const response = await HttpClient.postWithToken(`deletion/dump`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletionLink(params: { link: any }) {
  try {
    const response = await HttpClient.postWithToken(`deletion/link`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletionMeme(params: { meme: any }) {
  try {
    const response = await HttpClient.postWithToken(`deletion/meme`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletionEvent(params: { event: any }) {
  try {
    const response = await HttpClient.postWithToken(`deletion/event`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletionPrompt(params: { prompt: any }) {
  try {
    const response = await HttpClient.postWithToken(`deletion/prompt`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletionRoom(params: { room: any }) {
  try {
    const response = await HttpClient.postWithToken(`deletion/room`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletionSearch(params: {
  item_id: any;
  item_type: any;
}) {
  try {
    const response = await HttpClient.postWithToken(`deletion/search`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletionSearches(params: { item_type: any }) {
  try {
    const response = await HttpClient.postWithToken(
      `deletion/searches`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletionInterest(params: { interest: any }) {
  try {
    const response = await HttpClient.postWithToken(
      `deletion/interest`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletionSocialHandle(params: { id: any }) {
  try {
    const response = await HttpClient.postWithToken(
      `deletion/socialHandle`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
