import AngleDownIcon from 'assets/svgs/AngleDownIcon';
import React, { useEffect, useRef } from 'react';
import Sheet, { SheetRef } from 'react-modal-sheet';

import './index.styles.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const QRGalleryModal = ({ isOpen, onClose }: Props) => {
  const ref = useRef<SheetRef>();

  useEffect(() => {
    if (isOpen) {
      snapTo(0);
    } else {
      snapTo(1);
    }
  }, [isOpen]);

  const snapTo = (i: number) => ref.current?.snapTo(i);

  const renderTop = () => {
    return (
      <div className="row_align_items" onClick={onClose}>
        <div className="font-bold font16">Gallery</div>
        <AngleDownIcon color="rgba(0, 0, 0, 0.3)" />
      </div>
    );
  };

  return (
    <Sheet
      ref={ref}
      isOpen={isOpen}
      snapPoints={[600, 0]}
      initialSnap={1}
      onClose={() => onClose()}
    >
      <Sheet.Container
        style={{ borderTopLeftRadius: 32, borderTopRightRadius: 32 }}
      >
        <Sheet.Content>
          <div className="qr_gallery">{renderTop()}</div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
