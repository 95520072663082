import HttpClient from './apiClient';

export async function apiGetMicroInterest(params: {
  topic: any;
  userId: any;
  limit: any;
  offset: any;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `topics/microinterests`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetTopicTopUser(params: {
  topics: any;
  limit: any;
  offset: any;
}) {
  try {
    const response = await HttpClient.getWithToken(`topics/topUsers`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
