import React from 'react';

function InstagramIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 20}
      height={props.size ?? 20}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={props.color ? props.color : '#fff'}
        d="M15.34 3.46a1.2 1.2 0 100 2.4 1.2 1.2 0 000-2.4zm4.6 2.42a7.588 7.588 0 00-.46-2.43 4.94 4.94 0 00-1.16-1.77A4.7 4.7 0 0016.55.53a7.3 7.3 0 00-2.43-.47C13.06 0 12.72 0 10 0S6.94 0 5.88.06a7.3 7.3 0 00-2.43.47 4.78 4.78 0 00-1.77 1.15A4.7 4.7 0 00.53 3.45a7.3 7.3 0 00-.47 2.43C0 6.94 0 7.28 0 10s0 3.06.06 4.12a7.3 7.3 0 00.47 2.43 4.7 4.7 0 001.15 1.77 4.78 4.78 0 001.77 1.15 7.3 7.3 0 002.43.47C6.94 20 7.28 20 10 20s3.06 0 4.12-.06a7.3 7.3 0 002.43-.47 4.7 4.7 0 001.77-1.15 4.85 4.85 0 001.16-1.77c.285-.78.44-1.6.46-2.43 0-1.06.06-1.4.06-4.12s0-3.06-.06-4.12zM18.14 14a5.61 5.61 0 01-.34 1.86 3.06 3.06 0 01-.75 1.15c-.324.33-.717.586-1.15.75a5.61 5.61 0 01-1.86.34c-1 .05-1.37.06-4 .06s-3 0-4-.06a5.73 5.73 0 01-1.94-.3 3.27 3.27 0 01-1.1-.75 3 3 0 01-.74-1.15 5.54 5.54 0 01-.4-1.9c0-1-.06-1.37-.06-4s0-3 .06-4a5.54 5.54 0 01.35-1.9A3 3 0 013 3a3.14 3.14 0 011.1-.8A5.73 5.73 0 016 1.86c1 0 1.37-.06 4-.06s3 0 4 .06a5.61 5.61 0 011.86.34 3.06 3.06 0 011.19.8c.328.307.584.683.75 1.1.222.609.337 1.252.34 1.9.05 1 .06 1.37.06 4s-.01 3-.06 4zM10 4.87A5.13 5.13 0 1015.14 10 5.12 5.12 0 0010 4.87zm0 8.46a3.33 3.33 0 110-6.66 3.33 3.33 0 010 6.66z"
      ></path>
    </svg>
  );
}

export default InstagramIcon;
