import LinkIcon from 'assets/svgs/LinkIcon';
import LocationArrowIcon from 'assets/svgs/LocationArrowIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import WarningIcon from 'assets/svgs/WarningIcon';
import React from 'react';
import Sheet from 'react-modal-sheet';

import './index.styles.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  onSelected?: (type: 'share') => void;
}

export const MobileQuestionActionModal = ({
  open,
  onSelected,
  onClose
}: Props) => {
  const renderBody = () => {
    return (
      <div className="mobile_question_action_modal">
        <div className="top_div"></div>
        <div className="action_item row_align_items">
          <div className="action_icon">
            <LinkIcon color="#FF3E9A" opacity={1} />
          </div>
          <div className="font-regular font14">Copy Link to Post</div>
        </div>
        <div
          className="action_item row_align_items"
          onClick={() => {
            onSelected && onSelected('share');
          }}
        >
          <div className="action_icon">
            <ShareIcon color="#FF3E9A" size={16} />
          </div>
          <div className="font-regular font14">Share to Social Media</div>
        </div>
        <div className="action_item row_align_items">
          <div className="action_icon">
            <LocationArrowIcon color="#FF3E9A" />
          </div>
          <div className="font-regular font14">Send to Friends</div>
        </div>

        <div className="action_item row_align_items">
          <div className="action_icon">
            <WarningIcon color="#000000" size={16} opacity={0.3} />
          </div>
          <div className="font-regular font14">Report Post</div>
        </div>
      </div>
    );
  };

  return (
    <Sheet isOpen={open} onClose={onClose} detent={'content-height'}>
      <Sheet.Container
        style={{ borderTopLeftRadius: 32, borderTopRightRadius: 32 }}
      >
        <Sheet.Content>{renderBody()}</Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
