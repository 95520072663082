import CloseIcon from 'assets/svgs/CloseIcon';
import UCheckIcon from 'assets/svgs/UCheckIcon';
import React, { useEffect, useState } from 'react';
import Sheet, { SheetRef } from 'react-modal-sheet';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiCreateQADump } from 'shared/api/qa';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import { validURL } from 'shared/utils/utils';

import './index.styles.scss';

interface Props {
  aiId: any;
  open: boolean;
  onClose: () => void;
  onSuccess: (dumpId, link) => void;
  onViewFile: (aiId) => void;
}

export const MobileAddNewLinkModal = ({
  aiId,
  open,
  onClose,
  onSuccess,
  onViewFile
}: Props) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [newEmail, setNewEmail] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [isSuccessed, setIsSuccesed] = useState(false);

  useEffect(() => {
    if (!open) {
      setIsSuccesed(false);
    }
  }, [open]);

  const onCreate = async () => {
    if (validURL(newEmail)) {
      if (aiId) {
        if (!isCreating) {
          setIsCreating(true);

          const res = await apiCreateQADump({
            url: newEmail,
            type: 'url',
            to: aiId === -1 ? userInfo?.id : aiId,
            to_type: aiId === -1 ? 'user' : 'personal'
          });

          if (res.success) {
            onSuccess(res.id, newEmail);
            onClose();
            setNewEmail('');

            if (aiId === -1) {
              onClose();
            } else {
              setIsSuccesed(true);
            }
          } else {
            showToast('error', 'Unable to process link url');
          }

          setIsCreating(false);
        }
      } else {
        onSuccess(null, newEmail);
      }
    } else {
      showToast('error', 'Unable to process link url');
    }
  };

  const onContinueExplore = () => {
    onClose();
  };

  const renderHeader = () => {
    return (
      <div className="row_align_items" style={{ marginRight: 24 }}>
        <div onClick={onClose}>
          <CloseIcon />
        </div>
        <div
          className="font-bold font18"
          style={{ flex: 1, textAlign: 'center' }}
        >
          Add New Link
        </div>
      </div>
    );
  };

  const renderInput = () => {
    return (
      <div className="link_container">
        <div className="title font-bold font10">Target Link</div>
        <div className="add_link_input_container">
          <input
            value={newEmail}
            placeholder="Enter link here..."
            className="sub_input font-bold font16"
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </div>
      </div>
    );
  };

  const renderAddBody = () => {
    return (
      <div className="mobile_add_link_modal">
        {renderHeader()}
        {renderInput()}
        <div style={{ marginTop: 24 }}>
          <PrimaryButton
            label="Continue"
            isLoading={isCreating}
            onPressed={onCreate}
          />
        </div>
      </div>
    );
  };

  const renderSuccessBody = () => {
    return (
      <div className="mobile_add_link_modal">
        <div className="success_container">
          <div className="success_mark">
            <UCheckIcon size={46} />
          </div>
          <div className="text_container">
            <div className="font-bold font18">Success!</div>
            <div
              className="font-regular font18"
              style={{ opacity: 0.6, marginTop: 12, textAlign: 'center' }}
            >
              The transcribed content was uploaded to your AI
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <div
              className="continue_btn font-bold font12"
              onClick={onContinueExplore}
            >
              Continue to Explore
            </div>
            <div
              className="view_btn font-bold font12"
              onClick={() => {
                onViewFile(aiId);
              }}
            >
              View all Content Uploaded to Your AI
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Sheet isOpen={open} onClose={onClose} detent={'content-height'}>
      <Sheet.Container
        style={{ borderTopLeftRadius: 32, borderTopRightRadius: 32 }}
      >
        <Sheet.Header></Sheet.Header>
        <Sheet.Content>
          {isSuccessed ? renderSuccessBody() : renderAddBody()}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
