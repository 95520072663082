import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { sendAnalytics } from 'shared/utils/analytics';
import { MobileOtherProfile } from './OtherProfile';
import { MobileOwnProfile } from './OwnProfile';

export const MobileProfile = () => {
  const param = useParams();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const userId = param.id;

  useEffect(() => {
    sendAnalytics('Profile');
  }, []);

  if (!userId || (userInfo && userInfo!.id === parseInt(userId))) {
    return <MobileOwnProfile />;
  } else {
    return <MobileOtherProfile userId={userId} />;
  }
};
