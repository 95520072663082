import React from 'react';

import './index.styles.scss';

interface Props {
  data: Array<any>;
  selectedFilter: Array<any>;
  onUpdateFilter: (newFilters) => void;
}

export const MobileFeedTopics = ({
  data,
  selectedFilter,
  onUpdateFilter
}: Props) => {
  const onClickItem = (item) => {
    // if (
    //   selectedFilter.filter(
    //     (sItem, sIndex) =>
    //       sItem.item_type === item.item_type && sItem.id === parseInt(item.id)
    //   ).length > 0
    // ) {
    //   const newFilters = selectedFilter.filter(
    //     (sItem, sIndex) =>
    //       !(
    //         sItem.item_type === item.item_type && sItem.id === parseInt(item.id)
    //       )
    //   );
    //   onUpdateFilter(newFilters);
    // } else {
    //   const newFilters = [
    //     ...selectedFilter.filter(
    //       (sItem, index) =>
    //         !(sItem.item_type === item.item_type && sItem.id == 0)
    //     ),
    //     item
    //   ];
    //   onUpdateFilter(newFilters);
    // }
  };

  return (
    <div className="mobile_feed_topics">
      {data.map((item, index) => {
        // const isSelected =
        //   selectedFilter.filter(
        //     (sItem, index) =>
        //       sItem.type === 'topic' && sItem.id === parseInt(item.id)
        //   ).length > 0;
        const isSelected = true;

        return (
          <div
            className={`${
              isSelected ? 'topic_item' : 'default_topic_item'
            } font-regular font16`}
            key={`card_mobile_feed_topic_${index}`}
            onClick={() => {
              onClickItem({
                type: 'topic',
                id: parseInt(item.id),
                item_type: 'topic'
              });
            }}
          >
            {`${item.emjoi} ${item.name} `}
          </div>
        );
      })}
    </div>
  );
};
