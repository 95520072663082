import CommentDotIcon from 'assets/svgs/CommentIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import FolderOpenIcon from 'assets/svgs/FolderOpenIcon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';

import './index.styles.scss';
import { MobileAddNewLinkModal } from 'components/Modals/MobileAddFile/AddNewLink';
import { MobileAddNewAudio } from 'components/Modals/MobileAddFile/AddNewAudio';
import { MobileChatIntroModal } from 'components/Modals/Mobile/ChatIntroModal';
import RobotIcon from 'assets/svgs/RobotIcon';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface Props {
  data: any;
}

export const MobilePersonalTypeFirst = ({ data }: Props) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [showAddLinkModal, setShowAddLinkModal] = useState(false);
  const [showAddAudioModal, setShowAddAudioModal] = useState(false);
  const [showIntroModal, setShowIntroModal] = useState(false);

  const onViewFile = () => {
    navigate(`/personalAi/${data.id === -1 ? userInfo?.id : data.id}/first`);
  };

  const onChat = () => {
    navigate(`/conversation/${userInfo?.id}/null`);
  };

  const onAddLink = (dumpId, link) => {
    // show toast

    showToast('success', 'Successfully populated data!');
  };

  const onAddAudio = (dumpId, link) => {
    // show toast

    showToast('success', 'Successfully populated data!');
  };

  const renderAction = () => {
    return (
      <div className="action_view">
        <div className="file_btn" onClick={onViewFile}>
          <FolderOpenIcon />
          <div className="font-bold font14">Files</div>
        </div>
        <div className="chat_btn" onClick={onChat}>
          <CommentDotIcon color="white" size={24} />
          <div className="font-bold font14" style={{ color: 'white' }}>
            Chat
          </div>
        </div>
      </div>
    );
  };

  const renderAddLinkModal = () => {
    return (
      <MobileAddNewLinkModal
        aiId={data.id}
        open={showAddLinkModal}
        onClose={() => {
          setShowAddLinkModal(false);
        }}
        onSuccess={(dumpId, link) => {
          onAddLink(dumpId, link);
        }}
        onViewFile={(aiId) => {
          setShowAddLinkModal(false);
          onViewFile();
        }}
      />
    );
  };

  const renderAddAudioModal = () => {
    return (
      <MobileAddNewAudio
        aiId={data.id}
        open={showAddAudioModal}
        onClose={() => {
          setShowAddAudioModal(false);
        }}
        onSuccess={(dumpId, link) => {
          onAddAudio(dumpId, link);
        }}
        onViewFile={(aiId) => {
          setShowAddAudioModal(false);
          onViewFile();
        }}
      />
    );
  };

  const renderIntroModal = () => {
    return (
      <MobileChatIntroModal
        open={showIntroModal}
        aiInfo={data}
        onClose={() => {
          setShowIntroModal(false);
        }}
      />
    );
  };

  const renderBotIcon = () => {
    return (
      <div className="bot_icon">
        <RobotIcon size={16} color={'white'} />
      </div>
    );
  };

  return (
    <div className="mobile_personal_type_first">
      <div style={{ position: 'relative' }}>
        {userInfo?.image && userInfo?.image.startsWith('https') ? (
          <img src={userInfo?.image} className="ai_image" />
        ) : (
          <DefaultAvatarIcon size={50} />
        )}
        {renderBotIcon()}
      </div>
      <div className="ai_name font-bold font18">{'Personal AI'}</div>
      {renderAction()}
      {renderAddLinkModal()}
      {renderAddAudioModal()}
      {renderIntroModal()}
    </div>
  );
};
