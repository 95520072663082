import React from 'react';

function TwitterIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 23}
      height={props.size ?? 23}
      fill="none"
      viewBox="0 0 23 20"
    >
      <path
        fill={props.color ?? '#fff'}
        d="M22.49 1.95a.998.998 0 00-1.51-.86 7.48 7.48 0 01-1.873.794A5.152 5.152 0 0015.733.642a5.233 5.233 0 00-5.224 5.063 11.032 11.032 0 01-6.813-3.924 1.012 1.012 0 00-.858-.365.998.998 0 00-.785.5 5.275 5.275 0 00-.242 4.769l-.002.002a1.041 1.041 0 00-.495.888 3.04 3.04 0 00.026.44 5.185 5.185 0 001.568 3.312.999.999 0 00-.066.77 5.204 5.204 0 002.362 2.921 7.465 7.465 0 01-3.59.449A1 1 0 00.95 17.3a12.942 12.942 0 007.01 2.061 12.788 12.788 0 0012.465-9.363c.353-1.183.533-2.412.535-3.646v-.2A5.77 5.77 0 0022.49 1.95zm-3.306 3.212a.995.995 0 00-.234.702c.01.165.01.331.01.488a10.826 10.826 0 01-.455 3.08 10.685 10.685 0 01-10.546 7.93c-.859 0-1.715-.101-2.55-.301a9.48 9.48 0 002.942-1.564 1 1 0 00-.602-1.786 3.208 3.208 0 01-2.214-.935c.15-.028.298-.063.446-.105a1 1 0 00-.08-1.944 3.197 3.197 0 01-2.25-1.725c.18.025.362.04.545.046a1.02 1.02 0 00.983-.696 1 1 0 00-.4-1.137A3.196 3.196 0 013.36 4.344a13.014 13.014 0 008.209 3.48 1.02 1.02 0 00.817-.36 1 1 0 00.206-.867 3.164 3.164 0 01-.087-.729 3.23 3.23 0 014.506-2.962c.404.176.766.433 1.065.756a.993.993 0 00.921.298 9.27 9.27 0 001.212-.322 6.679 6.679 0 01-1.026 1.524z"
      ></path>
    </svg>
  );
}

export default TwitterIcon;
