import React from 'react';

function CommentDotIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '16'}
      height={props.size ?? '16'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={props.color ?? '#FF3E9A'}
        d="M5.335 7.336a.667.667 0 100 1.333.667.667 0 000-1.333zm2.667 0a.667.667 0 100 1.333.667.667 0 000-1.333zm2.666 0a.666.666 0 100 1.333.666.666 0 000-1.333zm-2.666-6a6.667 6.667 0 00-6.667 6.667 6.593 6.593 0 001.507 4.22l-1.333 1.333a.667.667 0 00-.14.727.666.666 0 00.633.386h6a6.667 6.667 0 000-13.333zm0 12H3.609l.62-.62a.666.666 0 00.2-.473.667.667 0 00-.2-.467 5.333 5.333 0 113.773 1.56z"
      ></path>
    </svg>
  );
}

export default CommentDotIcon;
