import React from 'react';

function AngleUpIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '20'}
      height={props.size ?? '20'}
      fill="none"
      viewBox="0 0 20 20"
    >
      <g opacity={props.opacity ?? 1}>
        <path
          fill={props.color ?? '#000'}
          d="M14.1666 12.3583C14.0104 12.5135 13.7992 12.6006 13.5791 12.6006C13.3589 12.6006 13.1477 12.5135 12.9916 12.3583L9.99992 9.40832L7.04992 12.3583C6.89378 12.5135 6.68257 12.6006 6.46242 12.6006C6.24226 12.6006 6.03105 12.5135 5.87492 12.3583C5.79681 12.2809 5.73481 12.1887 5.69251 12.0871C5.6502 11.9856 5.62842 11.8767 5.62842 11.7667C5.62842 11.6566 5.6502 11.5477 5.69251 11.4462C5.73481 11.3446 5.79681 11.2525 5.87492 11.175L9.40825 7.64165C9.48572 7.56355 9.57789 7.50155 9.67944 7.45924C9.78098 7.41694 9.88991 7.39515 9.99992 7.39515C10.1099 7.39515 10.2188 7.41694 10.3204 7.45924C10.4219 7.50155 10.5141 7.56355 10.5916 7.64165L14.1666 11.175C14.2447 11.2525 14.3067 11.3446 14.349 11.4462C14.3913 11.5477 14.4131 11.6566 14.4131 11.7667C14.4131 11.8767 14.3913 11.9856 14.349 12.0871C14.3067 12.1887 14.2447 12.2809 14.1666 12.3583Z"
        />
      </g>
    </svg>
  );
}

export default AngleUpIcon;
