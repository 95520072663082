import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiGetSuggestAIs } from 'shared/api/suggest';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { SearchBox } from 'shared/ui-kit/SearchBox';

import Columns from 'react-columns';
import BG_EMPTY_CREATOR from 'assets/pngs/bg_empty_chat_creator.png';

import './index.styles.scss';
import { MobileCardTopCreator } from '../CardTopCreator';

export const MobileSearchCreator = () => {
  const navigate = useNavigate();

  const [personalAIs, setPersonalAIs] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingAIs, setIsLoadingAIs] = useState(false);
  const [isLoadingMoreAIs, setIsLoadingMoreAIs] = useState(false);
  const [isLoadedAIs, setIsLoadedAIs] = useState(false);

  useEffect(() => {
    loadPersonalAIs();
  }, []);

  const loadPersonalAIs = async (init = true, query = '') => {
    if (init) {
      setIsLoadingAIs(true);
      const res = await apiGetSuggestAIs({
        query: query,
        type: null,
        limit: 10,
        offset: 0
      });
      if (res.success) {
        setPersonalAIs(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setIsLoadedAIs(true);
      }
      setIsLoadingAIs(false);
    } else {
      if (!isLoadingMoreAIs && hasMore) {
        setIsLoadingMoreAIs(true);

        const res = await apiGetSuggestAIs({
          query: query,
          type: null,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setPersonalAIs((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoadingMoreAIs(false);
      }
    }
  };

  const onSelectAI = (aiType, aiId) => {
    navigate(`/conversation/${aiType}/${aiId}`);
  };

  const onSearchCreators = (keyword) => {
    loadPersonalAIs(true, keyword);
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 0.5;

    if (bottom) {
      loadPersonalAIs(false);
    }
  };

  const renderHeader = () => {
    return (
      <div className="search_top_creator_header">
        <div className="search_top_view row_align_items">
          <div onClick={() => navigate(-1)}>
            <AngleLeftIcon color="#000000" size={32} />
          </div>
          <div className="title font-bold font16">Discover New AIs</div>
        </div>
        <div className="search_view">
          <SearchBox
            onSearch={onSearchCreators}
            height="40px"
            enableShadow={false}
            fontSize={16}
          />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="top_creators_body">
        {isLoadingAIs ? (
          <div className="empty_creator">
            <LoadingBarAnimation />
          </div>
        ) : (
          personalAIs.map((item, index) => {
            return (
              <MobileCardTopCreator
                index={index}
                item={item}
                isEnableFollow={true}
                key={`mobile_card_top_creator_${index}`}
                onSelectAI={onSelectAI}
              />
            );
          })
        )}
        {!isLoadingAIs &&
          personalAIs.length == 0 &&
          isLoadedAIs &&
          renderEmptyCreators()}
      </div>
    );
  };

  const renderEmptyCreators = () => {
    return (
      <div className="empty_creator">
        <img
          src={BG_EMPTY_CREATOR}
          style={{ width: 300, height: 230, marginBottom: 30 }}
        />
        <div className="font-bold font16">{'No AI Bots Found'}</div>
        <div
          className="font-regular font12"
          style={{
            color: 'rgba(0, 0, 0, 0.4)',
            textAlign: 'center',
            paddingBottom: 16
          }}
        >
          {
            'Your search did not find any matching AI bots. Please revise your search terms for more precise results.'
          }
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_search_creator" onScroll={handleScroll}>
      {renderHeader()}
      {renderBody()}
    </div>
  );
};
