import React, { useEffect, useMemo, useState } from 'react';

import './index.styles.scss';
import { useNavigate } from 'react-router-dom';
import { getArrFromString } from 'shared/utils/utils';
import { apiVotePoll } from 'shared/api/content';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';

export const MobileCardPoll = ({ data, onViewBotProfile }) => {
  const navigate = useNavigate();

  const [showActionModal, setShowActionModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const [userVote, setUserVote] = useState(data.user_vote);
  const [choiceVote, setChoiceVote] = useState(data.choice_votes);
  const [arrVotePro, setArrVotePro] = useState([]);

  useEffect(() => {
    loadArrVotePro(choiceVote);
  }, [choiceVote]);

  const loadArrVotePro = (voteData) => {
    var arrVotes = getArrFromString(voteData, ';');
    var totalVotes =
      arrVotes.reduce((a, b) => a + parseInt(b), 0) === 0
        ? 1
        : arrVotes.reduce((a, b) => a + parseInt(b), 0);

    var newArray: any = arrVotes.map((item, index) => {
      return {
        title: (index + 10).toString(36).toUpperCase(),
        pro: (parseInt(item) / totalVotes) * 100
      };
    });

    setArrVotePro(newArray.sort((a, b) => (a.pro > b.pro ? -1 : 1)));
  };

  const onVotePoll = async (voteId) => {
    const res = await apiVotePoll({
      poll: data.item_id,
      choice: parseInt(voteId)
    });

    if (res.success) {
      setUserVote(res.data.user_votes);
      setChoiceVote(res.data.choice_votes);
    }
  };

  const arrChoice = useMemo(() => {
    const arrText = getArrFromString(data.choice_names, ';');
    const arrId = getArrFromString(data.choice_ids, ';');

    return arrText.map((item, index) => {
      return {
        id: arrId[index],
        text: arrText[index]
      };
    });
  }, [data]);

  const idUserVote = useMemo(() => {
    const arrVotes = getArrFromString(userVote, ';');
    const arrId = getArrFromString(data.choice_ids, ';');

    const selectedIndex = arrVotes.findIndex((item, index) => item === '1');

    if (selectedIndex === -1) {
      return null;
    } else {
      return arrId[selectedIndex];
    }
  }, [userVote]);

  const isExistedVote = useMemo(() => {
    const votesData = getArrFromString(userVote, ';');
    return votesData.filter((item, index) => item > '0').length > 0;
  }, [userVote]);

  const renderGraph = () => {
    return (
      <div className="poll_graph">
        <div className="real_poll_graph">
          {arrVotePro
            .filter((item: any, index) => item.pro !== 0)
            .map((item: any, index) => {
              const opacity = (5 - index) / 5;

              return (
                <div
                  style={{
                    width: '100%',
                    height: item.pro + '%',
                    minHeight: 70,
                    backgroundColor: `rgba(255, 62, 154, ${opacity})`,

                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '8px 0px'
                  }}
                  key={`poll_graph_item_${index}`}
                >
                  <div
                    className="mark_view font-bold font10"
                    style={{ color: '#FF3E9A' }}
                  >
                    {item.title}
                  </div>
                  <div
                    className="font-bold font12"
                    style={{ color: 'white' }}
                  >{`${item.pro.toFixed(2)}%`}</div>
                </div>
              );
            })}
        </div>
        <div className="empty_poll_graph">
          {arrVotePro
            .filter((item: any, index) => item.pro == 0)
            .map((item: any, index) => {
              return (
                <div
                  style={{
                    width: '100%',
                    height: 70,
                    backgroundColor: `transparent`,

                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '8px 0px',
                    borderTop: '1px solid rgba(0, 0, 0, 0.1)'
                  }}
                  key={`poll_graph_item_${index}`}
                >
                  <div className="mark_view font-medium font10">
                    {item.title}
                  </div>
                  <div className="font-medium font12">{`${item.pro.toFixed(
                    2
                  )}%`}</div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const renderVoteBody = () => {
    return (
      <div className="poll_main_body">
        <div>
          <div className="font-bold font32">{data.question}</div>
          {renderUserInfo()}
        </div>

        {arrChoice.map((item, index) => {
          const isSelected = idUserVote === item.id;

          return (
            <div
              key={`poll_card_item_${index}`}
              className={`row_align_items poll_card_item_${index} ${
                isSelected ? 'selected_choice' : 'default_choice'
              }`}
              onClick={() => onVotePoll(item.id)}
            >
              <div
                className={`${
                  isSelected ? 'selected_choice_mark' : 'default_choice_mark'
                } font-medium font10`}
              >
                {(index + 10).toString(36).toUpperCase()}
              </div>
              <div
                className={`${
                  isSelected ? 'selected_choice_title' : 'default_choice_title'
                } font-medium font14`}
              >
                {item.text}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="row_align_items" style={{ flex: 1 }}>
        {isExistedVote && renderGraph()}
        {renderVoteBody()}
      </div>
    );
  };

  const renderUserInfo = () => {
    return (
      <div
        className="user_info row_align_items font-regular"
        onClick={() => {
          onViewBotProfile(data.creator_id);
        }}
      >
        <span className="font-regular">{`Shared By`}</span>
        {data.creator_image && data.creator_image.startsWith('https') ? (
          <img src={data.creator_image} className="user_img" />
        ) : (
          <DefaultAvatarIcon size={26} />
        )}
        <span className="font-bold">{`${
          data.creator_name ?? 'Anonymous'
        }`}</span>
      </div>
    );
  };

  return <div className="mobile_card_poll">{renderBody()}</div>;
};
