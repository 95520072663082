import React from 'react';
import { MobileCardTypeFilter } from './CardTypeFilter';

import './index.styles.scss';

interface Props {
  selectedFilter: Array<any>;
  onSelected: (item) => void;
}

const arrFilter = [
  { name: 'All Content Types', type: null, id: 0 },
  { name: 'Chats', type: 'chat', id: 9 },
  { name: 'Questions and Answers', type: 'bookmark', id: 3 },
  { name: 'Articles', type: 'article', id: 5 },
  { name: 'Polling', type: 'poll', id: 8 }
  // { name: 'Documents', type: 'file', id: 6 },
  // { name: 'Flash Card', type: 'generated_question', id: 1 },
  // { name: 'Memes', type: 'meme', id: 2 },
  // { name: 'Did You Know Trivia', type: 'statement', id: 4 }
  // { name: 'Medias', type: 'media', id: 7 },
];

export const MobileFeedTypeFilter = ({ selectedFilter, onSelected }: Props) => {
  return (
    <div className="mobile_feed_type_filter">
      {arrFilter.map((item, index) => {
        const isSelected =
          selectedFilter.filter(
            (sItem, sIndex) =>
              sItem.item_type === 'type' && sItem.id === item.id
          ).length > 0;

        return (
          <MobileCardTypeFilter
            key={`mobile_card_type_filter_${index}`}
            index={index}
            data={item}
            isSelected={isSelected}
            onSelected={(item) => onSelected(item)}
          />
        );
      })}
    </div>
  );
};
