import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { apiUserFollow, apiUserUnFollow } from 'shared/api/user';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import './index.styles.scss';

interface Props {
  data: any;
  type: 'all' | 'group' | 'org';
  onViewProfile?: (id) => void;
}

const LOW_SCORE = 0.35;
const MEDIUM_SCORE = 0.7;

export const CardCommunityMember = ({ data, type, onViewProfile }: Props) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [isOwner, setIsOwner] = useState(false);
  const [followStatus, setFollowStatus] = useState<
    'false' | 'true' | 'requested'
  >(data.isFollowed);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followerCount, setFollowerCount] = useState(data.followers);

  const [vibeStatue, setVibeStatus] = useState<'low' | 'medium' | 'high'>(
    data.vibes_type
  );
  const [hasAid, setHasAid] = useState(data.has_ai == 1);

  useEffect(() => {
    if (userInfo && userInfo.id === data.id) {
      setIsOwner(true);
    }
  }, [data]);

  useEffect(() => {
    if (type !== 'all') {
      if (data.suggestion_score < LOW_SCORE) {
        setVibeStatus('low');
      } else if (
        data.suggestion_score >= LOW_SCORE &&
        data.suggestion_score < MEDIUM_SCORE
      ) {
        setVibeStatus('medium');
      } else {
        setVibeStatus('high');
      }
    } else {
      setVibeStatus(data.vibes_type);
    }
  }, [data, type]);

  const onFollowAction = () => {
    if (followStatus === 'false') {
      onFollow();
    } else {
      onUnFollow();
    }
  };

  const onFollow = async () => {
    if (!isFollowing && !isOwner) {
      setIsFollowing(true);

      const res = await apiUserFollow({ followingId: data.id });
      if (res.success) {
        setFollowerCount((prev) => prev + 1);
        setFollowStatus(res.isFollowed);
      }

      setIsFollowing(false);
    }
  };

  const onUnFollow = async () => {
    if (!isFollowing && !isOwner) {
      setIsFollowing(true);

      const res = await apiUserUnFollow({ following: data.id });

      if (res.success) {
        setFollowerCount((prev) => prev - 1);
        setFollowStatus('false');
      }

      setIsFollowing(false);
    }
  };

  const onChat = () => {
    if (hasAid) {
      navigate(`/conversation/user/${data.id}`);
    }
  };

  const onProfile = () => {
    onViewProfile && onViewProfile(data.id);
  };

  const renderTop = () => {
    return (
      <div className="row_space_between" onClick={onProfile}>
        {data.image && data.image.startsWith('https') ? (
          <img src={data.image} className="user_image" />
        ) : (
          <DefaultAvatarIcon size={56} />
        )}
        <div>
          <div className="font-bold font10" style={{ opacity: 0.4 }}>
            Vibe Match
          </div>
          {renderStatus()}
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    return (
      <div style={{ marginTop: 4 }}>
        <div
          className={`font-bold font12 ${
            vibeStatue === 'low'
              ? 'low_color'
              : vibeStatue === 'medium'
              ? 'med_color'
              : 'high_color'
          }`}
        >
          {vibeStatue === 'low'
            ? 'Low'
            : vibeStatue === 'medium'
            ? 'Medium'
            : 'High'}
        </div>
        <div className="progress_bar">
          <div
            className={`actived_progress ${
              vibeStatue === 'low'
                ? 'low_bar_color'
                : vibeStatue === 'medium'
                ? 'med_bar_color'
                : 'high_bar_color'
            }`}
            style={{
              width: `${
                ((data.suggestion_score ?? data.vibes_score) === 0
                  ? 0.1
                  : data.suggestion_score ?? data.vibes_score) * 100
              }%`
            }}
          ></div>
        </div>
      </div>
    );
  };

  const renderMiddle = () => {
    return (
      <div style={{ marginTop: 12 }} onClick={onProfile}>
        <div className="font-bold font14">{data.name}</div>
        <div className="font-regular font12" style={{ opacity: 0.4 }}>
          {followerCount} followers
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="row_align_items" style={{ marginTop: 12 }}>
        {/* <div
          className="chat_btn"
          onClick={onChat}
          style={hasAid ? { borderColor: '#FF3E9A' } : {}}
        >
          <CommentDotIcon color={hasAid ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'} />
        </div> */}
        {isOwner ? renderFollowingBtn() : renderFollowBtn()}
      </div>
    );
  };

  const renderFollowBtn = () => {
    if (followStatus === 'false') {
      return (
        <div
          className="follow_btn font-bold font14 clickable"
          onClick={onFollowAction}
        >
          {isFollowing ? <LoadingCircular size={16} color="white" /> : `Follow`}
        </div>
      );
    } else if (followStatus === 'true') {
      return (
        <div
          className="following_btn font-bold font14 clickable"
          onClick={onFollowAction}
        >
          {isFollowing ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            `Unfollow`
          )}
        </div>
      );
    } else {
      return (
        <div
          className="requested_btn font-bold font14 clickable"
          onClick={onFollowAction}
        >
          {isFollowing ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            `Requested`
          )}
        </div>
      );
    }
  };

  const renderFollowingBtn = () => {
    return (
      <div className="following_btn font-bold font14 clickable">
        {'Following'}
      </div>
    );
  };

  return (
    <div className="mobile_card_community_member">
      {renderTop()}
      {renderMiddle()}
      {renderBottom()}
    </div>
  );
};
