import React, { useEffect, useState } from 'react';

import './index.styles.scss';
import { JoinedOrg } from './JoinedOrg';
import { GroupMembers } from './Members';

export const CommunityOrg = () => {
  const [selectedOrg, setSelectedOrg] = useState(null);

  const [isLoadedGroup, setIsLoadedGroup] = useState(false);
  const [isLoadedMember, setIsLoadedMember] = useState(false);
  const [isLoadedAll, setIsLoadedAll] = useState(false);

  useEffect(() => {
    setIsLoadedAll(isLoadedGroup && isLoadedMember);
  }, [isLoadedGroup, isLoadedMember]);

  return (
    <div className="mobile_org">
      <JoinedOrg
        isLoadedAll={isLoadedAll}
        selectedOrg={selectedOrg}
        onSelected={setSelectedOrg}
        setIsLoaded={() => setIsLoadedGroup(true)}
      />
      <GroupMembers
        isLoadedAll={isLoadedAll}
        selectedOrg={selectedOrg}
        setIsLoaded={() => setIsLoadedMember(true)}
      />
    </div>
  );
};
