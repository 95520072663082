import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { FolderCard } from 'shared/ui-kit/FolderCard';

import './index.styles.scss';

interface Props {
  data: Array<any>;
}

export const MobilePersonalTypeSecond = ({ data }: Props) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [arrAi, setArrAi] = useState(data);

  useEffect(() => {
    setArrAi(data);
  }, [data]);

  const onTap = () => {
    navigate('/myAi');
  };

  const renderCard1 = () => {
    return (
      <div
        className="card_center"
        style={{
          marginRight: arrAi.length === 2 ? 100 : 0,
          marginTop: arrAi.length === 2 ? 60 : 110
        }}
      >
        <FolderCard
          number={`00${arrAi[0].index + 1}`}
          content={<Card data={arrAi[0]} isCenter={true} />}
        ></FolderCard>
      </div>
    );
  };

  const renderCard2 = () => {
    return (
      <div className="card_left">
        {arrAi.length > 2 ? (
          <FolderCard
            number={`00${arrAi[1].index + 1}`}
            fillColor="#FFEFF7"
            content={<Card data={arrAi[1]} isCenter={false} />}
          ></FolderCard>
        ) : (
          <div />
        )}
      </div>
    );
  };

  const renderCard3 = () => {
    return (
      <div
        className="card_right"
        style={{
          right: arrAi.length === 2 ? 16 : 0
        }}
      >
        {arrAi.length == 2 ? (
          <FolderCard
            number={`00${arrAi[1].index + 1}`}
            fillColor="#FFEFF7"
            content={<Card data={arrAi[1]} isCenter={false} />}
          ></FolderCard>
        ) : (
          <FolderCard
            number={`00${arrAi[2].index + 1}`}
            fillColor="#FFEFF7"
            content={<Card data={arrAi[2]} isCenter={false} />}
          ></FolderCard>
        )}
      </div>
    );
  };

  const renderTap = () => {
    return (
      <div className="tap_btn font-medium font14">
        Tap to explore
        {renderTapGraph()}
      </div>
    );
  };

  const renderTapGraph = () => {
    return (
      <div className="tap_graph">
        <TabGraph />
      </div>
    );
  };

  return (
    <div className="mobile_personal_type_second" onClick={onTap}>
      {renderCard1()}
      {renderCard2()}
      {renderCard3()}
      {renderTap()}
    </div>
  );
};

const Card = ({ data, isCenter }) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const renderBotIcon = () => {
    return (
      <div className="bot_icon">
        <RobotIcon size={16} color={'white'} />
      </div>
    );
  };

  if (isCenter) {
    return (
      <div className="chat_personal_card">
        <div style={{ position: 'relative' }}>
          {userInfo?.image && userInfo?.image.startsWith('https') ? (
            <img src={userInfo?.image} className="ai_image" />
          ) : (
            <DefaultAvatarIcon size={50} />
          )}
          {renderBotIcon()}
        </div>
        <div className="ai_name font-bold font18">{'Personal AI'}</div>
      </div>
    );
  } else {
    return (
      <div className="chat_personal_card">
        <div style={{ position: 'relative' }}>
          {data.image && data.image.startsWith('https') ? (
            <img src={data.image} className="ai_image" />
          ) : (
            <DefaultAvatarIcon size={50} />
          )}
          {renderBotIcon()}
        </div>
        <div className="ai_name font-bold font18">{data.name}</div>
      </div>
    );
  }
};

function TabGraph() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="56"
      fill="none"
      viewBox="0 0 61 56"
    >
      <path
        fill="#FF3E9A"
        d="M25.803 4.262a1 1 0 00-.703-1.227L16.415.675a1 1 0 00-.525 1.93l7.72 2.098-2.098 7.72a1 1 0 001.93.524l2.36-8.685zm-1.462-1.13c-.574.329-1.147.667-1.717 1.013l1.038 1.71a76.116 76.116 0 011.672-.987l-.993-1.736zm-5.9 3.739a74.181 74.181 0 00-3.215 2.374l1.232 1.576a72.271 72.271 0 013.127-2.31L18.44 6.87zm-7.043 5.6a56.455 56.455 0 00-2.867 2.8l1.449 1.378a54.517 54.517 0 012.765-2.7l-1.347-1.478zm-6.14 6.628a33.958 33.958 0 00-2.27 3.378l1.727 1.008a31.97 31.97 0 012.136-3.178L5.257 19.1zM.854 27.14a16.985 16.985 0 00-.82 4.095l1.995.137a14.99 14.99 0 01.727-3.613l-1.902-.62zm-.173 9.335a14.118 14.118 0 001.94 3.757l1.625-1.164a12.12 12.12 0 01-1.668-3.227l-1.897.634zm5.539 7.489a27.89 27.89 0 003.35 2.287l1.012-1.725a25.89 25.89 0 01-3.11-2.123l-1.252 1.56zm7.869 4.543c1.168.499 2.416.977 3.748 1.435l.65-1.892a53.186 53.186 0 01-3.612-1.382l-.786 1.839zm8.547 2.891c1.223.328 2.503.641 3.841.94l.435-1.953a90.788 90.788 0 01-3.759-.918l-.517 1.931zm8.707 1.909c1.26.223 2.562.435 3.911.635l.293-1.979c-1.33-.197-2.615-.405-3.855-.625l-.35 1.969zm8.843 1.286c1.278.15 2.592.29 3.943.42l.191-1.991a188.844 188.844 0 01-3.901-.415l-.233 1.986zm8.844.834c1.301.096 2.633.182 3.996.26l.115-1.996a246.394 246.394 0 01-3.965-.259l-.146 1.995zm8.901.498c.671.026 1.35.05 2.035.073l.066-1.998a295.93 295.93 0 01-2.022-.074l-.079 1.998z"
      ></path>
    </svg>
  );
}
