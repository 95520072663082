import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import AngleRightIcon from 'assets/svgs/AngleRightIcon';
import AudioPauseIcon from 'assets/svgs/AudioPauseIcon';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import ExitFullScreenIcon from 'assets/svgs/ExitFullScreenIcon';
import HeartFulledIcon from 'assets/svgs/HeartFulledIcon';
import HeartIcon from 'assets/svgs/HeartIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import { MobileFeedCardArticle } from 'components/Mobile/Feed/Home/components/CardArticle';
import { MobileFeedCardBookMark } from 'components/Mobile/Feed/Home/components/CardBookMark';
import { MobileFeedCardChat } from 'components/Mobile/Feed/Home/components/CardChat';
import { MobileCardDidKnow } from 'components/Mobile/Feed/Home/components/CardDidKnow';
import { MobileFeedCardFile } from 'components/Mobile/Feed/Home/components/CardFile';
import { MobileCardFlipQuestion } from 'components/Mobile/Feed/Home/components/CardFlipQuestion';
import { MobileCardMedia } from 'components/Mobile/Feed/Home/components/CardMedia';
import { MobileCardMeme } from 'components/Mobile/Feed/Home/components/CardMeme';
import { MobileCardPoll } from 'components/Mobile/Feed/Home/components/CardPoll';
import React, { useEffect, useState } from 'react';
import Sheet from 'react-modal-sheet';
import ReactHowler from 'react-howler';
import { apiContentVoteAI, apiGetContentArticle } from 'shared/api/content';
import { useAudioContext } from 'shared/contexts/AudioContext';
import { MobileUserBotModal } from '../UserBotModal';

import './index.styles.scss';

interface Props {
  open: boolean;
  data: any;
  currentIndex: any;
  totalCount: any;
  onClose: () => void;
  onGoTo: (newIdx) => void;
  onUpdateLike?: (item, likeCount, isUserLike) => void;
  onShare: () => void;
}

export const MobileFeedFullViewModal = ({
  open,
  data,
  currentIndex,
  totalCount,
  onClose,
  onGoTo,
  onUpdateLike,
  onShare
}: Props) => {
  const { playingAudio, setPlayingAudio } = useAudioContext();
  const [isPlaying, setIsPlaying] = useState(false);

  const [showBotModal, setShowBotModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const [isLoadedArticleLink, setIsLoadedArticleLink] = useState(true);
  const [userVote, setUserVote] = useState(data.user_like);
  const [audioUrl, setAudioUrl] = useState(data.audio);
  const [text, setText] = useState(data.text);

  useEffect(() => {
    setIsLoadedArticleLink(true);
    setUserVote(data.user_like);
  }, [data]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isIframeLoaded()) {
        setIsLoadedArticleLink(false);
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if ((playingAudio ?? '') === audioUrl) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [playingAudio, data]);

  useEffect(() => {
    if (open && data) {
      loadArticleData();
    }
  }, [open, data]);

  const loadArticleData = async () => {
    const res = await apiGetContentArticle({ article: data.item_id });

    if (res.success) {
      setAudioUrl(res.audio);
      setText(res.text);
    }
  };

  const onVote = async () => {
    const vote = !userVote ? 1 : userVote === 0 ? 1 : 0;

    const res = await apiContentVoteAI({
      item_id: data.item_id,
      item_type:
        data.item_type === 'article' || data.item_type === 'file'
          ? 'dump'
          : data.item_type,
      like: vote,
      remove: userVote === vote ? true : false
    });

    if (res.success) {
      const voteData = res.voting;
      setUserVote(voteData.user_like);
      onUpdateLike && onUpdateLike(data, voteData.likes, voteData.user_like);
    }
  };

  const onPlayAudio = () => {
    if (isPlaying) {
      setPlayingAudio(null);
    } else {
      setPlayingAudio(audioUrl);
    }
  };

  const isIframeLoaded = () => {
    try {
      const iframe = document.getElementById(
        'feed_full_view_iframe'
      ) as HTMLIFrameElement;
      return iframe && iframe.contentWindow !== null;
    } catch (error) {
      return false;
    }
  };

  const onPreview = () => {
    onGoTo(currentIndex - 1);
  };

  const onNext = () => {
    onGoTo(currentIndex + 1);
  };

  const renderArticleLinkBody = () => {
    return (
      <div className="iframe_body">
        <iframe
          className="iframe_content"
          src={data.link}
          id="feed_full_view_iframe"
          onError={() => {
            setIsLoadedArticleLink(false);
          }}
        ></iframe>
      </div>
    );
  };

  const renderLeft = () => {
    return (
      <div className="preview_left" onClick={onPreview}>
        {/* <AngleLeftIcon /> */}
      </div>
    );
  };

  const renderRight = () => {
    return (
      <div className="preview_right" onClick={onNext}>
        {/* <AngleRightIcon color="#FF3E9A" opacity={1} size={40} /> */}
      </div>
    );
  };

  const renderContent = () => {
    if (data.item_type === 'statement') {
      return <MobileCardDidKnow data={data} />;
    } else if (data.item_type === 'meme') {
      return <MobileCardMeme data={data} />;
    } else if (data.item_type === 'generated_question') {
      return <MobileCardFlipQuestion data={data} />;
    } else if (
      data.item_type === 'article' ||
      data.item_type === 'chat_article'
    ) {
      if (data.link && isLoadedArticleLink && data.iframe == 1) {
        return renderArticleLinkBody();
      } else {
        return (
          <MobileFeedCardArticle
            data={{
              ...data,
              audio: audioUrl,
              text: text
            }}
            detectScroll={false}
            onViewProfile={(userId) => {
              setSelectedUser(userId);
              setShowBotModal(true);
            }}
          />
        );
      }
    } else if (data.item_type === 'file') {
      return <MobileFeedCardFile data={data} />;
    } else if (data.item_type === 'media') {
      return <MobileCardMedia data={data} />;
    } else if (data.item_type === 'poll') {
      return (
        <MobileCardPoll
          data={data}
          onViewBotProfile={(userId) => {
            setSelectedUser(userId);
            setShowBotModal(true);
          }}
        />
      );
    } else if (data.item_type === 'chat') {
      return (
        <MobileFeedCardChat
          data={data}
          detectScroll={false}
          onViewBotProfile={(userId) => {
            setSelectedUser(userId);
            setShowBotModal(true);
          }}
        />
      );
    } else if (data.item_type === 'bookmark') {
      return (
        <MobileFeedCardBookMark
          data={data}
          detectScroll={false}
          onViewBotProfile={(userId) => {
            setSelectedUser(userId);
            setShowBotModal(true);
          }}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const renderBotModal = () => {
    return (
      <MobileUserBotModal
        open={showBotModal}
        userId={selectedUser}
        onClose={() => {
          setShowBotModal(false);
        }}
        onViewOther={(otherId) => {
          setSelectedUser(otherId);
        }}
      />
    );
  };

  const renderSideBar = () => {
    return (
      <div className="side_bar">
        {audioUrl && renderPlayIcon()}
        <div className="item_container" onClick={onVote}>
          {userVote === 1 ? (
            <HeartFulledIcon size={24} color={'black'} />
          ) : (
            <HeartIcon size={24} color={'black'} />
          )}
        </div>
        <div
          className="item_container"
          onClick={(e) => {
            e.stopPropagation();
            onShare();
          }}
        >
          <ShareIcon color={'black'} size={22} />
        </div>
        <div
          className="item_container"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <ExitFullScreenIcon color={'black'} size={16} />
        </div>
      </div>
    );
  };

  const renderPlayIcon = () => {
    return (
      <div className="item_container" onClick={onPlayAudio}>
        {isPlaying ? (
          <AudioPauseIcon color={'black'} size={26} opacity={1} />
        ) : (
          <AudioPlayIcon color={'black'} size={26} opacity={1} />
        )}
        {isPlaying && (
          <ReactHowler
            src={audioUrl}
            playing={isPlaying}
            loop={false}
            html5={true} // Use HTML5 Audio API
            onLoad={() => {}}
            onEnd={() => setPlayingAudio(null)}
          />
        )}
      </div>
    );
  };

  return (
    <Sheet
      isOpen={open}
      className="custom_link_preview_modal"
      onClose={onClose}
    >
      <Sheet.Container className="preview_modal_body">
        <Sheet.Content>
          <div className="mobile_feed_full_view_modal">
            {<div className="feed_content_body">{renderContent()}</div>}
            {renderSideBar()}
            {selectedUser && renderBotModal()}
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
