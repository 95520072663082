import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import CommentMedicalIcon from 'assets/svgs/CommentMedicalIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import { MobileQuestionAddAnswerModal } from 'components/Modals/Mobile/QuestionAddAnswerModal';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import {
  apiGetContentAnswers,
  apiGetContentQuestion
} from 'shared/api/content';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { getArrFromString } from 'shared/utils/utils';
import { MobileCardQuestionDetailAnswer } from './CardAnswer';
import { removeFirstBlank } from 'shared/utils/utils';

import './index.styles.scss';

export const MobileQuestionDetail = () => {
  const navigate = useNavigate();
  const params = useParams();

  const questionId = params.id;

  const [questionDetail, setQuestionDetail] = useState<any>(null);

  const scrollRef = useRef<HTMLDivElement>(null);
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [showAddAnswer, setShowAddAnswer] = useState(false);
  const [showTopShadow, setShowTopShadow] = useState(false);

  const [otherAnswers, setOtherAnswers] = useState<Array<any>>([]);
  const [hasMoreOther, setHasMoreOther] = useState(true);
  const [lastIdOther, setLastIdOther] = useState(0);
  const [isLoadingOther, setIsLoadingOther] = useState(false);

  useEffect(() => {
    if (questionId) {
      loadQuestionDetail();
      loadOtherAnswers(true);
    }
  }, [questionId]);

  const loadQuestionDetail = async () => {
    const res = await apiGetContentQuestion({ bookmark: questionId });

    if (res.success) {
      setQuestionDetail(res.data);
      initAnswers(res.data);
    }
  };

  const loadOtherAnswers = async (init = true) => {
    if (!isLoadingOther && hasMoreOther) {
      const res = await apiGetContentAnswers({
        bookmark: questionId,
        limit: 10,
        offset: otherAnswers.length
      });

      if (res.success) {
        setOtherAnswers((prev) => [...prev, ...res.data]);
        setLastIdOther(res.lastId);
        setHasMoreOther(res.hasMoreOther);
      }
    }
  };

  const onBack = () => {
    navigate(-1);
  };

  const initAnswers = (questioData) => {
    const tmpArrAnswerId = getArrFromString(questioData.answer_ids, ';;;');
    const tmpArrAnswerText = getArrFromString(questioData.answer_texts, ';;;');
    const tmpArrAnswerAudio = getArrFromString(
      questioData.answer_audios,
      ';;;'
    );
    const tmpArrAnswerCreatorId = getArrFromString(
      questioData.answer_creator_ids,
      ';;;'
    );
    const tmpArrAnswerCreatorName = getArrFromString(
      questioData.answer_creator_names,
      ';;;'
    );
    const tmpArrAnswerCreatorImage = getArrFromString(
      questioData.answer_creator_images,
      ';;;'
    );
    const tmpArrLike = getArrFromString(questioData.answer_likes, ';;;');
    const tmpArrDisLike = getArrFromString(questioData.answer_dislikes, ';;;');
    const tmpArrUserLikes = getArrFromString(
      questioData.answer_user_likes,
      ';;;'
    );
    const tmpArrAnswerPoint = getArrFromString(
      questioData.answer_points,
      ';;;'
    );

    setOtherAnswers(
      tmpArrAnswerId.map((id, index) => {
        return {
          answer_id: tmpArrAnswerId[index],
          answer_text: tmpArrAnswerText[index],
          answer_audio: tmpArrAnswerAudio[index],
          answer_creator_id: tmpArrAnswerCreatorId[index],
          answer_creator_name: tmpArrAnswerCreatorName[index],
          answer_creator_image: tmpArrAnswerCreatorImage[index],
          points: tmpArrAnswerPoint[index],
          answer_likes: tmpArrLike[index],
          answer_dislikes: tmpArrDisLike[index],
          user_like: tmpArrUserLikes[index]
        };
      })
    );
  };

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    if (scrollTop > 0) {
      setShowTopShadow(true);
    } else {
      setShowTopShadow(false);
    }

    if (scrollRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = scrollRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 0.5) {
        loadOtherAnswers();
      }
    }
  };

  const onAddNewAnswerToUI = (newAnswerTxt, newAnswerId) => {
    const newAnswer = {
      answer_audio: null,
      answer_creator_id: userInfo?.id,
      answer_creator_image: userInfo?.image,
      answer_creator_name: userInfo?.name,
      answer_dislikes: 0,
      answer_id: newAnswerId,
      answer_likes: 0,
      answer_text: newAnswerTxt,
      points: 0,
      user_like: -1
    };

    setOtherAnswers((prev) => [newAnswer, ...prev]);
  };

  const onViewBotProfile = (userId) => {};

  const onAddAnswer = () => {
    setShowAddAnswer(true);
  };

  const renderHeader = () => {
    return (
      <div className="mobile_question_detail_header row_space_between row_align_items">
        <div onClick={onBack}>
          <AngleLeftIcon />
        </div>
      </div>
    );
  };

  const renderTopShadow = () => {
    return <div className="top_shadow"></div>;
  };

  const renderBody = () => {
    return (
      <div className="detail_body">
        {renderQuestion()}
        {renderUserInfo()}
        {renderSubActions()}
        {(otherAnswers.length > 0 || !!questionDetail.ai_answer) &&
          renderVerticalDivider()}
        {renderImage()}
        {renderTopAnswer()}
        {renderAnswers()}
      </div>
    );
  };

  const renderQuestion = () => {
    return (
      <div className="bookmark_question_title font-bold">
        {questionDetail.question}
      </div>
    );
  };

  const renderUserInfo = () => {
    return (
      <div
        className="user_info row_align_items font-regular"
        onClick={() => {
          onViewBotProfile(questionDetail.creator_id);
        }}
      >
        <span className="font-regular">{`Shared By`}</span>
        {questionDetail.creator_image &&
        questionDetail.creator_image.startsWith('https') ? (
          <img src={questionDetail.creator_image} className="user_img" />
        ) : (
          <DefaultAvatarIcon size={26} />
        )}
        <span className="font-bold">{`${questionDetail.creator_name}`}</span>
      </div>
    );
  };

  const renderSubActions = () => {
    return (
      <div className="row_align_items" style={{ marginTop: 8 }}>
        <div className="sub_item">
          <div style={{ fontSize: 16 }}>👀</div>
          <div className="font-medium font14">{questionDetail.seen_total}</div>
          <div className="font-regular font14" style={{ opacity: 0.6 }}>
            views
          </div>
        </div>
        <div className="sub_item" onClick={onAddAnswer}>
          <CommentMedicalIcon size={20} />
          <div className="font-medium font14">Add Answer</div>
        </div>
      </div>
    );
  };

  const renderVerticalDivider = () => {
    return <div className="vertical_divider"></div>;
  };

  const renderImage = () => {
    if (questionDetail.images && questionDetail.images.startsWith('https')) {
      return (
        <div className="normal_question_image">
          <img
            src={questionDetail.images}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderTopAnswer = () => {
    return (
      <pre className="top_answer_txt font-regular">
        {removeFirstBlank(questionDetail.ai_answer)}
      </pre>
    );
  };

  const renderAnswers = () => {
    return (
      <div className="answer_list">
        {otherAnswers.length > 0 && (
          <div className="other_answer_title font-bold font18">
            Other Answers
          </div>
        )}
        {otherAnswers.map((item, index) => {
          return (
            <MobileCardQuestionDetailAnswer
              key={`answer_card_${item.answer_id}`}
              data={item}
              isTopAnswer={false}
            />
          );
        })}
      </div>
    );
  };

  const renderAddAnswerModal = () => {
    return (
      <MobileQuestionAddAnswerModal
        open={showAddAnswer}
        data={{ ...questionDetail, item_id: questionId }}
        onClose={() => {
          setShowAddAnswer(false);
        }}
        onSuccess={(newAnswerTxt, newAnswerId) => {
          setShowAddAnswer(false);
          onAddNewAnswerToUI(newAnswerTxt, newAnswerId);
        }}
      />
    );
  };

  const renderBottomShadow = () => {
    return <div className="did_know_bottom"></div>;
  };

  if (questionDetail) {
    return (
      <div className="mobile_question_detail">
        {renderHeader()}
        <div className="mobile_question_detail_body">
          {showTopShadow && renderTopShadow()}
          <div
            className="card_container"
            ref={scrollRef}
            onScroll={handleScroll}
          >
            {renderBody()}
            {renderBottomShadow()}
          </div>
          {renderAddAnswerModal()}
        </div>
      </div>
    );
  } else {
    return (
      <div className="mobile_question_detail">
        <div className="loading_bar_container">
          <LoadingBarAnimation />
        </div>
      </div>
    );
  }
};
