import CalendarIcon from 'assets/svgs/CalendarIcon';
import React from 'react';
import { CardPointHistory } from './CardPointHistory';
import BG_EMPTY_CREATOR from 'assets/pngs/bg_empty_chat_creator.png';

import './index.styles.scss';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';

export const MobileMyPointBody = ({ data, isLoaded, isLoading }) => {
  const renderHeader = () => {
    return (
      <div className="mobile_point_history_header">
        <div className="font-bold font16">Point History</div>
        {/* {renderCalender()} */}
      </div>
    );
  };

  const renderCalender = () => {
    return (
      <div className="calendar_view clickable">
        <CalendarIcon />
        <div className="font-regular font14" style={{ marginLeft: 8 }}>
          20 May - 21 June 2022
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="mobile_point_history_body">
        {data.map((item, index) => {
          return (
            <CardPointHistory
              data={item}
              key={`mobile_point_history_card_${index}`}
            />
          );
        })}
      </div>
    );
  };

  const renderEmptyView = () => {
    return (
      <div className="empty_creator">
        <img
          src={BG_EMPTY_CREATOR}
          style={{ width: 300, height: 230, marginBottom: 30 }}
        />
        <div className="font-bold font16">
          {'Your Journey to Rewards Starts Here!'}
        </div>
        <div
          className="font-regular font12"
          style={{
            color: 'rgba(0, 0, 0, 0.4)',
            textAlign: 'center',
            marginTop: 8
          }}
        >
          {
            'Start Utilizing the App and Sharing Engaging Content to Earn Points for Exciting Rewards Ahead!'
          }
        </div>
      </div>
    );
  };

  const renderLoadingView = () => {
    return (
      <div className="empty_creator">
        <LoadingBarAnimation />
      </div>
    );
  };

  return (
    <div className="mobile_point_history">
      {renderHeader()}
      {isLoading
        ? renderLoadingView()
        : isLoaded && data.length === 0
        ? renderEmptyView()
        : renderBody()}
    </div>
  );
};
