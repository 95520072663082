import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useWhisper } from '@kkaczynski/use-whisper';
import SearchIcon from 'assets/svgs/SearchIcon';

import './index.styles.scss';
import MicIcon from 'assets/svgs/MicIcon';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { getToken } from 'shared/api/storageHelper';

interface MobileQuestionAskBarProps {
  onSearch: (searchText: string, audio?: string) => void;
  onChangedQuestion?: (searchText: string, audio?: string) => void;
  width?: string;
  height?: string;
  backgroundColor?: string;
  placeholder?: string;
  fontSize?: number;
  searchIconClickable?: boolean;
  type?: string;
}

export const MobileQuestionAskBar = ({
  onSearch,
  onChangedQuestion,
  width,
  height,
  backgroundColor,
  placeholder,
  fontSize,
  searchIconClickable,
  type
}: MobileQuestionAskBarProps) => {
  const { uploadBase64 } = useIPFS();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [keyword, setKeyword] = useState('');
  const [debouncedKeyword] = useDebounce(keyword, 500);
  const [audioUrl, setAudioUrl] = useState<any>(null);

  const onTranscribe = async (blob: Blob) => {
    const base64 = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    const body = JSON.stringify({ file: base64, model: 'whisper-1' });

    const url = await uploadBase64(base64, 'audio/webm', ''); //upload to wasabi and returns url of audio recording.

    // console.log('Base64 recording: ', base64);
    const headers = { 'Content-Type': 'application/json' };
    const { default: axios } = await import('axios');
    const response = await axios.post(
      'https://webhook.site/90bbf81d-b51b-424e-83bc-d957e4c9797c',
      body,
      {
        headers
      }
    );
    const { text } = await response.data;
    // you must return result from your server in Transcript format
    return {
      blob,
      text,
      url
    };
  };

  const {
    recording,
    speaking,
    transcript,
    transcribing,
    pauseRecording,
    startRecording,
    stopRecording
  } = useWhisper({
    apiKey: getToken(),
    streaming: true,
    removeSilence: false,
    timeSlice: 1_000, // 1 second
    whisperConfig: {
      language: 'en',
      endpoint: process.env.REACT_APP_WHISPER_URL
    },
    onTranscribe
  });

  useEffect(() => {
    if (recording && transcript.text) {
      setKeyword(transcript.text);
    }
  }, [recording, transcript]);

  useEffect(() => {
    const dumpData: any = transcript;
    setAudioUrl(dumpData?.url);
  }, [transcript]);

  useEffect(() => {
    onInput(null);
    onChangedQuestion && onChangedQuestion(keyword, audioUrl);
  }, [keyword]);

  const onStartRecord = () => {
    startRecording();
  };

  const onStopRecord = () => {
    stopRecording();
  };

  const onEnter = (e) => {
    if (keyword !== '' && !transcribing && !recording) {
      if (e.keyCode === 13) {
        e.preventDefault();
        onInput(e);
        onSearch(keyword, audioUrl);
      }
    }
  };

  const onInput = (e) => {
    if (inputRef.current) {
      if (keyword !== '') {
        inputRef.current.style.height = '1px';
        inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
      } else {
        inputRef.current.style.height = '40px';
      }
    }
  };

  const renderRecordIcon = () => {
    return (
      <div
        className="trascript_btn"
        style={
          recording || transcribing
            ? { backgroundColor: '#FF3E9A' }
            : { backgroundColor: 'white' }
        }
        onClick={() => {
          recording ? onStopRecord() : onStartRecord();
        }}
      >
        {transcribing ? (
          <LoadingCircular size={16} color={'white'} />
        ) : (
          <MicIcon color={recording ? 'white' : '#FF3E9A'} />
        )}
      </div>
    );
  };

  return (
    <div
      className="mobile_question_ask_box"
      style={{
        width: width ?? '',
        backgroundColor: backgroundColor ?? 'white'
      }}
    >
      {renderRecordIcon()}
      <textarea
        ref={inputRef}
        value={keyword}
        placeholder={placeholder ?? 'Search here....'}
        className="question_ask_box_input font-bold font24"
        onInput={onInput}
        style={{
          backgroundColor: backgroundColor ?? 'white',
          width: '100%',
          wordBreak: 'break-word'
        }}
        onChange={(e) => {
          if (e.nativeEvent['inputType'] === 'insertLineBreak') {
            e.preventDefault();
            return;
          } else {
            setKeyword(e.target.value);
          }
        }}
        onKeyUp={(e) => {
          e.preventDefault();
          onEnter(e);
        }}
      />
    </div>
  );
};
