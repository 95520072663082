import HttpClient from './apiClient';

export async function apiGetQADumps(params: {
  to?: number;
  to_type?: 'user' | 'group';
  interests?: string;
  limit?: number;
  offset?: number;
  tag_ids?: string;
}) {
  try {
    const response = await HttpClient.getWithToken(`QA/dumps`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiCreateQADump(params: {
  url?: any;
  type?: 'text' | 'url' | 'blog' | 'pdf' | 'audio';
  filename?: string;
  size?: string;
  to?: any;
  to_type?: 'user' | 'group' | 'organisation' | 'personal';
  interests?: string;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/dump`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQATagList(params: {
  item_id?: any;
  item_type?: any;
  limit?: number;
  offset?: number;
  tagType?: string | null;
}) {
  try {
    const response = await HttpClient.getWithToken(`QA/tagList`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQATag(params: { dump: number; tags: Array<any> }) {
  try {
    const response = await HttpClient.postWithToken(`QA/tag`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQAUnTag(params: {
  dump: number;
  item_id: number;
  item_type?: string;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/untag`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQACreateTag(params: {
  name: string;
  to?: any;
  to_type?: any;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/createTag`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQAAsk(params: {
  question?: string;
  interests?: string;
  to?: string | number;
  to_type?: string;
  tag_ids?: string;
  dumpString?: string;
  users?: string;
  dump_string?: string;
  audio?: string;
}) {
  try {
    const response = await HttpClient.getWithToken(`QA/ask`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQAQuestions(params: {
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`QA/questions`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQAAnswers(params: {
  bookmark?: any;
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`QA/answers`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQAAnswer(params: {
  bookmark: string;
  answer: string;
  is_anonymous: boolean;
  media_type: string;
  filename?: string;
  size?: number | undefined;
  dumpString?: string;
  users?: string;
  audio?: string;
}) {
  try {
    const response = await HttpClient.postWithToken('QA/answer', params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQAEditAnswer(params: {
  question: number;
  answer: string;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/editAnswer`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQABookMark(params: {
  question: number;
  item_id?: any;
  item_type?: any;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/bookmark`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQAUnBookMark(params: {
  question?: number;
  bookmark?: number;
  item_id: number;
  item_type: string;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/unbookmark`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQAGetShareLink(params: {
  url: any;
  item_id?: number;
  type: string;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/share`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQABookMarkAnswer(params: {
  answer: number;
  item_id?: number;
  item_type: string;
}) {
  try {
    const response = await HttpClient.postWithToken(
      `QA/bookmarkAnswer`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQAUnBookMarkAnswer(params: { answer: number }) {
  try {
    const response = await HttpClient.postWithToken(
      `QA/unbookmarkAnswer`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQAAnswerVote(params: {
  answer: number;
  vote: number;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/vote`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQAAIVote(params: {
  vote: any;
  item_id: any;
  item_type: any;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/voteAI`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQABookmarks(params: {
  query: string;
  item_id: number;
  item_type: string;
  limit: number;
  offset: number;
  date_start?: number;
  date_end?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`QA/bookmarks`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQAPostedQuestions(params: {
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `QA/postedQuestions`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQASuggestQuestions(params: {
  query: string;
  dump?: any;
  to?: any;
  to_type?: 'user' | 'group' | 'organisation';
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `QA/suggestedQuestions`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQARefresh(params: {
  question: number;
  dumpString?: string;
  users?: string;
  interests?: string;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/refresh`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQAFiles(params: {
  to?: any;
  to_type?: string;
  share_type?: 'uploaded' | 'received' | 'shared';
  limit: number;
  offset: number;
  query: string;
  type?: string | null;
}) {
  try {
    const response = await HttpClient.getWithToken(`QA/files`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQAFile(params: { dump: any }) {
  try {
    const response = await HttpClient.getWithToken(`QA/file`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQASummary(params: {
  dump: number;
  recursive?: boolean;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/summarise`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQAChat(params: {
  itemId: number;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`QA/chat`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQAUserReference(params: {
  query: string;
  to?: number | undefined;
  to_type?: string | undefined;
  limit?: number | undefined;
  offset?: number | undefined;
}) {
  try {
    const response = await HttpClient.getWithToken(`QA/userReferences`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQAFileReference(params: {
  query: string;
  to?: number | undefined;
  to_type?: string | undefined;
  limit?: number | undefined;
  offset?: number | undefined;
}) {
  try {
    const response = await HttpClient.getWithToken(`QA/fileReferences`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQASimilarQuestion(params: {
  question: string;
  item_id?: number | undefined;
  item_type?: string | undefined;
  limit?: number | undefined;
  offset?: number | undefined;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `QA/similarQuestions`,
      params
    );

    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
export async function apiGetQAAskGeneral(params: {
  question: string;
  to_type?: string;
  to?: number;
  audio?: any;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/askGeneral`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
export async function apiGetQAAskOrgGeneral(params: {
  question: string;
  org: number;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/askOrgGeneral`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
export async function apiGetQALatestQuestionsOrgs(params: {
  orgs: string;
  topics?: string;
  unanswered?: any;
  trending?: any;
  limit: number | undefined;
  offset: number | undefined;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `QA/latestQuestionsOrgs`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQALatestQuestions(params: {
  items?: string;
  item_type: 'user' | 'group' | 'organisation';
  limit: number | undefined;
  offset: number | undefined;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `QA/latestQuestions`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQAQuestionDetail(params: { bookmark: any }) {
  try {
    const response = await HttpClient.getWithToken('QA/questionDetail', params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetMobileQAQuestionDetail(params: { bookmark: any }) {
  try {
    const response = await HttpClient.getWithToken('qa/questionDetail', params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQAVote(params: { answer: number; vote: number }) {
  try {
    const response = await HttpClient.postWithToken(`QA/vote`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQAShareDump(params: {
  dump: number;
  ai_ids?: Array<any>;
  ai_types?: Array<any>;
  privacy?: any;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/shareDump`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
export async function apiQARetryDump(params: { dump: number }) {
  try {
    const response = await HttpClient.postWithToken(`QA/retryDump`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
export async function apiGetQAStatements(params: {
  to?: number | undefined;
  to_type?: string;
  query?: string;
  dump?: any;
  limit: number | undefined;
  offset: number | undefined;
}) {
  try {
    const response = await HttpClient.getWithToken(`QA/statements`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQAMemes(params: {
  to?: any | undefined;
  to_type?: string;
  query?: string;
  dump?: any;
  limit: number | undefined;
  offset: number | undefined;
}) {
  try {
    const response = await HttpClient.getWithToken(`QA/memes`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQAShareToOrgs(params: {
  questionId?: number;
  message?: number;
  orgs: any;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/shareToOrgs`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetBrowerTopics(params: {
  item_id: any;
  item_type: any;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`QA/browserTopics`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetBrowerLink(params: {
  query?: string;
  topics?: string;
  tag_ids?: string;
  tag_types?: string;
  item_id?: any;
  item_type?: any;
  received?: boolean;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`QA/browserLinks`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiQAShareAnswer(params: {
  bookmark?: any;
  answer?: any;
  question?: any;
  ai_ids: Array<any>;
  ai_types: Array<any>;
}) {
  try {
    const response = await HttpClient.postWithToken(`QA/shareAnswer`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQAAnswerWith(params: {
  bookmark?: any;
  answer?: any;
  type: any;
  query: string;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `QA/shareAnswerWith`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetQAWith(params: {
  dump: any;
  type: any;
  query: string;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`QA/shareWith`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetReceivedQuestion(params: {
  item_id?: any;
  item_type?: any;
  query?: string;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `QA/receivedQuestions`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
