import ChatSendIcon from 'assets/svgs/ChatSendIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import { socket } from 'components/Auth';
import React, { useEffect, useRef, useState } from 'react';
import { useWhisper } from '@kkaczynski/use-whisper';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiChatShare } from 'shared/api/chat';
import { SocketEventName } from 'shared/socket/socket_events';
import { MobileCardPromptChat } from './CardChat';

import './index.styles.scss';
import { TypeLoadingAnimation } from 'shared/ui-kit/TypeLoadingAnimation';
import BounceLoader from 'react-spinners/BounceLoader';
import { showToast } from 'shared/ui-kit/PrimaryToast';

import MicIcon from 'assets/svgs/MicIcon';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import AngleDownIcon from 'assets/svgs/AngleDownIcon';
import AngleUpIcon from 'assets/svgs/AngleUpIcon';
import { getToken } from 'shared/api/storageHelper';
interface Props {
  roomId: any;
  roomName: any;
  aiId: any;
  isSharing: boolean;
  isEnded: boolean;
  arrMessage: Array<any>;
  busyTime: any;
  isBusy: any;
  onShowShare: (status, messageId) => void;
  onFinishShare: () => void;
  onAddMessage: (newMessage) => void;
  onLoadMore: () => void;
  onUpdateMessageInfo: (tmpId: any, realId: any, audio?: any) => void;
}

const MAX_RESPONSE_TIME = 60;

export const MobilePromotConversationChat = ({
  roomId,
  roomName,
  aiId,
  isSharing,
  isEnded,
  arrMessage,
  busyTime,
  isBusy,
  onShowShare,
  onFinishShare,
  onAddMessage,
  onLoadMore,
  onUpdateMessageInfo
}: Props) => {
  const { uploadBase64 } = useIPFS();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [message, setMessage] = useState('');
  const [isGotResponse, setIsGotResponse] = useState(true);

  const [isBrowsingResponse, setIsBrowsingResponse] = useState(false);
  const [isAnalyingResponse, setIsAnalyingResponse] = useState(false);
  const [browsingTxt, setBrowsingTxt] = useState('');
  const [analysingTxt, setAnalysingTxt] = useState('');
  const [isExpandedBrowsing, setIsExpandedBrowsing] = useState(false);
  const [writerInfo, setWriterInfo] = useState<any>(null);

  const [arrShareMessage, setArrShareMessage] = useState<Array<any>>([]);
  const [newMessages, setNewMessages] = useState<Array<any>>([]);
  const [isStreamingMessage, setIsStreamingMessage] = useState(false);
  const [audioUrl, setAudioUrl] = useState<any>(null);
  const [isSendingAutomatically, setIsSendingAUtomatically] = useState(false);

  let timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [resendTime, setResendTime] = useState(MAX_RESPONSE_TIME);

  const onTranscribe = async (blob: Blob) => {
    const base64 = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });

    const url = await uploadBase64(base64, 'audio/webm', ''); //upload to wasabi and returns url of audio recording.

    return {
      blob,
      url
    };
  };

  const {
    recording,
    speaking,
    transcript,
    transcribing,
    pauseRecording,
    startRecording,
    stopRecording
  } = useWhisper({
    apiKey: getToken(),
    streaming: true,
    removeSilence: false,
    timeSlice: 1_000, // 1 second
    whisperConfig: {
      language: 'en',
      endpoint: process.env.REACT_APP_WHISPER_URL
    },
    onTranscribe
  });

  useEffect(() => {
    if (socket && roomId) {
      socket.emit('join-room', `chat-multi-ai-${roomId}`);

      const newMessageHandler = (data) => {
        console.log('new-chat-message-ai', data);

        if (data.type === 'typing') {
          onShowWriterInfo(data);
          setIsBrowsingResponse(false);
          onReStartCounter();
        } else if (data.type === 'browsing') {
          setIsBrowsingResponse(true);
          setBrowsingTxt(data.message);
          onReStartCounter();
        } else if (data.type === 'link') {
          console.log('--- link ----', { data });
          setIsAnalyingResponse(true);
          setAnalysingTxt(data.message);
          onReStartCounter();
        } else if (data.type === 'text') {
          onAddNewMessageToStack(data);
          setIsBrowsingResponse(false);
          setWriterInfo(null);
          onReStartCounter();
        } else if (data.type === 'done') {
          setIsGotResponse(true);
          setWriterInfo(null);
          onReceivedEndEvent();
        } else if (data.type === 'error') {
          setIsGotResponse(true);
          setWriterInfo(null);
          onReceivedEndEvent();
        }
      };

      const newUserIdHandler = (data) => {
        console.log('user-message-id', data);

        onUpdateMessageInfo(data.tmp_id, data.id, data.audio);
      };

      socket.on('new-chat-message-ai', newMessageHandler);
      socket.on('user-message-id', newUserIdHandler);

      return () => {
        socket.removeListener('new-chat-message-ai', newMessageHandler);
        socket.removeListener('user-message-id', newUserIdHandler);
      };
    }
  }, [socket, roomId]);

  useEffect(() => {
    if (!isSendingAutomatically) {
      if (recording && transcript.text) {
        setMessage(transcript.text);
      }
    } else {
      if (transcript.text) {
        setMessage(transcript.text);
      }
    }
  }, [recording, transcript]);

  useEffect(() => {
    if (!recording && !transcribing && isSendingAutomatically && audioUrl) {
      onSendMessage();
    }
  }, [transcribing, transcript, recording, isSendingAutomatically, audioUrl]);

  useEffect(() => {
    onInput(null);
  }, [message]);

  useEffect(() => {
    const dumpData: any = transcript;
    setAudioUrl(dumpData?.url);
  }, [transcript]);

  useEffect(() => {
    if (!isStreamingMessage && newMessages.length > 0) {
      // show message on UI
      onAddMessage({ ...newMessages[0], is_new: true });

      // remove message on stack
      setNewMessages((prev) => prev.slice(1, prev.length - 1));
    }
  }, [isStreamingMessage, newMessages]);

  useEffect(() => {
    onEnableInput();
  }, [resendTime]);

  const onStartCounter = () => {
    console.log('--- on start counter ---');
    if (resendTime > 0) {
      timerRef.current = setInterval(() => {
        setResendTime((prev) => prev - 1);
      }, 1000);
    }
  };

  const onReStartCounter = () => {
    console.log('--- on restart counter ---');
    setIsGotResponse(false);
    if (resendTime > 0) {
      clearInterval(timerRef?.current!);
      setResendTime(MAX_RESPONSE_TIME);

      timerRef.current = setInterval(() => {
        setResendTime((prev) => prev - 1);
      }, 1000);
    }
  };

  const onEnableInput = () => {
    console.log('--- on ended counter ---');
    if (resendTime == 0) {
      setIsGotResponse(true);
      setIsBrowsingResponse(false);
      setIsAnalyingResponse(false);
      setIsStreamingMessage(false);
      setIsSendingAUtomatically(false);

      if (timerRef) {
        clearInterval(timerRef?.current!);
        setResendTime(MAX_RESPONSE_TIME);
      }
    }
  };

  const onReceivedEndEvent = () => {
    console.log('--- on received end event ---');
    if (timerRef) {
      clearInterval(timerRef?.current!);
      setResendTime(MAX_RESPONSE_TIME);
    }
  };

  const onSendMessage = () => {
    if (
      message !== '' &&
      isGotResponse &&
      !recording &&
      !transcribing &&
      !isBusy
    ) {
      setIsSendingAUtomatically(false);
      setIsGotResponse(false);

      const tmpId = new Date().getTime();
      socket.emit(SocketEventName.send_message_multichat_ai, {
        room: roomId,
        message,
        type: 'text',
        audio: audioUrl,
        tmp_id: String(tmpId)
      });

      const newMessageItem = {
        ai_id: aiId,
        ai_image: userInfo?.image,
        ai_name: userInfo?.name,
        ai_type: 'personal',
        is_ai: 0,
        message: message,
        type: 'text',
        creator: userInfo?.id,
        audio: audioUrl,
        tmp_id: String(tmpId)
      };

      onStartCounter();
      setMessage('');
      setAudioUrl(null);

      onAddMessage(newMessageItem);
      inputRef!.current!.style.height = '30px';
    } else if (recording) {
      setIsSendingAUtomatically(true);
      onStopRecord();
    }
  };

  const onShareMessages = async () => {
    if (arrShareMessage.length > 0) {
      onShowShare(false, null);

      const arrMessageId = arrShareMessage.map((item, index) => item.id);
      const res = await apiChatShare({
        room: roomId,
        messages: arrMessageId
      });

      if (res.success) {
        setArrShareMessage([]);
        onFinishShare();
        showToast('success', 'Successfully shared!');
      }
    }
  };

  const onShowWriterInfo = (data) => {
    setWriterInfo({
      image: data.ai_image,
      name: data.ai_name
    });
  };

  const onAddNewMessageToStack = (data) => {
    setNewMessages((prev) => [...prev, { ...data, is_new: true }]);
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.clientHeight - e.target.scrollTop + 1 >= e.target.scrollHeight;

    if (bottom) {
      onLoadMore();
    }
  };

  const onSelected = (newItem) => {
    if (
      arrShareMessage.filter((item, index) => item.id === newItem.id).length > 0
    ) {
      setArrShareMessage((prev) =>
        prev.filter((item, index) => item.id !== newItem.id)
      );
    } else {
      if (arrShareMessage.length == 30) {
        // show message
        showToast('error', 'Max limit of 30 messages reached.');
      } else {
        setArrShareMessage((prev) => [...prev, newItem]);
      }
    }
  };

  const onInput = (e) => {
    if (inputRef.current) {
      inputRef.current.style.height = '1px';
      inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
    }
  };

  const onStartRecord = () => {
    if (isGotResponse && !isBusy) {
      startRecording();
    }
  };

  const onStopRecord = () => {
    stopRecording();
  };

  const onViewAnalysingLink = () => {
    window.open(analysingTxt, '_blank');
  };

  const renderMessages = () => {
    return (
      <div className="multi_message_body" onScroll={handleScroll}>
        {renderStatus()}
        {arrMessage.map((item, index) => {
          var isSelected =
            arrShareMessage.filter((sItem, index) => sItem.id === item.id)
              .length > 0;

          return (
            <MobileCardPromptChat
              key={`card_chat_${index}`}
              isSelected={isSelected}
              isSharing={isSharing}
              data={item}
              onShare={() => {
                isGotResponse && onShowShare(true, item.id);
              }}
              onClick={onSelected}
              onStreamingMessage={() => {
                setIsStreamingMessage(true);
              }}
              onFinishedStreamingMessage={() => {
                setIsStreamingMessage(false);
              }}
            />
          );
        })}
      </div>
    );
  };

  const renderInput = () => {
    return (
      <div className="multi_message_input">
        <textarea
          ref={inputRef}
          value={message}
          placeholder="Ask more questions here..."
          className="multip_mesasge_chat_box_input font-regular font16"
          disabled={!isGotResponse || isBusy}
          onChange={(e) => {
            onInput(e);
            if (e.nativeEvent['inputType'] === 'insertLineBreak') {
              e.preventDefault();
              return;
            } else {
              setMessage(e.target.value);
            }
          }}
        />

        <div
          className="trascript_btn"
          style={
            recording || transcribing
              ? { backgroundColor: '#FF3E9A' }
              : { backgroundColor: 'white' }
          }
          onClick={() => {
            recording ? onStopRecord() : onStartRecord();
          }}
        >
          {transcribing ? (
            <LoadingCircular size={16} color={'white'} />
          ) : (
            <MicIcon color={recording ? 'white' : '#FF3E9A'} />
          )}
        </div>
        <div className="clickable" onClick={onSendMessage}>
          <ChatSendIcon />
        </div>
      </div>
    );
  };

  const renderShareView = () => {
    return (
      <div className="multi_message_share_view">
        <div
          className="row_align_items"
          style={{ gap: 4 }}
          onClick={onShareMessages}
        >
          <ShareIcon color={'#FF3E9A'} />
          <div className="font-regular font12">Share to Q/A</div>
        </div>
        <div className="row_align_items">
          <div className="font-regular font12">
            {arrShareMessage.length} Selected
          </div>
        </div>
      </div>
    );
  };

  const renderEnded = () => {
    return (
      <div>
        <div className="ended_chat font-regular font14">
          Chat is ended. <br />
          Saved in history as <span className="font-bold">“{roomName}”</span>
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    if ((!isSharing && !isGotResponse) || isBusy) {
      return (
        <div className="status_container">
          {isBrowsingResponse
            ? renderBrowingStatus()
            : writerInfo && (
                <div className="row_align_items" style={{ gap: 8 }}>
                  <img
                    src={writerInfo.image}
                    style={{ width: 24, height: 24, borderRadius: 24 }}
                  />
                  <div className="font-regular font12">{writerInfo.name}</div>
                </div>
              )}
          {((!isBrowsingResponse &&
            !isAnalyingResponse &&
            !isStreamingMessage) ||
            isBusy ||
            writerInfo) && (
            <div className="loading_bar">
              <TypeLoadingAnimation
                background={'#FF3E9A'}
                size={'6px'}
                margin={'2px'}
                duration={'0.5s'}
              />
            </div>
          )}
        </div>
      );
    } else {
      return <div />;
    }
  };

  const renderBrowingStatus = () => {
    return (
      <div>
        <div
          className="browsing_status row_align_items row_space_between"
          onClick={() => {
            setIsExpandedBrowsing((prev) => !prev);
          }}
        >
          <div className="row_align_items">
            <div className="font-regular font12">
              Browsing
              <span className="font-bold font12" style={{ marginLeft: 6 }}>
                {browsingTxt}
              </span>
              ...
            </div>
            <BounceLoader color={'#FF3E9A'} loading={true} size={24} />
          </div>

          <div className="expand_btn">
            {isExpandedBrowsing ? (
              <AngleUpIcon color={'#FF3E9A'} opacity={1} />
            ) : (
              <AngleDownIcon color={'#FF3E9A'} opacity={1} />
            )}
          </div>
        </div>
        {isExpandedBrowsing && (
          <div className="analysing_status" onClick={onViewAnalysingLink}>
            <div className="analysing_status_txt font-medium font10">
              {analysingTxt}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="mobile_multi_chat"
      style={
        isSharing
          ? { height: 'calc(100% - 40px)' }
          : { height: 'calc(100% - 120px)' }
      }
    >
      {renderMessages()}

      {isEnded && !isSharing
        ? renderEnded()
        : isSharing
        ? renderShareView()
        : renderInput()}
    </div>
  );
};
