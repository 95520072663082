import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import CoinIcon from 'assets/svgs/CoinIcont';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useState } from 'react';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import moment from 'moment';

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';

import Download1Icon from 'assets/svgs/Download1Icon';
import WhatsappIcon from 'assets/svgs/WhatsappIcon';
import TelegramIcon from 'assets/svgs/TelegramIcon';
import InstagramIcon from 'assets/svgs/InstagramIcon';
import TwitterIcon from 'assets/svgs/TwitterIcon';
import TikTokIcon from 'assets/svgs/TikTokIcon';

import './index.styles.scss';
import { getImageFromHtml } from 'shared/utils/utils';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { apiQAGetShareLink } from 'shared/api/qa';
import { apiGetUserSharingQR } from 'shared/api/user';

interface ModalProps {
  show: boolean;
  question?: any;
  onClose: () => void;
}

export const MobileShareMemeModal = ({
  show,
  question,
  onClose
}: ModalProps) => {
  const { uploadBase64 } = useIPFS();
  const [shareUrl, setShareUrl] = useState('');
  const [qrCodeUrl, setQRCodeUrl] = useState('');

  useEffect(() => {
    if (show && question) {
      getSharingQRcode();
    }
  }, [show, question]);

  useEffect(() => {
    if (show) {
      document
        .getElementsByClassName('plus_btn')[0]
        .setAttribute('style', 'display: none');
    } else {
      document
        .getElementsByClassName('plus_btn')[0]
        .setAttribute('style', 'display: visible');
    }
  }, [show]);

  const getSharingQRcode = async () => {
    const res = await apiGetUserSharingQR();
    if (res.success) {
      setQRCodeUrl(res.qr_url);
      initShareUrl();
    }
  };

  const initShareUrl = async () => {
    try {
      const base64Image = await getImageFromHtml(
        `mobile_share_body_image_${question!.item_id}_meme`
      );
      const url = await uploadBase64(base64Image, 'image/png', '');

      const res = await apiQAGetShareLink({
        url: url,
        item_id: question!.item_id,
        type: 'meme'
      });

      if (res.success) {
        setShareUrl(res.sharing_url);
      } else {
        // initShareUrl();
      }
    } catch (error) {
      // initShareUrl();
    }
  };

  const renderHeader = () => {
    return (
      <div className="share_header row_align_items">
        <div onClick={onClose}>
          <BlackBackIcon />
        </div>
        <div className="header_title font-bold font18">Share to Social</div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="share_body">
        <div
          className="share_main_body"
          id={`mobile_share_body_image_${question!.item_id}_meme`}
        >
          {renderTopView()}
          {renderQuestion()}
        </div>
      </div>
    );
  };

  const renderTopView = () => {
    return (
      <div className="row_space_between">
        <div className="row_align_items">
          <div className="align-vertical-center">
            {question?.creator_image &&
            question?.creator_image.startsWith('https') ? (
              <img
                src={question?.creator_image}
                style={{ width: 20, height: 20, borderRadius: 20 }}
              />
            ) : (
              <DefaultAvatarIcon size={20} />
            )}
          </div>
          <div className="font-bold font12" style={{ marginLeft: 6 }}>
            {question?.creator_name}
          </div>
        </div>
        <div className="qr_view">
          <img
            src={qrCodeUrl}
            style={{ width: '100%', height: '100%' }}
            crossOrigin="anonymous"
          />
        </div>
      </div>
    );
  };

  const renderQuestion = () => {
    return (
      <div style={{ maxHeight: 300 }}>
        {question?.images && question?.images.startsWith('https') ? (
          <img src={question?.images} className="meme_image" />
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="share_bottom">
        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <WhatsappShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Whatsapp_itm">
                <WhatsappIcon />
              </div>
              <div className="font10">Whatsapp</div>
            </div>
          </WhatsappShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TelegramShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Telegram_itm ">
                <TelegramIcon />
              </div>
              <div className="font10">Telegram</div>
            </div>
          </TelegramShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <FacebookShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div
                className="statement_link_bar_items_item Telegram_itm "
                style={{ padding: 0 }}
              >
                <FacebookIcon borderRadius={16} size={48} />
              </div>
              <div className="font10">Facebook</div>
            </div>
          </FacebookShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TwitterShareButton title={''} url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Twitter_itm">
                <TwitterIcon />
              </div>
              <div className="font10">Twitter</div>
            </div>
          </TwitterShareButton>
        )}
      </div>
    );
  };

  return (
    <PrimaryModal
      show={show}
      width={'100%'}
      style={{ width: '100%', height: '100%', borderRadius: 0, padding: 0 }}
      onClose={onClose}
    >
      <div className="mobile_share_meme_modal">
        {renderHeader()}
        {renderBody()}
        {renderBottom()}
      </div>
    </PrimaryModal>
  );
};
