import React from 'react';

function VolumnMuteIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '24'}
      height={props.size ?? '24'}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_7112_226996)">
        <path
          fill={props.color ?? '#FF3E9A'}
          d="M14 8.83v6.34L11.83 13H9v-2h2.83L14 8.83zM16 4l-5 5H7v6h4l5 5V4z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_7112_226996">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default VolumnMuteIcon;
