import React from 'react';

function CameraIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '18'}
      height={props.size ?? '18'}
      fill="none"
      viewBox="0 0 18 16"
    >
      <path
        fill={props.color ?? '#fff'}
        d="M9.217 2.907l1.125 1.125a.75.75 0 001.066 0 .75.75 0 00.097-.907h2.745a.75.75 0 100-1.5h-2.745a.75.75 0 00-.098-.908.75.75 0 00-1.065 0L9.217 1.842a.75.75 0 00-.157.248.75.75 0 000 .57.75.75 0 00.157.247zM17.19 5.84a.75.75 0 00-.158-.248l-1.124-1.125a.75.75 0 00-1.066 0 .75.75 0 00-.097.908H12a.75.75 0 100 1.5h2.745a.75.75 0 00.098.907.75.75 0 001.065 0l1.124-1.125a.75.75 0 00.158-.247.75.75 0 000-.57zm-8.94.66a3 3 0 100 6 3 3 0 000-6zm0 4.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM15 8.75a.75.75 0 00-.75.75v3.75a.75.75 0 01-.75.75H3a.75.75 0 01-.75-.75v-6A.75.75 0 013 6.5h1.5a.75.75 0 00.75-.518l.405-1.215a.75.75 0 01.675-.517H7.5a.75.75 0 100-1.5H6.33a2.25 2.25 0 00-2.138 1.545L3.96 5H3A2.25 2.25 0 00.75 7.25v6A2.25 2.25 0 003 15.5h10.5a2.25 2.25 0 002.25-2.25V9.5a.75.75 0 00-.75-.75z"
      ></path>
    </svg>
  );
}

export default CameraIcon;
