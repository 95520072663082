import React from 'react';

function FavBorderIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '32'}
      height={props.size ?? '32'}
      fill="none"
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_7336_202699)">
        <path
          fill={props.color ?? '#EB5099'}
          d="M22 4c-2.32 0-4.547 1.08-6 2.787C14.547 5.08 12.32 4 10 4c-4.107 0-7.333 3.227-7.333 7.333 0 5.04 4.533 9.147 11.4 15.387L16 28.467l1.933-1.76c6.867-6.227 11.4-10.334 11.4-15.374C29.333 7.227 26.107 4 22 4zm-5.867 20.733l-.133.134-.133-.134c-6.347-5.746-10.534-9.546-10.534-13.4 0-2.666 2-4.666 4.667-4.666 2.053 0 4.053 1.32 4.76 3.146h2.493c.694-1.826 2.694-3.146 4.747-3.146 2.667 0 4.667 2 4.667 4.666 0 3.854-4.187 7.654-10.534 13.4z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_7336_202699">
          <path fill="#fff" d="M0 0H32V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default FavBorderIcon;
