import { MobileChat } from 'components/Mobile/Chat';
import { MobileCreatePrompt } from 'components/Mobile/Chat/PersonalAI/CreatePrompt';
import { MobileMyPersonalAiFiles } from 'components/Mobile/Chat/PersonalAI/PersonalAiFiles';
import { MobilePromotConversation } from 'components/Mobile/Chat/PromotConversation';
import { MobilePrompts } from 'components/Mobile/Chat/PromotConversation/Prompts';
import { MobileSearchPrompt } from 'components/Mobile/Chat/PromotConversation/SearchPrompt';
import { MobileCommunityHome } from 'components/Mobile/Community/Home';
import { MobileEventDetail } from 'components/Mobile/Event/Detail';
import { MobileEventHome } from 'components/Mobile/Event/Home';
import { MobileAskQuestion } from 'components/Mobile/Feed/AskQuestion';
import { MobileCreateMedia } from 'components/Mobile/Feed/Create/CreateMedia';
import { MobileCreatePoll } from 'components/Mobile/Feed/Create/CreatePoll';
import { MobileFeedHome } from 'components/Mobile/Feed/Home';
import { MobileHome } from 'components/Mobile/Home';
import { MobileInviteFriend } from 'components/Mobile/InviteFriend';
import { MobileProfile } from 'components/Mobile/Profile';
import { MobileEditOrgProfile } from 'components/Mobile/Profile/components/WelcomeOrg/edit_org_profile';
import { MobileOrgMember } from 'components/Mobile/Profile/components/WelcomeOrg/org_member';
import { MobileEditProfile } from 'components/Mobile/Profile/EditProfile';
import { MobileMyPersonalAI } from 'components/Mobile/Profile/MyPersonalAI';
import { MobileMyPoint } from 'components/Mobile/Profile/MyPoint';
import { JoinNewGroup } from 'components/Mobile/Qa/JoinNewGroup';
import { JoinNewOrg } from 'components/Mobile/Qa/JoinNewOrg';
import { MobileQADetail } from 'components/Mobile/Qa/QADetail';

import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { MobileSearchCreator } from 'components/Mobile/Chat/SearchCreator';
import { MobileCommunitySearch } from 'components/Mobile/Community/Search';
import { MobileQuestionDetail } from 'components/Mobile/Feed/QuestionDetail';
import { MobileCreateBot } from 'components/Mobile/Bot/CreateBot';
import { MobileIndividualConversation } from 'components/Mobile/Chat/IndividualConversation';
import { MobileChatHistory } from 'components/Mobile/Chat/MobileChatHistory';

export const MobileMainRoutes = () => {
  useEffect(() => {
    document
      .getElementsByTagName('body')[0]
      .setAttribute('style', 'zoom: 100%;');
  }, []);

  return (
    <Routes>
      <Route path={'/'} element={<MobileHome />}>
        <Route index path="" element={<MobileFeedHome />} />
        <Route index path="/org/join" element={<JoinNewOrg />} />
        <Route index path="/group/join" element={<JoinNewGroup />} />
        <Route index path="/org/detail" element={<MobileQADetail />} />
        <Route path="/chat" element={<MobileChat />} />
        <Route path="/profile" element={<MobileProfile />} />
        <Route path={'/profile/:id/*'} element={<MobileProfile />} />
        <Route path="/event" element={<MobileEventHome />} />
        <Route path="/agent/:id/*" element={<MobileCommunityHome />} />
        <Route path="/community" element={<MobileCommunityHome />} />
      </Route>
      <Route path="/school/events/:code/*" element={<MobileEventDetail />} />
      <Route path="/invite" element={<MobileInviteFriend />} />
      <Route path="*" element={<Navigate to="/" />} />
      <Route
        path={'/conversation/:id/:roomId'}
        element={<MobileIndividualConversation />}
      />
      <Route path={'/conversation/history'} element={<MobileChatHistory />} />
      <Route path={'/edit_profile/*'} element={<MobileEditProfile />} />
      <Route
        path={'/personalAi/:id/:type/*'}
        element={<MobileMyPersonalAiFiles />}
      />
      <Route
        path={'/personalAi/definePrompt/:name/:image/*'}
        element={<MobileCreateBot />}
      />
      <Route path={'/prompt/create/:aiId'} element={<MobileCreatePrompt />} />
      <Route
        path={'/prompt/conversation/:id/:roomId'}
        element={<MobilePromotConversation />}
      />
      <Route path={'/prompt/:aiId'} element={<MobilePrompts />} />
      <Route path={'/prompt/search/:aiId'} element={<MobileSearchPrompt />} />
      <Route path={'/myAi/*'} element={<MobileMyPersonalAI />} />
      <Route path={'/myPoint/*'} element={<MobileMyPoint />} />
      <Route
        path={'/editOrgProfile/:id/*'}
        element={<MobileEditOrgProfile />}
      />
      <Route path={'/orgMember/:id/*'} element={<MobileOrgMember />} />
      <Route path="/event/:id/*" element={<MobileEventHome />} />
      <Route path={'/poll/create/*'} element={<MobileCreatePoll />} />
      <Route path={'/media/create/*'} element={<MobileCreateMedia />} />
      <Route path={'/question/create/*'} element={<MobileAskQuestion />} />
      <Route path={'/search/creator/*'} element={<MobileSearchCreator />} />
      <Route path={'/search/community/*'} element={<MobileCommunitySearch />} />
      <Route path={'/question/:id/*'} element={<MobileQuestionDetail />} />
    </Routes>
  );
};
