import React, { useEffect } from 'react';
import { LoadingCircular } from '../LoadingCircular';
import { PrimaryModal } from '../PrimaryModal';

import './index.styles.scss';

export const LoadingDialog = ({ show, onClose, title, description }) => {
  return (
    <PrimaryModal
      show={show}
      width={'330px'}
      onClose={onClose}
      style={{ height: '330px' }}
      disableBackdropClick={true}
    >
      <div className="loading_dialog">
        <LoadingCircular size={90} color={'main'} />
        <div className="font-bold font24" style={{ marginTop: 40 }}>
          {title}
        </div>
        <div
          className="font-regular font16"
          style={{ opacity: 0.6, marginTop: 12 }}
        >
          {description}
        </div>
      </div>
    </PrimaryModal>
  );
};
