import { MobilePromptUploadFileModal } from 'components/Modals/Mobile/PromptUploadFile';
import { MobileAddNewAudio } from 'components/Modals/MobileAddFile/AddNewAudio';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { LoadingDialog } from 'shared/ui-kit/LoadingDialog';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { getBase64String } from 'shared/utils-IPFS/functions';
import useIPFS from 'shared/utils-IPFS/useIPFS';

import './index.styles.scss';

interface Props {
  aiId?: any;
  onSkipAndCreateAI: () => void;
  onSuccess: (newUrls: Array<any>) => void;
}

export const MobileBotUploadAudio = ({
  aiId,
  onSkipAndCreateAI,
  onSuccess
}: Props) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { uploadBase64 } = useIPFS();

  const [showAddAudioModal, setShowAddAudioModal] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileSelect = async (event) => {
    const selectedFiles = event.target.files;

    const convertedFiles = Array.from(selectedFiles);
    if (convertedFiles.length > 0) {
      setIsUploading(true);

      const uploadedUrls = await Promise.all(
        convertedFiles.map(async (item: any, index) => {
          const type = item.type;
          const base64String = await getBase64String(item);
          const url = await uploadBase64(base64String, type, '');

          return url;
        })
      ).then((results) => {
        return results;
      });

      onSuccess(uploadedUrls);
      setIsUploading(false);
    }
  };

  const renderTop = () => {
    return (
      <div className="top_view_container">
        <div className="round_out">
          <div className="round_out">
            <div className="round_center">
              <div className="font-bold" style={{ fontSize: 48 }}>
                📁
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMainBody = () => {
    return (
      <div className="creation_body">
        {renderTop()}
        <div className="font-regular font12" style={{ textAlign: 'center' }}>
          Choose any audio or video file with audio but make sure only one voice
          is on the file to have a good result.
        </div>
        <input
          ref={fileInputRef}
          type="file"
          accept=".mp3, audio/wav"
          multiple
          style={{ display: 'none' }}
          onChange={handleFileSelect}
        />
        {renderUploadBtn()}
      </div>
    );
  };

  const renderUploadBtn = () => {
    return (
      <PrimaryButton
        label="Upload"
        isLoading={false}
        onPressed={() => {
          setShowUploadDialog(true);
        }}
      />
    );
  };

  const renderSkipBtn = () => {
    return (
      <div className="font-bold font14" onClick={onSkipAndCreateAI}>
        Skip Voice and Create AI
      </div>
    );
  };

  const renderUploadModal = () => {
    return (
      <MobilePromptUploadFileModal
        open={showUploadDialog}
        onClose={() => setShowUploadDialog(false)}
        onSelected={(item) => {
          setShowUploadDialog(false);

          if (item === 'audio') {
            setShowAddAudioModal(true);
          } else {
            fileInputRef?.current && fileInputRef?.current.click();
          }
        }}
      />
    );
  };

  const renderAddAudioModal = () => {
    return (
      <MobileAddNewAudio
        aiId={aiId}
        open={showAddAudioModal}
        onClose={() => {
          setShowAddAudioModal(false);
        }}
        onSuccess={(dumpId, link) => {
          onSuccess([link]);
        }}
        onViewFile={(aiId) => {
          setShowAddAudioModal(false);
        }}
      />
    );
  };

  const renderLoadingModal = () => {
    return (
      <LoadingDialog
        show={isUploading}
        title={'Uploading Files...'}
        description="This may take a moment..."
        onClose={() => setIsUploading(false)}
      />
    );
  };

  return (
    <div className="mobile_bot_upload_audio">
      {renderMainBody()}
      {renderSkipBtn()}
      {renderUploadModal()}
      {renderAddAudioModal()}
      {renderLoadingModal()}
    </div>
  );
};
