import React, { useState } from 'react';
import { apiChatCreatePrompt } from 'shared/api/chat';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { showToast } from 'shared/ui-kit/PrimaryToast';

import './index.styles.scss';

interface Props {
  onCreatedPrompt: (promptId) => void;
}

export const MobileBotDefinePrompt = ({ onCreatedPrompt }: Props) => {
  const [prompt, setPrompt] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  const onCreate = async () => {
    if (!!prompt) {
      if (!isCreating) {
        setIsCreating(true);

        const res = await apiChatCreatePrompt({
          prompt: prompt,
          ai_id: null,
          ai_type: 'personal'
        });

        if (res.success) {
          onCreatedPrompt(res.id);
        }
        setIsCreating(false);
      }
    } else {
      showToast(
        'error',
        'Please provide prompt for your personal AI to continue.'
      );
    }
  };

  const renderTopView = () => {
    return (
      <div className="top_view_container">
        <div className="round_out">
          <div className="round_out">
            <div className="round_center">
              <div className="font-bold" style={{ fontSize: 48 }}>
                📝
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="bot_define_body">
        {renderDesc()}
        {renderPrompt()}
        {renderCreateBtn()}
      </div>
    );
  };

  const renderDesc = () => {
    return (
      <>
        <div className="define_title font-bold font20">Define your prompts</div>
        <div className="define_desc font-regular font12">
          It is an open-ended prompt where you can explore and design a bot that
          suits your needs
        </div>
      </>
    );
  };

  const renderPrompt = () => {
    return (
      <textarea
        value={prompt}
        placeholder="Type your prompt here..."
        className="prompt_box_input font-regular font14"
        onChange={(e) => {
          setPrompt(e.target.value);
        }}
      />
    );
  };

  const renderCreateBtn = () => {
    return (
      <PrimaryButton
        label="Create Prompt"
        isLoading={isCreating}
        onPressed={onCreate}
      />
    );
  };

  return (
    <div className="mobile_bot_define">
      {renderTopView()}
      {renderBody()}
    </div>
  );
};
