import HttpClient from './apiClient';

export async function apiCreateContentMedia(params: {
  item_id: any;
  item_type: any;
  urls: any;
  media_types: any;
  is_anonymous: boolean;
}) {
  try {
    const response = await HttpClient.postWithToken(
      `content/createMedia`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiCreateContentPoll(params: {
  item_id: any;
  item_type: any;
  question: any;
  choices: any;
  is_anonymous: boolean;
}) {
  try {
    const response = await HttpClient.postWithToken(
      `content/createPoll`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiVotePoll(params: { poll: any; choice: any }) {
  try {
    const response = await HttpClient.postWithToken(`content/votePoll`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiContentVoteAI(params: {
  like: any;
  remove?: boolean;
  item_id: any;
  item_type: any;
}) {
  try {
    const response = await HttpClient.postWithToken(`content/voteAI`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetContentQuestions(params: {
  items: any;
  item_type: any;
  topics?: string;
  unanswered?: any;
  trending?: any;
  limit: number | undefined;
  offset: number | undefined;
}) {
  try {
    const response = await HttpClient.getWithToken(`content/questions`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetContentFlashCards(params: {
  to?: any;
  to_type?: 'user' | 'group' | 'organisation';
  query: string;
  dump?: any;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `content/flashCards`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetContentMemes(params: {
  to?: any | undefined;
  to_type?: string;
  query?: string;
  dump?: any;
  limit: number | undefined;
  offset: number | undefined;
}) {
  try {
    const response = await HttpClient.getWithToken(`content/memes`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetContentStatements(params: {
  to?: any | undefined;
  to_type?: string;
  query?: string;
  dump?: any;
  limit: number | undefined;
  offset: number | undefined;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `content/statements`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetContentFiles(params: {
  to?: any;
  to_type?: string;
  share_type?: 'uploaded' | 'received' | 'shared';
  limit: number;
  offset: number;
  query: string;
  type?: string | null;
}) {
  try {
    const response = await HttpClient.getWithToken(`content/files`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetContentLinks(params: {
  query?: string;
  topics?: string;
  tag_ids?: string;
  tag_types?: string;
  item_id?: any;
  item_type?: any;
  received?: boolean;
  is_summarised?: string;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`content/links`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiCreateAudioStory(params: { audio: any; length: any }) {
  try {
    const response = await HttpClient.postWithToken(
      `content/createAudioStory`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetAudioStories(params: {
  query: any;
  limit?: any;
  offset?: any;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `content/audioStories`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetContentQuestion(params: { bookmark: any }) {
  try {
    const response = await HttpClient.getWithToken(`content/question`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetContentAnswers(params: {
  bookmark: any;
  limit?: any;
  offset?: any;
}) {
  try {
    const response = await HttpClient.getWithToken(`content/answers`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetContentArticle(params: { article: any }) {
  try {
    const response = await HttpClient.getWithToken(`content/article`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
