import React from "react";

function Download1Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      fill="none"
      viewBox="0 0 18 20"
    >
      <path
        fill="#fff"
        d="M5.29 11.29a1 1 0 000 1.42l3 3a1 1 0 001.42 0l3-3a1.004 1.004 0 10-1.42-1.42L10 12.59V1a1 1 0 00-2 0v11.59l-1.29-1.3a1 1 0 00-1.42 0zM15 7h-2a1 1 0 100 2h2a1 1 0 011 1v7a1 1 0 01-1 1H3a1 1 0 01-1-1v-7a1 1 0 011-1h2a1 1 0 000-2H3a3 3 0 00-3 3v7a3 3 0 003 3h12a3 3 0 003-3v-7a3 3 0 00-3-3z"
      ></path>
    </svg>
  );
}

export default Download1Icon;
