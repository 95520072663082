import ChartIcon from 'assets/svgs/ChartIcon';
import FileIcon from 'assets/svgs/FileIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import React from 'react';
import FilterBoltIcon from '../svgs/FilterBoltIcon';
import FilterCheckIcon from '../svgs/FilterCheckIcon';
import FilterCommentIcon from '../svgs/FilterCommentIcon';
import FilterLightIcon from '../svgs/FilterLightIcon';
import FilterMemeIcon from '../svgs/FilterMemeIcon';
import FilterSmileIcon from '../svgs/FilterSmileIcon';

import './index.styles.scss';

interface Props {
  index: number;
  data: any;
  isSelected: boolean;
  onSelected: (item) => void;
}

export const MobileCardTypeFilter = ({
  index,
  data,
  isSelected,
  onSelected
}: Props) => {
  return (
    <div
      className={`${
        isSelected ? 'selected' : 'default'
      }_filter_item row_align_items row_space_between`}
      onClick={() => {
        onSelected({
          type: 'type',
          id: data.id,
          item_type: 'type'
        });
      }}
    >
      <div className="row_align_items">
        <div className="align-center">
          {data.id === 0 ? (
            <FilterSmileIcon isSelected={isSelected} />
          ) : data.id === 1 ? (
            <FilterBoltIcon isSelected={isSelected} />
          ) : data.id === 2 ? (
            <FilterMemeIcon isSelected={isSelected} />
          ) : data.id === 3 ? (
            <FilterCommentIcon isSelected={isSelected} />
          ) : data.id === 4 ? (
            <FilterLightIcon isSelected={isSelected} />
          ) : data.id === 5 ? (
            <LinkIcon
              color={isSelected ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'}
              opacity={1}
            />
          ) : data.id === 7 ? (
            <FilterMemeIcon isSelected={isSelected} />
          ) : data.id === 8 ? (
            <ChartIcon color={isSelected ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'} />
          ) : data.id === 9 ? (
            <ChartIcon color={isSelected ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'} />
          ) : (
            <FileIcon
              size={20}
              color={isSelected ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'}
            />
          )}
        </div>
        <div className="font-regular font14" style={{ marginLeft: 6 }}>
          {data.name}
        </div>
      </div>
      {isSelected ? <FilterCheckIcon /> : <div className="default_mark"></div>}
    </div>
  );
};
