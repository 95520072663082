import React from 'react';

function LanguageIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '16'}
      height={props.size ?? '16'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_7189_202386)">
        <path
          fill={props.color ?? '#000'}
          d="M7.993 1.334a6.663 6.663 0 00-6.66 6.667c0 3.68 2.98 6.666 6.66 6.666a6.67 6.67 0 006.674-6.666 6.67 6.67 0 00-6.674-6.667zm4.62 4h-1.966a10.432 10.432 0 00-.92-2.373 5.353 5.353 0 012.886 2.373zM8 2.694c.553.8.987 1.687 1.273 2.64H6.727c.286-.953.72-1.84 1.273-2.64zm-5.16 6.64a5.494 5.494 0 01-.173-1.333c0-.46.066-.907.173-1.334h2.253C5.04 7.107 5 7.547 5 8.001c0 .453.04.893.093 1.333H2.84zm.547 1.333h1.966c.214.834.52 1.634.92 2.374a5.325 5.325 0 01-2.886-2.374zm1.966-5.333H3.387a5.325 5.325 0 012.886-2.373c-.4.74-.706 1.54-.92 2.373zM8 13.307a9.39 9.39 0 01-1.273-2.64h2.546A9.39 9.39 0 018 13.307zm1.56-3.973H6.44c-.06-.44-.107-.88-.107-1.333 0-.454.047-.9.107-1.334h3.12c.06.434.107.88.107 1.334 0 .453-.047.893-.107 1.333zm.167 3.707c.4-.74.706-1.54.92-2.374h1.966a5.353 5.353 0 01-2.886 2.374zm1.18-3.707c.053-.44.093-.88.093-1.333 0-.454-.04-.894-.093-1.334h2.253c.107.427.173.874.173 1.334 0 .46-.066.906-.173 1.333h-2.253z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_7189_202386">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LanguageIcon;
