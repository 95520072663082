import HttpClient from './apiClient';

export async function apiSocialShareTiktok(params: { feed: any }) {
  try {
    const response = await HttpClient.postWithToken(
      `socials/shareTiktok`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
