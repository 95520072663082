import React from 'react';

function RobotIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 16}
      height={props.size ?? 16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={props?.color ? props.color : '#FF3E9A'}
        d="M6 10a.666.666 0 100 1.333A.666.666 0 006 10zm-4.667-.667A.667.667 0 00.667 10v1.333a.666.666 0 101.333 0V10a.667.667 0 00-.667-.667zm13.334 0A.667.667 0 0014 10v1.333a.667.667 0 001.333 0V10a.667.667 0 00-.667-.667zm-3.334-4.666H8.666v-.854a1.333 1.333 0 00.667-1.146 1.333 1.333 0 00-2.667 0 1.333 1.333 0 00.667 1.146v.854H4.666a2 2 0 00-2 2v6a2 2 0 002 2h6.667a2 2 0 002-2v-6a2 2 0 00-2-2zM9.146 6l-.333 1.333H7.186L6.853 6h2.293zM12 12.667a.667.667 0 01-.667.666H4.666A.667.667 0 014 12.667v-6A.667.667 0 014.667 6h.813L6 8.16a.667.667 0 00.667.507h2.666A.667.667 0 0010 8.16L10.52 6h.813a.667.667 0 01.667.667v6zM10 10a.666.666 0 100 1.333A.666.666 0 0010 10z"
      ></path>
    </svg>
  );
}

export default RobotIcon;
