import { MobileShareDidKnowModal } from 'components/Modals/MobileShare/ShareDidKnow';
import React, { useMemo, useState } from 'react';
import { convertFirstToUpper, getArrFromString } from 'shared/utils/utils';

import './index.styles.scss';
import { MobileFeedSideBar } from '../SideBar';
import LightIcon from 'assets/svgs/LightIcon';

interface Props {
  data: any;
}

export const MobileCardDidKnow = ({ data }: Props) => {
  const [showShareModal, setShowShareModal] = useState(false);

  const getText = () => {
    return data.text.startsWith('Did you know that ')
      ? data.text.replace('Did you know that ', '').replace('?', '')
      : data.text.startsWith('Did you know, ')
      ? data.text.replace('Did you know, ', '').replace('?', '')
      : data.text.startsWith('Did you know that')
      ? data.text.replace('Did you know that', '').replace('?', '')
      : data.text.replace('Did you know', '').replace('?', '');
  };

  const imageUrl = useMemo(() => {
    if (data.images) {
      return getArrFromString(data.images, ';;;')[0];
    } else {
      return null;
    }
  }, [data]);

  const renderType = () => {
    return (
      <div className="mark row_align_items">
        <div className="light_view">
          <LightIcon color={'#FF3E9A'} size={20} />
        </div>
        <div className="type_title font-bold font14">Did you know</div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="did_know_body">
        <div className="feed_main_content">
          {imageUrl && imageUrl.startsWith('https') ? (
            <img src={imageUrl} className="did_know_image" />
          ) : (
            <div></div>
          )}
          <div className="question_txt font-medium font32">
            {convertFirstToUpper(getText())}
          </div>
        </div>
      </div>
    );
  };

  const renderShareModal = () => {
    return (
      <MobileShareDidKnowModal
        data={data}
        show={showShareModal}
        onClose={() => {
          setShowShareModal(false);
        }}
      />
    );
  };

  const renderRightAction = () => {
    return <MobileFeedSideBar data={data} />;
  };

  return (
    <div className="mobile_card_did_know">
      {renderType()}
      {renderBody()}
      {/* {renderRightAction()} */}
      {renderShareModal()}
    </div>
  );
};
