import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';

import Download1Icon from 'assets/svgs/Download1Icon';
import WhatsappIcon from 'assets/svgs/WhatsappIcon';
import TelegramIcon from 'assets/svgs/TelegramIcon';
import InstagramIcon from 'assets/svgs/InstagramIcon';
import TwitterIcon from 'assets/svgs/TwitterIcon';
import TikTokIcon from 'assets/svgs/TikTokIcon';

import './index.styles.scss';
import { getImageFromHtml } from 'shared/utils/utils';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { apiQAGetShareLink } from 'shared/api/qa';
import { apiGetTopicPercentage, apiGetUserSharingQR } from 'shared/api/user';
import SnapChatIcon from 'assets/svgs/SnapChatIcon';
import { RootState } from 'redux/interfaces';
import { useSelector } from 'react-redux';

interface ModalProps {
  show: boolean;
  data: any;
  onClose: () => void;
  onConnectSocial?: (socialName) => void;
}

export const MobileShareArticleModal = ({
  show,
  data,
  onClose,
  onConnectSocial
}: ModalProps) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const { uploadBase64 } = useIPFS();

  const [shareUrl, setShareUrl] = useState('');
  const [qrCodeUrl, setQRCodeUrl] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [percentInfo, setPercentInfo] = useState<any>(null);

  const [createdImageUrl, setCreatedImageUrl] = useState<any>(null);
  const [isSharingSnapChat, setIsSharingSnapChat] = useState(false);

  useEffect(() => {
    if (show && data) {
      getSharingQRcode();
      loadPercentInfo();
    }
  }, [show, data]);

  useEffect(() => {
    if (show) {
      (function (d, s, id) {
        var js,
          sjs = d.getElementsByTagName(s)[0];
        if (sjs && sjs.parentNode) {
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src = 'https://sdk.snapkit.com/js/v1/create.js';
          sjs.parentNode.insertBefore(js, sjs);
        } else {
          console.log('--- failed to load snap sdk');
        }
      })(document, 'script', 'snapkit-creative-kit-sdk');
    }
  }, [show]);

  const loadPercentInfo = async () => {
    const res = await apiGetTopicPercentage({
      userId: data.creator_id
    });
    setPercentInfo(res.data);
  };

  const getSharingQRcode = async () => {
    const res = await apiGetUserSharingQR();
    if (res.success) {
      setQRCodeUrl(res.qr_url);
      initShareUrl();
    }
  };

  const initShareUrl = async () => {
    try {
      const base64Image = await getImageFromHtml(
        `mobile_share_body_image_${data!.item_id}_article`
      );
      const url = await uploadBase64(base64Image, 'image/png', '');

      const res = await apiQAGetShareLink({
        url: url,
        item_id: data!.item_id,
        type: 'article'
      });

      if (res.success) {
        setShareUrl(res.sharing_url);
      } else {
      }
    } catch (error) {}
  };

  const onShare = async (type: 'download') => {
    if (!isDownloading)
      if (type === 'download') {
        setIsDownloading(true);
        const base64Image = await getImageFromHtml(
          `mobile_share_body_image_${data!.item_id}_article`
        );
        const url = await uploadBase64(base64Image, 'image/png', '');

        await onDownloadFile(url);
        setIsDownloading(true);
      }
  };

  const onDownloadFile = async (fileUrl) => {
    if (!isDownloading) {
      setIsDownloading(true);

      fetch(fileUrl)
        .then((response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'share_image';
            alink.click();
          });
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  };

  const onShareSnapchat = async () => {
    if (!isSharingSnapChat) {
      setIsSharingSnapChat(true);

      const base64Image = await getImageFromHtml(
        `mobile_share_body_image_${data!.item_id}_article`
      );
      const url = await uploadBase64(base64Image, 'image/png', '');
      setCreatedImageUrl(url);

      const button = document.getElementById(
        `snapchat-creative-kit-share-article-${data.item_id}`
      ) as HTMLDivElement;
      button.setAttribute('data-share-url', String(url));
      button.setAttribute('data-snapchat-creative-kit-share-button', 'true');
      console.log({ button });
      // const firstAnchor = button.querySelector('a');
      // if (firstAnchor) {
      //   firstAnchor.click();
      // }

      setIsSharingSnapChat(false);
    }
  };

  const renderHeader = () => {
    return (
      <div className="share_header row_align_items">
        <div onClick={onClose}>
          <BlackBackIcon />
        </div>
        <div className="header_title font-bold font18">Share to Social</div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="share_body">
        <div
          className="share_main_body"
          id={`mobile_share_body_image_${data!.item_id}_article`}
        >
          {renderTopView()}
          {renderInfo()}
        </div>
      </div>
    );
  };

  const renderTopView = () => {
    return (
      <div className="row_space_between">
        <div className="row_align_items">
          <div className="align-vertical-center">
            {userInfo?.image && userInfo?.image.startsWith('https') ? (
              <img
                src={userInfo?.image}
                style={{ width: 32, height: 32, borderRadius: 32 }}
              />
            ) : (
              <DefaultAvatarIcon size={32} />
            )}
          </div>
          <div style={{ marginLeft: 6 }}>
            <div className="font-bold font12 text_no_wrap">
              {userInfo?.name}
            </div>
            {percentInfo && renderPercentInfo()}
          </div>
        </div>

        <div className="qr_view">
          <img
            src={qrCodeUrl}
            style={{ width: '100%', height: '100%' }}
            crossOrigin="anonymous"
          />
        </div>
      </div>
    );
  };

  const renderPercentInfo = () => {
    return (
      <div
        className="font-regular font10 text_no_wrap"
        style={{ marginTop: 4 }}
      >
        {`Top ${percentInfo!.percentile}% in ${percentInfo!.topic_emoji} ${
          percentInfo!.topic_name
        }`}
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <div
        className="info_container"
        style={isDownloading ? { overflow: 'hidden' } : { overflowY: 'auto' }}
      >
        <div className="info_body">
          <div className="font-bold font22" style={{ textAlign: 'center' }}>
            {data.title}
          </div>
          <div className="font-regular font12" style={{ textAlign: 'center' }}>
            {data.text}
          </div>
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="share_bottom">
        <div className="statement_link_bar_items">
          <div
            className="statement_link_bar_items_item download_itm clickable"
            onClick={() => onShare('download')}
          >
            {isDownloading ? (
              <LoadingCircular size={20} color={'white'} />
            ) : (
              <Download1Icon />
            )}
          </div>
          <div className="font10">Download</div>
        </div>
        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <WhatsappShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Whatsapp_itm">
                <WhatsappIcon />
              </div>
              <div className="font10">Whatsapp</div>
            </div>
          </WhatsappShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TelegramShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Telegram_itm ">
                <TelegramIcon />
              </div>
              <div className="font10">Telegram</div>
            </div>
          </TelegramShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TwitterShareButton title={''} url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Twitter_itm">
                <TwitterIcon />
              </div>
              <div className="font10">Twitter</div>
            </div>
          </TwitterShareButton>
        )}
        <div className="statement_link_bar_items" onClick={onShareSnapchat}>
          <div className="statement_link_bar_items_item Snapchat_itm">
            {isSharingSnapChat ? (
              <LoadingCircular size={24} color="main" />
            ) : (
              <SnapChatIcon size={32} />
            )}
          </div>
          <div className="font10">SnapChat</div>
          {/* <div
            className="snapchat-creative-kit-share"
            id={`snapchat-creative-kit-share-article-${data.item_id}`}
            data-theme="dark"
            data-size="large"
            data-text="false"
            data-share-url={createdImageUrl}
            // style={{ display: 'none' }}
          ></div> */}
          {!!data?.sharing && (
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Tiktok_itm">
                <TikTokIcon color={'#333333'} size={48} />
              </div>
              <div className="font10">TikTok</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (data) {
    return (
      <PrimaryModal
        show={show}
        width={'100%'}
        style={{ width: '100%', height: '100%', borderRadius: 0, padding: 0 }}
        onClose={onClose}
      >
        <div className="mobile_share_article_modal">
          {renderHeader()}
          {renderBody()}
          {renderBottom()}
        </div>
      </PrimaryModal>
    );
  } else {
    return <></>;
  }
};
