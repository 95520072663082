import UserFollowerIcon from 'assets/svgs/UserFollowerIcon';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import './CardProfileOrg.styles.scss';

interface DumpData {
  id: number;
  name: string;
  email_prefix: string;
  creator: number;
  description: string;
  image: string;
  joining_type: string;
  timestamp: string;
  colour: number;
  code: string;
  member_number: number;
}

interface CardProfileOrgProps {
  data: DumpData;
  isOwner: boolean;
  isSelected: boolean;
  onSelected: (orgId) => void;
}

export const MobileCardProfileTopOrg = ({
  data,
  isOwner,
  isSelected,
  onSelected
}: CardProfileOrgProps) => {
  const navigate = useNavigate();

  const onDetail = () => {
    onSelected(data.id);
  };

  return (
    <div
      className="mobile_card_profile_top_org clickable"
      onClick={onDetail}
      style={{ width: '100%' }}
    >
      <div className={isSelected ? 'selected_card' : 'default_card'}>
        <img src={data.image} className="top_org_image" />
        <div className="top_org_name font-bold font14">{data.name}</div>
      </div>
    </div>
  );
};
