import React from 'react';

function BookMarkedIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 13}
      height={props.size ?? 18}
      fill="none"
      viewBox="0 0 13 18"
    >
      <path
        d="M11.5001 0.667969H1.50008C1.27907 0.667969 1.06711 0.755766 0.910826 0.912046C0.754546 1.06833 0.666748 1.28029 0.666748 1.5013V16.5013C0.666738 16.6606 0.712389 16.8166 0.798293 16.9507C0.884198 17.0849 1.00676 17.1916 1.15145 17.2583C1.29614 17.3249 1.4569 17.3487 1.61469 17.3268C1.77248 17.3049 1.92069 17.2382 2.04175 17.1346L6.50008 13.393L10.9084 17.093C10.9863 17.1702 11.0786 17.2313 11.1802 17.2728C11.2817 17.3142 11.3904 17.3353 11.5001 17.3346C11.6094 17.3375 11.7178 17.3146 11.8167 17.268C11.9689 17.2055 12.0992 17.0993 12.1911 16.9629C12.2831 16.8264 12.3326 16.6658 12.3334 16.5013V1.5013C12.3334 1.28029 12.2456 1.06833 12.0893 0.912046C11.9331 0.755766 11.7211 0.667969 11.5001 0.667969Z"
        fill={props.color ?? '#FF3E9A'}
      />
    </svg>
  );
}

export default BookMarkedIcon;


