import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import { QuestionAskBar } from 'components/Main/Organisation/components/QuestionAskBar';
import { CardCommunityMember } from 'components/Mobile/Community/Home/components/CardCommunityMember';
import React, { useEffect, useState } from 'react';
import Columns from 'react-columns';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGetOrgDetail, apiGetOrgMembers } from 'shared/api/org';

import './index.styles.scss';

export const MobileOrgMember = () => {
  const navigate = useNavigate();
  const param = useParams();

  const orgId = param.id;

  const [orgImage, setOrgImage] = useState('');
  const [orgName, setOrgName] = useState('');
  const [searchTxt, setSearchTxt] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const [arrUser, setArrUser] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadOrgDetail();
  }, []);

  useEffect(() => {
    loadData();
  }, [searchTxt]);

  const onSearch = (question) => {
    setSearchTxt(question);
  };

  const loadOrgDetail = async () => {
    const res = await apiGetOrgDetail({ organisation: orgId });

    if (res.success) {
      setOrgImage(res.data.image);
      setOrgName(res.data.name);
    }
  };

  const loadData = async (init = true) => {
    if (init) {
      const res = await apiGetOrgMembers({
        organisation: orgId,
        query: searchTxt === '' ? undefined : searchTxt,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrUser(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }
    } else {
      if (hasMore && !isLoading) {
        const res = await apiGetOrgMembers({
          organisation: orgId,
          query: searchTxt === '' ? undefined : searchTxt,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrUser((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }
      }
    }
  };
  const renderHeader = () => {
    return (
      <div className="org_member_header row_align_items">
        <div
          className="clickable"
          onClick={() => {
            navigate(-1);
          }}
        >
          <BlackBackIcon />
        </div>
        {!!orgImage && <img src={orgImage} className="org_image" />}
        <div className="org_title font-bold font20">{orgName}</div>
      </div>
    );
  };

  const renderAskBar = () => {
    return (
      <div className="row_align_items search_view">
        <QuestionAskBar
          onSearch={onSearch}
          onRealTimeSearch={onSearch}
          onFocus={() => {
            setIsSearchFocused(true);
          }}
          onClose={() => {
            setIsSearchFocused(false);
            setSearchTxt('');
          }}
          width="100%"
          height="56px"
          backgroundColor="#F1F6F9"
          placeholder="Search users here...."
          fontSize={20}
          searchIconClickable={true}
          showButton={false}
          showCloseButton={isSearchFocused}
        />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="org_member_body">
        <div className="font-bold font16">People You Might Know</div>
        <div className="mobile_discover_friend_body">
          <Columns columns={2} gap={'8px'} rootStyles={{ paddingBottom: 10 }}>
            {arrUser.map((item, index) => {
              return (
                <CardCommunityMember
                  data={{
                    ...item,
                    id: item.creator_id,
                    image: item.creator_image,
                    name: item.creator_name
                  }}
                  type="org"
                  key={`card_community_${index}`}
                />
              );
            })}
          </Columns>
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_org_member">
      {renderHeader()}
      {renderAskBar()}
      {renderBody()}
    </div>
  );
};
