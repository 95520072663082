import React from 'react';

function EditPencilIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.size ? props.size : '20'}
      height={props?.size ? props.size : '21'}
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        fill={props.color ?? '#000'}
        d="M3 17h4.24a.999.999 0 00.71-.29l6.92-6.93L17.71 7a1.001 1.001 0 000-1.42l-4.24-4.29a1 1 0 00-1.42 0L9.23 4.12l-6.94 6.93a1.001 1.001 0 00-.29.71V16a1 1 0 001 1zm9.76-13.59l2.83 2.83-1.42 1.42-2.83-2.83 1.42-1.42zM4 12.17l5.93-5.93 2.83 2.83L6.83 15H4v-2.83zM19 19H1a1 1 0 000 2h18a1 1 0 100-2z"
      ></path>
    </svg>
  );
}

export default EditPencilIcon;
