import CameraIcon from 'assets/svgs/CameraIcon';
import CloseIcon from 'assets/svgs/CloseIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useRef, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { getBase64String } from 'shared/utils-IPFS/functions';
import useIPFS from 'shared/utils-IPFS/useIPFS';

import './index.styles.scss';
import { useNavigate } from 'react-router-dom';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const MobileCreatePersonalAiModal = ({
  open,
  onClose,
  onSuccess
}: Props) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const { uploadBase64 } = useIPFS();
  const hiddenFileInput = useRef<any>(null);

  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const [aiImage, setAiImage] = useState<any>(userInfo?.image);
  const [aiName, setAiName] = useState('');

  useEffect(() => {
    if (open) {
      setAiName('');
      setAiImage(userInfo?.image);
    }
  }, [open]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    event.preventDefault();
    const files = event.target.files;
    if (files.length) {
      const name = files[0].name;
      const size = files[0].size;
      const type = files[0].type;
      setIsUploadingFile(true);

      const base64String = await getBase64String(files[0]);
      const url = await uploadBase64(base64String, type, '');
      setAiImage(url);

      setIsUploadingFile(false);
    }
  };

  const onCreate = async () => {
    if (aiName === '') {
      showToast(
        'error',
        'Please provide a name for your personal AI to continue.'
      );
      return;
    }

    let ai_image =
      aiImage === null ? 'image_url_null' : aiImage.replace('https://', '');
    navigate(`/personalAi/definePrompt/${aiName}/${ai_image}`);
    onClose();
  };

  const renderUserImage = () => {
    return (
      <div style={{ position: 'relative' }}>
        {aiImage && aiImage.startsWith('https') ? (
          <img
            src={aiImage}
            style={{
              width: 64,
              height: 64,
              borderRadius: 64,
              objectFit: 'cover'
            }}
          />
        ) : (
          <DefaultAvatarIcon size={64} />
        )}
        <div className="camera_icon_tag clickable" onClick={handleClick}>
          {isUploadingFile ? (
            <LoadingCircular color="white" size={16} />
          ) : (
            <CameraIcon size={12} />
          )}
        </div>

        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
          accept="image/*"
        />
      </div>
    );
  };

  const renderPersonName = () => {
    return (
      <div className="personal_name">
        <div className="font-regular font12" style={{ opacity: 0.6 }}>
          Personal AI Name
        </div>
        <input
          value={aiName}
          placeholder="Type AI Name Here..."
          className="sub_input font-bold font20"
          onChange={(e) => setAiName(e.target.value)}
        />
      </div>
    );
  };

  const renderCreateBtn = () => {
    return (
      <PrimaryButton
        label="Create New"
        isLoading={isCreating}
        onPressed={onCreate}
      />
    );
  };

  const renderBody = () => {
    return (
      <div className="mobile_create_personal_ai_modal">
        <div className="top_div"></div>
        <div className="row_align_items" style={{ width: '100%' }}>
          <div onClick={onClose}>
            <CloseIcon color="rgba(0, 0, 0, 0.2)" />
          </div>
          <div
            className="font-bold font18"
            style={{ flex: 1, textAlign: 'center', marginRight: 24 }}
          >
            Create new Personal AI
          </div>
        </div>
        {renderUserImage()}
        {renderPersonName()}
        <div className="horizontal_divider"></div>
        {renderCreateBtn()}
      </div>
    );
  };

  return (
    <Sheet isOpen={open} onClose={onClose} detent={'content-height'}>
      <Sheet.Container
        style={{ borderTopLeftRadius: 32, borderTopRightRadius: 32 }}
      >
        <Sheet.Content>{renderBody()}</Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
