import React, { useState } from 'react';
import moment from 'moment';

import './index.styles.scss';
import ShareIcon from 'assets/svgs/ShareIcon';
import { MobileShareEventModal } from 'components/Modals/MobileShare/ShareEvent';
import { useNavigate } from 'react-router-dom';

export const CardEventAttending = ({ data }) => {
  const navigate = useNavigate();
  const [showShare, setShowShare] = useState(false);

  const onDetail = (e) => {
    e.stopPropagation();
    navigate(`/school/events/${data.qr_code}`);
  };

  const renderShareModal = () => {
    return (
      <MobileShareEventModal
        show={showShare}
        eventData={data}
        onClose={() => {
          setShowShare(false);
        }}
      />
    );
  };

  return (
    <div className="card_event_attending clickable" onClick={onDetail}>
      <div className="row_align_items">
        <div className="card_mark"></div>
        <div className="card_title font-medium font20">{data.title}</div>
        <div
          className="share_btn clickable"
          onClick={(e) => {
            e.stopPropagation();
            setShowShare(true);
          }}
        >
          <ShareIcon color="rgba(0, 0, 0, 0.3)" />
        </div>
      </div>
      <div
        className="row_align_items"
        style={{ marginLeft: 16, marginTop: 6, flex: 1 }}
      >
        <div style={{ fontSize: 14 }}>📍</div>
        <div
          className="card_desc font-regular font12"
          style={{ color: 'rgba(0, 0, 0, 0.6)', marginLeft: 6 }}
        >
          {data.location}
        </div>
      </div>
      <div className="row_align_items" style={{ marginLeft: 16, marginTop: 6 }}>
        <div style={{ fontSize: 14 }}>🗓</div>
        <div
          className="font-regular font14"
          style={{ color: 'rgba(0, 0, 0, 0.6)', marginLeft: 6 }}
        >
          {moment(data.date).format('DD MMM YYYY')} •{' '}
          {moment(data.date).format('hh:mm A')}
        </div>
      </div>
      {renderShareModal()}
    </div>
  );
};
