import React, { useEffect, useState } from 'react';
import WelcomeBg from 'assets/pngs/profile_welcome_bg.png';

import './index.styles.scss';
import { RootState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import { WelcomeList } from './welcome_list';
import { useParams } from 'react-router-dom';
import { apiClaimPoint, apiGetOrgProfile } from 'shared/api/user';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { showToast } from 'shared/ui-kit/PrimaryToast';

export const MobileWelcomeOrg = ({ orgId }) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [followingStatus, setFollowingStatus] = useState<
    'claimable' | 'false' | 'claimed'
  >('false');
  const [eventStatus, setEventStatus] = useState<
    'claimable' | 'false' | 'claimed'
  >('false');
  const [joinStatus, setJoinStatus] = useState<
    'claimable' | 'false' | 'claimed'
  >('false');
  const [profileStatus, setProfileStatus] = useState<
    'claimable' | 'false' | 'claimed'
  >('false');
  const [postStatus, setPostStatus] = useState<
    'claimable' | 'false' | 'claimed'
  >('false');

  const [isClaimFollow, setIsClaimFollow] = useState(false);
  const [isEventFollow, setIsEventFollow] = useState(false);
  const [isJoinFollow, setIsJoinFollow] = useState(false);
  const [isProfileFollow, setIsProfileFollow] = useState(false);
  const [isPostFollow, setIsPostFollow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [orgImage, setOrgImage] = useState('');
  const [orgName, setOrgName] = useState('');
  const [totalProgress, setTotalProgress] = useState(0);
  const [totalPoint, setTotalPoint] = useState(0);

  useEffect(() => {
    loadData();
  }, [orgId]);

  const loadData = async () => {
    setIsLoading(true);
    const res = await apiGetOrgProfile({ organisation: orgId });

    if (res.success) {
      setFollowingStatus(res.data.following);
      setEventStatus(res.data.events);
      setJoinStatus(res.data.joining);
      setProfileStatus(res.data.profile);
      setPostStatus(res.data.post);

      setOrgName(res.org_name);
      setOrgImage(res.org_image);
      setTotalProgress(res.progress);
      setTotalPoint(res.total_points);
    }

    setIsLoading(false);
  };

  const onClaim = async (
    task: 'following' | 'events' | 'joining' | 'profile' | 'post'
  ) => {
    if (
      !isClaimFollow &&
      !isEventFollow &&
      !isJoinFollow &&
      !isProfileFollow &&
      !isPostFollow
    ) {
      if (task === 'following') {
        setIsClaimFollow(true);
      } else if (task === 'events') {
        setIsEventFollow(true);
      } else if (task === 'joining') {
        setIsJoinFollow(true);
      } else if (task === 'post') {
        setIsPostFollow(true);
      } else if (task === 'profile') {
        setIsProfileFollow(true);
      }

      const res = await apiClaimPoint({
        task: task,
        item_id: orgId,
        item_type: 'organisation'
      });

      if (res.success) {
        if (task === 'following') {
          setFollowingStatus('claimed');
        } else if (task === 'events') {
          setEventStatus('claimed');
        } else if (task === 'joining') {
          setJoinStatus('claimed');
        } else if (task === 'post') {
          setPostStatus('claimed');
        } else if (task === 'profile') {
          setProfileStatus('claimed');
        }

        showToast(
          'success',
          'Congratulations! You have successfully claimed your points.'
        );
      }

      if (task === 'following') {
        setIsClaimFollow(false);
      } else if (task === 'events') {
        setIsEventFollow(false);
      } else if (task === 'joining') {
        setIsJoinFollow(false);
      } else if (task === 'post') {
        setIsPostFollow(false);
      } else if (task === 'profile') {
        setIsProfileFollow(false);
      }
    }
  };

  const boxShadow = '0px 20px 28px rgba(205, 12, 104, 0.1)';

  const renderWelcome = () => {
    return (
      <div className="welcome_to">
        <img src={WelcomeBg} className="welcome_to_bg" />
        <div className="welcome_main_body">
          <div className="image_container row_align_items">
            <div
              className="welcome_item"
              style={{
                transform: `rotate(-7.86deg)`,
                filter: `drop-shadow(${boxShadow})`
              }}
            >
              <img src={orgImage} className="welcome_item_image" />
            </div>
            <div
              className="welcome_item"
              style={{
                marginLeft: -24,
                marginBottom: 8,
                transform: `rotate(11.41deg)`,
                filter: `drop-shadow(${boxShadow})`
              }}
            >
              <img src={userInfo?.image} className="welcome_item_image" />

              <div className="welcome_hand">
                <div className="font-bold font32">👋</div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: 16, textAlign: 'center' }}>
            <div className="font-bold font24">Welcome to {orgName}</div>
            <div
              className="font-bold font12"
              style={{ opacity: 0.4, textAlign: 'center' }}
            >
              You are a campus newbie now
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderWelcomeList = () => {
    return (
      <WelcomeList
        orgId={orgId}
        orgName={orgName}
        followingStatus={followingStatus}
        eventStatus={eventStatus}
        joinStatus={joinStatus}
        profileStatus={profileStatus}
        postStatus={postStatus}
        isFollowingFollow={isClaimFollow}
        isFollowingEvent={isEventFollow}
        isFollowingJoin={isJoinFollow}
        isFollowingPost={isPostFollow}
        isFollowingProfile={isProfileFollow}
        totalPoint={totalPoint}
        totalProgress={totalProgress}
        onClaim={onClaim}
      />
    );
  };

  if (isLoading) {
    return (
      <div className="mobile_welcome_org">
        <div className="loading_container">
          <LoadingBarAnimation />
        </div>
      </div>
    );
  } else {
    return (
      <div className="mobile_welcome_org">
        {renderWelcome()}
        {renderWelcomeList()}
      </div>
    );
  }
};
