import React, { useEffect, useRef, useState } from 'react';
import randomcolor from 'randomcolor';
import polished, { darken } from 'polished';

import { apiGetTopicMatches } from 'shared/api/user';

import './index.styles.scss';
import { apiGetMicroInterest } from 'shared/api/topic';
import { apiDeletionInterest } from 'shared/api/delete';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import TrashIcon from 'assets/svgs/TrashIcon';

export const MobileProfileCommonInterest = ({ userId, isOwner }) => {
  const mainScrollRef = useRef<HTMLDivElement>(null);
  const subScrollRef = useRef<HTMLDivElement>(null);

  const [arrTopic, setArrTopic] = useState<Array<any>>([]);
  const [hasMore, setHasMore] = useState(true);
  const [lastId, setLastId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedTopic, setSelectedTopic] = useState<any>(null);

  const [arrSubTopic, setArrSubTopic] = useState<Array<any>>([]);
  const [hasMoreSubTopic, setHasMoreSubTopic] = useState(true);
  const [lastIdSubTopic, setLastIdSubTopic] = useState(0);
  const [isLoadingSubTopic, setIsLoadingSubTopic] = useState(false);

  useEffect(() => {
    loadData();
  }, [userId]);

  useEffect(() => {
    if (selectedTopic) {
      loadSubTopicData();
    } else {
      setArrSubTopic([]);
      setHasMoreSubTopic(true);
    }
  }, [selectedTopic]);

  const loadData = async (init = true) => {
    if (init) {
      setIsLoading(true);

      const res = await apiGetTopicMatches({
        toUser: userId,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrTopic(
          res.data.map((item, index) => {
            return {
              ...item,
              color: randomcolor()
            };
          })
        );
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }

      setIsLoading(false);
    } else {
      if (!isLoading && hasMore) {
        setIsLoading(true);

        const res = await apiGetTopicMatches({
          toUser: userId,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrTopic((prev) => [
            ...prev,
            ...res.data.map((item, index) => {
              return {
                ...item,
                color: randomcolor()
              };
            })
          ]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoading(false);
      }
    }
  };

  const loadSubTopicData = async (init = true) => {
    if (init) {
      setIsLoadingSubTopic(true);

      const res = await apiGetMicroInterest({
        topic: selectedTopic.topic,
        userId: userId,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrSubTopic(res.data);
        setLastIdSubTopic(res.lastId);
        setHasMoreSubTopic(res.hasMore);
      }

      setIsLoadingSubTopic(false);
    } else {
      if (!isLoadingSubTopic && hasMoreSubTopic) {
        setIsLoadingSubTopic(true);

        const res = await apiGetMicroInterest({
          topic: selectedTopic.topic,
          userId: userId,
          limit: 10,
          offset: lastIdSubTopic
        });

        if (res.success) {
          setArrSubTopic((prev) => [...prev, ...res.data]);
          setLastIdSubTopic(res.lastId);
          setHasMoreSubTopic(res.hasMore);
        }

        setIsLoadingSubTopic(false);
      }
    }
  };

  const handleMainScroll = () => {
    const container = mainScrollRef.current;
    if (container) {
      if (
        container.scrollLeft + container.clientWidth >=
        container.scrollWidth
      ) {
        loadData(false);
      }
    }
  };

  const handleSubScroll = () => {
    const container = subScrollRef.current;
    if (container) {
      if (
        container.scrollLeft + container.clientWidth + 1 >=
        container.scrollWidth
      ) {
        loadSubTopicData(false);
      }
    }
  };

  const onSelectedMainTopic = (item) => {
    if (selectedTopic && selectedTopic.topic == item.topic) {
      setSelectedTopic(null);
    } else {
      setSelectedTopic(item);
    }
  };

  const onRemoveSubInterestOnUI = (itemId) => {
    setArrSubTopic((prev) => prev.filter((item, index) => item.id !== itemId));
  };

  const renderHeader = () => {
    return (
      <div className="sub_title font-bold font12">
        {isOwner ? 'Top Interests' : 'Common interest'}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div
        className="topic_list"
        ref={mainScrollRef}
        onScroll={handleMainScroll}
      >
        {arrTopic.map((item, index) => {
          return (
            <InterestItem
              key={`mobile_profile_interest_${index}`}
              data={item}
              onClick={() => {
                onSelectedMainTopic(item);
              }}
            />
          );
        })}
      </div>
    );
  };

  const renderSubTopic = () => {
    if (arrSubTopic.length > 0) {
      return (
        <div
          className="sub_topic_list"
          ref={subScrollRef}
          onScroll={handleSubScroll}
        >
          {arrSubTopic.map((item, index) => {
            return (
              <SubInterestItem
                key={`card_sub_topic_${index}`}
                data={item}
                onRemoveOnUI={onRemoveSubInterestOnUI}
              />
            );
          })}
        </div>
      );
    } else {
      return <></>;
    }
  };

  if (arrTopic.length > 0) {
    return (
      <div className="mobile_prfoile_common_interest">
        {renderHeader()}
        {renderBody()}
        {renderSubTopic()}
      </div>
    );
  } else {
    return <></>;
  }
};

const InterestItem = ({ data, onClick }) => {
  return (
    <div className="mobile_topic_item row_align_items" onClick={onClick}>
      <div style={{ fontSize: 20 }}>{data.topic_emoji}</div>
      <div
        className="topic_name_view"
        style={{
          backgroundColor: `rgba(${parseInt(
            data.color.slice(1, 3),
            16
          )}, ${parseInt(data.color.slice(3, 5), 16)}, ${parseInt(
            data.color.slice(5, 7),
            16
          )}, ${0.3})`
        }}
      >
        <div
          className="topic_name font-bold font12"
          style={{ color: darken(0.2, data.color) }}
        >
          {data.topic_name}
        </div>
      </div>
    </div>
  );
};

const SubInterestItem = ({ data, onRemoveOnUI }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = async () => {
    if (!isDeleting) {
      setIsDeleting(true);

      const res = await apiDeletionInterest({
        interest: data.id
      });

      if (res.success) {
        showToast('success', 'Interest removed successfully!');
        onRemoveOnUI(data.id);
      }

      setIsDeleting(false);
    }
  };

  return (
    <div className="sub_topic_card row_align_items">
      <div className="font-regular font12">{data.topic_name}</div>
      <div className="trash_btn" onClick={onDelete}>
        {isDeleting ? (
          <LoadingCircular size={16} color="main" />
        ) : (
          <TrashIcon size={16} color="#FF3E9A" />
        )}
      </div>
    </div>
  );
};
