import CloseIcon from 'assets/svgs/CloseIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import DownloadIcon from 'assets/svgs/DownloadIcon';
import TelegramIcon from 'assets/svgs/TelegramIcon';
import TikTokIcon from 'assets/svgs/TikTokIcon';
import TwitterIcon from 'assets/svgs/TwitterIcon';
import WhatsappIcon from 'assets/svgs/WhatsappIcon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import { RootState } from 'redux/interfaces';
import { apiQAGetShareLink } from 'shared/api/qa';
import {
  apiGetTopicPercentage,
  apiGetUserMobileQR,
  apiGetUserSharingQR
} from 'shared/api/user';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { getImageFromHtml } from 'shared/utils/utils';

import './index.styles.scss';

interface MobileUSerQRCodeModalProps {
  show: boolean;
  onClose: () => void;
}

export const MobileUSerQRCodeModal = ({
  show,
  onClose
}: MobileUSerQRCodeModalProps) => {
  const { uploadBase64 } = useIPFS();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [isDownloading, setIsDownloading] = useState(false);
  const [qrCodeImage, setQRCodeImage] = useState('');
  const [shareUrl, setShareUrl] = useState('');
  const [percentInfo, setPercentInfo] = useState<any>(null);

  useEffect(() => {
    if (show) {
      loadUserQRCode();
      loadPercentInfo();
    }
  }, [show]);

  const loadPercentInfo = async () => {
    const res = await apiGetTopicPercentage({
      userId: userInfo?.id
    });
    setPercentInfo(res.data);
  };

  const initShareUrl = async () => {
    const base64Image = await getImageFromHtml(`user_info_with_qr`);
    const url = await uploadBase64(base64Image, 'image/png', '');

    const res = await apiQAGetShareLink({
      url: url,
      item_id: userInfo?.id ?? 0,
      type: 'user'
    });

    if (res.success) {
      setShareUrl(res.sharing_url);
    }
  };

  const loadUserQRCode = async () => {
    const res = await apiGetUserSharingQR();

    if (res.success) {
      setQRCodeImage(res.qr_url);

      initShareUrl();
    }
  };

  const onShare = async (type: 'download') => {
    if (!isDownloading)
      if (type === 'download') {
        setIsDownloading(true);
        let base64Image = await getImageFromHtml('user_info_with_qr');

        const url = await uploadBase64(base64Image, 'image/png', '');

        await onDownloadFile(url);
        setIsDownloading(true);
      }
  };

  const onDownloadFile = async (fileUrl) => {
    if (!isDownloading) {
      setIsDownloading(true);

      fetch(fileUrl)
        .then((response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'share_image';
            alink.click();
          });
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  };

  const renderHeader = () => {
    return (
      <div
        className="row_align_items"
        style={{ width: '100%', paddingRight: 24 }}
      >
        <div
          className="align-vertical-center clickable"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon color="#000000" />
        </div>
        <div className="qr_title font-bold font16">Referral QR Code</div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="main_body">
        <div id={'user_info_with_qr'} className="qr_image_body">
          <div className="row_space_between row_align_items">
            <div className="row_align_items">
              <div className="align-vertical-center">
                {userInfo?.image && userInfo?.image.startsWith('https') ? (
                  <img
                    src={userInfo?.image}
                    style={{ width: 32, height: 32, borderRadius: 32 }}
                  />
                ) : (
                  <DefaultAvatarIcon size={32} />
                )}
              </div>
              <div style={{ marginLeft: 6 }}>
                <div className="font-bold font12">{userInfo?.name}</div>
                {percentInfo && renderPercentInfo()}
              </div>
            </div>

            <div
              className="font-bold font12"
              style={{ color: 'rgba(0, 0, 0, 0.3)' }}
            >
              {userInfo?.code}
            </div>
          </div>
          <div className="image_view">
            {!!qrCodeImage && (
              <img
                src={qrCodeImage}
                style={{ width: '100%', height: '100%' }}
                crossOrigin="anonymous"
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderPercentInfo = () => {
    return (
      <div
        className="font-regular font10"
        style={{ marginTop: 4, whiteSpace: 'nowrap' }}
      >
        {`Top ${percentInfo!.percentile}% in ${percentInfo!.topic_emoji} ${
          percentInfo!.topic_name
        }`}
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="bottom">
        <div className="divider"></div>
        <div className="statement_link_bar">
          <div className="statement_link_bar_items">
            <div
              className="statement_link_bar_items_item download_itm clickable"
              onClick={() => onShare('download')}
            >
              {isDownloading ? (
                <LoadingCircular size={20} color={'white'} />
              ) : (
                <DownloadIcon color={'white'} />
              )}
            </div>
            <div className="font10">Download</div>
          </div>

          {false ? (
            <LoadingCircular size={48} color="main" />
          ) : (
            <WhatsappShareButton title="" url={shareUrl}>
              <div className="statement_link_bar_items">
                <div className="statement_link_bar_items_item Whatsapp_itm">
                  <WhatsappIcon />
                </div>
                <div className="font10">Whatsapp</div>
              </div>
            </WhatsappShareButton>
          )}

          {false ? (
            <LoadingCircular size={48} color="main" />
          ) : (
            <TelegramShareButton title="" url={shareUrl}>
              <div className="statement_link_bar_items">
                <div className="statement_link_bar_items_item Telegram_itm ">
                  <TelegramIcon />
                </div>
                <div className="font10">Telegram</div>
              </div>
            </TelegramShareButton>
          )}

          {false ? (
            <LoadingCircular size={48} color="main" />
          ) : (
            <FacebookShareButton title="" url={shareUrl}>
              <div className="statement_link_bar_items">
                <div
                  className="statement_link_bar_items_item Telegram_itm "
                  style={{ padding: 0 }}
                >
                  <FacebookIcon borderRadius={16} size={48} />
                </div>
                <div className="font10">Facebook</div>
              </div>
            </FacebookShareButton>
          )}

          {false ? (
            <LoadingCircular size={48} color="main" />
          ) : (
            <TwitterShareButton title={''} url={shareUrl}>
              <div className="statement_link_bar_items">
                <div className="statement_link_bar_items_item Twitter_itm">
                  <TwitterIcon />
                </div>
                <div className="font10">Twitter</div>
              </div>
            </TwitterShareButton>
          )}

          {/* <div className="statement_link_bar_items">
            <div className="statement_link_bar_items_item Tiktok_itm">
              <TikTokIcon />
            </div>
            <div className="font10">Tiktok</div>
          </div> */}
        </div>
      </div>
    );
  };

  return (
    <PrimaryModal show={show} width={'calc(100% - 32px)'} onClose={onClose}>
      <div className="mobile_invite_using_qr">
        {renderHeader()}
        {renderBody()}
        {renderBottom()}
      </div>
    </PrimaryModal>
  );
};
