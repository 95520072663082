import React, { useEffect, useRef, useState } from 'react';

import OrgPng from 'assets/pngs/ai_image.png';

import './index.styles.scss';
import { apiGetUserPersonalAIs } from 'shared/api/user';
import { RootState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MobileProfileCardPersonalAI } from './CardPersonalAI';

interface Props {
  userId: any;
  isLoadedAll: boolean;
  onLoaded: () => void;
}

export const MobileProfilePersonalAi = ({
  userId,
  isLoadedAll,
  onLoaded
}: Props) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const scrollRef = useRef(null);

  const [arrAi, setArrAi] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (userId) {
      setIsOwner(userId === userInfo?.id);
      loadData();
    }
  }, [userId]);

  const loadData = async (init = true) => {
    if (init) {
      setIsLoading(true);

      const res = await apiGetUserPersonalAIs({
        userId: userId,
        limit: 10,
        offset: 0
      });
      onLoaded();

      if (res.success) {
        setArrAi(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }

      setIsLoading(false);
    } else {
      if (!isLoading && hasMore) {
        setIsLoading(true);

        const res = await apiGetUserPersonalAIs({
          userId: userId,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrAi((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoading(false);
      }
    }
  };

  const onViewAll = () => {
    navigate('/myAi');
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = scrollRef.current;
      const isEndReached = scrollLeft + clientWidth === scrollWidth;

      if (isEndReached) {
        loadData(false);
      }
    }
  };

  const renderTop = () => {
    return (
      <div className="profile_org_body row_space_between">
        <div className="row_align_items">
          <img src={OrgPng} width={24} height={24} />
          <div className="font-bold font16" style={{ marginLeft: 8 }}>
            Personal AI
          </div>
        </div>
        {arrAi.length > 3 && isOwner && (
          <div
            className="row_align_items join_new font-bold font12"
            onClick={onViewAll}
          >
            View All
          </div>
        )}
      </div>
    );
  };

  const renderDivider = () => {
    return <div className="profile_section_divider"></div>;
  };

  const renderBody = () => {
    return (
      <div className="render_block" id={'profile_joined_org'}>
        {renderTop()}
        <div className="ai_list" ref={scrollRef} onScroll={handleScroll}>
          {arrAi.map((item, index) => {
            return (
              <MobileProfileCardPersonalAI
                index={index}
                key={`mobile_card_personal_ai_${index}`}
                data={item}
                isOwner={isOwner}
              />
            );
          })}
        </div>
      </div>
    );
  };

  if (!isLoadedAll) {
    return <div />;
  } else {
    if (arrAi.length === 0) {
      return <div />;
    } else {
      return (
        <div className="mobile_profile_personal_ai">
          {renderDivider()}
          {renderBody()}
        </div>
      );
    }
  }
};
