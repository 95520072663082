import React, { useEffect, useState } from 'react';
import Columns from 'react-columns';
import InfiniteScrollWrapper from 'components/Common/WrapperScroll';
import { apiEventAll } from 'shared/api/event';
import { CardAllEvent } from './CardAllEvent';

import BG_EMPTY_EVENT from 'assets/pngs/bg_empty_event.png';

import './index.styles.scss';
import { CreateEventModal } from 'components/Modals/CreateEventModal';
import { RootState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import { MobileCreateEventModal } from 'components/Modals/CreateEventModal/MobileCreateEventModal';

export const AllEvent = ({ orgId, isFocusedSearch, searchTxt }) => {
  const { hasNewEvent } = useSelector((state: RootState) => state.app);
  const [tab, setTab] = useState<'day' | 'week' | 'month'>('day');

  const [arrEvent, setArrEvent] = useState<any>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  //create event modal
  const [isEventModalShow, setIsEventModalShow] = useState(false);

  useEffect(() => {
    loadData();
  }, [tab, hasNewEvent, searchTxt]);

  const loadData = async (init = true) => {
    if (init) {
      const res = await apiEventAll({
        query: searchTxt,
        orgs: orgId,
        period: isFocusedSearch ? null : tab,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrEvent(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setIsLoaded(true);
      }
    } else {
      const res = await apiEventAll({
        query: searchTxt,
        orgs: orgId,
        period: isFocusedSearch ? null : tab,
        limit: 10,
        offset: lastId
      });

      if (res.success) {
        setArrEvent((prev) => [...prev, ...res.data]);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }
    }
  };

  const renderHeader = () => {
    return (
      <div className="row_space_between" style={{ margin: '0px 16px' }}>
        <div className="font-bold font20">All Events</div>
      </div>
    );
  };

  const renderTab = () => {
    return (
      <div className="tab_container row_align_items">
        <div
          className={`${
            tab === 'day' ? 'selected_tab' : 'default_tab'
          } clickable font-bold font12`}
          onClick={() => setTab('day')}
        >
          Today
        </div>
        <div
          className={`${
            tab === 'week' ? 'selected_tab' : 'default_tab'
          } clickable font-bold font12`}
          onClick={() => setTab('week')}
        >
          This Week
        </div>
        <div
          className={`${
            tab === 'month' ? 'selected_tab' : 'default_tab'
          } clickable font-bold font12`}
          onClick={() => setTab('month')}
        >
          This Month
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="mobile_all_event_body" id="mobile_all_event_body">
        <InfiniteScrollWrapper
          isInfiniteScrollOn={true}
          lengthData={arrEvent.length}
          functionNext={() => {
            loadData(false);
          }}
          hasOrgsMore={hasMore}
          parentId="mobile_all_event_body"
        >
          {
            <Columns columns={1} gap={'8px'}>
              {arrEvent.map((item, index) => {
                return (
                  <CardAllEvent data={item} key={`card_all_event_${index}`} />
                );
              })}
            </Columns>
          }
        </InfiniteScrollWrapper>
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <div className="event_empty_view">
        <div>
          <img
            src={BG_EMPTY_EVENT}
            style={{ width: 220, aspectRatio: 213 / 119 }}
          />
        </div>
        <div className="font-bold font18" style={{ marginTop: 16 }}>
          {isFocusedSearch ? 'No Events Found' : 'No Event for Today'}
        </div>
        <div
          className="font-regular font12"
          style={{ opacity: 0.6, marginTop: 8, textAlign: 'center' }}
        >
          Host your own event and hang out
          <br />
          with your friends
        </div>
        <div
          className="create_event_btn font-bold font14 clickable"
          onClick={() => {
            setIsEventModalShow(true);
          }}
        >
          Create Event
        </div>
      </div>
    );
  };

  const renderCreateModal = () => {
    return (
      <MobileCreateEventModal
        show={isEventModalShow}
        onClose={() => {
          setIsEventModalShow(false);
          console.log('--clickable');
        }}
        onSuccess={() => {
          loadData();
          setIsEventModalShow(false);
        }}
      />
    );
  };

  return (
    <div className="mobile_all_event">
      {searchTxt === '' && renderHeader()}
      {searchTxt === '' && renderTab()}
      {isLoaded && arrEvent.length === 0 ? renderEmpty() : renderBody()}
      {renderCreateModal()}
    </div>
  );
};
