import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import VerticalDetailIcon from 'assets/svgs/VerticalDetailIcon';
import VolumnMuteIcon from 'assets/svgs/VolumnMuteIcon';
import { MobilePromptActionModal } from 'components/Modals/Mobile/PromptActionModal';
import { MobileShareBotModal } from 'components/Modals/MobileShare/ShareBot';
import React, { useState } from 'react';
import { apiChatMute, apiChatUnMute } from 'shared/api/chat';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { getWindowWidth } from 'shared/utils/utils';

import './index.styles.scss';

interface Props {
  data: any;
  index: any;
  roomId: any;
  totalCount: number;
  isEndedChat: boolean;
  onDeleteFromUI: (botId) => void;
  onSuccessMute: (botName) => void;
}

export const MobileCardBot = ({
  data,
  index,
  roomId,
  totalCount,
  isEndedChat,
  onDeleteFromUI,
  onSuccessMute
}: Props) => {
  const [showActionDlg, setShowActionDlg] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const [isMuted, setIsMuted] = useState(data.is_active !== 1);
  const [isMuting, setIsMuting] = useState(false);

  const onMute = async () => {
    if (!isMuting) {
      if (isMuted) {
        setIsMuting(true);

        const res = await apiChatUnMute({ room: roomId, agent: data.id });

        if (res.success) {
          setIsMuted(false);
        }
        setIsMuting(false);
      } else {
        setIsMuting(true);

        const res = await apiChatMute({ room: roomId, agent: data.id });

        if (res.success) {
          setIsMuted(true);
          onSuccessMute(data.ai_name);
        }
        setIsMuting(false);
      }
    }
  };

  const renderActionModal = () => {
    return (
      <MobilePromptActionModal
        open={showActionDlg}
        roomId={roomId}
        aiId={data.ai_id}
        promptTxt={data.prompt}
        isAgentCreator={data.is_agent_creator === 1}
        enableDelete={totalCount > 1}
        onDeleteFromUI={onDeleteFromUI}
        onClose={() => {
          setShowActionDlg(false);
        }}
      />
    );
  };

  const renderShareModal = () => {
    return (
      <MobileShareBotModal
        show={showShareModal}
        data={data}
        onClose={() => {
          setShowShareModal(false);
        }}
      />
    );
  };

  return (
    <div
      className="mobile_promot_card_bot row_align_items"
      style={{
        minWidth: getWindowWidth() - 80,
        maxWidth: getWindowWidth() - 80,
        overflow: 'hidden'
      }}
    >
      {data.ai_image && data.ai_image.startsWith('https') ? (
        <img src={data.ai_image} className="bot_image" />
      ) : (
        <DefaultAvatarIcon size={45} />
      )}
      <div
        className="bot_body"
        style={
          isEndedChat
            ? { width: 'calc(100%)' }
            : { width: 'calc(100% - 170px)' }
        }
      >
        <div className="personal_ai_txt font-bold font12">Personal AI</div>
        <div className="personal_ai_name font-bold font14">{data.ai_name}</div>
      </div>
      {!isEndedChat && (
        <div className="row_align_items" style={{ gap: 8, marginLeft: 16 }}>
          <div
            className="bot_action_btn"
            onClick={() => {
              onMute();
            }}
            style={!isMuted ? {} : { backgroundColor: '#D6D6D6' }}
          >
            {isMuting ? (
              <LoadingCircular color="main" size={16} />
            ) : !isMuted ? (
              <VolumnMuteIcon color="#FF3E9A" />
            ) : (
              <VolumnMuteIcon color="black" />
            )}
          </div>
          <div
            className="bot_action_btn"
            onClick={() => {
              setShowShareModal(true);
            }}
          >
            <ShareIcon size={16} color="#FF3E9A" />
          </div>
          <div
            className="bot_action_btn"
            onClick={() => {
              setShowActionDlg(true);
            }}
          >
            <VerticalDetailIcon size={16} />
          </div>
        </div>
      )}
      {renderActionModal()}
      {renderShareModal()}
    </div>
  );
};
