import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import FilterCheckIcon from 'components/Modals/Mobile/FeedFilterModal/TypeFilter/svgs/FilterCheckIcon';
import React from 'react';

import './index.styles.scss';

interface Props {
  isSelected: boolean;
  data: any;
  onPressed: (item) => void;
}

export const MobileCardAddBot = ({ isSelected, data, onPressed }: Props) => {
  const renderBotIcon = () => {
    return (
      <div className="bot_icon">
        <RobotIcon size={16} color={'white'} />
      </div>
    );
  };

  return (
    <div
      className="mobile_card_add_bot row_align_items"
      onClick={() => {
        onPressed(data);
      }}
    >
      <div style={{ position: 'relative' }}>
        {data.ai_image && data.ai_image.startsWith('https') ? (
          <img src={data.ai_image} className="bot_image" />
        ) : (
          <DefaultAvatarIcon size={40} />
        )}
        {renderBotIcon()}
      </div>

      <div className="main_info">
        <div className="font-bold font16">{data.ai_name}</div>
        <div className="bot_description font-regular font12">
          {data.description}
        </div>
      </div>
      <div className="action_btn">
        {isSelected ? (
          <FilterCheckIcon />
        ) : (
          <div className="default_mark"></div>
        )}
      </div>
    </div>
  );
};
