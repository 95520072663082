import React, { useMemo, useState } from 'react';
import { getArrFromString } from 'shared/utils/utils';
import { MobileShareArticleModal } from 'components/Modals/MobileShare/ShareArticle';
import ImageGallery from 'react-image-gallery';

import './index.styles.scss';

interface Props {
  data: any;
}

export const MobileFeedCardFile = ({ data }: Props) => {
  const [showShareModal, setShowShareModal] = useState(false);

  const images = useMemo(() => {
    if (data.images) {
      let arrImage = getArrFromString(data.images, ',');

      return arrImage.map((item, index) => {
        return {
          original: item,
          thumbnail: item
        };
      });
    } else {
      return null;
    }
  }, [data]);

  const renderBody = () => {
    return (
      <div className="did_know_body">
        <div className="feed_main_content">
          {images && images.length > 0 && (
            <div className="image_container">
              <ImageGallery
                items={images}
                showThumbnails={false}
                showPlayButton={false}
                showNav={false}
                showFullscreenButton={false}
              />
            </div>
          )}
          <div className="article_title font24">
            {data.title.replace(/^"|"$/g, '')}
          </div>
          <div className="artile_txt">{data.text}</div>
        </div>
      </div>
    );
  };

  const renderBottomShadow = () => {
    return <div className="did_know_bottom"></div>;
  };

  const renderShareModal = () => {
    return (
      <MobileShareArticleModal
        data={data}
        show={showShareModal}
        onClose={() => {
          setShowShareModal(false);
        }}
      />
    );
  };

  return (
    <div className="mobile_card_file">
      {renderBody()}
      {renderBottomShadow()}
      {/* {renderRightAction()} */}
      {renderShareModal()}
    </div>
  );
};
