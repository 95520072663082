import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
const useLogin = () => {
  const [loginStatus, setLoginStatus] = useState({
    isSignedIn: false,
    isLoginChecking: true
  });

  useEffect(() => {
    if (!loginStatus.isSignedIn && loginStatus.isLoginChecking) {
      let token: string = Cookies.get('accessToken') || '';
      let userId: string = localStorage.getItem('userId') || '';

      if (
        !token ||
        token === '' ||
        token === 'undefined' ||
        !userId ||
        userId === '' ||
        userId === 'undefined'
      ) {
        setLoginStatus({
          isSignedIn: false,
          isLoginChecking: false
        });
      } else {
        setLoginStatus({
          isSignedIn: true,
          isLoginChecking: false
        });
      }
    }
  });

  return loginStatus;
};

export { useLogin };
