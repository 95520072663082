import React, { useEffect, useMemo, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { useNavigate } from 'react-router-dom';
import {
  apiGetUserPersonalAIs,
  apiGetUserInfo,
  apiGetUserCommunityProfile
} from 'shared/api/user';
import { MobileCardUserBot } from './CardBot';

import BG_TOP_VIEW from 'assets/pngs/bg_discover.png';

import './index.styles.scss';
import { TopView } from './TopView';
import { CommonInterest } from './CommonInterest';

import { MobileChatIntroModal } from '../ChatIntroModal';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';

interface Props {
  open: boolean;
  userId: any;
  onClose: () => void;
  onViewOther: (otherId) => void;
}

export const MobileUserBotModal = ({
  open,
  userId,
  onClose,
  onViewOther
}: Props) => {
  const navigate = useNavigate();

  const [arrAi, setArrAi] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [userData, setUserData] = useState<any>(null);
  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(false);

  const [showIntroModal, setShowIntroModal] = useState(false);
  const [selectedBot, setSelectedBot] = useState<any>(null);

  useEffect(() => {
    if (!open) {
      setArrAi([]);
    }
  }, [open]);

  useEffect(() => {
    if (userData) {
      loadBotData();
    }
  }, [userData]);

  useEffect(() => {
    if (userId) {
      loadUserInfo();
    }
  }, [userId]);

  const loadUserInfo = async () => {
    setIsLoadingUserInfo(true);
    const res = await apiGetUserCommunityProfile(userId);

    if (res.success) {
      setUserData(res.data);
    }

    setIsLoadingUserInfo(false);
  };

  const loadBotData = async (init = true) => {
    if (init) {
      setIsLoading(true);

      const res = await apiGetUserPersonalAIs({
        userId: userData.id,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrAi(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }

      setIsLoading(false);
    } else {
      if (!isLoadingMore && hasMore) {
        setIsLoadingMore(true);

        const res = await apiGetUserPersonalAIs({
          userId: userData.id,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrAi((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoadingMore(false);
      }
    }
  };

  const onViewProfile = () => {
    onClose();
    navigate(`/profile/${userId}`);
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    if (bottom) {
      loadBotData(false);
    }
  };

  const handleScrollCloseModal = (event) => {
    event.preventDefault();
  };

  const vibeStatue = useMemo(() => {
    if (userData) {
      return userData.vibes_type;
    } else {
      return 'low';
    }
  }, [userData]);

  const renderModalTopView = () => {
    return (
      <div className="modal_top_view">
        <img src={BG_TOP_VIEW} className="bg_image" />
        <TopView userData={userData} onClose={onClose} />
      </div>
    );
  };

  const renderUserInfo = () => {
    return (
      <div className="user_info row_align_items row_space_between">
        <div style={{ flex: 1 }}>
          <div className="user_name font-bold font20">{userData.name}</div>
          <div
            className="view_profile font-bold font14"
            onClick={onViewProfile}
          >
            View profile
          </div>
        </div>

        <div className="vibe_container">
          <div className="font-bold font10" style={{ opacity: 0.4 }}>
            Vibe Match
          </div>
          {renderStatus()}
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    return (
      <div className="row_align_items" style={{ marginTop: 4, gap: 6 }}>
        <div
          className={`font-bold font12 ${
            vibeStatue === 'low'
              ? 'low_color'
              : vibeStatue === 'medium'
              ? 'med_color'
              : 'high_color'
          }`}
        >
          {vibeStatue === 'low'
            ? 'Low'
            : vibeStatue === 'medium'
            ? 'Medium'
            : 'High'}
        </div>
        <div className="progress_bar">
          <div
            className={`actived_progress ${
              vibeStatue === 'low'
                ? 'low_bar_color'
                : vibeStatue === 'medium'
                ? 'med_bar_color'
                : 'high_bar_color'
            }`}
            style={{
              width: `${
                ((userData.suggestion_score ?? userData.vibes_score) === 0
                  ? 0.1
                  : userData.suggestion_score ?? userData.vibes_score) * 100
              }%`
            }}
          ></div>
        </div>
      </div>
    );
  };

  const renderCommontInterest = () => {
    return (
      <CommonInterest
        userData={userData}
        onViewProfile={(id) => {
          onViewOther(id);
        }}
      />
    );
  };

  const renderBotList = () => {
    if (arrAi.length > 0) {
      return (
        <div className="bot_list">
          <div className="font-bold font16" style={{ marginLeft: 16 }}>
            Bots
          </div>
          <div className="bot_content" onScroll={handleScroll}>
            {arrAi.map((item, index) => {
              return (
                <MobileCardUserBot
                  key={`card_item_${index}`}
                  data={item}
                  onPressed={() => {
                    setShowIntroModal(true);
                    setSelectedBot(item);
                  }}
                />
              );
            })}
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderIntroModal = () => {
    return (
      <MobileChatIntroModal
        open={showIntroModal}
        aiInfo={selectedBot}
        onClose={() => {
          setShowIntroModal(false);
        }}
      />
    );
  };

  return (
    <Sheet
      isOpen={open}
      disableDrag={true}
      className="custom_add_bot_modal"
      onClose={onClose}
      onScroll={handleScrollCloseModal}
    >
      <Sheet.Container className="bot_modal_body">
        <Sheet.Content>
          {!isLoadingUserInfo && userData ? (
            <div className="mobile_user_bot_modal">
              {renderModalTopView()}
              {renderUserInfo()}
              {renderCommontInterest()}
              {renderBotList()}
              {renderIntroModal()}
            </div>
          ) : (
            <div className="mobile_user_bot_modal">
              <div className="loading_container">
                <LoadingBarAnimation />
              </div>
            </div>
          )}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
