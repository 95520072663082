import CloseIcon from 'assets/svgs/CloseIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import React, { useState } from 'react';
import { apiSendInviteByEmail } from 'shared/api/utils';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import { validateEmail } from 'shared/utils/utils';

import './index.styles.scss';

export const MobileInviteUsingEmail = () => {
  const [newEmail, setNewEmail] = useState('');
  const [isSending, setIsSending] = useState(false);

  const [arrNewEmail, setArrNewEmail] = useState<Array<string>>([]);

  const onSend = async () => {
    if (!isSending && arrNewEmail.length > 0) {
      setIsSending(true);

      const res = await apiSendInviteByEmail({ emails: arrNewEmail });

      if (res.success) {
        setArrNewEmail([]);
        showToast('success', 'Invitations sent successfully!');
      } else {
        showToast('error', 'Error sending invitation. Please retry.');
      }

      setIsSending(false);
    }
  };

  const onAddEmail = () => {
    if (validateEmail(newEmail)) {
      arrNewEmail.push(newEmail);
      setNewEmail('');
    }
  };

  const onRemoveEmail = (email) => {
    setArrNewEmail((prev) => prev.filter((item, index) => item !== email));
  };

  const renderFriendsKnow = () => {
    return (
      <div>
        <div className="font-bold font14">Friends You Might Know</div>
        <div className="friends_list">
          {Array(10)
            .fill(0)
            .map((item, index) => {
              return (
                <div
                  className="friend_card row_space_between"
                  key={`card_friend_${index}`}
                >
                  <DefaultAvatarIcon />
                  <div
                    className="font-regular font14"
                    style={{ color: 'rgba(0, 0, 0, 0.8)' }}
                  >
                    jessica@harvard.ac.uk
                  </div>
                  <div className="clickable">
                    <PlusIcon />
                  </div>
                </div>
              );
            })}
        </div>
        <div
          className="font-regular font12"
          style={{ color: 'rgba(0, 0, 0, 0.5)', marginTop: 12 }}
        >
          People with same org email that already in the waitlist.{' '}
        </div>
      </div>
    );
  };

  const renderAddNewEmail = () => {
    return (
      <div className="add_new_email">
        <div className="font-bold font14">Add New Email</div>
        <div className="new_email_input_container">
          <input
            value={newEmail}
            placeholder="Enter your friend’s email here..."
            className="sub_input font-bold font16"
            onChange={(e) => setNewEmail(e.target.value)}
          />
          <div className="plus_btn clickable" onClick={onAddEmail}>
            <PlusIcon />
          </div>
        </div>
      </div>
    );
  };

  const renderAddedEmail = () => {
    return (
      <div className="added_emails">
        <div className="font-bold font14">Added Emails</div>
        <div className="email_list">
          {arrNewEmail.map((item, index) => {
            return (
              <div
                className="card_added_email row_space_between"
                key={`card_added_email_${index}`}
              >
                <div className="row_align_items">
                  <div className="avatar_container">
                    <DefaultAvatarIcon />
                  </div>
                  <div
                    className="font-regular font14"
                    style={{ marginLeft: 4 }}
                  >
                    {item}
                  </div>
                </div>

                <div
                  className="avatar_container clickable"
                  onClick={() => onRemoveEmail(item)}
                >
                  <CloseIcon opacity={0.3} />
                </div>
              </div>
            );
          })}
          {arrNewEmail.length === 0 && (
            <div
              className="font-regular font12"
              style={{
                color: 'rgba(0, 0, 0, 0.3)',
                textAlign: 'center',
                marginTop: 60
              }}
            >
              Not yet added
            </div>
          )}
        </div>
        <div className="divider"></div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="bottom">
        <div className="desc_container font-bold font12">
          Will earn &nbsp;<div style={{ color: '#FF3E9A' }}>+100 pts</div>
          &nbsp;after they accept the invitation
        </div>
        <div style={{ marginTop: 16 }}>
          <PrimaryButton
            label={`Send Invitation to ${arrNewEmail.length} emails`}
            isLoading={isSending}
            onPressed={onSend}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="invite_using_email">
      {/* {renderFriendsKnow()} */}
      {renderAddNewEmail()}
      {renderAddedEmail()}
      {renderBottom()}
    </div>
  );
};
