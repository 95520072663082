import React, { useEffect, useState } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useDispatch } from 'react-redux';
import { apiEventHosted } from 'shared/api/event';
import { CreateEventModal } from 'components/Modals/CreateEventModal';

import appAction from 'redux/app/action';

import { CardEventHosted } from './CardEventHosted';
import './index.styles.scss';
import { MobileCreateEventModal } from 'components/Modals/CreateEventModal/MobileCreateEventModal';

export const EventHosted = ({ orgId }) => {
  const dispatch = useDispatch();

  const [arrEvent, setArrEvent] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const [isLoaded, setIsLoaded] = useState(false);

  //create event modal
  const [isEventModalShow, setIsEventModalShow] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (init = true) => {
    if (init) {
      const res = await apiEventHosted({
        query: '',
        orgs: null,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrEvent(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setIsLoaded(true);
      }
    } else {
      const res = await apiEventHosted({
        query: '',
        orgs: null,
        limit: 10,
        offset: lastId
      });

      if (res.success) {
        setArrEvent((prev) => [...prev, ...res.data]);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }
    }
  };

  const onLoadMoreData = () => {
    loadData(false);
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const renderBody = () => {
    return (
      <div id="attachScrollableDiv" className="mobile_event_hosted_body">
        <ScrollMenu
          // LeftArrow={LeftArrow}
          // RightArrow={RightArrow}
          onWheel={onWheel}
          onUpdate={onLoadMoreData}
        >
          {arrEvent.map((item, index) => {
            return (
              <CardEventHosted
                key={`card_event_hosted_${index}`}
                data={item}
                onReloadData={() => {
                  loadData();
                  dispatch(appAction.refreshEventData());
                }}
              />
            );
          })}
        </ScrollMenu>
      </div>
    );
  };

  const renderCreateNew = () => {
    return (
      <div
        className="create_event_btn_txt font-bold font12"
        onClick={() => setIsEventModalShow(true)}
      >
        Create New
      </div>
    );
  };

  const renderCreateModal = () => {
    return (
      <MobileCreateEventModal
        show={isEventModalShow}
        onClose={() => setIsEventModalShow(false)}
        onSuccess={() => {
          loadData();
          setIsEventModalShow(false);
          dispatch(appAction.refreshEventData());
        }}
      />
    );
  };

  const renderEmpty = () => {
    return (
      <div className="event_empty_view row_align_items row_space_between">
        <div className="row_align_items">
          <div style={{ fontSize: 40 }}>🗓</div>
          <div style={{ marginLeft: 24 }}>
            <div className="font-bold font16">
              You are not hosting any events yet
            </div>
            <div className="font-regular font14" style={{ opacity: 0.6 }}>
              Click create and host your own event with ease
            </div>
          </div>
        </div>
        <div
          className="create_event_btn font-bold font10"
          onClick={() => setIsEventModalShow(true)}
        >
          Create
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_event_hosted">
      <div
        className="row_space_between row_align_items"
        style={{ padding: '0px 16px' }}
      >
        <div className="font-bold font20">Hosted</div>
        {isLoaded && arrEvent.length !== 0 && renderCreateNew()}
      </div>

      {isLoaded && arrEvent.length === 0 ? renderEmpty() : renderBody()}
      {renderCreateModal()}
    </div>
  );
};
