import React from "react";

function ArrowLeftIcon() {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="14"
          fill="none"
          viewBox="0 0 12 14"
        >
          <path
            fill="#000"
            d="M11 6H3.41l3.3-3.29a1.004 1.004 0 00-1.42-1.42l-5 5a1 1 0 00-.21.33 1 1 0 000 .76 1 1 0 00.21.33l5 5a1 1 0 001.639-.325 1 1 0 00-.22-1.095L3.41 8H11a1 1 0 100-2z"
          ></path>
        </svg>
      );
}

export default ArrowLeftIcon;
