import React from 'react';

function ClockIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '20'}
      height={props.size ?? '20'}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FF3E9A"
        d="M9.167 1.667a8.333 8.333 0 100 16.667 8.333 8.333 0 000-16.667zm0 15a6.667 6.667 0 110-13.334 6.667 6.667 0 010 13.334zM9.167 5a.833.833 0 00-.834.834v3.683l-1.75 1.008A.834.834 0 007 12.084a.833.833 0 00.417-.109l2.166-1.25.075-.075.134-.108a.72.72 0 00.083-.133.745.745 0 00.067-.142.542.542 0 00.041-.167L10 10V5.834A.833.833 0 009.167 5z"
      ></path>
    </svg>
  );
}

export default ClockIcon;
