import React from 'react';

function FullScreenIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '800'}
      height={props.size ?? '800'}
      fill={props.color ?? '#000'}
      stroke="#000"
      strokeWidth="30"
      version="1.1"
      viewBox="0 0 384.97 384.97"
      xmlSpace="preserve"
    >
      <g>
        <path d="M372.939 216.545c-6.123 0-12.03 5.269-12.03 12.03v132.333H24.061V24.061h132.333c6.388 0 12.03-5.642 12.03-12.03S162.409 0 156.394 0H24.061C10.767 0 0 10.767 0 24.061v336.848c0 13.293 10.767 24.061 24.061 24.061h336.848c13.293 0 24.061-10.767 24.061-24.061V228.395c0-6.664-4.885-11.85-12.031-11.85z"></path>
        <path d="M372.939 0H252.636c-6.641 0-12.03 5.39-12.03 12.03s5.39 12.03 12.03 12.03h91.382L99.635 268.432c-4.668 4.668-4.668 12.235 0 16.903 4.668 4.668 12.235 4.668 16.891 0L360.909 40.951v91.382c0 6.641 5.39 12.03 12.03 12.03s12.03-5.39 12.03-12.03V12.03C384.97 5.558 379.412 0 372.939 0z"></path>
      </g>
    </svg>
  );
}

export default FullScreenIcon;
