import PointIcon from 'assets/svgs/PointIcon';
import FilterCheckIcon from 'components/Modals/Mobile/FeedFilterModal/TypeFilter/svgs/FilterCheckIcon';
import React, { useMemo, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import './index.styles.scss';

interface Props {
  orgId: any;
  orgName: any;

  followingStatus: 'claimable' | 'false' | 'claimed';
  eventStatus: 'claimable' | 'false' | 'claimed';
  joinStatus: 'claimable' | 'false' | 'claimed';
  profileStatus: 'claimable' | 'false' | 'claimed';
  postStatus: 'claimable' | 'false' | 'claimed';
  isFollowingFollow: boolean;
  isFollowingEvent: boolean;
  isFollowingJoin: boolean;
  isFollowingProfile: boolean;
  isFollowingPost: boolean;

  totalProgress: any;
  totalPoint: any;
  onClaim: (task) => void;
}

export const WelcomeList = ({
  orgId,
  orgName,
  followingStatus,
  eventStatus,
  joinStatus,
  profileStatus,
  postStatus,
  isFollowingFollow,
  isFollowingEvent,
  isFollowingJoin,
  isFollowingPost,
  isFollowingProfile,

  totalPoint,
  totalProgress,
  onClaim
}: Props) => {
  const navigate = useNavigate();

  const percentage = useMemo(() => {
    return totalProgress * 100;
  }, [totalProgress]);

  const onViewOrgMember = () => {
    navigate(`/orgMember/${orgId}`);
  };

  const onViewEvent = () => {
    navigate(`/event/${orgId}`);
  };

  const onEditProfile = () => {
    navigate(`/editOrgProfile/${orgId}`);
  };

  const onReply = () => {
    navigate(
      `/?filters=type:type%20id:0%20item_type:type,type:creator%20id:-1%20item_type:creator,type:doc%20id:-1%20item_type:doc,type:collective%20id:${orgId}%20item_type:organisation&showDialog=true`
    );
  };

  const renderProgress = () => {
    return (
      <div className="item_container row_align_items row_space_between">
        <div className="row_align_items">
          <div className="font-bold font32">👋</div>
          <div style={{ marginLeft: 16 }}>
            <div className="font-bold font16">Campus Tour Progress</div>
            <div className="row_align_items" style={{ marginTop: 4 }}>
              <div className="font-regular font12" style={{ opacity: 0.4 }}>
                Total Reward
              </div>
              <div className="font-regular font12" style={{ marginLeft: 8 }}>
                <PointIcon />
                <span style={{ marginLeft: 2 }}>{totalPoint}pts</span>
              </div>
            </div>
          </div>
        </div>
        <div className="progress_view font-bold">
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            styles={buildStyles({
              textSize: '24px',
              pathColor: `#ff3e9a`,
              textColor: '#ff3e9a',
              trailColor: 'rgba(0, 0, 0, 0.1)',
              backgroundColor: 'white'
            })}
          />
        </div>
      </div>
    );
  };

  const renderDivider = () => {
    return <div className="item_divider"></div>;
  };

  const renderAccept = () => {
    return (
      <div className="item_container row_align_items row_space_between">
        <div className="row_align_items">
          {renderStatusBtn(joinStatus)}
          <div style={{ marginLeft: 16 }}>
            <div className="font-medium font14">Got accepted and joined</div>
            <div className="row_align_items" style={{ marginTop: 4 }}>
              <div className="font-regular font12" style={{ opacity: 0.4 }}>
                Reward
              </div>
              <div className="font-regular font12" style={{ marginLeft: 8 }}>
                <PointIcon />
                <span style={{ marginLeft: 2 }}>150pts</span>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            joinStatus === 'claimable' && onClaim('joining');
          }}
        >
          {isFollowingJoin ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            renderClaimBtn(joinStatus)
          )}
        </div>
      </div>
    );
  };

  const renderOrgPrfile = () => {
    return (
      <div
        className="item_container row_align_items row_space_between"
        onClick={onEditProfile}
      >
        <div className="row_align_items">
          {renderStatusBtn(profileStatus)}
          <div style={{ marginLeft: 16 }}>
            <div className="font-medium font14">
              Fill your school profile info
            </div>
            <div className="row_align_items" style={{ marginTop: 4 }}>
              <div className="font-regular font12" style={{ opacity: 0.4 }}>
                Reward
              </div>
              <div className="font-regular font12" style={{ marginLeft: 8 }}>
                <PointIcon />
                <span style={{ marginLeft: 2 }}>150pts</span>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            profileStatus === 'claimable' && onClaim('profile');
          }}
        >
          {isFollowingProfile ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            renderClaimBtn(profileStatus)
          )}
        </div>
      </div>
    );
  };

  const renderFollowPerson = () => {
    return (
      <div
        className="item_container row_align_items row_space_between"
        onClick={onViewOrgMember}
      >
        <div className="row_align_items">
          {renderStatusBtn(followingStatus)}
          <div style={{ marginLeft: 16 }}>
            <div className="font-medium font14">
              Follow 10 person in {orgName}
            </div>
            <div className="row_align_items" style={{ marginTop: 4 }}>
              <div className="font-regular font12" style={{ opacity: 0.4 }}>
                Reward
              </div>
              <div className="font-regular font12" style={{ marginLeft: 8 }}>
                <PointIcon />
                <span style={{ marginLeft: 2 }}>150pts</span>
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={(e) => {
            e.stopPropagation();
            followingStatus === 'claimable' && onClaim('following');
          }}
        >
          {isFollowingFollow ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            renderClaimBtn(followingStatus)
          )}
        </div>
      </div>
    );
  };

  const renderReplyOrg = () => {
    return (
      <div className="item_container row_align_items row_space_between">
        <div
          className="row_align_items"
          onClick={(e) => {
            e.stopPropagation();
            onReply();
          }}
        >
          {renderStatusBtn(postStatus)}
          <div style={{ marginLeft: 16 }}>
            <div className="font-medium font14">
              Post or reply 5 posts in {orgName}
            </div>
            <div className="row_align_items" style={{ marginTop: 4 }}>
              <div className="font-regular font12" style={{ opacity: 0.4 }}>
                Reward
              </div>
              <div className="font-regular font12" style={{ marginLeft: 8 }}>
                <PointIcon />
                <span style={{ marginLeft: 2 }}>150pts</span>
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={(e) => {
            e.stopPropagation();
            postStatus === 'claimable' && onClaim('posts');
          }}
        >
          {isFollowingPost ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            renderClaimBtn(postStatus)
          )}
        </div>
      </div>
    );
  };

  const renderAttendEvent = () => {
    return (
      <div
        className="item_container row_align_items row_space_between"
        onClick={() => onViewEvent()}
      >
        <div className="row_align_items">
          {renderStatusBtn(eventStatus)}
          <div style={{ marginLeft: 16 }}>
            <div className="font-medium font14">Attend 3 events</div>
            <div className="row_align_items" style={{ marginTop: 4 }}>
              <div className="font-regular font12" style={{ opacity: 0.4 }}>
                Reward
              </div>
              <div className="font-regular font12" style={{ marginLeft: 8 }}>
                <PointIcon />
                <span style={{ marginLeft: 2 }}>150pts</span>
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={(e) => {
            e.stopPropagation();
            eventStatus === 'claimable' && onClaim('events');
          }}
        >
          {isFollowingEvent ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            renderClaimBtn(eventStatus)
          )}
        </div>
      </div>
    );
  };

  const renderStatusBtn = (status) => {
    return (
      <div>
        {status == 'false' ? (
          <div className="default_mark"></div>
        ) : (
          <FilterCheckIcon />
        )}
      </div>
    );
  };

  const renderClaimBtn = (status) => {
    if (status === 'false') {
      return <div />;
    } else if (status === 'claimable') {
      return (
        <div className="font-bold font12" style={{ color: '#ff3e9a' }}>
          Claim
        </div>
      );
    } else if (status === 'claimed') {
      return (
        <div className="font-bold font12" style={{ opacity: 0.4 }}>
          Claimed
        </div>
      );
    }
  };

  return (
    <div className="mobile_welcome_list">
      {renderProgress()}
      {renderDivider()}
      {renderAccept()}
      {renderOrgPrfile()}
      {renderFollowPerson()}
      {renderReplyOrg()}
      {renderAttendEvent()}
    </div>
  );
};
