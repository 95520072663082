import React, { useEffect, useRef, useState } from 'react';
import { AppRoutes } from 'shared/routes/AppRoutes';
import { useSelector } from 'react-redux';

import { io } from 'socket.io-client';

import { RootState } from 'redux/interfaces';
import { getToken } from 'shared/api/storageHelper';

export let socket: any;
export const setSocket = (sock: any) => {
  socket = sock;
};

function Auth() {
  const timerRef = useRef<any>();

  const { userInfo } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    if (userInfo && userInfo.id) {
      connectSocket(userInfo.id);
    }

    return () => {
      socket?.off('connect');
      socket?.off('disconnect');
      socket?.off('new-chat-message-ai');
    };
  }, [userInfo]);

  const connectSocket = async (userId) => {
    const token = await getToken();
    const sock = io(process.env.REACT_APP_SERVER_URL ?? '', {
      query: {
        token: token
      }
    });

    sock.on('connect', () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      console.log('--- connected sokect ---');
      sock.emit('add user', userId);
      sock.connect();

      sock.emit(
        'join-room',
        `chat-personal-ai-${userInfo?.id}-${userInfo?.id}`
      );

      sock.emit('join-room', `${userInfo?.id}`);

      setSocket(sock);
    });

    return socket;
  };

  return <AppRoutes sock={socket} />;
}
export default Auth;
