import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { MobileCardTrendingQuestion } from './CardTrendingQuestion';

import './index.styles.scss';
import { MobileNewShareAnswerModal } from 'components/Modals/MobileShare/ShareAnswer/NewShareModal';

interface Props {
  userId: any;
  data: Array<any>;
  isLoadedAll: boolean;
  onLoaded: () => void;
}

export const MobileProfileQuestion = ({
  userId,
  data,
  isLoadedAll,
  onLoaded
}: Props) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [showShare, setShowShare] = useState(false);
  const [selectedQuestionData, setSelectedQuestionData] = useState<any>(null);
  const [isOwner, setIsOwner] = useState(false);

  const renderDivider = () => {
    return <div className="profile_section_divider"></div>;
  };

  const renderShareQuestionToSocial = () => {
    return (
      <MobileNewShareAnswerModal
        show={showShare}
        onClose={() => {
          setShowShare(false);
        }}
        question={selectedQuestionData}
      />
    );
  };

  if (isLoadedAll) {
    return (
      <div className="mobile_user_activity_ai_list">
        {renderDivider()}
        {data.length > 0 && (
          <InfiniteScroll
            dataLength={data.length} //This is important field to render the next data
            next={() => {}}
            hasMore={false}
            loader={
              <div style={{ display: 'flex', margin: 'auto' }}>
                <LoadingCircular size={20} color="main" />
              </div>
            }
            refreshFunction={() => {}}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            style={{ marginTop: 16 }}
          >
            {data.map((item, index) => {
              return (
                <MobileCardTrendingQuestion
                  key={`mobile_card_user_activity_ai_${index}`}
                  data={item}
                  type={'creator'}
                  hideBookMark={true}
                  onShareMedia={(data, modalType) => {
                    setSelectedQuestionData(data);
                    setShowShare(true);
                  }}
                />
              );
            })}
          </InfiniteScroll>
        )}
        {isLoadedAll && data.length == 0 && (
          <div className="empty_user_activity_ai">
            <div
              className="font-bold font56"
              style={{ textAlign: 'center', marginTop: 24 }}
            >
              🏃‍♀️
            </div>
            <div
              className="font-bold font18"
              style={{ textAlign: 'center', marginTop: 16 }}
            >
              {isOwner ? 'No activities yet' : 'No activities found'}
            </div>
            <div
              className="font-regular font14"
              style={{
                textAlign: 'center',
                marginTop: 8,
                paddingBottom: 24
              }}
            >
              {isOwner
                ? 'Post a question, add an answer, or upvotes other’s answer!'
                : 'This user hasn’t posted any questions, added answers, or upvoted others’ answers yet. Check back later to see their contributions!'}
            </div>
          </div>
        )}
        {selectedQuestionData && renderShareQuestionToSocial()}
      </div>
    );
  } else {
    return <div />;
  }
};
