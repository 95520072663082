import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import SearchIcon from 'assets/svgs/SearchIcon';

import './index.styles.scss';
import CloseIcon from 'assets/svgs/CloseIcon';

interface QuestionAskBarProps {
  onSearch: (searchText: string) => void;
  onRealTimeSearch?: (searchText: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClose?: () => void;
  width?: string;
  height?: string;
  backgroundColor?: string;
  placeholder?: string;
  fontSize?: number;
  searchIconClickable?: boolean;
  type?: string;
  showButton?: boolean;
  showCloseButton?: boolean;
}

export const QuestionAskBar = ({
  onSearch,
  onRealTimeSearch,
  onFocus,
  onBlur,
  onClose,
  width,
  height,
  backgroundColor,
  placeholder,
  fontSize,
  searchIconClickable,
  type,
  showButton = true,
  showCloseButton = false
}: QuestionAskBarProps) => {
  const [keyword, setKeyword] = useState('');
  const [debouncedKeyword] = useDebounce(keyword, 100);

  const onChange = (e) => {
    if (type === 'joinNewOrg') onSearch(e);
  };
  const onEnter = (e) => {
    if (keyword !== '') {
      if (e.keyCode === 13) {
        e.preventDefault();
        onSearch(debouncedKeyword);
      }
    }
  };

  useEffect(() => {
    if (!searchIconClickable) {
      onSearch(debouncedKeyword);
    }
  }, [debouncedKeyword]);

  const renderFindButton = () => {
    return (
      <div
        className="find_asnwer_btn clickable"
        style={
          keyword === ''
            ? {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                borderRadius: 48,
                border: '1px solid rgba(0, 0, 0, 0.1)'
              }
            : {
                backgroundColor: '#FF3E9A'
              }
        }
        onClick={() => {
          onSearch(debouncedKeyword);
        }}
      >
        <SearchIcon color={'white'} />
        <div
          className="font-bold font16"
          style={{ color: 'white', marginLeft: 8 }}
        >
          Find Answers
        </div>
      </div>
    );
  };

  const renderCloseIcon = () => {
    return (
      <div
        onClick={() => {
          setKeyword('');
          onClose && onClose();
        }}
      >
        <CloseIcon />
      </div>
    );
  };

  return (
    <div
      className="question_ask_box"
      style={{
        width: width ?? '',
        height: height ?? 46,
        backgroundColor: backgroundColor ?? 'white'
      }}
    >
      <div>
        <SearchIcon />
      </div>
      <input
        value={keyword}
        placeholder={placeholder ?? 'Search here....'}
        className={`question_ask_box_input font-bold font${fontSize ?? 24}`}
        onFocus={onFocus}
        onBlur={onBlur}
        style={{
          backgroundColor: backgroundColor ?? 'white',
          width: '100%'
        }}
        onChange={(e) => {
          if (e.nativeEvent['inputType'] === 'insertLineBreak') {
            e.preventDefault();
            return;
          } else {
            setKeyword(e.target.value);
            onChange(e.target.value);
            onRealTimeSearch && onRealTimeSearch(e.target.value);
          }
        }}
        onKeyUp={(e) => {
          e.preventDefault();
          onEnter(e);
        }}
      />
      {showButton && renderFindButton()}
      {showCloseButton && renderCloseIcon()}
    </div>
  );
};
