import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import FolderOpenIcon from 'assets/svgs/FolderOpenIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { apiGetChatPersonalDocs } from 'shared/api/chat';
import { MobileCardpersonalAiFile } from './CardPersonalAiFile';
import BG_EMPTY_CREATOR from 'assets/pngs/bg_empty_chat_creator.png';

import './index.styles.scss';
import { socket } from 'components/Auth';
import LinkAddIcon from 'assets/svgs/LinkAddIcon';
import MicIcon from 'assets/svgs/MicIcon';
import { MobileAddNewAudio } from 'components/Modals/MobileAddFile/AddNewAudio';
import { MobileAddNewLinkModal } from 'components/Modals/MobileAddFile/AddNewLink';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';

export const MobileMyPersonalAiFiles = () => {
  const navigate = useNavigate();
  const params = useParams();
  const aiId = params.id;
  const type = params.type;

  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [showAddLinkModal, setShowAddLinkModal] = useState(false);
  const [showAddAudioModal, setShowAddAudioModal] = useState(false);

  const [arrDoc, setArrDoc] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const onClickAddLink = () => {
    setShowAddLinkModal(true);
  };

  const onClickAddAudio = () => {
    setShowAddAudioModal(true);
  };

  useEffect(() => {
    if (userInfo && !!aiId && !!type) {
      loadData();
    }
  }, [userInfo, aiId, type]);

  useEffect(() => {
    if (socket) {
      const newMessageHandler = (data) => {
        onUpdateDumpStatus(parseInt(data.dump), data.status);
      };

      socket.on('dump-scraping', newMessageHandler);
      return () => {
        socket.removeListener('dump-scraping', newMessageHandler);
      };
    }
  }, [socket]);

  const loadData = async (init = true) => {
    if (init) {
      setIsLoading(true);
      const res = await apiGetChatPersonalDocs({
        id: aiId,
        type: parseInt(aiId ?? '0') === userInfo?.id ? 'user' : 'personal',
        query: '',
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrDoc(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setIsLoaded(true);
      }
      setIsLoading(false);
    } else {
      if (hasMore && !isLoading) {
        setIsLoading(true);
        const res = await apiGetChatPersonalDocs({
          id: aiId,
          type: parseInt(aiId ?? '0') === userInfo?.id ? 'user' : 'personal',
          query: '',
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrDoc((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }
        setIsLoading(false);
      }
    }
  };

  const onUpdateDumpStatus = (dumpId, status) => {
    setArrDoc((prev) =>
      prev.map((item, index) => {
        if (item.id == dumpId) {
          return {
            ...item,
            status: status
          };
        } else {
          return item;
        }
      })
    );
  };

  const onRemoveDumpOnUI = (dumpId) => {
    setArrDoc((prev) => {
      return prev.filter((item, index) => item.id !== dumpId);
    });
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      loadData(false);
    }
  };

  const renderHeader = () => {
    return (
      <div className="ai_file_header row_align_items row_space_between">
        <div className="row_align_items">
          <div
            onClick={() => {
              navigate(-1);
            }}
          >
            <BlackBackIcon />
          </div>
          <div style={{ marginLeft: 12 }}>
            <FolderOpenIcon size={32} />
          </div>
          <div style={{ marginLeft: 8 }}>
            <div className="ai_file_my_txt font-bold font12">
              My Personal AI
            </div>
            <div className="font-bold font16">Uploaded Content</div>
          </div>
        </div>
        {renderActions()}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="ai_file_body" onScroll={handleScroll}>
        {isLoaded && arrDoc.length === 0
          ? renderEmptyCreators()
          : arrDoc.map((item, index) => {
              return (
                <MobileCardpersonalAiFile
                  data={item}
                  key={`mobile_card_personal_ai_file_${index}`}
                  onRemoveOnUI={onRemoveDumpOnUI}
                />
              );
            })}
      </div>
    );
  };

  const renderEmptyCreators = () => {
    return (
      <div className="empty_creator">
        <img
          src={BG_EMPTY_CREATOR}
          style={{ width: 300, height: 230, marginBottom: 30 }}
        />
        <div className="font-bold font16">{'Empower your AI!'}</div>
        <div
          className="font-regular font12"
          style={{
            color: 'rgba(0, 0, 0, 0.4)',
            textAlign: 'center',
            paddingBottom: 16
          }}
        >
          {
            'Upload documents, links, and audios to augment the AI’s capabilities and knowledge base.'
          }
        </div>
      </div>
    );
  };

  const renderActions = () => {
    return (
      <div className="row_align_items" style={{ gap: 4 }}>
        <div className="ai_action_btn" onClick={onClickAddLink}>
          <LinkAddIcon />
        </div>
        <div className="ai_action_btn" onClick={onClickAddAudio}>
          <MicIcon color="#FF3E9A" />
        </div>
      </div>
    );
  };

  const renderAddLinkModal = () => {
    return (
      <MobileAddNewLinkModal
        aiId={type === 'first' ? -1 : aiId}
        open={showAddLinkModal}
        onClose={() => {
          setShowAddLinkModal(false);
        }}
        onSuccess={(dumpId, link) => {
          loadData(true);
        }}
        onViewFile={(aiId) => {
          setShowAddLinkModal(false);
        }}
      />
    );
  };

  const renderAddAudioModal = () => {
    return (
      <MobileAddNewAudio
        aiId={type === 'first' ? -1 : aiId}
        open={showAddAudioModal}
        onClose={() => {
          setShowAddAudioModal(false);
        }}
        onSuccess={(dumpId, link) => {
          loadData(true);
        }}
        onViewFile={(aiId) => {
          setShowAddAudioModal(false);
        }}
      />
    );
  };

  return (
    <div className="personal_ai_files">
      {renderHeader()}
      {renderBody()}
      {renderAddAudioModal()}
      {renderAddLinkModal()}
    </div>
  );
};
