import ArrowPlayIcon from 'assets/svgs/ArrowPlayIcon';
import AudioPauseIcon from 'assets/svgs/AudioPauseIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import FilterCheckIcon from 'components/Modals/Mobile/FeedFilterModal/TypeFilter/svgs/FilterCheckIcon';
import React, { useEffect, useMemo, useState } from 'react';
import ReactHowler from 'react-howler';
import { useAudioContext } from 'shared/contexts/AudioContext';

import './index.styles.scss';

interface Props {
  isSelected: boolean;
  data: any;
  onPressed: (item) => void;
}

export const MobileCardChooseVoice = ({
  isSelected,
  data,
  onPressed
}: Props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const { playingAudio, setPlayingAudio } = useAudioContext();

  const audio = useMemo(() => {
    return data.description_audio;
  }, [data.description_audio]);

  useEffect(() => {
    if (!!audio && (playingAudio ?? '') === audio) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [playingAudio]);

  const togglePlayback = () => {
    if (isPlaying) {
      setPlayingAudio(null);
    } else {
      if (playingAudio) {
        setPlayingAudio(null);
      }
      setPlayingAudio(audio);
    }
  };

  const renderBotIcon = () => {
    return (
      <div className="bot_icon">
        <RobotIcon size={16} color={'white'} />
      </div>
    );
  };

  const renderAudioIcon = () => {
    if (!!audio) {
      return (
        <div className="audio_play_btn" onClick={togglePlayback}>
          {isPlaying ? (
            <AudioPauseIcon size={16} color={'black'} />
          ) : (
            <ArrowPlayIcon />
          )}
          {isPlaying && (
            <ReactHowler
              src={audio}
              playing={isPlaying}
              loop={false}
              html5={true} // Use HTML5 Audio API
              onLoad={() => {}}
              onEnd={() => setPlayingAudio(null)}
            />
          )}
        </div>
      );
    } else {
      return <div />;
    }
  };

  return (
    <div
      className="mobile_card_choose_voice row_align_items"
      onClick={() => {
        onPressed(data);
      }}
    >
      <div className="image_container align-center">
        {data.image && data.image.startsWith('https') ? (
          <img src={data.image} className="bot_image" />
        ) : (
          <DefaultAvatarIcon size={50} />
        )}
        {renderBotIcon()}
      </div>

      <div className="main_info">
        <div className="font-bold font16">{data.name}</div>
        <div className="bot_description font-regular font12">
          {data.description}
        </div>
      </div>
      <div className="align-center">{renderAudioIcon()}</div>
      <div className="action_btn align-center">
        {isSelected ? (
          <FilterCheckIcon size={32} />
        ) : (
          <div className="default_mark"></div>
        )}
      </div>
    </div>
  );
};
