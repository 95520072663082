import React from 'react';

function UserFollowingIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill={props.color ?? '#000'}
        d="M8.867 8.647A3.28 3.28 0 0010 6.167a3.333 3.333 0 00-6.667 0 3.28 3.28 0 001.134 2.48A5.334 5.334 0 001.333 13.5a.667.667 0 101.334 0 4 4 0 118 0 .667.667 0 001.333 0 5.333 5.333 0 00-3.133-4.853zm-2.2-.48a2 2 0 110-4 2 2 0 010 4zm7.806-1.58a.667.667 0 00-.946 0L12.193 7.92l-.413-.42a.667.667 0 10-.947.94l.894.893a.667.667 0 00.94 0l1.78-1.78a.664.664 0 00.026-.966z"
        opacity={props.opacity ?? '0.4'}
      ></path>
    </svg>
  );
}

export default UserFollowingIcon;
