import AngleRightIcon from 'assets/svgs/AngleRightIcon';
import CloseIcon from 'assets/svgs/CloseIcon';
import FolderOpenIcon from 'assets/svgs/FolderOpenIcon';
import MicIcon from 'assets/svgs/MicIcon';
import React from 'react';
import Sheet from 'react-modal-sheet';

import './index.styles.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  onSelected: (item: 'file' | 'audio') => void;
}

export const MobilePromptUploadFileModal = ({
  open,
  onClose,
  onSelected
}: Props) => {
  const renderHeader = () => {
    return (
      <div className="header_view row_align_items">
        <div className="" onClick={onClose}>
          <CloseIcon color="rgba(0, 0, 0, 0.2)" />
        </div>
        <div
          className="font-bold font18"
          style={{ textAlign: 'center', flex: 1 }}
        >
          Upload
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="action_body">
        <div
          className="action_item row_align_items row_space_between"
          onClick={() => {
            onSelected('file');
          }}
        >
          <div className="row_align_items" style={{ gap: 8 }}>
            <FolderOpenIcon />
            <div className="font-regular font16">My Files</div>
          </div>
          <div>
            <AngleRightIcon size={16} color="#FF3E9A" opacity={1} />
          </div>
        </div>
        <div
          className="action_item row_align_items row_space_between"
          onClick={() => {
            onSelected('audio');
          }}
        >
          <div className="row_align_items" style={{ gap: 8 }}>
            <MicIcon color="#FF3E9A" />
            <div className="font-regular font16">Audio</div>
          </div>
          <div>
            <AngleRightIcon size={16} color="#FF3E9A" opacity={1} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Sheet isOpen={open} onClose={onClose} detent={'content-height'}>
      <Sheet.Container
        style={{ borderTopLeftRadius: 32, borderTopRightRadius: 32 }}
      >
        <Sheet.Content>
          <div className="mobile_prompt_upload_modal">
            <div
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                height: '4px',
                width: '56px',
                borderRadius: 4
              }}
            />
            {renderHeader()}
            <div className="divider"></div>
            {renderBody()}
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
