import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import SearchIcon from 'assets/svgs/SearchIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import VerticalDetailIcon from 'assets/svgs/VerticalDetailIcon';
import React from 'react';

import './index.styles.scss';

interface Props {
  roomInfo: any;
  isSharing: boolean;
  isEndedRoom: boolean;
  onBack: () => void;
  onSearch: () => void;
  onShare: () => void;
  onMoreAction: () => void;
}

export const MobileIndividualConversationHeader = ({
  isSharing,
  isEndedRoom,
  roomInfo,
  onBack,
  onSearch,
  onShare,
  onMoreAction
}: Props) => {
  const renderRoomInfo = () => {
    if (roomInfo) {
      return (
        <div className="room_info row_align_items">
          {roomInfo?.ai_image && roomInfo?.ai_image.startsWith('https') ? (
            <img
              src={roomInfo?.ai_image}
              style={{ width: 32, height: 32, borderRadius: 32 }}
            />
          ) : (
            <DefaultAvatarIcon size={32} />
          )}
          <div className="font-bold font18">{`${roomInfo?.ai_name} AI`}</div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  return (
    <div className="mobile_individual_chat_header row_space_between">
      <div className="row_align_items" style={{ flex: 1, gap: 8 }}>
        <div onClick={onBack}>
          <AngleLeftIcon color="black" size={24} />
        </div>
        {!isSharing ? (
          renderRoomInfo()
        ) : (
          <div className="share_title font-bold font16">
            Share from selection
          </div>
        )}
      </div>
      {!isSharing && (
        <div className="row_align_items" style={{ gap: 12 }}>
          <div
            className="align-center"
            onClick={() => {
              onSearch();
            }}
          >
            <SearchIcon color={'black'} />
          </div>
          <div
            className="align-center"
            onClick={() => {
              onShare();
            }}
          >
            <ShareIcon />
          </div>
          {!isEndedRoom && (
            <div
              className="align-center"
              onClick={() => {
                onMoreAction();
              }}
            >
              <VerticalDetailIcon color={'black'} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
