import React from 'react';

function TrashIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '20'}
      height={props.size ?? '20'}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={props.color ?? '#FF3E9A'}
        d="M16.667 5h-3.334v-.833a2.5 2.5 0 00-2.5-2.5H9.167a2.5 2.5 0 00-2.5 2.5V5H3.333a.833.833 0 000 1.667h.834v9.166a2.5 2.5 0 002.5 2.5h6.666a2.5 2.5 0 002.5-2.5V6.667h.834a.833.833 0 000-1.667zm-8.334-.833a.833.833 0 01.834-.834h1.666a.833.833 0 01.834.834V5H8.333v-.833zm5.834 11.666a.833.833 0 01-.834.834H6.667a.833.833 0 01-.834-.834V6.667h8.334v9.166z"
      ></path>
    </svg>
  );
}

export default TrashIcon;
