import RobotIcon from 'assets/svgs/RobotIcon';
import React, { useMemo, useState } from 'react';
import moment from 'moment';

import './index.styles.scss';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import TrashIcon from 'assets/svgs/TrashIcon';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { apiDeletionRoom } from 'shared/api/delete';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';

export interface ChatRoomData {
  id?: number;
  name: string;
  last_interacted: string;
  ai_id: number;
  ai_name: string;
  ai_image: string;
  ai_type: string;
  is_voted: number;
  user: number;
}
interface CardChatHistoryProps {
  selectedRoom?: ChatRoomData;
  data: ChatRoomData;
  onSelected: (data: ChatRoomData) => void;
  onRemoveFromUI: (roomId) => void;
}

export const CardChatHistory = ({
  data,
  selectedRoom,
  onSelected,
  onRemoveFromUI
}: CardChatHistoryProps) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = async (e) => {
    e.stopPropagation();
    if (!isDeleting) {
      setIsDeleting(true);
      const res = await apiDeletionRoom({ room: data.id });

      if (res.success) {
        showToast('success', 'Chat deleted successfully!');

        onRemoveFromUI(data.id);
      }

      setIsDeleting(false);
    }
  };

  const isPersonalChat = useMemo(() => {
    return data.ai_type === 'user' && data.user === userInfo?.id;
  }, [data]);

  const renderImage = () => {
    if (data.ai_type === 'general') {
      return (
        <div className="mobile_card_chat_history_left_side_header_image">
          <RobotIcon />
        </div>
      );
    } else {
      return (
        <div className="mobile_card_chat_history_left_side_header_image">
          {data.ai_image ? (
            <img
              src={data.ai_image}
              className="mobile_card_chat_history_left_side_header_image"
            />
          ) : (
            <DefaultAvatarIcon />
          )}
        </div>
      );
    }
  };

  const renderName = () => {
    if (data.ai_type === 'general') {
      return (
        <div className="mobile_card_chat_history_left_side_header_group font-bold font14">
          {`General AI`}
        </div>
      );
    } else if (isPersonalChat) {
      return (
        <div className="mobile_card_chat_history_left_side_header_group font-bold font14">
          {`Personal AI`}
        </div>
      );
    } else {
      return (
        <div className="mobile_card_chat_history_left_side_header_group font-bold font14">
          {`${data.ai_name} AI`}
        </div>
      );
    }
  };

  const renderDelete = () => {
    return (
      <div className="trash_btn" onClick={onDelete}>
        {isDeleting ? (
          <LoadingCircular size={16} color={'main'} />
        ) : (
          <TrashIcon />
        )}
      </div>
    );
  };

  return (
    <div
      className="mobile_card_chat_history clickable row_align_items"
      style={
        data.id !== selectedRoom?.id
          ? { backgroundColor: '#ffffff' }
          : {
              backgroundColor: '#f3f4f5'
            }
      }
      onClick={() => {
        onSelected(data);
      }}
    >
      <div className="mobile_card_chat_history_left_side">
        <div className="mobile_card_chat_history_left_side_name font-medium font20">
          {data.name ?? `No Name`}
        </div>
        <div className="row_align_items">
          {renderImage()}
          <div>
            {renderName()}
            <div className="mobile_card_chat_history_left_side_date font-regular font12">
              {`Last interacted at ${moment(data.last_interacted).format(
                'DD MMM'
              )}`}
            </div>
          </div>
        </div>
      </div>
      {renderDelete()}
    </div>
  );
};
