import React from 'react';

function DownloadIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={props.color ?? '#08B883'}
        d="M6.908 11.075a.834.834 0 000 1.183l2.5 2.5a.833.833 0 001.184 0l2.5-2.5a.837.837 0 00-1.184-1.183l-1.075 1.083V2.5a.834.834 0 00-1.666 0v9.658l-1.075-1.083a.833.833 0 00-1.184 0zM15 7.5h-1.667a.833.833 0 000 1.666H15a.833.833 0 01.833.834v5.833a.834.834 0 01-.833.833H5a.833.833 0 01-.833-.833V10A.833.833 0 015 9.166h1.667a.833.833 0 100-1.666H5A2.5 2.5 0 002.5 10v5.833a2.5 2.5 0 002.5 2.5h10a2.5 2.5 0 002.5-2.5V10A2.5 2.5 0 0015 7.5z"
      ></path>
    </svg>
  );
}

export default DownloadIcon;
