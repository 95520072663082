import React, { useEffect, useState } from 'react';
import { apiGetSuggestedUser } from 'shared/api/user';
import { MobileCardSearchedUser } from './CardSearchedUser';
import BG_EMPTY_CREATOR from 'assets/pngs/bg_empty_chat_creator.png';

import './index.styles.scss';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';

interface Props {
  query: string;
}

export const MobileCommunitySearchedUser = ({ query }: Props) => {
  const [arrUser, setArrUser] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingFirst, setIsLoadingFirlst] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, [query]);

  const loadData = async (init = true) => {
    if (init) {
      setIsLoading(true);
      setIsLoadingFirlst(true);

      const res = await apiGetSuggestedUser({
        query: query,
        limit: 15,
        offset: 0
      });

      if (res.success) {
        setArrUser(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }

      setIsLoading(false);
      setIsLoadingFirlst(false);
    } else {
      if (hasMore && !isLoading) {
        setIsLoading(true);

        const res = await apiGetSuggestedUser({
          query: query,
          limit: 15,
          offset: lastId
        });

        if (res.success) {
          setArrUser((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoading(false);
      }
    }
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      loadData(false);
    }
  };

  const renderUsers = () => {
    return arrUser.map((item, index) => {
      return (
        <MobileCardSearchedUser key={`searched_user_${index}`} data={item} />
      );
    });
  };

  const renderEmptyCreators = () => {
    return (
      <div className="empty_creator">
        <img
          src={BG_EMPTY_CREATOR}
          style={{ width: 300, height: 230, marginBottom: 30 }}
        />
        <div className="font-bold font16">{'No Results Found'}</div>
        <div
          className="font-regular font12"
          style={{
            color: 'rgba(0, 0, 0, 0.4)',
            textAlign: 'center',
            paddingBottom: 16
          }}
        >
          {'Oops! We couldn’t find any users matching your search query.'}
        </div>
      </div>
    );
  };

  const renderLoadingBar = () => {
    return (
      <div className="loading_bar">
        <LoadingBarAnimation />
      </div>
    );
  };

  return (
    <div className="mobile_community_search_users" onScroll={handleScroll}>
      {isLoadingFirst
        ? renderLoadingBar()
        : arrUser.length === 0
        ? renderEmptyCreators()
        : renderUsers()}
    </div>
  );
};
