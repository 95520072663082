import React from 'react';

function CoinIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '20'}
      height={props.size ?? '20'}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FF3E9A"
        d="M17.683 10a2.5 2.5 0 00.65-1.667 2.5 2.5 0 00-2.5-2.5h-4.316A2.5 2.5 0 009.167 2.5h-5a2.5 2.5 0 00-2.5 2.5 2.5 2.5 0 00.65 1.667 2.5 2.5 0 000 3.333 2.5 2.5 0 000 3.333A2.5 2.5 0 001.667 15a2.5 2.5 0 002.5 2.5h11.666a2.5 2.5 0 001.85-4.167 2.5 2.5 0 000-3.333zm-8.516 5.833h-5a.834.834 0 010-1.666h5a.834.834 0 010 1.666zm0-3.333h-5a.833.833 0 110-1.667h5a.833.833 0 110 1.667zm0-3.333h-5a.833.833 0 110-1.667h5a.833.833 0 010 1.667zm0-3.334h-5a.833.833 0 110-1.666h5a.833.833 0 110 1.666zm7.241 9.759a.775.775 0 01-.575.241h-4.316c.2-.537.2-1.129 0-1.666h4.316a.833.833 0 01.834.833.834.834 0 01-.259.592zm0-3.334a.775.775 0 01-.575.242h-4.316c.2-.538.2-1.13 0-1.667h4.316a.833.833 0 01.834.834.833.833 0 01-.259.591zm0-3.333a.775.775 0 01-.575.242h-4.316c.2-.538.2-1.13 0-1.667h4.316a.833.833 0 01.834.833.834.834 0 01-.259.592z"
      ></path>
    </svg>
  );
}

export default CoinIcon;
