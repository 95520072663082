import { ConnectSocialModal } from 'components/Modals/ConnectSocialModal';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiGetUserQuestions } from 'shared/api/user';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { MobileProfilePersonalAi } from './components/PersonalAI';
import { MobileProfileQuestion } from './components/Question';
import { MobileProfileSocialMedia } from './components/SocialMedia';
import { MobileProfileTopOrg } from './components/TopOrg';
import { MobileProfileUserInfo } from './components/UserInfo';
import { MobileWelcomeOrg } from './components/WelcomeOrg';

import './index.styles.scss';

export const MobileOwnProfile = () => {
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [selectedOrg, setSelectedOrg] = useState(null);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const [isConnectSocial, setIsConnectSocial] = useState(false);
  const [isLoadedAll, setIsLoadedAll] = useState(false);
  const [isLoadedUserInfo, setIsLoadedUserInfo] = useState(false);
  const [isLoadedOrg, setIsLoadedOrg] = useState(false);
  const [isLoadedQuestion, setIsLoadedQuestion] = useState(false);
  const [isLoadedAi, setIsLoadedAi] = useState(false);

  const [arrUserActivityAI, setArrUserActivityAI] = useState<Array<any>>([]);
  const [lastIdUserActivityAI, setLastIdUserActivityAI] = useState(0);
  const [hasMoreUserActivityAI, setHasMoreUserActivityAI] = useState(true);
  const [isLoadingUserActivityAI, setIsLoadingUserActivityAI] = useState(false);

  useEffect(() => {
    if (isLoadedOrg && isLoadedQuestion && isLoadedAi && isLoadedUserInfo) {
      setIsLoadedAll(true);
    }
  }, [isLoadedOrg, isLoadedQuestion, isLoadedAi, isLoadedUserInfo]);

  useEffect(() => {
    if (selectedOrg) {
      setIsLoadedAi(false);
      setIsLoadedQuestion(false);
      setIsLoadedUserInfo(false);
      setIsLoadedAll(false);
    }
  }, [selectedOrg]);

  useEffect(() => {
    if (userInfo && !selectedOrg) {
      loadUserActivityAIData();
    }
  }, [userInfo, selectedOrg]);

  const loadUserActivityAIData = async (init = true) => {
    if (init) {
      setIsLoadingUserActivityAI(true);
      const res = await apiGetUserQuestions({
        id: userInfo?.id,
        offset: 0,
        limit: 10
      });

      if (res.success) {
        setArrUserActivityAI(res.data);
        setLastIdUserActivityAI(res.lastId);
        setHasMoreUserActivityAI(res.hasMore);
        setIsLoadedQuestion(true);
      }
      setIsLoadingUserActivityAI(false);
    } else {
      if (hasMoreUserActivityAI && !isLoadingUserActivityAI) {
        setIsLoadingUserActivityAI(true);
        const res = await apiGetUserQuestions({
          id: userInfo?.id,
          offset: lastIdUserActivityAI,
          limit: 10
        });
        if (res.success) {
          setArrUserActivityAI((prev) => [...prev, ...res.data]);
          setLastIdUserActivityAI(res.lastId);
          setHasMoreUserActivityAI(res.hasMore);
        }
        setIsLoadingUserActivityAI(false);
      }
    }
  };

  const handleScroll = (e) => {
    // hide nav bar
    const scrollTop = e.target.scrollTop;
    setLastScrollTop(scrollTop);

    if (scrollTop < lastScrollTop) {
      document
        .getElementById('mobile_body')
        ?.setAttribute('class', 'mobile_body normal_mobile_body');
      document
        .getElementById('mobile_tab')
        ?.setAttribute('class', 'mobile_tab active_nav');
    } else {
      document
        .getElementById('mobile_body')
        ?.setAttribute('class', 'mobile_body expanded_mobile_body');
      document
        .getElementById('mobile_tab')
        ?.setAttribute('class', 'mobile_tab hidden_nav');
    }

    // load more questions
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;

    if (bottom) {
      loadUserActivityAIData(false);
    }
  };

  const renderConnectSocialModal = () => {
    return (
      <ConnectSocialModal
        show={isConnectSocial}
        onClose={() => {
          setIsConnectSocial(false);
        }}
        onSuccess={() => {
          setIsConnectSocial(false);
        }}
      />
    );
  };

  const renderBody = () => {
    return (
      <div className="profile_body">
        <MobileProfileTopOrg
          selectedOrg={selectedOrg}
          userId={userInfo?.id}
          isLoadedAll={selectedOrg ? isLoadedOrg : isLoadedAll}
          onLoaded={() => setIsLoadedOrg(true)}
          onSelected={(orgId) => {
            if (orgId === selectedOrg) {
              setSelectedOrg(null);
            } else {
              setSelectedOrg(orgId);
            }
          }}
        />
        {!selectedOrg ? (
          <div>
            <MobileProfileUserInfo
              userId={userInfo?.id}
              isLoadedAll={isLoadedAll}
              onLoaded={() => setIsLoadedUserInfo(true)}
            />
            <MobileProfilePersonalAi
              userId={userInfo?.id}
              isLoadedAll={isLoadedAll}
              onLoaded={() => setIsLoadedAi(true)}
            />
            {/* <MobileProfileJoinedGroup
              userId={userInfo?.id}
              isLoadedAll={isLoadedAll}
              onLoaded={() => setIsLoadedGroup(true)}
            /> */}
            <MobileProfileSocialMedia
              userId={userInfo?.id}
              isLoadedAll={isLoadedAll}
              onConnect={() => {
                setIsConnectSocial(true);
              }}
            />
            <MobileProfileQuestion
              userId={userInfo?.id}
              data={arrUserActivityAI}
              isLoadedAll={isLoadedAll}
              onLoaded={() => setIsLoadedQuestion(true)}
            />
          </div>
        ) : (
          <MobileWelcomeOrg orgId={selectedOrg} />
        )}
      </div>
    );
  };

  const renderLoadingBar = () => {
    return (
      <div style={{ flex: 1 }}>
        <LoadingBarAnimation />
      </div>
    );
  };

  return (
    <div className="mobile_profile" onScroll={handleScroll}>
      {renderBody()}
      {!isLoadedAll && !selectedOrg && renderLoadingBar()}
      {renderConnectSocialModal()}
    </div>
  );
};
