import HistoryIcon from 'assets/svgs/HistoryIcon';
import React, { useState } from 'react';
import { MobileStartNewChatBody } from './Body';

import './index.styles.scss';

interface Props {
  onViewAll: () => void;
}

export const MobileStartNewChat = ({ onViewAll }: Props) => {
  const [isEmpty, setIsEmpty] = useState(true);

  const renderHeader = () => {
    return (
      <div className="qa_header row_space_between">
        <div className="row_align_items">
          <div className="font-bold font18">Start a New Chat</div>
        </div>
        <div className="row_align_items">
          <div className="conversation_history_btn" onClick={onViewAll}>
            <HistoryIcon color="#FF3E9A" size={22} />
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return <MobileStartNewChatBody onEmpty={setIsEmpty} />;
  };

  return (
    <div className="mobile_start_new_chat">
      {!isEmpty && renderHeader()}
      {renderBody()}
    </div>
  );
};
