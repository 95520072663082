import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { apiGetUserOrganisations } from 'shared/api/user';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import { CarOrg } from './CardOrg';
import './index.styles.scss';
import InfiniteScrollWrapper from './Wrapper';

interface EventSelectOrgModalProps {
  show: boolean;
  enableDefault: boolean;
  onClose: () => void;
  onSelectOrg: (org) => void;
}

export const EventSelectOrgModal = ({
  show,
  enableDefault,
  onClose,
  onSelectOrg
}: EventSelectOrgModalProps) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [organisations, setOrganisations] = useState<Array<any>>([]);
  const [hasOrgMore, setHasOrgMore] = useState(true);
  const [lastId, setLastId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedOrg, setSelectedOrg] = useState<any>(null);

  useEffect(() => {
    loadOrganisations(true);
  }, []);

  const onNext = () => {
    loadOrganisations(false);
  };

  const onSelect = () => {
    if (selectedOrg) {
      onSelectOrg(selectedOrg);
    }
  };

  const loadOrganisations = async (init = true) => {
    if (init) {
      setIsLoading(true);
      const res = await apiGetUserOrganisations(userInfo?.id, 10, 0);
      if (res.success) {
        setOrganisations(res.data);
        setHasOrgMore(res.hasMore);
        setLastId(res.lastId);

        if (enableDefault && res.data.length > 0) {
          onSelectOrg(res.data[0]);
        }
      }
      setIsLoading(false);
    } else {
      const res = await apiGetUserOrganisations(userInfo?.id, 10, lastId);
      if (res.success) {
        setOrganisations((prev) => [...prev, ...res.data]);
        setHasOrgMore(res.hasMore);
        setLastId(res.lastId);
      }
    }
  };

  const renderBody = () => {
    return (
      <div className="org_list" id="scrollableDiv">
        <InfiniteScrollWrapper
          isInfiniteScrollOn={true}
          lengthData={organisations.length}
          functionNext={onNext}
          hasOrgsMore={hasOrgMore}
          parentId="scrollableDiv"
        >
          <div className="card_org_list" id="scrollableDiv">
            {isLoading ? (
              <LoadingBarAnimation />
            ) : (
              organisations.map((item, index) => {
                return (
                  <CarOrg
                    key={`car_org_${index}`}
                    data={item}
                    setSelectedOrg={setSelectedOrg}
                    isSelected={selectedOrg && selectedOrg?.id === item.id}
                  />
                );
              })
            )}
          </div>
        </InfiniteScrollWrapper>
      </div>
    );
  };

  const renderActions = () => {
    return (
      <div className="action_view">
        <div className="cancel_btn clickable" onClick={onClose}>
          <div className="font-bold font16">Cancel</div>
        </div>
        <div style={{ width: 16 }} />
        <div
          className="publish_btn clickable"
          onClick={() => {
            onSelect();
          }}
        >
          <div className="font-bold font16" style={{ color: 'white' }}>
            Select
          </div>
        </div>
      </div>
    );
  };

  return (
    <PrimaryModal show={show} width={423} onClose={onClose}>
      <div className="modal_event_select_org">
        <div className="modal_event_select_org_header">
          <div
            className="align-vertical-center clickable"
            onClick={() => {
              onClose();
            }}
          >
            <AngleLeftIcon color="#000000" />
          </div>
          <div className="title font-bold font20">Select School</div>
        </div>
        <div className="modal_event_select_org_body">{renderBody()}</div>
        {renderActions()}
      </div>
    </PrimaryModal>
  );
};
