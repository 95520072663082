import React, { useEffect, useState } from 'react';
import { apiGetMicroInterest } from 'shared/api/topic';

import './index.styles.scss';

interface Props {
  selectedTopic: any;
  userId: any;
  selectedSubTopic: Array<any>;
  onUpdateSubTopic: (newTopic) => void;
}

export const SubTopics = ({
  selectedTopic,
  userId,
  selectedSubTopic,
  onUpdateSubTopic
}: Props) => {
  const [arrSubTopic, setArrSubTopic] = useState<Array<any>>([]);
  const [hasMore, setHasMore] = useState(true);
  const [lastId, setLastId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedTopic) {
      loadData();
    }
  }, [selectedTopic]);

  const loadData = async (init = true) => {
    if (init) {
      setIsLoading(true);

      const res = await apiGetMicroInterest({
        topic: selectedTopic.topic,
        userId: userId,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrSubTopic(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }

      setIsLoading(false);
    } else {
      if (!isLoading && hasMore) {
        setIsLoading(true);

        const res = await apiGetMicroInterest({
          topic: selectedTopic.topic,
          userId: userId,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrSubTopic((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoading(false);
      }
    }
  };

  const onClicked = (item) => {
    if (
      selectedSubTopic.filter((sItem, _) => sItem.id === item.id).length > 0
    ) {
      onUpdateSubTopic(
        selectedSubTopic.filter((sItem, _) => sItem.id !== item.id)
      );
    } else {
      onUpdateSubTopic([item, ...selectedSubTopic]);
    }
  };

  return (
    <div className="mobile_bot_sub_topics">
      <div className="sub_topics_list">
        {arrSubTopic.map((item, index) => {
          let isSelected =
            selectedSubTopic.filter((sItem, _) => sItem.id === item.id).length >
            0;

          return (
            <CardSubTopic
              key={`card_sub_topic_${index}`}
              data={item}
              isSelected={isSelected}
              onClick={() => onClicked(item)}
            />
          );
        })}
      </div>
    </div>
  );
};

const CardSubTopic = ({ data, isSelected, onClick }) => {
  return (
    <div
      className={isSelected ? 'selected_item' : 'default_item'}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <div
        className={`${
          isSelected ? 'selected_item_txt' : 'default_item_txt'
        } font-medium font14`}
      >
        {data.topic_name}
      </div>
    </div>
  );
};
