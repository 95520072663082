import React from 'react';

function OrgIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 24}
      height={props.size ?? 24}
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill={props.color ?? '#000'}
        d="M14.5 8h1a1 1 0 100-2h-1a1 1 0 100 2zm0 4h1a1 1 0 000-2h-1a1 1 0 000 2zm-5-4h1a1 1 0 100-2h-1a1 1 0 000 2zm0 4h1a1 1 0 000-2h-1a1 1 0 000 2zm12 8h-1V3a1 1 0 00-1-1h-14a1 1 0 00-1 1v17h-1a1 1 0 000 2h18a1 1 0 000-2zm-8 0h-2v-4h2v4zm5 0h-3v-5a1 1 0 00-1-1h-4a1 1 0 00-1 1v5h-3V4h12v16z"
      ></path>
    </svg>
  );
}

export default OrgIcon;
