import React, { useEffect, useState } from 'react';
import { CommunityAll } from './All';
import { CommunityGroup } from './Group';

import './index.styles.scss';
import { CommunityOrg } from './Org';

import appAction from 'redux/app/action';
import { useDispatch } from 'react-redux';
import { sendAnalytics } from 'shared/utils/analytics';
import { useNavigate, useParams } from 'react-router-dom';

import { MobileChatIntroModal } from 'components/Modals/Mobile/ChatIntroModal';

export const MobileCommunityHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const param = useParams();

  const [tab, setTab] = useState<'all' | 'group' | 'org'>('all');

  const [agentId, setAgentId] = useState<any>(null);
  const [showAgentModal, setShowAgentModal] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    sendAnalytics('Community Mobile');
  }, []);

  useEffect(() => {
    if (param && param.id) {
      setShowAgentModal(true);
      setAgentId(param.id);
    } else {
      setShowAgentModal(false);
    }
  }, [param]);

  const handleScroll = (e) => {
    // hide nav bar
    const scrollTop = e.target.scrollTop;
    setLastScrollTop(scrollTop);

    if (scrollTop < lastScrollTop) {
      document
        .getElementById('mobile_body')
        ?.setAttribute('class', 'mobile_body normal_mobile_body');
      document
        .getElementById('mobile_tab')
        ?.setAttribute('class', 'mobile_tab active_nav');
    } else {
      document
        .getElementById('mobile_body')
        ?.setAttribute('class', 'mobile_body expanded_mobile_body');
      document
        .getElementById('mobile_tab')
        ?.setAttribute('class', 'mobile_tab hidden_nav');
    }

    // load more questions
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      dispatch(appAction.communityScroll());
    }
  };

  const renderTab = () => {
    return (
      <div className="community_tab">
        <div
          className={`${
            tab === 'all' ? 'selected_tab' : 'default_tab'
          } font-bold font18`}
          onClick={() => setTab('all')}
        >
          All
        </div>
        <div
          className={`${
            tab === 'group' ? 'selected_tab' : 'default_tab'
          } font-bold font18`}
          onClick={() => setTab('group')}
        >
          Groups
        </div>
        <div
          className={`${
            tab === 'org' ? 'selected_tab' : 'default_tab'
          } font-bold font18`}
          onClick={() => setTab('org')}
        >
          Schools
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="community_body">
        {tab === 'all' ? (
          <CommunityAll />
        ) : tab === 'group' ? (
          <CommunityGroup />
        ) : (
          <CommunityOrg />
        )}
      </div>
    );
  };

  const renderBotModal = () => {
    return (
      <MobileChatIntroModal
        open={showAgentModal}
        aiInfo={{
          ai_id: parseInt(agentId),
          ai_type: 'personal'
        }}
        onClose={() => {
          setShowAgentModal(false);
        }}
      />
    );
  };

  return (
    <div className="mobile_community_home" onScroll={handleScroll}>
      {/* {renderTab()} */}
      {renderBody()}
      {agentId && renderBotModal()}
    </div>
  );
};
