//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QrReader } from 'react-qr-reader';
import { useCookies } from 'react-cookie';
import jsQR from 'jsqr';

import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import './index.styles.scss';
import { apiLoginWithQR } from 'shared/api/user';

import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import QuestionMarkIcon from 'assets/svgs/QuestionMarkIcon';
import ImageIcon from 'assets/svgs/ImageIcon';
import { QRGalleryModal } from './QRGallery';
import { showToast } from 'shared/ui-kit/PrimaryToast';

export const MobileLoginWithQR = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [cookie, setCookie] = useCookies(['accessToken']);
  const code = params.code;

  const [showGallery, setShowGallery] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  useEffect(() => {
    if (code && code !== '1') {
      onVerify(code);
    }
  }, []);

  const onVerify = async (qrLink: string) => {
    const arrCodeData = qrLink.split('/');
    const qrCode = arrCodeData[arrCodeData.length - 1];

    if (!isVerifying) {
      setIsVerifying(true);

      const res = await apiLoginWithQR({ code: qrCode });

      if (res.success) {
        setCookie('accessToken', res.accessToken, { path: '/' });
        localStorage.setItem('userId', res.userData.id);
        window.location.reload(false);
      } else {
        showToast('error', 'QR code not recognized');

        navigate('/');
      }
    }
  };

  const onSelectFromGallery = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.multiple = true;
    inputElement.addEventListener('change', onAddFile);
    inputElement.dispatchEvent(new MouseEvent('click'));
  };

  const renderLoadingBar = () => {
    return (
      <div className="loading_bar">
        <LoadingCircular size={24} color="main" />
      </div>
    );
  };

  const renderGalleryModal = () => {
    return (
      <QRGalleryModal
        isOpen={showGallery}
        onClose={() => setShowGallery(false)}
      />
    );
  };

  async function onAddFile(e: any) {
    if ((e.target.files[0] as File).type.includes('image/')) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = URL.createObjectURL(e.target.files[0]);

      img.onload = () => {
        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        ctx!.drawImage(img, 0, 0);
        const imageData = ctx!.getImageData(
          0,
          0,
          img.naturalWidth,
          img.naturalHeight
        );

        const code = jsQR(imageData.data, img.naturalWidth, img.naturalHeight);

        if (code && code.data) {
          onVerify(code.data);
        } else {
          showToast('error', 'This is invalid QR Code');
        }
      };
    }
  }

  return (
    <div className="mobile_login_qr">
      <div className="top_bar_wrapper">
        <div className="top_bar">
          <div className="" onClick={() => navigate(-1)}>
            <AngleLeftIcon color="white" />
          </div>
          <div className="text-white font20 font-bold title">Login with QR</div>
          <div></div>
        </div>
      </div>
      <div className="body_wrapper">
        <div style={{ width: '100%', height: '100%' }}>
          <QrReader
            className="camera_view"
            constraints={{ facingMode: 'environment' }}
            onResult={(result, error) => {
              if (!!result && !isVerifying) {
                onVerify(result?.text);
              }

              if (!!error) {
                // console.info(error);
              }
            }}
            containerStyle={{ width: '100%', height: '100%' }}
            videoContainerStyle={{ width: '100%', height: '100%' }}
            videoStyle={{ width: '100%', height: '100%' }}
          />
        </div>
      </div>
      <div className="bottom_bar_wrapper">
        <div className="bottom_bar">
          <div className="bottom_bar_title">
            <QuestionMarkIcon />
            <div className="font20 font-bold">How to use mobile QR login</div>
          </div>
          <div className="font16 font-regular">
            <p>{`1. Log in on desktop`}</p>
            <p>2. Click on the QR icon on the top right</p>
            <p>3. Point your camera to the QR code</p>
          </div>
          <div className="you_can">
            <div className="you_can_divider"></div>
            <div className="font-regular font12">or you can</div>
            <div className="you_can_divider"></div>
          </div>
          <div className="open_image" onClick={onSelectFromGallery}>
            <ImageIcon />
            <div className="font-bold font14">Open Image from Gallery</div>
          </div>
        </div>
      </div>
      {isVerifying && renderLoadingBar()}
      {renderGalleryModal()}
    </div>
  );
};
