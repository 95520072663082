import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import EmptyUserIcon from 'assets/pngs/user_with_hand_grey.png';
import React, { useEffect, useMemo, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { apiGetQAAnswers } from 'shared/api/qa';
import { getArrFromString } from 'shared/utils/utils';
import { MobileCardQuestionDetailAnswer } from './CardAnswer';

import './index.styles.scss';
import { MobileShareAnswerModal } from 'components/Modals/MobileShare/ShareAnswer';

interface Props {
  open: boolean;
  data: any;
  onClose: () => void;
  onSuccess: () => void;
}

export const MobileFeedAnswersModal = ({
  open,
  data,
  onClose,
  onSuccess
}: Props) => {
  const [otherAnswers, serOtherAnswers] = useState<Array<any>>([]);
  const [hasMoreOther, setHasMoreOther] = useState(true);
  const [lastIdOther, setLastIdOther] = useState(0);
  const [isLoadingOther, setIsLoadingOther] = useState(false);
  const [isLoadedData, setIsLoadedData] = useState(false);

  const [showShareModal, setShowShareModal] = useState(false);

  useEffect(() => {
    if (open) {
      loadOtherAnswers(true);
    }
  }, [open]);

  const loadOtherAnswers = async (init = true) => {
    if (init) {
      setIsLoadingOther(true);

      const res = await apiGetQAAnswers({
        bookmark: data.item_id,
        limit: 10
      });

      if (res.success) {
        setIsLoadedData(true);
        serOtherAnswers(res.data);
        setHasMoreOther(res.hasMore);
        setLastIdOther(res.lastId);
      }

      setIsLoadingOther(false);
    } else {
      if (!isLoadingOther && hasMoreOther) {
        setIsLoadingOther(true);

        const res = await apiGetQAAnswers({
          bookmark: data.item_id,
          limit: 10,
          offset: lastIdOther
        });

        if (res.success) {
          serOtherAnswers((prev) => [...prev, ...res.data]);
          setHasMoreOther(res.hasMore);
          setLastIdOther(res.lastId);
        }

        setIsLoadingOther(false);
      }
    }
  };

  const feedTopics = useMemo(() => {
    let feedTopicsArray: Array<any> = [];
    const feedItem = data;
    const arrTopicId = getArrFromString(feedItem.topic_ids, ';');
    const arrTopicName = getArrFromString(feedItem.topics, ';');
    const arrTopicEmoji = getArrFromString(feedItem.topic_emojis, ';');

    if (arrTopicId.length > 0) {
      feedTopicsArray = arrTopicId.map((item, index) => {
        return {
          id: item,
          name: arrTopicName[index],
          emjoi: arrTopicEmoji[index]
        };
      });
    }

    return feedTopicsArray;
  }, [data]);

  const renderTop = () => {
    return (
      <div className="modal_header row_align_items row_space_between">
        <div
          className="row_align_items"
          style={{ gap: 16, width: 'calc(100% - 48px)' }}
        >
          <div className="item_btn" onClick={onClose}>
            <AngleLeftIcon size={24} color="rgba(0, 0, 0, 0.4)" />
          </div>
          <div className="header_info">
            <div className="question_txt font-bold font14">{data.question}</div>
            {renderTopics()}
          </div>
        </div>
        <div
          className="item_btn"
          onClick={() => {
            setShowShareModal(true);
          }}
        >
          <ShareIcon size={16} color="#FF3E9A" />
        </div>
      </div>
    );
  };

  const renderTopics = () => {
    return (
      <div className="topic_list">
        {feedTopics.map((item, index) => {
          return (
            <div
              className={`default_topic_item font-medium font12`}
              key={`card_mobile_feed_topic_${index}`}
            >
              {`${item.emjoi} ${item.name} `}
            </div>
          );
        })}
      </div>
    );
  };

  const renderAnswers = () => {
    return (
      <div className="answer_list">
        {otherAnswers.map((item, index) => {
          return (
            <MobileCardQuestionDetailAnswer
              data={item}
              key={`mobile_card_question_detail_answer_${index}`}
            />
          );
        })}
      </div>
    );
  };

  const renderEmptyAnswer = () => {
    return (
      <div className="empty_view_container">
        <div className="empty_view">
          <img src={EmptyUserIcon} style={{ width: 56, height: 56 }} />
          <div className="font-bold font18" style={{ marginTop: 16 }}>
            Nobody answered yet
          </div>
          <div
            className="font-regular font14"
            style={{ marginTop: 8, textAlign: 'center' }}
          >
            Be the first to post an answer
          </div>
        </div>
      </div>
    );
  };

  const renderShareModal = () => {
    return (
      <MobileShareAnswerModal
        show={showShareModal}
        question={data}
        onClose={() => {
          setShowShareModal(false);
        }}
      />
    );
  };

  return (
    <Sheet
      isOpen={open}
      onClose={onClose}
      className="mobile_question_answers_mdoal"
    >
      <Sheet.Container className="mobile_feed_question_answers_modal_body">
        <Sheet.Content>
          <div className="mobile_feed_question_answers_modal">
            {renderTop()}
            {isLoadedData && otherAnswers.length == 0
              ? renderEmptyAnswer()
              : renderAnswers()}
          </div>
          {renderShareModal()}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
