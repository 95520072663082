import CloseIcon from 'assets/svgs/CloseIcon';
import React from 'react';
import DEFAULT_AVATAR from 'assets/pngs/default_user_image.png';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import './index.styles.scss';

interface Props {
  userData: any;
  onClose: () => void;
}

export const TopView = ({ userData, onClose }: Props) => {
  const percentage = 10;

  const renderCloseBtn = () => {
    return (
      <div onClick={onClose}>
        <CloseIcon />
      </div>
    );
  };

  const renderUserImage = () => {
    return (
      <div className="image_view">
        <div className="user_image_container">
          {userData.image && userData.image.startsWith('https') ? (
            <img src={userData.image} className="user_image" />
          ) : (
            <img src={DEFAULT_AVATAR} className="user_image" />
          )}
          {/* <div className="profile_status font-bold">
            <CircularProgressbar
              value={percentage}
              text={`${percentage}%`}
              styles={buildStyles({
                textSize: '30px',
                pathColor: `#ff3e9a`,
                textColor: '#3BB491',
                trailColor: 'white',
                backgroundColor: 'white'
              })}
            />
          </div> */}
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_bot_modal_top_view">
      {renderCloseBtn()}
      {renderUserImage()}
    </div>
  );
};
