import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import InstagramIcon from 'assets/svgs/InstagramIcon';
import TikTokIcon from 'assets/svgs/TikTokIcon';
import TwitterIcon from 'assets/svgs/TwitterIcon';
import profileAction from 'redux/profile/actions';
import appAction from 'redux/app/action';

import './index.styles.scss';
import AngleRightIcon from 'assets/svgs/AngleRightIcon';
import { apiEditSocialHandle } from 'shared/api/user';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import TrashIcon from 'assets/svgs/TrashIcon';
import { apiDeletionSocialHandle } from 'shared/api/delete';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import RedditIcon from 'assets/svgs/RedditIcon';
import SnapChatIcon from 'assets/svgs/SnapChatIcon';
import YoutubIcon from 'assets/svgs/YoutubeIcon';
import SpotifyIcon from 'assets/svgs/SpotifyIcon';

interface SocialItemProps {
  type:
    | 'twitter'
    | 'instagram'
    | 'tiktok'
    | 'reddit'
    | 'snapchat'
    | 'youtube'
    | 'spotify';
  socialInfo?: any;
  isConnected: boolean;
}

export const SocialItem = ({
  type,
  socialInfo,
  isConnected
}: SocialItemProps) => {
  const dispatch = useDispatch();
  const { user, loginWithPopup, logout } = useAuth0();
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (
      user &&
      user.sub?.toLowerCase().includes(getConnectionName(type).toLowerCase()) &&
      !isConnected &&
      !isDeleting
    ) {
      onSave();
    }
  }, [user]);

  const getConnectionName = (baseType) => {
    return baseType === 'youtube'
      ? 'google-oauth2'
      : baseType === 'tiktok'
      ? 'TikTok'
      : baseType;
  };

  const onEdit = async () => {
    if (isConnected) {
      // await logout({ clientId: type });
    } else {
      try {
        if (type === 'spotify') {
          loginWithPopup({
            authorizationParams: {
              connection: getConnectionName(type),
              accessType: 'offline',
              approvalPrompt: 'force',
              scope: 'openid profile',
              connection_scope: 'user-top-read user-read-recently-played'
            }
          });
        } else if (type === 'youtube') {
          loginWithPopup({
            authorizationParams: {
              connection: getConnectionName(type),
              accessType: 'offline',
              approvalPrompt: 'force',
              scope: 'offline_access',
              connection_scope:
                'https://www.googleapis.com/auth/youtube.readonly,https://www.googleapis.com/auth/yt-analytics.readonly'
            }
          });
        } else {
          loginWithPopup({
            authorizationParams: {
              connection: getConnectionName(type),
              accessType: 'offline',
              approvalPrompt: 'force',
              scope: 'openid profile'
            }
          });
        }
      } catch (error) {
        console.log({ error });
      }
    }
  };

  const onDelete = async () => {
    dispatch(profileAction.deletingSocial());

    if (!isDeleting) {
      setIsDeleting(true);

      const res = await apiDeletionSocialHandle({ id: socialInfo.id });

      if (res.success) {
        await logout({ clientId: process.env.REACT_APP_AUTH0_CLIENT_ID });
        dispatch(profileAction.loadUserInfo(userInfo?.id ?? 0));
        showToast('success', 'Social handle successfully disconnected!');
      }

      setIsDeleting(false);
    }
  };

  const onSave = async () => {
    dispatch(profileAction.connectingSocial());

    var params = {
      social_id: user?.sub,
      type,
      handle: type === 'instagram' ? user?.nickname : user?.name
    };

    const res = await apiEditSocialHandle(params);

    if (res.success) {
      dispatch(appAction.refreshMobileSocialMedia());
      if (userInfo) {
        dispatch(profileAction.loadUserInfo(userInfo?.id));
      }
    }
  };

  const renderIcon = () => {
    switch (type) {
      case 'instagram':
        return <InstagramIcon color="#FF3E9A" size={32} />;
      case 'tiktok':
        return <TikTokIcon color="#333333" size={36} />;
      case 'twitter':
        return <TwitterIcon color="#2D9CDB" size={32} />;
      case 'reddit':
        return <RedditIcon color="#2D9CDB" size={32} />;
      case 'snapchat':
        return <SnapChatIcon color="#2D9CDB" size={32} />;
      case 'youtube':
        return <YoutubIcon size={32} />;
      case 'spotify':
        return <SpotifyIcon size={32} />;
      default:
        return <div />;
    }
  };

  const renderName = () => {
    switch (type) {
      case 'instagram':
        return socialInfo.handle ?? 'Instagram';
      case 'tiktok':
        return socialInfo.handle ?? 'Tiktok';
      case 'twitter':
        return socialInfo.handle ?? 'Twitter';
      case 'reddit':
        return socialInfo.handle ?? 'Reddit';
      case 'snapchat':
        return socialInfo.handle ?? 'SnapChat';
      case 'youtube':
        return socialInfo.handle ?? 'Youtube';
      case 'spotify':
        return socialInfo.handle ?? 'Spotify';
      default:
        return <div />;
    }
  };

  const renderAction = () => {
    if (isConnected) {
      return (
        <div onClick={onDelete}>
          {isDeleting ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            <TrashIcon />
          )}
        </div>
      );
    } else {
      return (
        <div>
          <AngleRightIcon />
        </div>
      );
    }
  };

  return (
    <div className="modal_social_item clickable" onClick={onEdit}>
      <div className="social_content">
        {renderIcon()}
        <div className="social_content_divider"></div>
        <div className="social_content_name font-regular font18">
          {renderName()}
        </div>
      </div>

      {renderAction()}
    </div>
  );
};
