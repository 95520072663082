import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useState } from 'react';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';

import Download1Icon from 'assets/svgs/Download1Icon';
import WhatsappIcon from 'assets/svgs/WhatsappIcon';
import TelegramIcon from 'assets/svgs/TelegramIcon';
import InstagramIcon from 'assets/svgs/InstagramIcon';
import TwitterIcon from 'assets/svgs/TwitterIcon';
import TikTokIcon from 'assets/svgs/TikTokIcon';

import './index.styles.scss';
import { getImageFromHtml } from 'shared/utils/utils';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { apiQAGetShareLink } from 'shared/api/qa';
import { apiGetTopicPercentage, apiGetUserSharingQR } from 'shared/api/user';
import { apiGetChatIntroduce } from 'shared/api/chat';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';

interface ModalProps {
  show: boolean;
  data: any;
  onClose: () => void;
}

export const MobileShareBotModal = ({ show, data, onClose }: ModalProps) => {
  const { uploadBase64 } = useIPFS();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [shareUrl, setShareUrl] = useState('');
  const [qrCodeUrl, setQRCodeUrl] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);

  const [percentInfo, setPercentInfo] = useState<any>(null);

  useEffect(() => {
    if (show && data) {
      getSharingQRcode();
      loadPercentInfo();
    }
  }, [show, data]);

  const [chatDetail, setChatDetail] = useState<any>(null);

  useEffect(() => {
    if (data && show) {
      loadDetail();
    }
  }, [data, show]);

  const loadDetail = async () => {
    const res = await apiGetChatIntroduce({
      ai_id: data.ai_id,
      ai_type: data.ai_type
    });

    if (res.success) {
      setChatDetail(res.data);
    }
  };

  const loadPercentInfo = async () => {
    const res = await apiGetTopicPercentage({
      userId: data.creator_id
    });
    setPercentInfo(res.data);
  };

  const getSharingQRcode = async () => {
    const res = await apiGetUserSharingQR();
    if (res.success) {
      setQRCodeUrl(res.qr_url);
      initShareUrl();
    }
  };

  const initShareUrl = async () => {
    try {
      const base64Image = await getImageFromHtml(
        `mobile_share_body_image_${data!.ai_id}_bot`
      );
      const url = await uploadBase64(base64Image, 'image/png', '');

      const res = await apiQAGetShareLink({
        url: url,
        item_id: data!.ai_id,
        type: 'bot'
      });

      if (res.success) {
        setShareUrl(res.sharing_url);
      } else {
        // initShareUrl();
      }
    } catch (error) {
      // initShareUrl();
    }
  };

  const onShare = async (type: 'download') => {
    if (!isDownloading)
      if (type === 'download') {
        setIsDownloading(true);
        const base64Image = await getImageFromHtml(
          `mobile_share_body_image_${data!.ai_id}_bot`
        );
        const url = await uploadBase64(base64Image, 'image/png', '');

        await onDownloadFile(url);
        setIsDownloading(true);
      }
  };

  const onDownloadFile = async (fileUrl) => {
    if (!isDownloading) {
      setIsDownloading(true);

      fetch(fileUrl)
        .then((response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'share_image';
            alink.click();
          });
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  };

  const renderHeader = () => {
    return (
      <div className="share_header row_align_items">
        <div onClick={onClose}>
          <BlackBackIcon />
        </div>
        <div className="header_title font-bold font18">Share to Social</div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="share_body">
        <div
          className="share_main_body"
          id={`mobile_share_body_image_${data!.ai_id}_bot`}
        >
          {renderTopView()}
          {renderInfo()}
        </div>
      </div>
    );
  };

  const renderTopView = () => {
    return (
      <div className="row_space_between">
        <div className="row_align_items">
          <div className="align-vertical-center">
            {userInfo?.image && userInfo?.image.startsWith('https') ? (
              <img
                src={userInfo?.image}
                style={{ width: 32, height: 32, borderRadius: 32 }}
              />
            ) : (
              <DefaultAvatarIcon size={32} />
            )}
          </div>
          <div style={{ marginLeft: 6 }}>
            <div className="font-bold font12 text_no_wrap">
              {userInfo?.name}
            </div>
            {percentInfo && renderPercentInfo()}
          </div>
        </div>

        <div className="qr_view">
          <img
            src={qrCodeUrl}
            style={{ width: '100%', height: '100%' }}
            crossOrigin="anonymous"
          />
        </div>
      </div>
    );
  };

  const renderPercentInfo = () => {
    return (
      <div
        className="font-regular font10 text_no_wrap"
        style={{ marginTop: 4 }}
      >
        {`Top ${percentInfo!.percentile}% in ${percentInfo!.topic_emoji} ${
          percentInfo!.topic_name
        }`}
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <div
        className="info_container"
        style={isDownloading ? { overflow: 'hidden' } : { overflowY: 'auto' }}
      >
        <div className="info_body">
          <div className="chat_intro_body">
            {chatDetail?.ai_image &&
            chatDetail?.ai_image.startsWith('https') ? (
              <img src={chatDetail?.ai_image} className="chat_ai_image" />
            ) : (
              <DefaultAvatarIcon size={88} />
            )}
            <div className="sub_txt">
              <div className="font-bold font20">{chatDetail?.ai_name}</div>
            </div>
            {renderCreatorInfo()}
            <div className="chat_intro_desc font-regular font12">
              {chatDetail.description}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCreatorInfo = () => {
    return (
      <div className="row_align_items" style={{ gap: 4 }}>
        {chatDetail?.creator_image &&
        chatDetail?.creator_image.startsWith('https') ? (
          <img src={chatDetail?.creator_image} className="creator_image" />
        ) : (
          <DefaultAvatarIcon size={16} />
        )}
        <div className="font-regular font10">{chatDetail?.creator_name}</div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="share_bottom">
        <div className="statement_link_bar_items">
          <div
            className="statement_link_bar_items_item download_itm clickable"
            onClick={() => onShare('download')}
          >
            {isDownloading ? (
              <LoadingCircular size={20} color={'white'} />
            ) : (
              <Download1Icon />
            )}
          </div>
          <div className="font10">Download</div>
        </div>
        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <WhatsappShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Whatsapp_itm">
                <WhatsappIcon />
              </div>
              <div className="font10">Whatsapp</div>
            </div>
          </WhatsappShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TelegramShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Telegram_itm ">
                <TelegramIcon />
              </div>
              <div className="font10">Telegram</div>
            </div>
          </TelegramShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TwitterShareButton title={''} url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Twitter_itm">
                <TwitterIcon />
              </div>
              <div className="font10">Twitter</div>
            </div>
          </TwitterShareButton>
        )}
        {!!data?.sharing && (
          <div className="statement_link_bar_items">
            <div className="statement_link_bar_items_item Tiktok_itm">
              <TikTokIcon color={'#333333'} size={48} />
            </div>
            <div className="font10">TikTok</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <PrimaryModal
      show={show}
      width={'100%'}
      style={{ width: '100%', height: '100%', borderRadius: 0, padding: 0 }}
      onClose={onClose}
    >
      <div className="mobile_share_bot_modal">
        {renderHeader()}
        {chatDetail ? renderBody() : <div className="share_body" />}
        {renderBottom()}
      </div>
    </PrimaryModal>
  );
};
