import React, { useEffect, useRef, useState } from 'react';
import Sheet from 'react-modal-sheet';
import ReactHowler from 'react-howler';
import MicIcon from 'assets/svgs/MicIcon';
import { ReactMediaRecorder } from 'react-media-recorder';
import { apiCreateQADump } from 'shared/api/qa';

import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { formatSecond } from 'shared/utils/format-time';
import { validURL } from 'shared/utils/utils';

import './index.styles.scss';
import CloseIcon from 'assets/svgs/CloseIcon';
import { RootState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import UploadAltIcon from 'assets/svgs/UploadAltIcon';
import ChatSendIcon from 'assets/svgs/ChatSendIcon';
import AudioPauseIcon from 'assets/svgs/AudioPauseIcon';
import ArrowPlayIcon from 'assets/svgs/ArrowPlayIcon';
import TrashIcon from 'assets/svgs/TrashIcon';
import UCheckIcon from 'assets/svgs/UCheckIcon';

interface Props {
  aiId: any;
  open: boolean;
  onClose: () => void;
  onSuccess: (dumpId, link) => void;
  onViewFile: (aiId) => void;
}

export const MobileAddNewAudio = ({
  aiId,
  open,
  onClose,
  onSuccess,
  onViewFile
}: Props) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);

  const [createdBlob, setCreatedBlob] = useState<any>(null);
  const [createdBlobBase64, setCreatedBlobBase64] = useState<any>(null);
  const [createdAudio, setCreatedAudio] = useState(null);
  const [createdAudioLength, setCreatedAudioLenght] = useState(0);

  const [isCreating, setIsCreating] = useState(false);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [isSuccessed, setIsSuccesed] = useState(false);

  const { uploadBase64 } = useIPFS();
  let timerRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    initRecord();
  }, [open]);

  useEffect(() => {
    if (recordingTime !== 0) {
      setCreatedAudioLenght(recordingTime);
    }
  }, [recordingTime]);

  const initRecord = () => {
    setCreatedAudio(null);
    setCreatedBlob(null);
    setCreatedBlobBase64(null);
    setCreatedAudioLenght(0);
    setRecordingTime(0);
    setIsCreating(false);
    setIsPlayingAudio(false);
    onClearTimer();
  };

  const onCreate = async (url) => {
    try {
      if (validURL(url)) {
        if (aiId) {
          if (!isCreating) {
            setIsCreating(true);
            const res = await apiCreateQADump({
              url: url,
              type: 'url',
              to: aiId === -1 ? userInfo?.id : aiId,
              to_type: aiId === -1 ? 'user' : 'personal'
            });
            console.log({
              url: url,
              type: 'url',
              to: aiId === -1 ? userInfo?.id : aiId,
              to_type: aiId === -1 ? 'user' : 'personal'
            });
            console.log({ res });
            if (res.success) {
              if (aiId === -1) {
                onSuccess(res.id, url);
                onClose();
              } else {
                setIsSuccesed(true);
                onSuccess(res.id, url);
              }
            } else {
              showToast('error', 'Unable to process link url');
            }
            setIsCreating(false);
          }
        } else {
          onSuccess(null, url);
        }
      } else {
        showToast('error', 'Unable to process link url');
      }
    } catch (error) {
      setIsCreating(false);
    } finally {
      setIsCreating(false);
    }
  };

  const onStartRecord = () => {
    setIsRecording(true);
    timerRef.current = setInterval(() => {
      setRecordingTime((prev) => prev + 1);
    }, 1000);
  };

  const onStopRecord = async (mediaBlobUrl, blob) => {
    onClearTimer();
    setIsRecording(false);

    const newBlob = createdBlob
      ? await mergeBlobs([createdBlob, blob])
      : await mergeBlobs([blob]);
    setCreatedBlob(newBlob);
    onCreatBlobBase64(newBlob);
  };

  function mergeBlobs(blobs) {
    return new Promise((resolve, reject) => {
      const blobPromises = blobs.map((blob) => blob.arrayBuffer());
      Promise.all(blobPromises)
        .then((buffers) => {
          let totalLength = buffers.reduce(
            (acc, buffer) => acc + buffer.byteLength,
            0
          );
          let result = new Uint8Array(totalLength);
          let offset = 0;
          buffers.forEach((buffer) => {
            result.set(new Uint8Array(buffer), offset);
            offset += buffer.byteLength;
          });
          resolve(new Blob([result.buffer], { type: blobs[0].type }));
        })
        .catch(reject);
    });
  }

  const onUploadAndCreateAudio = async () => {
    if (open && createdBlob) {
      setIsCreating(true);
      try {
        let reader = new FileReader();
        reader.readAsDataURL(createdBlob);
        reader.onloadend = async function () {
          let base64String = reader.result;
          const url = (await uploadBase64(
            base64String,
            'audio/wav',
            'wav'
          )) as any;

          setCreatedAudio(url);
          await onCreate(url);

          setIsCreating(false);
        };
      } catch (error) {
        setIsCreating(false);
        console.log('--- onUploadAndCreateAudio ---', error);
      }
    }
  };

  const onCreatBlobBase64 = (blob) => {
    try {
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async function () {
        let base64String = reader.result;
        setCreatedBlobBase64(base64String);
      };
    } catch (error) {
      console.log('--- creating blob base64 ---', error);
    }
  };

  const onClearTimer = () => {
    if (timerRef) {
      clearInterval(timerRef?.current!);
      setIsRecording(false);
    }
  };

  const onContinueExplore = () => {
    onClose();
  };

  const renderBody = () => {
    return (
      <ReactMediaRecorder
        audio
        onStop={onStopRecord}
        render={({
          status,
          startRecording,
          stopRecording,
          clearBlobUrl,
          mediaBlobUrl
        }) => {
          return (
            <Sheet
              isOpen={open}
              onClose={() => {
                clearBlobUrl();
                initRecord();
                onClose();
              }}
              detent={'content-height'}
            >
              <Sheet.Container
                style={{ borderTopLeftRadius: 32, borderTopRightRadius: 32 }}
              >
                <Sheet.Content>
                  <div className="top_indicator_container">
                    <div className="top_div"></div>
                  </div>
                  {isSuccessed ? (
                    renderSuccessBody()
                  ) : (
                    <div className="mobile_user_profile_record_modal">
                      <div
                        style={{ width: '100%', paddingLeft: 32 }}
                        onClick={() => {
                          onClose();
                          clearBlobUrl();
                          initRecord();
                        }}
                      >
                        <CloseIcon />
                      </div>
                      {renderHeader()}
                      <div className="row_align_items" style={{ gap: 24 }}>
                        {createdAudioLength !== 0 && (
                          <div
                            className="refresh_btn clickable"
                            onClick={() => {
                              if (!isCreating) {
                                clearBlobUrl();
                                initRecord();
                              }
                            }}
                          >
                            <TrashIcon />
                          </div>
                        )}
                        {isRecording
                          ? renderRecordStopBtn(stopRecording)
                          : createdBlobBase64
                          ? renderRecordRefreshBtn(startRecording)
                          : renderRecordBtn(startRecording)}
                        {createdAudioLength !== 0 &&
                          (createdBlobBase64
                            ? renderCreateDoneBtn()
                            : renderDiabledDoneBtn())}
                      </div>
                    </div>
                  )}
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet>
          );
        }}
      />
    );
  };

  const renderHeader = () => {
    if (!isRecording && createdBlobBase64 && recordingTime !== 0) {
      return (
        <div className="play_header row_align_items">
          {isRecording ? (
            <div className="font-bold font14">Recording... </div>
          ) : (
            <div
              onClick={() => {
                setIsPlayingAudio(true);
              }}
              className="align-center"
            >
              {isPlayingAudio ? (
                <AudioPauseIcon size={16} color={'black'} />
              ) : (
                <ArrowPlayIcon />
              )}
            </div>
          )}

          <div className="font-bold font14">
            {`${formatSecond(recordingTime)}`}
          </div>
          {!!createdBlobBase64 && (
            <ReactHowler
              src={[createdBlobBase64]}
              format={['mp3']}
              playing={isPlayingAudio}
              loop={false}
              html5={true} // Use HTML5 Audio API
              onLoad={() => {}}
              onEnd={() => setIsPlayingAudio(false)}
              onLoadError={(e) => {
                console.log('-- load audio error ---', e);
              }}
              onPlayError={(e) => {
                console.log('-- play audio error ---', e);
              }}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className="font-bold font14" style={{ marginBottom: 24 }}>
          {createdAudioLength === 0
            ? `Tap to record voice note`
            : `Recording... ${formatSecond(recordingTime)}`}
        </div>
      );
    }
  };

  const renderRecordBtn = (startRecording) => {
    return (
      <div
        className="record_btn clickable"
        onClick={() => {
          if (!isCreating) {
            startRecording();
            onStartRecord();
          }
        }}
      >
        <MicIcon size={24} color={'#FF3E9A'} />
      </div>
    );
  };

  const renderRecordStopBtn = (stopRecording) => {
    return (
      <div
        className="record_stop_btn clickable"
        onClick={() => {
          if (!isCreating) {
            stopRecording();
          }
        }}
      >
        <AudioPauseIcon size={36} color={'#FF3E9A'} />
      </div>
    );
  };

  const renderRecordRefreshBtn = (startRecording) => {
    return (
      <div
        className="record_refresh_btn clickable"
        onClick={() => {
          if (!isCreating) {
            startRecording();
            onStartRecord();
          }
        }}
      >
        <MicIcon size={24} color={'white'} />
      </div>
    );
  };

  const renderDiabledDoneBtn = () => {
    return (
      <div className={`disabled_done_btn clickable`}>
        <ChatSendIcon
          size={36}
          color={'#5F6368'}
          bgColor={'white'}
          opacity={0.6}
        />
      </div>
    );
  };

  const renderCreateDoneBtn = () => {
    return (
      <div
        className={`done_btn`}
        onClick={() => {
          if (!isCreating && createdBlob) {
            onUploadAndCreateAudio();
          }
        }}
      >
        {isCreating ? (
          <LoadingCircular color="white" size={20} />
        ) : (
          <ChatSendIcon color={'white'} size={36} />
        )}
      </div>
    );
  };

  const renderSuccessBody = () => {
    return (
      <div className="mobile_add_link_modal">
        <div className="success_container">
          <div className="success_mark">
            <UCheckIcon size={46} />
          </div>
          <div className="text_container">
            <div className="font-bold font18">Success!</div>
            <div
              className="font-regular font18"
              style={{ opacity: 0.6, marginTop: 12, textAlign: 'center' }}
            >
              The transcribed content was uploaded to your AI
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <div
              className="continue_btn font-bold font12"
              onClick={onContinueExplore}
            >
              Continue to Explore
            </div>
            <div
              className="view_btn font-bold font12"
              onClick={() => {
                onViewFile(aiId);
              }}
            >
              View all Content Uploaded to Your AI
            </div>
          </div>
        </div>
      </div>
    );
  };

  return renderBody();
};
