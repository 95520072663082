import CommentDotIcon from 'assets/svgs/CommentIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import FolderOpenIcon from 'assets/svgs/FolderOpenIcon';
import LinkAddIcon from 'assets/svgs/LinkAddIcon';
import MicIcon from 'assets/svgs/MicIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import { MobileChatIntroModal } from 'components/Modals/Mobile/ChatIntroModal';
import { MobileAddNewAudio } from 'components/Modals/MobileAddFile/AddNewAudio';
import { MobileAddNewLinkModal } from 'components/Modals/MobileAddFile/AddNewLink';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { showToast } from 'shared/ui-kit/PrimaryToast';

import './index.styles.scss';

export const MobileCardPersonalAIReview = ({ data }) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [showAddLinkModal, setShowAddLinkModal] = useState(false);
  const [showAddAudioModal, setShowAddAudioModal] = useState(false);
  const [showIntroModal, setShowIntroModal] = useState(false);

  const onAddLink = (dumpId, link) => {
    // show toast
    showToast('success', 'Successfully populated data!');
  };

  const onAddAudio = (dumpId, link) => {
    // show toast

    showToast('success', 'Successfully populated data!');
  };

  const renderAddLinkModal = () => {
    return (
      <MobileAddNewLinkModal
        aiId={data.id}
        open={showAddLinkModal}
        onClose={() => {
          setShowAddLinkModal(false);
        }}
        onSuccess={(dumpId, link) => {
          onAddLink(dumpId, link);
        }}
        onViewFile={(aiId) => {
          setShowAddLinkModal(false);
        }}
      />
    );
  };

  const renderAddAudioModal = () => {
    return (
      <MobileAddNewAudio
        aiId={data.id}
        open={showAddAudioModal}
        onClose={() => {
          setShowAddAudioModal(false);
        }}
        onSuccess={(dumpId, link) => {
          onAddAudio(dumpId, link);
        }}
        onViewFile={(aiId) => {
          setShowAddAudioModal(false);
        }}
      />
    );
  };

  const renderIntroModal = () => {
    return (
      <MobileChatIntroModal
        open={showIntroModal}
        aiInfo={data}
        onClose={() => {
          setShowIntroModal(false);
        }}
      />
    );
  };

  const renderBotIcon = () => {
    return (
      <div className="bot_icon">
        <RobotIcon size={16} color={'white'} />
      </div>
    );
  };

  return (
    <div className={`mobile_card_my_personal_ai_review`}>
      <div className="bg_container"></div>
      <div className="mobile_card_personal_ai_body">
        <div className="top_info_view row_align_items">
          <div style={{ position: 'relative' }}>
            {data.id === -1 ? (
              userInfo?.image && userInfo.image.startsWith('https') ? (
                <img
                  src={data.id === -1 ? userInfo?.image : data.image}
                  className="ai_image"
                />
              ) : (
                <DefaultAvatarIcon size={48} />
              )
            ) : (
              <img src={data.image} className="ai_image" />
            )}
            {renderBotIcon()}
          </div>

          <div className="ai_name font-bold font18">
            {data.id == -1 ? 'Personal AI' : data.name}
          </div>
          <div className="row_align_items" style={{ gap: 12 }}>
            <div className="ai_action_btn">
              <LinkAddIcon />
            </div>
            <div className="ai_action_btn">
              <MicIcon color="#FF3E9A" />
            </div>
          </div>
        </div>
        <div className="action_view">
          <div className="file_btn">
            <FolderOpenIcon />
            <div className="font-bold font14">Files</div>
          </div>
          <div className="chat_btn">
            <CommentDotIcon color="white" size={24} />
            <div className="font-bold font14" style={{ color: 'white' }}>
              Chat
            </div>
          </div>
        </div>
      </div>

      {renderAddLinkModal()}
      {renderAddAudioModal()}
      {renderIntroModal()}
    </div>
  );
};
