import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import NotificationIcon from 'assets/svgs/NotificationIcon';
import RoundBackIcon from 'assets/svgs/RoundBackIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  apiEventConfirmRSVP,
  apiEventDetail,
  apiEventRemoveRSVP
} from 'shared/api/event';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';

import './index.styles.scss';
import { MobileShareEventModal } from 'components/Modals/MobileShare/ShareEvent';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { RootState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import TrashIcon from 'assets/svgs/TrashIcon';
import { apiDeletionEvent } from 'shared/api/delete';

export const MobileEventDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const eventCode = params.code;

  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [isRsvp, setIsRsvp] = useState(false);
  const [isRsvping, setIsRsvping] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [eventDetail, setEventDetail] = useState<any>(null);
  const [isRemoving, setIsRemoving] = useState(false);

  useEffect(() => {
    loadDetail();
  }, []);

  const onClose = () => {
    navigate(-1);
  };

  const loadDetail = async () => {
    const res = await apiEventDetail({ code: eventCode });
    if (res.success) {
      setIsLoaded(true);
      setEventDetail(res.data);
      setIsRsvp(res.data.is_rsvp === 1);
    }
  };

  const onRsvp = async () => {
    if (isRsvp) return;

    setIsRsvping(true);

    const res = await apiEventConfirmRSVP({ event: eventDetail.id });

    if (res.success) {
      setIsRsvp(true);
    }

    setIsRsvping(false);
  };

  const onRemoveRsvp = async () => {
    setIsRsvping(true);

    const res = await apiEventRemoveRSVP({ event: eventDetail.id });

    if (res.success) {
      setIsRsvp(false);
    }

    setIsRsvping(false);
  };

  const onDeleteEvent = async (e) => {
    e && e.stopPropagation();
    setIsRemoving(true);
    const res = await apiDeletionEvent({ event: eventDetail.id });

    setIsRemoving(false);
    if (res.success) {
      navigate(-1);
    }
  };

  const renderImageView = () => {
    return (
      <div className="image_view">
        {eventDetail.image && (
          <img
            src={eventDetail.image}
            style={{ width: '100%', height: '100%' }}
          />
        )}
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="modal_header row_space_between row_align_items">
        <div className="clickable" onClick={onClose}>
          <RoundBackIcon size={40} />
        </div>
        <div className="font-bold font16">Event Detail</div>
        <div className="row_align_items" style={{ gap: 8 }}>
          <div
            className="camera_btn clickable"
            onClick={() => {
              setShowShare(true);
            }}
          >
            <ShareIcon size={20} color="#FF3E9A" />
          </div>
          {eventDetail.creator === userInfo?.id && (
            <div
              className="camera_btn clickable"
              onClick={(e) => onDeleteEvent(e)}
            >
              {isRemoving ? (
                <LoadingCircular size={16} color="main" />
              ) : (
                <TrashIcon color="#FF3E9A" />
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="mobile_event_detail_body">
        <div className="top_body">
          {renderHeader()}
          <div className="top_info">
            <div className="font-bold font32" style={{ margin: '0px 16px' }}>
              {eventDetail.title}
            </div>

            <div className="event_detail_users row_align_items">
              {eventDetail.creator_image &&
              eventDetail.creator_image.startsWith('https') ? (
                <img
                  src={eventDetail.creator_image}
                  className="event_user_image"
                />
              ) : (
                <DefaultAvatarIcon size={24} />
              )}
              <div style={{ marginLeft: -6 }}>
                {eventDetail.org_image &&
                eventDetail.org_image.startsWith('https') ? (
                  <img
                    src={eventDetail.org_image}
                    className="event_user_image"
                  />
                ) : (
                  <DefaultAvatarIcon size={24} />
                )}
              </div>

              <div className="font-medium font10" style={{ marginLeft: 8 }}>
                {eventDetail.creator_name}{' '}
                <span style={{ opacity: 0.4 }}>in</span> {eventDetail.org_name}
              </div>
            </div>
          </div>
        </div>
        <div className="mobile_event_sub_info">
          <div className="row_space_between row_align_items">
            <div>
              <div className="font-regular font10" style={{ opacity: 0.6 }}>
                Date Time
              </div>
              <div className="font-regular font16" style={{ marginTop: 6 }}>
                {moment(eventDetail.date).format('DD MMM yyyy • hh a')}
              </div>
            </div>
            {/* <div className="reminde_me row_align_items clickable">
              <div
                className="font-bold font14 align-center"
                style={{ color: '#FF3E9A' }}
              >
                Remind Me
              </div>
              <div className="align-center" style={{ marginLeft: 6 }}>
                <NotificationIcon />
              </div>
            </div> */}
            {renderRsvpButton()}
          </div>
          <div className="content_divider"></div>
          <div>
            <div className="font-regular font10" style={{ opacity: 0.6 }}>
              Location
            </div>
            <div className="font-regular font16" style={{ marginTop: 6 }}>
              {eventDetail.location}
            </div>
          </div>
          <div className="content_divider"></div>
          <div>
            <div className="font-regular font10" style={{ opacity: 0.6 }}>
              Description
            </div>
            <div className="font-regular font16" style={{ marginTop: 6 }}>
              {eventDetail.description}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderRsvpButton = () => {
    return (
      <div>
        {!isRsvp ? (
          <div
            className="claim_btn clickable font-bold font12"
            onClick={onRsvp}
          >
            {isRsvping ? (
              <LoadingCircular color="white" size={16} />
            ) : (
              <div>Claim Your Spot</div>
            )}
          </div>
        ) : (
          <div
            className="claimed_button font-bold font12 clickable"
            onClick={onRemoveRsvp}
          >
            {isRsvping ? (
              <LoadingCircular color="main" size={16} />
            ) : (
              <div>RSVP-ed</div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderShareModal = () => {
    return (
      <MobileShareEventModal
        show={showShare}
        eventData={eventDetail}
        onClose={() => {
          setShowShare(false);
        }}
      />
    );
  };

  if (eventDetail) {
    return (
      <div className="mobile_event_detail">
        {renderImageView()}
        {renderBody()}
        {renderShareModal()}
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div className="mobile_event_detail">
        <div className="loading_container">
          <LoadingBarAnimation />
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};
