import { QuestionAskBar } from 'components/Main/Organisation/components/QuestionAskBar';
import React, { useEffect, useState } from 'react';
import Columns from 'react-columns';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiGetGroupMember } from 'shared/api/group';
import { apiGetOrgMember } from 'shared/api/org';
import { CardCommunityMember } from '../../components/CardCommunityMember';

import './index.styles.scss';

export const GroupMembers = ({ selectedOrg, isLoadedAll, setIsLoaded }) => {
  const { communityScroll } = useSelector((state: RootState) => state.app);

  const [searchTxt, setSearchTxt] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const [arrUser, setArrUser] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [requestedCount, setRequestedCount] = useState(0);

  useEffect(() => {
    if (selectedOrg) {
      loadData();
    } else {
      setIsLoaded(true);
    }
  }, [selectedOrg, searchTxt]);

  useEffect(() => {
    loadData(false);
  }, [communityScroll]);

  const loadData = async (init = true) => {
    if (init) {
      setIsLoading(true);

      const res = await apiGetOrgMember({
        organisation: selectedOrg.id,
        query: searchTxt === '' ? undefined : searchTxt,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrUser(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setRequestedCount(res.requests);
      }

      setIsLoaded(true);
      setIsLoading(false);
    } else {
      if (hasMore && !isLoading) {
        setIsLoading(true);

        const res = await apiGetOrgMember({
          organisation: selectedOrg.id,
          query: searchTxt === '' ? undefined : searchTxt,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrUser((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoading(false);
      }
    }
  };

  const onSearch = (question) => {
    setSearchTxt(question);
  };

  const renderTop = () => {
    return (
      <div>
        <div className="row_align_items row_space_between">
          <div className="font-bold font18">Members</div>
          {requestedCount > 0 && (
            <div className="font-bold font12" style={{ color: '#FF3E9A' }}>
              Pending ({requestedCount})
            </div>
          )}
        </div>
        {renderAskBar()}
      </div>
    );
  };

  const renderAskBar = () => {
    return (
      <div className="row_align_items search_view">
        <QuestionAskBar
          onSearch={onSearch}
          onRealTimeSearch={onSearch}
          onFocus={() => {
            setIsSearchFocused(true);
          }}
          onClose={() => {
            setIsSearchFocused(false);
            setSearchTxt('');
          }}
          width="100%"
          height="56px"
          backgroundColor="#F1F6F9"
          placeholder="Search users here...."
          fontSize={20}
          searchIconClickable={true}
          showButton={false}
          showCloseButton={isSearchFocused}
        />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="mobile_org_members_body">
        <Columns columns={2} gap={'8px'} rootStyles={{ paddingBottom: 10 }}>
          {arrUser.map((item, index) => {
            return (
              <CardCommunityMember
                data={{
                  ...item,
                  name: item.creator_name,
                  image: item.creator_image,
                  id: item.creator_id
                }}
                type="org"
                key={`card_community_${index}`}
              />
            );
          })}
        </Columns>
      </div>
    );
  };

  if (selectedOrg) {
    return (
      <div className="mobile_org_members">
        {renderTop()}
        {renderBody()}
      </div>
    );
  } else {
    return <div className="mobile_org_members"></div>;
  }
};
