import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import React, { useEffect, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { apiGetUserFollowers, apiGetUserFollowing } from 'shared/api/user';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { MobileCardFollowUser } from './CardSearchedUser';

import './index.styles.scss';

interface Props {
  userId: any;
  open: boolean;
  name: any;
  following: any;
  follower: any;
  defaultTab: 'follower' | 'following';
  onClose: () => void;
  onUpdatedFollowingCount: (newValue) => void;
}

export const MobileProfileFollow = ({
  userId,
  open,
  name,
  follower,
  following,
  defaultTab,
  onClose,
  onUpdatedFollowingCount
}: Props) => {
  const [tab, setTab] = useState<'follower' | 'following'>(defaultTab);
  const [query, setQuery] = useState('');

  const [arrFollowing, setArrFollowing] = useState<Array<any>>([]);
  const [hasMoreFollowing, setHasMoreFollowing] = useState(true);
  const [lastIdFollowing, setLastIdFollowing] = useState(null);
  const [isLoadingFollowing, setIsLoadingFollowing] = useState(false);

  const [arrFollower, setArrFollower] = useState<Array<any>>([]);
  const [hasMoreFollower, setHasMoreFollower] = useState(true);
  const [lastIdFollower, setLastIdFollower] = useState(null);
  const [isLoadingFollower, setIsLoadingFollower] = useState(false);

  useEffect(() => {
    if (userId && open) {
      loadFollowings();
      loadFollowers();
    }
  }, [userId, open, query]);

  useEffect(() => {
    setTab(defaultTab);
  }, [defaultTab]);

  const loadFollowers = async (init = true) => {
    if (init) {
      setIsLoadingFollower(true);

      const res = await apiGetUserFollowers({
        userId,
        query: query,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrFollower(res.data);
        setHasMoreFollower(res.hasMore);
        setLastIdFollower(res.lastId);
      }

      setIsLoadingFollower(false);
    } else {
      if (hasMoreFollower && !isLoadingFollower) {
        setIsLoadingFollower(true);

        const res = await apiGetUserFollowers({
          userId,
          query: query,
          limit: 10,
          offset: lastIdFollower
        });
        if (res.success) {
          setArrFollower((prev) => [...prev, ...res.data]);
          setHasMoreFollower(res.hasMore);
          setLastIdFollower(res.lastId);
        }

        setIsLoadingFollower(false);
      }
    }
  };

  const loadFollowings = async (init = true) => {
    if (init) {
      setIsLoadingFollowing(true);

      const res = await apiGetUserFollowing({
        userId,
        query: query,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrFollowing(res.data);
        setHasMoreFollowing(res.hasMore);
        setLastIdFollowing(res.lastId);
      }

      setIsLoadingFollowing(false);
    } else {
      if (hasMoreFollowing && !isLoadingFollowing) {
        setIsLoadingFollowing(true);

        const res = await apiGetUserFollowing({
          userId,
          query: query,
          limit: 10,
          offset: lastIdFollowing
        });
        if (res.success) {
          setArrFollowing((prev) => [...prev, ...res.data]);
          setHasMoreFollowing(res.hasMore);
          setLastIdFollowing(res.lastId);
        }

        setIsLoadingFollowing(false);
      }
    }
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      if (tab === 'follower') {
        loadFollowers(false);
      } else {
        loadFollowings(false);
      }
    }
  };

  const onUnFollowed = (itemId) => {
    setArrFollowing((prev) =>
      prev.filter((item, index) => item.creator_id !== itemId)
    );

    onUpdatedFollowingCount(following - 1);
  };

  const renderHeader = () => {
    return (
      <div className="header_view row_align_items">
        <div onClick={onClose}>
          <BlackBackIcon />
        </div>
        <div className="font-bold font18">{name}</div>
      </div>
    );
  };

  const renderTab = () => {
    return (
      <div className="tab_header row_align_items">
        <div
          className={`tab_item font-bold font16`}
          style={{
            color: tab === 'follower' ? 'black' : 'rgba(0, 0, 0, 0.6)',
            borderBottom:
              tab === 'follower'
                ? '2px solid black'
                : '1px solid rgba(0, 0, 0, 0.2)'
          }}
          onClick={() => {
            setTab('follower');
          }}
        >{`${follower} followers`}</div>
        <div
          className={`tab_item font-bold font16`}
          style={{
            color: tab === 'following' ? 'black' : 'rgba(0, 0, 0, 0.3)',
            borderBottom:
              tab === 'following'
                ? '2px solid black'
                : '1px solid rgba(0, 0, 0, 0.2)'
          }}
          onClick={() => {
            setTab('following');
          }}
        >{`${following} following`}</div>
      </div>
    );
  };

  const renderSearchBox = () => {
    return (
      <div className="search_view">
        <SearchBox
          onSearch={setQuery}
          height="50px"
          enableShadow={true}
          fontSize={16}
        />
      </div>
    );
  };

  const renderContentView = () => {
    return (
      <div className="content_view" onScroll={handleScroll}>
        {tab === 'follower'
          ? arrFollower.map((item, index) => {
              return (
                <MobileCardFollowUser
                  data={item}
                  key={`card_follower_${index}`}
                />
              );
            })
          : arrFollowing.map((item, index) => {
              return (
                <MobileCardFollowUser
                  data={item}
                  key={`card_following_${index}`}
                  onUnFollowed={onUnFollowed}
                />
              );
            })}
      </div>
    );
  };

  return (
    <Sheet
      isOpen={open}
      className="custom_mobile_profile_follow_modal_body_modal"
      onClose={onClose}
    >
      <Sheet.Container className="mobile_profile_follow_modal_body">
        <Sheet.Content>
          <div className="mobile_profile_follow_modal">
            {renderHeader()}
            {renderTab()}
            {renderSearchBox()}
            {renderContentView()}
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
