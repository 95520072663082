import { MobileShareQuestionModal } from 'components/Modals/MobileShare/ShareQuestion';
import React, { useState } from 'react';

import './index.styles.scss';
import { useNavigate } from 'react-router-dom';
import { MobileFeedSideBar } from '../../SideBar';
import QuestionCircleIcon from 'assets/svgs/QuestionCircle';

export const CardFlipQuestionBody = ({ data, onFlip }) => {
  const [showShareModal, setShowShareModal] = useState(false);

  const renderHeader = () => {
    return (
      <div className="mark row_align_items">
        <div className="light_view">
          <QuestionCircleIcon />
        </div>
        <div
          className="font-bold font16"
          style={{ color: '#FF3E9A', marginLeft: 6 }}
        >
          Question
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="flip_question_body">
        <div className="feed_main_content">
          {data.images && data.images.startsWith('https') ? (
            <img src={data.images} className="flip_question_image" />
          ) : (
            <div></div>
          )}
          <div className="question_txt font-medium">{data.question}</div>
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="flip_question_bottom">
        <div className="flip_action_conatiner row_align_items">
          <div className="flip_btn font-bold font14" onClick={onFlip}>
            Flip
          </div>
        </div>
      </div>
    );
  };

  const renderShareModal = () => {
    return (
      <MobileShareQuestionModal
        question={data}
        show={showShareModal}
        onClose={() => {
          setShowShareModal(false);
        }}
      />
    );
  };

  return (
    <div className="mobile_card_flip_question">
      {renderHeader()}
      <div className="mobile_card_flip_question_main">{renderBody()}</div>
      {renderBottom()}
      {renderShareModal()}
    </div>
  );
};
