import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import CoinIcon from 'assets/svgs/CoinIcont';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiGetUserPoint } from 'shared/api/user';

import './index.styles.scss';
import { MobileMyPointBody } from './MyPointBody';

export const MobileMyPoint = () => {
  const navigate = useNavigate();

  const [arrData, setArrData] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [availablePoint, setAvailablePoint] = useState(0);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (init = true) => {
    if (init) {
      setIsLoading(true);
      setIsFirstLoading(true);
      const res = await apiGetUserPoint({
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setIsLoaded(true);
        setAvailablePoint(res.energy);
        setArrData(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }

      setIsFirstLoading(false);
      setIsLoading(false);
    } else {
      if (!isLoading && hasMore) {
        setIsLoading(true);
        const res = await apiGetUserPoint({
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrData((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoading(false);
      }
    }
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 0.5;

    if (bottom) {
      loadData(false);
    }
  };

  const renderHeader = () => {
    return (
      <div
        className="row_align_items row_space_between"
        style={{ margin: '0px 16px' }}
      >
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <BlackBackIcon />
        </div>
        <div className="font-bold font16">My Points</div>
        <div style={{ marginRight: 40 }} />
      </div>
    );
  };

  const renderAvailablePoint = () => {
    return (
      <div className="point_available">
        <div className="point_available_icon">
          <CoinIcon />
        </div>
        <div style={{ marginLeft: 16, flex: 1 }}>
          <div className="font-regular font14" style={{ opacity: 0.8 }}>
            Available Points
          </div>
          <div className="font-bold font18" style={{ marginRight: 16 }}>
            {availablePoint} pts
          </div>
        </div>
      </div>
    );
  };

  const renderDivider = () => {
    return <div className="point_divider"></div>;
  };

  const renderBody = () => {
    return (
      <MobileMyPointBody
        data={arrData}
        isLoaded={isLoaded}
        isLoading={isFirstLoading}
      />
    );
  };

  return (
    <div className="mobile_my_point" onScroll={handleScroll}>
      {renderHeader()}
      {renderAvailablePoint()}
      {renderDivider()}
      {renderBody()}
    </div>
  );
};
