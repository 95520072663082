import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BG_SUPPORT_AI from 'assets/pngs/ic_supporter.png';

import './index.styles.scss';
import { MobileChatIntroModal } from 'components/Modals/Mobile/ChatIntroModal';
import { RootState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import { apiGetChatRoomNumber } from 'shared/api/chat';

interface MobileCardHeaderOrgChatProps {
  index: any;
  data: any;
}

export const MobileCardHeaderUserChat = ({
  index,
  data
}: MobileCardHeaderOrgChatProps) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [roomNumber, setRoomNumber] = useState(0);
  const [showIntroModal, setShowIntroModal] = useState(false);

  useEffect(() => {
    if (index === 0 && userInfo) {
      loadRoomNumber();
    } else {
      setRoomNumber(data.total_rooms);
    }
  }, [userInfo]);

  const loadRoomNumber = async () => {
    const res = await apiGetChatRoomNumber({
      ai_id: userInfo?.id,
      ai_type: 'user'
    });

    if (res.success) {
      setRoomNumber(res.total_count);
    }
  };

  const onClick = (e) => {
    if (index === 0) {
      navigate(`/conversation/${userInfo?.id}/null`);
    } else {
      setShowIntroModal(true);
    }
  };

  const renderIntroModal = () => {
    return (
      <MobileChatIntroModal
        open={showIntroModal}
        aiInfo={data}
        onClose={() => {
          setShowIntroModal(false);
        }}
      />
    );
  };

  const renderImage = () => {
    if (index === 0) {
      return (
        <>
          {userInfo?.image && userInfo?.image.startsWith('https') ? (
            <img src={userInfo?.image} className="item_image" />
          ) : (
            <DefaultAvatarIcon size={40} />
          )}
        </>
      );
    } else {
      return (
        <>
          {data.ai_id === -1 ? (
            <img src={BG_SUPPORT_AI} className="item_image" />
          ) : data.ai_image && data.ai_image.startsWith('https') ? (
            <img src={data.ai_image} className="item_image" />
          ) : (
            <DefaultAvatarIcon size={40} />
          )}
        </>
      );
    }
  };

  return (
    <div
      className="mobile_card_header_chat_user row_align_items"
      onClick={onClick}
    >
      {renderImage()}
      <div className="item_info">
        <div className="item_name font-bold font14">
          {index === 0 ? 'Personal AI' : data.ai_name}
        </div>
        <div className="item_sub_info font-medium font12">{`${roomNumber} rooms`}</div>
      </div>
      {renderIntroModal()}
    </div>
  );
};
