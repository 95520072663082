import CloseIcon from 'assets/svgs/CloseIcon';
import React, { useState } from 'react';
import { apiChatVote } from 'shared/api/chat';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import './index.styles.scss';

interface ConversationVoteModalProps {
  show: boolean;
  isMobile?: boolean;
  roomId: any | undefined;
  roomInfo: any;
  onSuccess?: any;
  onClose: () => void;
}

export const ConversationVoteModal = ({
  show,
  roomId,
  roomInfo,
  isMobile = false,
  onClose,
  onSuccess
}: ConversationVoteModalProps) => {
  const [isVoting, setIsVoting] = useState(false);
  const [isSatisfied, setIsSatisfied] = useState<0 | 1>(1);

  const onSubmit = async () => {
    setIsVoting(true);

    const res = await apiChatVote({
      room: roomId,
      vote: isSatisfied
    });

    if (res.success) {
      onSuccess();
    }

    setIsVoting(false);
  };

  return (
    <PrimaryModal
      show={show}
      width={isMobile ? 'calc(100% - 32px)' : 423}
      onClose={onClose}
    >
      <div className="modal_vote_group">
        <div
          className="align-vertical-center clickable modal_vote_group_close"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon opacity={0.4} />
        </div>
        <div className="modal_vote_group_header">
          <div className="title font-bold font18">Rate your experience</div>
        </div>
        <div className="modal_vote_group_body">
          <div className="vote_section">
            <div className="satisfied">
              <div
                className={`thumbs_down font32 ${
                  !isSatisfied ? 'selected' : 'non_selected'
                }`}
                onClick={() => setIsSatisfied(0)}
              >
                👎
                {isSatisfied == 1 && <div className="bacground_effect"></div>}
              </div>
              <div className="font16 font-regular">Not Satisfied</div>
            </div>
            <div className="satisfied">
              <div
                className={`thumbs_up font32 text-dark ${
                  isSatisfied ? 'selected' : 'non_selected'
                }`}
                onClick={() => setIsSatisfied(1)}
              >
                👍
                {isSatisfied == 0 && <div className="bacground_effect"></div>}
              </div>
              <div className="font16  font-regular">Satisfied</div>
            </div>
          </div>
          <div className="font16 font-regular sub_description">
            Your input will remain anonymous
          </div>
          <div className="btn_create">
            <PrimaryButton
              label="Submit"
              isLoading={isVoting}
              onPressed={onSubmit}
            />
          </div>
        </div>
      </div>
    </PrimaryModal>
  );
};
