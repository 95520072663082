import CloseIcon from 'assets/svgs/CloseIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import UCheckIcon from 'assets/svgs/UCheckIcon';
import UserFollowerIcon from 'assets/svgs/UserFollowerIcon';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiAddSuggestionSearch } from 'shared/api/suggest';
import { apiUserFollow, apiUserUnFollow } from 'shared/api/user';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import './index.styles.scss';

interface Props {
  data: any;
  onUnFollowed?: (userId) => void;
}

export const MobileCardFollowUser = ({ data, onUnFollowed }: Props) => {
  const navigate = useNavigate();

  const [isFollowing, setIsFollowing] = useState(false);
  const [followStatus, setFollowingStatus] = useState<'false' | 'true'>(
    data.isFollowed
  );

  const onClick = async () => {
    navigate(`/profile/${data.creator_id}`);
  };

  const onFollowAction = (e) => {
    e.stopPropagation();

    if (followStatus === 'false') {
      onFollow();
    } else {
      onUnFollow();
    }
  };

  const onFollow = async () => {
    if (!isFollowing) {
      setIsFollowing(true);

      const res = await apiUserFollow({
        followingId: data.creator_id,
        type: 'user'
      });

      if (res.success) {
        setFollowingStatus('true');
      }

      setIsFollowing(false);
    }
  };

  const onUnFollow = async () => {
    if (!isFollowing) {
      setIsFollowing(true);

      const res = await apiUserUnFollow({
        following: data.creator_id,
        type: 'user'
      });
      if (res.success) {
        // onUnFollowed && onUnFollowed(data.creator_id);
        setFollowingStatus('false');
      }

      setIsFollowing(false);
    }
  };

  const renderFollowerInfo = () => {
    return (
      <div className="follower_info row_align_items">
        <UserFollowerIcon size={18} color={'rgba(0, 0, 0, 0.4)'} />
        <div className="font-regular font12">{`${data.followers} follower`}</div>
      </div>
    );
  };

  const renderFollowBtn = () => {
    if (followStatus === 'false') {
      return (
        <div
          className="follow_btn font-bold font12 clickable row_align_items"
          onClick={onFollowAction}
        >
          {isFollowing ? <LoadingCircular size={12} color="white" /> : `Follow`}
        </div>
      );
    } else if (followStatus === 'true') {
      return (
        <div
          className="requested_btn font-bold font12 clickable"
          onClick={onFollowAction}
        >
          {isFollowing ? (
            <LoadingCircular size={12} color="main" />
          ) : (
            `Unfollow`
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <div
      className="mobile_community_card_searched_user row_align_items row_space_between"
      onClick={onClick}
    >
      <div className="row_align_items" style={{ gap: 12 }}>
        {data.creator_image && data.creator_image.startsWith('https') ? (
          <img src={data.creator_image} className="user_image" />
        ) : (
          <DefaultAvatarIcon size={50} />
        )}
        <div>
          <div className="user_name font-bold font16">{data.creator_name}</div>
          {renderFollowerInfo()}
        </div>
      </div>
      {renderFollowBtn()}
    </div>
  );
};
