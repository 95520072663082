import React, { useMemo, useState } from 'react';
import moment from 'moment';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './index.styles.scss';
import { MobileQuestionActionModal } from 'components/Modals/Mobile/QuestionActionModal';
import ShareIcon from 'assets/svgs/ShareIcon';
import { useNavigate } from 'react-router-dom';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import HeartIcon from 'assets/svgs/HeartIcon';
import HeartBreakIcon from 'assets/svgs/HeartBreakIcon';
import { apiContentVoteAI } from 'shared/api/content';
import { getArrFromString } from 'shared/utils/utils';
import { MobileMedia } from './CardMedia';
import { MobileShareMediaModal } from 'components/Modals/MobileShare/ShareMedia';

interface Props {
  data: any;
}

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

export const MobileCardMedia = ({ data }: Props) => {
  const navigate = useNavigate();

  const [showActionModal, setShowActionModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const [userVote, setUserVote] = useState(data.user_like);
  const [agree, setAgree] = useState(data.likes);
  const [doubt, setDoubt] = useState(data.dislikes);

  const onVote = async (vote: number) => {
    const res = await apiContentVoteAI({
      item_id: data.item_id,
      item_type: data.item_type,
      like: vote,
      remove: userVote === vote ? true : false
    });

    if (res.success) {
      const voteData = res.voting;
      setUserVote(voteData.user_like);
      setAgree(voteData.likes);
      setDoubt(voteData.dislikes);
    }
  };

  const onViewProfile = () => {
    if (data.creator_id) {
      navigate(`/profile/${data.creator_id}`);
    }
  };

  const arrMedia = useMemo(() => {
    if (data.images) {
      const arrImage = getArrFromString(data.images, ',');
      const arrTypes = getArrFromString(data.text, ',');

      return arrImage.map((item, index) => {
        return {
          url: arrImage[index],
          type: arrTypes[index]
        };
      });
    }
  }, [data]);

  const renderTopView = () => {
    return (
      <div className="media_top_view row_space_between">
        <div className="row_align_items">
          {data.creator_image && data.creator_image.startsWith('https') ? (
            <img
              src={data.creator_image}
              className="user_avatar"
              onClick={onViewProfile}
            />
          ) : (
            <div className="user_avatar" onClick={onViewProfile}></div>
          )}
          <div style={{ marginLeft: 8 }}>
            <div className="font-bold font10" onClick={onViewProfile}>
              {data.creator_name}
            </div>
            <div className="font-medium font10" style={{ opacity: 0.6 }}>
              {moment(data.timestamp).format('DD MMM')}
            </div>
          </div>
        </div>
        <div className="row_align_items">
          <div className="detail_btn" onClick={() => setShowShareModal(true)}>
            <ShareIcon size={16} color="rgba(0, 0, 0, 0.3)" />
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="media_body">
        <Slider {...settings} swipe={false} dots={true}>
          {arrMedia?.map((item, index) => {
            return (
              <MobileMedia
                key={`mobile_card_media_feed_${index}`}
                data={item}
              />
            );
          })}
        </Slider>
      </div>
    );
  };

  const renderRightAction = () => {
    return (
      <div className="right_action_view">
        <div className="action_item">
          <AudioPlayIcon color="#FF3E9A" />
        </div>
        <div className="vote_divider"></div>
        <div
          className="action_item"
          onClick={() => onVote(1)}
          style={{ marginTop: 16 }}
        >
          <HeartIcon
            color={userVote === 1 ? '#FF3E9A' : 'rgba(0, 0, 0, 0.4)'}
          />
          <div
            className="font-medium font16"
            style={{ color: userVote === 1 ? '#FF3E9A' : 'rgba(0, 0, 0, 0.4)' }}
          >
            {agree}
          </div>
        </div>
        <div
          className="action_item"
          onClick={() => onVote(0)}
          style={{ marginTop: 16 }}
        >
          <HeartBreakIcon
            color={userVote === 0 ? '#000000' : 'rgba(0, 0, 0, 0.4)'}
          />
          <div
            className="font-medium font16"
            style={{ color: userVote === 0 ? '#000000' : 'rgba(0, 0, 0, 0.4)' }}
          >
            {doubt}
          </div>
        </div>
      </div>
    );
  };

  const renderActionModal = () => {
    return (
      <MobileQuestionActionModal
        open={showActionModal}
        onClose={() => {
          setShowActionModal(false);
        }}
        onSelected={(type) => {
          setShowActionModal(false);

          if (type === 'share') {
            setShowShareModal(true);
          }
        }}
      />
    );
  };

  const renderShareModal = () => {
    return (
      <MobileShareMediaModal
        question={data}
        show={showShareModal}
        onClose={() => {
          setShowShareModal(false);
        }}
      />
    );
  };

  return (
    <div className="mobile_card_media">
      {renderTopView()}
      {renderBody()}
      {/* {renderRightAction()} */}
      {renderActionModal()}
      {renderShareModal()}
    </div>
  );
};
