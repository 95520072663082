import React from 'react';

function HeartBreakIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '24'}
      height={props.size ?? '24'}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.color ?? '#FF3E9A'}
        d="M20.16 4.607a6.27 6.27 0 00-8.16-.61 6.27 6.27 0 00-8.16 9.48l7.45 7.46a1 1 0 001.42 0l7.45-7.46a6.273 6.273 0 000-8.87zm-1.41 7.45L12 18.807l-6.75-6.75a4.26 4.26 0 015.54-6.45l-1.71 4a1 1 0 000 .83 1 1 0 00.65.53l2.77.7-1.4 2.89a1 1 0 101.8.88l2-4a1 1 0 00-.275-1.207 1.051 1.051 0 00-.395-.203l-2.83-.71 1.45-3.39a4.26 4.26 0 115.92 6.13h-.02z"
      ></path>
    </svg>
  );
}

export default HeartBreakIcon;
