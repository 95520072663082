import CloseIcon from 'assets/svgs/CloseIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiChatGetAi } from 'shared/api/chat';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import { CardPersonAi } from './CardPersonAi';

import './mobile.styles.scss';

interface SelectPersonAiModalProps {
  show: boolean;
  aiType: string;
  selectedAi: any;
  onClose: () => void;
  onSelected: (aiInfo) => void;
}

export const MobileSelectPersonAiModal = ({
  show,
  aiType,
  selectedAi,
  onClose,
  onSelected
}: SelectPersonAiModalProps) => {
  const [arrAi, setArrAi] = useState([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      loadAi();
    } else {
      setArrAi([]);
    }
  }, [show]);

  const loadAi = async (init = true) => {
    setIsLoading(true);
    if (!isLoading) {
      const res = await apiChatGetAi({
        ai_type: aiType,
        query: '',
        limit: 100,
        offset: 0
      });

      if (res.success) {
        setArrAi(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }
    }
    setIsLoading(false);
  };

  const renderSubHeader = () => {
    if (aiType === 'user') {
      return <div />;
    } else if (aiType === 'group') {
      return (
        <div className="modal_mobile_select_ai_person_sub_title font-bold font14">
          My Groups
        </div>
      );
    } else if (aiType === 'organisation') {
      return (
        <div className="modal_mobile_select_ai_person_sub_title font-bold font14">
          My Schools
        </div>
      );
    }
  };

  const renderBody = () => {
    if (!isLoading)
      return (
        <div className="modal_select_ai_person_body">
          {arrAi.map((item, index) => {
            return (
              <CardPersonAi
                key={`select_card_person_ai_${index}`}
                data={item}
                selected={selectedAi}
                onSelected={onSelected}
              />
            );
          })}
        </div>
      );
    else return <LoadingBarAnimation />;
  };

  const renderBottom = () => {
    return (
      <div
        className="btn_to_creators clickable"
        onClick={() => navigate('/creators')}
      >
        <PlusIcon /> Find more creators
      </div>
    );
  };

  return (
    <PrimaryModal
      show={show}
      onClose={onClose}
      width={'calc(100% - 32px)'}
      style={{
        padding: '16px'
      }}
    >
      <div className="modal_mobile_select_ai_person">
        <div className="modal_select_ai_person_header row_align_items">
          <div className="clickable" onClick={onClose}>
            <CloseIcon />
          </div>
          <div className="font-bold font18" style={{ marginLeft: 16 }}>
            {aiType === 'user'
              ? 'Select Personal AI'
              : aiType === 'group'
              ? 'Select Group AI'
              : 'Select School AI'}
          </div>
        </div>
        {renderSubHeader()}
        {renderBody()}
      </div>
    </PrimaryModal>
  );
};
