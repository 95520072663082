import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import CoinIcon from 'assets/svgs/CoinIcont';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useState } from 'react';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import moment from 'moment';

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';

import Download1Icon from 'assets/svgs/Download1Icon';
import WhatsappIcon from 'assets/svgs/WhatsappIcon';
import TelegramIcon from 'assets/svgs/TelegramIcon';
import InstagramIcon from 'assets/svgs/InstagramIcon';
import TwitterIcon from 'assets/svgs/TwitterIcon';
import TikTokIcon from 'assets/svgs/TikTokIcon';

import './index.styles.scss';
import { getImageFromHtml } from 'shared/utils/utils';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { apiQAGetShareLink } from 'shared/api/qa';
import { apiGetTopicPercentage, apiGetUserSharingQR } from 'shared/api/user';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';

interface ModalProps {
  show: boolean;
  question?: any;
  onClose: () => void;
}

export const MobileShareAnswerModal = ({
  show,
  question,
  onClose
}: ModalProps) => {
  const { uploadBase64 } = useIPFS();
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [shareUrl, setShareUrl] = useState('');
  const [qrCodeUrl, setQRCodeUrl] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);

  const [percentInfo, setPercentInfo] = useState<any>(null);

  useEffect(() => {
    if (show && question) {
      getSharingQRcode();
      loadPercentInfo();
    }
  }, [show, question]);

  const loadPercentInfo = async () => {
    const res = await apiGetTopicPercentage({
      userId: question.creator_id
    });
    setPercentInfo(res.data);
  };

  const getSharingQRcode = async () => {
    const res = await apiGetUserSharingQR();
    if (res.success) {
      setQRCodeUrl(res.qr_url);
      initShareUrl();
    }
  };

  const initShareUrl = async () => {
    const base64Image = await getImageFromHtml(
      `mobile_share_body_image_${question!.item_id ?? question!.id}_answer`
    );
    const url = await uploadBase64(base64Image, 'image/png', '');

    const res = await apiQAGetShareLink({
      url: url,
      item_id: question!.item_id ?? question!.id,
      type: 'statement'
    });

    if (res.success) {
      setShareUrl(res.sharing_url);
    }
  };

  const onShare = async (type: 'download') => {
    if (!isDownloading)
      if (type === 'download') {
        setIsDownloading(true);
        let base64Image = await getImageFromHtml(
          `mobile_share_body_image_${question!.item_id ?? question!.id}_answer`
        );

        const url = await uploadBase64(base64Image, 'image/png', '');

        await onDownloadFile(url);
        setIsDownloading(true);
      }
  };

  const onDownloadFile = async (fileUrl) => {
    if (!isDownloading) {
      setIsDownloading(true);

      fetch(fileUrl)
        .then((response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'share_image';
            alink.click();
          });
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  };

  const renderHeader = () => {
    return (
      <div className="share_header row_align_items">
        <div onClick={onClose}>
          <BlackBackIcon />
        </div>
        <div className="header_title font-bold font18">Share to Social</div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="share_body">
        <div
          className="share_main_body"
          id={`mobile_share_body_image_${
            question!.item_id ?? question!.id
          }_answer`}
        >
          {renderTopView()}
          {renderQuestion()}
          {renderInfoView()}
        </div>
      </div>
    );
  };

  const renderTopView = () => {
    return (
      <div className="row_space_between">
        <div className="row_align_items">
          <div className="align-vertical-center">
            {userInfo?.image && userInfo?.image.startsWith('https') ? (
              <img
                src={userInfo?.image}
                style={{ width: 32, height: 32, borderRadius: 32 }}
              />
            ) : (
              <DefaultAvatarIcon size={32} />
            )}
          </div>
          <div style={{ marginLeft: 6 }}>
            <div className="font-bold font12 text_no_wrap">
              {userInfo?.name}
            </div>
            {percentInfo && renderPercentInfo()}
          </div>
        </div>
        <div className="qr_view">
          <img
            src={qrCodeUrl}
            style={{ width: '100%', height: '100%' }}
            crossOrigin="anonymous"
          />
        </div>
      </div>
    );
  };

  const renderPercentInfo = () => {
    return (
      <div
        className="font-regular font10 text_no_wrap"
        style={{ marginTop: 4 }}
      >
        {`Top ${percentInfo!.percentile}% in ${percentInfo!.topic_emoji} ${
          percentInfo!.topic_name
        }`}
      </div>
    );
  };

  const renderQuestion = () => {
    return (
      <div>
        <div className="question font-bold font22">{question?.question}</div>
        <div
          className="question_answer font-regular font14"
          style={{ margin: '12px 0px' }}
        >
          {question?.ai_answer}
        </div>
      </div>
    );
  };

  const renderInfoView = () => {
    return (
      <div className="row_space_between" style={{ marginTop: 8 }}>
        <div className="row_align_items" style={{ gap: 12 }}>
          <div className="row_align_items" style={{ gap: 4 }}>
            <CoinIcon size={16} />
            <div className="font-bold font12">{`${
              question?.points ?? 0
            } pts`}</div>
          </div>
          {question?.views && (
            <div className="row_align_items" style={{ gap: 4 }}>
              <div className="font16">👀</div>
              <div className="font-bold font14">{question?.views}</div>
            </div>
          )}
        </div>
        <div
          className="font-regular font12"
          style={{ color: 'rgba(0, 0, 0, 0.4)' }}
        >
          {moment(question?.timestamp).format('DD MMM')}
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="share_bottom">
        <div className="statement_link_bar_items">
          <div
            className="statement_link_bar_items_item download_itm clickable"
            onClick={() => onShare('download')}
          >
            {isDownloading ? (
              <LoadingCircular size={20} color={'white'} />
            ) : (
              <Download1Icon />
            )}
          </div>
          <div className="font10">Download</div>
        </div>
        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <WhatsappShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Whatsapp_itm">
                <WhatsappIcon />
              </div>
              <div className="font10">Whatsapp</div>
            </div>
          </WhatsappShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TelegramShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Telegram_itm ">
                <TelegramIcon />
              </div>
              <div className="font10">Telegram</div>
            </div>
          </TelegramShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TwitterShareButton title={''} url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Twitter_itm">
                <TwitterIcon />
              </div>
              <div className="font10">Twitter</div>
            </div>
          </TwitterShareButton>
        )}
        {!!question?.sharing && (
          <div className="statement_link_bar_items">
            <div className="statement_link_bar_items_item Tiktok_itm">
              <TikTokIcon color={'#333333'} size={48} />
            </div>
            <div className="font10">TikTok</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <PrimaryModal
      show={show}
      width={'100%'}
      style={{ width: '100%', height: '100%', borderRadius: 0, padding: 0 }}
      onClose={onClose}
    >
      <div className="mobile_share_answer_modal">
        {renderHeader()}
        {renderBody()}
        {renderBottom()}
      </div>
    </PrimaryModal>
  );
};
