import React, { useEffect } from 'react';
import { Navigate, Routes } from 'react-router-dom';

export const TmpRoutes = () => {
  useEffect(() => {
    window.open(process.env.REACT_APP_REDIRECT_URL, '_self');
  }, []);

  return <></>;
};
