import AudioPauseIcon from 'assets/svgs/AudioPauseIcon';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import FilterCheckIcon from 'components/Modals/Mobile/FeedFilterModal/TypeFilter/svgs/FilterCheckIcon';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { useAudioContext } from 'shared/contexts/AudioContext';
import ReactHowler from 'react-howler';

import './index.styels.scss';
import { TypeLoadingText } from 'shared/ui-kit/TypeLoadingText';
import { removeFirstBlank } from 'shared/utils/utils';

interface Props {
  isSelected: boolean;
  isSharing: boolean;
  data: any;
  onShare: () => void;
  onClick: (item) => void;
}

export const MobileIndividualCardChat = ({
  data,
  isSelected,
  isSharing,
  onShare,
  onClick
}: Props) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [isNewMessage, setIsNewMessage] = useState(data.is_new);
  const [isPlaying, setIsPlaying] = useState(false);
  const { playingAudio, setPlayingAudio } = useAudioContext();

  const audio = useMemo(() => {
    return data.audio;
  }, [data.audio]);

  useEffect(() => {
    setIsNewMessage(data.is_new ? data.is_new : false);
  }, [data.is_new]);

  useEffect(() => {
    if (!!audio && (playingAudio ?? '') === audio) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [playingAudio]);

  const togglePlayback = () => {
    if (isPlaying) {
      setPlayingAudio(null);
    } else {
      if (playingAudio) {
        setPlayingAudio(null);
      }
      setPlayingAudio(audio);
    }
  };

  const onClickedMessage = () => {
    if (isSharing) {
      onClick(data);
    } else {
      onShare();
    }
  };

  const renderOwnerHeader = () => {
    return (
      <div className="mobile_individual_chat_card_header">
        {renderAudioIcon()}
        <div className="user_image">
          {userInfo?.image && userInfo?.image.startsWith('https') ? (
            <img src={userInfo?.image} className="creator_image" />
          ) : (
            <DefaultAvatarIcon size={24} />
          )}
        </div>
        <div className="font-bold font12">{'You'}</div>
      </div>
    );
  };

  const renderOtherHeader = () => {
    return (
      <div className="mobile_individual_chat_card_header">
        <div className="user_image">
          {data.ai_type === 'general' ? (
            <RobotIcon size={24} />
          ) : data.ai_image ? (
            <img src={data.ai_image} className="creator_image" />
          ) : (
            <DefaultAvatarIcon />
          )}
        </div>
        <div className="font-bold font12">
          {data.ai_type === 'general' ? `General AI` : `${data.ai_name} AI`}
        </div>
        {renderAudioIcon()}
      </div>
    );
  };

  const renderOwnerMessage = () => {
    return (
      <div
        className="own_message font-regular font14"
        onClick={onClickedMessage}
      >
        {removeFirstBlank(data.message)}
      </div>
    );
  };

  const renderOtherMessage = () => {
    return (
      <pre
        className="other_message font-regular font14"
        onClick={onClickedMessage}
      >
        {isNewMessage ? (
          <div className="font-regular font14">
            <TypeLoadingText
              text={
                data.message?.startsWith('\n')
                  ? '\n'.concat(data.message.slice(1))
                  : data.message
              }
              fontSize={'font14'}
              speed={20}
              onFinished={() => {
                data.is_new = false;
                setIsNewMessage(false);
              }}
            />
          </div>
        ) : (
          <pre
            className="font-regular font14"
            style={{ marginBottom: 0 }}
            onClick={onShare}
          >
            {removeFirstBlank(
              data.message?.startsWith('\n')
                ? '\n'.concat(data.message.slice(1))
                : data.message
            )}
          </pre>
        )}
      </pre>
    );
  };

  const renderSummaryCard = () => {
    return (
      <div className="summary_card">
        <div className="font-bold font14" style={{ color: '#FF3E9A' }}>
          Summary of the conversation
        </div>
        <pre className="summary_message font-regular font14">
          {data.message ?? 'No summary available'}
        </pre>
      </div>
    );
  };

  const renderAudioIcon = () => {
    if (!!audio) {
      return (
        <div className="audio_play_btn align-center" onClick={togglePlayback}>
          {isPlaying ? (
            <AudioPauseIcon size={12} />
          ) : (
            <AudioPlayIcon color="#FF3E9A" size={12} />
          )}
          {isPlaying && (
            <ReactHowler
              src={audio}
              playing={isPlaying}
              loop={false}
              html5={true} // Use HTML5 Audio API
              onLoad={() => {}}
              onEnd={() => setPlayingAudio(null)}
            />
          )}
        </div>
      );
    } else {
      return <div />;
    }
  };

  const renderSelectedStatus = () => {
    return (
      <div className="mobile_individual_select_btn" onClick={onClickedMessage}>
        {isSelected ? (
          <FilterCheckIcon />
        ) : (
          <div className="default_mark"></div>
        )}
      </div>
    );
  };

  if (data.type === 'summary' && data.ai_type === 'system') {
    return (
      <div className="mobile_individual_card_container">
        <div className="mobile_individual_chat_card">{renderSummaryCard()}</div>
      </div>
    );
  } else {
    return (
      <div className="mobile_individual_card_container row_align_items">
        {isSharing && renderSelectedStatus()}
        <div
          className={`mobile_individual_chat_card ${
            data.is_ai === 0 ? 'own_message_card' : ''
          }`}
        >
          {data.is_ai === 0 ? renderOwnerHeader() : renderOtherHeader()}
          {data.is_ai === 0 ? renderOwnerMessage() : renderOtherMessage()}
        </div>
      </div>
    );
  }
};
