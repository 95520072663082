import * as React from 'react';

import './index.styles.scss';

export const CardNormalMainTopicItem = ({ item, isSelected, onClicked }) => {
  return (
    <div className="mobile_card_main_topic_item" onClick={onClicked}>
      <div style={{ fontSize: 20 }}>{item.topic_emoji}</div>
      <div className="main_topic_name font-medium font14">
        {item.topic_name}
      </div>
    </div>
  );
};
