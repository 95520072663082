import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import HeartFulledIcon from 'assets/svgs/HeartFulledIcon';
import HeartIcon from 'assets/svgs/HeartIcon';
import LanguageIcon from 'assets/svgs/LanguageIcon';
import ReplyIcon from 'assets/svgs/ReplyIcon';
import { MobileQuestionActionModal } from 'components/Modals/Mobile/QuestionActionModal';
import { MobileShareArticleModal } from 'components/Modals/MobileShare/ShareArticle';
import { MobileShareDidKnowModal } from 'components/Modals/MobileShare/ShareDidKnow';
import { MobileShareMediaModal } from 'components/Modals/MobileShare/ShareMedia';
import { MobileShareAnswerModal } from 'components/Modals/MobileShare/ShareAnswer';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiContentVoteAI } from 'shared/api/content';

import './index.styles.scss';
import { useAudioContext } from 'shared/contexts/AudioContext';
import AudioPauseIcon from 'assets/svgs/AudioPauseIcon';
import ReactHowler from 'react-howler';
import FullScreenIcon from 'assets/svgs/FullScreenIcon';
import { getArrFromString } from 'shared/utils/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import profileAction from 'redux/profile/actions';
import appAction from 'redux/app/action';
import { apiEditSocialHandle } from 'shared/api/user';
import { RootState } from 'redux/interfaces';
import { MobileShareChatModal } from 'components/Modals/MobileShare/ShareChat';

interface Props {
  data: any;
  onViewPreview?: () => void;
  onUpdateLike?: (item, likeCount, isUserLike) => void;
}

export const MobileFeedSideBar = ({
  data,
  onViewPreview,
  onUpdateLike
}: Props) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const { user, loginWithPopup, logout } = useAuth0();
  const dispatch = useDispatch();
  const { playingAudio, setPlayingAudio, setPlayingAudioIndex } =
    useAudioContext();

  const [userVote, setUserVote] = useState(data.user_like);
  const [agree, setAgree] = useState(data.likes);
  const [doubt, setDoubt] = useState(data.dislikes);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const [currentTrack, setCurrentTrack] = useState(0);
  const [selectedSocial, setSelectedSocial] = useState<any>('');

  useEffect(() => {
    if (user && user.sub?.includes(selectedSocial)) {
      onSave();
    }
  }, [user]);

  const arrExistedAudios = useMemo(() => {
    const tmpArr = getArrFromString(data.chat_ai_audios, ';;;');

    return tmpArr.filter((item, index) => item && !!item);
  }, [data]);

  useEffect(() => {
    if (data.item_type === 'chat') {
      if (playingAudio && arrExistedAudios.includes(String(playingAudio))) {
        setIsPlaying(true);
      } else {
        setIsPlaying(false);
      }
    } else {
      if ((playingAudio ?? '') === data.audio) {
        setIsPlaying(true);
      } else {
        setIsPlaying(false);
      }
    }
  }, [playingAudio, data, arrExistedAudios]);

  useEffect(() => {
    if (data) {
      setUserVote(data.user_like);
      setAgree(data.likes);
      setDoubt(data.dislikes);
    }
  }, [data]);

  useEffect(() => {
    setPlayingAudioIndex(currentTrack);
  }, [currentTrack]);

  const onVote = async () => {
    const vote = !userVote ? 1 : userVote === 0 ? 1 : 0;

    const res = await apiContentVoteAI({
      item_id: data.item_id,
      item_type:
        data.item_type === 'article' || data.item_type === 'file'
          ? 'dump'
          : data.item_type,
      like: vote,
      remove: userVote === vote ? true : false
    });

    if (res.success) {
      const voteData = res.voting;
      setUserVote(voteData.user_like);
      setAgree(voteData.likes);
      setDoubt(voteData.dislikes);
      onUpdateLike && onUpdateLike(data, voteData.likes, voteData.user_like);
    }
  };

  const onViewFile = () => {
    window.open(data.link_url);
  };

  const onPlayAudio = () => {
    if (isPlaying) {
      setPlayingAudio(null);
    } else {
      if (playingAudio) {
        setPlayingAudio(null);
      }
      setPlayingAudio(data.audio);
    }
  };

  const onPlayMultiAudio = () => {
    if (isPlaying) {
      setIsPlaying(false);
      setPlayingAudio(null);
      setPlayingAudioIndex(null);
    } else {
      if (arrExistedAudios.length > 0) {
        setIsPlaying(true);
        setCurrentTrack(0);
        setPlayingAudio(arrExistedAudios[0]);
      }
    }
  };

  const onHandleMultiAuioEnd = () => {
    if (currentTrack < arrExistedAudios.length - 1) {
      setCurrentTrack(currentTrack + 1);
      setPlayingAudio(arrExistedAudios[currentTrack + 1]);
    } else {
      setCurrentTrack(0);
      setPlayingAudio(null);
      setPlayingAudioIndex(null);
    }
  };

  const onConnectSocial = async (socialType) => {
    try {
      loginWithPopup({
        authorizationParams: {
          connection: socialType === 'tiktok' ? 'TikTok' : socialType,
          access_type: "offline",
          scope: "offline_access"
        }
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const onSave = async () => {
    dispatch(profileAction.connectingSocial());

    var params = {
      social_id: user?.sub,
      selectedSocial,
      handle: selectedSocial === 'instagram' ? user?.nickname : user?.name
    };

    const res = await apiEditSocialHandle(params);

    if (res.success) {
      dispatch(appAction.refreshMobileSocialMedia());
      if (userInfo) {
        dispatch(profileAction.loadUserInfo(userInfo?.id));
      }
    }
  };

  const renderPlayIcon = () => {
    if (data.item_type === 'chat') {
      return (
        <div className="item_container" onClick={onPlayMultiAudio}>
          {isPlaying ? (
            <AudioPauseIcon color={'black'} size={26} opacity={1} />
          ) : (
            <AudioPlayIcon color={'black'} size={26} opacity={1} />
          )}
          {data.item_type === 'chat' && isPlaying && (
            <ReactHowler
              src={arrExistedAudios[currentTrack]}
              playing={isPlaying}
              loop={false}
              html5={true} // Use HTML5 Audio API
              onLoad={() => setIsLoaded(true)}
              onEnd={() => {
                onHandleMultiAuioEnd();
              }}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className="item_container" onClick={onPlayAudio}>
          {isPlaying ? (
            <AudioPauseIcon color={'black'} size={26} opacity={1} />
          ) : (
            <AudioPlayIcon color={'black'} size={26} opacity={1} />
          )}
          {isPlaying && (
            <ReactHowler
              src={data.audio}
              playing={isPlaying}
              loop={false}
              html5={true} // Use HTML5 Audio API
              onLoad={() => setIsLoaded(true)}
              onEnd={() => setPlayingAudio(null)}
            />
          )}
        </div>
      );
    }
  };

  const renderLikeIcon = () => {
    return (
      <div className="item_container" onClick={onVote}>
        <div className="icon_container align-center">
          {userVote === 1 ? (
            <HeartFulledIcon size={26} color={'black'} />
          ) : (
            <HeartIcon size={26} color={'black'} />
          )}
        </div>
        <div className="font-bold font12">{agree}</div>
      </div>
    );
  };

  const renderReplyIcon = () => {
    return (
      <div
        className="item_container"
        onClick={() => {
          setShowShareModal(true);
        }}
      >
        <div className="icon_container align-center">
          <ReplyIcon size={26} color={'black'} />
        </div>
      </div>
    );
  };

  const renderLanguageIcon = () => {
    return (
      <div className="item_container" onClick={onViewFile}>
        <div className="icon_container align-center">
          <LanguageIcon size={26} color={'black'} />
        </div>
      </div>
    );
  };

  const renderFullScreenIcon = () => {
    return (
      <div className="item_container" onClick={onViewPreview}>
        <div className="icon_container align-center">
          <FullScreenIcon size={20} color={'black'} />
        </div>
      </div>
    );
  };

  const renderShareModal = () => {
    if (data.item_type === 'statement') {
      return (
        <MobileShareDidKnowModal
          data={data}
          show={showShareModal}
          onClose={() => {
            setShowShareModal(false);
          }}
        />
      );
    } else if (data.item_type === 'meme') {
      return (
        <MobileQuestionActionModal
          open={showShareModal}
          onClose={() => {
            setShowShareModal(false);
          }}
          onSelected={(type) => {
            setShowShareModal(false);

            if (type === 'share') {
              setShowShareModal(true);
            }
          }}
        />
      );
    } else if (data.item_type === 'generated_question') {
      return (
        <MobileShareAnswerModal
          show={showShareModal}
          question={data}
          onClose={() => {
            setShowShareModal(false);
          }}
        />
      );
    } else if (
      data.item_type === 'article' ||
      data.item_type == 'chat_article'
    ) {
      return (
        <MobileShareArticleModal
          data={data}
          show={showShareModal}
          onConnectSocial={onConnectSocial}
          onClose={() => {
            setShowShareModal(false);
          }}
        />
      );
    } else if (data.item_type === 'file') {
      return (
        <MobileShareArticleModal
          data={data}
          show={showShareModal}
          onConnectSocial={onConnectSocial}
          onClose={() => {
            setShowShareModal(false);
          }}
        />
      );
    } else if (data.item_type === 'media') {
      return (
        <MobileShareMediaModal
          question={data}
          show={showShareModal}
          onClose={() => {
            setShowShareModal(false);
          }}
        />
      );
    } else if (data.item_type === 'poll') {
      return (
        <MobileShareAnswerModal
          show={showShareModal}
          question={data}
          onClose={() => {
            setShowShareModal(false);
          }}
        />
      );
    } else if (data.item_type === 'chat') {
      return (
        <MobileShareChatModal
          data={data}
          show={showShareModal}
          onConnectSocial={onConnectSocial}
          onClose={() => {
            setShowShareModal(false);
          }}
        />
      );
    } else if (data.item_type === 'bookmark') {
      return (
        <MobileShareAnswerModal
          show={showShareModal}
          question={data}
          onClose={() => {
            setShowShareModal(false);
          }}
        />
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <div className="mobile_feed_sidebar">
      {(data.audio ||
        (data.item_type === 'chat' && arrExistedAudios.length > 0)) &&
        renderPlayIcon()}
      {renderLikeIcon()}
      {renderReplyIcon()}
      {data.dump_type === 'url' && renderLanguageIcon()}
      {(data.item_type === 'article' || data.item_type === 'chat_article') &&
        renderFullScreenIcon()}
      {renderShareModal()}
    </div>
  );
};
