export const fileSize = (objFile) => {
  return sizeToString(objFile.size);
};

export const sizeToString = (size: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const bytes: number = size;
  let s;
  let i: number = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) {
    s = bytes + ' ' + sizes[i];
  } else {
    s = (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
  }
  return s;
};

export const getIPFSURL = () => {
  return (
    process.env.REACT_APP_IPFS_UPLOAD_URL ?? 'https://elb.ipfs.myx.audio:8000'
  );
};

export const getIPFSFileURL = () => {
  return (
    process.env.REACT_APP_IPFS_UPLOAD_URL ?? 'https://elb.ipfs.myx.audio:8080'
  );
};

export function _arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export const getBase64String = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
};
