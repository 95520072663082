import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import CommentQuestionIcon from 'assets/svgs/CommentQuestionIcon';
import { MobileQuestionAskBar } from 'components/Mobile/Qa/AskQuestion/QuestionAskBar';
import { PostQuestionModal } from 'components/Modals/PostQuestionModal';
import { MobilePostQuestionToOrgModal } from 'components/Modals/PostQuestionModal/MobilePostQuestionModal';
import { MobileSelectPersonAiModal } from 'components/Modals/SelectPersonAIModal/MobileSelectPersonaAiModal';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { apiGetExtensionSimilarQuestion } from 'shared/api/extension';
import { apiGetQAAsk, apiGetQAAskGeneral } from 'shared/api/qa';
import { apiGetUserOrganisations } from 'shared/api/user';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { MobileQuestionAsked } from './AskedInfo';

import './index.styles.scss';
import { MobileSimilarQuestion } from './SimilarQuestion';

export const MobileAskQuestion = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [currentQuestion, setCurrentQuestion] = useState('');
  const [currentQuestionAudio, setCurrentQuestionAudio] = useState<any>('');
  const [isAsking, setIsAsking] = useState(false);
  const [isAsked, setIsAsked] = useState(false);

  const [selectedOrg, setSelectedOrg] = useState<any>(null);
  const [qaAskInfo, setQaAskInfo] = useState<any>(null);
  const [isAIGeneral, setIsAIGeneral] = useState(true);
  const [isOpenAIToogle, setIsOpenAIToogle] = useState(false);
  const [isShareModalShow, setIsShareModalShow] = useState(false);

  const [arrSimilarQuestion, setArrSimilarQuestion] = useState<Array<any>>([]);
  const [arrUserOrg, setArrUserOrg] = useState<Array<any>>([]);

  useEffect(() => {
    if (!!currentQuestion) {
      if (selectedOrg) {
        setIsAIGeneral(false);
      } else {
        setIsAIGeneral(true);
      }
    }
  }, [selectedOrg]);

  useEffect(() => {
    if (userInfo) {
      loadOrgData();
    }
  }, [userInfo]);

  const loadOrgData = async () => {
    const res = await apiGetUserOrganisations(userInfo ? userInfo.id : '');

    if (res.success) {
      setArrUserOrg(res.data);
    }
  };

  const onAsk = async (question, audioUrl) => {
    if (!!question) {
      setCurrentQuestion(question);
      setIsAsking(true);

      Promise.all([
        apiGetQAAskGeneral({
          question,
          audio: audioUrl
        }),
        apiGetExtensionSimilarQuestion({ query: question })
      ])
        .then(([questionRes, similarRes]) => {
          if (questionRes.success) {
            setQaAskInfo(questionRes);
            setIsAsked(true);
          } else {
            setQaAskInfo(null);
          }

          if (similarRes.success) {
            setArrSimilarQuestion(similarRes.data);
          }
          setIsAsking(false);
        })
        .catch((error) => {
          console.error(error);
          setIsAsking(false);
        });
    }
  };

  const loadQuestionData = async (question, orgId = null) => {
    if (question) {
      setIsAsking(true);

      Promise.all([
        apiGetQAAsk({
          question,
          to: orgId ?? selectedOrg?.id,
          to_type: orgId ? 'organisation' : 'general'
        }),
        apiGetExtensionSimilarQuestion({ query: question })
      ])
        .then(([questionRes, similarRes]) => {
          if (questionRes.success) {
            setQaAskInfo(questionRes.data);
            setIsAsked(true);
          } else {
            setQaAskInfo(null);
          }

          if (similarRes.success) {
            setArrSimilarQuestion(similarRes.data);
          }

          setIsAsking(false);
        })
        .catch((error) => {
          console.error(error);
          setIsAsking(false);
        });
    } else {
      setQaAskInfo(null);
    }
  };

  const onRefresh = () => {
    if (selectedOrg) {
      loadQuestionData(currentQuestion, selectedOrg.ai_id);
    } else {
      onAsk(currentQuestion, null);
    }
  };

  const onChangeAi = (newAI) => {
    setSelectedOrg(newAI);
    loadQuestionData(currentQuestion, newAI.ai_id);
  };

  const onReset = () => {
    setQaAskInfo(null);
    setCurrentQuestion('');
    setIsAIGeneral(true);
    setSelectedOrg(null);
  };

  const onBack = () => {
    navigate(-1);
  };

  const onPostQuestion = async () => {
    setIsShareModalShow(true);
  };

  const renderTop = () => {
    return (
      <div
        className="row_align_items row_space_between"
        style={{ padding: '0px 16px' }}
      >
        <div className="row_align_items" style={{ gap: 8 }}>
          <div onClick={onBack}>
            <AngleLeftIcon size={24} color={'black'} />
          </div>
          <div className="font-bold font16">Ask a Question</div>
        </div>
        {qaAskInfo ? (
          <div className="add_question_btn font-bold font12" onClick={onReset}>
            + Question
          </div>
        ) : (
          <div
            className="ask_question_btn font-bold font12"
            onClick={() => {
              onAsk(currentQuestion, currentQuestionAudio);
            }}
          >
            Ask
          </div>
        )}
      </div>
    );
  };

  const renderAskBar = () => {
    return (
      <div className="ask_bar">
        {qaAskInfo ? (
          <div className="asked_question_bar">
            <div className="font-bold font24">{currentQuestion}</div>
          </div>
        ) : (
          <MobileQuestionAskBar
            onSearch={onAsk}
            onChangedQuestion={(text, audio) => {
              setCurrentQuestion(text);
              setCurrentQuestionAudio(audio);
            }}
            width="100%"
            height="45px"
            placeholder="Ask new question here..."
            searchIconClickable={true}
          />
        )}
      </div>
    );
  };

  const renderLoadingBar = () => {
    return (
      <div className="loading_bar_container">
        <LoadingBarAnimation />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="mobile_question_body">
        <MobileQuestionAsked
          qaAskInfo={qaAskInfo}
          isAIGeneral={isAIGeneral}
          aiInfo={selectedOrg}
          onSwitchAI={() => setIsOpenAIToogle(true)}
          onRefresh={onRefresh}
        />
        <MobileSimilarQuestion data={arrSimilarQuestion} />
      </div>
    );
  };

  const renderNotFoundPerfectAnswer = () => {
    return (
      <div
        className="mobile_could_not_found row_align_items"
        onClick={onPostQuestion}
      >
        <CommentQuestionIcon size={16} color={'#FF3E9A'} />
        <div className="font-medium font12" style={{ whiteSpace: 'nowrap' }}>
          Couldn’t Find Perfect Answer?
        </div>
      </div>
    );
  };

  const renderAIToogle = () => {
    return (
      <MobileSelectPersonAiModal
        show={isOpenAIToogle}
        aiType="organisation"
        selectedAi={selectedOrg}
        onClose={() => {
          setIsOpenAIToogle(false);
        }}
        onSelected={(newAI) => {
          setIsOpenAIToogle(false);
          onChangeAi(newAI);
        }}
      />
    );
  };

  const renderShareQuestionToSocial = () => {
    return (
      <MobilePostQuestionToOrgModal
        show={isShareModalShow}
        question={currentQuestion}
        selectedOrgId={selectedOrg ? selectedOrg.id : arrUserOrg[0]?.id}
        onClose={() => {
          setIsShareModalShow(false);
        }}
        onSuccess={() => {
          navigate(-1);
        }}
      />
    );
  };

  return (
    <div className="mobile_ask_question">
      {renderTop()}
      {renderAskBar()}
      {isAsking && renderLoadingBar()}
      {!isAsking && isAsked && qaAskInfo && renderBody()}
      {qaAskInfo && !isAsking && renderNotFoundPerfectAnswer()}
      {renderAIToogle()}
      {renderShareQuestionToSocial()}
    </div>
  );
};
