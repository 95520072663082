import React, { CSSProperties } from 'react';
import { LoadingCircular } from '../LoadingCircular';

import './PrimaryButton.styles.scss';

interface PrimaryButtonProps {
  label: string;
  fontSize?: number;
  isLoading?: boolean;
  disable?: boolean;
  containerStyle?: CSSProperties;
  onPressed?: () => void;
}

export const PrimaryButton = ({
  label,
  fontSize = 14,
  disable = false,
  containerStyle,
  isLoading,
  onPressed
}: PrimaryButtonProps) => {
  const onClicked = (e) => {
    e.stopPropagation();

    onPressed && onPressed();
  };

  return (
    <div
      className={
        disable ? 'primary_btn_container_disabled' : 'primary_btn_container'
      }
      style={containerStyle}
      onClick={(e) => !disable && onClicked(e)}
    >
      {isLoading ? (
        <LoadingCircular size={20} color="white" />
      ) : (
        <div
          className={`label font-bold font${fontSize}`}
          style={disable ? { color: 'black' } : {}}
        >
          {label}
        </div>
      )}
    </div>
  );
};
