import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import appAction from 'redux/app/action';

import './index.styles.scss';
import { apiEventConfirmRSVP } from 'shared/api/event';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { useDispatch } from 'react-redux';
import { MobileShareEventModal } from 'components/Modals/MobileShare/ShareEvent';
import { useNavigate } from 'react-router-dom';

export const CardAllEvent = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isRsvp, setIsRsvp] = useState(data.is_rsvp === 1);
  const [isRsvping, setIsRsvping] = useState(false);
  const [showShare, setShowShare] = useState(false);

  useEffect(() => {
    setIsRsvp(data.is_rsvp);
  }, [data]);

  const onDetail = (e) => {
    e.stopPropagation();
    navigate(`/school/events/${data.qr_code}`);
  };

  const onRsvp = async (e) => {
    e.stopPropagation();
    if (isRsvp) return;

    setIsRsvping(true);

    const res = await apiEventConfirmRSVP({ event: data.id });

    if (res.success) {
      setIsRsvp(true);
      dispatch(appAction.refreshEventData());
    }

    setIsRsvping(false);
  };

  const renderHeader = () => {
    return (
      <div className="card_header row_space_between">
        <div className="row_align_items">
          <div className="row_align_items">
            {data.creator_image?.startsWith('https') ? (
              <img src={data.creator_image} className="item_image" />
            ) : (
              <DefaultAvatarIcon size={28} />
            )}
            {data.org_image?.startsWith('https') ? (
              <img
                src={data.org_image}
                className="item_image"
                style={{ marginLeft: -6 }}
              />
            ) : (
              <div style={{ marginLeft: -6 }}>
                <DefaultAvatarIcon size={28} />
              </div>
            )}
          </div>
          <div className="font-bold font12" style={{ marginLeft: 4 }}>
            {data.creator_name}
            <span
              className="font-bold font12"
              style={{ opacity: 0.4, margin: '0px 4px' }}
            >
              in
            </span>
            {data.org_name}
          </div>
        </div>
        <div
          className="share_btn clickable"
          onClick={(e) => {
            e.stopPropagation();
            setShowShare(true);
          }}
        >
          <ShareIcon color="rgba(0, 0, 0, 0.3)" />
        </div>
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <div className="info_view">
        <div className="row_align_items">
          <div className="card_mark"></div>
          <div className="card_title font-medium font24">{data.title}</div>
        </div>
        <div className="row_align_items" style={{ marginLeft: 16 }}>
          <div style={{ fontSize: 14 }}>📍</div>
          <div
            className="card_desc font-regular font14 align-center"
            style={{ color: 'rgba(0, 0, 0, 0.6)', marginLeft: 6 }}
          >
            {data.location}
          </div>
        </div>
        <div className="row_align_items">
          <div className="row_align_items" style={{ marginLeft: 16 }}>
            <div style={{ fontSize: 14 }}>🗓</div>
            <div
              className="font-regular font14"
              style={{ color: 'rgba(0, 0, 0, 0.6)', marginLeft: 6 }}
            >
              {moment(data.date).format('DD MMM YYYY')} •{' '}
              {moment(data.date).format('hh:mm A')}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderImage = () => {
    if (data.image?.startsWith('https')) {
      return <img src={data.image} className="event_image" />;
    } else {
      return <div></div>;
    }
  };

  const renderBottom = () => {
    return (
      <div style={{ padding: '0px 24px', marginTop: 24 }}>
        {!isRsvp ? (
          <div
            className="claim_button clickable font-bold font14"
            onClick={onRsvp}
          >
            {isRsvping ? (
              <LoadingCircular color="white" size={24} />
            ) : (
              <div>Claim Your Spot</div>
            )}
          </div>
        ) : (
          <div
            className="claimed_button font-bold font14"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            RSVP-ed
          </div>
        )}
      </div>
    );
  };

  const renderShareModal = () => {
    return (
      <MobileShareEventModal
        show={showShare}
        eventData={data}
        onClose={() => {
          setShowShare(false);
        }}
      />
    );
  };

  return (
    <div className="mobile_card_all_event" onClick={onDetail}>
      {renderHeader()}
      <div style={{ display: 'flex', marginTop: 8, flex: 1 }}>
        {renderInfo()}
        {renderImage()}
      </div>

      {renderBottom()}
      {renderShareModal()}
    </div>
  );
};
