import React from 'react';

function RedditIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '256'}
      height={props.size ?? '256'}
      viewBox="0 0 256 256"
    >
      <g
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="1"
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"
      >
        <circle cx="45" cy="45" r="45" fill="#FF4500"></circle>
        <path
          fill="#FFF"
          d="M75.011 45c-.134-3.624-3.177-6.454-6.812-6.331a6.594 6.594 0 00-4.306 1.823 31.99 31.99 0 00-17.327-5.537l2.919-14.038 9.631 2.025a4.497 4.497 0 004.955 3.993c2.472-.268 4.262-2.483 3.993-4.955s-2.483-4.262-4.955-3.993a4.45 4.45 0 00-3.4 2.204L48.68 17.987a1.395 1.395 0 00-1.667 1.063v.022l-3.322 15.615a32.238 32.238 0 00-17.55 5.537 6.562 6.562 0 00-9.284.291 6.562 6.562 0 00.291 9.284 6.372 6.372 0 001.767 1.186c-.045.66-.045 1.32 0 1.98 0 10.078 11.745 18.277 26.23 18.277 14.485 0 26.23-8.188 26.23-18.277.045-.66.045-1.32 0-1.98A6.57 6.57 0 0075.011 45zm-45 4.508A4.517 4.517 0 0134.519 45c2.483 0 4.508 2.025 4.508 4.508s-2.025 4.508-4.508 4.508c-2.494-.023-4.508-2.025-4.508-4.508zm26.141 12.55v-.179a17.282 17.282 0 01-11.119 3.468 17.278 17.278 0 01-11.119-3.468 1.215 1.215 0 011.712-1.711 14.697 14.697 0 009.362 2.83 14.811 14.811 0 009.407-2.74 1.267 1.267 0 011.779.022c.481.492.47 1.297-.022 1.778zm-.615-7.718h-.224l.034-.168c-2.483 0-4.508-2.025-4.508-4.508s2.025-4.508 4.508-4.508 4.508 2.025 4.508 4.508a4.501 4.501 0 01-4.318 4.676z"
        ></path>
      </g>
    </svg>
  );
}

export default RedditIcon;
