export const LinkNames = {
  // auth
  magic_link: '/*',
  login: '/login_password',
  link_verify: '/login',
  qr_verify: '/qr_verify/:code/*',
  register_magic: '/ref/:code/*',

  // referral
  waiting_list: '/waitingList',
  waiting_success: '/waitingsuccess',
  referral_activation: '/referral_activation',

  // main
  home: '/home',
  group: '/group',
  bookmark: '/bookmark',
  organization: '/organization',
  profile: '/profile'
};
