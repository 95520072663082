import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useState } from 'react';
import { apiGroupAskToJoin } from 'shared/api/group';
import { apiOrgRequestJoin } from 'shared/api/org';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import './index.styles.scss';
interface CardOrgProps {
  data: any;
}

export const CarGroup = ({ data }: CardOrgProps) => {
  const [isFollowed, setIsFollowed] = useState<'true' | 'false' | 'requested'>(
    'false'
  );
  const [isRequesting, setIsRequesting] = useState(false);

  useEffect(() => {
    setIsFollowed(data.isFollowed);
  }, [data]);

  const onRequestJoin = async (groupId) => {
    setIsRequesting(true);
    const res = await apiGroupAskToJoin({ group: groupId });
    if (res.sucesss) {
      setIsFollowed('requested');
      setIsRequesting(false);
    }
    setIsRequesting(false);
  };

  const requestTypeClassName = {
    true: 'joined_btn font-bold font12 ',
    false: 'request_join_btn font-bold font12 text-white clickable',
    requested: 'requested_join_btn font-bold font12'
  };

  const requestJoinBtnText = {
    true: 'Joined',
    false: 'Request Join',
    requested: 'Requested'
  };

  return (
    <div className="mobile_join_card_org">
      <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
        <div className="org_image">
          {data.url ? (
            <img src={data.url} className="org_box_icon" />
          ) : (
            <DefaultAvatarIcon size={60} />
          )}
        </div>
        <div className="org_info">
          <div className="first_line">
            <div className="org_title font-bold font22">{data.name}</div>
          </div>
          <div className="second_line">
            <div className="members_count">🙋&nbsp;{data.members} members</div>
          </div>
        </div>
      </div>

      {isRequesting ? (
        <div className="requested_join_btn font-bold font14 text-white clickable">
          <LoadingCircular color="main" size={16} />
        </div>
      ) : (
        <div
          className={
            isFollowed === 'true'
              ? requestTypeClassName['true']
              : isFollowed === 'false'
              ? requestTypeClassName['false']
              : requestTypeClassName['requested']
          }
          onClick={() => onRequestJoin(data.id)}
        >
          {isFollowed === 'true'
            ? requestJoinBtnText['true']
            : isFollowed === 'false'
            ? requestJoinBtnText['false']
            : requestJoinBtnText['requested']}
        </div>
      )}
    </div>
  );
};
