import React from 'react';

function AngleLeftIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ? props.size : '32'}
      height={props.size ? props.size + 1 : '33'}
      fill="none"
      viewBox="0 0 32 33"
    >
      <path
        fill={props.color ? props.color : '#FF3E9A'}
        d="M11.333 17.567l7.6 7.466a1.289 1.289 0 001.867 0 1.289 1.289 0 000-1.866L14.266 16.5 20.8 9.833a1.289 1.289 0 000-1.866c-.267-.267-.534-.4-.934-.4s-.666.133-.933.4l-7.6 7.466c-.533.667-.533 1.467 0 2.134 0-.134 0-.134 0 0z"
      ></path>
    </svg>
  );
}

export default AngleLeftIcon;
