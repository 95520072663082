import AudioPauseIcon from 'assets/svgs/AudioPauseIcon';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import CoinIcon from 'assets/svgs/CoinIcont';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import FavBorderIcon from 'assets/svgs/FavBorderIcon';
import ThumbDownIcon from 'assets/svgs/ThumbDownIcon';
import React, { useEffect, useState } from 'react';
import { apiContentVoteAI } from 'shared/api/content';
import ReactHowler from 'react-howler';
import { useAudioContext } from 'shared/contexts/AudioContext';

import './index.styles.scss';

export const MobileCardQuestionDetailAnswer = ({ data, isTopAnswer }) => {
  const [likeCount, setLikeCount] = useState(data.answer_likes);
  const [dislikeCount, setDislikeCount] = useState(data.answer_dislikes);
  const [userVote, setUserVote] = useState(data.user_like);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const { playingAudio, setPlayingAudio } = useAudioContext();

  useEffect(() => {
    if ((playingAudio ?? '') === data.answer_audio) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [playingAudio, data]);

  const onVote = async (vote: number) => {
    const res = await apiContentVoteAI({
      item_id: data.answer_id,
      item_type: 'answer',
      like: vote,
      remove: userVote === vote ? true : false
    });

    if (res.success) {
      const voteData = res.voting;
      setUserVote(voteData.user_like);
      setLikeCount(voteData.likes);
      setDislikeCount(voteData.dislikes);
    }
  };

  const togglePlayback = () => {
    if (isPlaying) {
      setPlayingAudio(null);
    } else {
      if (playingAudio) {
        setPlayingAudio(null);
      }
      setPlayingAudio(data.answer_audio);
    }
  };

  const renderTopAnswerMarker = () => {
    return <div className="top_voted_mark font-bold font12">Top Answer</div>;
  };

  const renderTopView = () => {
    return (
      <div
        className="answer_top_view row_space_between"
        style={isTopAnswer ? { marginTop: 16 } : {}}
      >
        <div className="row_align_items" style={{ gap: 8 }}>
          {data.answer_creator_image &&
          data.answer_creator_image.startsWith('https') ? (
            <img src={data.answer_creator_image} className="user_image" />
          ) : (
            <DefaultAvatarIcon size={32} />
          )}
          <div className="font-bold font14">{data.answer_creator_name}</div>
        </div>
        <div className="row_align_items">
          <div className="user_point row_align_items">
            <CoinIcon />
            <div className="font-medium font14">
              {parseInt(data.points)} pts
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAnswer = () => {
    return <pre className="font-regular font24">{data.answer_text}</pre>;
  };

  const renderAction = () => {
    return (
      <div className="answer_box_bottom_left">
        {data.answer_audio && (
          <div className="agree_btn align-center" onClick={togglePlayback}>
            {isPlaying ? (
              <AudioPauseIcon color="#EB5099" size={20} />
            ) : (
              <AudioPlayIcon color="#EB5099" size={20} />
            )}
            {isPlaying && (
              <ReactHowler
                src={data.answer_audio}
                playing={isPlaying}
                loop={false}
                html5={true} // Use HTML5 Audio API
                onLoad={() => setIsLoaded(true)}
                onEnd={() => setPlayingAudio(null)}
              />
            )}
          </div>
        )}
        <div
          className={
            userVote === 1
              ? 'agree_btn_highlighted clickable'
              : 'agree_btn clickable'
          }
          onClick={() => onVote(1)}
        >
          <FavBorderIcon size={20} />
        </div>
        <div
          className={
            userVote === 0
              ? 'agree_btn_highlighted clickable'
              : 'agree_btn clickable'
          }
          onClick={() => onVote(0)}
        >
          <ThumbDownIcon size={20} />
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_question_detail_full_card_answer">
      {renderTopView()}
      {renderAnswer()}
      {renderAction()}
      {isTopAnswer && renderTopAnswerMarker()}
    </div>
  );
};
