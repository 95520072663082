import PlusIcon from 'assets/svgs/PlusIcon';
import React, { useEffect, useState } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiEventAttending } from 'shared/api/event';

import { LeftArrow, RightArrow } from './ArrowBtns';
import { CardEventAttending } from './CardEventAttending';
import './index.styles.scss';

export const EventAttending = ({ orgId }) => {
  const { hasNewEvent } = useSelector((state: RootState) => state.app);

  const [arrEvent, setArrEvent] = useState<any>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    loadData();
  }, [hasNewEvent]);

  const loadData = async (init = true) => {
    if (init) {
      const res = await apiEventAttending({
        query: '',
        orgs: null,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrEvent(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }
    } else {
      const res = await apiEventAttending({
        query: '',
        orgs: null,
        limit: 10,
        offset: lastId
      });

      if (res.success) {
        setArrEvent((prev) => [...prev, ...res.data]);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }
    }
  };

  const onLoadMoreData = () => {
    loadData(false);
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const renderBody = () => {
    return (
      <div id="attachScrollableDiv" className="mobile_event_attending_body">
        <ScrollMenu
          // LeftArrow={LeftArrow}
          // RightArrow={RightArrow}
          onWheel={onWheel}
          onUpdate={onLoadMoreData}
        >
          {arrEvent.map((item, index) => {
            return (
              <CardEventAttending
                key={`card_event_attending_${index}`}
                data={item}
              />
            );
          })}
        </ScrollMenu>
      </div>
    );
  };

  if (arrEvent.length == 0) {
    return <div />;
  } else {
    return (
      <div className="mobile_event_attending">
        <div className="font-bold font20" style={{ marginLeft: 16 }}>
          Attending
        </div>
        {renderBody()}
      </div>
    );
  }
};
