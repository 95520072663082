import CloseIcon from 'assets/svgs/CloseIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  apiGetChatPrompt,
  apiChatAttachPrompt,
  apiGetChatIntroduce
} from 'shared/api/chat';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { MobileCardPrompt } from '../Prompts/CardPrompt';

import './index.styles.scss';

export const MobileSearchPrompt = () => {
  const navigate = useNavigate();
  const params = useParams();
  const aiId = params.aiId;

  const [query, setQuery] = useState('');

  const [arrPrompt, setArrPrompt] = useState<Array<any>>([]);
  const [lastIdPrompt, setLastIdPrompt] = useState(0);
  const [hasMorePrompt, setHasMorePrompt] = useState(true);
  const [isLoadingPrompt, setIsLoadingPrompt] = useState(false);
  const [selectedPromptId, setSelectedPromptId] = useState<any>(null);

  useEffect(() => {
    loadPrompts();
    loadRoomInfo();
  }, [query]);

  const loadPrompts = async (init = true) => {
    if (init) {
      setIsLoadingPrompt(true);

      const res = await apiGetChatPrompt({
        query: query,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrPrompt(res.data);
        setLastIdPrompt(res.lastId);
        setHasMorePrompt(res.hasMore);
      }

      setIsLoadingPrompt(false);
    } else {
      if (!isLoadingPrompt && hasMorePrompt) {
        setIsLoadingPrompt(true);

        const res = await apiGetChatPrompt({
          query: query,
          limit: 10,
          offset: lastIdPrompt
        });

        if (res.success) {
          setArrPrompt((prev) => [...prev, ...res.data]);
          setLastIdPrompt(res.lastId);
          setHasMorePrompt(res.hasMore);
        }

        setIsLoadingPrompt(false);
      }
    }
  };

  const loadRoomInfo = async () => {
    const res = await apiGetChatIntroduce({
      ai_id: aiId,
      ai_type: 'personal'
    });

    if (res.success) {
      setSelectedPromptId(res.data.prompt_id);
    }
  };

  const onUpdatePrompt = async (newPromptId) => {
    const res = await apiChatAttachPrompt({
      prompt: newPromptId,
      ai_id: aiId,
      ai_type: 'personal'
    });

    if (res.success) {
      setSelectedPromptId(newPromptId);
    }
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      loadPrompts(false);
    }
  };

  const onBack = () => {
    navigate(-1);
  };

  const onSearch = (query: string) => {
    setQuery(query);
  };

  const renderHeader = () => {
    return (
      <div className="prompt_search_header row_align_items">
        <div className="close_btn" onClick={onBack}>
          <CloseIcon />
        </div>
        <div className="search_view">
          <SearchBox
            onSearch={onSearch}
            placeholder="Search here..."
            fontSize={14}
            height="40px"
            enableShadow={false}
          />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="content_container">
        <div className="font-bold font16">Suggested Prompts</div>
        <div className="content_list" onScroll={handleScroll}>
          {arrPrompt.map((item, index) => {
            var isSelected = item.id === selectedPromptId;

            return (
              <MobileCardPrompt
                key={`mobile_card_prompt_${index}`}
                data={item}
                isSelected={isSelected}
                onPressed={(sItem) => onUpdatePrompt(sItem.id)}
                onRemoveFromUI={() => {}}
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_search_prompt">
      {renderHeader()}
      {renderBody()}
    </div>
  );
};
