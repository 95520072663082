import React, { useState } from 'react';

import './index.styles.scss';
import { MobileShareArticleModal } from 'components/Modals/MobileShare/ShareArticle';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';

interface Props {
  data: any;
  detectScroll: boolean;
  onViewProfile: (userId) => void;
}

export const MobileFeedCardArticle = ({
  data,
  detectScroll,
  onViewProfile
}: Props) => {
  const [showTopShadow, setShowTopShadow] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const handleScroll = (e) => {
    if (detectScroll) {
      // hide nav bar
      const scrollTop = e.target.scrollTop;
      setLastScrollTop(scrollTop);

      if (scrollTop < lastScrollTop) {
        document
          .getElementById('mobile_body')
          ?.setAttribute('class', 'mobile_body normal_mobile_body');
        document
          .getElementById('mobile_tab')
          ?.setAttribute('class', 'mobile_tab active_nav');
        setShowTopShadow(false);
      } else {
        document
          .getElementById('mobile_body')
          ?.setAttribute('class', 'mobile_body expanded_mobile_body');
        document
          .getElementById('mobile_tab')
          ?.setAttribute('class', 'mobile_tab hidden_nav');
        setShowTopShadow(true);
      }
    }
  };

  const renderBody = () => {
    return (
      <div className="did_know_body">
        <div className="feed_main_content" onScroll={handleScroll}>
          {data.images && data.images.startsWith('https') ? (
            <img src={data.images} className="did_know_image" />
          ) : (
            <div></div>
          )}
          <div className="font24 article_title">{data.title}</div>
          {renderUserInfo()}
          <div className="artile_txt article-font-regular">{data.text}</div>
        </div>
      </div>
    );
  };

  const renderUserInfo = () => {
    return (
      <div
        className="user_info row_align_items font-regular"
        onClick={() => {
          onViewProfile(data.creator_id);
        }}
      >
        <span className="font-regular">{`Shared By`}</span>
        {data.creator_image && data.creator_image.startsWith('https') ? (
          <img src={data.creator_image} className="user_img" />
        ) : (
          <DefaultAvatarIcon size={26} />
        )}
        <span className="font-bold">{`${
          data.creator_name ?? 'Anonymous'
        }`}</span>
      </div>
    );
  };

  const renderShareModal = () => {
    return (
      <MobileShareArticleModal
        data={data}
        show={showShareModal}
        onClose={() => {
          setShowShareModal(false);
        }}
      />
    );
  };

  const renderBottomShadow = () => {
    return <div className="did_know_bottom"></div>;
  };

  const renderTopShadow = () => {
    return <div className="top_shadow"></div>;
  };

  return (
    <div className="mobile_card_article">
      {showTopShadow && renderTopShadow()}
      {renderBody()}
      {renderBottomShadow()}
      {renderShareModal()}
    </div>
  );
};
