import React from 'react';

function HistoryIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ? props.size : '14'}
      height={props.size ? props.size : '14'}
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill={props.color ? props.color : '#000'}
        d="M7 .334a6.667 6.667 0 00-4.587 1.847V1A.667.667 0 001.08 1v3a.667.667 0 00.666.666h3a.667.667 0 000-1.333h-1.6a5.333 5.333 0 11-1.48 3.667.667.667 0 00-1.333 0A6.667 6.667 0 107 .334zm0 4a.667.667 0 00-.667.667v2A.667.667 0 007 7.667h1.333a.667.667 0 000-1.333h-.667V5.001A.667.667 0 007 4.334z"
      ></path>
    </svg>
  );
}

export default HistoryIcon;
