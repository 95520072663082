import DownloadIcon from 'assets/svgs/DownloadIcon';
import React from 'react';
import moment from 'moment';

import './index.styles.scss';

export const CardPointHistory = ({ data }) => {
  return (
    <div className="card_point_history">
      <div
        className="card_point_history_icon"
        style={{
          backgroundColor:
            data.points > 0
              ? 'rgba(8, 184, 131, 0.1)'
              : 'rgba(255, 102, 81, 0.1)'
        }}
      >
        <DownloadIcon color={data.points > 0 ? '#08B883' : '#FF6651'} />
      </div>
      <div className="card_point_history_info">
        <div
          className="font-regular font12"
          style={{ color: 'rgba(0, 0, 0, 0.3)' }}
        >
          {moment(data.timestamp).format('DD MMM yyyy hh:mm a')}
        </div>
        <div className="font-regular font14" style={{ marginTop: 8 }}>
          {data.notification}
        </div>
      </div>
      <div
        className="card_point_history_status font-bold font16"
        style={{
          color: data.points > 0 ? 'black' : '#FF3E9A',
          paddingLeft: 16
        }}
      >{`${data.points > 0 ? '+' : '-'} ${Math.abs(data.points)}`}</div>
    </div>
  );
};
