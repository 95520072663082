import React from 'react';

function FilterBoltIcon({ isSelected }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={isSelected ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'}
        d="M16.575 7.958a.833.833 0 00-.741-.458h-4.167v-5a.834.834 0 00-1.508-.533l-6.667 9.166a.833.833 0 00-.067.834.834.834 0 00.742.533h4.166v5a.833.833 0 001.509.492l6.667-9.167a.833.833 0 00.066-.867zM10 14.933v-3.266a.833.833 0 00-.833-.834H5.833L10 5.067v3.266a.833.833 0 00.834.834h3.333L10 14.933z"
      ></path>
    </svg>
  );
}

export default FilterBoltIcon;
