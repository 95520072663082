import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import TrashIcon from 'assets/svgs/TrashIcon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';

import moment from 'moment';

import './index.styles.scss';
import MicIcon from 'assets/svgs/MicIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import FileIcon from 'assets/svgs/FileIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import ChatAudioFilePlayer from '../ChatAudioFilePlayer';
import { apiDeletionDump } from 'shared/api/delete';
import { LoadingDialog } from 'shared/ui-kit/LoadingDialog';
import { showToast } from 'shared/ui-kit/PrimaryToast';

export const MobileCardpersonalAiFile = ({ data, onRemoveOnUI }) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [showDetail, setShowDetail] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const onViewDetail = () => {
    if (data.dump_type === 'audio') {
      setShowDetail((prev) => !prev);
    } else {
      window.open(data.url, '_blank');
    }
  };

  const onRemove = async (e) => {
    e.stopPropagation();

    if (!isRemoving) {
      setIsRemoving(true);

      const res = await apiDeletionDump({
        dump: data.id
      });
      setIsRemoving(false);

      if (res.success) {
        showToast('success', 'Deletion successful!');
        onRemoveOnUI && onRemoveOnUI(data.id);
      }
    }
  };

  const getDescTxt = (dumpType) => {
    if (dumpType === 'audio') {
      return 'added new voice notes';
    } else if (dumpType === 'notes') {
      return 'added new written notes';
    } else if (dumpType === 'pdf') {
      return 'added a new doc';
    } else {
      return 'added a new link';
    }
  };

  const getTitle = () => {
    if (data.summary_status === 'completed') return data.title;
    if (data.dump_type === 'pdf') return data.filename ?? data.url;
    if (data.dump_type === 'url' || data.dump_type === 'link') return data.url;
    if (data.dump_type === 'audio') return data.filename ?? data.url;
    if (data.dump_type === 'text') return data.url;

    return data.title ? data.title : data.filename ? data.filename : data.url;
  };

  const getIcon = () => {
    if (data.status === 'completed') return <div className="font14">✅</div>;
    return <div className="font14">⌛️</div>;
  };

  const renderHeader = () => {
    return (
      <div className="row_space_between row_align_items">
        <div className="row_align_items" style={{ gap: 6 }}>
          {userInfo?.image && userInfo.image.startsWith('https') ? (
            <img src={userInfo.image} className="user_image" />
          ) : (
            <DefaultAvatarIcon size={16} />
          )}
          <div className="font-medium font10">
            {`You`}{' '}
            <span className="font-regular font10" style={{ opacity: 0.5 }}>
              {getDescTxt(data.dump_type)}
            </span>
          </div>
        </div>
        <div className="row_align_items">
          <div className="font-regular font10" style={{ opacity: 0.6 }}>
            {moment(data.timestamp).format('DD MMM')}
          </div>
          <div
            className="clickable align-center"
            style={{ marginLeft: 16 }}
            onClick={onRemove}
          >
            <TrashIcon size={16} />
          </div>
        </div>
      </div>
    );
  };

  const renderFileIcon = (type) => {
    return (
      <div style={{ width: 50, height: 50 }}>
        {type === 'pdf' ? (
          <PdfFileIcon />
        ) : type === 'audio' ? (
          <MicIcon size={50} color="#FF3E9A" opacity={1} />
        ) : type === 'url' || type === 'html' ? (
          <LinkIcon size={50} color="#FF3E9A" opacity={1} />
        ) : (
          <FileIcon size={50} />
        )}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div
        className="row_space_between row_align_items"
        style={{ width: 'calc(100%)' }}
      >
        <div className="row_align_items" style={{ width: 'calc(100%)' }}>
          <div>{renderFileIcon(data.dump_type)}</div>
          <div style={{ marginLeft: 8, width: 'calc(100% - 50px)' }}>
            <div className="file_title font-medium font14">{getTitle()}</div>
            <div className="row_align_items" style={{ marginTop: 6 }}>
              {getIcon()}
              <div
                className="font-bold font10"
                style={{ color: '#FF3E9A', marginLeft: 6 }}
                onClick={onViewDetail}
              >
                View Original
              </div>
            </div>
          </div>
        </div>
        {/* <div className="trash_btn">
          <TrashIcon color="rgba(0, 0, 0, 0.3)" />
        </div> */}
      </div>
    );
  };

  const renderDetail = () => {
    return <ChatAudioFilePlayer audioSrc={`${data.url}`} index={data.id} />;
  };

  const renderRemovingModal = () => {
    return (
      <LoadingDialog
        show={isRemoving}
        title={'Deleting data'}
        description="This may take a moment..."
        onClose={() => setIsRemoving(false)}
      />
    );
  };

  return (
    <div className="mobile_card_personal_ai_file">
      {renderHeader()}
      {renderBody()}
      {showDetail && renderDetail()}
      {renderRemovingModal()}
    </div>
  );
};
