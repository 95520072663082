import React from 'react';

import { Worker } from '@react-pdf-viewer/core';
import { Provider } from 'react-redux';

import store from './redux/store';

import './App.scss';
import { StyledEngineProvider } from '@mui/material/styles';
import { IPFSContextProvider } from 'shared/contexts/IPFSContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from 'components/Auth';
import { Auth0Provider } from '@auth0/auth0-react';
import ReactGA from 'react-ga4';
import { AudioContextProvider } from 'shared/contexts/AudioContext';

import 'react-tooltip/dist/react-tooltip.css';

function App() {
  if (process.env.REACT_APP_GA_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      useRefreshTokens={true}
      authorizationParams={{
        redirect_uri: window.location.origin,
        access_type: 'offline'
      }}
    >
      <AudioContextProvider>
        <Provider store={store}>
          <StyledEngineProvider>
            <IPFSContextProvider>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/legacy/build/pdf.worker.min.js">
                <Auth />
              </Worker>
              <ToastContainer />
            </IPFSContextProvider>
          </StyledEngineProvider>
        </Provider>
      </AudioContextProvider>
    </Auth0Provider>
  );
}
export default App;
