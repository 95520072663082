import CloseIcon from 'assets/svgs/CloseIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useState } from 'react';
import { apiDeletionSearch } from 'shared/api/delete';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { showToast } from 'shared/ui-kit/PrimaryToast';

import './index.styles.scss';

interface Props {
  data: any;
  onRemoveFromUI: (item) => void;
}

export const MobileCardRecentHistory = ({ data, onRemoveFromUI }: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const onRemove = async () => {
    if (!isDeleting) {
      setIsDeleting(true);

      const res = await apiDeletionSearch({
        item_id: data.creator_id,
        item_type: 'user'
      });
      if (res.success) {
        showToast('success', 'Search successfully deleted!');
        onRemoveFromUI(data);
      }

      setIsDeleting(false);
    }
  };

  return (
    <div className="mobile_community_card_recent_history row_align_items">
      {data.creator_image && data.creator_image.startsWith('https') ? (
        <img src={data.creator_image} className="user_image" />
      ) : (
        <DefaultAvatarIcon size={50} />
      )}
      <div className="user_name font-regular font16">{data.creator_name}</div>
      <div onClick={onRemove}>
        {isDeleting ? (
          <LoadingCircular size={16} color="main" />
        ) : (
          <CloseIcon size={20} />
        )}
      </div>
    </div>
  );
};
