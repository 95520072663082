import React from 'react';
import ReactLoading from 'react-loading';

export const LoadingBarAnimation = () => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <ReactLoading
        type={'bars'}
        color={'rgba(0, 0, 0, 0.1)'}
        height={100}
        width={100}
      />
    </div>
  );
};
