import HttpClient from './apiClient';

export async function apiCreateGroup(params: {
  name: string;
  description: string;
  url: string;
  tags?: string;
  is_private: boolean;
}) {
  try {
    const response = await HttpClient.postWithToken(`groups/create`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetGroups(params: {
  query?: string;
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`groups/get`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetSuggestedGroups(params: {
  query?: any;
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `groups/suggestions`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDismissSuggestion(params: { group: number }) {
  try {
    const response = await HttpClient.postWithToken(
      `groups/dismissSuggestion`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGroupSendInvitation(params: {
  group: number;
  toUser: number;
}) {
  try {
    const response = await HttpClient.postWithToken(`groups/invite`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGroupSendEmailInvitation(params: {
  group: number;
  emails: Array<any>;
}) {
  try {
    const response = await HttpClient.postWithToken(
      `groups/inviteByEmail`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetGroupsInvitation(params: {
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `groups/invitations`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetGroupDetail(params: { group: any }) {
  try {
    console.log(params);
    const response = await HttpClient.getWithToken(`groups/detail`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetGroupSearch(params: {
  query: string;
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`groups/search`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiAcceptGroupInvitation(params: { group: number }) {
  try {
    const response = await HttpClient.postWithToken(
      `groups/acceptInvitation`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiRejectGroupInvitation(params: { group: number }) {
  try {
    const response = await HttpClient.postWithToken(
      `groups/rejectInvitation`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiAcceptGroupRequesst(params: { request: number }) {
  try {
    const response = await HttpClient.postWithToken(
      `groups/acceptJoinRequest`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiRejectGroupRequest(params: { request: number }) {
  try {
    const response = await HttpClient.postWithToken(
      `groups/rejectJoinRequest`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGroupAskToJoin(params: { group: number }) {
  try {
    const response = await HttpClient.postWithToken(`groups/join`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetPendingMember(params: {
  group: number | string | undefined;
  query?: string;
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `groups/joinRequests`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetGroupMember(params: {
  group: number;
  query?: string;
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`groups/members`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetGroupDetailById(params: { group: any }) {
  try {
    const response = await HttpClient.getWithToken(`groups/detailById`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEditDumpPermission(params: {
  group: number;
  permission: string;
}) {
  try {
    const response = await HttpClient.postWithToken(
      `groups/editDumpPermission`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiAddDumpEditor(params: {
  group: number;
  toUser: number;
}) {
  try {
    console.log(params);
    const response = await HttpClient.postWithToken(
      `groups/addDumpEditor`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiRemoveDumpEditor(params: {
  group: number;
  toUser: number;
}) {
  try {
    console.log(params);
    const response = await HttpClient.postWithToken(
      `groups/removeDumpEditor`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
