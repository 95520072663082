import React from 'react';

function CalendarIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '16'}
      height={props.size ?? '16'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={props.color ?? '#FF3E9A'}
        d="M8 12.666a.667.667 0 100-1.334.667.667 0 000 1.334zm3.333 0a.667.667 0 100-1.334.667.667 0 000 1.334zm0-2.666a.667.667 0 100-1.334.667.667 0 000 1.334zM8 10a.667.667 0 100-1.333A.667.667 0 008 10zm4.667-8H12v-.667a.667.667 0 10-1.333 0V2H5.333v-.667a.667.667 0 10-1.333 0V2h-.667a2 2 0 00-2 2v9.333a2 2 0 002 2h9.334a2 2 0 002-2V4a2 2 0 00-2-2zm.666 11.333a.667.667 0 01-.666.667H3.333a.667.667 0 01-.666-.667v-6h10.666v6zm0-7.333H2.667V4a.667.667 0 01.666-.667H4V4a.667.667 0 001.333 0v-.667h5.334V4A.666.666 0 1012 4v-.667h.667a.667.667 0 01.666.667v2zm-8.666 4a.667.667 0 100-1.333.667.667 0 000 1.333zm0 2.666a.667.667 0 100-1.334.667.667 0 000 1.334z"
      ></path>
    </svg>
  );
}

export default CalendarIcon;
