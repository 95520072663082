import { MobileNewShareAnswerModal } from 'components/Modals/MobileShare/ShareAnswer/NewShareModal';
import { MobileQuestionAnswerShareModal } from 'components/Modals/QuestionAnswerShareModal/MobileQuestionShareModal';
import React, { useState } from 'react';
import { MobileCardSimilarQuestion } from './CardSimilarQuestion';

import './index.styles.scss';

interface Props {
  data: Array<any>;
}

export const MobileSimilarQuestion = ({ data }: Props) => {
  const [showShare, setShowShare] = useState(false);
  const [selectedQuestionData, setSelectedQuestionData] = useState<any>(null);

  const renderEmptyBody = () => {
    return (
      <div className="mobile_question_box_not_found">
        <div className="speech_balloon">💬</div>
        <div className="font-bold font14 not_found_title">
          No similar question found
        </div>
        <div
          className="not_found_description font-regular font12"
          style={{ width: '100%', justifyContent: 'center' }}
        >
          Post your question or use answer from AI to create a discussion
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div>
        {data.map((item, index) => {
          return (
            <MobileCardSimilarQuestion
              key={`mobile_card_similar_question_${index}`}
              data={item}
              type={'creator'}
              hideBookMark={true}
              onShareMedia={(data, modalType) => {
                setSelectedQuestionData(data);
                setShowShare(true);
              }}
            />
          );
        })}
      </div>
    );
  };

  const renderShareQuestionToSocial = () => {
    return (
      <MobileNewShareAnswerModal
        show={showShare}
        onClose={() => {
          setShowShare(false);
        }}
        question={selectedQuestionData}
      />
    );
  };

  return (
    <div className="mobile_similar_question">
      <div className="font-bold font20" style={{ padding: '0px 16px' }}>
        Similar Questions
      </div>
      {data.length > 0 ? renderBody() : renderEmptyBody()}
      {selectedQuestionData && renderShareQuestionToSocial()}
    </div>
  );
};
