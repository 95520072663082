import AudioPauseIcon from 'assets/svgs/AudioPauseIcon';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import FilterCheckIcon from 'components/Modals/Mobile/FeedFilterModal/TypeFilter/svgs/FilterCheckIcon';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { useAudioContext } from 'shared/contexts/AudioContext';
import { TypeLoadingText } from 'shared/ui-kit/TypeLoadingText';
import ReactHowler from 'react-howler';

import './index.styles.scss';

interface ChatDataType {
  id: number;
  room: number;
  creator: number;
  message: string;
  is_ai: number;
  type: 'text' | 'summary';
  timestamp: string;
  ai_type: 'general' | 'user' | 'group' | 'organisation' | 'system';
  ai_id: number;
  ai_name: string;
  ai_image: string;
  ref_users?: string;
  ref_names?: string;
  ref_images?: string;
  ref_filenames?: string;
  ref_sizes?: string;
  ref_texts?: string;
  ref_titles?: string;
  ref_urls?: string;
  ref_types?: string;
  ref_count?: number;
  ref_ids?: string;
  is_new?: boolean;
  audio?: any;
  duration?: any;
}

interface CardChatProps {
  isSelected: boolean;
  isSharing: boolean;
  data: ChatDataType;
  onShare: () => void;
  onClick: (item) => void;
  onStreamingMessage: () => void;
  onFinishedStreamingMessage: () => void;
}

export const MobileCardPromptChat = ({
  isSelected,
  isSharing,
  data,
  onShare,
  onClick,
  onStreamingMessage,
  onFinishedStreamingMessage
}: CardChatProps) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [isNewMessage, setIsNewMessage] = useState(data.is_new);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const { playingAudio, setPlayingAudio } = useAudioContext();

  const audio = useMemo(() => {
    return data.audio;
  }, [data.audio]);

  useEffect(() => {
    setIsNewMessage(data.is_new ? data.is_new : false);
  }, [data.is_new]);

  useEffect(() => {
    if (!!audio && (playingAudio ?? '') === audio) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [playingAudio]);

  const togglePlayback = () => {
    if (isPlaying) {
      setPlayingAudio(null);
    } else {
      if (playingAudio) {
        setPlayingAudio(null);
      }
      setPlayingAudio(audio);
    }
  };

  const renderAudioIcon = () => {
    if (!!audio) {
      return (
        <div className="audio_play_btn" onClick={togglePlayback}>
          {isPlaying ? (
            <AudioPauseIcon size={12} />
          ) : (
            <AudioPlayIcon color="#FF3E9A" size={12} />
          )}
          {isPlaying && (
            <ReactHowler
              src={audio}
              playing={isPlaying}
              loop={false}
              html5={true} // Use HTML5 Audio API
              onLoad={() => setIsLoaded(true)}
              onEnd={() => setPlayingAudio(null)}
            />
          )}
        </div>
      );
    } else {
      return <div />;
    }
  };

  const renderSummaryCard = () => {
    return (
      <div className="summary_card">
        <div className="font-bold font14" style={{ color: '#FF3E9A' }}>
          Summary of the conversation
        </div>
        <pre className="summary_message font-regular font14">
          {data.message}
        </pre>
      </div>
    );
  };

  if (!isSharing) {
    if (data.type === 'summary' && data.ai_type === 'system') {
      return (
        <div className="mobile_card_prompt_chat">{renderSummaryCard()}</div>
      );
    } else {
      if (data.creator === userInfo?.id) {
        return (
          <>
            <div
              className="mobile_card_prompt_chat"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end'
              }}
            >
              <div className="mobile_card_prompt_chat_header">
                {renderAudioIcon()}
                <div className="mobile_card_prompt_chat_header_image">
                  {userInfo?.image && userInfo?.image.startsWith('https') ? (
                    <img src={userInfo?.image} className="creator_image" />
                  ) : (
                    <DefaultAvatarIcon size={20} />
                  )}
                </div>
                <div className="font-bold font12">{'You'}</div>
              </div>
              <div
                className="mobile_card_prompt_chat_own_message font-regular font14"
                style={{ marginBottom: 0 }}
                onClick={onShare}
              >
                {data.message}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="mobile_card_prompt_chat">
              <div className="mobile_card_prompt_chat_header">
                <div className="mobile_card_prompt_chat_header_image">
                  {data.ai_image ? (
                    <img src={data.ai_image} className="creator_image" />
                  ) : (
                    <DefaultAvatarIcon size={20} />
                  )}
                </div>
                <div className="font-bold font12">{`${data.ai_name}`}</div>
                {renderAudioIcon()}
              </div>
              <div className="mobile_card_prompt_chat_message">
                {isNewMessage ? (
                  <div
                    className="font-regular font14"
                    style={{ wordBreak: 'break-all' }}
                  >
                    <TypeLoadingText
                      text={
                        data.message?.startsWith('\n')
                          ? '\n'.concat(data.message.slice(1))
                          : data.message
                      }
                      fontSize={'font14'}
                      speed={20}
                      onFinished={() => {
                        data.is_new = false;
                        setIsNewMessage(false);
                        onFinishedStreamingMessage();
                      }}
                      onStarted={() => onStreamingMessage()}
                    />
                  </div>
                ) : (
                  <pre
                    className="font-regular font14"
                    style={{ marginBottom: 0 }}
                    onClick={onShare}
                  >
                    {data.message?.startsWith('\n')
                      ? '\n'.concat(data.message.slice(1))
                      : data.message}
                  </pre>
                )}
              </div>
            </div>
          </>
        );
      }
    }
  } else {
    if (data.creator === userInfo?.id) {
      return (
        <div
          className="mobile_multichat_card_container row_align_items"
          onClick={() => onClick(data)}
        >
          <div className="mobile_multichat_select_btn">
            {isSelected ? (
              <FilterCheckIcon />
            ) : (
              <div className="default_mark"></div>
            )}
          </div>
          <div
            className="mobile_card_prompt_chat"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end'
            }}
          >
            <div className="mobile_card_prompt_chat_header">
              {renderAudioIcon()}
              <div className="mobile_card_prompt_chat_header_image">
                {userInfo?.image && userInfo?.image.startsWith('https') ? (
                  <img src={userInfo?.image} className="creator_image" />
                ) : (
                  <DefaultAvatarIcon size={20} />
                )}
              </div>
              <div className="font-bold font12">{'You'}</div>
            </div>
            <div
              className="mobile_card_prompt_chat_own_message font-regular font14"
              style={{ marginBottom: 0 }}
              onClick={onShare}
            >
              {data.message}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="mobile_multichat_card_container row_align_items"
          onClick={() => onClick(data)}
        >
          <div className="mobile_multichat_select_btn">
            {isSelected ? (
              <FilterCheckIcon />
            ) : (
              <div className="default_mark"></div>
            )}
          </div>
          <div className="mobile_card_prompt_chat">
            <div className="mobile_card_prompt_chat_header">
              <div className="mobile_card_prompt_chat_header_image">
                {data.ai_image ? (
                  <img src={data.ai_image} className="creator_image" />
                ) : (
                  <DefaultAvatarIcon size={20} />
                )}
              </div>
              <div className="font-bold font12">{`${data.ai_name}`}</div>
              {renderAudioIcon()}
            </div>
            <div className="mobile_card_prompt_chat_message">
              {isNewMessage ? (
                <div
                  className="font-regular font14"
                  style={{ wordBreak: 'break-all' }}
                >
                  <TypeLoadingText
                    text={
                      data.message?.startsWith('\n')
                        ? '\n'.concat(data.message.slice(1))
                        : data.message
                    }
                    fontSize={'font14'}
                    speed={100}
                    onFinished={() => {
                      data.is_new = false;
                      setIsNewMessage(false);
                    }}
                  />
                </div>
              ) : (
                <pre
                  className="font-regular font14"
                  style={{ marginBottom: 0 }}
                  onClick={onShare}
                >
                  {data.message?.startsWith('\n')
                    ? '\n'.concat(data.message.slice(1))
                    : data.message}
                </pre>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
};
