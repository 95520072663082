import PlusIcon from 'assets/svgs/PlusIcon';
import { MobileCreatePersonalAiModal } from 'components/Modals/Mobile/CreatePersonalAI';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';

import './index.styles.scss';
import { apiGetUserPersonalAIs } from 'shared/api/user';
import { MobileChatPersonalAI } from './ChatPersonalAI';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { showToast } from 'shared/ui-kit/PrimaryToast';

export const MobileTopMyPersonalAI = () => {
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [arrAi, setArrAi] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (init = true) => {
    if (init) {
      setIsLoading(true);

      const res = await apiGetUserPersonalAIs({
        userId: userInfo?.id,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setIsLoaded(true);
        setArrAi(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }

      setIsLoading(false);
    } else {
      if (!isLoadingMore && hasMore) {
        setIsLoadingMore(true);

        const res = await apiGetUserPersonalAIs({
          userId: userInfo?.id,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrAi((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoadingMore(false);
      }
    }
  };

  const onSuccessedToCreate = () => {
    showToast('success', 'Successfully created');
    setShowCreateModal(false);
    loadData();
  };

  const renderHeader = () => {
    return (
      <div
        className="row_space_between row_align_items"
        style={{ padding: '0px 16px' }}
      >
        <div className="font-bold font18">My Personal AI</div>
        <div
          className="ai_plus_btn"
          onClick={() => {
            setShowCreateModal(true);
          }}
        >
          <PlusIcon />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="ai_list">
        <MobileChatPersonalAI
          data={arrAi}
          isLoaded={isLoaded}
          onGetStart={() => {
            setShowCreateModal(true);
          }}
        />
      </div>
    );
  };

  const renderCreateModal = () => {
    return (
      <MobileCreatePersonalAiModal
        open={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
        }}
        onSuccess={() => {
          onSuccessedToCreate();
        }}
      />
    );
  };

  const renderLoadingBar = () => {
    return (
      <div>
        <LoadingBarAnimation />
      </div>
    );
  };

  return (
    <div className="mobile_personal_ai">
      {renderHeader()}
      {isLoading ? renderLoadingBar() : renderBody()}
      {renderCreateModal()}
    </div>
  );
};
