const actions = {
  LOAD_USER_INFO: 'LOAD_USER_INFO',
  LOAD_USER_INFO_SUCCESS: 'LOAD_USER_INFO_SUCCESS',
  LOAD_USER_INFO_FAILED: 'LOAD_USER_INFO_FAILED',
  LOAD_USER_SOCIAL_INFO: 'LOAD_USER_INFO',
  LOAD_USER_SOCIAL_INFO_SUCCESS: 'LOAD_USER_INFO_SUCCESS',
  LOAD_USER_SOCIAL_INFO_FAILED: 'LOAD_USER_INFO_FAILED',
  CONNECTING_SOCAIL: 'CONNECTING_SOCAIL',
  CONNECTING_SOCAIL_SUCCESS: 'CONNECTING_SOCAIL_SUCCESS',
  CONNECTING_SOCAIL_FAILED: 'CONNECTING_SOCAIL_FAILED',
  DELETING_SOCIAL: 'DELETING_SOCIAL',
  DELETING_SOCIAL_SUCCESS: 'DELETING_SOCIAL_SUCCESS',
  DELETING_SOCIAL_FAILED: 'DELETING_SOCIAL_FAILED',

  loadUserInfo: (userId: number) => ({
    type: actions.LOAD_USER_INFO,
    payload: userId
  }),
  loadUserInfoSuccess: (data) => ({
    type: actions.LOAD_USER_INFO_SUCCESS,
    payload: data
  }),
  loadUserInfoFailed: () => ({
    type: actions.LOAD_USER_INFO_FAILED
  }),
  loadUserSocialInfo: (userId: number) => ({
    type: actions.LOAD_USER_SOCIAL_INFO,
    payload: userId
  }),
  loadUserSocialInfoSuccess: (data) => ({
    type: actions.LOAD_USER_SOCIAL_INFO_SUCCESS,
    payload: data
  }),
  loadUserSocialInfoFailed: () => ({
    type: actions.LOAD_USER_SOCIAL_INFO_FAILED
  }),
  connectingSocial: () => ({
    type: actions.CONNECTING_SOCAIL
  }),
  connectingSocialSuccess: () => ({
    type: actions.CONNECTING_SOCAIL_SUCCESS
  }),
  connectingSocialFailed: () => ({
    type: actions.CONNECTING_SOCAIL_FAILED
  }),
  deletingSocial: () => ({
    type: actions.DELETING_SOCIAL
  }),
  deletingSocialSuccess: () => ({
    type: actions.DELETING_SOCIAL_SUCCESS
  }),
  deletingSocialFailed: () => ({
    type: actions.DELETING_SOCIAL_FAILED
  })
};

export default actions;
