import CloseIcon from 'assets/svgs/CloseIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import React, { useEffect, useState } from 'react';
import { apiChatAddAgent, apiGetChatAgents } from 'shared/api/chat';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { MobileCardAddBot } from './CardBot';
import { MobileCardAddBotSelected } from './CardSelectedBot';

import './index.styles.scss';

export const MobilePromptAddBot = ({
  open,
  roomId,
  currentBots,
  onClose,
  onSuccess
}) => {
  const [arrSelected, setArrSelected] = useState<Array<any>>([]);
  const [arrBot, setArrBot] = useState<Array<any>>([]);
  const [query, setQuery] = useState('');

  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    if (open) {
      loadAgent();
    }
  }, [query, roomId, open]);

  const loadAgent = async () => {
    const res = await apiGetChatAgents({ room: roomId, query: query });

    if (res.success) {
      setArrBot(res.data);
    }
  };

  const onAddBots = async () => {
    if (!isAdding && arrSelected.length > 0) {
      setIsAdding(true);

      const bots = arrSelected.map((item, index) => {
        return {
          ai_id: item.id,
          ai_type: item.ai_type
        };
      });

      const res = await apiChatAddAgent({
        room: roomId,
        bots: bots
      });

      setIsAdding(false);
      if (res.success) {
        onSuccess(res.agents);
      }
    }
  };

  const onBack = () => {
    onClose();
  };

  const onSearch = (query: string) => {
    setQuery(query);
  };

  const onSelected = (botItem) => {
    if (
      arrSelected.filter((item, index) => item.id === botItem.id).length > 0
    ) {
      setArrSelected((prev) =>
        prev.filter((item, index) => item.id !== botItem.id)
      );
    } else {
      if (currentBots.length + arrSelected.length >= 4) {
        showToast('error', 'Max limit of 4 bots reached.');
      } else {
        setArrSelected((prev) => [...prev, botItem]);
      }
    }
  };

  const renderHeader = () => {
    return (
      <div className="header_view row_space_between">
        <div className="row_align_items" style={{ gap: 8 }}>
          <div className="align-center" onClick={onBack}>
            <CloseIcon color={'rgba(0, 0, 0, 0.6)'} size={20} />
          </div>
          <div className="font-bold font18">Add Bots</div>
        </div>
        <div className="plus_btn">
          <PlusIcon size={16} color={'#FF3E9A'} />
        </div>
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <div className="search_view">
        <SearchBox
          onSearch={onSearch}
          placeholder="Search here..."
          fontSize={14}
          height="45"
          enableShadow={false}
        />
      </div>
    );
  };

  const renderSelected = () => {
    return (
      <div className="mobile_add_bot_selected_list">
        {arrSelected.map((item, index) => {
          return (
            <MobileCardAddBotSelected
              key={`mobile_card_add_bot_selected+${index}`}
              data={item}
              onRemove={onSelected}
            />
          );
        })}
      </div>
    );
  };

  const renderSelectable = () => {
    return (
      <div className="mobile_add_bot_list">
        {arrSelected.length > 0 && renderSelected()}
        <div
          className="font-bold font12"
          style={{ opacity: 0.2, marginLeft: 16, marginTop: 10 }}
        >
          My Personal AI
        </div>
        <div className="list_body">
          {arrBot.map((item, index) => {
            var isSelected =
              arrSelected.filter((sItem, index) => sItem.id === item.id)
                .length > 0;

            return (
              <MobileCardAddBot
                key={`mobile_card_add_bot_${index}`}
                data={item}
                isSelected={isSelected}
                onPressed={onSelected}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderAction = () => {
    return (
      <div className="action_view">
        <PrimaryButton
          label="Confirm"
          onPressed={onAddBots}
          isLoading={isAdding}
        />
      </div>
    );
  };

  return (
    <div className="mobile_prompt_add_bot">
      {renderHeader()}
      {renderSearch()}
      {renderSelectable()}
      {renderAction()}
    </div>
  );
};
