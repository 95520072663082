import React from 'react';

function QuestionFolder(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 20}
      height={props.size ?? 20}
      fill="none"
      viewBox="0 0 18 16"
    >
      <path
        fill={props.color ?? '#FF3E9A'}
        d="M9.308 11.582a.536.536 0 00-.125-.108l-.141-.075-.159-.067a.833.833 0 00-.75.233.832.832 0 00-.183.267.833.833 0 00-.058.325c0 .219.087.428.241.583.076.078.167.14.267.184.103.04.214.06.325.058a.83.83 0 00.317-.058c.1-.043.19-.106.266-.184a.833.833 0 00.25-.583.833.833 0 00-.066-.325.726.726 0 00-.184-.25zm-.583-6.083a2.5 2.5 0 00-2.167 1.25A.833.833 0 108 7.582a.833.833 0 011.558.417.833.833 0 01-.833.833.833.833 0 000 1.667 2.5 2.5 0 000-5zm5.942-2.917H9.433l-.266-.833A2.5 2.5 0 006.8.082H3a2.5 2.5 0 00-2.5 2.5v10.833a2.5 2.5 0 002.5 2.5h11.667a2.5 2.5 0 002.5-2.5V5.082a2.5 2.5 0 00-2.5-2.5zm.833 10.833a.833.833 0 01-.833.834H3a.833.833 0 01-.833-.834V2.582A.833.833 0 013 1.75h3.8a.833.833 0 01.792.566l.45 1.367a.833.833 0 00.791.567h5.834a.833.833 0 01.833.833v8.333z"
      ></path>
    </svg>
  );
}

export default QuestionFolder;
