import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { SubTopics } from '../SubTopics';

import './index.styles.scss';

interface Props {
  topicData: any;
  userInfo: any;
  selectedSubTopic: Array<any>;
  onUpdateSubTopic: (newTopic) => void;
  onClicked: () => void;
}

export const CardAnimatedMainTopic = ({
  topicData,
  userInfo,
  selectedSubTopic,
  onUpdateSubTopic,
  onClicked
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const { x, y } = useSpring({
    from: { x: 1, y: 0 },
    to: { x: 1, y: 1 },
    config: {
      tension: 400,
      friction: 5,
      velocity: 0,
      mass: 0.5
    }
  });

  const expand = y.interpolate({
    range: [0, 1],
    output: ['0px', '177px']
  });

  const bounce = y.interpolate({
    range: [0, 0.2, 0.4, 0.6, 0.8, 1],
    output: [0, -10, -20, -10, 0, 0]
  });

  const rectStyle = useSpring({
    width: isExpanded ? '76px' : '76px',
    height: isExpanded ? '177px' : '0px',
    onRest: () => {
      setShowInfo(true);
    }
  });

  useEffect(() => {
    setIsExpanded(true);
  }, [topicData]);

  return (
    <animated.div
      className="mobile_card_animated_main_topic"
      style={{
        width: '76px',
        height: expand,
        transform: `translate3d(0, ${bounce}px, 0)`
      }}
      onClick={onClicked}
    >
      <div style={{ fontSize: 20 }}>{topicData?.topic_emoji}</div>
      <div className="main_topic_name font-medium font14">
        {topicData?.topic_name}
      </div>

      <SubTopics
        selectedTopic={topicData}
        userId={userInfo.id}
        selectedSubTopic={selectedSubTopic}
        onUpdateSubTopic={onUpdateSubTopic}
      />
    </animated.div>
  );
};
