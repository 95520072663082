import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useIntersection } from './useIntersection';
import { IFlat } from './types';

const CustomCircleProgressBar: React.FC<IFlat> = ({
  progress = 0,
  range = { from: 0, to: 100 },
  sx,
  size = 110,
  showSubTrack = true
}) => {
  const {
    loadingTime = 1000,
    bgStrokeColor = '#ffffff',
    strokeLinecap = 'round',
    shape = 'full',
    intersectionEnabled = false,
    miniCircleSize = 5,
    miniCircleColor = '#ff0000'
  } = sx;

  const [afterProgress, setAfterProgress] = useState(0);
  const flatRef = useRef<HTMLDivElement>(null);
  const prevCountRef = useRef(0);
  const { isVisible } = useIntersection(flatRef);

  const setShape = (): number => {
    switch (shape) {
      case 'full':
        return 100;
      case 'threequarters':
        return 75;
      case 'half':
        return 50;
      default:
        return 100;
    }
  };

  const setRotate = (): string => {
    switch (shape) {
      case 'full':
        return 'rotate(-90, 55, 55)';
      case 'threequarters':
        return 'rotate(135, 55, 55)';
      case 'half':
        return 'rotate(180, 55, 55)';
      default:
        return 'rotate(-90, 55, 55)';
    }
  };

  const setRatio = (): number => {
    switch (shape) {
      case 'full':
        return 1;
      case 'threequarters':
        return 0.75;
      case 'half':
        return 0.5;
      default:
        return 1;
    }
  };

  const setAngle = (): number => {
    switch (shape) {
      case 'full':
        return 0;
      case 'threequarters':
        return 135;
      case 'half':
        return 90;
      default:
        return 0;
    }
  };

  useEffect(() => {
    if ((intersectionEnabled && isVisible) || !intersectionEnabled) {
      setAfterProgress(progress * setRatio());
      prevCountRef.current = afterProgress;
    }
  }, [progress, shape, isVisible]);

  const dasharray = 2 * Math.PI * 50;
  const dashoffset = (1 - (afterProgress + range.from) / range.to) * dasharray;

  const renderTrack = () => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 110 110"
        style={{ position: 'relative', zIndex: 50 }}
      >
        <circle
          cx="55"
          cy="55"
          r="50"
          style={{
            transition: 'stroke-dashoffset ease-in-out',
            transitionDuration: loadingTime.toString().concat('ms')
          }}
          strokeWidth={sx.barWidth}
          transform={setRotate()}
          fill="none"
          stroke={sx.strokeColor}
          shapeRendering="geometricPrecision"
          strokeLinecap={strokeLinecap}
          strokeDasharray={dasharray}
          strokeDashoffset={dashoffset}
        />
      </svg>
    );
  };

  const renderSubTrack = () => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 110 110"
        style={
          {
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 10,
            '--ds1': 'drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))',
            '--ds2': 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.1))',
            filter: 'var(--ds1) var(--ds2)'
          } as CSSProperties
        }
      >
        <circle
          cx="55"
          cy="55"
          r="50"
          fill="none"
          stroke={sx.strokeColor}
          strokeWidth={2}
          strokeDasharray={dasharray}
          strokeLinecap={strokeLinecap}
          strokeDashoffset={(1 - setShape() / 100) * dasharray}
          transform={setRotate()}
          shapeRendering="geometricPrecision"
        />
      </svg>
    );
  };

  const renderMiniCircle = () => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 110 110"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: '50',
          transition: 'transform ease-in-out',
          MozTransition: 'transform ease-in-out',
          transitionDuration: loadingTime.toString().concat('ms'),
          overflow: 'visible'
        }}
        transform={`rotate(${
          afterProgress * (3.6 / (range.to / 100)) - setAngle()
        }, 0, 0)`}
      >
        <circle
          cx="55"
          cy={miniCircleSize / 2 + sx.barWidth / 2}
          r={miniCircleSize}
          fill={miniCircleColor}
        ></circle>
      </svg>
    );
  };

  return (
    <div
      ref={flatRef}
      style={{ position: 'relative', width: size, height: size }}
    >
      {renderTrack()}
      {showSubTrack && renderSubTrack()}
      {renderMiniCircle()}
    </div>
  );
};

export default CustomCircleProgressBar;
