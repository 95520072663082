import CloseIcon from 'assets/svgs/CloseIcon';
import CommentDotIcon from 'assets/svgs/CommentIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import { MobileShareBotModal } from 'components/Modals/MobileShare/ShareBot';
import React, { useEffect, useMemo, useState } from 'react';
import Sheet from 'react-modal-sheet';
import ReactHowler from 'react-howler';
import { useNavigate } from 'react-router-dom';
import { apiGetChatIntroduce } from 'shared/api/chat';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';

import './index.styles.scss';
import { useAudioContext } from 'shared/contexts/AudioContext';
import AudioPauseIcon from 'assets/svgs/AudioPauseIcon';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';

interface Props {
  open: boolean;
  aiInfo: any;
  onClose: () => void;
}

export const MobileChatIntroModal = ({ open, aiInfo, onClose }: Props) => {
  const navigate = useNavigate();

  const [chatDetail, setChatDetail] = useState<any>(null);
  const [showShareModal, setShowShareModal] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const { playingAudio, setPlayingAudio } = useAudioContext();

  useEffect(() => {
    if (aiInfo && open) {
      loadDetail();
    }
  }, [aiInfo, open]);

  const audio = useMemo(() => {
    if (chatDetail) {
      return chatDetail.description_audio;
    } else {
      return null;
    }
  }, [chatDetail]);

  useEffect(() => {
    if (!!audio && (playingAudio ?? '') === audio) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [playingAudio]);

  const togglePlayback = () => {
    if (isPlaying) {
      setPlayingAudio(null);
    } else {
      if (playingAudio) {
        setPlayingAudio(null);
      }
      setPlayingAudio(audio);
    }
  };

  const loadDetail = async () => {
    const res = await apiGetChatIntroduce({
      ai_id: aiInfo.ai_id,
      ai_type: aiInfo.ai_type
    });

    if (res.success) {
      setChatDetail(res.data);
    }
  };

  const onChat = () => {
    navigate(`/prompt/conversation/${aiInfo.ai_id}/null`);
    onClose();
  };

  const onProfile = () => {
    navigate(`/profile/${chatDetail.creator_id}`);
    onClose();
  };

  const renderHeader = () => {
    return (
      <div className="header_view row_align_items row_space_between">
        <div
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <CloseIcon color={'rgba(0, 0, 0, 0.2)'} size={24} />
        </div>
        <div
          className="font-bold font16"
          style={{ textAlign: 'center', flex: 1 }}
        >
          Introduction
        </div>

        {renderShareBtn()}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="chat_intro_body">
        <div style={{ position: 'relative' }}>
          {chatDetail?.ai_image && chatDetail?.ai_image.startsWith('https') ? (
            <img src={chatDetail?.ai_image} className="chat_ai_image" />
          ) : (
            <DefaultAvatarIcon size={88} />
          )}
          {renderAudioIcon()}
        </div>

        <div className="sub_txt">
          <div className="personal_ai_txt font-bold font16">Personal AI</div>
          <div className="font-bold font20">{chatDetail?.ai_name}</div>
          <div
            className="row_align_items"
            style={{ gap: 4 }}
            onClick={onProfile}
          >
            {chatDetail?.creator_image &&
            chatDetail?.creator_image.startsWith('https') ? (
              <img src={chatDetail?.creator_image} className="creator_image" />
            ) : (
              <DefaultAvatarIcon size={16} />
            )}
            <div className="font-regular font10">
              {chatDetail?.creator_name}
            </div>
          </div>
        </div>
        <div className="chat_intro_desc font-regular font12">
          {chatDetail.description}
        </div>
        {renderChatBtn()}
      </div>
    );
  };

  const renderAudioIcon = () => {
    if (!!audio) {
      return (
        <div className="audio_play_btn" onClick={togglePlayback}>
          {isPlaying ? (
            <AudioPauseIcon size={16} color={'white'} />
          ) : (
            <AudioPlayIcon size={16} color={'white'} />
          )}
          {isPlaying && (
            <ReactHowler
              src={audio}
              playing={isPlaying}
              loop={false}
              html5={true} // Use HTML5 Audio API
              onLoad={() => {}}
              onEnd={() => setPlayingAudio(null)}
            />
          )}
        </div>
      );
    } else {
      return <div />;
    }
  };

  const renderShareBtn = () => {
    return (
      <div
        className="share_btn"
        onClick={(e) => {
          e.stopPropagation();

          setShowShareModal(true);
          onClose();
        }}
      >
        <ShareIcon />
      </div>
    );
  };

  const renderChatBtn = () => {
    return (
      <div className="chat_intro_btn row_align_items" onClick={onChat}>
        <CommentDotIcon color={'white'} />
        <div className="font-bold font14">Chat</div>
      </div>
    );
  };

  const renderLoadingBar = () => {
    return (
      <div className="loading_container">
        <LoadingBarAnimation />
      </div>
    );
  };

  const renderShareModal = () => {
    return (
      <MobileShareBotModal
        show={showShareModal}
        data={chatDetail}
        onClose={() => {
          setShowShareModal(false);
        }}
      />
    );
  };

  if (open) {
    return (
      <Sheet isOpen={open} onClose={onClose} detent={'content-height'}>
        <Sheet.Container
          style={{ borderTopLeftRadius: 32, borderTopRightRadius: 32 }}
        >
          <Sheet.Content>
            <div className="mobile_chat_intro_modal">
              <div
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  height: '4px',
                  width: '56px',
                  borderRadius: 4
                }}
              />
              {renderHeader()}
              {chatDetail ? renderBody() : renderLoadingBar()}
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    );
  } else {
    return renderShareModal();
  }
};
