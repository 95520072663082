import ArrowLeftIcon from 'assets/svgs/ArrowLeftIcon';
import EnvelopeIcon from 'assets/svgs/EnvelopeIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import { JoinOrgByEmailModal } from 'components/Modals/JoinOrgByEmailModal';
import { OrgJoinMagicModal } from 'components/Modals/OrgJoinMagicModal';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { CarGroup } from './CardGroup';
import { CreateNewGroup } from './CreateNewOrg';
import InfiniteScrollWrapper from './Wrapper';

import './index.styles.scss';
import { socket } from 'components/Auth';
import { getArrFromString } from 'shared/utils/utils';
import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import { apiGetGroupSearch } from 'shared/api/group';

export const JoinNewGroup = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState<'main' | 'pending' | 'create'>('main');
  const [showJoinByEmailModal, setShowJoinByEmailModal] = useState(false);
  const [showMagicLinkModal, setShowMagicLinkModal] = useState(false);
  const [createOrgModal, setCreateOrgModal] = useState(false);
  const [groups, setGroups] = useState<Array<any>>([]);
  const [hasGroupMore, setHasGroupMore] = useState(true);
  const [lastId, setLastId] = useState(0);
  const [queryOrg, setQueryOrg] = useState('');

  const [additionalEmail, setAdditionalEmail] = useState('');
  const [pendingCount, setPendingCount] = useState(0);

  useEffect(() => {
    if (tab === 'pending')
      setGroups((prev) =>
        prev.filter((item, index) => item.isFollowed === 'requested')
      );
    else if (tab === 'main') loadOrganisations('', true);
  }, [tab]);

  useEffect(() => {
    if (socket) {
      const newMessageHandler = (data) => {
        if (data.orgs) {
          onUpdateOrg(data);
        }
      };

      socket.on('email-verified', newMessageHandler);

      return () => {
        socket.removeListener('email-verified', newMessageHandler);
      };
    }
  }, [socket]);

  const onBack = () => {
    if (tab === 'main') navigate(-1);
    else if (tab === 'pending') setTab('main');
  };

  const onSearch = (query) => {
    setQueryOrg(query);
    loadOrganisations(query, true);
  };

  const onNext = () => {
    loadOrganisations('', false);
  };

  const onUpdateOrg = (arrOrgId) => {
    const arrJoined = getArrFromString(arrOrgId, ',');
    setGroups((prev) => {
      const newArr = prev.map((item, index) => {
        if (arrJoined.includes(item.id)) {
          return {
            ...item,
            isFollowed: 'true'
          };
        } else {
          return item;
        }
      });

      return newArr;
    });
  };

  const loadOrganisations = async (query = '', init = true) => {
    if (init) {
      const res = await apiGetGroupSearch({
        query: query,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setGroups(res.data);
        setHasGroupMore(res.hasMore);
        setLastId(res.lastId);
        setPendingCount(res.requests);
      }
    } else {
      const res = await apiGetGroupSearch({
        query: queryOrg,
        limit: 10,
        offset: lastId
      });
      if (res.success) {
        if (tab === 'pending')
          setGroups((prev) =>
            [...prev, ...res.data].filter(
              (item, index) => item.isFollowed === 'requested'
            )
          );
        else setGroups((prev) => [...prev, ...res.data]);
        setHasGroupMore(res.hasMore);
        setLastId(res.lastId);
      }
    }
  };

  const renderHeader = () => {
    return (
      <div className="join_new_org_header">
        <div className="header_title">
          <div
            className="navigation_back_btn clickable"
            onClick={() => onBack()}
          >
            <AngleLeftIcon color="#000000" size={24} />
          </div>
          <div className="title_text font-bold font16">
            {tab === 'main' ? 'Join New Group' : 'Pending Groups'}
          </div>
        </div>
        <div className="header_buttons"></div>
      </div>
    );
  };

  const renderQuestionInput = () => {
    return (
      <div style={{ marginTop: 70 }}>
        <SearchBox
          onSearch={onSearch}
          width="100%"
          height="44"
          backgroundColor="#FFFFFF"
          placeholder="Search org here..."
          fontSize={16}
          searchIconClickable={true}
          type="joinNewOrg"
        />
      </div>
    );
  };

  const renderGroups = () => {
    return (
      <div className="card_org_panel">
        {tab === 'main' && (
          <div className="card_org_list_header">
            <div className="header_text font-bold font18">Trending Groups </div>
            <div
              className="pending_count font-bold font14 clickable"
              onClick={() => setTab('pending')}
            >
              {pendingCount > 0 && `Pending(${pendingCount})`}
            </div>
          </div>
        )}
        <InfiniteScrollWrapper
          isInfiniteScrollOn={true}
          lengthData={groups.length}
          functionNext={onNext}
          hasOrgsMore={hasGroupMore}
          parentId="scrollableDiv"
        >
          <div className="card_org_list">
            {groups.map((item, index) => {
              return <CarGroup key={`car_org_${index}`} data={item} />;
            })}
          </div>
        </InfiniteScrollWrapper>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <>
        {renderHeader()}
        {tab === 'main' && renderQuestionInput()}
        {renderGroups()}
      </>
    );
  };

  const renderCreate = () => {
    return <CreateNewGroup setTab={setTab} />;
  };

  const renderJoinByEmailModal = () => {
    return (
      <JoinOrgByEmailModal
        show={showJoinByEmailModal}
        onClose={() => setShowJoinByEmailModal(false)}
        onSuccess={(email) => {
          setShowJoinByEmailModal(false);
          setAdditionalEmail(email);
          setShowMagicLinkModal(true);
        }}
      />
    );
  };

  const renderOrgMagicLinkModal = () => {
    return (
      <OrgJoinMagicModal
        show={showMagicLinkModal}
        onClose={() => setShowMagicLinkModal(false)}
        email={additionalEmail}
      />
    );
  };

  const renderJCreateOrgModal = () => {
    return (
      <JoinOrgByEmailModal
        show={createOrgModal}
        onClose={() => setShowJoinByEmailModal(false)}
        onSuccess={() => {
          setShowJoinByEmailModal(false);
        }}
      />
    );
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (hasGroupMore) onNext();
    }
  };
  return (
    <div className="mobile_join_new_group_home">
      <div
        className="mobile_join_new_group_home_body"
        onScroll={handleScroll}
        id="scrollableDiv"
      >
        {tab == 'main'
          ? renderMain()
          : tab == 'create'
          ? renderCreate()
          : renderMain()}
        {renderJoinByEmailModal()}
        {renderOrgMagicLinkModal()}
        {renderJCreateOrgModal()}
      </div>
    </div>
  );
};
