import React from 'react';

function ChatIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '24'}
      height={props.size ?? '24'}
      fill="none"
      viewBox="0 0 17 18"
    >
      <path
        fill={props.color ?? '#BDBDBD'}
        d="M15.674 14.993a5.833 5.833 0 00-2.283-8.808 6.667 6.667 0 10-11.825 5.242l-1.158 1.15a.833.833 0 00-.175.908.833.833 0 00.766.517h4.742A5.833 5.833 0 0011 17.335h5a.833.833 0 00.767-.517.833.833 0 00-.175-.908l-.917-.917zM5.166 11.502c.001.279.024.557.067.833H3.008l.291-.283a.834.834 0 000-1.184 4.941 4.941 0 01-1.466-3.533 5 5 0 015-5A4.95 4.95 0 0111.54 5.67H11a5.833 5.833 0 00-5.833 5.833zm8.783 4.166l.042.042H11a4.167 4.167 0 112.95-1.217.834.834 0 00-.25.584.835.835 0 00.25.591z"
      ></path>
    </svg>
  );
}

export default ChatIcon;
