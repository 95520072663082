import React from 'react';

function ArrowPlayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_8523_205601)">
        <path
          fill="#0F0F0F"
          d="M10 8.64L15.27 12 10 15.36V8.64zM8 5v14l11-7L8 5z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_8523_205601">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ArrowPlayIcon;
