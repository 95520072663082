import React, { useEffect, useState } from 'react';

import { CardFlipQuestionBody } from './QuestionBody';
import { CardFlipAnswerBody } from './AnswerBody';

import './index.styles.scss';
import { MobileFeedSideBar } from '../SideBar';

interface Props {
  data: any;
}

export const MobileCardFlipQuestion = ({ data }: Props) => {
  const [isFlip, setIsFlip] = useState(false);

  const renderRightAction = () => {
    return <MobileFeedSideBar data={data} />;
  };

  return (
    <div
      className="mobile_card_flip_question_container"
      id={`mobile_card_flip_question_container_${data.item_id}`}
    >
      {!isFlip ? (
        <CardFlipQuestionBody
          data={data}
          onFlip={() => {
            setIsFlip(true);
          }}
        />
      ) : (
        <CardFlipAnswerBody
          data={data}
          onFlip={() => {
            setIsFlip(false);
          }}
        />
      )}
      {/* {renderRightAction()} */}
    </div>
  );
};
