import { MobileShareAnswerModal } from 'components/Modals/MobileShare/ShareAnswer';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './index.styles.scss';
import { MobileFeedSideBar } from '../../SideBar';
import CheckCircleIcon from 'assets/svgs/CheckCircleIcon';

export const CardFlipAnswerBody = ({ data, onFlip }) => {
  const navigate = useNavigate();

  const [showShareModal, setShowShareModal] = useState(false);

  const renderHeader = () => {
    return (
      <div className="row_align_items row_space_between">
        <div className="mark row_align_items">
          <div className="light_view">
            <CheckCircleIcon />
          </div>
          <div
            className="font-bold font16"
            style={{ color: '#FF3E9A', marginLeft: 6 }}
          >
            Answer
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="flip_answer_body">
        <div className="feed_main_content">
          {data.images && data.images.startsWith('https') ? (
            <img src={data.images} className="flip_answer_image" />
          ) : (
            <></>
          )}
          <div className="question_txt article-font-regular">{data.text}</div>
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="flip_answer_bottom">
        <div className="flip_action_conatiner row_align_items">
          <div className="flip_btn font-bold font14" onClick={onFlip}>
            Flip
          </div>
        </div>
      </div>
    );
  };

  const renderShareModal = () => {
    return (
      <MobileShareAnswerModal
        question={data}
        show={showShareModal}
        onClose={() => {
          setShowShareModal(false);
        }}
      />
    );
  };

  return (
    <div className="mobile_card_flip_answer">
      {renderHeader()}
      {renderBody()}
      {renderBottom()}
      {renderShareModal()}
    </div>
  );
};
