import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

/** DOCS { https://github.com/ankeetmaini/react-infinite-scroll-component#readme } */

interface InfiniteScrollWrapperProps {
  isInfiniteScrollOn: boolean;
  lengthData: number;
  functionNext: any;
  children: any;
  hasOrgsMore: boolean;
  parentId: string;
}

export const InfiniteScrollWrapper = ({
  isInfiniteScrollOn,
  lengthData,
  functionNext,
  children,
  hasOrgsMore,
  parentId
}: InfiniteScrollWrapperProps) => {
  if (!isInfiniteScrollOn) return <>{children}</>;
  return (
    <div style={{ marginTop: 'opx', width: '100%' }}>
      <InfiniteScroll
        dataLength={lengthData} //This is important field to render the next data
        next={functionNext}
        hasMore={hasOrgsMore}
        loader={<h4></h4>}
        scrollableTarget={parentId}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b></b>
          </p>
        }
      >
        {children} {/** !!! DO NOT TOUCH !!! => It wraps the table */}
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteScrollWrapper;
