import CloseIcon from 'assets/svgs/CloseIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React from 'react';

import './index.styles.scss';

interface Props {
  data: any;
  onRemove: (item) => void;
}

export const MobileCardAddBotSelected = ({ data, onRemove }: Props) => {
  const renderRemoveIcon = () => {
    return (
      <div className="remove_icon" onClick={() => onRemove(data)}>
        <CloseIcon color={'white'} size={12} />
      </div>
    );
  };

  return (
    <div className="mobile_card_add_bot_selected">
      {data.ai_image && data.ai_image.startsWith('https') ? (
        <img src={data.ai_image} className="bot_image" />
      ) : (
        <DefaultAvatarIcon size={40} />
      )}
      <div className="ai_name font-bold font10">{data.ai_name}</div>
      {renderRemoveIcon()}
    </div>
  );
};
