import React from 'react';

function FilterCheckIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '24'}
      height={props.size ?? '24'}
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect width="24" height="24" fill="#FF3E9A" rx="12"></rect>
      <path
        fill="#fff"
        d="M16.475 8.807a.666.666 0 00-.947 0l-4.966 4.973-2.087-2.093a.68.68 0 10-.947.98l2.56 2.56a.665.665 0 00.947 0l5.44-5.44a.666.666 0 000-.98z"
      ></path>
    </svg>
  );
}

export default FilterCheckIcon;
