import React, { useEffect, useState } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { apiGetUserOrganisations } from 'shared/api/user';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { CardJoinedOrg } from './CardJoinedOrg';

import BG_EMPTY_CREATOR from 'assets/pngs/bg_empty_chat_creator.png';

import './index.styles.scss';
import { useNavigate } from 'react-router-dom';

export const JoinedOrg = ({
  selectedOrg,
  onSelected,
  isLoadedAll,
  setIsLoaded
}) => {
  const navigate = useNavigate();
  const [arrOrg, setArrOrg] = useState<Array<any>>([]);
  const [lastIdOrg, setLastIdOrg] = useState(0);
  const [hasMoreOrg, setHasMoreOrg] = useState(true);
  const [isLoadingOrg, setIsLoadingOrg] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (init = true, keyword = '') => {
    if (init) {
      setIsLoadingOrg(true);
      const res = await apiGetUserOrganisations(null, 10, 0, keyword);

      if (res.success) {
        setArrOrg(res.data);
        setLastIdOrg(res.lastId);
        setHasMoreOrg(res.hasMore);
        if (res.data.length > 0) {
          onSelected(res.data[0]);
        }
      }
      setIsLoaded(true);
      setIsLoadingOrg(false);
    } else {
      if (hasMoreOrg && !isLoadingOrg) {
        const res = await apiGetUserOrganisations(null, 10, lastIdOrg, keyword);

        if (res.success) {
          setArrOrg((prev) => [...prev, ...res.data]);
          setLastIdOrg(res.lastId);
          setHasMoreOrg(res.hasMore);
        }
      }
    }
  };

  const onLoadMoreData = () => {
    loadData(false);
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const renderBody = () => {
    return (
      <div className="mobile_joined_org_body" id="mobile_joined_org_body">
        <ScrollMenu onWheel={onWheel} onUpdate={onLoadMoreData}>
          {arrOrg.map((item, index) => {
            return (
              <CardJoinedOrg
                index={index}
                isSelected={item.id === selectedOrg?.id}
                onSelected={onSelected}
                data={item}
                key={`card_joined_org_${index}`}
              />
            );
          })}
        </ScrollMenu>
      </div>
    );
  };

  const renderEmptyBody = () => {
    return (
      <div className="mobile_joined_empty_body">
        <img
          src={BG_EMPTY_CREATOR}
          style={{ width: 300, height: 230, marginBottom: 30 }}
        />
        <div
          className="font-bold font16"
          style={{ margin: '0px 16px', textAlign: 'center' }}
        >
          {' '}
          {`It looks like you haven’t joined any school yet.`}
        </div>
        <div
          className="font-regular font12"
          style={{
            color: 'rgba(0, 0, 0, 0.4)',
            marginTop: 8,
            textAlign: 'center'
          }}
        >
          {`Take a moment to explore and join your schools.`}
          <br />
          <span
            className="clickable font-bold font12"
            style={{ marginTop: 16, color: '#FF3E9A' }}
            onClick={() => {
              navigate('/org/join');
            }}
          >
            {'Start exploring now!'}
          </span>
        </div>
      </div>
    );
  };

  if (isLoadedAll) {
    if (arrOrg.length > 0) {
      return (
        <div className="mobile_org_joined">
          <div
            className="row_space_between row_align_items"
            style={{ marginLeft: 16 }}
          >
            <div className="font-bold font18">Joined Schools</div>
          </div>
          {renderBody()}
        </div>
      );
    } else {
      return <div className="mobile_org_joined">{renderEmptyBody()}</div>;
    }
  } else {
    return (
      <div className="mobile_org_joined">
        <LoadingBarAnimation />
      </div>
    );
  }
};
