import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { MobileIndividualConversationHeader } from './Header';

import './index.styles.scss';
import { apiChatCreateMultiChat, apiChatGet } from 'shared/api/chat';
import { MobileIndividualChatBody } from './Body';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import { socket } from 'components/Auth';
import { SocketEventName } from 'shared/socket/socket_events';
import { RootState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import { MobileMultichatActionModal } from 'components/Modals/Mobile/MultiChatActionModal';
import { ConversationVoteModal } from 'components/Modals/ConversationVoteModal';
import { MobileShareChatModal } from 'components/Modals/Mobile/ShareChatModal';

const MAX_RESPONSE_TIME = 60;

export const MobileIndividualConversation = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [aiId, setAiId] = useState(params.id);
  const [roomId, setRoomId] = useState(params.roomId);

  // conversation
  const [roomInfo, setRoomInfo] = useState<
    IndividualChatRoomData | undefined
  >();
  const [isEndedRoom, setIsEndedRoom] = useState(false);

  // modals
  const [showActionDlg, setShowActionDlg] = useState(false);
  const [showVoteModal, setShowVoteModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  // messages
  const [arrMessage, setArrMessage] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMessage, setIsLoadingMessage] = useState(true);

  const [arrShareMessage, setArrShareMessage] = useState<Array<any>>([]);
  const [newMessage, setNewMessage] = useState('');

  // others
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [showFullShareOption, setShowFullShareOption] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedMessageId, setSelectedMessageId] = useState<any>(null);

  let timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [resendTime, setResendTime] = useState(MAX_RESPONSE_TIME);

  useEffect(() => {
    if (roomId && roomId !== 'null') {
      checkRoomInfo();
      loadMessage();
    } else {
      onCreateChat();
    }
  }, [roomId, query]);

  useEffect(() => {
    if (socket && roomId && roomId !== 'null') {
      socket.emit('join-room', `chat-ai-${roomId}`);
      console.log('join-room', `chat-ai-${roomId}`);

      const newMessageHandler = (data) => {
        onAddMessageOnUI(data);
        onReceivedEndEvent();
      };

      const newUserIdHandler = (data) => {
        onUpdateMessageInfo(data.tmp_id, data.id, data.audio);
      };

      socket.on('new-chat-message-ai', newMessageHandler);
      socket.on('user-message-id', newUserIdHandler);

      return () => {
        socket.removeListener('new-chat-message-ai', newMessageHandler);
        socket.removeListener('user-message-id', newUserIdHandler);
      };
    }
  }, [socket, roomId]);

  useEffect(() => {
    onEnableInput();
  }, [resendTime]);

  const checkRoomInfo = async () => {
    const res = await apiChatGet({
      room: roomId,
      ai_id: aiId,
      ai_type: 'user',
      limit: 10,
      offset: 0
    });

    if (res.success) {
      if (res.room) {
        setRoomId(res.room.id);
        setRoomInfo(res.room);
        setIsEndedRoom(res.room.is_voted);
      }
    }
  };

  const loadMessage = async (init = true) => {
    if (init) {
      setIsLoadingMessage(true);
      const res = await apiChatGet({
        query: !isSearching ? null : query,
        room: roomId,
        ai_id: parseInt(aiId!),
        ai_type: 'user',
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrMessage(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      } else {
        setArrMessage([]);
      }

      setIsLoadingMessage(false);
    } else {
      if (!isLoadingMessage && hasMore) {
        setIsLoadingMessage(true);

        const res = await apiChatGet({
          query: !isSearching ? null : query,
          room: roomId,
          ai_id: parseInt(aiId!),
          ai_type: 'user',
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrMessage((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoadingMessage(false);
      }
    }
  };

  const onCreateChat = async () => {
    const res = await apiChatCreateMultiChat({
      ai_id: aiId,
      ai_type: 'user'
    });

    if (res.sucess) {
      setRoomInfo(res.room);
      setRoomId(res.room.id);

      navigate(`/conversation/${aiId}/${res.room.id}`, {
        replace: true
      });
    }
  };

  const onBack = () => {
    if (isSharing) {
      setIsSharing(false);
    } else {
      navigate(-1);
    }
  };

  const onSendMessage = (audioUrl) => {
    if (!!newMessage && !isWaitingResponse) {
      onStartCounter();

      const tmpId = new Date().getTime();
      socket.emit(SocketEventName.send_message_chat_ai, {
        room: roomId,
        creator: userInfo?.id,
        type: 'text',
        message: newMessage,
        tmp_id: tmpId,
        audio: audioUrl
      });

      const newMessageItem = {
        ai_id: aiId,
        ai_image: userInfo?.image,
        ai_name: userInfo?.name,
        ai_type: 'user',
        is_ai: 0,
        message: newMessage,
        type: 'text',
        creator: userInfo?.id,
        audio: audioUrl,
        tmp_id: String(tmpId)
      };

      setNewMessage('');
      onAddMessageOnUI(newMessageItem);
    }
  };

  const onSuccessShare = () => {
    setIsSharing(false);
    setArrShareMessage([]);
    showToast('success', 'Successfully shared!');
  };

  const onAddMessageOnUI = (data) => {
    setArrMessage((prev) => [{ ...data, is_new: true }, ...prev]);
    setIsWaitingResponse((prev) => !prev);
  };

  const onSelectedMessage = (newItem) => {
    if (
      arrShareMessage.filter((item, index) => item.id === newItem.id).length > 0
    ) {
      setArrShareMessage((prev) =>
        prev.filter((item, index) => item.id !== newItem.id)
      );
    } else {
      if (arrShareMessage.length == 30) {
        // show message
        showToast('error', 'Max limit of 30 messages reached.');
      } else {
        setArrShareMessage((prev) => [...prev, newItem]);
      }
    }
  };

  const onStartCounter = () => {
    console.log('--- on start counter ---');
    if (resendTime > 0) {
      timerRef.current = setInterval(() => {
        setResendTime((prev) => prev - 1);
      }, 1000);
    }
  };

  const onReceivedEndEvent = () => {
    setIsWaitingResponse(false);
    if (timerRef) {
      clearInterval(timerRef?.current!);
      setResendTime(MAX_RESPONSE_TIME);
    }
  };

  const onEnableInput = () => {
    if (resendTime == 0) {
      setIsWaitingResponse(false);

      if (timerRef) {
        clearInterval(timerRef?.current!);
        setResendTime(MAX_RESPONSE_TIME);
      }
    }
  };

  const renderHeader = () => {
    return (
      <MobileIndividualConversationHeader
        roomInfo={roomInfo}
        isSharing={isSharing}
        isEndedRoom={isEndedRoom}
        onBack={onBack}
        onSearch={() => {
          setIsSearching(true);
        }}
        onShare={() => {
          setShowFullShareOption(false);
          setShowShareModal(true);
        }}
        onMoreAction={() => {
          setShowActionDlg(true);
        }}
      />
    );
  };

  const onUpdateMessageInfo = (tmpId, realId, audio) => {
    if (audio) {
      setArrMessage((prev) => {
        return prev.map((item, index) => {
          if (item.tmp_id === String(tmpId) || item.id === realId) {
            return { ...item, id: realId, audio: audio };
          } else {
            return item;
          }
        });
      });
    } else {
      setArrMessage((prev) => {
        return prev.map((item, index) => {
          if (item.tmp_id === String(tmpId)) {
            return { ...item, id: realId };
          } else {
            return item;
          }
        });
      });
    }
  };

  const renderSearchView = () => {
    return (
      <div className="row_space_between">
        <div style={{ width: '100%', padding: '0px 16px' }}>
          <SearchBox
            searchIconClickable={false}
            placeholder="Search messages here..."
            fontSize={14}
            height="40px"
            enableShadow={false}
            onSearch={setQuery}
            onClickedSearchIcon={() => {
              setIsSearching(false);
            }}
          />
        </div>
      </div>
    );
  };

  const renderMessages = () => {
    return (
      <MobileIndividualChatBody
        roomId={roomId}
        roomName={roomInfo?.name}
        message={newMessage}
        isWaitingResponse={isWaitingResponse}
        isSharing={isSharing}
        isEnded={isEndedRoom}
        arrMessage={arrMessage}
        arrShareMessage={arrShareMessage}
        onShowShare={(status, messageId) => {
          if (!isSharing) {
            setSelectedMessageId(messageId);
            setShowFullShareOption(true);
            setShowShareModal(true);
          }
        }}
        onFinishShare={() => {
          setIsSharing(false);
          setArrShareMessage([]);
        }}
        onLoadMore={() => {
          loadMessage(false);
        }}
        onSelectedMessage={onSelectedMessage}
        onChangedInput={setNewMessage}
        onSendMessage={onSendMessage}
        onSuccessShare={onSuccessShare}
      />
    );
  };

  const renderActionDlg = () => {
    return (
      <MobileMultichatActionModal
        open={showActionDlg}
        roomId={roomId}
        onClose={() => {
          setShowActionDlg(false);
        }}
        onEnded={() => {
          setShowVoteModal(true);
        }}
        onAddMessage={(message) => {
          setArrMessage((prev) => [message, ...prev]);
        }}
      />
    );
  };

  const renderChatVoteModal = () => {
    return (
      <ConversationVoteModal
        show={showVoteModal}
        roomId={roomId}
        roomInfo={roomInfo}
        isMobile={true}
        onClose={() => setShowVoteModal(false)}
        onSuccess={() => {
          setShowVoteModal(false);
          setIsEndedRoom(true);
        }}
      />
    );
  };

  const renderShareModal = () => {
    return (
      <MobileShareChatModal
        open={showShareModal}
        roomId={roomId}
        messageId={selectedMessageId}
        showFullOptions={showFullShareOption}
        onClose={() => {
          setShowShareModal(false);
        }}
        onSelectedOption={(option) => {
          if (option === 'selection') {
            setShowShareModal(false);
            setIsSharing(true);
          }
        }}
      />
    );
  };

  return (
    <div className="mobile_individual_conversation">
      {isSearching ? renderSearchView() : renderHeader()}
      {renderMessages()}
      {renderActionDlg()}
      {renderChatVoteModal()}
      {renderShareModal()}
    </div>
  );
};

export interface IndividualChatRoomData {
  id?: number;
  name: string;
  last_interacted: string;
  ai_id: number;
  ai_name: string;
  ai_image: string;
  ai_type: string;
  is_voted: number;
}
