import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiChatCreatePrompt, apiGetChatIntroduce } from 'shared/api/chat';
import { LoadingDialog } from 'shared/ui-kit/LoadingDialog';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { showToast } from 'shared/ui-kit/PrimaryToast';

import './index.styles.scss';

export const MobileCreatePrompt = () => {
  const navigate = useNavigate();
  const params = useParams();

  const aiId = params.aiId;

  const [prompt, setPrompt] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  const [chatDetail, setChatDetail] = useState<any>(null);

  useEffect(() => {
    loadDetail();
  }, [aiId]);

  const loadDetail = async () => {
    const res = await apiGetChatIntroduce({
      ai_id: aiId,
      ai_type: 'personal'
    });

    if (res.success) {
      setChatDetail(res.data);
    }
  };

  const onCreate = async () => {
    if (!isCreating) {
      if (prompt === '') {
        showToast(
          'error',
          'Please provide prompt for your personal AI to continue.'
        );
        return;
      }
      setIsCreating(true);

      const res = await apiChatCreatePrompt({
        prompt: prompt,
        ai_id: aiId,
        ai_type: 'personal'
      });

      if (res.success) {
        showToast(
          'success',
          'Your prompt creation successful. Congratulations!'
        );

        navigate(-1);
      } else {
        showToast('error', res.message);
      }

      setIsCreating(false);
    }
  };

  const onBack = () => {
    navigate(-1);
  };

  const renderHeader = () => {
    return (
      <div className="header_view row_align_items">
        <div className="" onClick={onBack}>
          <BlackBackIcon />
        </div>
        <div
          className="font-bold font16"
          style={{ textAlign: 'center', flex: 1 }}
        >
          {chatDetail?.ai_name} Prompts
        </div>
      </div>
    );
  };

  const renderDesc = () => {
    return (
      <div className="desc_view">
        <div className="desc_icon">📝</div>
        <div className="font-bold font20" style={{ marginTop: 16 }}>
          Define Your Prompts
        </div>
        <div
          className="font-regular font12"
          style={{ textAlign: 'center', margin: '0px 32px', opacity: 0.6 }}
        >
          It is an open-ended prompt where you can explore and design a bot that
          suits your needs
        </div>
      </div>
    );
  };

  const renderInput = () => {
    return (
      <div className="input_view">
        <div className="row_align_items">
          <img src={chatDetail?.ai_image} className="ai_image" />
          <div className="font-bold font12" style={{ marginLeft: 8 }}>
            {chatDetail?.ai_name} AI
          </div>
        </div>
        <textarea
          value={prompt}
          placeholder="Type your prompt here..."
          className="chat_box_input font-regular font16"
          onChange={(e) => {
            setPrompt(e.target.value);
          }}
        />
      </div>
    );
  };

  const renderAction = () => {
    return (
      <div className="action_view">
        <PrimaryButton
          label="Create Prompt"
          isLoading={false}
          onPressed={onCreate}
        />
      </div>
    );
  };

  const renderLoadingModal = () => {
    return (
      <LoadingDialog
        show={isCreating}
        title={'Creating Prompt...'}
        description="This may take a moment..."
        onClose={() => setIsCreating(false)}
      />
    );
  };

  return (
    <div className="mobile_define_prompt">
      {renderHeader()}
      {renderDesc()}
      {renderInput()}
      {renderAction()}
      {renderLoadingModal()}
    </div>
  );
};
