import React from 'react';
import FilterCheckIcon from '../../TypeFilter/svgs/FilterCheckIcon';

import './index.styles.scss';

interface Props {
  data: any;
  isSelected: boolean;
  onSelected: (item) => void;
}

export const MobileCardFeedTopicFilter = ({
  data,
  isSelected,
  onSelected
}: Props) => {
  return (
    <div className="mobile_card_feed_topic_filter">
      <div
        className={`${
          isSelected ? 'selected' : 'default'
        }_filter_item row_align_items row_space_between`}
        onClick={() => {
          onSelected({
            type: 'topic',
            id: data.topic_id,
            item_type: 'topic'
          });
        }}
      >
        <div className="row_align_items">
          <div className="font-regular font14" style={{ marginLeft: 6 }}>
            {data.topic_emoji}
          </div>
          <div className="font-regular font14" style={{ marginLeft: 6 }}>
            {data.topic}
          </div>
        </div>
        {isSelected ? (
          <FilterCheckIcon />
        ) : (
          <div className="default_mark"></div>
        )}
      </div>
    </div>
  );
};
