import HttpClient from './apiClient';

export async function apiOrgSearch(params: {
  query?: string;
  friends?: Boolean;
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `organisation/search`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiOrgRequestJoin(params: { organisation: any }) {
  try {
    const response = await HttpClient.postWithToken(
      `organisation/join`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiOrgRequestMagicLink(params: { email: string }) {
  try {
    const response = await HttpClient.postWithToken(
      `organisation/requestMagicLink`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiOrgVerifyMagicLink(params: { token: string }) {
  try {
    const response = await HttpClient.postWithToken(
      `organisation/verifyEmail`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetOrgMembers(params: {
  organisation: any;
  query?: any;
  limit: any;
  offset: any;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `organisation/members`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiOrgVerifyEmail(params: { token: string }) {
  try {
    const response = await HttpClient.postWithToken(
      `organisation/verifyEmail`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetOrgTopics(params: {
  orgs: any;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `organisation/topicList`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetOrgDetail(params: { organisation: any }) {
  try {
    const response = await HttpClient.getWithToken(`organisation/get`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetOrgMember(params: {
  organisation: any;
  query?: any;
  limit: any;
  offset: any;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `organisation/members`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
