// @ts-nocheck

import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';

// import Cookies from 'js-cookie';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import './index.styles.scss';
import { useCookies } from 'react-cookie';
import { showToast } from 'shared/ui-kit/PrimaryToast';

export const LinkVerifying = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const accessToken = searchParams.get('token');
  const [cookies, setCookie] = useCookies(['accessToken']);

  const navigate = useNavigate();

  useEffect(() => {
    verifyToken();
  }, []);

  const verifyToken = async () => {
    try {
      const config = {
        headers: {
          Authorization: 'Bearer ' + accessToken
        }
      };

      const res = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/user/loginWithMagicLink' ??
          'test.link',
        {},
        config
      );

      if (res.status === 200 && res.data.success) {
        setCookie('accessToken', res.data.accessToken, { path: '/' });
        // Cookies.set('accessToken', res.data.accessToken);
        localStorage.setItem('userId', res.data.userData.id);
        window.location.reload(false);
      } else {
      }
    } catch (error) {
      showToast('error', 'Magic link expired please request again');

      navigate('/');
    }
  };

  return (
    <div className="content_body_link">
      <div className="loading">
        <LoadingCircular size={80} color="main" />
      </div>
      <div className="verifying_link_txt font-bold font24">
        Verifying link....
      </div>
      <div className="will_take_time_txt font-regular font16">
        You will get into the app in a few seconds
      </div>
    </div>
  );
};
