import CopyIcon from 'assets/svgs/CopyIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import React, { useEffect, useState } from 'react';
import { apiGetInvitationStats } from 'shared/api/utils';
import { showToast } from 'shared/ui-kit/PrimaryToast';

import './index.styles.scss';

export const MobileInviteType = () => {
  const [tab, setTab] = useState<'app' | 'group' | 'org'>('app');

  const [code, setCode] = useState('');
  const refferalUrl = process.env.REACT_APP_REFERRAL_URL;

  useEffect(() => {
    getUserCode();
  }, []);

  const getUserCode = async () => {
    const res = await apiGetInvitationStats();
    if (res.success) {
      setCode(res.code);
    }
  };

  const onCopy = () => {
    navigator.clipboard.writeText(refferalUrl + code ?? '');
    showToast('success', 'Successfully copied!');
  };

  const renderTop = () => {
    return (
      <div className="row_align_items">
        <div className="font-bold font16">Invite to: </div>
        <div className="invite_top_view row_align_items">
          <div
            className={
              (tab === 'app' ? 'selectedTab' : 'defaultTab') +
              ` font-bold font16`
            }
            onClick={() => setTab('app')}
          >
            App
          </div>
          {/* <div
            className={
              (tab === 'group' ? 'selectedTab' : 'defaultTab') +
              ` font-bold font16`
            }
            onClick={() => setTab('group')}
          >
            Group
          </div> */}
          {/* <div
            className={
              (tab === 'org' ? 'selectedTab' : 'defaultTab') +
              ` font-bold font16`
            }
            onClick={() => setTab('org')}
          >
            Org
          </div> */}
        </div>
      </div>
    );
  };

  const renderInput = () => {
    return (
      <div className="input_container row_space_between row_align_items">
        <div
          className="row_align_items"
          style={{
            width: 'calc(100% - 30px)'
          }}
        >
          <LinkIcon color={'rgba(0, 0, 0, 0.2)'} />
          <div
            className="font-regular font12"
            style={{
              marginLeft: 16,
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {refferalUrl?.replace('https://', '') + code}
          </div>
        </div>
        <div onClick={onCopy} style={{ width: 24, height: 24 }}>
          <CopyIcon />
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_invite_type">
      {renderTop()}
      {renderInput()}
    </div>
  );
};
