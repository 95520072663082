import CommentDotIcon from 'assets/svgs/CommentIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import FolderOpenIcon from 'assets/svgs/FolderOpenIcon';
import LinkAddIcon from 'assets/svgs/LinkAddIcon';
import MicIcon from 'assets/svgs/MicIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import { MobileAddVoiceModal } from 'components/Modals/Mobile/AddVoiceModal';
import { MobileChatIntroModal } from 'components/Modals/Mobile/ChatIntroModal';
import { MobileAddNewAudio } from 'components/Modals/MobileAddFile/AddNewAudio';
import { MobileAddNewLinkModal } from 'components/Modals/MobileAddFile/AddNewLink';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { apiGetUserVoiceCloned } from 'shared/api/user';
import CustomCircleProgressBar from 'shared/ui-kit/CircleProgressBar';
import { showToast } from 'shared/ui-kit/PrimaryToast';

import './index.styles.scss';

export const MobileProfileCardPersonalAI = ({
  index,
  data,
  isOwner = true
}) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [voice, setVoice] = useState<any>(data.voice);
  const [isCloned, setIsCloned] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const [showAddLinkModal, setShowAddLinkModal] = useState(false);
  const [showAddAudioModal, setShowAddAudioModal] = useState(false);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showAttachVoiceModal, setShowAttachVoiceModal] = useState(false);

  useEffect(() => {
    if (index === 0 && isOwner) {
      loadVoiceCloneStatus();
    } else {
      setIsCloned(data.is_cloned);
      setPercentage(data.cloning_status);
    }
  }, [data, index, isOwner]);

  const loadVoiceCloneStatus = async () => {
    const res = await apiGetUserVoiceCloned({ userId: userInfo?.id });

    if (res.success) {
      setIsCloned(res.is_cloned);
      setPercentage(res.percentage);
    }
  };

  const onClickAddLink = () => {
    setShowAddLinkModal(true);
  };

  const onClickAddAudio = () => {
    setShowAddAudioModal(true);
  };

  const onViewFile = () => {
    if (index === 0 && isOwner) {
      navigate(`/personalAi/${userInfo?.id}/first`);
    } else {
      navigate(`/personalAi/${data.id === -1 ? userInfo?.id : data.id}/second`);
    }
  };

  const onChat = () => {
    if (index === 0 && isOwner) {
      navigate(`/conversation/${userInfo?.id}/null`);
    } else {
      setShowIntroModal(true);
    }
  };

  const onAddLink = (dumpId, link) => {
    // show toast
    showToast('success', 'Successfully populated data!');
  };

  const onAddAudio = (dumpId, link) => {
    // show toast

    showToast('success', 'Successfully populated data!');
  };

  const onAttachVoice = () => {
    setShowAttachVoiceModal(true);
  };

  const progress = useMemo(() => {
    if (isCloned === 1) {
      return 100;
    } else {
      return percentage;
    }
  }, [isCloned, percentage]);

  const progressBarColor = useMemo(() => {
    if (isCloned === 1) {
      return '#22D654';
    } else {
      if (percentage < 0.25) {
        return '#F24F4F';
      } else if (percentage >= 0.25 && percentage < 0.5) {
        return '#EC8362';
      } else if (percentage >= 0.5 && percentage < 1) {
        return '#ECAF39';
      } else {
        return '#22D654';
      }
    }
  }, [isCloned, percentage]);

  const renderImage = () => {
    if (isOwner) {
      return (
        <div className="user_image_container">
          {(isCloned === 0 || isCloned === 1) && (
            <CustomCircleProgressBar
              progress={progress}
              range={{ from: 0, to: 100 }}
              sign={{ value: '%', position: 'end' }}
              text={'Match'}
              showMiniCircle={true}
              showValue={true}
              size={64}
              sx={{
                strokeColor: progressBarColor,
                barWidth: 5,
                shape: 'full',
                strokeLinecap: 'round',
                loadingTime: 1000,
                miniCircleColor: progressBarColor,
                miniCircleSize: 7,
                valueAnimation: true,
                intersectionEnabled: true
              }}
            />
          )}
          <div className="user_image_content">
            <div className="user_image_view" onClick={onAttachVoice}>
              {index === 0 ? (
                userInfo?.image && userInfo.image.startsWith('https') ? (
                  <img src={userInfo?.image} className="ai_image" />
                ) : (
                  <DefaultAvatarIcon size={48} />
                )
              ) : data.image && data.image.startsWith('https') ? (
                <img
                  src={data.image}
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 50,
                    objectFit: 'cover'
                  }}
                />
              ) : (
                <DefaultAvatarIcon size={50} />
              )}
              {!voice && (
                <div
                  className="camera_icon_tag clickable"
                  onClick={onAttachVoice}
                >
                  <MicIcon size={16} />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ position: 'relative' }}>
          {data.id === -1 ? (
            userInfo?.image && userInfo.image.startsWith('https') ? (
              <img
                src={data.id === -1 ? userInfo?.image : data.image}
                className="ai_image"
              />
            ) : (
              <DefaultAvatarIcon size={48} />
            )
          ) : (
            <img src={data.image} className="ai_image" />
          )}
          {renderBotIcon()}
        </div>
      );
    }
  };

  const renderAddLinkModal = () => {
    return (
      <MobileAddNewLinkModal
        aiId={isOwner && index === 0 ? -1 : data.id}
        open={showAddLinkModal}
        onClose={() => {
          setShowAddLinkModal(false);
        }}
        onSuccess={(dumpId, link) => {
          onAddLink(dumpId, link);
        }}
        onViewFile={(aiId) => {
          setShowAddLinkModal(false);
          onViewFile();
        }}
      />
    );
  };

  const renderAddAudioModal = () => {
    return (
      <MobileAddNewAudio
        aiId={isOwner && index === 0 ? -1 : data.id}
        open={showAddAudioModal}
        onClose={() => {
          setShowAddAudioModal(false);
        }}
        onSuccess={(dumpId, link) => {
          onAddAudio(dumpId, link);
        }}
        onViewFile={(aiId) => {
          setShowAddAudioModal(false);
          onViewFile();
        }}
      />
    );
  };

  const renderIntroModal = () => {
    return (
      <MobileChatIntroModal
        open={showIntroModal}
        aiInfo={data}
        onClose={() => {
          setShowIntroModal(false);
        }}
      />
    );
  };

  const renderAddVoiceModal = () => {
    return (
      <MobileAddVoiceModal
        open={showAttachVoiceModal}
        onClose={() => {
          setShowAttachVoiceModal(false);
        }}
        aiInfo={data}
      />
    );
  };

  const renderBotIcon = () => {
    return (
      <div className="bot_icon">
        <RobotIcon size={16} color={'white'} />
      </div>
    );
  };

  return (
    <div
      className={`${
        index === 0
          ? isOwner
            ? `mobile_profile_card_personal_ai`
            : `mobile_profile_card_personal_ai_other`
          : isOwner
          ? `mobile_profile_card_personal_ai mobile_profile_card_personal_ai2`
          : `mobile_profile_card_personal_ai_other mobile_profile_card_personal_ai2`
      } `}
    >
      <div
        className="mobile_profile_card_personal_ai_body"
        style={
          index === 0
            ? {}
            : {
                background:
                  'linear-gradient(180deg, rgba(99, 99, 99, 0.05) 0%, rgba(255, 255, 255, 0) 72.91%)'
              }
        }
      >
        <div className="top_info_view row_align_items">
          {renderImage()}
          <div className="ai_name font-bold font18">
            {isOwner && index === 0 ? 'Personal AI' : data.name}
          </div>
          {isOwner && (
            <div className="row_align_items" style={{ gap: 12 }}>
              <div className="ai_action_btn" onClick={onClickAddLink}>
                <LinkAddIcon />
              </div>
              <div className="ai_action_btn" onClick={onClickAddAudio}>
                <MicIcon color="#FF3E9A" />
              </div>
            </div>
          )}
          {!isOwner && (
            <div className="other_chat_btn" onClick={onChat}>
              <CommentDotIcon color="white" size={24} />
            </div>
          )}
        </div>
        {isOwner && (
          <div className="action_view">
            <div className="file_btn" onClick={onViewFile}>
              <FolderOpenIcon />
              <div className="font-bold font14">Files</div>
            </div>
            <div className="chat_btn" onClick={onChat}>
              <CommentDotIcon color="white" size={24} />
              <div className="font-bold font14" style={{ color: 'white' }}>
                Chat
              </div>
            </div>
          </div>
        )}
      </div>

      {renderAddLinkModal()}
      {renderAddAudioModal()}
      {renderIntroModal()}
      {renderAddVoiceModal()}
    </div>
  );
};
