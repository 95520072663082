import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import { MobileCreatePersonalAiModal } from 'components/Modals/Mobile/CreatePersonalAI';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { apiGetUserPersonalAIs } from 'shared/api/user';
import { showToast } from 'shared/ui-kit/PrimaryToast';

import './index.styles.scss';
import { MobileMyCardPersonalAI } from './MyCardPersonalAI';

export const MobileMyPersonalAI = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [arrAi, setArrAi] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (init = true) => {
    if (init) {
      setIsLoading(true);

      const res = await apiGetUserPersonalAIs({
        userId: userInfo?.id,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrAi(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }

      setIsLoading(false);
    } else {
      if (!isLoading && hasMore) {
        setIsLoading(true);

        const res = await apiGetUserPersonalAIs({
          userId: userInfo?.id,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrAi((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoading(false);
      }
    }
  };

  const onSuccessedToCreate = () => {
    showToast('success', 'Successfully created');
    setShowCreateModal(false);
    loadData();
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;

    if (bottom) {
      loadData(false);
    }
  };

  const renderHeader = () => {
    return (
      <div
        className="row_align_items row_space_between"
        style={{ padding: '0px 16px' }}
      >
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <BlackBackIcon />
        </div>
        <div className="font-bold font16">My Personal AIs</div>
        <div onClick={() => setShowCreateModal(true)}>
          <PlusIcon />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="ai_list" onScroll={handleScroll}>
        {arrAi.map((item, index) => {
          return (
            <MobileMyCardPersonalAI
              key={`mobile_card_personal_ai_${index}`}
              index={index}
              data={item}
              fromProfile={true}
            />
          );
        })}
      </div>
    );
  };

  const renderCreateModal = () => {
    return (
      <MobileCreatePersonalAiModal
        open={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
        }}
        onSuccess={() => {
          onSuccessedToCreate();
        }}
      />
    );
  };

  return (
    <div className="mobile_my_ai">
      {renderHeader()}
      {renderBody()}
      {renderCreateModal()}
    </div>
  );
};
