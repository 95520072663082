import OrgIcon from 'assets/svgs/OrgIcon';
import React, { useEffect, useState } from 'react';

import './index.styles.scss';
interface CardOrgProps {
  data: any;
  setSelectedOrg: (data) => void;
  isSelected: boolean;
}

export const CarOrg = ({ data, setSelectedOrg, isSelected }: CardOrgProps) => {
  const onSelect = async (org) => {
    setSelectedOrg(org);
  };

  return (
    <div className="card_org">
      <div
        className="org_image"
        style={{
          minWidth: 40,
          height: 40,
          borderRadius: 40,
          border: `1px solid rgba(0, 0, 0, 0.1)`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {!data ? (
          <OrgIcon color="#FF3E9A" />
        ) : (
          <img src={data.image} className="org_box_icon" />
        )}
      </div>
      <div className="org_info">
        <div className="first_line">
          <div className="org_title font-bold font14">
            {data ? data.name : 'All Schools'}
          </div>
        </div>
        {data && (
          <div className="second_line">
            <div className="members_count font12 font-normal">
              {data.member_number} members
            </div>
          </div>
        )}
      </div>
      <div className="org_select">
        {!isSelected ? (
          <div
            className="select_btn text-white font12 font-bold clickable"
            onClick={() => onSelect(data)}
          >
            Select
          </div>
        ) : (
          <div className="selected_btn font12 font-bold clickable">
            Selected
          </div>
        )}
      </div>
    </div>
  );
};
