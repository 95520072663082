import React from 'react';

function UserFollowerIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 24}
      height={props.size ?? 24}
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill={props.color ?? '#000'}
        d="M12.8 12.22a4.92 4.92 0 001.7-3.72 5 5 0 00-10 0 4.92 4.92 0 001.7 3.72 8 8 0 00-4.7 7.28 1 1 0 102 0 6 6 0 1112 0 1 1 0 002 0 8 8 0 00-4.7-7.28zm-3.3-.72a3 3 0 110-6 3 3 0 010 6zm9.74.32A5 5 0 0015.5 3.5a1 1 0 100 2 3 3 0 013 3 3 3 0 01-1.5 2.59 1 1 0 00-.05 1.7l.39.26.13.07a7 7 0 014 6.38 1 1 0 002 0 9 9 0 00-4.23-7.68z"
      ></path>
    </svg>
  );
}

export default UserFollowerIcon;
