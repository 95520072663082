import React from 'react';

function SearchIcon(props) {
  return (
    <svg
      width={props.size ?? 18}
      height={props.size ?? 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0916 15.9085L13.9999 12.8418C15.2 11.3455 15.7812 9.44625 15.6239 7.53458C15.4667 5.62292 14.583 3.84415 13.1545 2.56403C11.7261 1.28392 9.86143 0.599756 7.94402 0.652223C6.02662 0.70469 4.20219 1.4898 2.84587 2.84612C1.48956 4.20243 0.704446 6.02686 0.651979 7.94427C0.599512 9.86167 1.28368 11.7263 2.56379 13.1548C3.84391 14.5832 5.62267 15.4669 7.53434 15.6242C9.446 15.7814 11.3453 15.2002 12.8416 14.0002L15.9083 17.0668C15.9857 17.1449 16.0779 17.2069 16.1794 17.2492C16.281 17.2915 16.3899 17.3133 16.4999 17.3133C16.6099 17.3133 16.7189 17.2915 16.8204 17.2492C16.9219 17.2069 17.0141 17.1449 17.0916 17.0668C17.2418 16.9114 17.3257 16.7038 17.3257 16.4877C17.3257 16.2716 17.2418 16.0639 17.0916 15.9085ZM8.16659 14.0002C7.01286 14.0002 5.88505 13.658 4.92576 13.0171C3.96647 12.3761 3.2188 11.4651 2.77729 10.3992C2.33578 9.33325 2.22026 8.16036 2.44534 7.0288C2.67042 5.89725 3.22599 4.85785 4.0418 4.04204C4.8576 3.22624 5.897 2.67066 7.02856 2.44558C8.16012 2.2205 9.333 2.33602 10.3989 2.77753C11.4648 3.21904 12.3759 3.96672 13.0168 4.926C13.6578 5.88529 13.9999 7.01311 13.9999 8.16683C13.9999 9.71393 13.3853 11.1977 12.2914 12.2916C11.1974 13.3856 9.71368 14.0002 8.16659 14.0002Z"
        fill={props.color ?? "#FF3E9A"}
      />
    </svg>
  );
}

export default SearchIcon;

