import ChartIcon from 'assets/svgs/ChartIcon';
import CloseIcon from 'assets/svgs/CloseIcon';
import CommentMedicalIcon from 'assets/svgs/CommentMedicalIcon';
import EditPencilColoredIcon from 'assets/svgs/EditPencilColoredIcon';
import ImageIcon from 'assets/svgs/ImageIcon';
import React from 'react';

import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import './index.styles.scss';

interface Props {
  show: boolean;
  onClose: () => void;
  onSelected: (selected) => void;
}

export const MobileContentAddModal = ({ show, onClose, onSelected }: Props) => {
  const renderItems = () => {
    return (
      <div className="list_container">
        {/* <div
          className="content_item"
          onClick={() => {
            onSelected('media');
          }}
        >
          <ImageIcon />
          <div className="font-regular font12">Upload Media</div>
        </div> */}
        {/* <div
          className="content_item"
          onClick={() => {
            onSelected('post');
          }}
        >
          <EditPencilColoredIcon />
          <div className="font-regular font12">Write New Post</div>
        </div> */}
        <div
          className="content_item"
          onClick={() => {
            onSelected('poll');
          }}
        >
          <ChartIcon />
          <div className="font-regular font12">Create Polling</div>
        </div>
        <div
          className="content_item"
          onClick={() => {
            onSelected('question');
          }}
        >
          <CommentMedicalIcon size={20} />
          <div className="font-regular font12">Ask New Question</div>
        </div>
      </div>
    );
  };

  return (
    <PrimaryModal
      show={show}
      onClose={onClose}
      width={'100%'}
      style={{ backgroundColor: 'transparent', height: '100%', padding: 0 }}
    >
      <div className="mobile_content_add_modal">
        {renderItems()}
        <div className="modal_close_btn" onClick={onClose}>
          <CloseIcon opacity={0.3} size={24} />
        </div>
      </div>
    </PrimaryModal>
  );
};
