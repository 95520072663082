import React from 'react';

function FeedFilterIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '16'}
      height={props.size ?? '16'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#FF3E9A"
        d="M12.665 1.336H3.332a2 2 0 00-2 2v.78a2 2 0 00.167.8v.04c.094.214.227.408.393.573l4.107 4.08v4.394a.666.666 0 00.666.666.668.668 0 00.3-.073l2.667-1.333a.667.667 0 00.367-.594V9.61l4.08-4.08c.165-.165.299-.36.393-.573v-.04a2 2 0 00.193-.8v-.78a2 2 0 00-2-2zM8.86 8.863a.667.667 0 00-.194.473v2.92l-1.333.667V9.336a.667.667 0 00-.193-.473L3.605 5.336h8.787L8.859 8.863zm4.473-4.86H2.665v-.667a.667.667 0 01.667-.667h9.333a.667.667 0 01.667.667v.667z"
      ></path>
    </svg>
  );
}

export default FeedFilterIcon;
