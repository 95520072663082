import React from 'react';

function AngleRightIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '24'}
      height={props.size ?? '24'}
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        fill={props.color ? props.color : '#000'}
        d="M14.83 11.79l-4.24-4.24a1 1 0 10-1.42 1.41l3.54 3.54-3.54 3.54a1 1 0 000 1.41.999.999 0 00.71.29.998.998 0 00.71-.29l4.24-4.24a1 1 0 000-1.42z"
        opacity={props.opacity ? props.opacity : 0.3}
      ></path>
    </svg>
  );
}

export default AngleRightIcon;
