import HttpClient from './apiClient';

export async function apiGetExtensionLinks(params: {
  query?: string;
  topics?: string;
  tag_ids?: string;
  tag_types?: string;
  item_id?: any;
  item_type?: any;
  received?: boolean;
  is_summarised?: string;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`extension/links`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetExtensionTopics(params: {
  item_id?: any;
  item_type?: any;
  received?: string;
  is_summarised?: string;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`extension/topics`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiExtensionTag(params: { browser?: any; tags?: any }) {
  try {
    const response = await HttpClient.postWithToken(`extension/tag`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiExtensionChangePrivacy(params: {
  browser?: any;
  privacy?: any;
}) {
  try {
    const response = await HttpClient.postWithToken(
      `extension/changePrivacy`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetExtensionSummary(params: {
  browser: number;
  recursive?: boolean;
}) {
  try {
    const response = await HttpClient.postWithToken(
      `extension/summarise`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiStopExtensionSummary(params: { browser: number }) {
  try {
    const response = await HttpClient.postWithToken(
      `extension/stopSummary`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiExtensionShareWith(params: {
  browser: any;
  type: any;
  query: string;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `extension/shareWith`,
      params
    );

    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetExtensionSimilarQuestion(params: {
  query: string;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `extension/similarQuestions`,
      params
    );

    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
