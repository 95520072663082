import React from 'react';

function MapPinIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '20'}
      height={props.size ?? '20'}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FF3E9A"
        d="M9.167 9.913v4.25a.833.833 0 001.666 0v-4.25a4.167 4.167 0 10-1.666 0zM10 3.33a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm3.508 8.683a.85.85 0 00-.888 1.3.852.852 0 00.538.367c1.892.375 2.675 1.05 2.675 1.316 0 .484-2.041 1.667-5.833 1.667-3.792 0-5.833-1.183-5.833-1.667 0-.266.783-.941 2.675-1.35a.849.849 0 00.658-1.008.852.852 0 00-1.008-.658c-2.534.583-3.992 1.675-3.992 3.016 0 2.192 3.775 3.334 7.5 3.334s7.5-1.142 7.5-3.333c0-1.342-1.458-2.434-3.992-2.984z"
      ></path>
    </svg>
  );
}

export default MapPinIcon;
