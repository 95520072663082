import ShareIcon from 'assets/svgs/ShareIcon';
import React from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { apiChatShare } from 'shared/api/chat';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import { TypeLoadingAnimation } from 'shared/ui-kit/TypeLoadingAnimation';
import { MobileIndividualCardChat } from './CardChat';
import { MobileIndividualChatInput } from './ChatInput';

import './index.styles.scss';

interface Props {
  roomId: any;
  roomName?: any;
  message: string;
  isWaitingResponse: boolean;
  isSharing: boolean;
  isEnded: boolean;
  arrMessage: Array<any>;
  arrShareMessage: Array<any>;
  onShowShare: (status, messageId) => void;
  onFinishShare: () => void;
  onSelectedMessage: (message) => void;
  onLoadMore: () => void;
  onChangedInput: (message) => void;
  onSendMessage: (audioUrl) => void;
  onSuccessShare: () => void;
}

export const MobileIndividualChatBody = ({
  roomId,
  roomName,
  message,
  isWaitingResponse,
  isSharing,
  isEnded,
  arrMessage,
  arrShareMessage,
  onShowShare,
  onFinishShare,
  onLoadMore,
  onSelectedMessage,
  onChangedInput,
  onSendMessage
}: Props) => {
  const handleScroll = (e) => {
    const bottom =
      e.target.clientHeight - e.target.scrollTop + 1 >= e.target.scrollHeight;

    if (bottom) {
      onLoadMore();
    }
  };

  const onShareMessages = async () => {
    if (arrShareMessage.length > 0) {
      onShowShare(false, null);

      const arrMessageId = arrShareMessage.map((item, index) => item.id);
      const res = await apiChatShare({
        room: roomId,
        messages: arrMessageId
      });

      if (res.success) {
        onFinishShare();
        showToast('success', 'Successfully shared!');
      }
    }
  };

  const renderMessages = () => {
    return (
      <div
        className="individual_message_body"
        onScroll={handleScroll}
        style={isWaitingResponse ? { marginBottom: 0 } : { marginBottom: 16 }}
      >
        {renderStatus()}
        {arrMessage.map((item, index) => {
          var isSelected =
            arrShareMessage.filter((sItem, index) => sItem.id === item.id)
              .length > 0;

          return (
            <MobileIndividualCardChat
              key={`card_chat_${index}`}
              isSelected={isSelected}
              isSharing={isSharing}
              data={item}
              onShare={() => {
                !isWaitingResponse && onShowShare(true, item.id);
              }}
              onClick={onSelectedMessage}
            />
          );
        })}
      </div>
    );
  };

  const renderStatus = () => {
    if (isWaitingResponse) {
      return (
        <div className="browsing_status row_align_items row_space_between">
          <div className="row_align_items">
            <div className="font-regular font12">
              Querying
              <span className="font-bold font12" style={{ marginLeft: 6 }}>
                {'Internal Knowledge'}
              </span>
              ...
            </div>
            <BounceLoader color={'#FF3E9A'} loading={true} size={24} />
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderInput = () => {
    return (
      <MobileIndividualChatInput
        message={message}
        isWaitingResponse={isWaitingResponse}
        onChangedMessage={onChangedInput}
        onSend={onSendMessage}
      />
    );
  };

  const renderShareView = () => {
    return (
      <div className="individual_message_share_view">
        <div
          className="row_align_items"
          style={{ gap: 4 }}
          onClick={onShareMessages}
        >
          <ShareIcon color={'#FF3E9A'} />
          <div className="font-regular font12">Share to Q/A</div>
        </div>
        <div className="row_align_items">
          <div className="font-regular font12">
            {arrShareMessage.length} Selected
          </div>
        </div>
      </div>
    );
  };

  const renderEnded = () => {
    return (
      <div style={{ background: 'white' }}>
        <div className="ended_chat_view font-regular font14">
          Chat is ended. <br />
          Saved in history as <span className="font-bold">“{roomName}”</span>
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_individual_chat_body">
      {renderMessages()}
      {isEnded && !isSharing
        ? renderEnded()
        : isSharing
        ? renderShareView()
        : renderInput()}
    </div>
  );
};
