import CloseIcon from 'assets/svgs/CloseIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import SearchIcon from 'assets/svgs/SearchIcon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { apiChatGetAi } from 'shared/api/chat';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import { CardPersonAi } from './CardPersonAi';

import './index.styles.scss';

interface MobileSelectAIModalProps {
  show: boolean;
  onClose: () => void;
  onSelected: (aiInfo) => void;
  onFindMore?: () => void;
}

export const MobileSelectAIModal = ({
  show,
  onClose,
  onSelected,
  onFindMore
}: MobileSelectAIModalProps) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [arrAi, setArrAi] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [aiType, setAiType] = useState<'user' | 'group' | 'organisation'>(
    'user'
  );
  const navigate = useNavigate();
  const userAI = {
    ai_id: userInfo?.id,
    ai_name: userInfo?.name,
    ai_image: userInfo?.image,
    ai_type: 'user'
  };

  useEffect(() => {
    if (show) {
      loadAi();
    } else {
      setArrAi([]);
    }
  }, [show, aiType]);

  const loadAi = async (init = true) => {
    setIsLoading(true);
    if (!isLoading) {
      const res = await apiChatGetAi({
        ai_type: aiType,
        query: '',
        limit: 100,
        offset: 0
      });

      if (res.success) {
        if (aiType === 'user') {
          setArrAi([userAI, ...res.data]);
        } else {
          setArrAi(res.data);
        }
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }
    }
    setIsLoading(false);
  };

  const renderSubHeader = () => {
    if (aiType === 'user') {
      return <div />;
    } else if (aiType === 'group') {
      return (
        <div className="modal_select_ai_person_sub_title font-bold font14">
          My Groups
        </div>
      );
    } else if (aiType === 'organisation') {
      return (
        <div className="modal_select_ai_person_sub_title font-bold font14">
          My Schools
        </div>
      );
    }
  };
  const renderSubNavbar = () => {
    return (
      <div className="ai_type_nav_bar font-regular">
        <div
          className={aiType === 'user' ? 'aiType_selected' : 'aiType'}
          onClick={() => setAiType('user')}
        >
          Personal
        </div>
        <div
          className={aiType === 'group' ? 'aiType_selected' : 'aiType'}
          onClick={() => setAiType('group')}
        >
          Groups
        </div>
        <div
          className={aiType === 'organisation' ? 'aiType_selected' : 'aiType'}
          onClick={() => setAiType('organisation')}
        >
          Schools
        </div>
      </div>
    );
  };

  const renderSubTitle = () => {
    return (
      <div className="font-bold font16" style={{ marginTop: '12px' }}>
        {aiType === 'user'
          ? 'Your Contacts'
          : aiType === 'group'
          ? 'Your Groups'
          : 'Your Schools'}
      </div>
    );
  };

  const renderBody = () => {
    if (!isLoading)
      return (
        <div className="modal_select_ai_person_body">
          {renderSubTitle()}
          {arrAi.map((item, index) => {
            return (
              <CardPersonAi
                key={`select_card_person_ai_${index}`}
                data={item}
                onSelected={onSelected}
              />
            );
          })}
        </div>
      );
    else return <LoadingBarAnimation />;
  };

  const renderBottom = () => {
    if (aiType === 'user')
      return (
        <div
          className="btn_to_creators clickable font-bold font16"
          onClick={onFindMore}
        >
          <SearchIcon /> Find more creators
        </div>
      );
    else if (aiType === 'group')
      return (
        <div className="btn_to_creators clickable font-bold font16">
          <PlusIcon /> Join New Groups
        </div>
      );
    else
      return (
        <div
          className="btn_to_creators clickable font-bold font16"
          onClick={() => {
            navigate('/org/join/');
          }}
        >
          <PlusIcon /> Join New Schools
        </div>
      );
  };

  return (
    <PrimaryModal onClose={onClose} show={show} width={400}>
      <div className="mobile_modal_select_ai_person">
        <div className="mobile_modal_select_ai_person_header">
          <div className="clickable" onClick={onClose}>
            <CloseIcon />
          </div>
          <div className="font-bold font18" style={{ marginLeft: 16 }}>
            Start a New Chat
          </div>
        </div>
        {renderSubNavbar()}
        {renderBody()}
        {renderBottom()}
      </div>
    </PrimaryModal>
  );
};
