import HttpClient from './apiClient';

export async function apiUserRequestMagicLink(params: {
  email: string;
  invite_code?: string;
}) {
  try {
    const response = await HttpClient.post(`user/requestMagicLink`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserInterest(userId, limit = 10, offset = 0) {
  try {
    const params = {
      userId,
      limit,
      offset
    };
    const response = await HttpClient.getWithToken(`user/interests`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserPoint(params?: {
  limit?: number;
  offset?: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`user/pointHistory`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserMobileQR() {
  try {
    const response = await HttpClient.getWithToken(`user/mobileQR`, {});
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserSharingQR() {
  try {
    const response = await HttpClient.getWithToken(`user/sharingQR`, {});
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiAddInterest(topicId) {
  try {
    const params = {
      interest: topicId
    };
    const response = await HttpClient.postWithToken(`user/addInterest`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiRemoveInterest(topicId) {
  try {
    const params = {
      interest: topicId
    };
    const response = await HttpClient.postWithToken(
      `user/removeInterest`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiUserFollow(params: { followingId: any; type?: any }) {
  try {
    const response = await HttpClient.postWithToken(`user/follow`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiUserUnFollow(params: { following: any; type?: any }) {
  try {
    const response = await HttpClient.postWithToken(`user/unfollow`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserInfo(userId = '') {
  try {
    const params = {
      userId
    };

    const response = await HttpClient.getWithToken(`user/info`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserSocials(userId = '') {
  try {
    const params = {
      userId
    };

    const response = await HttpClient.getWithToken(`user/socials`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserCommunityProfile(userId = '') {
  try {
    const params = {
      userId
    };

    const response = await HttpClient.getWithToken(
      `user/communityProfile`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEditSocialHandle(params) {
  try {
    const response = await HttpClient.postWithToken(
      '/user/editSocialHandle',
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEditProfile(params) {
  try {
    const response = await HttpClient.postWithToken('/user/edit', params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEditProfileImage(params) {
  try {
    const response = await HttpClient.postWithToken(
      '/user/editProfileImage',
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiLoginWithQR(params: { code: string }) {
  try {
    const response = await HttpClient.postWithToken(
      '/user/loginWithQR',
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetSearchedUser(params: {
  query: string;
  full: string;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`user/search`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserOrganisations(
  userId,
  limit = 10,
  offset = 0,
  query = ''
) {
  try {
    const params = {
      userId,
      limit,
      offset,
      query
    };
    const response = await HttpClient.getWithToken(
      `user/organisations`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserGroups(userId, limit = 10, offset = 0) {
  try {
    const params = {
      userId,
      limit,
      offset
    };
    const response = await HttpClient.getWithToken(`user/groups`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetWorldInterests(query = '', limit = 10, offset = 0) {
  try {
    const params = {
      query,
      limit,
      offset
    };
    const response = await HttpClient.getWithToken(`world/interests`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserActivityAI(userId, limit = 10, offset = 0) {
  try {
    const params = {
      toUser: userId,
      limit,
      offset
    };
    const response = await HttpClient.getWithToken(`user/activityAI`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetSuggestedUser(params: {
  query?: any;
  limit: any;
  offset: any;
}) {
  try {
    const response = await HttpClient.getWithToken(`suggestion/users`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserQuestions(params: {
  toUser?: any;
  id?: any;
  fromId?: any;
  limit: any;
  offset: any;
}) {
  try {
    const response = await HttpClient.getWithToken(`user/questions`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetSuggestedOrg(params: {
  query?: any;
  limit: any;
  offset: any;
}) {
  try {
    const response = await HttpClient.getWithToken(`suggestion/orgs`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserFollowing(params: {
  userId: any;
  query?: any;
  limit: any;
  offset: any;
}) {
  try {
    const response = await HttpClient.getWithToken('/user/followings', params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserFollowers(params: {
  userId: any;
  query?: any;
  limit: any;
  offset: any;
}) {
  try {
    const response = await HttpClient.getWithToken('/user/followers', params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserPersonalAIs(params: {
  userId: any;
  limit: any;
  offset: any;
}) {
  try {
    const response = await HttpClient.getWithToken('/user/personalAIs', params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserVoiceCloned(params: { userId: any }) {
  try {
    const response = await HttpClient.getWithToken('/user/voiceCloned', params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetOrgProfile(params: { organisation: any }) {
  try {
    const response = await HttpClient.getWithToken('/user/orgProfile', params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiClaimPoint(params: {
  task: any;
  item_id: any;
  item_type: any;
}) {
  try {
    const response = await HttpClient.postWithToken(
      '/user/claimPoints',
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetOrgProfileInfo(params: {
  userId: any;
  organisation: any;
}) {
  try {
    const response = await HttpClient.getWithToken(
      '/user/orgProfileInfo',
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEditOrgProfile(params: {
  organisation: any;
  username: any;
  description: any;
  major: any;
}) {
  try {
    const response = await HttpClient.postWithToken(
      '/user/editOrgProfile',
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetTopicPercentage(params: { userId: any }) {
  try {
    const response = await HttpClient.getWithToken(
      '/topics/topPercentage',
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetTopicMatches(params: {
  toUser: any;
  limit?: any;
  offset?: any;
}) {
  try {
    const response = await HttpClient.getWithToken('/topics/matches', params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
