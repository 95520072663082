import React from 'react';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { MobileCardPersonalAIReview } from './CardPersonalAI';

import './index.styles.scss';

export const MobileBotCreateReview = ({ aiName, aiImage, onFinish }) => {
  const renderBody = () => {
    return (
      <div className="creation_body">
        <div className="font-bold font20" style={{ textAlign: 'center' }}>
          Check in on your voice results later and add more audio files to
          refine it.
        </div>
        <div
          className="font-regular font12"
          style={{ textAlign: 'center', opacity: 0.6 }}
        >
          You can access your Personal AIs from your profile or from "Clones" on
          the bottom menu.
        </div>
        <MobileCardPersonalAIReview
          data={{
            name: aiName,
            image: aiImage,
            id: null
          }}
        />
        <div className="select_desc">
          <div className="direction_icon">
            <DirectionIcon />
          </div>
          <div className="select_txt font-bold font14">
            Select the "Files" and
            <br /> upload audio files there
          </div>
        </div>
      </div>
    );
  };

  const renderCompleteBtn = () => {
    return (
      <PrimaryButton label="Got it!" isLoading={false} onPressed={onFinish} />
    );
  };

  return (
    <div className="mobile_bot_create_review">
      {renderBody()}
      {renderCompleteBtn()}
    </div>
  );
};

function DirectionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="63"
      fill="none"
      viewBox="0 0 34 63"
    >
      <path
        fill="#FF3E9A"
        d="M8.545.293a1 1 0 00-1.414 0L.767 6.657A1 1 0 102.18 8.07l5.657-5.657 5.657 5.657a1 1 0 001.414-1.414L8.545.293zM6.838 1c0 .632-.01 1.268-.026 1.909l2 .051A76.69 76.69 0 008.837 1h-2zm-.235 6.716c-.067 1.271-.137 2.566-.19 3.879l1.998.082c.053-1.298.122-2.58.19-3.855l-1.998-.106zm-.284 8.766a62.97 62.97 0 00.15 3.931l1.996-.141a60.976 60.976 0 01-.146-3.807l-2 .017zm.734 8.83c.216 1.27.49 2.558.832 3.868l1.935-.506a43.45 43.45 0 01-.795-3.698l-1.972.336zm2.348 8.582c.457 1.192.977 2.4 1.568 3.624l1.802-.87a45.194 45.194 0 01-1.502-3.47l-1.868.716zm3.926 7.958a58.777 58.777 0 002.15 3.292l1.638-1.148a56.829 56.829 0 01-2.078-3.18l-1.71 1.036zm5.122 7.21a78.28 78.28 0 002.549 2.966l1.484-1.34A76.381 76.381 0 0120 47.798l-1.55 1.264zm5.94 6.52c.895.89 1.833 1.788 2.816 2.695l1.357-1.47c-.966-.89-1.886-1.772-2.763-2.643l-1.41 1.418zm6.46 5.92c.495.422 1 .845 1.515 1.27l1.273-1.543c-.507-.419-1.004-.835-1.492-1.25l-1.295 1.524z"
      ></path>
    </svg>
  );
}
