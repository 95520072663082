import PlusIcon from 'assets/svgs/PlusIcon';
import VolumnMuteIcon from 'assets/svgs/VolumnMuteIcon';
import { MobileAddBotModal } from 'components/Modals/Mobile/Bots/AddBotModal';
import React, { useState } from 'react';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import { MobileCardBot } from './CardBot';

import './index.styles.scss';

interface Props {
  roomId: any;
  data: Array<any>;
  isEndedChat: boolean;
  onDeleteFromUI: (botId) => void;
  onUpdateBots: (newData) => void;
}

export const MobilePromotConversationBot = ({
  roomId,
  data,
  isEndedChat,
  onDeleteFromUI,
  onUpdateBots
}: Props) => {
  const [showAddBot, setShowAddBot] = useState(false);

  const [showMutedMessage, setShowMutedMessage] = useState(false);
  const [mutedBotName, setMutedBotName] = useState('');

  const onAdd = () => {
    if (data.length < 4) {
      setShowAddBot(true);
    } else {
      showToast('error', 'Max limit of 4 bots reached.');
    }
  };

  const onSuccessMute = (botName) => {
    setMutedBotName(botName);
    setShowMutedMessage(true);

    setTimeout(() => {
      setShowMutedMessage(false);
    }, 3000);
  };

  const renderPlus = () => {
    return (
      <div className="plus_btn" onClick={onAdd}>
        <PlusIcon color={'black'} />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="content_list row_align_items">
        {!isEndedChat && renderPlus()}
        {data.map((item, index) => {
          return (
            <MobileCardBot
              index={index}
              data={item}
              roomId={roomId}
              isEndedChat={isEndedChat}
              totalCount={data.length}
              key={`mobile_card_bot_${index}`}
              onDeleteFromUI={onDeleteFromUI}
              onSuccessMute={onSuccessMute}
            />
          );
        })}
      </div>
    );
  };

  const renderAddBotModal = () => {
    return (
      <MobileAddBotModal
        roomId={roomId}
        open={showAddBot}
        currentBots={data}
        onClose={() => {
          setShowAddBot(false);
        }}
        onSuccess={(newBots) => {
          onUpdateBots(newBots);
          setShowAddBot(false);
        }}
      />
    );
  };

  const renderMutedMessage = () => {
    return (
      <div className="mute_message">
        <VolumnMuteIcon size={16} color="black" />
        <div
          className="font-bold font10"
          style={{ whiteSpace: 'nowrap' }}
        >{`${mutedBotName} was muted from this conversation`}</div>
      </div>
    );
  };

  return (
    <div className="mobile_promot_conversation_bot">
      {renderBody()}
      {showMutedMessage && renderMutedMessage()}
      {renderAddBotModal()}
    </div>
  );
};
