import React from 'react';

function AudioPlayIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 24}
      height={props.size ?? 24}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        d="M18.5402 8.99832L8.88021 3.45832C8.3575 3.15648 7.76421 2.99836 7.16061 3.00001C6.557 3.00167 5.96459 3.16305 5.44354 3.46776C4.92249 3.77246 4.49137 4.20964 4.19396 4.73489C3.89655 5.26014 3.74345 5.85475 3.75021 6.45832V17.5783C3.75021 18.4854 4.11053 19.3552 4.75191 19.9966C5.39328 20.638 6.26317 20.9983 7.17021 20.9983C7.77065 20.9973 8.3603 20.8387 8.88021 20.5383L18.5402 14.9983C19.0593 14.6979 19.4902 14.2663 19.7898 13.7468C20.0894 13.2272 20.2471 12.638 20.2471 12.0383C20.2471 11.4386 20.0894 10.8494 19.7898 10.3299C19.4902 9.81035 19.0593 9.37873 18.5402 9.07832V8.99832ZM17.5402 13.1883L7.88021 18.8083C7.6637 18.931 7.41908 18.9955 7.17021 18.9955C6.92135 18.9955 6.67673 18.931 6.46021 18.8083C6.24431 18.6837 6.06503 18.5044 5.9404 18.2885C5.81576 18.0725 5.75017 17.8276 5.75021 17.5783V6.41832C5.75017 6.16901 5.81576 5.92409 5.9404 5.70818C6.06503 5.49226 6.24431 5.31297 6.46021 5.18832C6.67762 5.06747 6.92151 5.00221 7.17021 4.99832C7.41875 5.00342 7.66235 5.06861 7.88021 5.18832L17.5402 10.7683C17.7562 10.8929 17.9356 11.0722 18.0603 11.2881C18.185 11.504 18.2506 11.749 18.2506 11.9983C18.2506 12.2477 18.185 12.4926 18.0603 12.7085C17.9356 12.9244 17.7562 13.1037 17.5402 13.2283V13.1883Z"
        fill={props.color ?? "white"}
      />
    </svg>
  );
}

export default AudioPlayIcon;


