import React from 'react';

function WarningIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 24}
      height={props.size ?? 24}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={props.color ?? '#E1A325'}
        d="M8 10.668a.666.666 0 100 1.333.666.666 0 000-1.333zm7.114.98L9.747 2.315a2 2 0 00-3.493 0L.92 11.648a2 2 0 001.707 3.02h10.747a2.001 2.001 0 001.74-3.02zm-1.154 1.334a.666.666 0 01-.586.34H2.627a.667.667 0 01-.587-.34.667.667 0 010-.667L7.374 2.98a.667.667 0 011.186 0l5.367 9.334a.668.668 0 01.033.68v-.013zM8 5.335A.667.667 0 007.334 6v2.667a.667.667 0 001.333 0V6.001A.667.667 0 008 5.335z"
        opacity={props.opacity ?? 1}
      ></path>
    </svg>
  );
}

export default WarningIcon;
