import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import CameraIcon from 'assets/svgs/CameraIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import {
  apiEditOrgProfile,
  apiEditProfileImage,
  apiGetOrgProfileInfo
} from 'shared/api/user';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { getBase64String } from 'shared/utils-IPFS/functions';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import profileAction from 'redux/profile/actions';

import './index.styles.scss';
import { showToast } from 'shared/ui-kit/PrimaryToast';

export const MobileEditOrgProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const orgId = params.id;
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const { uploadBase64 } = useIPFS();

  const hiddenFileInput = useRef<any>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const [imageUrl, setImageUrl] = useState('');
  const [userName, setUserName] = useState('');
  const [description, setDescription] = useState('');
  const [major, setMajor] = useState('');

  useEffect(() => {
    loadData();
  }, [userInfo, orgId]);

  const loadData = async () => {
    const res = await apiGetOrgProfileInfo({
      userId: userInfo?.id,
      organisation: orgId
    });

    if (res.success) {
      setUserName(res.data.name);
      setImageUrl(res.data.image);
      setDescription(res.data.description);
      setMajor(res.data.major);
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    event.preventDefault();
    const files = event.target.files;
    if (files.length) {
      const name = files[0].name;
      const size = files[0].size;
      const type = files[0].type;
      setIsUploadingFile(true);

      const base64String = await getBase64String(files[0]);
      const url = await uploadBase64(base64String, type, '');

      const res = await apiEditProfileImage({ profileImage: url });
      if (res.success) {
        dispatch(profileAction.loadUserInfo(userInfo?.id ?? 0));
        setImageUrl(url + '');
      }

      setIsUploadingFile(false);
    }
  };

  const onUpdateProfile = async () => {
    if (!isUpdating && !!userName && !!description) {
      setIsUpdating(true);

      const res = await apiEditOrgProfile({
        organisation: orgId,
        major: major,
        username: userName,
        description: description
      });

      if (res.success) {
        showToast('success', 'Successfully updated');
        navigate(-1);
      }

      setIsUpdating(false);
    }
  };

  const renderHeader = () => {
    return (
      <div className="edit_profile_header row_align_items row_space_between">
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <BlackBackIcon />
        </div>
        <div className="font-bold font16">Edit Profile</div>
        <div style={{ marginRight: 24 }} />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="edit_profile_body">
        {renderImageSection()}
        {renderInputSection()}
      </div>
    );
  };

  const renderImageSection = () => {
    return (
      <div className="image_section">
        {!!imageUrl ? (
          <img
            src={imageUrl}
            style={{
              width: 100,
              height: 100,
              borderRadius: 100,
              objectFit: 'cover'
            }}
          />
        ) : (
          <DefaultAvatarIcon size={100} />
        )}
        <div className="camera_icon_tag clickable" onClick={handleClick}>
          {isUploadingFile ? (
            <LoadingCircular color="white" size={20} />
          ) : (
            <CameraIcon size={20} />
          )}
        </div>

        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
          accept="image/*"
        />
      </div>
    );
  };

  const renderInputSection = () => {
    return (
      <div className="input_sections">
        <div className="input_section">
          <div className="sub_title font-regular font10">Display Name</div>
          <input
            value={userName}
            placeholder="Enter your name here..."
            className="sub_input font-regular font16"
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className="input_section">
          <div className="sub_title font-regular font10">Major</div>
          <input
            value={major}
            placeholder="Enter your major subject here..."
            className="sub_input font-regular font16"
            onChange={(e) => setMajor(e.target.value)}
          />
        </div>
        <div className="input_section">
          <div className="sub_title font-regular font10">Short Bio</div>
          <input
            value={description}
            placeholder="Enter your bio here..."
            className="sub_input font-regular font16"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="edit_profile_bottom">
        <PrimaryButton
          label="Update School Profile"
          isLoading={isUpdating}
          onPressed={() => {
            onUpdateProfile();
          }}
        />
      </div>
    );
  };

  return (
    <div className="mobile_edit_org_profile">
      {renderHeader()}
      {renderBody()}
      {renderBottom()}
    </div>
  );
};
