import React from 'react';

function FilterLightIcon({ isSelected }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={isSelected ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'}
        d="M14.24 2.354A6.667 6.667 0 008.674.97a6.667 6.667 0 00-5.225 5.267 6.725 6.725 0 001.433 5.542c.586.661.923 1.508.95 2.391v2.5a2.5 2.5 0 002.5 2.5h3.333a2.5 2.5 0 002.5-2.5V14.33a4.308 4.308 0 011.017-2.659 6.667 6.667 0 00-.942-9.333v.017zM12.5 16.67a.833.833 0 01-.834.834H8.332a.833.833 0 01-.833-.834v-.833h5v.833zm1.391-6.033A5.942 5.942 0 0012.5 14.17h-1.667v-2.5a.833.833 0 00-1.667 0v2.5H7.5a5.417 5.417 0 00-1.334-3.466 5 5 0 012.825-8.1A5 5 0 0115 7.504a4.908 4.908 0 01-1.109 3.133z"
      ></path>
    </svg>
  );
}

export default FilterLightIcon;
