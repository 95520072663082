import React, { useEffect, useMemo, useRef, useState } from 'react';
import { apiGetTopicTopUser } from 'shared/api/topic';
import { apiGetTopicMatches } from 'shared/api/user';
import { CardAnimatedMainTopic } from './CardAnimatedMainTopic';
import { CardNormalMainTopicItem } from './CardNormalMainTopic';

import './index.styles.scss';
import { SubUsers } from './SubUsers';

interface Props {
  userData: any;
  onViewProfile: (userId) => void;
}

export const CommonInterest = ({ userData, onViewProfile }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [arrMainTopic, setArrTopic] = useState<Array<any>>([]);
  const [hasMore, setHasMore] = useState(true);
  const [lastId, setLastId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [arrSubUser, setArrSubUser] = useState<Array<any>>([]);
  const [hasMoreUser, setHasMoreUser] = useState(true);
  const [lastIdUser, setLastIdUser] = useState(0);
  const [isLoadingUser, setIsLoadingUser] = useState(false);

  const [selectedMainTopic, setSelectedMainTopic] = useState<any>(null);
  const [arrSelectedSubTopic, setArrSelectedSubTopic] = useState<Array<any>>(
    []
  );

  useEffect(() => {
    if (userData) {
      loadMainTopicData();
    }
  }, [userData]);

  useEffect(() => {
    setArrSelectedSubTopic([]);
  }, [selectedMainTopic]);

  useEffect(() => {
    if (arrSelectedSubTopic.length > 0) {
      loadTopUsers();
    } else {
      setArrSubUser([]);
    }
  }, [arrSelectedSubTopic]);

  const loadMainTopicData = async (init = true) => {
    if (init) {
      setIsLoading(true);

      const res = await apiGetTopicMatches({
        toUser: userData.id,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrTopic(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }

      setIsLoading(false);
    } else {
      if (!isLoading && hasMore) {
        setIsLoading(true);

        const res = await apiGetTopicMatches({
          toUser: userData.id,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrTopic((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoading(false);
      }
    }
  };

  const loadTopUsers = async (init = true) => {
    const topicIds = arrSelectedSubTopic.map((item, index) => item.id);

    if (init) {
      setIsLoadingUser(true);

      const res = await apiGetTopicTopUser({
        topics: topicIds + '',
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrSubUser(res.data);
        setLastIdUser(res.lastId);
        setHasMoreUser(res.hasMore);
      }

      setIsLoadingUser(false);
    } else {
      if (!isLoadingUser && hasMoreUser) {
        setIsLoadingUser(true);

        const res = await apiGetTopicTopUser({
          topics: topicIds + '',
          limit: 10,
          offset: lastIdUser
        });

        if (res.success) {
          setArrSubUser((prev) => [...prev, ...res.data]);
          setLastIdUser(res.lastId);
          setHasMoreUser(res.hasMore);
        }

        setIsLoadingUser(false);
      }
    }
  };

  const onSelectedMainTopic = (topicData) => {
    setSelectedMainTopic(topicData);

    if (scrollRef.current) {
      scrollRef.current.scrollLeft = 0;
    }
  };

  const handleScroll = () => {
    const container = scrollRef.current;
    if (container) {
      if (
        container.scrollLeft + container.clientWidth >=
        container.scrollWidth
      ) {
        loadMainTopicData(false);
      }
    }
  };

  const arrShowedTopics = useMemo(() => {
    if (selectedMainTopic) {
      return [
        ...arrMainTopic.filter(
          (item, index) => item.topic !== selectedMainTopic.topic
        )
      ];
    } else {
      return arrMainTopic;
    }
  }, [arrMainTopic, selectedMainTopic]);

  const renderMainTopics = () => {
    return (
      <div className="main_topic_list">
        {selectedMainTopic && renderSelectedTopic()}
        {renderNormalTopic()}
      </div>
    );
  };

  const renderSelectedTopic = () => {
    return (
      <div className="selected_main_topic">
        <CardAnimatedMainTopic
          key={`main_topic_item_${0}`}
          topicData={selectedMainTopic}
          userInfo={userData}
          selectedSubTopic={arrSelectedSubTopic}
          onUpdateSubTopic={setArrSelectedSubTopic}
          onClicked={() => {
            setSelectedMainTopic(null);
          }}
        />
      </div>
    );
  };

  const renderNormalTopic = () => {
    return (
      <div
        className="normal_main_topics"
        ref={scrollRef}
        onScroll={handleScroll}
      >
        {arrShowedTopics.map((item, index) => {
          return (
            <CardNormalMainTopicItem
              item={item}
              isSelected={false}
              key={`main_topic_item_${index}`}
              onClicked={() => onSelectedMainTopic(item)}
            />
          );
        })}
      </div>
    );
  };
  const renderSubUser = () => {
    return <SubUsers data={arrSubUser} onViewProfile={onViewProfile} />;
  };

  if (arrMainTopic.length > 0) {
    return (
      <div className="modal_mobile_bot_common_interest">
        <div className="topic_title font-bold font16">Common interests</div>
        {renderMainTopics()}
        {renderSubUser()}
      </div>
    );
  } else {
    return <></>;
  }
};
