import { QuestionAskBar } from 'components/Main/Organisation/components/QuestionAskBar';
import React, { useEffect, useState } from 'react';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { DiscoverFriend } from './DiscoverFriend';
import { MobileExplorePeople } from './ExplorePeople';

import './index.styles.scss';
import { MobileJoinGroup } from './JoinGroup';
import { MobileJoinOrg } from './JoinOrg';
import { MobileLastInteract } from './LastInteract';

export const CommunityAll = () => {
  const [searchTxt, setSearchTxt] = useState('');

  const renderBody = () => {
    return (
      <div className="community_home_body">
        <MobileExplorePeople />
        <MobileJoinOrg />
        <DiscoverFriend searchText={searchTxt} />
      </div>
    );
  };

  return <div className="community_home">{renderBody()}</div>;
};
