import React from "react";

interface EmailInputBarProps {
    order: number
}

export const EmailInputBar = ({order}: EmailInputBarProps) => {
    return(
            <div className="email_input_bar">
                <div className="input_bar_order font16">{order}</div>
                <div className="input_email">
                    <input type="email" className="email_input font-bold font16" value="jessica.pierce@harvard.ac.uk"/>
                </div>
            </div>
        );
}