export function formatSecond(seconds: number) {
  if (seconds < 60) {
    const secondStr = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `00:${secondStr}`;
  } else {
    const minutes = Math.floor(seconds / 60);
    const secondStr = seconds < 10 ? `0${seconds}` : `${seconds}`;

    if (minutes < 10) {
      return `0${minutes}:${secondStr}`;
    } else {
      return `${minutes}:${secondStr}`;
    }
  }
}
