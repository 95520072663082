import SearchIcon from 'assets/svgs/SearchIcon';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { apiGetAudioStories } from 'shared/api/content';
import { MobileCardExplorePeople } from './CardExplorePeople';
import { socket } from 'components/Auth';

import './index.styles.scss';
import { MobileRecordUserProfileModal } from 'components/Modals/Mobile/RecordUserProfileModal';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';

export const MobileExplorePeople = () => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const navigate = useNavigate();

  const scrollRef = useRef<HTMLDivElement>(null);

  const [arrStory, setArrStory] = useState<Array<any>>([]);
  const [hasMore, setHasMore] = useState(true);
  const [lastId, setLastId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [showRecordModal, setShowRecordModal] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (init = true) => {
    if (init) {
      setIsLoading(true);

      const res = await apiGetAudioStories({
        query: '',
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setIsLoaded(true);
        setArrStory(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }

      setIsLoading(false);
    } else {
      if (!isLoading && hasMore) {
        const res = await apiGetAudioStories({
          query: '',
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrStory((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }
      }
    }
  };

  const handleScroll = () => {
    const container = scrollRef.current;
    if (container) {
      if (
        container.scrollLeft + container.clientWidth >=
        container.scrollWidth
      ) {
        loadData(false);
      }
    }
  };

  const onSearch = () => {
    navigate('/search/community');
  };

  const onRecord = () => {
    setShowRecordModal(true);
  };

  const emitSeenEvent = (storyId) => {
    socket.emit('story-seen', {
      story: storyId
    });
  };

  const renderTopView = () => {
    return (
      <div className="expolore_top_view row_align_items row_space_between">
        <div className="top_left_view">
          <div className="font-regular font16">
            {userInfo && `Welcome ${userInfo?.name}!`}
          </div>
          <div className="font-bold font18">Listen to what’s being shared</div>
        </div>
        <div className="top_right_view" onClick={onSearch}>
          <SearchIcon size={24} color={'rgba(0, 0, 0, 0.3)'} />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="exlore_body" ref={scrollRef} onScroll={handleScroll}>
        {arrStory.map((item, index) => {
          return (
            <MobileCardExplorePeople
              key={`mobile_card_explore_people_${index}`}
              data={item}
              onPlayed={emitSeenEvent}
            />
          );
        })}
      </div>
    );
  };

  const renderEmptyDesc = () => {
    return (
      <div className="empty_body row_align_items font-regular font12">
        <span className="font32" style={{ fontSize: 36 }}>
          &#128554;
        </span>
        <div>
          Everyone’s quiet today. Break the silence and{' '}
          <span className="share_text font-bold" onClick={onRecord}>
            share your audio first!
          </span>
        </div>
      </div>
    );
  };

  const renderRecordModal = () => {
    return (
      <MobileRecordUserProfileModal
        show={showRecordModal}
        onClose={() => {
          setShowRecordModal(false);
        }}
        onSuccess={() => {
          setShowRecordModal(false);
          loadData(true);
        }}
      />
    );
  };

  return (
    <div className="mobile_explore_people">
      {renderTopView()}
      {isLoading ? (
        <LoadingBarAnimation />
      ) : isLoaded && arrStory.length === 0 ? (
        renderEmptyDesc()
      ) : (
        renderBody()
      )}
      {renderRecordModal()}
    </div>
  );
};
