import TrashIcon from 'assets/svgs/TrashIcon';
import FilterCheckIcon from 'components/Modals/Mobile/FeedFilterModal/TypeFilter/svgs/FilterCheckIcon';
import React, { useState } from 'react';
import { apiDeletionPrompt } from 'shared/api/delete';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import './index.styles.scss';

interface Props {
  isSelected: boolean;
  data: any;
  onPressed: (item) => void;
  onRemoveFromUI: (promptId) => void;
}

export const MobileCardPrompt = ({
  isSelected,
  data,
  onPressed,
  onRemoveFromUI
}: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = async (e) => {
    e.stopPropagation();
    if (!isDeleting) {
      setIsDeleting(true);

      const res = await apiDeletionPrompt({ prompt: data.id });

      if (res.success) {
        onRemoveFromUI(data.id);
      }
      setIsDeleting(false);
    }
  };

  return (
    <div
      className="mobile_card_prompt row_align_items row_space_between"
      onClick={() => {
        onPressed(data);
      }}
    >
      <div className="font-regular font14">{data.prompt}</div>
      <div className="row_align_items" style={{ gap: 8 }}>
        <div className="action_btn">
          {isSelected ? (
            <FilterCheckIcon />
          ) : (
            <div className="default_mark"></div>
          )}
        </div>
      </div>
    </div>
  );
};
