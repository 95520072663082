import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import { ConnectSocialModal } from 'components/Modals/ConnectSocialModal';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { apiGetUserQuestions } from 'shared/api/user';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { MobileProfilePersonalAi } from './components/PersonalAI';
import { MobileProfileQuestion } from './components/Question';
import { MobileProfileSocialMedia } from './components/SocialMedia';
import { MobileProfileTopOrg } from './components/TopOrg';
import { MobileProfileUserInfo } from './components/UserInfo';

import './index.styles.scss';

export const MobileOtherProfile = ({ userId }) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [isConnectSocial, setIsConnectSocial] = useState(false);
  const [isLoadedAll, setIsLoadedAll] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const [isLoadedUserInfo, setIsLoadedUserInfo] = useState(false);
  const [isLoadedOrg, setIsLoadedOrg] = useState(false);
  const [isLoadedQuestion, setIsLoadedQuestion] = useState(false);
  const [isLoadedAi, setIsLoadedAi] = useState(false);

  const [arrUserActivityAI, setArrUserActivityAI] = useState<Array<any>>([]);
  const [lastIdUserActivityAI, setLastIdUserActivityAI] = useState(0);
  const [hasMoreUserActivityAI, setHasMoreUserActivityAI] = useState(true);
  const [isLoadingUserActivityAI, setIsLoadingUserActivityAI] = useState(false);

  useEffect(() => {
    if (isLoadedOrg && isLoadedQuestion && isLoadedAi && isLoadedUserInfo) {
      setIsLoadedAll(true);
    }
  }, [isLoadedOrg, isLoadedQuestion, isLoadedAi, isLoadedUserInfo]);

  useEffect(() => {
    if (userInfo) {
      loadUserActivityAIData();
    }
  }, [userInfo]);

  const loadUserActivityAIData = async (init = true) => {
    if (init) {
      setIsLoadingUserActivityAI(true);
      const res = await apiGetUserQuestions({
        toUser: userId,
        offset: 0,
        limit: 10
      });

      if (res.success) {
        setArrUserActivityAI(res.data);
        setLastIdUserActivityAI(res.lastId);
        setHasMoreUserActivityAI(res.hasMore);
        setIsLoadedQuestion(true);
      }
      setIsLoadingUserActivityAI(false);
    } else {
      if (hasMoreUserActivityAI && !isLoadingUserActivityAI) {
        setIsLoadingUserActivityAI(true);
        const res = await apiGetUserQuestions({
          toUser: userId,
          offset: lastIdUserActivityAI,
          limit: 10
        });
        if (res.success) {
          setArrUserActivityAI((prev) => [...prev, ...res.data]);
          setLastIdUserActivityAI(res.lastId);
          setHasMoreUserActivityAI(res.hasMore);
        }
        setIsLoadingUserActivityAI(false);
      }
    }
  };

  const handleScroll = (e) => {
    // hide nav bar
    const scrollTop = e.target.scrollTop;
    setLastScrollTop(scrollTop);

    if (scrollTop < lastScrollTop) {
      document
        .getElementById('mobile_body')
        ?.setAttribute('class', 'mobile_body normal_mobile_body');
      document
        .getElementById('mobile_tab')
        ?.setAttribute('class', 'mobile_tab active_nav');
    } else {
      document
        .getElementById('mobile_body')
        ?.setAttribute('class', 'mobile_body expanded_mobile_body');
      document
        .getElementById('mobile_tab')
        ?.setAttribute('class', 'mobile_tab hidden_nav');
    }

    // load more questions

    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;

    if (bottom) {
      loadUserActivityAIData(false);
    }
  };

  const onBack = () => {
    navigate(-1);
  };

  const renderConnectSocialModal = () => {
    return (
      <ConnectSocialModal
        show={isConnectSocial}
        onClose={() => {
          setIsConnectSocial(false);
        }}
        onSuccess={() => {
          setIsConnectSocial(false);
        }}
      />
    );
  };

  const renderHeader = () => {
    return (
      <div className="profile_header">
        <div className="clickable" onClick={onBack}>
          <BlackBackIcon />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="profile_body">
        <MobileProfileTopOrg
          userId={userId}
          isLoadedAll={isLoadedAll}
          onLoaded={() => setIsLoadedOrg(true)}
        />
        <MobileProfileUserInfo
          userId={userId}
          isLoadedAll={isLoadedAll}
          onLoaded={() => setIsLoadedUserInfo(true)}
        />

        <MobileProfilePersonalAi
          userId={userId}
          isLoadedAll={isLoadedAll}
          onLoaded={() => setIsLoadedAi(true)}
        />
        <MobileProfileSocialMedia
          userId={userId}
          isLoadedAll={isLoadedAll}
          onConnect={() => {
            setIsConnectSocial(true);
          }}
        />
        <MobileProfileQuestion
          userId={userId}
          data={arrUserActivityAI}
          isLoadedAll={isLoadedAll}
          onLoaded={() => setIsLoadedQuestion(true)}
        />
      </div>
    );
  };

  const renderLoadingBar = () => {
    return (
      <div style={{ flex: 1 }}>
        <LoadingBarAnimation />
      </div>
    );
  };

  return (
    <div className="mobile_profile" onScroll={handleScroll}>
      {/* {renderHeader()} */}
      {renderBody()}
      {!isLoadedAll && renderLoadingBar()}
      {renderConnectSocialModal()}
    </div>
  );
};
