import HttpClient from './apiClient';

export async function apiChatGet(params: {
  query?: any;
  room?: any;
  ai_id?: any;
  ai_type?:
    | 'general'
    | 'user'
    | 'group'
    | 'organisation'
    | 'multichat'
    | 'personal';
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`chat/chat`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatRooms(params: {
  limit: number;
  offset: number;
  ongoing_only?: string;
}) {
  try {
    const response = await HttpClient.getWithToken(`chat/rooms`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
export async function apiChatVote(params: {
  room: number | undefined;
  vote: number;
}) {
  try {
    const response = await HttpClient.postWithToken(`chat/vote`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
export async function apiChatCreate(params: {
  ai_id?: number;
  ai_type?: 'general' | 'user' | 'group' | 'organisation';
  type: 'text';
  message: string;
}) {
  try {
    const response = await HttpClient.postWithToken(`chat/create`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatGetAi(params: {
  ai_type: string;
  query: string;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`chat/getAI`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
export async function apiChatGetPersonalAIs(params: {
  query?: string;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`chat/personalAIs`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetChatPersonalDocs(params: {
  id: any;
  query?: string;
  type?: string;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(
      `chat/personalAIDocs`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatCreatePersonalAI(params: {
  name: any;
  image: any;
  prompt: any;
}) {
  try {
    const response = await HttpClient.postWithToken(
      `chat/createPersonalAI`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatCreatePersonalAIV2(params: {
  name: any;
  image: any;
  prompt: any;
  voice: any;
}) {
  try {
    const response = await HttpClient.postWithToken(
      `chat/createPersonalAIV2`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatAttachVoice(params: {
  ai_id: any;
  ai_type: any;
  voice: any;
}) {
  try {
    const response = await HttpClient.postWithToken(`chat/attachVoice`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatCreateMultiChat(params: {
  ai_id: any;
  ai_type: any;
}) {
  try {
    const response = await HttpClient.postWithToken(
      `chat/createMultiChat`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetChatAgents(params: { room: any; query: string }) {
  try {
    const response = await HttpClient.getWithToken(`chat/agents`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetChatChatAgents(params: { room: any }) {
  try {
    const response = await HttpClient.getWithToken(`chat/chatAgents`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeleteChatAgent(params: { room: any; agent: any }) {
  try {
    const response = await HttpClient.postWithToken(`chat/deleteAgent`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatAddAgent(params: { room: any; bots: Array<any> }) {
  try {
    const response = await HttpClient.postWithToken(`chat/addAgent`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetChatIntroduce(params: {
  ai_id: any;
  ai_type: any;
}) {
  try {
    const response = await HttpClient.getWithToken(`chat/introduction`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatMute(params: { room: any; agent: any }) {
  try {
    const response = await HttpClient.postWithToken(`chat/mute`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatUnMute(params: { room: any; agent: any }) {
  try {
    const response = await HttpClient.postWithToken(`chat/unmute`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetChatPrompt(params: {
  query: string;
  limit: any;
  offset: any;
}) {
  try {
    const response = await HttpClient.getWithToken(`chat/prompts`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatCreatePrompt(params: {
  prompt: any;
  ai_id: any;
  ai_type: any;
}) {
  try {
    const response = await HttpClient.postWithToken(
      `chat/createPrompt`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetChatRoomNumber(params: {
  ai_id: any;
  ai_type: any;
}) {
  try {
    const response = await HttpClient.getWithToken(`chat/roomNumber`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatAttachPrompt(params: {
  prompt: any;
  ai_id: any;
  ai_type: any;
}) {
  try {
    const response = await HttpClient.postWithToken(
      `chat/attachPrompt`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatShare(params: {
  room: any;
  messages: Array<any>;
}) {
  try {
    const response = await HttpClient.postWithToken(`chat/share`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatSummarise(params: { room: any }) {
  try {
    const response = await HttpClient.postWithToken(`chat/summarise`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatShareArticle(params: { room: any }) {
  try {
    const response = await HttpClient.postWithToken(
      `chat/shareArticle`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatShareQA(params: {
  room: any;
  messageId: any;
  answer: boolean;
}) {
  try {
    const response = await HttpClient.postWithToken(`chat/shareQA`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChatCreateVoice(params: { urls: Array<any> }) {
  try {
    const response = await HttpClient.postWithToken(`chat/createVoice`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
