import React from 'react';

function ReplyIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '16'}
      height={props.size ?? '16'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_7189_202384)">
        <path
          fill={props.color ?? '#000'}
          d="M9.333 6V3.335L14 8.001l-4.667 4.666V9.934C6 9.934 3.667 11.001 2 13.334c.667-3.333 2.667-6.667 7.333-7.333z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_7189_202384">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
            transform="matrix(-1 0 0 1 16 0)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ReplyIcon;
