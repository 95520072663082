import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import UserFollowerIcon from 'assets/svgs/UserFollowerIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiGetInvitationStats } from 'shared/api/utils';
import { MobileInviteBody } from './components/InviteBody';
import { MobileInviteType } from './components/InviteType';

import './index.styles.scss';

export const MobileInviteFriend = () => {
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };

  const renderHeader = () => {
    return (
      <div className="invite_header row_space_between">
        <div onClick={onBack}>
          <BlackBackIcon />
        </div>
        <div className="font-bold font16" style={{ textAlign: 'center' }}>
          Invite Friends to {process.env.REACT_APP_NAME}
        </div>
        <div>
          <UserFollowerIcon color={'black'} />
        </div>
      </div>
    );
  };

  const renderType = () => {
    return <MobileInviteType />;
  };

  const renderBody = () => {
    return <MobileInviteBody />;
  };

  return (
    <div className="mobile_invite_friend">
      {renderHeader()}
      {renderType()}
      {renderBody()}
    </div>
  );
};
