import React, { useEffect, useRef, useState } from 'react';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { useWhisper } from '@kkaczynski/use-whisper';

import './index.styles.scss';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import MicIcon from 'assets/svgs/MicIcon';
import ChatSendIcon from 'assets/svgs/ChatSendIcon';
import { getToken } from 'shared/api/storageHelper';

interface Props {
  message: string;
  isWaitingResponse: boolean;
  onChangedMessage: (newMsg) => void;
  onSend: (audioUrl) => void;
}

export const MobileIndividualChatInput = ({
  message,
  isWaitingResponse,
  onChangedMessage,
  onSend
}: Props) => {
  const { uploadBase64 } = useIPFS();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [audioUrl, setAudioUrl] = useState<any>(null);
  const [isSendingAutomatically, setIsSendingAUtomatically] = useState(false);

  const onTranscribe = async (blob: Blob) => {
    const base64 = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });

    const url = await uploadBase64(base64, 'audio/webm', ''); //upload to wasabi and returns url of audio recording.

    // you must return result from your server in Transcript format
    return {
      blob,
      url
    };
  };

  const {
    recording,
    speaking,
    transcript,
    transcribing,
    pauseRecording,
    startRecording,
    stopRecording
  } = useWhisper({
    apiKey: getToken(),
    streaming: true,
    removeSilence: false,
    timeSlice: 1_000, // 1 second
    whisperConfig: {
      language: 'en',
      endpoint: process.env.REACT_APP_WHISPER_URL
    },
    onTranscribe
  });

  useEffect(() => {
    const dumpData: any = transcript;
    setAudioUrl(dumpData?.url);
  }, [transcript]);

  useEffect(() => {
    if (!isSendingAutomatically) {
      if (recording && transcript.text) {
        onChangedMessage(transcript.text);
      }
    } else {
      if (transcript.text) {
        onChangedMessage(transcript.text);
      }
    }
  }, [recording, transcript]);

  useEffect(() => {
    if (!recording && !transcribing && isSendingAutomatically && audioUrl) {
      onSendMessage();
    }
  }, [transcribing, transcript, recording, isSendingAutomatically, audioUrl]);

  const onInput = (e) => {
    if (inputRef.current) {
      inputRef.current.style.height = '1px';
      inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
    }
  };

  const onStartRecord = () => {
    if (!isWaitingResponse) {
      startRecording();
    }
  };

  const onStopRecord = () => {
    stopRecording();
  };

  const onSendMessage = () => {
    if (!!message && !isWaitingResponse && !recording && !transcribing) {
      onSend(audioUrl);
      inputRef!.current!.style.height = '30px';
    } else if (recording) {
      setIsSendingAUtomatically(true);
      onStopRecord();
    }
  };

  const renderInputView = () => {
    return (
      <textarea
        ref={inputRef}
        value={message}
        placeholder="Ask more questions here..."
        className="individual_mesasge_chat_box_input font-regular font16"
        disabled={isWaitingResponse}
        onChange={(e) => {
          onInput(e);
          if (e.nativeEvent['inputType'] === 'insertLineBreak') {
            e.preventDefault();
            return;
          } else {
            onChangedMessage(e.target.value);
          }
        }}
      />
    );
  };

  const renderAudioBtn = () => {
    return (
      <div
        className="trascript_btn"
        style={
          recording || transcribing
            ? { backgroundColor: '#FF3E9A' }
            : { backgroundColor: 'white' }
        }
        onClick={() => {
          recording ? onStopRecord() : onStartRecord();
        }}
      >
        {transcribing ? (
          <LoadingCircular size={16} color={'white'} />
        ) : (
          <MicIcon color={recording ? 'white' : '#FF3E9A'} />
        )}
      </div>
    );
  };

  const renderSendBtn = () => {
    return (
      <div className="clickable" onClick={onSendMessage}>
        <ChatSendIcon />
      </div>
    );
  };

  return (
    <div className="mobile_individual_chat_input">
      {renderInputView()}
      {renderAudioBtn()}
      {renderSendBtn()}
    </div>
  );
};
