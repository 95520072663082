import AngleRightIcon from 'assets/svgs/AngleRightIcon';
import React from 'react';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';

import './index.styles.scss';

interface Props {
  isCreatingAI: boolean;
  onSelectedOption: (
    option: 'complete' | 'new_voice' | 'existed_voice'
  ) => void;
}

export const MobileBotAudioOption = ({
  isCreatingAI,
  onSelectedOption
}: Props) => {
  const renderBody = () => {
    return (
      <div className="audio_option_container">
        {renderBodyTop()}
        {renderChoices()}
      </div>
    );
  };

  const renderBodyTop = () => {
    return (
      <>
        <div className="font-bold font28">Give Your AI a Voice</div>
        <div
          className="font-regular font14"
          style={{ textAlign: 'center', marginTop: 4 }}
        >
          Messages from your AI can now be played with a custom voice that you
          choose for your AI. You have three options.
        </div>
      </>
    );
  };

  const renderChoices = () => {
    return (
      <div className="bot_choice_list">
        <div
          className="choice_item row_align_items"
          onClick={() => {
            onSelectedOption('complete');
          }}
        >
          <div className="font-bold font24">🎤</div>
          <div className="choice_text font-medium font12">
            Give your AI your own voice from your profile recordings.
          </div>
          <div>
            <AngleRightIcon color="#FF3E9A" opacity={1} />
          </div>
        </div>
        <div
          className="choice_item row_align_items"
          onClick={() => {
            onSelectedOption('new_voice');
          }}
        >
          <div className="font-bold font24">📁</div>
          <div className="choice_text font-medium font12">
            Create a new voice with uploads of audio samples of other voices.
          </div>
          <div>
            <AngleRightIcon color="#FF3E9A" opacity={1} />
          </div>
        </div>
        <div
          className="choice_item row_align_items"
          onClick={() => {
            onSelectedOption('existed_voice');
          }}
        >
          <div className="font-bold font24">🗣</div>
          <div className="choice_text font-medium font12">
            Copy an existing
            <br />
            voice for your AI.
          </div>
          <div>
            <AngleRightIcon color="#FF3E9A" opacity={1} />
          </div>
        </div>
      </div>
    );
  };

  return <div className="mobile_bot_audio_option">{renderBody()}</div>;
};
