import React from 'react';

function AngleDownIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '24'}
      height={props.size ?? '24'}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g opacity={props.opacity ?? 1}>
        <path
          fill={props.color ?? '#000'}
          d="M17 9.17a1 1 0 00-1.41 0L12 12.71 8.46 9.17a1 1 0 10-1.41 1.42l4.24 4.24a1.002 1.002 0 001.42 0L17 10.59a1.002 1.002 0 000-1.42z"
        />
      </g>
    </svg>
  );
}

export default AngleDownIcon;
