import React, { useEffect, useRef, useState } from 'react';

import './index.styles.scss';
import BG_REGISTER from 'assets/pngs/bg_register_magic_link.png';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { apiUserRequestMagicLink } from 'shared/api/user';
import { useParams, useSearchParams } from 'react-router-dom';

const RESEND_TIME = 60;

export const RegisterMagicLink = () => {
  const param = useParams();
  const code = param.code;

  const [isSent, setIsSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [email, setEmail] = useState('');

  let timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [resendTime, setResendTime] = useState(RESEND_TIME);

  useEffect(() => {
    onCheck();
  }, [resendTime]);

  const onCheck = () => {
    if (resendTime == 0) {
      if (timerRef) {
        clearInterval(timerRef?.current!);
        setResendTime(RESEND_TIME);
      }
    }
  };

  const onStartCounter = () => {
    if (resendTime > 0) {
      timerRef.current = setInterval(() => {
        setResendTime((prev) => prev - 1);
      }, 1000);
    }
  };

  const onSend = async () => {
    if (resendTime === RESEND_TIME && email !== '') {
      setIsSending(true);

      const res = await apiUserRequestMagicLink({
        email: email.replaceAll(' ', ''),
        invite_code: code ?? undefined
      });
      if (res.success) {
        onStartCounter();
        setIsSent(true);
      }

      setIsSending(false);
    }
  };

  const renderSentSuccess = () => {
    return (
      <div className="register_magic_link">
        <div style={{ marginTop: -44 }}>
          <div
            style={{ fontSize: 88, margin: `0 auto 0`, textAlign: 'center' }}
          >
            📬
          </div>
          <div
            className="title font-bold font24"
            style={{ marginTop: 22, textAlign: 'center' }}
          >
            Woohoo, we’ve sent you a magic link to log in
          </div>
          <div
            className="font-regular font16"
            style={{
              color: 'rgba(0, 0, 0, 0.6)',
              marginTop: 12,
              textAlign: 'center'
            }}
          >
            Check your email app for the link
            <br />
            (and spam if not there)
          </div>
          {renderReSendBtn()}
          {renderHavingProblem()}
        </div>
      </div>
    );
  };

  const renderReSendBtn = () => {
    return (
      <div className="send_email_btn">
        <PrimaryButton
          label={resendTime == 60 ? `Resend` : `Resend (${resendTime}s)`}
          isLoading={isSending}
          onPressed={onSend}
        />
      </div>
    );
  };

  const renderHavingProblem = () => {
    return (
      <div className="already_registered">
        <div className="font14 font-regular">
          Want to let us know what you love or any issue you
          have?&nbsp;&nbsp;Write us anytime&nbsp;
          <span className="check_referral font14 font-bold">
            {`${process.env.REACT_APP_SUPPORT_EMAIL}`}
          </span>
        </div>
      </div>
    );
  };

  const renderMainBody = () => {
    return (
      <div className="register_magic_link">
        <div className="align-center">
          <img
            src={'/logo512.png'}
            width={50}
            height={60}
            style={{ objectFit: 'contain' }}
          />
        </div>
        <div style={{ marginTop: 56 }}>
          <img src={BG_REGISTER} width={190} height={182} />
        </div>
        <div className="info_title font-bold font24">
          You’ve been invited to skip the line!
        </div>
        <div className="info_desc font-regular font16">
          Enter your email to join now
        </div>
        <div className="register_input">
          <div className="your_email font10 font-bold">Email</div>
          <input
            value={email}
            placeholder="Enter your email here..."
            className="email_input font-bold font16"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div style={{ marginTop: 22, width: '100%' }}>
          <PrimaryButton
            label="Claim Your Invite"
            isLoading={isSending}
            onPressed={onSend}
          />
        </div>
      </div>
    );
  };

  return <div>{!isSent ? renderMainBody() : renderSentSuccess()}</div>;
};
