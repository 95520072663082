import React, { useEffect, useState } from 'react';
import { NavLink, Route, Routes } from 'react-router-dom';
import { MobileChat } from '../Chat';
import { MobileProfile } from '../Profile';

import QuestionFolder from 'assets/svgs/QuestionFolder';
import ChatIcon from 'assets/svgs/ChatIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';

import './index.styles.scss';
import { JoinNewOrg } from '../Qa/JoinNewOrg';
import { JoinNewGroup } from '../Qa/JoinNewGroup';
import { MobileEventHome } from '../Event/Home';
import { MobileCommunityHome } from '../Community/Home';
import UserFollowerIcon from 'assets/svgs/UserFollowerIcon';
import { MobileFeedHome } from '../Feed/Home';
import { sendAnalytics } from 'shared/utils/analytics';
import { MobileIndividualConversation } from '../Chat/IndividualConversation';

let activeStyle = {
  textDecoration: 'none',
  color: '#FF3E9A'
};

let deactiveStyle = {
  textDecoration: 'none',
  color: '#bdbdbd'
};

export const MobileHome = () => {
  const [tab, setTab] = useState<
    'qa' | 'chat' | 'event' | 'community' | 'profile'
  >('community');

  useEffect(() => {
    sendAnalytics('Home');
  }, []);

  const renderBody = () => {
    return (
      // <div className="mobile_body" id="mobile_body">
      <div className="normal_mobile_body">
        <Routes>
          <Route index path="/" element={<MobileFeedHome />} />
          <Route path="/org/join" element={<JoinNewOrg />} />
          <Route path="/group/join" element={<JoinNewGroup />} />
          <Route path="/chat" element={<MobileChat />} />
          <Route
            path={'/conversation/:type/:id'}
            element={<MobileIndividualConversation />}
          />
          <Route path="/profile" element={<MobileProfile />} />
          <Route path={'/profile/:id/*'} element={<MobileProfile />} />
          <Route path="/event" element={<MobileEventHome />} />
          <Route path="/community" element={<MobileCommunityHome />} />
          <Route path="/agent/:id/*" element={<MobileCommunityHome />} />
        </Routes>
      </div>
    );
  };

  const renderTab = () => {
    return (
      // <div className="mobile_tab" id="mobile_tab">
      <div className="mobile_tab">
        <NavLink
          to={`/community`}
          className="tab_item"
          style={({ isActive }) => {
            if (isActive) {
              setTab('community');
            }

            return isActive ? activeStyle : deactiveStyle;
          }}
        >
          <div>
            <UserFollowerIcon
              size={20}
              color={tab === 'community' ? '#FF3E9A' : '#BDBDBD'}
            />
          </div>
          <div className="tab_item_text font-bold font10">{`Community`}</div>
        </NavLink>
        <NavLink
          to={`/`}
          className="tab_item"
          style={({ isActive }) => {
            if (isActive) {
              setTab('qa');
            }
            return isActive ? activeStyle : deactiveStyle;
          }}
        >
          <div>
            <QuestionFolder
              size={20}
              color={tab === 'qa' ? '#FF3E9A' : '#BDBDBD'}
            />
          </div>
          <div className="tab_item_text font-bold font10">{`Q&A`}</div>
        </NavLink>
        <NavLink
          to={`/chat`}
          className="tab_item"
          style={({ isActive }) => {
            if (isActive) {
              setTab('chat');
            }
            return isActive ? activeStyle : deactiveStyle;
          }}
        >
          <div>
            <ChatIcon
              size={20}
              color={tab === 'chat' ? '#FF3E9A' : '#BDBDBD'}
            />
          </div>
          <div className="tab_item_text font-bold font10">{`Clones`}</div>
        </NavLink>

        <NavLink
          to={`/profile`}
          className="tab_item"
          style={({ isActive }) => {
            if (isActive) {
              setTab('profile');
            }

            return isActive ? activeStyle : deactiveStyle;
          }}
        >
          <div>
            <DefaultAvatarIcon
              color={tab === 'profile' ? '#FF3E9A' : '#BDBDBD'}
            />
          </div>
          <div className="tab_item_text font-bold font10">{`Profile`}</div>
        </NavLink>
      </div>
    );
  };

  return (
    <div className="mobile_home">
      {renderBody()}
      {renderTab()}
    </div>
  );
};
