import React, { useEffect, useState } from 'react';

import './index.styles.scss';
import { useNavigate } from 'react-router-dom';
import { apiGetUserOrganisations } from 'shared/api/user';
import { RootState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import { MobileCardProfileTopOrg } from './CardProfileTopOrg/CardProfileTopOrg';
import BuildingIcon from 'assets/svgs/BuildingIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import BlackBackIcon from 'assets/svgs/BlackBackIcon';

interface Props {
  userId: any;
  selectedOrg?: any;
  isLoadedAll: boolean;
  onLoaded: () => void;
  onSelected?: (orgId) => void;
}

export const MobileProfileTopOrg = ({
  userId,
  selectedOrg,
  isLoadedAll,
  onLoaded,
  onSelected
}: Props) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [isOwner, setIsOwner] = useState(false);

  const [isLoadedOrg, setIsLoadedOrg] = useState(false);
  const [isViewOrg, setIsViewOrg] = useState(true);

  const [arrOrg, setArrOrg] = useState<Array<any>>([]);
  const [lastIdOrg, setLastIdOrg] = useState(0);
  const [hasMoreOrg, setHasMoreOrg] = useState(true);
  const [isLoadingOrg, setIsLoadingOrg] = useState(false);

  useEffect(() => {
    if (userId) {
      setIsOwner(userId === userInfo?.id);
      loadOrgData();
    }
  }, [userId]);

  const loadOrgData = async (init = true) => {
    if (init) {
      setIsLoadingOrg(true);

      const res = await apiGetUserOrganisations(userId);
      onLoaded();

      if (res.success) {
        setIsLoadedOrg(true);
        setArrOrg(res.data);
        setLastIdOrg(res.lastId);
        setHasMoreOrg(res.hasMore);
      }

      setIsLoadingOrg(false);
    } else {
      if (hasMoreOrg && !isLoadingOrg) {
        setIsLoadingOrg(true);

        const res = await apiGetUserOrganisations(userId, 10, lastIdOrg);
        if (res.success) {
          setArrOrg((prev) => [...prev, ...res.data]);
          setLastIdOrg(res.lastId);
          setHasMoreOrg(res.hasMore);
        }

        setIsLoadingOrg(false);
      }
    }
  };

  const onJoinNew = () => {
    navigate('/org/join');
  };

  const onScroll = (e) => {
    const isEndReached =
      e.target.scrollWidth - e.target.scrollLeft < e.target.clientWidth;

    if (isEndReached) {
      loadOrgData(false);
    }
  };

  const renderBody = () => {
    return (
      <div className="org_list" onScroll={onScroll}>
        {isOwner && renderPlusButton()}
        {arrOrg.map((item, index) => {
          return (
            <MobileCardProfileTopOrg
              key={`card_profile_top_org_${index}`}
              data={item}
              isOwner={isOwner}
              isSelected={item.id === selectedOrg}
              onSelected={(orgId) => onSelected && onSelected(orgId)}
            />
          );
        })}
      </div>
    );
  };

  const renderPlusButton = () => {
    return (
      <div className="add_btn" onClick={onJoinNew}>
        <BuildingIcon />
        <div className="btn_plus_bg">
          <PlusIcon size={14} />
        </div>
      </div>
    );
  };

  return (
    <div
      className="mobile_profile_top_org row_align_items"
      style={arrOrg.length === 0 ? { paddingTop: 16 } : { paddingTop: 16 }}
    >
      {!isOwner && (
        <div
          onClick={() => {
            navigate(-1);
          }}
          style={{
            marginRight: 8,
            marginLeft: 16,
            marginBottom: 16
          }}
        >
          <BlackBackIcon />
        </div>
      )}
      {arrOrg.length > 0 && renderBody()}
    </div>
  );
};
