import React from 'react';

function UCoinIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 16}
      height={props.size ?? 16}
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill={props.color ? props.color : '#FF3E9A'}
        d="M14.147 8.5a2 2 0 00.52-1.333 2 2 0 00-2-2H9.213A2 2 0 007.333 2.5h-4a2 2 0 00-2 2 2 2 0 00.52 1.333 2 2 0 000 2.667 2 2 0 000 2.667 2 2 0 00-.52 1.333 2 2 0 002 2h9.334a2 2 0 001.48-3.333 2 2 0 000-2.667zm-6.814 4.667h-4a.667.667 0 010-1.334h4a.667.667 0 010 1.334zm0-2.667h-4a.666.666 0 110-1.333h4a.667.667 0 010 1.333zm0-2.667h-4a.667.667 0 110-1.333h4a.667.667 0 010 1.333zm0-2.666h-4a.667.667 0 010-1.334h4a.667.667 0 110 1.334zm5.794 7.806a.62.62 0 01-.46.194H9.213c.16-.43.16-.904 0-1.334h3.454a.667.667 0 01.667.667.668.668 0 01-.207.473zm0-2.666a.62.62 0 01-.46.193H9.213c.16-.43.16-.903 0-1.333h3.454a.667.667 0 01.667.666.667.667 0 01-.207.474zm0-2.667a.62.62 0 01-.46.193H9.213c.16-.43.16-.903 0-1.333h3.454a.667.667 0 01.667.667.668.668 0 01-.207.473z"
      ></path>
    </svg>
  );
}

export default UCoinIcon;
