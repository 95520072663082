import CommentDotIcon from 'assets/svgs/CommentIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import UCheckIcon from 'assets/svgs/UCheckIcon';
import { MobileChatIntroModal } from 'components/Modals/Mobile/ChatIntroModal';
import React, { useEffect, useMemo, useState } from 'react';
import appAction from 'redux/app/action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiUserFollow, apiUserUnFollow } from 'shared/api/user';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import ReactHowler from 'react-howler';

import './index.styles.scss';
import ShareIcon from 'assets/svgs/ShareIcon';
import { MobileShareBotModal } from 'components/Modals/MobileShare/ShareBot';
import { useAudioContext } from 'shared/contexts/AudioContext';
import AudioPauseIcon from 'assets/svgs/AudioPauseIcon';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';

export const MobileCardTopCreator = ({
  index,
  isEnableFollow,
  item,
  onSelectAI
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isPlaying, setIsPlaying] = useState(false);
  const { playingAudio, setPlayingAudio } = useAudioContext();

  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const [followStatus, setFollowStatus] = useState<
    'false' | 'true' | 'requested'
  >(item.isFollowed);
  const [isFollowing, setIsFollowing] = useState(false);

  const audio = useMemo(() => {
    return item.description_audio;
  }, [item.description_audio]);

  useEffect(() => {
    if (!!audio && (playingAudio ?? '') === audio) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [playingAudio]);

  const togglePlayback = () => {
    if (isPlaying) {
      setPlayingAudio(null);
    } else {
      if (playingAudio) {
        setPlayingAudio(null);
      }
      setPlayingAudio(audio);
    }
  };

  const onFollowAction = () => {
    if (followStatus === 'false') {
      onFollow();
    } else {
      onUnFollow();
    }
  };

  const onFollow = async () => {
    if (!isFollowing) {
      setIsFollowing(true);

      const res = await apiUserFollow({
        followingId: item.ai_id,
        type: 'personal'
      });

      if (res.success) {
        setFollowStatus(res.isFollowed);

        // refresh start new
        dispatch(appAction.refreshMobileStartNew());
      }

      setIsFollowing(false);
    }
  };

  const onUnFollow = async () => {
    if (!isFollowing) {
      setIsFollowing(true);

      const res = await apiUserUnFollow({
        following: item.ai_id,
        type: 'personal'
      });
      if (res.success) {
        setFollowStatus('false');

        // refresh start new
        dispatch(appAction.refreshMobileStartNew());
      }

      setIsFollowing(false);
    }
  };

  const onChat = () => {
    if (item.ai_type === 'personal') {
      setShowIntroModal(true);
    } else {
      onSelectAI(item.ai_type, item.ai_id);
    }
  };

  const onViewCreatorProfile = () => {
    navigate(`/profile/${item.creator_id}`);
  };

  const renderAudioIcon = () => {
    if (!!audio) {
      return (
        <div className="audio_play_btn" onClick={togglePlayback}>
          {isPlaying ? (
            <AudioPauseIcon size={16} color={'white'} />
          ) : (
            <AudioPlayIcon size={16} color={'white'} />
          )}
          {isPlaying && (
            <ReactHowler
              src={audio}
              playing={isPlaying}
              loop={false}
              html5={true} // Use HTML5 Audio API
              onLoad={() => {}}
              onEnd={() => setPlayingAudio(null)}
            />
          )}
        </div>
      );
    } else {
      return <div />;
    }
  };

  const renderFollowBtn = () => {
    if (followStatus === 'false') {
      return (
        <div
          className="follow_btn font-bold font10 clickable row_align_items"
          onClick={onFollowAction}
        >
          {isFollowing ? (
            <LoadingCircular size={12} color="main" />
          ) : (
            <PlusIcon color="rgba(0, 0, 0, 0.4)" size={16} />
          )}
          {`Follow`}
        </div>
      );
    } else if (followStatus === 'true') {
      return (
        <div
          className="requested_btn font-bold font10 clickable"
          onClick={onFollowAction}
        >
          {isFollowing ? (
            <LoadingCircular size={12} color="main" />
          ) : (
            <UCheckIcon size={16} color="#FF3E9A" />
          )}
          {`Following`}
        </div>
      );
    } else {
      return (
        <div
          className="requested_btn font-bold font10 clickable"
          onClick={onFollowAction}
        >
          {isFollowing ? (
            <LoadingCircular size={12} color="main" />
          ) : (
            `Requested`
          )}
        </div>
      );
    }
  };

  const renderShareBtn = () => {
    return (
      <div
        className="share_btn"
        onClick={() => {
          setShowShareModal(true);
        }}
      >
        <ShareIcon />
      </div>
    );
  };

  const renderIntroModal = () => {
    return (
      <MobileChatIntroModal
        open={showIntroModal}
        aiInfo={item}
        onClose={() => {
          setShowIntroModal(false);
        }}
      />
    );
  };

  const renderShareModal = () => {
    return (
      <MobileShareBotModal
        show={showShareModal}
        data={item}
        onClose={() => {
          setShowShareModal(false);
        }}
      />
    );
  };

  const renderAiImage = () => {
    return (
      <div className="card_creator_image">
        {item.ai_image === null || item.ai_image === '' ? (
          <DefaultAvatarIcon size={`calc(100%)`} />
        ) : (
          <img src={item.image} />
        )}
        {renderAudioIcon()}
      </div>
    );
  };

  const renderAiInfo = () => {
    return (
      <div className="ai_info">
        <div
          className="row_align_items"
          style={{ gap: 4 }}
          onClick={onViewCreatorProfile}
        >
          {item.creator_image && item.creator_image.startsWith('https') ? (
            <img src={item.creator_image} className="creator_image" />
          ) : (
            <DefaultAvatarIcon size={88} />
          )}
          <div className="font-regular font10 text_no_wrap">
            {item.creator_name}
          </div>
        </div>
        <div className="top_creator_name font16 font-bold text_no_wrap">
          {`${item.name}'s AI`}
        </div>
        <div className="desc_txt font-regular font12">
          {item.short_description ?? item.description}
        </div>
        <div className="card_creator_bottom">
          {isEnableFollow && renderFollowBtn()}
          <div className="chat_btn" onClick={() => onChat()}>
            <CommentDotIcon color="white" size={12} />
            <div className="font-bold font10" style={{ color: 'white' }}>
              Chat
            </div>
          </div>
        </div>
        {renderShareBtn()}
      </div>
    );
  };

  return (
    <div className="mobile_card_creator" key={`card_creator_${index}`}>
      {renderAiImage()}
      {renderAiInfo()}
      {renderIntroModal()}
      {renderShareModal()}
    </div>
  );
};
