import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import SearchIcon from 'assets/svgs/SearchIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import VerticalDetailIcon from 'assets/svgs/VerticalDetailIcon';
import { ConversationVoteModal } from 'components/Modals/ConversationVoteModal';
import { MobileMultichatActionModal } from 'components/Modals/Mobile/MultiChatActionModal';
import { MobileShareChatModal } from 'components/Modals/Mobile/ShareChatModal';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  apiChatCreateMultiChat,
  apiChatGet,
  apiGetChatChatAgents
} from 'shared/api/chat';
import { SearchBox } from 'shared/ui-kit/SearchBox';

import './index.styles.scss';
import { MobilePromotConversationBot } from './PromotConversationBot';
import { MobilePromotConversationChat } from './PromotConversationChat';

export const MobilePromotConversation = () => {
  const navigate = useNavigate();

  // messages
  const [arrMessage, setArrMessage] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMessage, setIsLoadingMessage] = useState(true);

  const [showActionDlg, setShowActionDlg] = useState(false);
  const [showVoteModal, setShowVoteModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const params = useParams();
  const aiId = params.id;
  const [roomId, setRoomId] = useState(params.roomId);
  const [roomName, setRoomName] = useState('');
  const [isEndedRoom, setIsEndedRoom] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [arrAgent, setArrAgent] = useState<Array<any>>([]);
  const [roomInfo, setRoomInfo] = useState<any>(null);

  const [isSearching, setIsSearching] = useState(false);
  const [query, setQuery] = useState('');

  const [isSharing, setIsSharing] = useState(false);
  const [showFullShareOption, setShowFullShareOption] = useState(true);

  const [isBusy, setIsBusy] = useState(false);
  const [currentBusyTime, setCurrentBusyTime] = useState(0);
  let busyTimerRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (!roomId || roomId === 'null') {
      createChat();
    } else {
      loadAgents();
      checkRoomInfo();
    }
  }, []);

  useEffect(() => {
    if (roomId && aiId) {
      loadMessage(true);
    }
  }, [roomId, aiId]);

  useEffect(() => {
    loadMessage(true);
  }, [query]);

  const onBack = () => {
    if (isSharing) {
      setIsSharing(false);
    } else {
      navigate(-1);
    }
  };

  const createChat = async () => {
    setIsLoading(true);

    const res = await apiChatCreateMultiChat({
      ai_id: aiId,
      ai_type: 'personal'
    });

    if (res.sucess) {
      setArrAgent(res.agents);
      setRoomInfo(res.room);
      setRoomId(res.room.id);

      navigate(`/prompt/conversation/${aiId}/${res.room.id}`, {
        replace: true
      });
    }

    setIsLoading(false);
  };

  const loadAgents = async () => {
    const res = await apiGetChatChatAgents({
      room: roomId
    });

    if (res.success) {
      setArrAgent(res.data);
    }
  };

  const checkRoomInfo = async () => {
    const res = await apiChatGet({
      room: roomId,
      ai_id: aiId,
      ai_type: 'personal',
      limit: 10,
      offset: 0
    });

    if (res.success) {
      if (res.room) {
        setIsEndedRoom(res.room.is_voted);
        setRoomName(res.room.name);
      }
    }
  };

  const loadMessage = async (init = true) => {
    if (init) {
      setIsLoadingMessage(true);
      const res = await apiChatGet({
        query: !isSearching ? null : query,
        room: roomId,
        ai_id: parseInt(aiId!),
        ai_type: 'personal',
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrMessage(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);

        if (res.room && res.room.is_busy === 1) {
          onStartBusyTime(res.room.busy_until);
        }
      } else {
        setArrMessage([]);
      }

      setIsLoadingMessage(false);
    } else {
      if (!isLoadingMessage && hasMore) {
        setIsLoadingMessage(true);

        const res = await apiChatGet({
          query: !isSearching ? null : query,
          room: roomId,
          ai_id: parseInt(aiId!),
          ai_type: 'personal',
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrMessage((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoadingMessage(false);
      }
    }
  };

  useEffect(() => {
    if (currentBusyTime === 0) {
      clearInterval(busyTimerRef?.current!);
      setIsBusy(false);
      setCurrentBusyTime(0);
    }
  }, [currentBusyTime]);

  const onStartBusyTime = (busyTime) => {
    if (busyTime !== 0) {
      setIsBusy(true);

      setCurrentBusyTime(busyTime);
      busyTimerRef.current = setInterval(() => {
        setCurrentBusyTime((prev) => prev - 1);
      }, 1000);
    }
  };

  const onDeleteFromUI = (botId) => {
    setArrAgent((prev) => prev.filter((item, index) => item.ai_id != botId));
  };

  const onUpdateMessageInfo = (tmpId, realId, audio) => {
    if (audio) {
      setArrMessage((prev) => {
        return prev.map((item, index) => {
          if (item.tmp_id === tmpId || item.id === realId) {
            return { ...item, id: realId, audio: audio };
          } else {
            return item;
          }
        });
      });
    } else {
      setArrMessage((prev) => {
        return prev.map((item, index) => {
          if (item.tmp_id === tmpId) {
            return { ...item, id: realId };
          } else {
            return item;
          }
        });
      });
    }
  };

  const renderHeader = () => {
    return (
      <div
        className="row_space_between"
        style={{ padding: '0px 16px', minHeight: 40 }}
      >
        <div className="row_align_items" style={{ flex: 1, gap: 8 }}>
          <div onClick={onBack}>
            <AngleLeftIcon color="black" size={24} />
          </div>
          {!isSharing ? (
            <div className="font-bold font16">Bots ({arrAgent.length})</div>
          ) : (
            <div className="share_title font-bold font16">
              Share from selection
            </div>
          )}
        </div>
        {!isSharing && (
          <div className="row_align_items" style={{ gap: 12 }}>
            <div
              onClick={() => {
                setIsSearching(true);
              }}
            >
              <SearchIcon color={'black'} />
            </div>
            <div
              className="align-center"
              onClick={() => {
                setShowFullShareOption(false);
                setShowShareModal(true);
              }}
            >
              <ShareIcon />
            </div>
            {!isEndedRoom && (
              <div
                className="align-center"
                onClick={() => {
                  setShowActionDlg(true);
                }}
              >
                <VerticalDetailIcon color={'black'} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderSearchView = () => {
    return (
      <div className="row_space_between" style={{ padding: '0px 16px' }}>
        <div style={{ width: '100%' }}>
          <SearchBox
            searchIconClickable={false}
            placeholder="Search messages here..."
            fontSize={14}
            height="40px"
            enableShadow={false}
            onSearch={setQuery}
            onClickedSearchIcon={() => {
              setIsSearching(false);
            }}
          />
        </div>
      </div>
    );
  };

  const renderBot = () => {
    return (
      <div>
        {!isSharing && (
          <MobilePromotConversationBot
            data={arrAgent}
            roomId={roomId}
            isEndedChat={isEndedRoom}
            onDeleteFromUI={onDeleteFromUI}
            onUpdateBots={(newBots) => {
              setArrAgent(newBots);
            }}
          />
        )}
      </div>
    );
  };

  const renderChat = () => {
    return (
      <MobilePromotConversationChat
        aiId={aiId}
        roomId={roomId}
        roomName={roomName}
        isSharing={isSharing}
        isEnded={isEndedRoom}
        isBusy={isBusy}
        busyTime={currentBusyTime}
        onShowShare={(status, messageId) => {
          if (!isSharing) {
            setSelectedMessageId(messageId);
            setShowFullShareOption(true);
            setShowShareModal(true);
          }
        }}
        onFinishShare={() => {
          setIsSharing(false);
        }}
        arrMessage={arrMessage}
        onAddMessage={(message) => {
          setArrMessage((prev) => [message, ...prev]);
        }}
        onLoadMore={() => {
          loadMessage(false);
        }}
        onUpdateMessageInfo={onUpdateMessageInfo}
      />
    );
  };

  const renderActionDlg = () => {
    return (
      <MobileMultichatActionModal
        open={showActionDlg}
        roomId={roomId}
        onClose={() => {
          setShowActionDlg(false);
        }}
        onEnded={() => {
          setShowVoteModal(true);
        }}
        onAddMessage={(message) => {
          setArrMessage((prev) => [message, ...prev]);
        }}
      />
    );
  };

  const renderChatVoteModal = () => {
    return (
      <ConversationVoteModal
        show={showVoteModal}
        roomId={roomId}
        roomInfo={roomInfo}
        isMobile={true}
        onClose={() => setShowVoteModal(false)}
        onSuccess={() => {
          setShowVoteModal(false);
          setIsEndedRoom(true);
        }}
      />
    );
  };

  const renderShareModal = () => {
    return (
      <MobileShareChatModal
        open={showShareModal}
        roomId={roomId}
        messageId={selectedMessageId}
        showFullOptions={showFullShareOption}
        onClose={() => {
          setShowShareModal(false);
        }}
        onSelectedOption={(option) => {
          if (option === 'selection') {
            setShowShareModal(false);
            setIsSharing(true);
          }
        }}
      />
    );
  };

  return (
    <div className="mobile_promot_conversation">
      {isSearching ? renderSearchView() : renderHeader()}
      {renderBot()}
      {renderChat()}
      {renderActionDlg()}
      {renderChatVoteModal()}
      {renderShareModal()}
    </div>
  );
};
