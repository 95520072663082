import React from 'react';

function FilterCommentIcon({ isSelected }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={isSelected ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'}
        d="M17.174 15.99a5.833 5.833 0 00-2.283-8.809 6.667 6.667 0 10-11.825 5.242l-1.158 1.15a.833.833 0 00-.175.908.833.833 0 00.766.517h4.742a5.833 5.833 0 005.258 3.333h5a.833.833 0 00.767-.516.833.833 0 00-.175-.909l-.917-.916zM6.666 12.498c.001.279.024.558.067.833H4.508l.291-.283a.834.834 0 000-1.183A4.942 4.942 0 013.333 8.33a5 5 0 015-5 4.95 4.95 0 014.708 3.334H12.5a5.833 5.833 0 00-5.833 5.833zm8.783 4.167l.042.041H12.5a4.167 4.167 0 112.95-1.216.834.834 0 00-.25.583.835.835 0 00.25.592z"
      ></path>
    </svg>
  );
}

export default FilterCommentIcon;
