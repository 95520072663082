import { apiGetUserInfo, apiGetUserSocials } from 'shared/api/user';
import { put, call, takeEvery, takeLatest, cancel } from 'redux-saga/effects';
import actions from 'redux/profile/actions';

function* loadUserInfo(payload: any) {
  try {
    const userId = payload.payload;
    const resUserInfo = yield call(apiGetUserInfo, userId);
    const resUserSocial = yield call(apiGetUserSocials, userId);

    if (resUserInfo.success && resUserSocial.success) {
      yield put(
        actions.loadUserInfoSuccess({
          userInfo: resUserInfo.data,
          socials: resUserSocial.socials
        })
      );
    } else {
      yield put(actions.loadUserInfoFailed());
    }
  } catch (err) {
    console.log(err);
    yield put(actions.loadUserInfoFailed());
  }
}

export default function* profileSaga() {
  yield takeEvery(actions.LOAD_USER_INFO, loadUserInfo);
}
