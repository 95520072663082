import React from 'react';

function LinkIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 20}
      height={props.size ?? 20}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={props.color ?? '#ffff'}
        d="M6.667 10a.833.833 0 00.833.833h5a.833.833 0 100-1.666h-5a.833.833 0 00-.833.833zm1.666 2.5h-2.5a2.5 2.5 0 010-5h2.5a.833.833 0 000-1.667h-2.5a4.167 4.167 0 000 8.334h2.5a.833.833 0 100-1.667zm5.833-6.667h-2.5a.833.833 0 000 1.667h2.5a2.5 2.5 0 010 5h-2.5a.833.833 0 000 1.667h2.5a4.167 4.167 0 100-8.334z"
        opacity={props.opacity ?? 0.5}
      ></path>
    </svg>
  );
}

export default LinkIcon;
