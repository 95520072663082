import React from 'react';

function FilterMemeIcon({ isSelected }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={isSelected ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'}
        d="M15.001 12.497V4.164a2.5 2.5 0 00-2.5-2.5H4.168a2.5 2.5 0 00-2.5 2.5v8.333a2.5 2.5 0 002.5 2.5h8.333a2.5 2.5 0 002.5-2.5zM3.335 4.164a.833.833 0 01.833-.833h8.333a.833.833 0 01.834.833v3.633l-.9-.908a2.133 2.133 0 00-3.017 0l-.758.758-.675-.675a2.442 2.442 0 00-3.425 0L3.335 8.206V4.164zm.1 8.708a.783.783 0 01-.1-.375v-1.941l2.4-2.4a.758.758 0 011.075 0l.691.675-4.066 4.041zm7.166-4.8a.432.432 0 01.325-.141.434.434 0 01.325.141l2.084 2.075v2.35a.834.834 0 01-.834.834H5.335L10.6 8.072zm6.9-3.075a.833.833 0 00-.833.834v8.333a2.5 2.5 0 01-2.5 2.5H5.835a.834.834 0 000 1.667h8.333a4.167 4.167 0 004.167-4.167V5.831a.834.834 0 00-.834-.834z"
      ></path>
    </svg>
  );
}

export default FilterMemeIcon;
