import { RootState, UserInfo } from 'redux/interfaces';
import actions from './action';

export type RootAppState = RootState['app'];

interface State extends RootAppState {
  hasNewBookmark: boolean;
  hasNewHightLight: boolean;
  hasNewEvent: boolean;
  hasNewSocialMedia: boolean;
  hasNewOrgOtherAnswer: boolean;
  hasNewStartNew: boolean;
  newBookmarkData: any;
  communityScroll: boolean;
}

interface Action extends RootAppState {
  type: string;
  payload: any;
}

const initalState: State = {
  hasNewBookmark: false,
  hasNewHightLight: false,
  hasNewEvent: false,
  hasNewSocialMedia: false,
  hasNewOrgOtherAnswer: false,
  hasNewStartNew: false,
  newBookmarkData: null,
  communityScroll: false
};

export default function appReducer(
  state: RootState['app'] = initalState,
  action: Action
) {
  const { type, payload } = action;

  switch (type) {
    case actions.REFRESH_BOOKMARK_DATA:
      return {
        ...state,
        hasNewBookmark: !state.hasNewBookmark
      };
    case actions.REFRESH_HIGHLIGHT_DATA:
      return {
        ...state,
        hasNewHightLight: !state.hasNewHightLight
      };
    case actions.REFRESH_EVENTS_DATA:
      return {
        ...state,
        hasNewEvent: !state.hasNewEvent
      };
    case actions.REFRESH_MOBILE_SOCIAL_MEDIA:
      return {
        ...state,
        hasNewSocialMedia: !state.hasNewSocialMedia
      };
    case actions.REFRESH_MOBILE_START_NEW:
      return {
        ...state,
        hasNewStartNew: !state.hasNewStartNew
      };
    case actions.REFRESH_ORG_OTHER_ANSWER:
      return {
        ...state,
        hasNewOrgOtherAnswer: !state.hasNewOrgOtherAnswer
      };
    case actions.ADD_BOOKMARK_DATA:
      return {
        ...state,
        hasNewEvent: !state.hasNewEvent,
        newBookmarkData: payload
      };
    case actions.COMMUNITY_SCROLL:
      return {
        ...state,
        communityScroll: !state.communityScroll
      };
    default:
      return state;
  }
}
