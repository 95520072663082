import React, { useMemo, useState } from 'react';
import ReactPlayer from 'react-player';

import AudioPauseIcon from 'assets/svgs/AudioPauseIcon';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import './index.styles.scss';
import TrashIcon from 'assets/svgs/TrashIcon';
import { showToast } from 'shared/ui-kit/PrimaryToast';

export const MobileCardCreateMedia = ({
  data,
  index,
  maxVideoDuration,
  onRemove
}) => {
  const [playVideo, setPlayVideo] = useState(false);

  const mediaUrl = useMemo(() => {
    return URL.createObjectURL(data);
  }, [data]);

  const mediaType = useMemo(() => {
    if (data.type.startsWith('image')) {
      return 'image';
    } else if (data.type.startsWith('video')) {
      return 'video';
    } else {
      return null;
    }
  }, [data]);

  const onChangedDuration = (duration) => {
    if (duration > maxVideoDuration) {
      onRemove(index);

      showToast('error', 'Please select video no longer than 20 seconds');
    }
  };

  const renderRemoveBtn = () => {
    return (
      <div className="remove_btn" onClick={() => onRemove(index)}>
        <TrashIcon />
      </div>
    );
  };

  return (
    <div className="mobile_card_create_media">
      {mediaType === 'video' && (
        <div className="card_media_view">
          <ReactPlayer
            url={mediaUrl}
            width="100%"
            height="100%"
            controls={false}
            playing={playVideo}
            onEnded={() => setPlayVideo(false)}
            onDuration={onChangedDuration}
          />
          <div
            className="play_btn"
            onClick={() => setPlayVideo((prev) => !prev)}
          >
            {playVideo ? <AudioPauseIcon /> : <AudioPlayIcon color="#FF3E9A" />}
          </div>
        </div>
      )}
      {mediaType === 'image' && (
        <img src={mediaUrl} className="card_media_view" />
      )}
      {renderRemoveBtn()}
    </div>
  );
};
