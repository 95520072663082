import React from 'react';

function ImageIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
    >
      <path
        fill={props.color ?? '#FF3E9A'}
        d="M15.497 13.001V4.668a2.5 2.5 0 00-2.5-2.5H4.664a2.5 2.5 0 00-2.5 2.5v8.333a2.5 2.5 0 002.5 2.5h8.333a2.5 2.5 0 002.5-2.5zM3.831 4.668a.833.833 0 01.833-.833h8.333a.833.833 0 01.834.833v3.633l-.9-.908a2.133 2.133 0 00-3.017 0l-.758.758-.675-.675a2.442 2.442 0 00-3.425 0L3.83 8.71V4.668zm.1 8.708a.783.783 0 01-.1-.375V11.06l2.4-2.4a.758.758 0 011.075 0l.691.675-4.066 4.041zm7.166-4.8a.433.433 0 01.325-.141.433.433 0 01.325.141l2.084 2.075v2.35a.834.834 0 01-.834.834H5.831l5.266-5.259zm6.9-3.075a.833.833 0 00-.833.834v8.333a2.5 2.5 0 01-2.5 2.5H6.331a.834.834 0 000 1.667h8.333a4.167 4.167 0 004.167-4.167V6.335a.834.834 0 00-.834-.834z"
      ></path>
    </svg>
  );
}

export default ImageIcon;
