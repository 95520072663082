import React, { useEffect, useRef, useState } from 'react';
import { useAudioContext } from 'shared/contexts/AudioContext';
import ReactHowler from 'react-howler';
import AudioPauseIcon from 'assets/svgs/AudioPauseIcon';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import SineWaves from 'sine-waves';

import './index.styles.scss';

interface BackgroundAudioProps {
  audioSrc: string;
  index: any;
  loop?: boolean;
}

const ChatAudioFilePlayer: React.FC<BackgroundAudioProps> = ({
  audioSrc,
  index,
  loop = true
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const { playingAudio, setPlayingAudio } = useAudioContext();
  const waves = useRef<any>(null);

  useEffect(() => {
    if ((playingAudio ?? '') === audioSrc) {
      setIsPlaying(true);
      startAnimation();
    } else {
      setIsPlaying(false);
      stopAnimation();
    }
  }, [playingAudio, audioSrc]);

  useEffect(() => {
    initAnimation();
  }, []);

  const initAnimation = () => {
    waves.current = new SineWaves({
      el: document.getElementById(`waves_${index}`),

      speed: 3,

      ease: 'SineInOut',

      wavesWidth: '200%',

      waves: [
        {
          timeModifier: 4,
          lineWidth: 1,
          amplitude: -25,
          wavelength: 25
        },
        {
          timeModifier: 2,
          lineWidth: 1,
          amplitude: -10,
          wavelength: 30
        },
        {
          timeModifier: 1,
          lineWidth: 1,
          amplitude: -30,
          wavelength: 40
        }
      ],

      // Called on window resize
      resizeEvent: function () {
        var gradient = this.ctx.createLinearGradient(0, 0, this.width, 0);
        gradient.addColorStop(0, 'rgba(255, 62, 154, 0)');
        gradient.addColorStop(0.5, 'rgba(255, 62, 154, 1)');
        gradient.addColorStop(1, 'rgba(255, 62, 154, 0');

        var index = -1;
        var length = this.waves.length;
        while (++index < length) {
          this.waves[index].strokeStyle = gradient;
        }

        // Clean Up
        length = void 0;
        gradient = void 0;
      }
    });

    // or
    waves.current.running = false;

    // And then update the animation one frame
    waves.current.update();
  };

  const togglePlayback = () => {
    if (isLoaded) {
      if (isPlaying) {
        setPlayingAudio(null);
      } else {
        if (playingAudio) {
          setPlayingAudio(null);
        }
        setPlayingAudio(audioSrc);
      }
    }
  };

  const stopAnimation = () => {
    if (waves.current) {
      waves.current.running = false;
      waves.current.update();
    }
  };

  const startAnimation = () => {
    if (waves.current) {
      waves.current.running = true;
      waves.current.update();
    }
  };

  return (
    <div className="chat_audio_file_player row_align_items row_space_between">
      <canvas className="animation_view" id={`waves_${index}`}></canvas>
      <div className="action_item" onClick={togglePlayback}>
        {!isLoaded ? (
          <LoadingCircular size={16} color="main" />
        ) : isPlaying ? (
          <AudioPauseIcon />
        ) : (
          <AudioPlayIcon color="#FF3E9A" />
        )}
        <ReactHowler
          src={audioSrc}
          playing={isPlaying}
          loop={loop}
          html5={true} // Use HTML5 Audio API
          onLoad={() => setIsLoaded(true)}
          onEnd={() => setPlayingAudio(null)}
        />
      </div>
      <div className="row_align_items">
        <div className="font-medium font10">0.2.11</div>
        <div
          className="font-medium font10"
          style={{ opacity: 0.4, padding: '0px 8px' }}
        >
          /
        </div>
        <div className="font-medium font10" style={{ opacity: 0.4 }}>
          10.40
        </div>
      </div>
    </div>
  );
};

export default ChatAudioFilePlayer;
