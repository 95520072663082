import React from 'react';
import FilterCheckIcon from '../../TypeFilter/svgs/FilterCheckIcon';

import './index.styles.scss';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';

interface Props {
  data: any;
  isSelected: boolean;
  onSelected: (item) => void;
}

export const MobileCardFeedCreatorFilter = ({
  data,
  isSelected,
  onSelected
}: Props) => {
  return (
    <div className="mobile_card_feed_creator_filter">
      <div
        className={`${
          isSelected ? 'selected' : 'default'
        }_filter_item row_align_items row_space_between`}
        onClick={() => {
          onSelected({
            type: 'creator',
            id: data.ai_id,
            item_type: data.ai_type
          });
        }}
      >
        <div className="row_align_items">
          {data.ai_image && data.ai_image.startsWith('https') ? (
            <img src={data.ai_image} className={'filter_image'} />
          ) : (
            <DefaultAvatarIcon size={36} />
          )}
          <div style={{ marginLeft: 12 }}>
            <div className="font-bold font14">{data.ai_name}</div>
            {data.score && (
              <div
                className="font-regular font12"
                style={{ opacity: 0.4 }}
              >{`${data.score} posts`}</div>
            )}
          </div>
        </div>
        {isSelected ? (
          <FilterCheckIcon />
        ) : (
          <div className="default_mark"></div>
        )}
      </div>
    </div>
  );
};
