import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import './index.styles.scss';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import { DeviceSize } from 'shared/utils/constants';
import { useMediaQuery } from 'react-responsive';

interface Props {
  show: boolean;
  selectedDate: any;
  onClose: () => void;
  onSelected: (newDate) => void;
}

export const SelectDateModal = ({
  show,
  selectedDate,
  onClose,
  onSelected
}: Props) => {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  return (
    <PrimaryModal
      show={show}
      width={!isMobile ? 500 : '100%'}
      onClose={onClose}
      style={{ backgroundColor: 'white', padding: 0 }}
    >
      <div className="modal_select_date">
        <DayPicker
          mode="single"
          selected={selectedDate}
          onSelect={onSelected}
        />
      </div>
    </PrimaryModal>
  );
};
