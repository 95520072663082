import React, { useEffect, useState } from 'react';
import { apiGetSuggestTopic } from 'shared/api/suggest';
import { MobileCardFeedTopicFilter } from './CardTopicFilter';
import './index.styles.scss';

interface Props {
  selectedFilter: Array<any>;
  onSelected: (item) => void;
}

export const MobileFeedTopicFilter = ({
  selectedFilter,
  onSelected
}: Props) => {
  const [arrTopic, setArrTopic] = useState<Array<any>>([]);
  const [lastIdTopic, setLastIdTopic] = useState(0);
  const [hasMoreTopic, setHasMoreTopic] = useState(true);
  const [isLoadingTopic, setIsLoadingTopic] = useState(false);

  useEffect(() => {
    loadTopicList();
  }, []);

  const loadTopicList = async (init = true) => {
    if (init) {
      setIsLoadingTopic(true);

      const res = await apiGetSuggestTopic({
        limit: 20,
        offset: 0
      });

      if (res.success) {
        setArrTopic(res.data);
        setLastIdTopic(res.lastId);
        setHasMoreTopic(res.hasMore);
      }
      setIsLoadingTopic(false);
    } else {
      if (!isLoadingTopic && hasMoreTopic) {
        setIsLoadingTopic(true);

        const res = await apiGetSuggestTopic({
          limit: 20,
          offset: lastIdTopic
        });
        if (res.success) {
          setArrTopic((prev) => [...prev, ...res.data]);
          setLastIdTopic(res.lastId);
          setHasMoreTopic(res.hasMore);
        }
        setIsLoadingTopic(false);
      }
    }
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      loadTopicList(false);
    }
  };

  return (
    <div
      className="mobile_feed_topic_filter"
      id="mobile_feed_topic_filter"
      onScroll={handleScroll}
    >
      {arrTopic.map((item, index) => {
        const isSelected =
          selectedFilter.filter(
            (sItem, sIndex) =>
              sItem.type === 'topic' && sItem.id === item.topic_id
          ).length > 0;

        return (
          <MobileCardFeedTopicFilter
            key={`mobile_card_feed_topic_${index}`}
            data={item}
            isSelected={isSelected}
            onSelected={(item) => onSelected(item)}
          />
        );
      })}
    </div>
  );
};
