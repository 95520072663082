import React from 'react';

function LinkAddIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#FF3E9A"
        d="M12.11 15.39l-3.88 3.88a2.47 2.47 0 01-3.5 0 2.46 2.46 0 010-3.5l3.88-3.88a1.004 1.004 0 00-1.42-1.42l-3.88 3.89a4.48 4.48 0 006.33 6.33l3.89-3.88a1.004 1.004 0 10-1.42-1.42zm-3.28-.22a1 1 0 00.71.29 1 1 0 00.71-.29l4.92-4.92a1.004 1.004 0 00-1.42-1.42l-4.92 4.92a.999.999 0 000 1.42zM21 18h-1v-1a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 000-2zm-4.19-4.47l3.88-3.89a4.48 4.48 0 00-6.33-6.33l-3.89 3.88a1.004 1.004 0 101.42 1.42l3.88-3.88a2.47 2.47 0 013.5 0 2.46 2.46 0 010 3.5l-3.88 3.88a1 1 0 000 1.42 1 1 0 001.42 0z"
      ></path>
    </svg>
  );
}

export default LinkAddIcon;
