import { RootState, SocialInfo, UserInfo } from 'redux/interfaces';
import actions from './actions';

export type RootProfileState = RootState['profile'];

interface State extends RootProfileState {
  userInfo?: UserInfo;
  socials: Array<SocialInfo>;
  isConnectingSocial: boolean;
  isDeletingSocial: boolean;
}

interface Action extends RootProfileState {
  type: string;
  payload: any;
}

const initalState: State = {
  userInfo: undefined,
  socials: [],
  isConnectingSocial: false,
  isDeletingSocial: false
};

export default function profileReducer(
  state: RootState['profile'] = initalState,
  action: Action
) {
  const { type, payload } = action;

  switch (type) {
    case actions.LOAD_USER_INFO:
      return {
        ...state
      };
    case actions.LOAD_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: payload.userInfo,
        socials: payload.socials,
        isConnectingSocial: false,
        isDeletingSocial: false
      };
    case actions.LOAD_USER_INFO_FAILED:
      return {
        ...state,
        isConnectingSocial: false,
        isDeletingSocial: false
      };
    case actions.CONNECTING_SOCAIL:
      return {
        ...state,
        isDeletingSocial: false,
        isConnectingSocial: true
      };
    case actions.CONNECTING_SOCAIL_SUCCESS:
      return {
        ...state,
        isDeletingSocial: false,
        isConnectingSocial: false
      };
    case actions.CONNECTING_SOCAIL_FAILED:
      return {
        ...state,
        isDeletingSocial: false,
        isConnectingSocial: false
      };
    case actions.DELETING_SOCIAL:
      return {
        ...state,
        isDeletingSocial: true,
        isConnectingSocial: false
      };
    case actions.DELETING_SOCIAL_SUCCESS:
      return {
        ...state,
        isDeletingSocial: false,
        isConnectingSocial: false
      };
    case actions.DELETING_SOCIAL_FAILED:
      return {
        ...state,
        isDeletingSocial: false,
        isConnectingSocial: false
      };
    default:
      return state;
  }
}
