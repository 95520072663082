import React, { useMemo } from 'react';
import { FolderCard } from 'shared/ui-kit/FolderCard';

import './index.styles.scss';
import { MobilePersonalTypeFirst } from './PersonalTypeFirst';
import { MobilePersonalTypeGetStart } from './PersonalTypeGetStart';
import { MobilePersonalTypeSecond } from './PersonalTypeSecond';

interface Props {
  data: Array<any>;
  isLoaded: boolean;
  onGetStart: () => void;
}

export const MobileChatPersonalAI = ({ data, isLoaded, onGetStart }: Props) => {
  const arrAi = useMemo(() => {
    return data.map((item, index) => {
      return {
        ...item,
        index: index
      };
    });
  }, [data]);

  return (
    <div className="mobile_chat_personal_ai">
      {arrAi.length === 0 && isLoaded ? (
        <FolderCard
          number={'0001'}
          content={<MobilePersonalTypeGetStart onGetStart={onGetStart} />}
        />
      ) : arrAi.length === 1 ? (
        <FolderCard
          number={'0001'}
          content={<MobilePersonalTypeFirst data={arrAi[0]} />}
        />
      ) : data.length >= 2 ? (
        <MobilePersonalTypeSecond data={arrAi} />
      ) : (
        <div />
      )}
    </div>
  );
};
