import React, { useEffect, useMemo, useRef, useState } from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './index.styles.scss';
import '@brainhubeu/react-carousel/lib/style.css';

import { MobileCardDidKnow } from './components/CardDidKnow';
import { MobileCardFlipQuestion } from './components/CardFlipQuestion';
import { MobileQuestionDetail } from '../QuestionDetail';
import { MobileCardMeme } from './components/CardMeme';
import { apiGetSuggestFeed } from 'shared/api/suggest';
import { useLocation, useNavigate } from 'react-router-dom';
import { MobileFeedFilterModal } from 'components/Modals/Mobile/FeedFilterModal';
import { MobileFeedTopics } from './components/FeedTopics';

import { socket } from 'components/Auth';
import { sendAnalytics } from 'shared/utils/analytics';
import { getArrFromString } from 'shared/utils/utils';
import { useAudioContext } from 'shared/contexts/AudioContext';

import { MobileFeedCardArticle } from './components/CardArticle';
import { MobileFeedCardFile } from './components/CardFile';
import { MobileContentAddModal } from 'components/Modals/Mobile/ConentAddModal';
import { MobileCardMedia } from './components/CardMedia';
import { MobileCardPoll } from './components/CardPoll';
import { MobileFeedCardChat } from './components/CardChat';
import { MobileFeedCardBookMark } from './components/CardBookMark';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';

import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import FeedFilterIcon from 'assets/svgs/FeedFilterIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import EnvelopAddIcon from 'assets/svgs/EnvelopAddIcon';
import { MobileFeedSideBar } from './components/SideBar';
import { MobileFeedFullViewModal } from 'components/Modals/Mobile/FeedFullViewModal';
import { MobileUserBotModal } from 'components/Modals/Mobile/UserBotModal';
import { MobileShareArticleModal } from 'components/Modals/MobileShare/ShareArticle';

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

export const MobileFeedHome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setPlayingAudio } = useAudioContext();
  const queryParams = new URLSearchParams(location.search);

  const filterParams = queryParams.get('filters');
  const showFilterDialogParam = queryParams.get('showDialog');

  const feedRef = useRef<Slider>(null);
  const startTimeRef = useRef(Date.now());
  const [currentIndex, setCurrentIndex] = useState(0);

  const [arrFeed, setArrFeed] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isInit, setIsInit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [showFilter, setShowFilter] = useState(false);
  const [showAddQuestion, setShowAddQuestion] = useState(false);
  const [showAddContentModal, setShowAddContentModal] = useState(false);
  const [showPreviewModal, setShowPreivewModal] = useState(false);
  const [showBotModal, setShowBotModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const [feedTopics, setFeedTopics] = useState<Array<any>>([]);
  const [feedItemTopics, setFeedItemTopics] = useState<Array<any>>([]);
  const [selectedFilter, setSelectedFilter] = useState<Array<any>>([
    { type: 'type', id: 0 }
  ]);

  const [showShareArticleModal, setShowShareArticleModal] = useState(false);

  useEffect(() => {
    sendAnalytics('Feed Mobile');
  }, []);

  useEffect(() => {
    initFeedScroll();
  }, []);

  useEffect(() => {
    initFilters(filterParams);
  }, [filterParams]);

  useEffect(() => {
    if (showFilterDialogParam) {
      setShowFilter(true);
    }
  }, [showFilterDialogParam]);

  useEffect(() => {
    if (feedRef.current) {
      feedRef.current.slickGoTo(currentIndex, false);

      if (currentIndex % 10 === 7) {
        loadData(false, selectedFilter);
      }
    }
  }, [currentIndex]);

  const initFilters = (params: String | null) => {
    if (params) {
      const tmpFilters = getArrFromString(params, ',');

      const realFilters = tmpFilters.map((item, index) => {
        const returnFilters = {};
        const subFilters = getArrFromString(item, ' ');
        const baseFilters = subFilters.map((subItem: any, index) => {
          const key = subItem.split(':')[0];
          const value = subItem.split(':')[1];

          return {
            [key]: value
          };
        });

        baseFilters.forEach((item, index) => {
          Object.assign(returnFilters, item);
        });

        return returnFilters;
      });

      const newFilters = realFilters.map((item: any, index) => {
        return { ...item, id: parseInt(item.id) };
      });
      setSelectedFilter(newFilters);
      loadData(true, newFilters);
    } else {
      loadData(true, []);
    }
  };

  const loadData = async (init = true, filters: Array<any>) => {
    const arrFilter = filters
      .map((item, index) => {
        if (item.type === 'type') {
          if (parseInt(item.id) === 0) {
            return null;
          } else if (parseInt(item.id) === 1) {
            return 'generated_question';
          } else if (parseInt(item.id) === 2) {
            return 'meme';
          } else if (parseInt(item.id) === 3) {
            return 'bookmark';
          } else if (parseInt(item.id) === 4) {
            return 'statement';
          } else if (parseInt(item.id) === 5) {
            return 'article';
          } else if (parseInt(item.id) === 6) {
            return 'file';
          } else if (parseInt(item.id) === 7) {
            return 'media';
          } else if (parseInt(item.id) === 8) {
            return 'poll';
          } else if (parseInt(item.id) === 9) {
            return 'chat';
          }
        }
      })
      .filter((item, index) => item && item);

    const arrTopic = filters
      .map((item, index) => {
        if (item.type === 'topic') {
          return parseInt(item.id);
        }
      })
      .filter((item, index) => item && item);

    const arrItemFilterId = filters
      .map((item, index) => {
        if (
          (item.type === 'collective' || item.type === 'creator') &&
          parseInt(item.id) !== -1
        ) {
          return parseInt(item.id);
        }
      })
      .filter((item, index) => item && item);

    const arrItemFilterType = filters
      .map((item, index) => {
        if (
          (item.type === 'collective' || item.type === 'creator') &&
          parseInt(item.id) !== -1
        ) {
          return item.item_type;
        }
      })
      .filter((item, index) => item && item);

    const arrDumpId = filters
      .map((item, index) => {
        if (item.type === 'doc' && parseInt(item.id) !== -1) {
          return parseInt(item.id);
        }
      })
      .filter((item, index) => item && item);

    if (init) {
      // init audio player
      setPlayingAudio(null);
      setIsLoading(true);
      initFeedView();

      const res = await apiGetSuggestFeed({
        limit: 10,
        offset: 0,
        item_ids: arrItemFilterId.length > 0 ? arrItemFilterId + '' : null,
        item_types:
          arrItemFilterType.length > 0 ? arrItemFilterType + '' : null,
        types:
          arrFilter && arrFilter.length > 0 && arrFilter[0]
            ? arrFilter + ''
            : null,
        topics: arrTopic && arrTopic.length > 0 ? arrTopic + '' : null,
        dumps: arrDumpId.length > 0 ? arrDumpId + '' : null
      });

      if (res.sucess) {
        setIsInit(false);
        setArrFeed(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);

        if (res.data.length > 0) {
          let feedTopicsArray: Array<any> = [];
          const feedItem = res.data[0];
          const arrTopicId = getArrFromString(feedItem.topic_ids, ';');
          const arrTopicName = getArrFromString(feedItem.topics, ';');
          const arrTopicEmoji = getArrFromString(feedItem.topic_emojis, ';');

          if (arrTopicId.length > 0) {
            feedTopicsArray = arrTopicId.map((item, index) => {
              return {
                id: item,
                name: arrTopicName[index],
                emjoi: arrTopicEmoji[index]
              };
            });
          }

          setFeedItemTopics(feedTopicsArray);
        } else {
          setFeedItemTopics([]);
        }
      }
      setIsLoading(false);
    } else {
      if (!isLoading && hasMore) {
        setIsLoading(true);

        const res = await apiGetSuggestFeed({
          limit: 10,
          offset: lastId,
          item_ids: arrItemFilterId.length > 0 ? arrItemFilterId + '' : null,
          item_types:
            arrItemFilterType.length > 0 ? arrItemFilterType + '' : null,
          types:
            arrFilter && arrFilter.length > 0 && arrFilter[0]
              ? arrFilter + ''
              : null,
          topics: arrTopic && arrTopic.length > 0 ? arrTopic + '' : null,
          dumps: arrDumpId.length > 0 ? arrDumpId + '' : null
        });

        if (res.sucess) {
          setArrFeed((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoading(false);
      }
    }
  };

  const initFeedView = () => {
    if (feedRef.current) {
      feedRef.current.slickGoTo(0);
    }
  };

  const onInvite = () => {
    navigate('/invite');
  };

  const onViewLinkPreview = () => {
    setShowPreivewModal(true);
  };

  const onUpdateLike = (feedItem, likeCount, isUserLike) => {
    setArrFeed((prev) =>
      prev.map((item, index) => {
        if (item.item_id === feedItem.item_id) {
          return {
            ...item,
            likes: likeCount,
            user_like: isUserLike
          };
        } else {
          return item;
        }
      })
    );
  };

  const onUpdateFeedTopic = (feedIndex) => {
    if (arrFeed.length > 0) {
      let feedTopicsArray: Array<any> = [];
      const feedItem = arrFeed[feedIndex];
      const arrTopicId = getArrFromString(feedItem.topic_ids, ';');
      const arrTopicName = getArrFromString(feedItem.topics, ';');
      const arrTopicEmoji = getArrFromString(feedItem.topic_emojis, ';');

      if (arrTopicId.length > 0) {
        feedTopicsArray = arrTopicId.map((item, index) => {
          return {
            id: item,
            name: arrTopicName[index],
            emjoi: arrTopicEmoji[index]
          };
        });
      }

      setFeedItemTopics(feedTopicsArray);
    }
  };

  const onHandleSlideChange = (feedIndex) => {
    const endTime = Date.now();
    const timeSpent = endTime - startTimeRef.current;

    if (arrFeed[currentIndex]) {
      emitFeedFeedback(arrFeed[currentIndex].id, timeSpent);
    }

    setCurrentIndex(feedIndex);
    startTimeRef.current = endTime;
  };

  const emitFeedFeedback = (feedId, timeSpent) => {
    socket.emit('feed-feedback', {
      feed: feedId,
      time: (timeSpent / 1000).toFixed(2)
    });
  };

  const onSelectedAddContent = (option) => {
    if (option === 'question') {
      navigate('/question/create');
    } else if (option === 'media') {
      navigate('/media/create');
    } else if (option === 'poll') {
      navigate('/poll/create');
    }
  };

  const onChangedFilter = (newItems) => {
    let queryString = newItems.map((item, index) => {
      let subString = Object.keys(item)
        .map((key) => key + ':' + item[key])
        .join('%20');

      return subString;
    });

    setShowFilter(false);
    navigate(`/?filters=${queryString + ''}`);
  };

  const initFeedScroll = () => {
    document
      .getElementById('mobile_body')
      ?.setAttribute('class', 'mobile_body normal_mobile_body');
    document
      .getElementById('mobile_tab')
      ?.setAttribute('class', 'mobile_tab active_nav');
  };

  const filterCounts = useMemo(() => {
    return selectedFilter.filter(
      (item, index) => !(parseInt(item.id) === -1 || parseInt(item.id) === 0)
    ).length;
  }, [selectedFilter]);

  const renderHeader = () => {
    return (
      <div className="feed_header row_align_items">
        <div
          className="action_item"
          style={filterCounts > 0 ? { border: '1px solid #FF3E9A' } : {}}
          onClick={() => {
            setShowFilter(true);
          }}
        >
          <FeedFilterIcon size={24} />
          {renderFilterCount()}
        </div>
        <div className="row_align_items">
          <div className="action_item" onClick={onInvite}>
            <EnvelopAddIcon size={24} />
          </div>
        </div>
        <MobileFeedTopics
          data={feedItemTopics}
          selectedFilter={selectedFilter}
          onUpdateFilter={(newFilters) => {
            onChangedFilter(newFilters);
          }}
        />
      </div>
    );
  };

  const renderFilterCount = () => {
    if (filterCounts > 0) {
      return (
        <div className="filter_count">
          <div className="font-bold font10" style={{ color: 'white' }}>
            {filterCounts}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  const renderRightAction = () => {
    if (arrFeed[currentIndex]) {
      return (
        <MobileFeedSideBar
          data={arrFeed[currentIndex]}
          onViewPreview={onViewLinkPreview}
          onUpdateLike={onUpdateLike}
        />
      );
    } else {
      return <></>;
    }
  };

  const renderBody = () => {
    return (
      <div className="feed_body">
        <Slider
          ref={feedRef}
          {...settings}
          afterChange={(index) => {
            initFeedScroll();
            onUpdateFeedTopic(index);
            onHandleSlideChange(index);
          }}
        >
          {arrFeed.map((item, index) => {
            return (
              <div
                key={`mobile_card_feed_${item.id}`}
                className="feed_card_body"
              >
                {getCard(item)}
              </div>
            );
          })}
        </Slider>
        {renderRightAction()}
      </div>
    );
  };

  const renderLoadingBar = () => {
    return (
      <div className="loading_bar_container">
        <LoadingBarAnimation />
      </div>
    );
  };

  const getCard = (data) => {
    if (data.item_type === 'statement') {
      return <MobileCardDidKnow data={data} />;
    } else if (data.item_type === 'meme') {
      return <MobileCardMeme data={data} />;
    } else if (data.item_type === 'generated_question') {
      return <MobileCardFlipQuestion data={data} />;
    } else if (
      data.item_type === 'article' ||
      data.item_type === 'chat_article'
    ) {
      return (
        <MobileFeedCardArticle
          data={data}
          detectScroll={true}
          onViewProfile={(userId) => {
            setSelectedUser(userId);
            setShowBotModal(true);
          }}
        />
      );
    } else if (data.item_type === 'file') {
      return <MobileFeedCardFile data={data} />;
    } else if (data.item_type === 'media') {
      return <MobileCardMedia data={data} />;
    } else if (data.item_type === 'poll') {
      return (
        <MobileCardPoll
          data={data}
          onViewBotProfile={(userId) => {
            setSelectedUser(userId);
            setShowBotModal(true);
          }}
        />
      );
    } else if (data.item_type === 'chat') {
      return (
        <MobileFeedCardChat
          data={data}
          detectScroll={true}
          onViewBotProfile={(userId) => {
            setSelectedUser(userId);
            setShowBotModal(true);
          }}
        />
      );
    } else if (data.item_type === 'bookmark') {
      return (
        <MobileFeedCardBookMark
          data={data}
          detectScroll={true}
          onViewBotProfile={(userId) => {
            setSelectedUser(userId);
            setShowBotModal(true);
          }}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const renderPlusBtn = () => {
    return (
      <div
        className="plus_btn"
        onClick={() => {
          setShowAddContentModal(true);
        }}
      >
        <PlusIcon color={'white'} size={32} />
      </div>
    );
  };

  const renderFilterModal = () => {
    return (
      <MobileFeedFilterModal
        open={showFilter}
        filters={selectedFilter}
        onClose={() => {
          setShowFilter(false);
        }}
        onDone={(items) => {
          onChangedFilter(items);
        }}
      />
    );
  };

  const renderAddContentModal = () => {
    return (
      <MobileContentAddModal
        show={showAddContentModal}
        onClose={() => setShowAddContentModal(false)}
        onSelected={(selected) => {
          setShowAddContentModal(false);
          onSelectedAddContent(selected);
        }}
      />
    );
  };

  const renderFullViewModal = () => {
    if (arrFeed[currentIndex]) {
      return (
        <MobileFeedFullViewModal
          data={arrFeed[currentIndex]}
          open={showPreviewModal}
          currentIndex={currentIndex}
          totalCount={arrFeed.length}
          onClose={() => {
            setShowPreivewModal(false);
          }}
          onUpdateLike={onUpdateLike}
          onGoTo={setCurrentIndex}
          onShare={() => {
            setShowShareArticleModal(true);
          }}
        />
      );
    } else {
      return <></>;
    }
  };

  const renderBotModal = () => {
    return (
      <MobileUserBotModal
        open={showBotModal}
        userId={selectedUser}
        onClose={() => {
          setSelectedUser(null);
          setShowBotModal(false);
        }}
        onViewOther={(otherId) => {
          setSelectedUser(otherId);
        }}
      />
    );
  };

  const renderShareModal = () => {
    return (
      <MobileShareArticleModal
        data={arrFeed[currentIndex]}
        show={showShareArticleModal}
        onConnectSocial={() => {}}
        onClose={() => {
          setShowShareArticleModal(false);
        }}
      />
    );
  };

  return (
    <div className="mobile_feed_home">
      {renderHeader()}
      {isInit ? renderLoadingBar() : renderBody()}
      {!showAddQuestion && renderPlusBtn()}
      {renderFilterModal()}
      {renderAddContentModal()}
      {renderFullViewModal()}
      {renderShareModal()}
      {selectedUser && renderBotModal()}
    </div>
  );
};
