import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';

import Download1Icon from 'assets/svgs/Download1Icon';
import WhatsappIcon from 'assets/svgs/WhatsappIcon';
import TelegramIcon from 'assets/svgs/TelegramIcon';
import InstagramIcon from 'assets/svgs/InstagramIcon';
import TwitterIcon from 'assets/svgs/TwitterIcon';
import TikTokIcon from 'assets/svgs/TikTokIcon';

import './index.styles.scss';
import { getArrFromString, getImageFromHtml } from 'shared/utils/utils';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { apiQAGetShareLink } from 'shared/api/qa';
import { apiGetTopicPercentage, apiGetUserSharingQR } from 'shared/api/user';
import SnapChatIcon from 'assets/svgs/SnapChatIcon';
import { RootState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import { apiSocialShareTiktok } from 'shared/api/social';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import { useAudioContext } from 'shared/contexts/AudioContext';
import RobotIcon from 'assets/svgs/RobotIcon';

interface ModalProps {
  show: boolean;
  data: any;
  onClose: () => void;
  onConnectSocial?: (socialName) => void;
}

export const MobileShareChatModal = ({
  show,
  data,
  onClose,
  onConnectSocial
}: ModalProps) => {
  const { uploadBase64 } = useIPFS();
  const { socials, userInfo } = useSelector(
    (state: RootState) => state.profile
  );

  const [shareUrl, setShareUrl] = useState('');
  const [qrCodeUrl, setQRCodeUrl] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [percentInfo, setPercentInfo] = useState<any>(null);

  const [isSharingTikTok, setIsSharingTikTok] = useState(false);

  useEffect(() => {
    if (show && data) {
      getSharingQRcode();
      loadPercentInfo();
    }
  }, [show, data]);

  useEffect(() => {
    if (show) {
      (function (d, s, id) {
        var js,
          sjs = d.getElementsByTagName(s)[0];
        if (sjs && sjs.parentNode) {
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src = 'https://sdk.snapkit.com/js/v1/create.js';
          sjs.parentNode.insertBefore(js, sjs);
        } else {
          console.log('--- failed to load snap sdk');
        }
      })(document, 'script', 'snapkit-creative-kit-sdk');

      // window.snapKitInit = function () {
      //   snap.creativekit.initalizeShareButtons(
      //     document.getElementsByClassName("snapchat-share-button")
      //   );
      // };
    }
  }, [show]);

  const isExistedTikTok = useMemo(() => {
    if (
      socials.filter((item, index) => item.type === 'tiktok').length > 0 &&
      data.sharing
    ) {
      return true;
    } else {
      return false;
    }
  }, [socials]);

  const loadPercentInfo = async () => {
    const res = await apiGetTopicPercentage({
      userId: data.creator_id
    });
    setPercentInfo(res.data);
  };

  const getSharingQRcode = async () => {
    const res = await apiGetUserSharingQR();
    if (res.success) {
      setQRCodeUrl(res.qr_url);

      setTimeout(() => {
        initShareUrl();
      }, 1000);
    }
  };

  const initShareUrl = async () => {
    try {
      const base64Image = await getImageFromHtml(
        `mobile_share_body_image_${data!.item_id}_chat`
      );
      const url = await uploadBase64(base64Image, 'image/png', '');

      const res = await apiQAGetShareLink({
        url: url,
        item_id: data!.item_id,
        type: 'chat'
      });

      if (res.success) {
        setShareUrl(res.sharing_url);
      } else {
      }
    } catch (error) {}
  };

  const onShareTiktok = async () => {
    if (isExistedTikTok) {
      if (!isSharingTikTok) {
        setIsSharingTikTok(true);

        const res = await apiSocialShareTiktok({ feed: data.id });

        if (res.success) {
          showToast('success', 'Successfully shared!');
        }

        setIsSharingTikTok(false);
      }
    } else {
      // open auth0 flow
      onConnectSocial && onConnectSocial('tiktok');
    }
  };

  const arrAllMessages = useMemo(() => {
    const arrIds = getArrFromString(data.chat_ai_ids, ';;;');
    const arrUserImages = getArrFromString(data.chat_ai_images, ';;;');
    const arrNames = getArrFromString(data.chat_ai_names, ';;;');
    const arrTmpMessage = getArrFromString(data.chat_messages, ';;;');
    const arrType = getArrFromString(data.chat_ai_types, ';;;');

    return arrIds.map((item, index) => {
      return {
        creator_id: arrIds[index],
        creator_name: arrNames[index],
        creator_image: arrUserImages[index],
        creator_name_color: data.text_color,
        message: arrTmpMessage[index],
        type: arrType[index]
      };
    });
  }, [data]);

  const renderHeader = () => {
    return (
      <div className="share_header row_align_items">
        <div onClick={onClose}>
          <BlackBackIcon />
        </div>
        <div className="header_title font-bold font18">Share to Social</div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="share_body">
        <div
          className="share_main_body"
          id={`mobile_share_body_image_${data!.item_id}_chat`}
        >
          {renderTopView()}
          {renderInfo()}
        </div>
      </div>
    );
  };

  const renderTopView = () => {
    return (
      <div className="row_space_between">
        <div className="row_align_items">
          <div className="align-vertical-center">
            {userInfo?.image && userInfo?.image.startsWith('https') ? (
              <img
                src={userInfo?.image}
                style={{ width: 32, height: 32, borderRadius: 32 }}
              />
            ) : (
              <DefaultAvatarIcon size={32} />
            )}
          </div>
          <div style={{ marginLeft: 6 }}>
            <div className="font-bold font12 text_no_wrap">
              {userInfo?.name}
            </div>
            {percentInfo && renderPercentInfo()}
          </div>
        </div>

        <div className="qr_view">
          <img
            src={qrCodeUrl}
            style={{ width: '100%', height: '100%' }}
            crossOrigin="anonymous"
          />
        </div>
      </div>
    );
  };

  const renderPercentInfo = () => {
    return (
      <div
        className="font-regular font10 text_no_wrap"
        style={{ marginTop: 4 }}
      >
        {`Top ${percentInfo!.percentile}% in ${percentInfo!.topic_emoji} ${
          percentInfo!.topic_name
        }`}
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <div
        className="info_container"
        style={isDownloading ? { overflow: 'hidden' } : { overflowY: 'auto' }}
      >
        <div className="info_body">
          <div
            className="font-bold font22"
            style={{ textAlign: 'center', lineHeight: '30px' }}
          >
            {data.chat_title}
          </div>
          <div
            className="feed_chat_body"
            style={{
              background: `linear-gradient(103.93deg, ${data.color} 0%, ${data.color} 101.36%)`
            }}
          >
            {arrAllMessages.map((item, index) => {
              return (
                <MessageBody
                  index={index}
                  item={item}
                  key={`feed_chat_message_${index}`}
                  onViewProfile={() => {}}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="share_bottom">
        <div className="statement_link_bar_items" onClick={onShareTiktok}>
          <div className="statement_link_bar_items_item Tiktok_itm">
            {isSharingTikTok ? (
              <LoadingCircular size={24} color="white" />
            ) : (
              <TikTokIcon size={32} color="#000000" />
            )}
          </div>
          <div className="font10">TikTok</div>
        </div>
        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <WhatsappShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Whatsapp_itm">
                <WhatsappIcon />
              </div>
              <div className="font10">Whatsapp</div>
            </div>
          </WhatsappShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TelegramShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Telegram_itm ">
                <TelegramIcon />
              </div>
              <div className="font10">Telegram</div>
            </div>
          </TelegramShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TwitterShareButton title={''} url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Twitter_itm">
                <TwitterIcon />
              </div>
              <div className="font10">Twitter</div>
            </div>
          </TwitterShareButton>
        )}
        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TwitterShareButton title={''} url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Snapchat_itm">
                <SnapChatIcon size={32} />
              </div>
              <div className="font10">SnapChat</div>
            </div>
          </TwitterShareButton>
        )}
        {!!data?.sharing && (
          <div className="statement_link_bar_items">
            <div className="statement_link_bar_items_item Tiktok_itm">
              <TikTokIcon color={'#333333'} size={48} />
            </div>
            <div className="font10">TikTok</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <PrimaryModal
      show={show}
      width={'100%'}
      style={{ width: '100%', height: '100%', borderRadius: 0, padding: 0 }}
      onClose={onClose}
    >
      <div className="mobile_share_chat_social_modal">
        {renderHeader()}
        {renderBody()}
        {renderBottom()}
      </div>
    </PrimaryModal>
  );
};

const MessageBody = ({ item, index, onViewProfile }) => {
  const { playingAudio } = useAudioContext();
  const [showWithAnimation, setShowWithAnimation] = useState(
    !!playingAudio && playingAudio === item.audio
  );

  useEffect(() => {
    if (!!playingAudio && playingAudio === item.audio) {
      setShowWithAnimation(true);
    } else {
      setShowWithAnimation(false);
    }
  }, [playingAudio]);

  const renderBotIcon = () => {
    return (
      <div className="bot_icon">
        <RobotIcon size={12} color={'rgba(15, 15, 15, 0.4)'} />
      </div>
    );
  };

  return (
    <div className="feed_chat_message">
      <div
        className="row_align_items"
        onClick={() => {
          onViewProfile(item);
        }}
      >
        <div style={{ position: 'relative' }}>
          {item.creator_image && item.creator_image.startsWith('https://') ? (
            <img src={item.creator_image} className="creator_image" />
          ) : (
            <DefaultAvatarIcon size={40} />
          )}
          {renderBotIcon()}
        </div>

        <div
          className="creator_name font-bold font14"
          style={{ color: item.creator_name_color }}
        >
          {item.creator_name}
        </div>
      </div>
      <div className="message_body">
        <div className="font-regular font16" style={{ lineHeight: '25.2px' }}>
          {item.message}
        </div>
      </div>
    </div>
  );
};
