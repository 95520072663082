import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import ReadCheckIcon from 'assets/svgs/ReadCheckIcon';
import RoundBackIcon from 'assets/svgs/RoundBackIcon';
import React, { useMemo, useRef, useState } from 'react';
import uuid from 'react-uuid';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { apiCreateContentMedia } from 'shared/api/content';
import { getBase64String } from 'shared/utils-IPFS/functions';
import useIPFS from 'shared/utils-IPFS/useIPFS';

import './index.styles.scss';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { MobileCardCreateMedia } from './CardMedia';
import { showToast } from 'shared/ui-kit/PrimaryToast';

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const maxImageCount = 10;
const maxVideoCount = 1;
const maxVideoDuration = 20;

export const MobileCreateMedia = () => {
  const navigate = useNavigate();

  const { userInfo } = useSelector((state: RootState) => state.profile);
  const { uploadBase64 } = useIPFS();

  const hiddenImageFileInput = useRef<any>(null);
  const hiddenVideoFileInput = useRef<any>(null);
  const [selectedCreator, setSelectedCreator] = useState<'owner' | 'anyo'>(
    'owner'
  );

  const [arrMedia, setArrMedia] = useState<Array<any>>([]);
  const [isCreating, setIsCreating] = useState(false);

  const imageCount = useMemo(() => {
    return arrMedia.filter((item, index) => item.data.type.startsWith('image'))
      .length;
  }, [arrMedia]);

  const videoCount = useMemo(() => {
    return arrMedia.filter((item, index) => item.data.type.startsWith('video'))
      .length;
  }, [arrMedia]);

  const arrFilteredMedia = useMemo(() => {
    const arrFilteredImage = arrMedia
      .filter((item, index) => item.data.type.startsWith('image'))
      .slice(0, maxImageCount);
    const arrFilteredVideo = arrMedia
      .filter((item, index) => item.data.type.startsWith('video'))
      .slice(0, maxVideoCount);

    return [...arrFilteredVideo, ...arrFilteredImage];
  }, [arrMedia]);

  const handleClickImage = (event) => {
    if (!isCreating && imageCount <= maxImageCount) {
      hiddenImageFileInput.current.click();
    }
  };

  const handleClickVideo = (event) => {
    if (!isCreating && videoCount < maxVideoCount) {
      hiddenVideoFileInput.current.click();
    }
  };

  const handleChangeImages = async (event) => {
    if (isCreating) return;

    event.preventDefault();
    const files = event.target.files;

    if (files.length) {
      const convertedFiles = Array.from(files).map((item: any, index) => {
        const id = uuid();
        return {
          id: id,
          data: item
        };
      });
      setArrMedia((prev) => [...prev, ...convertedFiles]);
    }
  };

  const handleChangeVideo = async (event) => {
    if (isCreating) return;

    event.preventDefault();
    const files = event.target.files;

    if (files.length) {
      const id = uuid();
      setArrMedia((prev) => [
        ...prev,
        {
          id: id,
          data: files[0]
        }
      ]);
    }
  };

  const onCreate = async () => {
    if (isCreating) return;

    if (arrFilteredMedia.length > 0) {
      setIsCreating(true);

      const createdData = await Promise.all(
        arrFilteredMedia.map(async (item, index) => {
          const type = item.data.type.startsWith('image')
            ? 'image'
            : item.data.type.startsWith('video')
            ? 'video'
            : 'other';
          const base64String = await getBase64String(item.data);
          const url = await uploadBase64(base64String, type, '');
          return { url, type };
        })
      ).then((results) => {
        return results;
      });

      const res = await apiCreateContentMedia({
        item_id: userInfo?.id,
        item_type: 'user',
        urls: createdData.map((item, index) => item.url) + '',
        media_types: createdData.map((item, index) => item.type) + '',
        is_anonymous: selectedCreator === 'anyo' ? true : false
      });

      if (res.success) {
        showToast(
          'success',
          'Success! Your media has been uploaded successfully. Thank you for sharing.'
        );

        navigate(-1);
      }
      setIsCreating(false);
    }
  };

  const renderHeader = () => {
    return (
      <div className="media_header row_align_items">
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <RoundBackIcon size={36} color="#FF3E9A" />
        </div>
        <div className="media_header_title font-bold font16">Upload Media</div>
        <div className="done_btn" onClick={onCreate}>
          {isCreating ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            <ReadCheckIcon color="#FF3E9A" size={14} />
          )}
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="media_body">
        {renderPostAsView()}
        {renderInputView()}
        {renderSelectBtn()}
      </div>
    );
  };

  const renderInputView = () => {
    return (
      <div className="media_input">
        <Slider {...settings}>
          {arrFilteredMedia.map((item, index) => {
            return (
              <MobileCardCreateMedia
                key={`mobile_card_create_media_${index}`}
                index={index}
                data={item.data}
                maxVideoDuration={maxVideoDuration}
                onRemove={(rIndex) => {
                  setArrMedia((prev) =>
                    prev.filter((nItem, nIndex) => {
                      return item.id !== nItem.id;
                    })
                  );
                }}
              />
            );
          })}
        </Slider>
      </div>
    );
  };

  const renderPostAsView = () => {
    return (
      <div className="media_action row_align_items">
        <div className="font-medium font12">Post as</div>
        <div
          className={
            selectedCreator === 'owner'
              ? `selected_creator_image`
              : `creator_image`
          }
          onClick={() => setSelectedCreator('owner')}
        >
          {userInfo && userInfo.image && userInfo.image.startsWith('https') ? (
            <img
              src={userInfo.image}
              style={{
                width: 28,
                height: 28,
                borderRadius: 28,
                objectFit: 'cover'
              }}
            />
          ) : (
            <DefaultAvatarIcon size={32} />
          )}
        </div>
        <div
          className={
            selectedCreator === 'anyo'
              ? `selected_creator_image`
              : `creator_image`
          }
          onClick={() => setSelectedCreator('anyo')}
        >
          <DefaultAvatarIcon size={36} />
        </div>
      </div>
    );
  };

  const renderSelectBtn = () => {
    return (
      <div className="row_align_items row_space_between">
        <div className="media_action" onClick={handleClickImage}>
          <div
            className={`${
              imageCount > maxImageCount - 1 ? 'disabled_btn' : 'create_btn'
            } font12 font-bold`}
            style={{ color: imageCount < 10 ? 'white' : 'rgba(0, 0, 0, 0.4)' }}
          >
            Select Images
          </div>
          <input
            type="file"
            ref={hiddenImageFileInput}
            onChange={handleChangeImages}
            style={{ display: 'none' }}
            accept="image/*"
            multiple
          />
        </div>
        <div className="media_action" onClick={handleClickVideo}>
          <div
            className={`${
              videoCount > maxVideoCount - 1 ? 'disabled_btn' : 'create_btn'
            } font12 font-bold`}
            style={{ color: videoCount < 10 ? 'white' : 'rgba(0, 0, 0, 0.4)' }}
          >
            Select Video
          </div>
          <input
            type="file"
            ref={hiddenVideoFileInput}
            onChange={handleChangeVideo}
            style={{ display: 'none' }}
            accept="video/mp4,video/x-m4v,video/*"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_create_media">
      {renderHeader()}
      {renderBody()}
    </div>
  );
};
