import React from 'react';

function FilterSmileIcon({ isSelected }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={isSelected ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'}
        d="M11.968 11.856a3.133 3.133 0 01-3.933 0 .835.835 0 00-1.067 1.283 4.733 4.733 0 006.067 0 .835.835 0 00-1.067-1.283zM8.751 8.33a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0zm3.75-.834h-.833a.833.833 0 000 1.667h.833a.833.833 0 000-1.667zm-2.5-5.833a8.333 8.333 0 100 16.667 8.333 8.333 0 000-16.667zm0 15a6.666 6.666 0 110-13.332 6.666 6.666 0 010 13.332z"
      ></path>
    </svg>
  );
}

export default FilterSmileIcon;
