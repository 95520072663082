import React, { useState } from 'react';

import IMG_BG_BOT from 'assets/pngs/bg_bot.png';

import './index.styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import { MobileBotDefinePrompt } from './BotDefine';
import { MobileBotAudioOption } from './BotAudioOption';
import { apiChatCreatePersonalAIV2 } from 'shared/api/chat';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import { MobileCompleteBotCreation } from './CompleteBotCreation';
import { MobileBotUploadAudio } from './UploadAudio';
import { MobileBotUploadAudioStatus } from './UploadedStatus';
import { MobileBotCreateReview } from './BotCreateReview';
import { MobileChooseVoice } from './ChooseVoice';

export const MobileCreateBot = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [currentStep, setCurrentStep] = useState<
    | 'define'
    | 'option'
    | 'complete'
    | 'new_voice'
    | 'upload_status'
    | 'review_bot'
    | 'existed_voice'
  >('define');

  const aiName = params.name;
  const aiImage = params.image
    ? 'https://' + params.image + '/' + params['*']
    : userInfo?.image;
  const [isCreatingAI, setIsCreatingAI] = useState(false);
  const [isCompletingAI, setIsCompletingAI] = useState(false);

  const [promptId, setPromptId] = useState<any>(null);
  const [voiceUrl, setVoiceUrl] = useState<Array<any>>([]);

  const onBack = () => {
    if (currentStep === 'existed_voice') {
      setCurrentStep('option');
    } else if (currentStep === 'new_voice') {
      setCurrentStep('option');
    } else if (currentStep === 'complete') {
      setCurrentStep('option');
    } else {
      navigate(-1);
    }
  };

  const onSkipAndCreateAI = async () => {
    if (!isCreatingAI && !isCompletingAI) {
      setIsCreatingAI(true);

      const res = await apiChatCreatePersonalAIV2({
        name: aiName,
        image: aiImage,
        prompt: promptId,
        voice: null
      });

      if (res.success) {
        showToast('success', 'AI creation successful. Congratulations!');
        navigate(-1);
      }

      setIsCreatingAI(false);
    }
  };

  const onCompleteAI = async (voiceId = null) => {
    if (!isCreatingAI && !isCompletingAI) {
      setIsCompletingAI(true);

      const res = await apiChatCreatePersonalAIV2({
        name: aiName,
        image: aiImage,
        prompt: promptId,
        voice: voiceId
      });

      if (res.success) {
        showToast('success', 'AI creation successful. Congratulations!');

        if (voiceId && currentStep === 'upload_status') {
          setCurrentStep('review_bot');
        } else {
          navigate(-1);
        }
      }

      setIsCompletingAI(false);
    }
  };

  const renderBg = () => {
    return (
      <div className="bot_bg_container">
        <img src={IMG_BG_BOT} className="bot_bg_image" />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="bot_create_body">
        {renderHeader()}
        {currentStep === 'define' ? (
          <MobileBotDefinePrompt
            onCreatedPrompt={(promptId) => {
              setCurrentStep('option');
              setPromptId(promptId);
            }}
          />
        ) : currentStep === 'option' ? (
          <MobileBotAudioOption
            isCreatingAI={isCreatingAI}
            onSkipAndCreateAI={onSkipAndCreateAI}
            onSelectedOption={setCurrentStep}
          />
        ) : currentStep === 'complete' ? (
          <MobileCompleteBotCreation
            isCompleting={isCompletingAI}
            isSkipingAndCompleting={isCreatingAI}
            onComplete={onCompleteAI}
            onSkipAndCreateAI={onSkipAndCreateAI}
          />
        ) : currentStep === 'new_voice' ? (
          <MobileBotUploadAudio
            onSuccess={(newLinks) => {
              setVoiceUrl(newLinks);
              setCurrentStep('upload_status');
            }}
            onSkipAndCreateAI={onSkipAndCreateAI}
          />
        ) : currentStep === 'upload_status' ? (
          <MobileBotUploadAudioStatus
            isCreating={isCompletingAI}
            urls={voiceUrl}
            isSkipingAndCompleting={isCreatingAI}
            onSkipAndCreateAI={onSkipAndCreateAI}
            onCreateAIWithVoice={(voiceId) => {
              onCompleteAI(voiceId);
            }}
          />
        ) : currentStep === 'review_bot' ? (
          <MobileBotCreateReview
            aiName={aiName}
            aiImage={aiImage}
            onFinish={() => {
              navigate(-1);
            }}
          />
        ) : currentStep === 'existed_voice' ? (
          <MobileChooseVoice
            isCreating={isCompletingAI}
            isSkipingAndCompleting={isCreatingAI}
            onSkipAndCreateAI={onSkipAndCreateAI}
            onCreateAIWithVoice={(voiceId) => {
              onCompleteAI(voiceId);
            }}
          />
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div
        className="bot_header row_align_items"
        style={currentStep === 'review_bot' ? { marginRight: 0 } : {}}
      >
        {currentStep !== 'review_bot' && (
          <div onClick={onBack}>
            <BlackBackIcon />
          </div>
        )}
        <div className="bot_name font-bold font16">{`${aiName} AI`}</div>
      </div>
    );
  };

  return (
    <div className="mobile_create_bot">
      {renderBg()}
      {renderBody()}
    </div>
  );
};
