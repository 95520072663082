import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiChatCreateVoice } from 'shared/api/chat';
import CustomCircleProgressBar from 'shared/ui-kit/CircleProgressBar';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';

import './index.styles.scss';

interface Props {
  urls: Array<any>;
  isCreating: boolean;
  onCreateAIWithVoice: (voiceId) => void;
}

export const MobileBotUploadAudioStatus = ({
  isCreating,
  urls,
  onCreateAIWithVoice
}: Props) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [percentage, setPercentage] = useState(0);
  const [voice, setVoice] = useState<any>(null);

  useEffect(() => {
    if (urls) {
      createVoice();
    }
  }, [urls]);

  const createVoice = async () => {
    const res = await apiChatCreateVoice({
      urls: urls
    });

    if (res.success) {
      setPercentage(res.percentage ?? 0);
      setVoice(res.voice);
    }
  };

  const progressBarColorOut = '#DB3EFB';
  const progressBarColorIn = '#FF3E9A';

  const renderTop = () => {
    return (
      <div className="top_view_container">
        <div className="round_out">
          <div className="round_out">
            <div className="round_center">
              <CustomCircleProgressBar
                progress={percentage}
                range={{ from: 0, to: 100 }}
                sign={{ value: '%', position: 'end' }}
                text={'Match'}
                showMiniCircle={true}
                showValue={true}
                size={120}
                showSubTrack={false}
                sx={{
                  strokeColor: progressBarColorOut,
                  barWidth: 8,
                  shape: 'full',
                  strokeLinecap: 'round',
                  loadingTime: 1000,
                  miniCircleColor: progressBarColorOut,
                  miniCircleSize: 0,
                  valueAnimation: true,
                  intersectionEnabled: true
                }}
              />
              <div className="in_progress">
                <CustomCircleProgressBar
                  progress={percentage}
                  range={{ from: 0, to: 100 }}
                  sign={{ value: '%', position: 'end' }}
                  text={'Match'}
                  showMiniCircle={true}
                  showValue={true}
                  size={80}
                  showSubTrack={false}
                  sx={{
                    strokeColor: progressBarColorIn,
                    barWidth: 8,
                    shape: 'full',
                    strokeLinecap: 'round',
                    loadingTime: 1000,
                    miniCircleColor: progressBarColorIn,
                    miniCircleSize: 0,
                    valueAnimation: true,
                    intersectionEnabled: true
                  }}
                />
              </div>
              <div className="percent_txt font-bold font16">{`${(
                percentage * 100
              ).toFixed(2)}%`}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMainBody = () => {
    return (
      <div className="creation_body">
        {renderTop()}
        <div className="font-bold font20">Generating AI voice</div>
        <div className="font-regular font12" style={{ textAlign: 'center' }}>
          Choose any audio or video file with audio but make sure only one voice
          is on the file to have a good result.
        </div>
        {renderCreateBtn()}
      </div>
    );
  };

  const renderCreateBtn = () => {
    return (
      <PrimaryButton
        label="Attach Voice"
        isLoading={isCreating}
        onPressed={() => {
          if (!isCreating) {
            onCreateAIWithVoice(voice);
          }
        }}
      />
    );
  };

  return (
    <div className="mobile_bot_upload_audio_status">{renderMainBody()}</div>
  );
};
