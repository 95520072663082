import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { useNavigate } from 'react-router-dom';
import ReactHowler from 'react-howler';

import {
  apiEditProfileImage,
  apiGetUserInfo,
  apiGetUserVoiceCloned,
  apiUserFollow,
  apiUserUnFollow
} from 'shared/api/user';

import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { OtherProfileVibe } from '../ProfileVibe';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import { MobileProfileCommonInterest } from './CommonInterest';

import { MobileUSerQRCodeModal } from 'components/Modals/UserQRCodeModal';
import { MobileRecordUserProfileModal } from 'components/Modals/Mobile/RecordUserProfileModal';

import MicIcon from 'assets/svgs/MicIcon';
import PointIcon from 'assets/svgs/PointIcon';
import QrCodeIcon from 'assets/svgs/QrCodeIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import UserFollowerIcon from 'assets/svgs/UserFollowerIcon';
import UserFollowingIcon from 'assets/svgs/UserFollowingIcon';
import EditPencilColoredIcon from 'assets/svgs/EditPencilColoredIcon';

import './index.styles.scss';
import CustomCircleProgressBar from 'shared/ui-kit/CircleProgressBar';
import { useAudioContext } from 'shared/contexts/AudioContext';
import AudioPauseIcon from 'assets/svgs/AudioPauseIcon';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import { MobileProfileFollow } from 'components/Modals/Mobile/FolllowInfoModal';
interface Props {
  userId: any;
  isLoadedAll: boolean;
  onLoaded: () => void;
}

export const MobileProfileUserInfo = ({
  userId,
  isLoadedAll,
  onLoaded
}: Props) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [userData, setUserData] = useState<any>(null);
  const [isOwner, setIsOwner] = useState(false);

  const { playingAudio, setPlayingAudio } = useAudioContext();
  const [isPlayingProfileAudio, setIsPlayingProfileAudio] = useState(false);
  const [profileAudio, setProfileAudio] = useState<any>(null);
  const [voiceClonePro, setVoiceClonePro] = useState(0);

  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [showRecordModal, setShowRecordModal] = useState(false);

  const [followStatus, setFollowStatus] = useState<
    'false' | 'true' | 'requested'
  >('false');
  const [isFollowing, setIsFollowing] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);

  const [showFollowModal, setShowFollowModal] = useState(false);
  const [selectedFollowViewMode, setSelectedFollowViewMode] = useState<
    'follower' | 'following'
  >('follower');

  useEffect(() => {
    if (userId) {
      setIsOwner(userId === userInfo?.id);
      loadData();
    }
  }, [userId]);

  useEffect(() => {
    if (!!playingAudio && playingAudio === profileAudio) {
      setIsPlayingProfileAudio(true);
    } else {
      setIsPlayingProfileAudio(false);
    }
  }, [playingAudio]);

  const loadData = async () => {
    await loadUserInfo();
    onLoaded();
    await loadVoiceClone();
  };

  const loadUserInfo = async () => {
    const res = await apiGetUserInfo(userId);

    if (res.success) {
      setUserData(res.data);
      setFollowStatus(res.data.isFollowing);
      setFollowerCount(res.data.followers);
      setFollowingCount(res.data.followings);
    }
  };

  const loadVoiceClone = async () => {
    const res = await apiGetUserVoiceCloned({
      userId: userId
    });

    if (res.success) {
      setVoiceClonePro(res.percentage);
      setProfileAudio(res.audio?.audio);
    }
  };

  const onClickCode = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(userInfo?.code ?? '');
    showToast('success', 'Successfully copied!');
  };

  const onRecordUserAudio = () => {
    setShowRecordModal(true);
  };

  const onToggleProfileAudio = () => {
    if (isPlayingProfileAudio) {
      setPlayingAudio(null);
    } else {
      setPlayingAudio(profileAudio);
    }
  };

  const onFollowAction = () => {
    if (followStatus === 'false') {
      onFollow();
    } else {
      onUnFollow();
    }
  };

  const onFollow = async () => {
    if (!isFollowing) {
      setIsFollowing(true);

      const res = await apiUserFollow({ followingId: userId });
      if (res.success) {
        setFollowerCount((prev) => prev + 1);
        setFollowStatus(res.isFollowed);
      }

      setIsFollowing(false);
    }
  };

  const onUnFollow = async () => {
    if (!isFollowing) {
      setIsFollowing(true);

      const res = await apiUserUnFollow({ following: userId });
      if (res.success) {
        setFollowerCount((prev) => prev - 1);
        setFollowStatus('false');
      }

      setIsFollowing(false);
    }
  };

  const onEditProfile = () => {
    navigate('/edit_profile');
  };

  const onViewMyPoint = () => {
    if (isOwner) {
      navigate('/myPoint');
    }
  };

  const progressBarColor = useMemo(() => {
    if (!!profileAudio) {
      return '#D83EFF';
    } else {
      if (voiceClonePro < 0.25) {
        return '#F24F4F';
      } else if (voiceClonePro >= 0.25 && voiceClonePro < 0.5) {
        return '#EC8362';
      } else if (voiceClonePro >= 0.5 && voiceClonePro < 1) {
        return '#ECAF39';
      } else {
        return '#22D654';
      }
    }
  }, [voiceClonePro, profileAudio]);

  const renderUserImage = () => {
    return (
      <div
        className="user_image_container"
        onClick={() => {
          isOwner && onRecordUserAudio();
        }}
      >
        {isOwner ? (
          <CustomCircleProgressBar
            progress={!!profileAudio ? 100 : voiceClonePro * 100}
            range={{ from: 0, to: 100 }}
            sign={{ value: '%', position: 'end' }}
            text={'Match'}
            showMiniCircle={true}
            showValue={true}
            size={80}
            sx={{
              strokeColor: progressBarColor,
              barWidth: 5,
              shape: 'full',
              strokeLinecap: 'round',
              loadingTime: 1000,
              miniCircleColor: progressBarColor,
              miniCircleSize: 7,
              valueAnimation: true,
              intersectionEnabled: true
            }}
          />
        ) : !!profileAudio ? (
          <CustomCircleProgressBar
            progress={100}
            range={{ from: 0, to: 100 }}
            sign={{ value: '%', position: 'end' }}
            text={'Match'}
            showMiniCircle={true}
            showValue={true}
            size={80}
            sx={{
              strokeColor: '#D83EFF',
              barWidth: 5,
              shape: 'full',
              strokeLinecap: 'round',
              loadingTime: 1000,
              miniCircleColor: '#D83EFF',
              miniCircleSize: 7,
              valueAnimation: true,
              intersectionEnabled: true
            }}
          />
        ) : (
          <></>
        )}
        <div className="user_image_content">
          <div className="user_image_view">
            {userData !== undefined && userData?.image !== '' ? (
              <img
                src={userData?.image}
                style={{
                  width: 64,
                  height: 64,
                  borderRadius: 64,
                  objectFit: 'cover'
                }}
              />
            ) : (
              <DefaultAvatarIcon size={64} />
            )}
            {!!profileAudio ? (
              <div
                className="camera_icon_tag clickable"
                onClick={(e) => {
                  e.stopPropagation();
                  onToggleProfileAudio();
                }}
              >
                {isPlayingProfileAudio ? (
                  <AudioPauseIcon color={'white'} size={16} opacity={1} />
                ) : (
                  <AudioPlayIcon color={'white'} size={16} opacity={1} />
                )}
                {isPlayingProfileAudio && (
                  <ReactHowler
                    src={profileAudio}
                    playing={isPlayingProfileAudio}
                    loop={false}
                    html5={true} // Use HTML5 Audio API
                    onLoad={() => {}}
                    onEnd={() => {
                      setPlayingAudio(null);
                    }}
                  />
                )}
              </div>
            ) : isOwner ? (
              <div
                className="camera_icon_tag clickable"
                onClick={(e) => {
                  e.stopPropagation();
                  onRecordUserAudio();
                }}
              >
                {isUploadingFile ? (
                  <LoadingCircular color="main" size={40} />
                ) : (
                  <MicIcon size={16} />
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderUserDetail = () => {
    return (
      <div className="user_info_detail">
        {renderUserTopDetail()}
        {renderUserBottomDetail()}
      </div>
    );
  };

  const renderUserTopDetail = () => {
    return (
      <div className="user_info_detail_top row_align_items row_space_between">
        <div className="user_name font-bold font22">{userData?.name}</div>
        <div className="row_align_items" style={{ gap: 4 }}>
          <div
            className="qr_code_view"
            onClick={() => {
              isOwner && setShowQRCode(true);
            }}
          >
            <QrCodeIcon color="rgba(0, 0, 0, 0.4)" size={20} />
            <div className="font-medium font12" style={{ opacity: 0.6 }}>
              {userData.code}
            </div>
          </div>
          {isOwner && (
            <div className="edit_btn" onClick={onEditProfile}>
              <EditPencilColoredIcon size={16} />
            </div>
          )}
          {!isOwner && renderFollowBtn()}
        </div>
      </div>
    );
  };

  const renderUserBottomDetail = () => {
    return (
      <div className="user_info_follow row_align_items">
        <div
          className="follow_item"
          onClick={() => {
            setSelectedFollowViewMode('follower');
            setShowFollowModal(true);
          }}
        >
          <div className="follow_top">
            <UserFollowerIcon color="rgba(0, 0, 0, 0.4)" size={16} />
            <div className="follow_value font-medium font12">
              {followerCount}
            </div>
          </div>
          <div className="follow_txt font-medium font10">followers</div>
        </div>
        <div
          className="follow_item"
          onClick={() => {
            setSelectedFollowViewMode('following');
            setShowFollowModal(true);
          }}
        >
          <div className="follow_top">
            <UserFollowingIcon
              color="rgba(0, 0, 0, 0.4)"
              opacity={1}
              size={16}
            />
            <div className="follow_value font-medium font12">
              {followingCount}
            </div>
          </div>
          <div className="follow_txt font-medium font10">following</div>
        </div>
        <div className="follow_item" onClick={onViewMyPoint}>
          <div className="follow_top">
            <PointIcon color="rgba(0, 0, 0, 0.4)" opacity={1} size={16} />
            <div className="follow_value font-medium font12">
              {`${userData?.energy.toFixed(1)}`}
            </div>
          </div>
          <div className="follow_txt font-medium font10">points</div>
        </div>
      </div>
    );
  };

  const renderDescription = () => {
    if (userData?.description) {
      return (
        <div className="description font-regular font14">
          {userData?.description}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderCommonIntereste = () => {
    return <MobileProfileCommonInterest userId={userId} isOwner={isOwner} />;
  };

  const renderFollowBtn = () => {
    if (followStatus === 'false') {
      return (
        <div
          className="follow_btn font-bold font14 clickable"
          onClick={onFollowAction}
        >
          {isFollowing ? <LoadingCircular size={12} color="white" /> : `Follow`}
        </div>
      );
    } else if (followStatus === 'true') {
      return (
        <div
          className="unfollow_btn font-bold font14 clickable"
          onClick={onFollowAction}
        >
          {isFollowing ? (
            <LoadingCircular size={12} color="main" />
          ) : (
            `Unfollow`
          )}
        </div>
      );
    } else {
      return (
        <div
          className="requested_btn font-bold font14 clickable"
          onClick={onFollowAction}
        >
          {isFollowing ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            `Requested`
          )}
        </div>
      );
    }
  };

  const renderUserQRCodeModal = () => {
    return (
      <MobileUSerQRCodeModal
        show={showQRCode}
        onClose={() => setShowQRCode(false)}
      />
    );
  };

  const renderRecordModal = () => {
    return (
      <MobileRecordUserProfileModal
        show={showRecordModal}
        onClose={() => {
          setShowRecordModal(false);
        }}
        onSuccess={() => {
          setShowRecordModal(false);
          loadVoiceClone();
        }}
      />
    );
  };

  const renderFollowerModal = () => {
    return (
      <MobileProfileFollow
        open={showFollowModal}
        userId={userData?.id}
        name={userData?.name}
        follower={followerCount}
        following={followingCount}
        defaultTab={selectedFollowViewMode}
        onClose={() => {
          setShowFollowModal(false);
        }}
        onUpdatedFollowingCount={setFollowingCount}
      />
    );
  };

  if (isLoadedAll && userData) {
    return (
      <div className="mobile_user_info">
        <div className="user_info row_align_items">
          {renderUserImage()}
          {renderUserDetail()}
        </div>
        {renderDescription()}
        {renderCommonIntereste()}
        {renderUserQRCodeModal()}
        {renderRecordModal()}
        {renderFollowerModal()}
      </div>
    );
  } else {
    return <></>;
  }
};
