import HistoryIcon from 'assets/svgs/HistoryIcon';
import SearchIcon from 'assets/svgs/SearchIcon';
import React, { useEffect, useState } from 'react';
import { apiChatRooms } from 'shared/api/chat';
import Columns from 'react-columns';
import './index.styles.scss';
import { MobileSelectAIModal } from 'components/Modals/MobileSelectAIModal';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { useNavigate } from 'react-router-dom';
import { MobileTopMyPersonalAI } from './PersonalAI';

import BG_EMPTY_CREATOR from 'assets/pngs/bg_empty_chat_creator.png';
import { apiGetSuggestAIs, apiGetSuggestPersonas } from 'shared/api/suggest';
import { MobileCardTopCreator } from './CardTopCreator';
import { sendAnalytics } from 'shared/utils/analytics';
import { MobileCloneTopFilterModal } from 'components/Modals/Mobile/CloneTopFilterModal';
import { MobileStartNewChat } from './StartNewChat';

export const MobileChat = () => {
  const navigate = useNavigate();

  const [topTab, setTopTab] = useState<'discover' | 'friends' | 'followed'>(
    'discover'
  );

  const [arrRoom, setArrRoom] = useState<Array<any>>([]);
  const [lastIdRoom, setLastIdRoom] = useState(0);
  const [hasMoreRoom, setHasMoreRoom] = useState(true);
  const [isLoadingRoom, setIsLoadingRoom] = useState(false);

  const [isLoadingMoreAIs, setIsLoadingMoreAIs] = useState(false);
  const [isLoadedAIs, setIsLoadedAIs] = useState(false);

  const [arrAiDiscover, setArrAiDiscover] = useState<Array<any>>([]);
  const [lastIdDiscover, setLastIdDiscover] = useState(0);
  const [hasMoreDiscover, setHasMoreDiscover] = useState(true);
  const [isLoadingDiscover, setIsLoadingDiscover] = useState(false);

  const [arrAiFriend, setArrAiFriend] = useState<Array<any>>([]);
  const [lastIdFriend, setLastIdFriend] = useState(0);
  const [hasMoreFriend, setHasMoreFriend] = useState(true);
  const [isLoadingFriend, setIsLoadingFriend] = useState(false);

  const [arrAiFollowed, setArrAiFollowed] = useState<Array<any>>([]);
  const [lastIdFollowed, setLastIdFollowed] = useState(0);
  const [hasMoreFollowed, setHasMoreFollowed] = useState(true);
  const [isLoadingFollowed, setIsLoadingFollowed] = useState(false);

  const [showAiSelectModal, setShowAiSelectModal] = useState(false);
  const [showTopFilterModal, setShowTopFilterModal] = useState(false);
  const [topFilterModalPos, setTopFilterModalPos] = useState({
    top: 0,
    left: 0
  });
  const [isPersonalAI, setIsPersonalAI] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    sendAnalytics('Chats Mobile');
  }, []);

  useEffect(() => {
    loadRooms();
  }, []);

  useEffect(() => {
    loadDiscoverData();
    loadFollowedData();
    loadFriendData();
  }, []);

  const loadRooms = async (init = true) => {
    if (init && !isLoadingMoreAIs) {
      setIsLoadingRoom(true);
      setIsLoadingMoreAIs(true);

      const res = await apiChatRooms({
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrRoom(res.data.filter((item, index) => item.is_voted !== 1));
        setLastIdRoom(res.lastId);
        setHasMoreRoom(res.hasMore);
      }

      setIsLoadingRoom(false);
      setIsLoadingMoreAIs(false);
    } else {
      if (!isLoadingRoom && hasMoreRoom) {
        setIsLoadingRoom(true);

        const res = await apiChatRooms({
          limit: 10,
          offset: lastIdRoom
        });

        if (res.success) {
          setArrRoom((prev) => [...prev, ...res.data]);
          setLastIdRoom(res.lastId);
          setHasMoreRoom(res.hasMore);
        }

        setIsLoadingRoom(false);
      }
    }
  };

  const loadDiscoverData = async (init = true, query = '') => {
    if (init) {
      setIsLoadingDiscover(true);
      const res = await apiGetSuggestAIs({
        query: query,
        type: null,
        limit: 10,
        offset: 0
      });
      if (res.success) {
        setArrAiDiscover(res.data);
        setLastIdDiscover(res.lastId);
        setHasMoreDiscover(res.hasMore);
        setIsLoadedAIs(true);
      }
      setIsLoadingDiscover(false);
    } else {
      if (!isLoadingMoreAIs && hasMoreDiscover) {
        setIsLoadingMoreAIs(true);

        const res = await apiGetSuggestAIs({
          query: query,
          type: null,
          limit: 10,
          offset: lastIdDiscover
        });

        if (res.success) {
          setArrAiDiscover((prev) => [...prev, ...res.data]);
          setLastIdDiscover(res.lastId);
          setHasMoreDiscover(res.hasMore);
        }

        setIsLoadingMoreAIs(false);
      }
    }
  };

  const loadFriendData = async (init = true, query = '') => {
    if (init) {
      setIsLoadingFriend(true);

      const res = await apiGetSuggestAIs({
        query: query,
        type: 'friends',
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrAiFriend(res.data);
        setLastIdFriend(res.lastId);
        setHasMoreFriend(res.hasMore);
      }

      setIsLoadingFriend(false);
    } else {
      if (!isLoadingMoreAIs && hasMoreFriend) {
        setIsLoadingMoreAIs(true);

        const res = await apiGetSuggestAIs({
          query: query,
          type: 'friends',
          limit: 10,
          offset: lastIdFriend
        });

        if (res.success) {
          setArrAiFriend((prev) => [...prev, ...res.data]);
          setLastIdFriend(res.lastId);
          setHasMoreFriend(res.hasMore);
        }

        setIsLoadingMoreAIs(false);
      }
    }
  };

  const loadFollowedData = async (init = true, query = '') => {
    if (init) {
      setIsLoadingFollowed(true);

      const res = await apiGetSuggestAIs({
        query: query,
        type: 'followed',
        limit: 10,
        offset: 0
      });
      if (res.success) {
        setArrAiFollowed(res.data);
        setLastIdFollowed(res.lastId);
        setHasMoreFollowed(res.hasMore);
      }

      setIsLoadingFollowed(false);
    } else {
      if (!isLoadingMoreAIs && hasMoreFollowed) {
        setIsLoadingMoreAIs(true);

        const res = await apiGetSuggestAIs({
          query: query,
          type: 'followed',
          limit: 10,
          offset: lastIdFollowed
        });

        if (res.success) {
          setArrAiFollowed((prev) => [...prev, ...res.data]);
          setLastIdFollowed(res.lastId);
          setHasMoreFollowed(res.hasMore);
        }

        setIsLoadingMoreAIs(false);
      }
    }
  };

  const onSelectAI = (aiType, aiId) => {
    navigate(`/conversation/${aiType}/${aiId}`);
  };

  const onViewAllStartNew = () => {
    navigate('/conversation/history');
  };

  const handleScroll = (e) => {
    if (
      !isLoadingDiscover &&
      !isLoadingFollowed &&
      !isLoadingFriend &&
      !isLoadingMoreAIs
    ) {
      // hide nav bar
      const scrollTop = e.target.scrollTop;
      setLastScrollTop(scrollTop);

      if (scrollTop < lastScrollTop) {
        document
          .getElementById('mobile_body')
          ?.setAttribute('class', 'mobile_body normal_mobile_body');
        document
          .getElementById('mobile_tab')
          ?.setAttribute('class', 'mobile_tab active_nav');
      } else {
        document
          .getElementById('mobile_body')
          ?.setAttribute('class', 'mobile_body expanded_mobile_body');
        document
          .getElementById('mobile_tab')
          ?.setAttribute('class', 'mobile_tab hidden_nav');
      }

      // load more questions
      const bottom =
        e.target.scrollHeight - e.target.scrollTop <=
        e.target.clientHeight + 0.5;

      if (bottom) {
        if (topTab === 'discover') {
          loadDiscoverData(false);
        } else if (topTab === 'followed') {
          loadFollowedData(false);
        } else {
          loadFriendData(false);
        }
      }
    }
  };

  const renderPersonalAI = () => {
    return (
      <div style={{ width: '100%' }}>
        <MobileTopMyPersonalAI />
      </div>
    );
  };

  const renderStartNewChat = () => {
    return <MobileStartNewChat onViewAll={onViewAllStartNew} />;
  };

  const renderTopCreators = () => {
    return (
      <div className="top_creators">
        <div className="top_creator_header">
          <div className="row_align_items">
            <div className="font-bold font18 ">Find Other Top</div>
            <div
              className="top_creator_filter row_align_items"
              id="mobile_clone_top_file_btn"
              // onClick={onShowTopFilterModal}
            >
              <div className="font-bold font18" style={{ color: '#FF3E9A' }}>
                {isPersonalAI ? 'Personal AIs' : 'Personas'}
              </div>
            </div>
          </div>

          <div
            onClick={(keyword) => {
              navigate('/search/creator');
            }}
          >
            <SearchIcon size={24} />
          </div>
        </div>
        {renderTopCreatorsTab()}
        <div className="top_creators_body">
          {isLoadingDiscover ? (
            <LoadingBarAnimation />
          ) : (
            (topTab === 'discover'
              ? arrAiDiscover
              : topTab === 'followed'
              ? arrAiFollowed
              : arrAiFriend
            ).map((item, index) => {
              return (
                <MobileCardTopCreator
                  index={index}
                  item={item}
                  isEnableFollow={true}
                  key={`mobile_card_top_creator_${
                    topTab === 'discover'
                      ? 'discover'
                      : topTab === 'followed'
                      ? 'followed'
                      : 'friend'
                  }_${index}`}
                  onSelectAI={onSelectAI}
                />
              );
            })
          )}
          {(topTab === 'discover'
            ? arrAiDiscover
            : topTab === 'followed'
            ? arrAiFollowed
            : arrAiFriend
          ).length == 0 &&
            isLoadedAIs &&
            renderEmptyCreators()}
        </div>
      </div>
    );
  };

  const renderTopCreatorsTab = () => {
    return (
      <div className="top_creator_tab row_align_items">
        <div
          className={
            topTab === 'discover'
              ? 'selected_btn' + ' font-bold font12'
              : 'default_btn' + ' font-bold font12'
          }
          onClick={() => setTopTab('discover')}
        >
          Discover
        </div>
        <div
          className={
            topTab === 'friends'
              ? 'selected_btn' + ' font-bold font12'
              : 'default_btn' + ' font-bold font12'
          }
          onClick={() => setTopTab('friends')}
        >
          Followed By Friends
        </div>
        <div
          className={
            topTab === 'followed'
              ? 'selected_btn' + ' font-bold font12'
              : 'default_btn' + ' font-bold font12'
          }
          onClick={() => setTopTab('followed')}
        >
          Followed
        </div>
      </div>
    );
  };

  const renderEmptyCreators = () => {
    return (
      <div className="empty_creator">
        <img
          src={BG_EMPTY_CREATOR}
          style={{ width: 300, height: 230, marginBottom: 30 }}
        />
        <div className="font-bold font16">
          {!isPersonalAI ? 'No personas were found' : 'No other creators yet'}
        </div>
        <div
          className="font-regular font12"
          style={{
            color: 'rgba(0, 0, 0, 0.4)',
            textAlign: 'center',
            paddingBottom: 16
          }}
        >
          {!isPersonalAI
            ? 'Please try refining your search'
            : topTab === 'friends'
            ? 'We currently don’t have any personal AIs to suggest from your friends, but we’ll update you as soon as they’re available.'
            : topTab === 'followed'
            ? 'You’re not following any personal AIs yet.\nExplore the Discover tab to find and follow personal AIs that interest you, or create your own AI to start a conversation!'
            : 'You can chat with group’s or org’s AI for now'}
        </div>
      </div>
    );
  };

  const renderTopFilterModal = () => {
    return (
      <MobileCloneTopFilterModal
        show={showTopFilterModal}
        posX={topFilterModalPos.left}
        posY={topFilterModalPos.top}
        onClose={() => {
          setShowTopFilterModal(false);
        }}
        onSelected={(action) => {
          setShowTopFilterModal(false);
          if (action === 'personal') {
            setIsPersonalAI(true);
          } else {
            setIsPersonalAI(false);
          }
        }}
      />
    );
  };

  return (
    <div className="mobile_chat">
      <div className="mobile_chat_main" onScroll={handleScroll}>
        {renderPersonalAI()}
        {renderStartNewChat()}
        {renderTopCreators()}
      </div>
      <MobileSelectAIModal
        show={showAiSelectModal}
        onClose={() => setShowAiSelectModal(false)}
        onSelected={(data) => {
          setShowAiSelectModal(false);
          onSelectAI(data.ai_type, data.ai_id);
        }}
        onFindMore={() => {
          setShowAiSelectModal(false);
        }}
      />
      {renderTopFilterModal()}
    </div>
  );
};
