import React, { useEffect, useState } from 'react';
import { apiGetGroups } from 'shared/api/group';

import './index.styles.scss';
import { JoinedGroup } from './JoinedGroup';
import { GroupMembers } from './Members';

export const CommunityGroup = () => {
  const [selectedGroup, setSelectedGroup] = useState(null);

  const [isLoadedGroup, setIsLoadedGroup] = useState(false);
  const [isLoadedMember, setIsLoadedMember] = useState(false);
  const [isLoadedAll, setIsLoadedAll] = useState(false);

  useEffect(() => {
    setIsLoadedAll(isLoadedGroup && isLoadedMember);
  }, [isLoadedGroup, isLoadedMember]);

  return (
    <div className="mobile_group">
      <JoinedGroup
        isLoadedAll={isLoadedAll}
        selectedGroup={selectedGroup}
        onSelected={setSelectedGroup}
        setIsLoaded={() => {
          setIsLoadedGroup(true);
        }}
      />
      <GroupMembers
        isLoadedAll={isLoadedAll}
        selectedGroup={selectedGroup}
        setIsLoaded={() => {
          setIsLoadedMember(true);
        }}
      />
    </div>
  );
};
