import React from 'react';

function ChatSendIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '32'}
      height={props.size ?? '32'}
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect
        width="32"
        height="32"
        fill={props.bgColor ?? '#FF3E9A'}
        rx="16"
      ></rect>
      <path
        fill={props.color ?? '#fff'}
        d="M12.233 22.453l9.334-4.666a2 2 0 000-3.574l-9.334-4.666a2 2 0 00-2.72 2.6l1.6 3.58a.706.706 0 010 .546l-1.6 3.58a2 2 0 002.72 2.6zM10.9 21.12a.666.666 0 01-.147-.753l1.594-3.58a2 2 0 000-1.627l-1.614-3.553a.667.667 0 01.9-.867l9.334 4.667a.666.666 0 010 1.186l-9.334 4.667a.666.666 0 01-.753-.113l.02-.027z"
        opacity={props.opacity ?? 1}
      ></path>
    </svg>
  );
}

export default ChatSendIcon;
