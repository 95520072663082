import React, { useState } from 'react';

import './index.styles.scss';

import BG_DONE from 'assets/pngs/bg_on_waiting_list.png';
import { useNavigate } from 'react-router-dom';
import { DeviceSize } from 'shared/utils/constants';
import { useMediaQuery } from 'react-responsive';

export const SuccessWaitingList = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  const onBack = async () => {
    navigate('/');
  };

  const renderSuccess = () => {
    return (
      <div className="wait_success">
        <div>
          <div className="align-center">
            <img
              src={'/logo512.png'}
              width={50}
              height={60}
              style={{ objectFit: 'contain' }}
            />
          </div>
        </div>
        <img src={BG_DONE} style={{ width: 220, height: 190 }} />
        <div>
          <div className="wait_txt font-bold font24">
            You are on the waitlist!
          </div>
          <div
            className="wait_txt font-regular font16"
            style={{ color: 'rgba(0, 0, 0, 0.4)', marginTop: 12 }}
          >
            We’ll let you know when your turn comes.
            <br />
            Please wait for our invitation email.
          </div>
        </div>
        <div className="wait_tip">
          <div className="font-regular font14" style={{ textAlign: 'center' }}>
            💡<span className="font-bold font14">Pro tip:</span> You can skip
            the line if you receive an invitation from
            <br />
            users that already joined.{' '}
          </div>
        </div>
        <div
          className="font-bold font14 clickable"
          style={{ color: '#FF3E9A' }}
          onClick={onBack}
        >
          Back to Login
        </div>
      </div>
    );
  };

  return (
    <div
      className="waiting_list"
      style={isMobile ? { width: '100%', borderRadius: 0 } : {}}
    >
      {renderSuccess()}
    </div>
  );
};
