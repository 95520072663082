const actions = {
  ADD_BOOKMARK_DATA: 'ADD_BOOKMARK_DATA',
  REFRESH_BOOKMARK_DATA: 'REFRESH_BOOKMARK_DATA',
  REFRESH_HIGHLIGHT_DATA: 'REFRESH_HIGHLIGHT_DATA',
  REFRESH_EVENTS_DATA: 'REFRESH_EVENTS_DATA',
  REFRESH_MOBILE_SOCIAL_MEDIA: 'REFRESH_MOBILE_SOCIAL_MEDIA',
  REFRESH_ORG_OTHER_ANSWER: 'REFRESH_ORG_OTHER_ANSWER',
  REFRESH_MOBILE_START_NEW: 'REFRESH_MOBILE_START_NEW',

  COMMUNITY_SCROLL: 'COMMUNITY_SCROLL',

  addBookMarkData: (data) => ({
    payload: data,
    type: actions.ADD_BOOKMARK_DATA
  }),
  refreshdBookMarkData: () => ({
    type: actions.REFRESH_BOOKMARK_DATA
  }),
  refreshHighLightData: () => ({
    type: actions.REFRESH_HIGHLIGHT_DATA
  }),
  refreshEventData: () => ({
    type: actions.REFRESH_EVENTS_DATA
  }),
  refreshOrgOtherAnswer: () => ({
    type: actions.REFRESH_ORG_OTHER_ANSWER
  }),
  refreshMobileSocialMedia: () => ({
    type: actions.REFRESH_MOBILE_SOCIAL_MEDIA
  }),
  refreshMobileStartNew: () => ({
    type: actions.REFRESH_MOBILE_START_NEW
  }),
  communityScroll: () => ({
    type: actions.COMMUNITY_SCROLL
  })
};

export default actions;
