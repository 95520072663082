import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiGetFeedCreator } from 'shared/api/suggest';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { MobileCardFeedCreatorFilter } from './CardCreatorFilter';

import './index.styles.scss';

interface Props {
  selectedFilter: Array<any>;
  onSelected: (item) => void;
}

export const MobileFeedCreatorFilter = ({
  selectedFilter,
  onSelected
}: Props) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [searchText, setSearchText] = useState('');

  const [arrTopic, setArrTopic] = useState<Array<any>>([]);
  const [lastIdTopic, setLastIdTopic] = useState(0);
  const [hasMoreTopic, setHasMoreTopic] = useState(true);
  const [isLoadingTopic, setIsLoadingTopic] = useState(false);

  useEffect(() => {
    loadTopicList();
  }, [searchText]);

  const loadTopicList = async (init = true) => {
    if (init) {
      setIsLoadingTopic(true);

      const res = await apiGetFeedCreator({
        query: searchText,
        limit: 20,
        offset: 0
      });
      console.log({
        query: searchText,
        limit: 20,
        offset: 0
      });
      console.log({ res });
      if (res.success) {
        const personalItem = {
          ai_id: userInfo?.id,
          ai_image: userInfo?.image,
          ai_name: 'Personal',
          ai_type: 'user'
        };

        setArrTopic([personalItem, ...res.data]);
        setLastIdTopic(res.lastId);
        setHasMoreTopic(res.hasMore);
      }
      setIsLoadingTopic(false);
    } else {
      if (!isLoadingTopic && hasMoreTopic) {
        setIsLoadingTopic(true);

        const res = await apiGetFeedCreator({
          query: searchText,
          limit: 20,
          offset: lastIdTopic
        });
        if (res.success) {
          setArrTopic((prev) => [...prev, ...res.data]);
          setLastIdTopic(res.lastId);
          setHasMoreTopic(res.hasMore);
        }
        setIsLoadingTopic(false);
      }
    }
  };

  const arrConvertedFilter = useMemo(() => {
    if (!!searchText) {
      return arrTopic.slice(1, arrTopic.length);
    } else {
      return arrTopic;
    }
  }, [arrTopic, searchText]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      loadTopicList(false);
    }
  };

  const renderSearch = () => {
    return (
      <SearchBox
        onSearch={setSearchText}
        height={'40px'}
        enableShadow={false}
        fontSize={14}
      />
    );
  };

  return (
    <div className="mobile_feed_creator_filter">
      {renderSearch()}
      <div className="mobile_feed_creator_filter_body" onScroll={handleScroll}>
        {arrConvertedFilter.map((item, index) => {
          const isSelected =
            selectedFilter.filter(
              (sItem, sIndex) =>
                sItem.type === 'creator' && sItem.id === item.ai_id
            ).length > 0;

          return (
            <MobileCardFeedCreatorFilter
              key={`mobile_card_feed_creator_${index}`}
              data={item}
              isSelected={isSelected}
              onSelected={(item) => onSelected(item)}
            />
          );
        })}
      </div>
    </div>
  );
};
