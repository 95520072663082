import React from 'react';

function FoloderDownloadIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 20}
      height={props.size ?? 20}
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        fill={props.color ?? '#fff'}
        d="M11.075 11.99l-.241.25v-2.158a.833.833 0 00-1.667 0v2.158l-.242-.25a.837.837 0 00-1.183 1.184l1.667 1.666c.079.076.172.136.275.175a.783.783 0 00.633 0c.102-.04.196-.099.275-.175l1.667-1.666a.837.837 0 00-1.184-1.184zm4.759-6.908H10.6l-.266-.833a2.5 2.5 0 00-2.367-1.667h-3.8a2.5 2.5 0 00-2.5 2.5v10.833a2.5 2.5 0 002.5 2.5h11.667a2.5 2.5 0 002.5-2.5V7.582a2.5 2.5 0 00-2.5-2.5zm.833 10.833a.833.833 0 01-.833.834H4.167a.833.833 0 01-.833-.834V5.082a.833.833 0 01.833-.833h3.8a.833.833 0 01.792.566l.45 1.367A.833.833 0 0010 6.75h5.834a.833.833 0 01.833.833v8.333z"
      ></path>
    </svg>
  );
}

export default FoloderDownloadIcon;
