import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React from 'react';

interface Props {
  data: Array<any>;
  onViewProfile: (id) => void;
}

export const SubUsers = ({ data, onViewProfile }: Props) => {
  if (data.length > 0) {
    return (
      <div className="sub_users">
        {data.map((item, index) => {
          return (
            <div
              key={`card_sub_user_${index}`}
              onClick={() => onViewProfile(item.creator_id)}
            >
              {item.creator_image &&
              item.creator_image.startsWith('https://') ? (
                <img src={item.creator_image} className="sub_user_image" />
              ) : (
                <DefaultAvatarIcon size={64} />
              )}
            </div>
          );
        })}
      </div>
    );
  } else {
    return <></>;
  }
};
