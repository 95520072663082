import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';

import './index.styles.scss';
import RobotIcon from 'assets/svgs/RobotIcon';

interface Props {
  onGetStart: () => void;
}

export const MobilePersonalTypeGetStart = ({ onGetStart }: Props) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const renderBotIcon = () => {
    return (
      <div className="bot_icon">
        <RobotIcon size={16} color={'white'} />
      </div>
    );
  };

  const renderGetStartBtn = () => {
    return (
      <div className="get_start_btn" onClick={onGetStart}>
        <div className="font-bold font14" style={{ color: 'white' }}>
          Get Started
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_personal_type_get_start">
      <div style={{ position: 'relative' }}>
        {userInfo?.image && userInfo?.image.startsWith('https') ? (
          <img src={userInfo?.image} className="ai_image" />
        ) : (
          <DefaultAvatarIcon size={50} />
        )}
        {renderBotIcon()}
      </div>
      <div className="ai_name font-bold font18">{'Personal AI'}</div>
      <div className="ai_desc font-bold font12">
        {'Upload, Customize and Converse with Your AI'}
      </div>
      {renderGetStartBtn()}
    </div>
  );
};
