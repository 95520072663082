import React, { useEffect, useMemo, useState } from 'react';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import CloseIcon from 'assets/svgs/CloseIcon';
import LightBulbIcon from 'assets/svgs/LightBulbIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import Download1Icon from 'assets/svgs/Download1Icon';
import WhatsappIcon from 'assets/svgs/WhatsappIcon';
import TelegramIcon from 'assets/svgs/TelegramIcon';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { convertFirstToUpper, getImageFromHtml } from 'shared/utils/utils';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { apiGetTopicPercentage, apiGetUserSharingQR } from 'shared/api/user';

import './index.styles.scss';

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import { apiQAGetShareLink } from 'shared/api/qa';
import { StatementData } from 'components/Main/Organisation/OrgHome/components/TopDidYouKnow/CardDidYouKnow';
import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import CoinIcon from 'assets/svgs/CoinIcont';

interface ModalProps {
  data: any;
  show: boolean;
  onClose: () => void;
}

export const MobileShareDidKnowModal = ({
  data,
  show,
  onClose
}: ModalProps) => {
  const { uploadBase64 } = useIPFS();

  const [shareUrl, setShareUrl] = useState('');
  const [qrCodeUrl, setQRCodeUrl] = useState('');
  const [isLoadingQrcode, setIsLoadingQRcode] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [percentInfo, setPercentInfo] = useState<any>(null);

  useEffect(() => {
    if (show) {
      getSharingQRcode();
      loadPercentInfo();
    } else {
      setShareUrl('');
    }
  }, [data, show]);

  useEffect(() => {
    if (show) {
      document
        .getElementsByClassName('plus_btn')[0]
        .setAttribute('style', 'display: none');
    } else {
      document
        .getElementsByClassName('plus_btn')[0]
        .setAttribute('style', 'display: visible');
    }
  }, [show]);

  const loadPercentInfo = async () => {
    const res = await apiGetTopicPercentage({
      userId: data.creator_id
    });
    setPercentInfo(res.data);
  };

  const initShareUrl = async () => {
    if (data) {
      const base64Image = await getImageFromHtml(
        `statement_share_body_${data.item_id}`
      );
      const url = await uploadBase64(base64Image, 'image/png', '');

      const res = await apiQAGetShareLink({
        url: url,
        item_id: data!.item_id,
        type: 'statement'
      });

      if (res.success) {
        setShareUrl(res.sharing_url);
      }
    }
  };

  const onShare = async (type: 'download') => {
    if (!isDownloading)
      if (type === 'download') {
        setIsDownloading(true);
        let base64Image = await getImageFromHtml(
          `statement_share_body_${data?.item_id}`
        );

        const url = await uploadBase64(base64Image, 'image/png', '');

        await onDownloadFile(url);
        setIsDownloading(true);
      }
  };

  const getSharingQRcode = async () => {
    setIsLoadingQRcode(true);
    const res = await apiGetUserSharingQR();
    if (res.success) {
      setQRCodeUrl(res.qr_url);
      setIsLoadingQRcode(false);

      setTimeout(() => {
        initShareUrl();
      }, 2000);
    }
  };

  const onDownloadFile = async (fileUrl) => {
    if (!isDownloading) {
      setIsDownloading(true);

      fetch(fileUrl)
        .then((response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'share_image';
            alink.click();
          });
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  };

  const renderHeader = () => {
    return (
      <div className="share_header row_align_items">
        <div onClick={onClose}>
          <BlackBackIcon />
        </div>
        <div className="header_title font-bold font18">Share to Social</div>
      </div>
    );
  };

  const renderContentHeader = () => {
    return (
      <div className="statement_card_header">
        <div className="statement_card_header_tip">
          <div className="statement_card_header_tip_lamp">
            <LightBulbIcon />
          </div>
          <div className="statement_card_header_tip_did_you_know">
            Did you know
          </div>
        </div>
        <div className="statement_card_header_attach">
          <img
            src="/logo512.png"
            width={26}
            height={26}
            style={{ objectFit: 'contain' }}
          />
        </div>
      </div>
    );
  };

  const descTxt = useMemo(() => {
    const tmpTxt = data?.text.startsWith('Did you know that ')
      ? data?.text.replace('Did you know that ', '').replace('?', '')
      : data?.text.startsWith('Did you know that')
      ? data?.text.replace('Did you know that', '').replace('?', '')
      : data?.text.replace('Did you know', '').replace('?', '');

    return convertFirstToUpper(tmpTxt);
  }, [data]);

  const renderContentBody = () => {
    return <div className="statement_card_body">{descTxt}</div>;
  };

  const renderScanBody = () => {
    return (
      <div className="statement_card_bottom">
        <div className="row_align_items">
          <div className="align-vertical-center">
            {data?.creator_image && data?.creator_image.startsWith('https') ? (
              <img
                src={data?.creator_image}
                style={{ width: 32, height: 32, borderRadius: 32 }}
              />
            ) : (
              <DefaultAvatarIcon size={32} />
            )}
          </div>
          <div style={{ marginLeft: 6 }}>
            <div className="font-bold font12 text_no_wrap">
              {data?.creator_name}
            </div>
            {percentInfo && renderPercentInfo()}
          </div>
        </div>
        <div className="statement_card_bottom_btns clickable">
          {isLoadingQrcode ? (
            <LoadingCircular color="main" size={24} />
          ) : (
            <>
              <div className="statement_card_bottom_btns_QR">
                <img
                  src={qrCodeUrl}
                  style={{ width: '60px', height: '60px' }}
                  crossOrigin="anonymous"
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderPercentInfo = () => {
    return (
      <div
        className="font-regular font10 text_no_wrap"
        style={{ marginTop: 4 }}
      >
        {`Top ${percentInfo!.percentile}% in ${percentInfo!.topic_emoji} ${
          percentInfo!.topic_name
        }`}
      </div>
    );
  };

  const renderShartBody = () => {
    return (
      <div className="statement_link_bar">
        <div className="statement_link_bar_items">
          <div
            className="statement_link_bar_items_item download_itm clickable"
            onClick={() => onShare('download')}
          >
            {isDownloading ? (
              <LoadingCircular size={20} color={'white'} />
            ) : (
              <Download1Icon />
            )}
          </div>
          <div className="font10">Download</div>
        </div>

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <WhatsappShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Whatsapp_itm">
                <WhatsappIcon />
              </div>
              <div className="font10">Whatsapp</div>
            </div>
          </WhatsappShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TelegramShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Telegram_itm ">
                <TelegramIcon />
              </div>
              <div className="font10">Telegram</div>
            </div>
          </TelegramShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <FacebookShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div
                className="statement_link_bar_items_item Telegram_itm "
                style={{ padding: 0 }}
              >
                <FacebookIcon borderRadius={16} size={48} />
              </div>
              <div className="font10">Facebook</div>
            </div>
          </FacebookShareButton>
        )}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="modal_share_statement_body">
        <div id={`statement_share_body_${data?.item_id}`}>
          <div className="statement_card">
            {renderContentHeader()}
            {renderContentBody()}
            {renderScanBody()}
          </div>
        </div>

        {renderShartBody()}
      </div>
    );
  };

  return (
    <PrimaryModal
      show={show}
      width={'100%'}
      style={{ width: '100%', height: '100%', borderRadius: 0, padding: 0 }}
      onClose={onClose}
    >
      <div className="mobile_share_did_know_modal">
        {renderHeader()}
        {renderBody()}
      </div>
    </PrimaryModal>
  );
};
