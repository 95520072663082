import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import { MobileChatIntroModal } from 'components/Modals/Mobile/ChatIntroModal';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { useAudioContext } from 'shared/contexts/AudioContext';
import { TypeLoadingText } from 'shared/ui-kit/TypeLoadingText';
import { getArrFromString } from 'shared/utils/utils';

import './index.styles.scss';

interface Props {
  data: any;
  detectScroll: boolean;
  onViewBotProfile: (userId) => void;
}

export const MobileFeedCardChat = ({
  data,
  detectScroll,
  onViewBotProfile
}: Props) => {
  const listRef = useRef<HTMLDivElement>(null);
  const { playingAudio, playingAudioIndex } = useAudioContext();
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const [showTopShadow, setShowTopShadow] = useState(false);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [selectedAi, setSelectedAi] = useState<any>(null);

  const onViewProfile = (item) => {
    setSelectedAi({
      ai_id: item.creator_id,
      ai_type: item.type
    });
    setShowIntroModal(true);
  };

  // const renderIntroModal = () => {
  //   return (
  //     <MobileChatIntroModal
  //       open={showIntroModal}
  //       aiInfo={selectedAi}
  //       onClose={() => {
  //         setShowIntroModal(false);
  //       }}
  //     />
  //   );
  // };

  const arrAudios = useMemo(() => {
    const tmpArr = getArrFromString(data.chat_ai_audios, ';;;');

    return tmpArr;
  }, [data]);

  const arrExistedAudios = useMemo(() => {
    const tmpArr = getArrFromString(data.chat_ai_audios, ';;;');

    return tmpArr.filter((item, index) => item && !!item);
  }, [data]);

  const arrAllMessages = useMemo(() => {
    const arrIds = getArrFromString(data.chat_ai_ids, ';;;');
    const arrUserImages = getArrFromString(data.chat_ai_images, ';;;');
    const arrNames = getArrFromString(data.chat_ai_names, ';;;');
    const arrTmpMessage = getArrFromString(data.chat_messages, ';;;');
    const arrType = getArrFromString(data.chat_ai_types, ';;;');
    const arrDuration = getArrFromString(data.chat_ai_durations, ';;;');
    const arrChatIsAi = getArrFromString(data.chat_is_ai, ';;;');

    return arrIds.map((item, index) => {
      return {
        creator_id: arrIds[index],
        creator_name: arrNames[index],
        creator_image: arrUserImages[index],
        creator_name_color: data.text_color,
        chat_is_ai: arrChatIsAi[index],
        message: arrTmpMessage[index],
        type: arrType[index],
        audio: arrAudios[index],
        duration: parseFloat(arrDuration[index])
      };
    });
  }, [data]);

  const arrShowingMessages = useMemo(() => {
    if (playingAudio) {
      let isIncludingAudioIndex = arrAudios.indexOf(String(playingAudio));

      if (isIncludingAudioIndex !== -1) {
        return arrAllMessages.slice(0, isIncludingAudioIndex + 1);
      } else {
        return arrAllMessages;
      }
    } else {
      return arrAllMessages;
    }
  }, [arrAllMessages, playingAudio]);

  useEffect(() => {
    if (playingAudio && arrExistedAudios.includes(String(playingAudio)))
      onAdjustScrollToBottom();
  }, [arrShowingMessages, playingAudio]);

  const onAdjustScrollToBottom = () => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  };

  const handleScroll = (e) => {
    if (detectScroll) {
      // hide nav bar
      const scrollTop = e.target.scrollTop;
      setLastScrollTop(scrollTop);

      if (scrollTop < lastScrollTop) {
        document
          .getElementById('mobile_body')
          ?.setAttribute('class', 'mobile_body normal_mobile_body');
        document
          .getElementById('mobile_tab')
          ?.setAttribute('class', 'mobile_tab active_nav');
        setShowTopShadow(false);
      } else {
        document
          .getElementById('mobile_body')
          ?.setAttribute('class', 'mobile_body expanded_mobile_body');
        document
          .getElementById('mobile_tab')
          ?.setAttribute('class', 'mobile_tab hidden_nav');
        setShowTopShadow(true);
      }
    }
  };

  const renderTitle = () => {
    if (data.chat_title) {
      return (
        <div className="chat_title font-bold font32">{data.chat_title}</div>
      );
    } else {
      return <div />;
    }
  };

  const renderUserInfo = () => {
    return (
      <div
        className="user_info row_align_items font-regular"
        style={{
          justifyContent: 'center'
        }}
        onClick={() => {
          onViewBotProfile(data.creator_id);
        }}
      >
        <span className="font-regular text_no_wrap">{`Shared By`}</span>
        {data.creator_image && data.creator_image.startsWith('https') ? (
          <img src={data.creator_image} className="user_img" />
        ) : (
          <DefaultAvatarIcon size={26} />
        )}
        <span className="font-bold text_no_wrap">{`${data.creator_name}`}</span>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div
        className="feed_chat_body"
        style={{
          background: `linear-gradient(103.93deg, ${data.color} 0%, ${data.color} 101.36%)`
        }}
      >
        {arrShowingMessages.map((item, index) => {
          const animationSpeed = item.message
            ? item.duration / item.message.length
            : 20;

          return (
            <MessageBody
              index={index}
              item={item}
              animationSpeed={animationSpeed}
              key={`feed_chat_message_${index}`}
              onViewProfile={onViewProfile}
            />
          );
        })}
      </div>
    );
  };

  const renderBottomShadow = () => {
    return <div className="did_know_bottom"></div>;
  };

  const renderTopShadow = () => {
    return <div className="top_shadow"></div>;
  };

  return (
    <div
      className="mobile_feed_card_chat"
      ref={listRef}
      onScroll={handleScroll}
    >
      {renderTitle()}
      {renderUserInfo()}
      {renderBody()}
      {showTopShadow && renderTopShadow()}
      {renderBottomShadow()}
      {/* {renderIntroModal()} */}
    </div>
  );
};

const MessageBody = ({ item, index, animationSpeed, onViewProfile }) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const { playingAudio } = useAudioContext();
  const [showWithAnimation, setShowWithAnimation] = useState(
    !!playingAudio && playingAudio === item.audio
  );

  useEffect(() => {
    if (!!playingAudio && playingAudio === item.audio) {
      setShowWithAnimation(true);
    } else {
      setShowWithAnimation(false);
    }
  }, [playingAudio]);

  const renderBotIcon = () => {
    return (
      <div className="bot_icon">
        <RobotIcon size={12} color={'rgba(15, 15, 15, 0.4)'} />
      </div>
    );
  };

  return (
    <div
      className="feed_chat_message"
      style={index === 0 ? { marginTop: 24 } : {}}
    >
      <div
        className="row_align_items"
        onClick={() => {
          onViewProfile(item);
        }}
      >
        <div style={{ position: 'relative' }}>
          {item.creator_image && item.creator_image.startsWith('https://') ? (
            <img src={item.creator_image} className="creator_image" />
          ) : (
            <DefaultAvatarIcon size={40} />
          )}
          {renderBotIcon()}
        </div>

        <div
          className="creator_name font-bold font14"
          style={{ color: item.creator_name_color }}
        >
          {`${item.creator_name} ${item.chat_is_ai === '1' ? ' AI' : ''}`}
        </div>
      </div>
      <div className="message_body">
        {!showWithAnimation ? (
          <pre className="font-regular font16" style={{ lineHeight: '25.2px' }}>
            {item.message}
          </pre>
        ) : (
          <pre className="font-regular font16" style={{ lineHeight: '25.2px' }}>
            <TypeLoadingText
              text={item.message}
              fontSize={'font16'}
              speed={animationSpeed}
              onFinished={() => {
                setShowWithAnimation(false);
              }}
            />
          </pre>
        )}
      </div>
    </div>
  );
};
