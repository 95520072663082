import React, { useState } from 'react';
import { apiOrgRequestJoin } from 'shared/api/org';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import './index.styles.scss';

export const CardJoinOrg = ({ data }) => {
  const [isFollowing, setIsFollowing] = useState<
    'false' | 'requested' | 'true'
  >(data.isFollowed);
  const [isJoining, setIsJoining] = useState(false);

  const onAskToJoin = async () => {
    if (isFollowing === 'false') {
      setIsJoining(true);
      const res = await apiOrgRequestJoin({ organisation: data.id });

      if (res.success) {
        setIsFollowing(res.isFollowed);
      }
      setIsJoining(false);
    }
  };

  const renderFollowBtn = () => {
    if (isFollowing === 'false') {
      return (
        <div
          className="org_join_btn font-bold font10 clickable"
          onClick={onAskToJoin}
        >
          {isJoining ? <LoadingCircular size={16} color="white" /> : `Follow`}
        </div>
      );
    } else if (isFollowing === 'true') {
      return (
        <div className="org_requested_btn font-bold font10 clickable">
          {`Followed`}
        </div>
      );
    } else {
      return (
        <div className="org_requested_btn font-bold font10 clickable">
          {`Requested`}
        </div>
      );
    }
  };

  return (
    <div className="mobile_card_join_org">
      {data.image && data.image.startsWith('https') ? (
        <img src={data.image} className="org_image" />
      ) : (
        <div className="org_image"></div>
      )}
      <div
        className="font-bold font14"
        style={{
          marginTop: 12,
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          textAlign: 'center'
        }}
      >
        {data.name}
      </div>
      <div
        className="font-medium font12"
        style={{ opacity: 0.4, marginTop: 2 }}
      >
        {data.member_number} members
      </div>
      {renderFollowBtn()}
    </div>
  );
};
