import React, { useEffect, useRef, useState } from 'react';

import IntroIMG from 'assets/pngs/mobile_login_bg.png';

import './index.styles.scss';

import { useNavigate } from 'react-router-dom';
import { sendAnalytics } from 'shared/utils/analytics';
import { apiUserRequestMagicLink } from 'shared/api/user';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { showToast } from 'shared/ui-kit/PrimaryToast';

const RESEND_TIME = 60;

export const MobileLoginMagicLink = () => {
  const navigate = useNavigate();
  const [isSent, setIsSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [email, setEmail] = useState('');

  let timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [resendTime, setResendTime] = useState(RESEND_TIME);

  useEffect(() => {
    sendAnalytics('Loign');
  }, []);

  useEffect(() => {
    onCheck();
  }, [resendTime]);

  const onCheck = () => {
    if (resendTime == 0) {
      if (timerRef) {
        clearInterval(timerRef?.current!);
        setResendTime(RESEND_TIME);
      }
    }
  };

  const onStartCounter = () => {
    if (resendTime > 0) {
      timerRef.current = setInterval(() => {
        setResendTime((prev) => prev - 1);
      }, 1000);
    }
  };

  const onEnter = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      onSend();
    }
  };

  const onSend = async () => {
    if (resendTime === RESEND_TIME && email !== '') {
      setIsSending(true);

      const res = await apiUserRequestMagicLink({
        email: email.replaceAll(' ', '')
      });
      if (res.success) {
        onStartCounter();

        if (res.is_authorized) {
          setIsSent(true);
        } else {
          onWaitListSuccess();
        }
      } else {
        showToast('error', 'Magic link expired please request again');
      }

      setIsSending(false);
    }
  };

  const onWaitListSuccess = () => {
    navigate('/waitingsuccess');
  };

  const onWaitList = () => {
    navigate('/waitingList');
  };

  const onAcceptTerm = () => {
    window.open(process.env.REACT_APP_TERMS_URL);
  };

  const renderRegister = () => {
    return (
      <div className="register_input">
        <div className="your_email font12 font-bold">Email</div>
        <div>
          <input
            value={email}
            placeholder="Enter your email here..."
            className="email_input font-bold font18"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onKeyUp={(e) => {
              onEnter(e);
            }}
          />
        </div>
      </div>
    );
  };

  const renderSendBtn = () => {
    return (
      <div className="send_email_btn">
        <PrimaryButton
          label="Send Me a Magic Link"
          fontSize={16}
          isLoading={isSending}
          onPressed={onSend}
        />
      </div>
    );
  };

  const renderReSendBtn = () => {
    return (
      <div className="send_email_btn">
        <PrimaryButton
          label={resendTime == 60 ? `Resend` : `Resend (${resendTime}s)`}
          isLoading={isSending}
          onPressed={onSend}
        />
      </div>
    );
  };

  const renderSentSuccess = () => {
    return (
      <div className="resend_container">
        <div className="resend_img">📬</div>
        <div className="resend_title font-bold font24">
          Woohoo, we’ve sent you a magic link to log in
        </div>
        <div className="resend_desc font-regular font16">
          Check your email app for the link
          <br />
          (and spam if not there)
        </div>
        {renderReSendBtn()}
        {/* {renderHavingProblem()} */}
      </div>
    );
  };

  const renderHavingProblem = () => {
    return (
      <div className="already_registered">
        <div className="font14 font-regular" style={{ textAlign: 'center' }}>
          Want to let us know what you love or any issue you
          have?&nbsp;&nbsp;Write us anytime&nbsp;
          <span className="check_referral font14 font-bold">
            {`${process.env.REACT_APP_SUPPORT_EMAIL}`}
          </span>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="content_body1">
        <img src={IntroIMG} className="intro_img" />
        <div className="row_align_items">
          <img
            src={'/logo512.png'}
            width={40}
            height={40}
            style={{ objectFit: 'cover' }}
          />
          <div className="font-bold" style={{ marginLeft: 14, fontSize: 30 }}>
            {process.env.REACT_APP_NAME}
          </div>
        </div>
        <div className="font-bold font28">Login with Magic Link</div>
        {renderRegister()}
        {renderSendBtn()}
      </div>
    );
  };

  return (
    <div className="mobile_login_magic_link">
      {!isSent ? renderBody() : renderSentSuccess()}
    </div>
  );
};
