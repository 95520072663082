import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { apiChatRooms } from 'shared/api/chat';
import { apiGetGroups } from 'shared/api/group';
import { apiGetUserOrganisations } from 'shared/api/user';
import { MobileCardHeaderUserChat } from './CardHeaderUserChat';

import './index.styles.scss';
import { apiGetSuggestionSubscriptions } from 'shared/api/suggest';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';

interface Props {
  onEmpty: (status) => void;
}

export const MobileStartNewChatBody = ({ onEmpty }: Props) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const { hasNewStartNew } = useSelector((state: RootState) => state.app);
  const [tab, setTab] = useState<'subscribe' | 'group' | 'org'>('subscribe');

  const [arrRoom, setArrRoom] = useState<Array<any>>([]);
  const [lastIdRoom, setLastIdRoom] = useState(0);
  const [hasMoreRoom, setHasMoreRoom] = useState(true);
  const [isLoadingRoom, setIsLoadingRoom] = useState(false);
  const [isLoadingMoreRoom, setIsLoadingMoreRoom] = useState(false);

  const [arrUser, setArrUser] = useState<Array<any>>([]);
  const [lastIdUser, setLastIdUser] = useState(0);
  const [hasMoreUser, setHasMoreUser] = useState(true);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [isLoadingMoreUser, setIsLoadingMoreUser] = useState(false);

  const [arrGroup, setArrGroup] = useState<Array<any>>([]);
  const [lastIdDump, setLastIdGroup] = useState(0);
  const [hasMoreGroup, setHasMoreGroup] = useState(true);
  const [isLoadingGroup, setIsLoadingGroup] = useState(false);

  const [arrOrganisation, setArrOrganisation] = useState<Array<any>>([]);
  const [lastIdOrg, setLastIdOrg] = useState(0);
  const [hasMoreOrg, setHasMoreOrg] = useState(true);
  const [isLoadingOrg, setIsLoadingOrg] = useState(false);

  useEffect(() => {
    loadOngoingRooms();
    loadUserData();
    // loadGroupData();
    // loadOrgData();
  }, []);

  useEffect(() => {
    loadUserData();
  }, [hasNewStartNew]);

  const loadOngoingRooms = async (init = true) => {
    if (init) {
      setIsLoadingRoom(true);

      const res = await apiChatRooms({
        limit: 10,
        offset: 0,
        ongoing_only: 'true'
      });

      if (res.success) {
        setArrRoom(res.data.filter((item, index) => item.is_voted !== 1));
        setLastIdRoom(res.lastId);
        setHasMoreRoom(res.hasMore);
      }

      setIsLoadingRoom(false);
    } else {
      if (!isLoadingMoreRoom && hasMoreRoom) {
        setIsLoadingMoreRoom(true);

        const res = await apiChatRooms({
          limit: 10,
          offset: lastIdRoom,
          ongoing_only: 'true'
        });

        if (res.success) {
          setArrRoom((prev) => [...prev, ...res.data]);
          setLastIdRoom(res.lastId);
          setHasMoreRoom(res.hasMore);
        }

        setIsLoadingMoreRoom(false);
      }
    }
  };

  const loadUserData = async (init = true, keyword = '') => {
    if (init) {
      setIsLoadingUser(true);
      const res = await apiGetSuggestionSubscriptions({
        query: keyword,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        onEmpty(res.data.length === 0);

        setArrUser(res.data);
        setLastIdUser(res.lastId);
        setHasMoreUser(res.hasMore);
      }
      setIsLoadingUser(false);
    } else {
      if (hasMoreUser && !isLoadingMoreUser) {
        setIsLoadingMoreUser(true);
        const res = await apiGetSuggestionSubscriptions({
          query: keyword,
          limit: 10,
          offset: lastIdUser
        });

        if (res.success) {
          setArrUser((prev) => [...prev, ...res.data]);
          setLastIdUser(res.lastId);
          setHasMoreUser(res.hasMore);
        }
        setIsLoadingMoreUser(false);
      }
    }
  };

  const loadGroupData = async (init = true, keyword = '') => {
    if (init) {
      setIsLoadingGroup(true);
      const res = await apiGetGroups({
        query: keyword,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrGroup(res.data);
        setLastIdGroup(res.lastId);
        setHasMoreGroup(res.hasMore);
      }
      setIsLoadingGroup(false);
    } else {
      if (hasMoreGroup && !isLoadingGroup) {
        setIsLoadingGroup(true);
        const res = await apiGetGroups({
          query: keyword,
          limit: 10,
          offset: lastIdDump
        });

        if (res.success) {
          setArrGroup((prev) => [...prev, ...res.data]);
          setLastIdGroup(res.lastId);
          setHasMoreGroup(res.hasMore);
        }
        setIsLoadingGroup(false);
      }
    }
  };

  const loadOrgData = async (init = true) => {
    if (init) {
      setArrOrganisation([]);
      setIsLoadingOrg(true);
      const res = await apiGetUserOrganisations(userInfo ? userInfo.id : '');

      if (res.success) {
        setArrOrganisation(res.data);
        setLastIdOrg(res.lastId);
        setHasMoreOrg(res.hasMore);
      } else {
        setArrOrganisation([]);
      }
      setIsLoadingOrg(false);
    } else {
      if (hasMoreOrg && !isLoadingOrg) {
        setIsLoadingOrg(true);
        const res = await apiGetUserOrganisations(
          userInfo ? userInfo.id : '',
          5,
          lastIdOrg
        );

        if (res.success) {
          setArrOrganisation([...arrOrganisation, ...res.data]);
          setLastIdOrg(res.lastId);
          setHasMoreOrg(res.hasMore);
        }
        setIsLoadingOrg(false);
      }
    }
  };

  const onScrollNewChat = (e) => {
    const isEndReached =
      e.target.scrollWidth - e.target.scrollLeft <= e.target.clientWidth + 2;

    if (isEndReached) {
      // if (tab === 'group') {
      //   loadGroupData(false);
      // } else if (tab === 'org') {
      //   loadOrgData(false);
      // } else {
      loadUserData(false);
      // }
    }
  };

  const onScrollOngoingChat = (e) => {
    const isEndReached =
      e.target.scrollWidth - e.target.scrollLeft <= e.target.clientWidth;

    if (isEndReached) {
      loadOngoingRooms(false);
    }
  };

  const renderOnGoingChat = () => {
    return (
      <div>
        <div className="font-bold font18" style={{ marginLeft: 16 }}>
          Ongoing Chats
        </div>
        <div className="ongoing_chats_bar" onScroll={onScrollOngoingChat}>
          {isLoadingRoom
            ? renderLoadingBar()
            : arrRoom.map((item, index) => {
                const isPersonalChat =
                  item.ai_type === 'user' && item.user === userInfo?.id;

                return (
                  <div
                    className="ongoing_room"
                    key={`ongoing_room_${index}`}
                    onClick={() => {
                      if (!isPersonalChat) {
                        navigate(
                          `/prompt/conversation/${item.ai_id}/${item.id}`
                        );
                      } else {
                        navigate(`/conversation/${item.ai_id}/${item.id}`);
                      }
                    }}
                  >
                    <div className="ai_avatar">
                      {item.ai_type === 'general' ? (
                        <RobotIcon size={40} />
                      ) : item.ai_image === null ? (
                        <DefaultAvatarIcon size={40} />
                      ) : (
                        <img
                          src={item.ai_image}
                          width={40}
                          height={40}
                          style={{ borderRadius: '50%' }}
                        />
                      )}
                    </div>
                    <div
                      className="font14 font-medium"
                      style={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textAlign: 'center'
                      }}
                    >
                      {isPersonalChat ? 'Personal AI' : item.ai_name}
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div
        className="mobile_chat_header_body row_align_items"
        onScroll={onScrollNewChat}
      >
        {tab === 'subscribe' &&
          arrUser.map((item, index) => {
            return (
              <MobileCardHeaderUserChat
                key={`mobile_card_header_user_chat_${index}`}
                data={item}
                index={index}
              />
            );
          })}
      </div>
    );
  };

  const renderLoadingBar = () => {
    return (
      <div style={{ width: '100%' }}>
        <LoadingBarAnimation />
      </div>
    );
  };

  return (
    <div className="mobile_chat_top_part">
      {isLoadingUser ? renderLoadingBar() : renderBody()}
      {arrRoom.length > 0 && renderOnGoingChat()}
    </div>
  );
};
