import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiChatRooms } from 'shared/api/chat';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { CardChatHistory, ChatRoomData } from './CardChatHistory';

import './index.styles.scss';

export const MobileChatHistory = () => {
  const navigate = useNavigate();

  // rooms
  const [arrRoom, setArrRoom] = useState<Array<any>>([]);
  const [lastIdRoom, setLastIdRoom] = useState(0);
  const [hasMoreRoom, setHasMoreRoom] = useState(true);
  const [isLoadingRoom, setIsLoadingRoom] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    loadRooms();
  }, []);

  const loadRooms = async (init = true) => {
    if (init) {
      setIsLoadingRoom(true);

      const res = await apiChatRooms({
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setIsLoaded(true);
        setArrRoom(res.data);
        setLastIdRoom(res.lastId);
        setHasMoreRoom(res.hasMore);
      }

      setIsLoadingRoom(false);
    } else {
      if (!isLoadingRoom && hasMoreRoom) {
        setIsLoadingRoom(true);

        const res = await apiChatRooms({
          limit: 10,
          offset: lastIdRoom
        });

        if (res.success) {
          setArrRoom((prev) => [...prev, ...res.data]);
          setLastIdRoom(res.lastId);
          setHasMoreRoom(res.hasMore);
        }

        setIsLoadingRoom(false);
      }
    }
  };

  const onSelected = (chatItem) => {
    if (chatItem.ai_type === 'multichat') {
      navigate(`/prompt/conversation/1/${chatItem.id}`);
    } else {
      navigate(`/conversation/${chatItem.ai_id}/${chatItem.id}`);
    }
  };

  const onRemoveFromUI = (roomId) => {
    setArrRoom((prev) => prev.filter((item, index) => item.id !== roomId));
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;

    if (bottom) {
      loadRooms(false);
    }
  };

  const renderRoomHistoryHeader = () => {
    return (
      <div className="mobile_history_header">
        <div className="mobile_history_header_left">
          <div
            className="back_icon clickable"
            onClick={() => {
              navigate(-1);
            }}
          >
            <AngleLeftIcon size={24} color="#000000" />
          </div>
          <div className="mobile_history_header_title font-bold font18">
            Conversation History
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_chat_history">
      {renderRoomHistoryHeader()}
      <div className="mobile_chat_history_body" onScroll={handleScroll}>
        {isLoaded ? (
          <div className="mobile_chat_history_body_list">
            {arrRoom.map((item, index) => {
              return (
                <CardChatHistory
                  key={`card_chat_history_${index}`}
                  data={item}
                  onSelected={onSelected}
                  onRemoveFromUI={onRemoveFromUI}
                />
              );
            })}
          </div>
        ) : (
          <div className="loading_container">
            <LoadingBarAnimation />
          </div>
        )}
      </div>
    </div>
  );
};
