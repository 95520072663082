import { Box, Modal } from '@mui/material';
import React from 'react';

import './index.styles.scss';

interface PrimaryModalProps {
  show: boolean;
  width: number | string;
  style?: any;
  children: React.ReactNode;
  disableBackdropClick?: boolean;
  onClose: () => void;
}

const defaultStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  boxShadow: 24,
  borderRadius: '32px',
  outline: 'none',
  pt: 2,
  px: 4,
  pb: 3
};

export const PrimaryModal = ({
  show,
  width,
  style,
  children,
  disableBackdropClick = false,
  onClose
}: PrimaryModalProps) => {
  return (
    <Modal
      open={show}
      onClose={(e, reason) => {
        if (disableBackdropClick && reason === 'backdropClick') {
          return;
        } else {
          onClose();
        }
      }}
      onBackdropClick={(e) => {
        e.stopPropagation();
      }}
      style={{ zIndex: 1000000 }}
      className="primary_modal"
    >
      <Box
        sx={[defaultStyle, { width: width, backgroundColor: 'white' }, style]}
      >
        {children}
      </Box>
    </Modal>
  );
};
