import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import './index.styles.scss';

interface LoadingCircularProps {
  size: number;
  color: 'white' | 'main';
}

export const LoadingCircular = ({ size, color }: LoadingCircularProps) => {
  return (
    <CircularProgress
      color={color === 'main' ? 'primary' : 'success'}
      size={size}
    />
  );
};
