import React, { createContext, useContext, useState } from 'react';

interface AudioContextValue {
  playingAudio: HTMLAudioElement | null;
  playingAudioIndex: any;
  setPlayingAudio: (audio: any | HTMLAudioElement | null) => void;
  setPlayingAudioIndex: (index: any) => void;
}

const AudioContext = createContext<AudioContextValue>({
  playingAudio: null,
  playingAudioIndex: null,
  setPlayingAudio: () => {},
  setPlayingAudioIndex: () => {}
});

export const useAudioContext = () => useContext(AudioContext);

interface AudioContextProviderProps {
  children: React.ReactNode;
}

export const AudioContextProvider: React.FC<AudioContextProviderProps> = ({
  children
}) => {
  const [playingAudio, setPlayingAudio] = useState<
    any | HTMLAudioElement | null
  >(null);
  const [playingAudioIndex, setPlayingAudioIndex] = useState<any>(null);

  return (
    <AudioContext.Provider
      value={{
        playingAudio,
        playingAudioIndex,
        setPlayingAudio,
        setPlayingAudioIndex
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};
