import React from 'react';

function LocationArrowIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 16}
      height={props.size ?? 16}
      fill="none"
      viewBox="0 0 17 16"
    >
      <path
        fill={props.color ?? '#fff'}
        d="M14.6 1.906a1.927 1.927 0 00-2-.474L3.167 4.586a1.933 1.933 0 00-.08 3.646L6.58 9.566a.62.62 0 01.353.346l1.334 3.5a1.913 1.913 0 001.806 1.253h.047a1.92 1.92 0 001.793-1.333l3.154-9.446a1.926 1.926 0 00-.467-1.98zm-.767 1.56l-3.186 9.453a.587.587 0 01-.56.413.614.614 0 01-.58-.387l-1.334-3.5a1.94 1.94 0 00-1.113-1.12l-3.5-1.333a.6.6 0 01-.393-.58.587.587 0 01.413-.56L13.033 2.7a.607.607 0 01.8.767z"
      ></path>
    </svg>
  );
}

export default LocationArrowIcon;
