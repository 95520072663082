import React from 'react';

function ThumbDownIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '32'}
      height={props.size ?? '32'}
      fill="none"
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_7336_202703)">
        <path
          fill={props.color ?? '#EA5098'}
          d="M20 4H8a2.649 2.649 0 00-2.453 1.627l-4.027 9.4c-.12.306-.187.626-.187.973v2.667c0 1.466 1.2 2.666 2.667 2.666h8.413l-1.266 6.094-.04.426c0 .547.226 1.054.586 1.414l1.414 1.4 8.786-8.787a2.65 2.65 0 00.774-1.88V6.667C22.667 5.2 21.467 4 20 4zm0 16l-5.787 5.787L16 18.667H4V16l4-9.333h12V20zm5.333-16h5.334v16h-5.334V4z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_7336_202703">
          <path fill="#fff" d="M0 0H32V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ThumbDownIcon;
