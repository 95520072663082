import React from 'react';

function ShareIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 20}
      height={props.size ?? 20}
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        fill={props.color ?? '#000'}
        stroke={props.color ?? '#000'}
        d="M12.224 13.855l.353.163.245-.302a2.835 2.835 0 012.18-1.048 2.833 2.833 0 11-2.835 2.836c.002-.169.019-.337.05-.503l.07-.383-.353-.163-4.4-2.025-.313-.144-.246.24a2.833 2.833 0 110-4.05l.246.24.313-.144 4.4-2.025.354-.163-.072-.383a2.833 2.833 0 01.601-2.335l-.384-.319.385.32a2.833 2.833 0 110 3.615l-.245-.296-.349.16-4.25 1.96-.404.185.138.423a2.534 2.534 0 010 1.573l-.138.423.404.187 4.25 1.958zm3.98-10.156a2.167 2.167 0 10-2.408 3.603A2.167 2.167 0 0016.204 3.7zM3.796 12.302A2.166 2.166 0 106.204 8.7a2.166 2.166 0 00-2.408 3.602zm10 5a2.167 2.167 0 102.408-3.603 2.167 2.167 0 00-2.408 3.603z"
      ></path>
    </svg>
  );
}

export default ShareIcon;
