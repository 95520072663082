import React from 'react';

function ChartIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '20'}
      height={props.size ?? '20'}
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        fill={props.color ?? '#FF3E9A'}
        d="M10.001 5.497a.833.833 0 00-.833.834v8.333a.833.833 0 001.667 0V6.331A.834.834 0 0010 5.497zm-4.166 5a.833.833 0 00-.834.834v3.333a.833.833 0 001.667 0v-3.333a.833.833 0 00-.833-.834zm8.333-1.666a.833.833 0 00-.833.833v5a.833.833 0 001.666 0v-5a.833.833 0 00-.833-.833zm1.667-6.667H4.168a2.5 2.5 0 00-2.5 2.5v11.667a2.5 2.5 0 002.5 2.5h11.667a2.5 2.5 0 002.5-2.5V4.664a2.5 2.5 0 00-2.5-2.5zm.833 14.167a.833.833 0 01-.833.833H4.168a.833.833 0 01-.833-.833V4.664a.833.833 0 01.833-.833h11.667a.833.833 0 01.833.833v11.667z"
      ></path>
    </svg>
  );
}

export default ChartIcon;
