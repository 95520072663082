import React from 'react';

function AudioPauseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '20'}
      height={props.size ?? '20'}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={props.color ?? '#FF3E9A'}
        d="M13.335 1.664a2.5 2.5 0 00-2.5 2.5v11.667a2.5 2.5 0 105 0V4.164a2.5 2.5 0 00-2.5-2.5zm.833 14.167a.833.833 0 01-1.667 0V4.164a.833.833 0 111.667 0v11.667zm-7.5-14.167a2.5 2.5 0 00-2.5 2.5v11.667a2.5 2.5 0 105 0V4.164a2.5 2.5 0 00-2.5-2.5zm.833 14.167a.833.833 0 01-1.666 0V4.164a.833.833 0 111.666 0v11.667z"
      ></path>
    </svg>
  );
}

export default AudioPauseIcon;
